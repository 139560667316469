// import { useWallet } from "@solana/wallet-adapter-react";
import { Suspense, useMemo, useState } from "react";
// import CouncilVotingPanel from "../components/CouncilVotingPanel";
import { Proposal } from "../components/Proposal";
import TreasuryPanel from "../components/TreasuryPanel";
import { themeOpts } from "../components/ThemeChanger";
import { useAtom } from "jotai";
import { ProposalSkeleton } from "../components/ProposalSkeleton";
import { useCurrentOrganisation } from "../state/hooks/useAlignGovernance";
import { useNavigate, useParams } from "react-router-dom";
import { JoinOrg } from "../components/JoinOrg";
import JoinOrgModal from "../components/joinOrgModal";
import { AlignLink } from "../components/AlignLink";
import { Tab } from "@headlessui/react";
// import PastProposalsPanel from "../components/PastProposalsPanel";

export function OrgOverview() {
  const [modalJoinOpen, setModalJoinOpen] = useState(false)
  const [theme,] = useAtom(themeOpts)
  const navigate = useNavigate()
  const { org } = useParams()

  return (
    <div className="org-overview-page mx-auto max-w-screen-2xl px-8 py-1 text-contrast">
      <div className="flex-wrap justify-between gap-6 lg:grid lg:grid-cols-3 mb-12">
        <main className={`proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col w-full lg:col-span-2 ${theme.gridLayout && 'p-3 sm:p-6 bg-secondary bg-opacity-30 border-boxWidth'}`}>
          <div className="flex justify-between items-center">
            
            <Suspense>
              <JoinOrg />
            </Suspense>
          </div>

          <Suspense>
            <TreasuryPanel container={false} />
          </Suspense>

        </main>
        <aside className="w-full lg:col-span-1 mt-4 lg:mt-0">
          <Suspense>
            <>
              {/* <CouncilVotingPanel />
              <PastProposalsPanel /> */}
            </>
          </Suspense>
        </aside>
      </div>
      <JoinOrgModal onClose={() => setModalJoinOpen(false)} show={modalJoinOpen} />
    </div>
  );
}