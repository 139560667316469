import { Proposal } from "../Proposal";
import { Wizard, WizardStepProps } from "./Wizard";
import DescriptionStep from "./steps/DescriptionStep";
import ProposalWizardGovernanceCouncilStep from "./steps/ProposalWizardGovernanceCouncilStep";
import ProposalWizardGovernanceReviewStep from "./steps/ProposalWizardGovernanceReviewStep";
import ProposalWizardGovernanceThresholdStep from "./steps/ProposalWizardGovernanceThresholdStep";
import ProposalWizardBonkRewardsAmount from "./steps/bonkRewards/ProposalWizardBonkRewardsAmount";
import ProposalWizardBonkRewardsSummary from "./steps/bonkRewards/ProposalWizardBonkRewardsSummary";
import ProposalWizardRecipient from "./steps/sendAssets/ProposalWizardRecipient";
import ProposalWizardSendDescription from "./steps/sendAssets/ProposalWizardSendDescription";
import ProposalWizardSendSummary from "./steps/sendAssets/ProposalWizardSendSummary";
import ProposalWizardSendTokens from "./steps/sendAssets/ProposalWizardSendTokens";


const ProposalWizardBonkRewards = () => {
    return( 
        <>
           <Wizard>
                {
                    (props : WizardStepProps<{}>) => (
                        <>
                            <ProposalWizardSendDescription {...props}/>
                            <ProposalWizardBonkRewardsAmount {...props}/>
                            <ProposalWizardBonkRewardsSummary {...props}/>
                        </>
                    )
                }
       
            </Wizard>
        </> 
    );
}
 
export default ProposalWizardBonkRewards;