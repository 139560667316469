import { Cog6ToothIcon, ForwardIcon } from "@heroicons/react/24/outline";
import { BN } from "@project-serum/anchor";
import { createAssociatedTokenAccountIdempotentInstruction, createTransferCheckedInstruction, getAssociatedTokenAddressSync } from "@solana/spl-token";
import { useConnection } from "@solana/wallet-adapter-react";
import { AccountMeta, Connection, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, TransactionInstruction } from "@solana/web3.js";
import { BERN_MINT_ADDRESS, USDC_MINT_ADDRESS, createProposalV2, sendNftInstruction } from "align-sdk";
import { delay } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { ArrowRight } from "react-feather";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { fetchNfts } from "../../../../api/nfts";
import { REFRESH_DELAY } from "../../../../constants";
import { useGetReputationQuery } from "../../../../generated/graphql";
import { isCustomDomainStore } from "../../../../state/domains";
import { proposalWizardSendAssetsDescription, proposalWizardSendAssetsNFTs, proposalWizardSendAssetsRecipients, proposalWizardSendAssetsTokens } from "../../../../state/forms";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useOrganisation } from "../../../../state/hooks/useAlignGovernance";
import { useCoinPrice } from "../../../../state/hooks/useCoins";
import { alignLink } from "../../../../utils/alignRoute";
import { prettyRoundedStringBalance } from "../../../../utils/coins";
import { truncateKey } from "../../../../utils/truncateKey";
import { WizardStepProps } from "../../Wizard";
import { NFTSummary } from "./ProposalWizardSendSummary/NFTSummary";
import { Recipient } from "./ProposalWizardSendTokens";
import { NFT } from "./ProposalWizardSendTokens/components/NFTItem";
import {
  ConcurrentMerkleTreeAccount,
  SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
  SPL_NOOP_PROGRAM_ID,
} from "@solana/spl-account-compression";
import { createTransferInstruction } from "../../../../utils/createCompressedTransfer";

const transferCompressedNFT = async (
  connection: Connection,
  treeAddress: PublicKey,
  proof: string[],
  root: string,
  dataHash: string,
  creatorHash: string,
  leafId: number,
  owner: string,
  newLeafOwner: PublicKey,
  delegate: string,
  nodeIndex: number
) => {
  const treeAccount = await ConcurrentMerkleTreeAccount.fromAccountAddress(connection, treeAddress);

  const treeAuthority = treeAccount.getAuthority();
  const canopyDepth = treeAccount.getCanopyDepth();

  const proofPath: AccountMeta[] = proof
    .map((node: string) => ({
      pubkey: new PublicKey(node),
      isSigner: false,
      isWritable: false,
    }))
    .slice(0, proof.length - (!!canopyDepth ? canopyDepth : 0));
    const leafOwner = new PublicKey(owner);
    const leafDelegate = delegate ? new PublicKey(delegate) : leafOwner;
    const index = nodeIndex - 2 ** proof.length;
    
    const transferInstruction = createTransferInstruction(
      {
        merkleTree: treeAddress,
        treeAuthority: treeAuthority,
        leafOwner: leafOwner,
        leafDelegate: leafDelegate,
        newLeafOwner: newLeafOwner,
        logWrapper: SPL_NOOP_PROGRAM_ID,
        compressionProgram: SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
        anchorRemainingAccounts: proofPath
      },
      {
        root: [...new PublicKey(root.trim()).toBytes()],
        dataHash: [...new PublicKey(dataHash.trim()).toBytes()],
        creatorHash: [...new PublicKey(creatorHash.trim()).toBytes()],
        nonce: leafId,
        index: index
      },
      new PublicKey('BGUMAp9Gq7iTEuizy4pqaxsTyUCBK68MDfK752saRPUY')
    )
    
  return transferInstruction;
}


const CoinSendSummary = ({
  mint,
  recipients,
  amounts
}: {
  mint: string,
  recipients: {
    id: number;
    walletAddress: string;
    name: string;
  }[],
  amounts: {
    amount: string,
    decimals: number
    recipient: string | number
  }[]
}) => {

  const [name, setName] = useState<string | null>(null);
  const [image, setImage] = useState<string | null | undefined>(null)
  const [tokenAmounts, settokenAmounts] = useState<Record<string, string>>({});
  const price = useCoinPrice(mint === "11111111111111111111111111111111" ? "So11111111111111111111111111111111111111112" : mint)
  const amount: number = useMemo(() => amounts.reduce((prev: number, current: {
    amount: string,
    decimals: number
    recipient: string | number
  }) => prev + Number(current.amount), 0), [amounts]) as number

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        if (mint === "11111111111111111111111111111111") {
          setName("SOL")
          setImage("/sol-logo.webp")
          return
        }

        if (mint === USDC_MINT_ADDRESS.toBase58()) {
          setName("USDC")
          setImage("/usdc-logo.webp")
          return
        }

                if(mint === BERN_MINT_ADDRESS.toBase58()){
                    setName("BERN")
                    setImage("/bern.jpg")
                    return
                }
                
                const account = await fetchNfts([mint])
                if(account.length === 0){
                    return
                }
                setName(account[0]?.content.metadata.name)
                setImage(account[0]?.content?.links.image)
            }
            catch(e){
               return 
            }
        
        }

    fetchMetadata()
  }, [mint]);




  return (
    <li className="w-full bg-contrast bg-opacity-10 p-4 min-h-28 rounded flex flex-col gap-2">
      <div className="flex justify-between mb-4">

        <div className="flex gap-3 justify-center items-center">
          <img className="rounded-full w-8 h-8" src={image || ""} />
          <div className="flex flex-col ">
            <div className="flex gap-3 justify-start items-center ">
              <div>{prettyRoundedStringBalance(amount.toString())}</div>
              <div className="text-xs font-light text-contrast text-opacity-70">${Math.floor(((amount * (price || 0)) * 100)) / 100}</div>
            </div>
            <a target="_blank" href={`https://solana.fm/address/${mint}`} className="hover:cursor-pointer hover:text-opacity-60 text-xs text-accent text-opacity-20">{mint !== "11111111111111111111111111111111" ? truncateKey(mint) : "~"}</a>
          </div>

        </div>

        <div className="flex gap-1 text-xs justify-center items-center font-light">
          <label className="flex gap-2 justify-start items-center w-[150px] bg-contrast bg-opacity-10 px-2 text-sm h-6">
            To {amounts.length} Recipient(s)
          </label>
        </div>
      </div>

      {
        amounts.map(am => (
          <div className=" w-full flex gap-2 justify-start=items-center">

            <div
              className="flex gap-2 justify-start items-center w-[100px] bg-contrast bg-opacity-10 px-2 text-sm h-7"
            >
              <label>{prettyRoundedStringBalance(am.amount.toString())}
              </label>
              <label className="text-xs font-light text-contrast text-opacity-70">${Math.floor(((parseFloat(am.amount) * (price || 0)) * 100)) / 100}</label>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <hr className="border-accent w-full border " />
              <ArrowRight className="text-accent" />
            </div>
            <label className="flex gap-2 justify-start items-center bg-contrast bg-opacity-10 px-2 text-sm h-7">{recipients.find(x => x?.id as any === am.recipient)?.name}
              <p className="text-xs text-opacity-25">{truncateKey(recipients.find(x => x?.id as any === am.recipient)?.walletAddress || "")}</p>
            </label>
          </div>
        ))
      }
    </li>
  )
}

export const ProposalTransferSummary = ({
  recipients,
  tokenAmounts
}: {
  recipients: {
    id: number;
    walletAddress: string;
    name: string;
  }[],
  tokenAmounts: Record<string, {
    amount: string,
    decimals: number
    recipient: string | number
  }[]>
}) => {
  return (
    <ol className="flex flex-col gap-3 w-full overflow-auto scroll">

      {
        recipients && tokenAmounts && Object.entries(tokenAmounts).map(([mint, input]: [string,
          {
            amount: string,
            decimals: number
            recipient: string | number
          }[]]) => (
          <CoinSendSummary mint={mint} recipients={recipients} amounts={input} />
        ))
      }

    </ol>
  )

}

const ProposalWizardSendSummary = ({
  currentStep,
  totalSteps,
  onComplete,
  goBack
}: WizardStepProps<{}>) => {
  const user = useAuth()
  const sendInputs = useRecoilValue(proposalWizardSendAssetsTokens)
  const sendNFTs = useRecoilValue(proposalWizardSendAssetsNFTs)
  const recipientInputs = useRecoilValue(proposalWizardSendAssetsRecipients)
  const descriptionInputs = useRecoilValue(proposalWizardSendAssetsDescription)

  const [currentOrg] = useCurrentOrganisation()
  const { organisation } = useOrganisation(currentOrg)
  const { connection } = useConnection()
  const alignPrograms = useAlignPrograms()
  const navigate = useNavigate()
  const isCustomDomain = useRecoilValue(isCustomDomainStore)
  const [isSubmitting, setIsSubmitting] = useState(false)

  console.log("sendNFTs", sendNFTs)

  const reputation = useGetReputationQuery({
    variables: {
      userIdentityAddress: user?.userIdentityAddress || "",
      organisationAddress: currentOrg || ""
    },
    skip: user?.userIdentityAddress === undefined || currentOrg === undefined
  })
  const isCouncil = useMemo(() => organisation?.councilManager?.councilMembers.find(c => c.user?.userIdentityAddress === user?.userIdentityAddress) !== undefined, [organisation?.councilManager?.councilMembers, user?.userIdentityAddress])

  console.log(reputation)

  if (currentStep !== 3) {
    return <></>
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    //Validations neeeds to be done heere
    if ((organisation
      && organisation?.config?.proposalMinReputationGate !== null
      && reputation.data?.reputation.totalReputation < organisation?.config?.proposalMinReputationGate
    ) && !isCouncil) {
      toast.error("You do not have enough reputation to create a proposal.");
      return;
    }

            const walletConfig = organisation?.wallets.find(x => x.name === "Treasury")
        if (!user?.identity) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>There is no user for the proposal being submited.</div>
                    <div>Please contact us if problems persist.</div>
                </div>
            );
            return;
        }

    console.log("sendInputs", sendInputs)
    console.log("sendNFTs", sendNFTs)

    if (!recipientInputs || (!sendInputs && !sendNFTs) || !descriptionInputs) {
      toast.error("Error retrieving form inputs.");
      return;
    }

        if (!organisation || organisation.config?.rankingPeriod === undefined || organisation.config?.rankingPeriod === null) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>No organisation was found that matched the proposal being submited.</div>
                    <div>Please contact us if problems persist.</div>
                </div>
            );
            return;
        }

        if (!alignPrograms) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>Provider information was not found for instruction.</div>
                    <div>Please contact us if problems persist.</div>
                </div>
            );
            return;
        }

        // if(!gover?.walletConfig){
        //     toast.error("Please select a governance account to take the payout from if the proposal gets approved by the council.");
        //     return;
        // }
        toast.loading(
            <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                <div>Creating your proposal this may take some time.</div>
                <div>Please be patient.</div>
            </div>, {
            id: 'createProposal'
        })


    if (!walletConfig) {
      toast.error("Could not find wallet config");
      return;
    }

    let transferInstructions: TransactionInstruction[] = []

    try {
      if (sendInputs) {
        const tokenTransferInstruction = Object.entries(sendInputs).flatMap(([mint, amounts]: [string, {
          amount: string;
          decimals: number;
          recipient: string | number;
          programId: string;
        }[]]) => {
          console.log('passei aqui')
          return amounts.flatMap(amount => {
            const recipient = recipientInputs.find(rec => rec.id === amount.recipient)
            if (!recipient) {
              throw new Error("Could not find recipient")
            }
            if (mint === "11111111111111111111111111111111") {
              console.log('transfering sol')
              return SystemProgram.transfer({
                fromPubkey: new PublicKey(walletConfig.walletAddress),
                toPubkey: new PublicKey(recipient.walletAddress),
                lamports: Number(amount.amount) * LAMPORTS_PER_SOL
              })
            }
            else {
              const senderAta = getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(walletConfig.walletAddress), true)
              const recieverAta = getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(recipient.walletAddress), true)
              console.log(amount)
              const tokenAmount = amount.decimals > 2 ? new BN(Math.floor(Number(amount.amount) * 100)).mul(new BN(10).pow(new BN(amount.decimals - 2))).toString() : amount.amount
              console.log(tokenAmount)
              const createAtaix = createAssociatedTokenAccountIdempotentInstruction(
                new PublicKey(walletConfig.walletAddress),
                recieverAta,
                new PublicKey(recipient.walletAddress),
                new PublicKey(mint)
              )
              return [createAtaix,
                createTransferCheckedInstruction(
                  senderAta,
                  new PublicKey(mint),
                  recieverAta,
                  new PublicKey(walletConfig.walletAddress),
                  BigInt(tokenAmount),
                  amount.decimals,
                  [],
                  new PublicKey(amount.programId)
                )]
            }
          })
        })

        transferInstructions = [...transferInstructions, ...tokenTransferInstruction]
      }

      console.log("transferInstructions", transferInstructions)

      if (sendNFTs) {
        const nftTransferInstructions = Object.entries(sendNFTs).filter(([mint, nft]: [string, { nft: NFT, recipient: Recipient }]) => !nft.nft.compression.compressed && nft.nft.token_standard !== 'ProgrammableNonFungibleEdition' && nft.nft.token_standard !== 'ProgrammableNonFungible')
        .flatMap(([mint, nft]: [string, { nft: NFT, recipient: Recipient }]) => {
          console.log('passei aqui')

          const recipient = recipientInputs.find(rec => rec.id === nft.recipient.id)
          if (!recipient) {
            throw new Error("Could not find recipient")
          }
          const senderAta = getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(walletConfig.walletAddress), true)
          const recieverAta = getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(recipient.walletAddress), true)
          const createAtaix = createAssociatedTokenAccountIdempotentInstruction(
            new PublicKey(walletConfig.walletAddress),
            recieverAta,
            new PublicKey(recipient.walletAddress),
            new PublicKey(mint)
          )
          return [createAtaix,
            createTransferCheckedInstruction(
              senderAta,
              new PublicKey(mint),
              recieverAta,
              new PublicKey(walletConfig.walletAddress),
              1,
              0
            )]
        }
        )

        const programmableNftTransferInstructions = Object.entries(sendNFTs).filter(([mint, nft]: [string, { nft: NFT, recipient: Recipient }]) => nft.nft.token_standard === 'ProgrammableNonFungibleEdition' || nft.nft.token_standard === 'ProgrammableNonFungible')
        .flatMap(async ([mint, nft]: [string, { nft: NFT, recipient: Recipient }]) => {
          console.log('passei programmable');

          const recipient = recipientInputs.find(rec => rec.id === nft.recipient.id)
          if (!recipient) {
            throw new Error("Could not find recipient")
          }

        const programmableNftTransferInstruction = await sendNftInstruction(
          new PublicKey(recipient.walletAddress),
          new PublicKey(nft.nft.ownership.owner),
          new PublicKey(nft.nft.mint),
          new BN(1),
          connection
        )

        return programmableNftTransferInstruction;
      })


        const compressedNftTransferInstructions = Object.entries(sendNFTs).filter(([mint, nft]: [string, { nft: NFT, recipient: Recipient }]) => nft.nft.compression.compressed)
        .flatMap(async ([mint, nft]: [string, { nft: NFT, recipient: Recipient }]) => {
          console.log('passei compressed')

          const recipient = recipientInputs.find(rec => rec.id === nft.recipient.id)
          if (!recipient) {
            throw new Error("Could not find recipient")
          }

          const getAssetProof = async () => {
            const response = await fetch(process.env.REACT_APP_SOL_RPC ?? '', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                jsonrpc: '2.0',
                id: 'my-id',
                method: 'getAssetProof',
                params: {
                  id: nft.nft.mint
                },
              }),
            });
            const { result } = await response.json();
            return result;
          };
          const assetProof = await getAssetProof();
          console.log('assetProof', assetProof)
          const compressedNftTransferInstruction = await transferCompressedNFT(
            connection,
            new PublicKey(assetProof.tree_id),
            assetProof.proof,
            assetProof.root,
            nft.nft.compression.data_hash,
            nft.nft.compression.creator_hash,
            nft.nft.compression.leaf_id ?? 0,
            nft.nft.ownership.owner,
            new PublicKey(recipient.walletAddress),
            nft.nft.ownership.delegate,
            assetProof.node_index
          )

          return compressedNftTransferInstruction
        }
        )

        const resolvedProgrammableNftTransferInstructions = await Promise.all(programmableNftTransferInstructions);
        const resolvedProgrammableNftTransferInstructionsFlat = resolvedProgrammableNftTransferInstructions.flat();
        const resolvedCompressedNftTransferInstructions = await Promise.all(compressedNftTransferInstructions);

        transferInstructions = [...transferInstructions, ...nftTransferInstructions, ...resolvedCompressedNftTransferInstructions, ...resolvedProgrammableNftTransferInstructionsFlat]
      }


      console.log("allTransferInstructions", transferInstructions)

      const { sigs, proposalAddress } = await createProposalV2(
        new PublicKey(organisation.address),
        new PublicKey(user.identity.identifier),
        // [new Transaction().add(...transferInstructions)],
        transferInstructions.map((instruction) => new Transaction().add(instruction)),
        new PublicKey(walletConfig.address),
        !descriptionInputs.hasRanking ? new BN(0) : new BN(organisation.config.rankingPeriod),
        {
          name: descriptionInputs.title,
          description: descriptionInputs.description,
        },
        {
          onCreatingDrive: () => toast.loading("Creating a shadow drive to upload your proposal metadata to. This may take some time. Please be patient", {
            id: 'createProposal'
          }),
          onCreateDrive: (res) => toast.loading("Drive created successfully. Now attempting to upload metadata.", {
            id: 'createProposal'
          }),
          onUpload: (res) => toast.loading("Upload complete. Creating your proposal.", {
            id: 'createProposal'
          }),
          onUploadRetry: () => toast.loading("Uploading metadata failed. Trying again..", {
            id: 'createProposal'
          })
        },
        alignPrograms,
        undefined
      )

      if (proposalAddress) {
        toast.success("Sucessfully created your proposal.", { id: 'createProposal' })
        delay(() => {
          // refreshProposals();
          navigate(alignLink(`/proposal/${proposalAddress.toBase58()}`, isCustomDomain, organisation?.address))
        }, REFRESH_DELAY);
      }
    }
    catch (e) {
      // toast.error(e?.toString() || "Unkown Error", { id: 'createProposal' })
      console.log(e);
    }

    setIsSubmitting(false)
  }

  return (
    <div className="proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full md:w-5/6 p-3 md:p-6 lg:col-span-2 sm:p-6 bg-secondary border-boxWidth flex flex-col">
      <div className="flex justify-between">
        <div className="font-heading text-lg md:text-3xl mb-2">Create Send Proposal </div>
        <p className="text-lg font-semibold text-primary">STEP {currentStep + 1}/{totalSteps}</p>
      </div>
      <h3 className="text-sm font-normal mb-6">Review your send proposal summary below.</h3>
      <div className="w-full mb-3 p-4 proposals-container box-container bg-opacity-30 rounded-lg border-2 border-contrast border-opacity-30 flex-col justify-start items-start gap-2.5 inline-flex">
        <h2 className="text-sm">SUMMARY</h2>

        <div className="w-full bg-contrast bg-opacity-10 p-4 min-h-28 rounded flex flex-col gap-2">
          <div className="contrast">{descriptionInputs?.title}</div>
          <div className="text-sm text-contrast text-opacity-90">{descriptionInputs?.description}</div>
        </div>

        <div className="w-full bg-contrast bg-opacity-10 p-4 min-h-28 rounded flex flex-col gap-2">
          <div className="contrast text-contrast text-opacity-50 font-thin text-sm">Has Ranking Period</div>
          <div className="text-sm text-contrast text-opacity-90">{descriptionInputs?.hasRanking ? "YES" : "FALSE"}</div>
        </div>

        {
          sendInputs && recipientInputs && <ProposalTransferSummary tokenAmounts={sendInputs} recipients={recipientInputs} />
        }
        {
          sendNFTs && Object.entries(sendNFTs).length > 0 && <NFTSummary nftsToSend={sendNFTs} />
        }
      </div>
      <button
        className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary  
            transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"

        onClick={() => handleSubmit()}
      >
        <Cog6ToothIcon className={`w-4 `} />
        Create Proposal
      </button>
      <button
        className="w-full flex justify-center gap-2 items-center mt-4 self-end border rounded-button border-primary 
              transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
        onClick={() => goBack()}
        disabled={isSubmitting}
      >
        <ForwardIcon className={`w-4 transform rotate-180`} />
        Go back
      </button>
    </div>
  );
}

export default ProposalWizardSendSummary;