import { ForwardIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { Api, Derivation } from "align-sdk";
import { useEffect, useMemo, useState } from "react";
import { Controller, ControllerRenderProps, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { proposalWizardcouncilMembersStepInputs } from "../../../state/forms";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useOrganisation } from "../../../state/hooks/useAlignGovernance";
import { isPubkey, truncateKey } from "../../../utils/truncateKey";
import Avatar from "../../Avatar";
import LoadingSpinner from "../../LoadingSpinner";
import { useGetUserQuery } from "../../../generated/graphql";
import { DescriptionPanel } from "../../DescriptionPanel";
import { UserInfo } from "../../UserInfo";
import { WizardStepProps } from "../Wizard";

export function CouncilMemberInfo({identifer, field} : {identifer : string, field : ControllerRenderProps<any, `councilMembers.${number}.identity`>}){

    const {data, loading, error} = useGetUserQuery({
        variables:{
            address: identifer
        }
    })
   


    // if(notFound) return <div>User not found</div>
    if(loading){
        return <>
        <LoadingSpinner/>
        </>
    }
    return(
        !loading && identifer !== null ? (
         <>
            <Avatar userIdentifier={new PublicKey(identifer)} size={16} noBorder={true}/>
            <div className="flex flex-col gap-1 justify-center">
                <label className="text-sm">{data?.user.displayName}</label>
                <label className="text-sm font-light text-contrast text-opacity-70">{truncateKey(identifer)}</label>
            </div>
            <div className="mb-1 flex gap-2 justify-center flex-1 bg-transparent border border-contrast border-opacity-10 rounded-full text-sm p-2 px-2 ">
                <input className="bg-transparent flex-1 outline-none focus:outline-none" {...field} value={data?.user.username} disabled />
            </div>
        </>
        ) : <LoadingSpinner/>
    )
}

const ProposalWizardGovernanceCouncilStep = (
    {
        currentStep,
        onComplete
    } : WizardStepProps<{}>
) => {

    const user = useAuth()
    const setCouncilInputs = useSetRecoilState(proposalWizardcouncilMembersStepInputs)
    const {connection} = useConnection()
    const alignPrograms = useAlignPrograms()
    const [organisationAddress] = useCurrentOrganisation()
    const {organisation} = useOrganisation(organisationAddress)

    const { register, handleSubmit, formState: { errors }, control, getValues } = 
        useForm<{councilMembers : {identity : string | null, username : string | null}[]}>({
        defaultValues: {
            councilMembers: organisation?.councilManager?.councilMembers.map(x => ({username: x.user.username, identity: x.user.userIdentityAddress}))
        },
        
    })

    const {fields, append, remove} = useFieldArray<{councilMembers : {identity : string | null, username : string | null}[]}>({
        control,
        name: "councilMembers",
        
    })

   if(currentStep !== 0){
        return <></>
   }
   if(user?.identity === undefined){
    return <>
        <LoadingSpinner/>
    </>
   }

     const onSubmit = async ({councilMembers}: {councilMembers: {identity : string | null, username : string| null}[]}) => {
        if(!councilMembers || councilMembers.length === 0 || !organisation){
            toast.error("Please choose at least one council member.")
            return
        }
        console.log("Storing Council members inputs", councilMembers)
        const councilAddDeltaPromise = councilMembers.map(async ({identity, username}) => {
     
            if (!identity && username){
                if(!alignPrograms){
                    return
                }
                try {
                    const profile = await Api.fetchUserProfileByUsername(username, alignPrograms)
                    if (!organisation.councilManager?.councilMembers.find(mem => mem.user.username === profile.account.username)){
                        return {
                                identifier :profile?.account.identifier,
                                username : profile.account.username
                        }
                    }
                }
                catch(e){
                    console.warn(e)
                    return
                }
            }
        })

        const toAdd = await Promise.all(councilAddDeltaPromise)
        const toRemoveIdenitfiers = organisation.councilManager?.councilMembers.filter(x =>!councilMembers.map(id => id.identity).includes(x.user.userIdentityAddress)) || []
        const toRemovePrimses = toRemoveIdenitfiers.map(async ({user}) => {
            
                if(!alignPrograms){
                    return
                }
                try {
                    const profile = await Api.fetchUserProfileByUsername(user.username, alignPrograms)
                    if (organisation.councilManager?.councilMembers.find(mem => mem.user.username === profile.account.username)){
                        return {
                                identifier :profile?.account.identifier,
                                username : profile.account.username
                        }
                    }
                }
                catch(e){
                    console.warn(e)
                    return
                }
            
        })

        const toRemove = await Promise.all(toRemovePrimses)

       // TODO dedup
        console.log({
            toAdd,
            toRemove
        })

        const hasDuplicate = organisation.councilManager?.councilMembers?.filter((user, index) => toAdd.filter(x => x !== undefined).findIndex(u => u?.identifier? Derivation.deriveIdentityAddress(new PublicKey(u?.identifier)).toBase58() === user.user.userIdentityAddress : false) === index)

        if(hasDuplicate && hasDuplicate?.length > 0){
            toast.error("You have duplicate council users. Please remove duplicates before continuing.")
            return false
        }
        setCouncilInputs({
            toAdd: toAdd.filter(x => x !== undefined) as any,
            toRemove: toRemove.filter(x => x !== undefined) as any
        })
        onComplete(councilMembers)

    }

   
    return ( 
        <>

    <form onSubmit={handleSubmit(onSubmit)} 
            className="proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full md:w-4/6 p-6 lg:col-span-2 sm:p-6 bg-secondary border-boxWidth">
                <h1 className="font-heading text-lg md:text-3xl mb-6">
              Add Members
            </h1>
        <DescriptionPanel message="Please add your council members.
                The council will have authority over the treasury and config accounts within the DAO, 
                they also will particpate in reviewing proposals proposed by the community."/>

        <ul className="flex flex-col gap-4 mt-4">
            {fields.map((item, index) => (
                <li key={item.id} className="relative flex p-4 items-center gap-6 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center">
                    <Controller 
                        name={`councilMembers.${index}.username` as const}
                        control={control}
                        render={({ field}) => ( <>
                            {
                                
                             <UserInfo username={field.value} field={field}/> 

                            }
                        </>
                        )   
                 
                    }/>
                    {errors.councilMembers?.type === "validate" && <span className=" absolute bottom-1 right-2 text-xs text-red-500 ">Duplicate user</span>}                        
                    <XMarkIcon className="w-6 p-1 bg-contrast text-opacity-70 bg-opacity-10 rounded-full absolute top-1 right-1" type="button" onClick={() => remove(index)}/>

                </li>


            ))}

            <button
                className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                 
                  
              
                 
                transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                type="button"
                onClick={user?.identity?.identifier !== undefined ? () => append({identity: null, username : null}) : () => {}}
                >
                Add Council Member
            </button>
        </ul>

        <button
                className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                 
                  
              
                 
                transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                type="submit"
                
                >
                 <ForwardIcon className={`w-4 `}/>
                Next Step
        </button>
    </form>
    </>
     );
}
 
export default ProposalWizardGovernanceCouncilStep;