import { PROGRAM_ID } from "@metaplex-foundation/mpl-token-metadata";
import { BN, Program, Provider, web3 } from "@project-serum/anchor";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Keypair, PublicKey } from "@solana/web3.js";
import {
  Derivation,
  getMasterEditionAddress,
  getMetadataAddress,
  SequenceType,
  signSendAndConfirmTransactionsWithFees,
  TransactionInstructionWithSigners,
  Warp,
  WarpAccount,
  WarpIDL,
  WarpNftMetadata,
  WARP_PROGRAM_ID,
} from "align-sdk";
import toast from "react-hot-toast";

export const createWarpProvider = (provider?: Provider): Program<Warp> => {
  return new Program(WarpIDL, WARP_PROGRAM_ID, provider);
};

export const createWarp = async (
  fungibleMint: PublicKey,
  fungiblePerNft: BN,
  collectionMetadataDetails: WarpNftMetadata,
  collecitonMintAddress: Keypair,
  nfturi: string,
  warpProgram: Program<Warp>,
  wallet: AnchorWallet,
) => {
  if (
    !warpProgram?.provider?.publicKey ||
    !warpProgram?.provider?.sendAndConfirm
  ) {
    return;
  }
  const warp = Derivation.deriveWarpAddress(
    fungibleMint,
    collecitonMintAddress.publicKey,
  );
  const tx = await warpProgram.methods
    .createWarp(fungiblePerNft, collectionMetadataDetails, nfturi)
    .accounts({
      payer: warpProgram.provider.publicKey,
      authority: warpProgram.provider.publicKey,
      fungibleMint,
      collectionMint: collecitonMintAddress.publicKey,
      collectionMetadata: getMetadataAddress(collecitonMintAddress.publicKey),
      collectionMasterEdition: getMasterEditionAddress(
        collecitonMintAddress.publicKey,
      ),
      collectionTokenAccount: getAssociatedTokenAddressSync(
        collecitonMintAddress.publicKey,
        warpProgram.provider.publicKey,
      ),
      tokenMetadata: PROGRAM_ID,
      warp,
    })
    .transaction();

  const ixSet = [
    {
      instructionsSet: tx.instructions.map(
        (ix) =>
          new TransactionInstructionWithSigners(ix, [collecitonMintAddress]),
      ),
      sequenceType: SequenceType.Sequential,
    },
  ];
  const sigs = await signSendAndConfirmTransactionsWithFees({
    connection: warpProgram.provider.connection,
    wallet,
    transactionInstructions: ixSet,
    confirmLevel: "confirmed",
    extra: [],
    callbacks: {
      onError: (err) => toast.error(err),
    },
  });
  // const sig = await warpProgram.provider.sendAndConfirm(tx, [collecitonMintAddress], {skipPreflight: true})
  return sigs.sigs.length ? sigs.sigs[0] : null;
};

export const warpCoinIx = async (
  warp: PublicKey,
  nftMint: Keypair,
  warpProgram: Program<Warp>,
  owner?: Keypair,
) => {
  if (
    !warpProgram?.provider?.publicKey ||
    !warpProgram?.provider?.sendAndConfirm
  ) {
    return;
  }
  const authority = owner ? owner.publicKey : warpProgram.provider.publicKey;
  const signers = owner ? [nftMint, owner] : [nftMint];
  const warpAccount: WarpAccount = await warpProgram.account.warp.fetch(warp);
  return await warpProgram.methods
    .warpCoin()
    .accountsStrict({
      payer: warpProgram.provider.publicKey,
      authority: authority,
      nftMint: nftMint.publicKey,
      nftMetadata: await getMetadataAddress(nftMint.publicKey),
      nftMasterEdition: await getMasterEditionAddress(nftMint.publicKey),
      fungibleMint: warpAccount.fungibleMint,
      collectionMint: warpAccount.collectionMint,
      collectionMetadata: await getMetadataAddress(warpAccount.collectionMint),
      collectionMasterEdition: await getMasterEditionAddress(
        warpAccount.collectionMint,
      ),
      userFungibleTokenAccount: getAssociatedTokenAddressSync(
        warpAccount.fungibleMint,
        authority,
      ),
      nftTokenAccount: getAssociatedTokenAddressSync(
        nftMint.publicKey,
        authority,
      ),
      tokenMetadata: PROGRAM_ID,
      fungibleVault: Derivation.deriveFungibleVaultAddress(
        warp,
        nftMint.publicKey,
      ),
      warp,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      rent: web3.SYSVAR_RENT_PUBKEY,
      systemProgram: web3.SystemProgram.programId,
    })
    // .preInstructions([
    //     web3.ComputeBudgetProgram.setComputeUnitLimit({
    //       units: 500_000,
    //     })
    // ])
    .instruction();

  // const sig = await warpProgram.provider.sendAndConfirm(tx, signers)
  // return {sig, mint : nftMint.publicKey}
};

export const warpNft = async (
  warp: PublicKey,
  nftMint: PublicKey,
  warpProgram: Program<Warp>,
  owner?: Keypair,
) => {
  if (
    !warpProgram?.provider?.publicKey ||
    !warpProgram?.provider?.sendAndConfirm
  ) {
    return;
  }
  const authority = owner ? owner.publicKey : warpProgram.provider.publicKey;
  const signers = owner ? [owner] : [];

  const warpAccount: WarpAccount = await warpProgram.account.warp.fetch(warp);
  return await warpProgram.methods
    .warpNft()
    .accountsStrict({
      payer: warpProgram.provider.publicKey,
      authority: authority,
      nftMint,
      nftMetadata: await getMetadataAddress(nftMint),
      nftMasterEdition: await getMasterEditionAddress(nftMint),
      fungibleMint: warpAccount.fungibleMint,
      collectionMint: warpAccount.collectionMint,
      collectionMetadata: await getMetadataAddress(warpAccount.collectionMint),
      collectionMasterEdition: await getMasterEditionAddress(
        warpAccount.collectionMint,
      ),
      userFungibleTokenAccount: getAssociatedTokenAddressSync(
        warpAccount.fungibleMint,
        authority,
      ),
      nftTokenAccount: getAssociatedTokenAddressSync(nftMint, authority),
      tokenMetadata: PROGRAM_ID,
      fungibleVault: Derivation.deriveFungibleVaultAddress(warp, nftMint),
      warp,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      rent: web3.SYSVAR_RENT_PUBKEY,
      systemProgram: web3.SystemProgram.programId,
    })
    // .preInstructions([
    //   web3.ComputeBudgetProgram.setComputeUnitLimit({
    //     units: 500_000,
    // })
    // ])
    .instruction();

  // const sig = await warpProgram.provider.sendAndConfirm(tx, signers)
  // return sig
};
