import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { User } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";

import alignByPhaseLabs from "../assets/alignbyphaselabs.svg";
import * as Align from "../align.config.js";
import { useAuth, useCurrentOrganisation, usePfp, useUser } from "../state/hooks/useAlignGovernance";
import {useRecoilValueLoadable} from "recoil"
import { Suspense } from "react";
import { BellIcon, ChartPieIcon, ChatBubbleLeftEllipsisIcon, ChatBubbleOvalLeftEllipsisIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import Avatar from "./Avatar";
import { AlignUserProfileButton, AlignWalletConnectButton } from "./AlignWalletConnectButton";
import { NotificationDropDownButton } from "./NotificationsDropDown";
import { AlignLink } from "./AlignLink";
import { OrganisationBar } from "./OrganisationBar";
import { AnchorProvider, Program } from "@project-serum/anchor";
import { IDL } from "../idl/nft_faucet";
import { Keypair, SystemProgram, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, getAssociatedTokenAddressSync, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { getMasterEditionAddress, getMetadataAddress } from "align-sdk";
import { METADATA_PROGRAM_ID } from "../pages/Profile";
import LoadingSpinner from "./LoadingSpinner";
import AlignLogo from "./AlignLogo";
import {ChatButton} from "@dialectlabs/react-ui"
import { Inbox, ChatNavigationHelpers, useDialectUiId, useDialectSdk, Thread, useTheme } from "@dialectlabs/react-ui"



export function Navbar() {
    const navigate = useNavigate();
    const logoSrc = Align.logo ? Align.logo : alignByPhaseLabs;
    return (
        <div>
            <nav>
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8 py-8">
                    <div className="flex flex-wrap justify-between items-center py-5">
                        <div
                            className="relative flex cursor-pointer"
                            onClick={() => {
                                navigate("/");
                            }}
                        >   
                            <Suspense fallback={<img src={alignByPhaseLabs}/>}>
                                 <AlignLogo/>
                            </Suspense>
                            {/* <img
                                alt="align by phase labs"
                                className="w-[50px] object-cover object-left h-[40px] overflow-hidden sm:object-contain sm:h-16 max-w-[30vw] md:max-w-[240px] my-auto sm:w-auto"
                                src={logoSrc}
                            ></img>

                            {!Align.logo && (
                                <span className="absolute top-0 right-3 translate-x-full bg-accent text-contrast text-[10px] font-bold ml-3 px-2 py-1 rounded-full">ALPHA</span>
                            )} */}
                        </div>
                        <div className="flex space-x-2 sm:space-x-4">
                            <div className="">
                                <div className="flex gap-2 sm:gap-6">
                                
                                    <Suspense fallback={<div className="flex items-center justify-center bg-accent opacity-25 animate-pulse rounded-lg h-12 w-24 px-5"></div>}>
                                        <UserProfile></UserProfile>
                                    </Suspense>

                                    {/* <WalletMultiButton
                                        className="!bg-accent rounded-button border-primary text-contrast transition font-poppins font-bold p-3 ml-5"
                                        // style={{
                                        //     border: "1px solid var(--button-text-color) ",
                                        // }}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Suspense fallback={<>
                                            <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-60 z-40"/>
                                            <div className="flex justify-center items-center fixed w-screen h-screen left-0 top-0 z-50">
                                                <LoadingSpinner/>

                                            </div>
                                        </>
        }>  
                        <OrganisationBar />
                    </Suspense>
                </div>
            </nav>
        </div>
    );
}

export function UserProfile() {
    const userInfo = useAuth()
    const navigate = useNavigate()
    const {org} = useParams()
    const [currentOrg] = useCurrentOrganisation()
    const {connection} = useConnection()
    const wallet = useAnchorWallet()
    const orgAddress = currentOrg ? currentOrg : org

    const logoSrc = Align.logo ? Align.logo : alignByPhaseLabs;
    const { navigation, open, ui,  } = useDialectUiId<ChatNavigationHelpers>('messenger');
    const {colors} = useTheme()

    return (
        <>



            <div className="flex items-center gap-1 sm:gap-2">
                    {/* <button
                        className="flex items-center text-accent ml-5"
                        onClick={() => {
                            if(!wallet){
                                return
                            }
                            const provider = new AnchorProvider(connection, wallet, {
                                commitment: "confirmed",
                            });
                            const faucet = new Program(
                                IDL,
                                "6zL3nsMVSvcKGTbx9fG31AkXNfbxNhUvyne7PJsfj7Qd",
                                provider
                            );
                            const mint = new Keypair()

                            faucet.methods.mintNft().accountsStrict({
                                payer: wallet.publicKey,
                                owner: wallet.publicKey,
                                mint : mint.publicKey,
                                tokenAccount: getAssociatedTokenAddressSync(mint.publicKey, wallet.publicKey),
                                metadata: getMetadataAddress(mint.publicKey),
                                masterEdition: getMasterEditionAddress(mint.publicKey),
                                collectionMint: "",
                                collectionMetadata: "",
                                collectionMasterEdition: "",
                                collectionAuthorityRecord: "",
                                faucet: "",
                                metadataProgram: METADATA_PROGRAM_ID,
                                tokenProgram: TOKEN_PROGRAM_ID,
                                associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                                rent: SYSVAR_RENT_PUBKEY,
                                systemProgram: SystemProgram.programId
                            })
                            .rpc()
                        }}
                    >
                        Mint Test Nft
                    </button>
                     */}

                <Suspense>
                    {/* <AlignLink path="review" orgAddress={`${orgAddress}/`}> */}
                        <button
                            className="flex items-center text-accent rounded-full bg-contrast bg-opacity-20"
                        >
                            <ChatButton bellClassName="w-8 sm:w-10 text-contrast hover:bg-opacity-30 rounded-full !bg-contrast bg-opacity-20 p-2" dialectId="messenger"/>

                            {/* <ChatBubbleOvalLeftEllipsisIcon  className="w-8 sm:w-10 text-contrast hover:bg-opacity-30 rounded-full bg-contrast bg-opacity-20 p-2"/> */}
                        </button>
                    {/* </AlignLink> */}
                </Suspense>

                {/* {userInfo?.hasProfile && userInfo?.publicKey && (
                    <AlignLink path="review" orgAddress={`${orgAddress}/`}>
                        <button
                            className="flex items-center px-2 sm:px-3 text-contrast"
                        >
                            <ChartPieIcon  className="w-8 sm:w-10 text-contrast hover:bg-opacity-30 rounded-full bg-contrast bg-opacity-20 p-2"/>

                        </button>
                    </AlignLink> 
                )} */}
                    <Suspense>
                        <NotificationDropDownButton/>
                    </Suspense>

            </div>

                <Suspense fallback={<div className="flex items-center bg-accent rounded-lg h-12 w-fit px-5"><LoadingSpinner/></div>}>
                    <AlignUserProfileButton/>
                </Suspense>
            </>
            // )}
                    
        // </>
    );
}

