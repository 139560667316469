import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./align.custom.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';


import { BrowserRouter } from "react-router-dom";
import { getHeaders } from "./lib/auth";

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_ALIGN_API_URL}/api/gateway/graphql`,
  });
  
const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...getHeaders()
      }
    }
  });
const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
  });
  

window.Buffer = window.Buffer || require("buffer").Buffer;

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <ApolloProvider client={client}>
                    <App />
                </ApolloProvider>
            </BrowserRouter>
        </RecoilRoot>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
