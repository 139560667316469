export const fetchMultiCoinPrice = async (mints: string[]) => {
  try {
    const priceResponse = await fetch(
      `https://public-api.birdeye.so/public/multi_price?list_address=${mints.join()}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    );
    if (priceResponse.ok) {
      const priceJson: { data: Record<string, { value: number }> } =
        await priceResponse.json();
      return priceJson.data;
    } else {
      throw "";
    }
  } catch (e) {
    console.log("Issue fetching price for tokens", mints);
  }
};
