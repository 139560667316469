
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useAlignPrograms, useAuth } from "../state/hooks/useAlignGovernance";

import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Api, createUserProfile } from "align-sdk";
import { Tooltip } from "antd";
import { delay } from "lodash";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { CreateNftModal } from "../components/CreateNftModal";
import { REFRESH_DELAY } from "../constants";
import { nftsForWallet } from "../state/users";
import { HeliusGetAssetResponse } from "../types";



export function ProfileCreate() {
	const [modalPfpOpen, setModalPfpOpen] = useState(false);
    const wallet = useWallet()
	const { register, handleSubmit, getValues, formState: { errors } } = useForm();
	const [pfp, setPfp] = useState('');
	const [pfpImage, setPfpImage] = useState('');
	const [username, setUsername] = useState('');
	const [displayName, setDisplayName] = useState('');
	
	const [pfps, setPfps] = useState<any[]>([]);
    const nfts = useRecoilValue(nftsForWallet(wallet.publicKey?.toBase58() as string))
	const alignPrograms = useAlignPrograms()
	const navigate = useNavigate();
	const user = useAuth();
	useEffect(() => {
		if( !wallet?.publicKey ) return;
		if( user && user?.hasProfile ) {
			navigate(`/u/${wallet.publicKey?.toBase58()}/edit`);
		}
	},[navigate, user, wallet.publicKey]);
  

	useEffect(() => {
		if( !pfp || !nfts ) return;
		const currentPfp = nfts.find((p: HeliusGetAssetResponse) => p.id as any === pfp);
		if( currentPfp ) {
			setPfpImage(currentPfp?.content?.links.image as string)
		}
	},[pfp, nfts.length])

  
	const onSubmit = (data:any, e:any) => {
		if( !alignPrograms || !wallet.publicKey || !data.recovery) return;
		const createProfileTx = async() => {
			if( !wallet.publicKey ) return;
			const {identifierAddress} = await toast.promise(
				createUserProfile(
					"@" + data.handle.trim(),
					data.display_name.trim(),
					pfp ? new PublicKey(pfp) : undefined,
					wallet.publicKey,
					[],
					new PublicKey(data.recovery),
					alignPrograms
				), {
				loading: "Creating your profile...",
				success: "Profile created successfully!",
				error: (e) => e.toString(),
				});

			delay(async () => {
				await user?.refreshLoggedInUser()
				navigate(`/u/${identifierAddress.toBase58()}`);
			}, REFRESH_DELAY)
		}
		createProfileTx()
	}

	const handleChange = (data: any) => {
		const values = getValues();
		setUsername(values.username)
		setDisplayName(values.display_name)
	}

    if (!wallet?.publicKey || user?.hasProfile) {
        return <div />;
    }

    return (
        <div className="mx-auto max-w-screen-2xl px-8 py-8 text-contrast">
            <div className="flex-wrap justify-between gap-6 max-w-xl mx-auto">
                <main className="rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 w-full p-6 lg:col-span-2">
                    <h1 className="font-heading text-lg md:text-2xl mb-12">Create Profile</h1>
            
                    <div className="w-full">
						<>
							<div className="-mt-5 flex">
								<button className="hover:opacity-80" onClick={() => {
									setModalPfpOpen(true)
								}}>
									{pfpImage ? (
										<img
										className="h-24 w-24 rounded-full border border-white border-opacity-30"
										src={pfpImage}
										alt="avatar"
										/>
									) : (
										<span className="h-24 w-24 rounded-full flex items-center justify-center border relative border-white border-opacity-30">
											<svg className="w-12 h-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
										</span>
									)}
									
								</button>
								<p className="ml-3 mt-6 text-xl">
									<span className="block">
										{displayName ? displayName : (username ? username : '')}
									</span>
								</p>
							</div>

							<form onSubmit={handleSubmit(onSubmit)} onChange={handleChange} className="space-y-4 mt-4">							
								{/* include validation with required or other standard HTML validation rules */}
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
									<div>
										<label>Handle</label>
										{errors.handle?.type === 'pattern' && <span className="pl-3 text-xs text-red-500 ">Handle not valid</span>}
										{errors.handle?.type === 'isAvailable' && <span className="pl-3 text-xs text-red-500 ">Username is not available</span>}
										<div className="input">
											<label className="pr-2">@</label>
											<input {...register("handle", { 
												required: true,
												pattern: /^[a-z0-9_]{1,15}$/,
												validate: {
													isAvailable: async (val) => {
														if(!alignPrograms){
															return false
														}
														const userProfile = await Api.fetchUserProfileByUsername("@" + val, alignPrograms)
														return userProfile === null ? true : false
													} 
												}
												})} className="bg-transparent outline-none" placeholder="satoshi"/>
										</div>
										
									</div>

									<div>
										<label>Display Name</label>
										{errors.display_name?.type === 'pattern' && <span className="pl-3 text-xs text-red-500 ">Display name not valid</span>}

										<input {...register("display_name", { 
											required: true,
											pattern: /^(?=.*[A-Za-z0-9])[A-Za-z0-9_\-.,!?()\[\]{}<>"' ]{3,22}$/
											})} 
											className="input" placeholder="Satoshi Nakamoto"/>
									</div>
								</div>

								<div>
									<label className="flex items-center gap-2">Recovery Wallet Address
									<Tooltip title="Backup wallet so that you can prove ownership of your profile in case you lose access to your main wallet">
                        				<InformationCircleIcon className="w-4 text-opacity-10"/>
                    				</Tooltip>
									</label>
									<input {...register("recovery", { required: true })} className="input" placeholder=""/>
								</div>

								{/* errors will return when field validation fails  */}
								{errors.exampleRequired && <span>This field is required</span>}

								<button type="submit"  className="btn">Save Profile</button>
							</form>
						</>
                    </div>
				</main>
            </div>

			<Suspense>
				<PfpModal 
					onClose={() => setModalPfpOpen(false)} 
					pfps={nfts}
					setPfp={(pfp:any) => setPfp(pfp)} 
					show={modalPfpOpen}/>
          	</Suspense>
        </div>
    );
}
export const LoadingSkeleton = () => (
	<div className="w-32 h-auto bg-gray-200 animate-pulse rounded"></div>
  );

export function PfpModal({onClose, pfps, setPfp, show} : {onClose: () => void, pfps: HeliusGetAssetResponse[], setPfp: (pfp:string) => void, show : boolean}) {
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 12;
	const [imageLoaded, setImageLoaded] = useState<boolean[]>([]);
	const [showCreateNftModal, setShowCreateNftModal] = useState(false);
	const maxPage = useMemo(()=>Math.ceil(pfps.length / itemsPerPage), [itemsPerPage, pfps.length] );

	const getPageItems = () => {
	  const startIndex = (currentPage - 1) * itemsPerPage;
	  const endIndex = startIndex + itemsPerPage;
	  return pfps.slice(startIndex, endIndex);
	};
  
	const nextPage = () => {
	  if (currentPage < maxPage) {
		setCurrentPage(currentPage + 1);
	  }
	};
  
	const prevPage = () => {
	  if (currentPage > 1) {
		setCurrentPage(currentPage - 1);
	  }
	};
  
	useEffect(() => {
		setImageLoaded([]);
	  }, [currentPage]);

  
	return <div
	  id="defaultModal"
	  tabIndex={-1}
	  aria-hidden="true"
	  className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-secondary bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${show ? "" : "hidden"
		}`}
	>
	  <div className="relative w-full h-full max-w-2xl md:h-auto text-contrast">
		<div className="relative bg-black border border-white border-opacity-30 rounded-lg shadow text-contrast p-10">
		  <div className="flex items-start justify-between">
			<h3 className="text-xl font-semibold mb-6">
			  Pick PFP
			</h3>
			
			<button
			  onClick={() => onClose()}
			  type="button"
			  className="bg-transparent hover:opacity-90 text-sm p-1.5 ml-auto inline-flex items-center"
			  data-modal-toggle="defaultModal"
			>
			  <svg
				aria-hidden="true"
				className="w-5 h-5"
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			  >
				<path
				  fillRule="evenodd"
				  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
				  clipRule="evenodd"
				></path>
			  </svg>
			  <span className="sr-only">Close modal</span>
			</button>
		  </div>
		  <div className="grid grid-cols-4 gap-6">
		  {
			getPageItems()
				.filter((pfp: HeliusGetAssetResponse) => pfp?.content.metadata.name !== 'id')
				.map((pfp: HeliusGetAssetResponse, index: number) => {
				return (
					<button
					key={pfp.id}
					disabled={!pfp.grouping.find(x => x.group_key === "collection")?.group_value }
					className={`${!pfp.grouping.find(x => x.group_key === "collection")?.group_value  ? 'opacity-50 cursor-not-allowed' : ''}`}

					onClick={() => {
						//@ts-ignore
						setPfp(pfp.id);
						onClose();
					}}
					>
					{/* {!imageLoaded[index] && (
						<div className="w-32 h-32 flex items-center justify-center bg-contrast bg-opacity-25 animate-pulse">
							{truncateKey(pfp.id)}
						</div>
					)} */}
										<Tooltip 
						title={
							!pfp.grouping.find(x => x.group_key === "collection")?.group_value ? 
							"Nft's used in align ecosystem need to have a verified collection nft associated. Please contact the project owners to get them to add a collection nft."
							:  null
						}
					>
						<img
							src={pfp?.content?.links.image ? pfp?.content?.links.image : "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"}
							alt={pfp?.content.metadata.name}
							className={`w-32 h-auto ${!pfp.grouping.find(x => x.group_key === "collection")?.group_value  ? 'opacity-50' : ''}`}
							
						/>
					</Tooltip>
					</button>
				);
				})
			}
    	</div>
				<div className="flex items-center justify-center mt-4">
				<button onClick={prevPage} disabled={currentPage === 1} className="bg-transparent hover:opacity-90 rounded-lg p-2 mr-2">
					<span className="sr-only">Previous Page</span>
					&larr;
				</button>
				<span>Page {currentPage} of {maxPage}</span>
				<button onClick={nextPage} disabled={currentPage === maxPage} className="bg-transparent hover:opacity-90 rounded-lg p-2 ml-2">
					<span className="sr-only">Next Page</span>
					&rarr;
				</button>
				</div>
				<div className=" absolute right-2 bottom-2 text-sm w-full text-right font-extralight text-opacity-40 ">
				<label>Dont have an nft? Create one </label> <button onClick={() => setShowCreateNftModal(true)} className="text-accent">here</button>
				<CreateNftModal onClose={() => {setShowCreateNftModal(false); onClose()}} onSuccess={(mint) => setPfp(mint.toBase58()) } show={showCreateNftModal}				/>
			</div>
		</div>
	  </div>
  </div>
  
  }