import AlignLogo from "../components/AlignLogo"
import alignByPhaseLabs from "../assets/alignbyphaselabs.svg";

const Maintenance = () => {
    return ( <div className="flex flex-col min-h-screen justify-center items-center gap-10 mx-auto max-w-screen-2xl  md:px-8 px-1 py-1 text-contrast">
        
        <img
                alt="align by phase labs"
                className="w-[50px] object-cover object-left h-[40px] overflow-hidden sm:object-contain sm:h-16 max-w-[30vw] md:max-w-[240px] sm:w-auto"
                src={alignByPhaseLabs}
            >

            </img>
        <div className="text-center">
            <p className="font-light text-2xl mb-2 text-accent">We are currently down for maitenance.</p> Wont be long. Please try again soon.
        </div>
    </div> );
}
 
export default Maintenance;