import { sha256 } from "js-sha256";
import {
  AccountInfo,
  Keypair,
  ParsedAccountData,
  PublicKey,
} from "@solana/web3.js";
import {
  ALIGN_PROGRAM_ID,
  BEACON_PROGRAM_ID,
  IDENTIFIERS_PROGRAM_ID,
} from "./constants";
import { filterFactory } from "./filters";
import { Derivation } from "./pda";
import {
  Account,
  AlignPrograms,
  AuthorityConfigType,
  CollectionNftRecord,
  ContributionRecord,
  CouncilManager,
  CouncilVoteRecord,
  Identity,
  NativeTreasuryAccount,
  Organisation,
  OwnerRecord,
  Profile,
  Proposal,
  ProposalInstruction,
  ProposalTransaction,
  ReputationManager,
  TrackedTokenRecord,
  User,
  UserRecord,
  WalletConfig,
  WalletTracker,
} from "./types";
import { Spl, Wallet, web3 } from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID } from "@project-serum/anchor/dist/cjs/utils/token";
import { AccountLayout, getAccount, RawAccount } from "@solana/spl-token";
import { ClockworkProvider } from "@clockwork-xyz/sdk";
import { metadataBeet } from "@metaplex-foundation/mpl-token-metadata";
import { getMetadataAccount } from "./utils";

export namespace Api {
  export const fetchUserProfileByIdentifier = async (
    identifierAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<User>> => {
    const userProfileAddress =
      Derivation.deriveUserProfileAddress(identifierAddress);
    try {
      const profile: User =
        await programs.profilesProgram.account.user.fetch(userProfileAddress);
      return {
        address: userProfileAddress,
        account: profile,
      };
    } catch (e) {
      return null;
    }
  };

  export const fetchContributionRecord = async (
    countributionRecordAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ContributionRecord> | null> => {
    try {
      const contributionRecord: ContributionRecord =
        await programs.alignGovernanceProgram.account.contributionRecord.fetch(
          countributionRecordAddress,
        );

      return {
        address: countributionRecordAddress,
        account: contributionRecord,
      };
    } catch (e) {
      return null;
    }
  };

  export const fetchMulitpleCollectionNftRecord = async (
    collectionNftRecordAddresses: PublicKey[],
    programs: AlignPrograms,
  ): Promise<Array<Account<CollectionNftRecord | null>>> => {
    const records: Array<CollectionNftRecord | null> =
      (await programs.alignGovernanceProgram.account.collectionNftRecord.fetchMultiple(
        collectionNftRecordAddresses,
      )) as Array<CollectionNftRecord | null>;

    return records.map((record, i) => ({
      address: collectionNftRecordAddresses[i],
      account: record,
    }));
  };

  export const fetchContributiontRecord = async (
    collectionNftRecordAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<CollectionNftRecord> | null> => {
    try {
      const contributionRecord: CollectionNftRecord =
        await programs.alignGovernanceProgram.account.collectionNftRecord.fetch(
          collectionNftRecordAddress,
        );

      return {
        address: collectionNftRecordAddress,
        account: contributionRecord,
      };
    } catch (e) {
      return null;
    }
  };

  export const fetchCouncilVoteRecord = async (
    councilVoteRecordAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<CouncilVoteRecord> | null> => {
    try {
      const contributionRecord: CouncilVoteRecord =
        await programs.alignGovernanceProgram.account.councilVoteRecord.fetch(
          councilVoteRecordAddress,
        );

      return {
        address: councilVoteRecordAddress,
        account: contributionRecord,
      };
    } catch (e) {
      return null;
    }
  };

  export const fetchUserProfileByOwnerPubkey = async (
    ownerAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<User>> => {
    const ownerRecord: Account<OwnerRecord> = await fetchOwnerRecord(
      ownerAddress,
      programs,
    );
    const userProfile = await fetchUserProfileByIdentifier(
      ownerRecord.account.identifier,
      programs,
    );
    return userProfile;
  };

  export const fetchUsernameRecord = async (
    username: string,
    programs: AlignPrograms,
  ): Promise<Account<UserRecord>> => {
    const recordAddress = Derivation.deriveUsernameRecordAddress(username);

    const record: UserRecord =
      await programs.profilesProgram.account.username.fetch(recordAddress);

    return {
      address: recordAddress,
      account: record,
    };
  };

  export const fetchUserProfileByUsername = async (
    username: string,
    programs: AlignPrograms,
  ): Promise<Account<User>> => {
    try {
      const usernameRecord = await fetchUsernameRecord(username, programs);
      const userProfile: User =
        await programs.profilesProgram.account.user.fetch(
          usernameRecord.account.user,
        );
      return {
        address: usernameRecord.account.user,
        account: userProfile,
      };
    } catch (e) {
      console.warn(e);
      return null;
    }
  };

  export const fetchUserProfileByIdentityAddress = async (
    identityAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<User>> => {
    try {
      console.log(identityAddress);
      const identityAccount = await fetchIdentity(identityAddress, programs);
      console.log(identityAccount.account.identifier);
      const userProfile = await fetchUserProfileByIdentifier(
        identityAccount.account.identifier,
        programs,
      );
      return userProfile;
    } catch (e) {
      console.warn(e);
      return null;
    }
  };

  export const fetchOwnerRecord = async (
    ownerAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<OwnerRecord>> => {
    const ownerRecordAddress =
      Derivation.deriveOwnerRecordAddress(ownerAddress);
    const ownerRecord: OwnerRecord =
      await programs.identifiersProgram.account.ownerRecord.fetch(
        ownerRecordAddress,
      );
    return {
      address: ownerRecordAddress,
      account: ownerRecord,
    };
  };

  export const fetchAllUsers = async (
    programs: AlignPrograms,
  ): Promise<Account<Identity>[]> => {
    const proposalAccounts =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        IDENTIFIERS_PROGRAM_ID,
        {
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:Identity")).subarray(0, 8),
            ),
          ],
        },
      );

    const parsedAccountData = proposalAccounts.map((acc) => {
      const parsedAccountData: Identity =
        programs.identifiersProgram.coder.accounts.decode(
          "identity",
          acc.account.data as Buffer,
        ) as Identity;

      return {
        address: acc.pubkey,
        account: parsedAccountData,
      };
    });

    return parsedAccountData;
  };

  export const fetchAllJointUsers = async (
    organisationAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ReputationManager>[]> => {
    const repAccounts =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        ALIGN_PROGRAM_ID,
        {
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:ReputationManager")).subarray(
                0,
                8,
              ),
            ),
            filterFactory(40, organisationAddress.toBuffer()),
          ],
        },
      );

    const parsedAccountData = repAccounts.map((acc) => {
      const parsedAccountData: ReputationManager =
        programs.alignGovernanceProgram.coder.accounts.decode(
          "reputationManager",
          acc.account.data as Buffer,
        ) as ReputationManager;

      return {
        address: acc.pubkey,
        account: parsedAccountData,
      };
    });

    return parsedAccountData;
  };

  export const fetchOwnerRecordsByIdentifier = async (
    identifier: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<OwnerRecord>[]> => {
    const proposalAccounts =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        IDENTIFIERS_PROGRAM_ID,
        {
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:OwnerRecord")).subarray(0, 8),
            ),
            filterFactory(8, identifier.toBuffer()),
          ],
        },
      );

    const parsedAccountData = proposalAccounts.map((acc) => {
      const parsedAccountData: OwnerRecord =
        programs.identifiersProgram.coder.accounts.decode(
          "ownerRecord",
          acc.account.data as Buffer,
        ) as OwnerRecord;

      return {
        address: acc.pubkey,
        account: parsedAccountData,
      };
    });

    return parsedAccountData;
  };

  export const fetchOrganisationAddressesByCollections = async (
    collectionMints: PublicKey[],
    programs: AlignPrograms,
  ) => {
    const orgPromises = collectionMints.map((mint) =>
      programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        ALIGN_PROGRAM_ID,
        {
          dataSlice: { offset: 0, length: 0 },
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:Organisation")).subarray(0, 8),
            ),
            filterFactory(40, mint.toBuffer()),
          ],
        },
      ),
    );

    const orgAccounts = await Promise.all(orgPromises);

    return orgAccounts
      .flat()
      .map(
        (acc: { pubkey: PublicKey; account: AccountInfo<Buffer> }) =>
          acc.pubkey,
      );
  };

  export const fetchOrganisations = async (
    programs: AlignPrograms,
  ): Promise<PublicKey[]> => {
    const orgAccounts =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        ALIGN_PROGRAM_ID,
        {
          dataSlice: { offset: 0, length: 0 },
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:Organisation")).subarray(0, 8),
            ),
          ],
        },
      );

    return orgAccounts
      .flat()
      .map(
        (acc: { pubkey: PublicKey; account: AccountInfo<Buffer> }) =>
          acc.pubkey,
      );
  };

  export const fetchOrganisation = async (
    organisationAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<Organisation>> => {
    const org: Organisation =
      (await programs.alignGovernanceProgram.account.organisation.fetch(
        organisationAddress,
      )) as Organisation;
    return {
      address: organisationAddress,
      account: org,
    };
  };

  export const fetchOrgranisations = async (
    organisationAddresses: PublicKey[],
    programs: AlignPrograms,
  ): Promise<Account<Organisation>[]> => {
    const org: Organisation[] =
      (await programs.alignGovernanceProgram.account.organisation.fetchMultiple(
        organisationAddresses,
      )) as Organisation[];
    return org
      .map((org, i) => ({
        address: organisationAddresses[i],
        account: org,
      }))
      .filter((x) => x.account !== null);
  };

  export const fetchOrganisationProposals = async (
    organisation: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<Proposal>[]> => {
    const proposalAccounts =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        ALIGN_PROGRAM_ID,
        {
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:Proposal")).subarray(0, 8),
            ),
            filterFactory(8 + 1, organisation.toBuffer()),
          ],
        },
      );

    const parsedAccountData = proposalAccounts.map((acc) => {
      const parsedAccountData: Proposal =
        programs.alignGovernanceProgram.coder.accounts.decode(
          "proposal",
          acc.account.data as Buffer,
        ) as Proposal;

      return {
        address: acc.pubkey,
        account: parsedAccountData,
      };
    });

    return parsedAccountData;
  };

  export const fetchContributionRecordForProposal = async (
    proposal: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ContributionRecord>[]> => {
    const records =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        ALIGN_PROGRAM_ID,
        {
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:ContributionRecord")).subarray(
                0,
                8,
              ),
            ),
            filterFactory(8 + 32 + 32, proposal.toBuffer()),
          ],
        },
      );

    const parsedAccountData = records.map((acc) => {
      const parsedAccountData: ContributionRecord =
        programs.alignGovernanceProgram.coder.accounts.decode(
          "contributionRecord",
          acc.account.data as Buffer,
        ) as ContributionRecord;

      return {
        address: acc.pubkey,
        account: parsedAccountData,
      };
    });

    return parsedAccountData;
  };

  export const fetchOrganisationProposalKeys = async (
    organisation: PublicKey,
    filters: web3.MemcmpFilter[],
    programs: AlignPrograms,
  ): Promise<PublicKey[]> => {
    const proposalAccounts =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        ALIGN_PROGRAM_ID,
        {
          // Dont return data
          dataSlice: {
            offset: 0,
            length: 0,
          },
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:Proposal")).subarray(0, 8),
            ),
            filterFactory(8 + 1, organisation.toBuffer()),
            ...filters,
          ],
        },
      );

    const keys = proposalAccounts.map((acc) => acc.pubkey);

    return keys;
  };

  export const fetchIdentifiersVotesForProposal = async (
    userIdentifier: PublicKey,
    proposalAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ContributionRecord>> => {
    const contributionRecordAddress = Derivation.deriveContributionRecord(
      userIdentifier,
      proposalAddress,
    );

    const contributionRecord: ContributionRecord =
      await programs.alignGovernanceProgram.account.contributionRecord.fetch(
        contributionRecordAddress,
      );

    return {
      address: contributionRecordAddress,
      account: contributionRecord,
    };
  };

  export const fetchIdentifiersVotesForProposals = async (
    userIdentifier: PublicKey,
    proposalAddresses: PublicKey[],
    programs: AlignPrograms,
  ): Promise<Account<ContributionRecord>[]> => {
    const contributionRecordAddresses: PublicKey[] = proposalAddresses.map(
      (proposalAddress) =>
        Derivation.deriveContributionRecord(userIdentifier, proposalAddress),
    );

    const contributionRecords: ContributionRecord[] =
      (await programs.alignGovernanceProgram.account.contributionRecord.fetchMultiple(
        contributionRecordAddresses,
      )) as ContributionRecord[];

    return contributionRecords
      .map((record, i) => ({
        address: contributionRecordAddresses[i],
        account: record,
      }))
      .filter((x) => x.account !== null);
  };

  export const fetchIdentityInfo = async (
    userIdentifier: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<Identity>> => {
    const identityAddress = Derivation.deriveIdentityAddress(userIdentifier);
    const identity =
      await programs.identifiersProgram.account.identity.fetch(identityAddress);

    return {
      address: identityAddress,
      account: identity,
    };
  };

  export const fetchIdentity = async (
    identityAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<Identity>> => {
    const identity =
      await programs.identifiersProgram.account.identity.fetch(identityAddress);

    return {
      address: identityAddress,
      account: identity,
    };
  };

  export const fetchNativeTreasuryBalance = async (
    organisation: PublicKey,
    programs: AlignPrograms,
  ): Promise<number> => {
    const nativeTreasuryAddress =
      Derivation.deriveNativeTreasuryAddress(organisation);
    const balance =
      await programs.alignGovernanceProgram.provider.connection.getBalance(
        nativeTreasuryAddress,
      );
    return balance;
  };

  export const fetchIdentifiersReputationManager = async (
    identifier: PublicKey,
    organisation: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ReputationManager>> => {
    const identityAddress = Derivation.deriveIdentityAddress(identifier);

    const reputationManagerAddress = Derivation.deriveReputationManagerAddress(
      organisation,
      identityAddress,
    );
    try {
      const reputationManager: ReputationManager =
        await programs.alignGovernanceProgram.account.reputationManager.fetch(
          reputationManagerAddress,
        );
      return {
        address: reputationManagerAddress,
        account: reputationManager,
      };
    } catch (e) {
      return null;
    }
  };

  export const fetchProposal = async (
    proposalAddress: PublicKey,
    progams: AlignPrograms,
  ): Promise<Account<Proposal>> => {
    const proposal: Proposal =
      await progams.alignGovernanceProgram.account.proposal.fetch(
        proposalAddress,
      );
    return {
      address: proposalAddress,
      account: proposal,
    };
  };

  export const fetchProposals = async (
    proposalAddresses: PublicKey[],
    progams: AlignPrograms,
  ): Promise<Account<Proposal>[]> => {
    const proposals: Proposal[] =
      (await progams.alignGovernanceProgram.account.proposal.fetchMultiple(
        proposalAddresses,
      )) as unknown as Proposal[];

    return proposals.map((prop, i) => ({
      address: proposalAddresses[i],
      account: prop,
    }));
  };

  export const isThreadUnderfuned = async (
    threadAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<boolean> => {
    const provider = new ClockworkProvider(
      {
        signTransaction: async (tx: web3.Transaction) => tx,
        signAllTransactions: async (tx: web3.Transaction[]) => tx,
        payer: new Keypair(),
        publicKey: programs.alignGovernanceProgram.provider.publicKey,
      },
      programs.alignGovernanceProgram.provider.connection,
    );

    const threadAccount = await provider.getThreadAccount(threadAddress);
    const accountInfo =
      await programs.profilesProgram.provider.connection.getBalance(
        threadAddress,
      );
    return accountInfo < threadAccount.fee.toNumber();
  };

  export const fetchTrackedNftsByWallet = async (
    wallet: PublicKey,
    organisation: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<TrackedTokenRecord>[]> => {
    const beaconAddress = Derivation.deriveBeaconAddress(
      organisation,
      ALIGN_PROGRAM_ID,
    );
    const walletTracker = Derivation.deriveWalletTrackerAddress(
      beaconAddress,
      wallet,
    );

    const trackedTokenAccounts =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        BEACON_PROGRAM_ID,
        {
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:TrackedTokenRecord")).subarray(
                0,
                8,
              ),
            ),
            filterFactory(8, walletTracker.toBuffer()),
          ],
        },
      );

    const parsedAccountData = trackedTokenAccounts.map((acc) => {
      const parsedAccountData: TrackedTokenRecord =
        programs.beaconProgram.coder.accounts.decode(
          "trackedTokenRecord",
          acc.account.data as Buffer,
        ) as TrackedTokenRecord;

      return {
        address: acc.pubkey,
        account: parsedAccountData,
      };
    });

    return parsedAccountData;
  };

  export const fetchTrackedNftsByUserIdentifier = async (
    identifier: PublicKey,
    organisation: PublicKey,
    programs: AlignPrograms,
  ): Promise<PublicKey[]> => {
    const ownerRecords = await fetchOwnerRecordsByIdentifier(
      identifier,
      programs,
    );
    const wallets = ownerRecords.map((record) => record.account.account);
    const trackedTokenAccountPromises = wallets.flatMap((wall) =>
      fetchTrackedNftsByWallet(wall, organisation, programs),
    );

    const tokenRecords = await Promise.all(trackedTokenAccountPromises);

    const flatRecords = tokenRecords.flat();
    return flatRecords.map((record) => record.account.mint);
  };
  /**
   *
   * @param identifier user identifier
   * @param organisation
   * @param programs
   * @returns An array of mints that are not currently tracked for the user
   */
  export const fetchUntrackedTokensForUser = async (
    identifier: PublicKey,
    organisation: PublicKey,
    programs: AlignPrograms,
  ): Promise<PublicKey[]> => {
    const trackedNfts = await fetchTrackedNftsByUserIdentifier(
      identifier,
      organisation,
      programs,
    );
    const organisationAccount = await fetchOrganisation(organisation, programs);
    const tokenAccounts =
      await programs.alignGovernanceProgram.provider.connection.getParsedTokenAccountsByOwner(
        programs.profilesProgram.provider.publicKey,
        {
          programId: new PublicKey(TOKEN_PROGRAM_ID),
        },
      );

    const promises = tokenAccounts.value.map(async (acc) => {
      if (
        acc.account.data.parsed.info.tokenAmount.uiAmount !== 1 ||
        acc.account.data.parsed.info.tokenAmount.decimals !== 0
      ) {
        return;
      }
      try {
        const metadatas = getMetadataAccount(
          new PublicKey(acc.account.data.parsed.info.mint),
          programs.profilesProgram.provider.connection,
        );
        return metadatas;
      } catch (e) {
        return null;
      }
    });

    const metadatas = await Promise.all(promises);
    return metadatas
      .filter(
        (meta) =>
          (meta !== null || meta !== undefined) &&
          organisationAccount.account.config.collectionItems.findIndex(
            (coll) =>
              coll?.mint.toBase58() === meta?.collection?.key?.toBase58(),
          ) !== -1 &&
          meta.collection.verified,
      )
      .map((meta) => meta.mint)
      .filter(
        (mint) =>
          trackedNfts.findIndex((nft) => mint.toBase58() === nft.toBase58()) ===
          -1,
      );
  };

  export const fetchStakedNfts = async (
    identifierAddress: PublicKey,
    organisationAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<PublicKey[]> => {
    const identityAddress = Derivation.deriveIdentityAddress(identifierAddress);
    const reputationManagerAddress = Derivation.deriveReputationManagerAddress(
      organisationAddress,
      identityAddress,
    );
    const tokenAccounts: {
      pubkey: PublicKey;
      account: AccountInfo<Buffer | ParsedAccountData>;
    }[] =
      await programs.alignGovernanceProgram.provider.connection.getParsedProgramAccounts(
        TOKEN_PROGRAM_ID,
        {
          filters: [
            {
              dataSize: 165,
            },
            {
              memcmp: {
                offset: 32,
                bytes: reputationManagerAddress.toBase58(),
              },
            },
          ],
        },
      );

    return tokenAccounts.map(
      //@ts-ignore
      (acc) => new PublicKey(acc.account.data.parsed.info.mint),
    );
  };

  /**
   * Fetch council manager - lists current elected council memebers, election date etc
   * @param organisation
   * @param programs
   */
  export const fetchCouncilManager = async (
    organisation: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<CouncilManager>> => {
    const councilManagerAddress =
      Derivation.deriveCouncilManagerAddress(organisation);
    const councilManager: CouncilManager =
      await programs.alignGovernanceProgram.account.councilManager.fetch(
        councilManagerAddress,
      );

    console.log(councilManager);

    return {
      address: councilManagerAddress,
      account: councilManager,
    };
  };

  export const fetchCouncilVotesForProposal = async (
    organisationAddress: PublicKey,
    proposalAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<CouncilVoteRecord>[]> => {
    const manager = await fetchCouncilManager(organisationAddress, programs);
    const votesPromise = manager.account.councilIdentifiers.map(async (id) => {
      const recordAddress = await Derivation.deriveCouncilVoteRecord(
        id,
        proposalAddress,
      );
      try {
        const record: CouncilVoteRecord =
          await programs.alignGovernanceProgram.account.councilVoteRecord.fetch(
            recordAddress,
          );
        return {
          address: recordAddress,
          account: record,
        };
      } catch (e) {
        return {
          address: recordAddress,
          account: null,
        };
      }
    });

    const votes = await Promise.all(votesPromise);
    return votes;
  };

  export const fetchProposalTransactions = async (
    proposalAddress: web3.PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ProposalTransaction>[]> => {
    const proposal = await Api.fetchProposal(proposalAddress, programs);
    const transactionCount = proposal.account.transactionCount;

    const transactionAddresses = [...Array(transactionCount).keys()].map(
      (index) => Derivation.deriveTransaction(proposalAddress, index),
    );
    const transactionAccounts: ProposalTransaction[] =
      (await programs.alignGovernanceProgram.account.proposalTransaction.fetchMultiple(
        transactionAddresses,
      )) as ProposalTransaction[];

    const accounts = transactionAddresses.map((address, i) => ({
      address,
      account: transactionAccounts[i],
    }));

    return accounts;
  };

  export const fetchProposalTransactionInstructions = async (
    proposalTransactionAddress: web3.PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ProposalInstruction>[]> => {
    const proposalTransaction = await Api.fetchProposalTransaction(
      proposalTransactionAddress,
      programs,
    );
    const instructionCount = proposalTransaction.account.instructionCount;

    const instructionAddresses = [...Array(instructionCount).keys()].map(
      (index) =>
        Derivation.deriveProposalInstructionAddress(
          proposalTransactionAddress,
          index,
        ),
    );
    const instructionAccounts: ProposalInstruction[] =
      (await programs.alignGovernanceProgram.account.proposalInstruction.fetchMultiple(
        instructionAddresses,
      )) as ProposalInstruction[];

    const accounts = instructionAddresses.map((address, i) => ({
      address,
      account: instructionAccounts[i],
    }));

    return accounts;
  };

  export const fetchProposalInstruction = async (
    instructionAddress: web3.PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ProposalInstruction>> => {
    const instructionAccount: ProposalInstruction =
      (await programs.alignGovernanceProgram.account.proposalInstruction.fetch(
        instructionAddress,
      )) as ProposalInstruction;

    return {
      address: instructionAddress,
      account: instructionAccount,
    };
  };

  export const fetchProposalTransaction = async (
    transactionAddress: web3.PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<ProposalTransaction>> => {
    const transactionAccount: ProposalTransaction =
      await programs.alignGovernanceProgram.account.proposalTransaction.fetch(
        transactionAddress,
      );

    return {
      address: transactionAddress,
      account: transactionAccount,
    };
  };

  export const fetchWalletConfigs = async (
    organisation: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<WalletConfig>[]> => {
    const tokenGovernanceAccounts =
      await programs.alignGovernanceProgram.provider.connection.getProgramAccounts(
        ALIGN_PROGRAM_ID,
        {
          filters: [
            filterFactory(
              0,
              Buffer.from(sha256.digest("account:AuthorityConfig")).subarray(
                0,
                8,
              ),
            ),
            filterFactory(8 + 1, organisation.toBuffer()),
          ],
        },
      );

    const parsedAccountData = tokenGovernanceAccounts.map((acc) => {
      const parsedAccountData: WalletConfig =
        programs.alignGovernanceProgram.coder.accounts.decode(
          "authorityConfig",
          acc.account.data as Buffer,
        ) as WalletConfig;

      return {
        address: acc.pubkey,
        account: parsedAccountData,
      };
    });

    return parsedAccountData;
  };

  export const fetchWalletConfig = async (
    walletConfigAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<WalletConfig>> => {
    const account: WalletConfig =
      (await programs.alignGovernanceProgram.account.authorityConfig.fetch(
        walletConfigAddress,
      )) as WalletConfig;
    return {
      address: walletConfigAddress,
      account: account,
    };
  };

  /**
   * BEACON API
   */

  export const fetchWalletTracker = async (
    walletTrackerAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<WalletTracker>> | null => {
    try {
      const account: WalletTracker =
        await programs.beaconProgram.account.walletTracker.fetch(
          walletTrackerAddress,
        );
      return {
        address: walletTrackerAddress,
        account: account,
      };
    } catch (e) {
      return null;
    }
  };

  export const fetchTrackedTokenRecord = async (
    trackedTokenRecordAddress: PublicKey,
    programs: AlignPrograms,
  ): Promise<Account<TrackedTokenRecord>> | null => {
    try {
      const account: TrackedTokenRecord =
        await programs.beaconProgram.account.trackedTokenRecord.fetch(
          trackedTokenRecordAddress,
        );
      return {
        address: trackedTokenRecordAddress,
        account: account,
      };
    } catch (e) {
      return null;
    }
  };
}
