import { ForwardIcon } from "@heroicons/react/24/solid";
import { Account, WalletConfig } from "align-sdk";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { proposalWizardcouncilMembersStepInputs, proposalWizardGovernanceThresholdStepInputs } from "../../../state/forms";
import { useCurrentOrganisation, useOrganisation, useWalletConfigs } from "../../../state/hooks/useAlignGovernance";
import { WalletConfigDto } from "../../../types";
import { DescriptionPanel } from "../../DescriptionPanel";
import { WizardStepProps } from "../Wizard";

const ProposalWizardGovernanceSelectionStep = (
    {
        currentStep,
        onComplete
    }: WizardStepProps<{}>
) => {
    const [organisationAddress] = useCurrentOrganisation();
    const { organisation } = useOrganisation(organisationAddress);
    const [hasThresholdsChanged, setHasThresholdsChanged] = useState(false);


    const councilInputs = useRecoilValue(proposalWizardcouncilMembersStepInputs);
    const setGovernanceThresholdInputs = useSetRecoilState(proposalWizardGovernanceThresholdStepInputs);

    const councilCount = useMemo(() => organisation?.councilManager && councilInputs ? organisation?.councilManager.council_count + councilInputs?.toAdd.length - councilInputs?.toRemove.length : 0, [organisation?.councilManager?.council_count,  councilInputs]);

    // Checks if council has changed for form validation 
    const hasCouncilChanged = useMemo(
    () => councilCount !== organisation?.councilManager?.council_count,
    [councilCount, organisation?.councilManager?.council_count]
    );

    const { register, handleSubmit, formState: { errors }, control, getValues, reset } = useForm<{ thresholds: { walletConfig: WalletConfigDto, threshold: number, hasChanged: boolean }[] }>({
        defaultValues: {
            thresholds: organisation?.wallets.map(config => ({
                threshold: Math.ceil((config.threshold / 10000) * councilCount),
                walletConfig: config,
                hasChanged: false
            }))
        }
    });

    useEffect(() => {
        reset({
            thresholds: organisation?.wallets.map(config => ({
                threshold: Math.ceil((config.threshold / 10000) * councilCount),
                walletConfig: config,
                hasChanged: false
            }))
        });
    }, [councilCount, reset, organisation?.wallets]);

    const { fields } = useFieldArray<{ thresholds: { walletConfig: WalletConfigDto, threshold: number, hasChanged: boolean }[] }>({
        control,
        name: "thresholds"
    });


    const onSubmit = async ({ thresholds }: { thresholds: { walletConfig:WalletConfigDto, threshold: number, hasChanged: boolean }[] }) => {
        if (!organisation?.councilManager) {
            toast.error("Council members not found");
            return;
        }

        let thresholdDeltas = {
            thresholdChanges: thresholds.filter(x => Math.ceil((x.threshold / councilCount) * 10000) !== x.walletConfig.threshold).map(x => ({
                walletConfigAddress: x.walletConfig.address,
                newThreshold: (x.threshold / councilCount) * 10000,
                name: x.walletConfig.name
            }))
        };
        console.log("Setting Governance thresholds: ", thresholdDeltas);
        setGovernanceThresholdInputs(thresholdDeltas);

        onComplete({});
    };

    if (currentStep !== 1) {
        return <></>;
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                onError={(e) => toast.error(e.toString())}
                className="proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full md:w-4/6 p-6 lg:col-span-2 sm:p-6 bg-secondary border-boxWidth"
                >
                <h1 className="font-heading text-lg md:text-3xl mb-6">Set Governance Thresholds</h1>
                <DescriptionPanel message="Adjust governance thresholds for your organisations wallets." />
                <div className="my-4">
                    <label>Wallets</label>
                </div>
                <ul className="flex flex-col gap-4">
                    {fields.map((item, index) => (
                        <li
                            key={item.id}
                            className="relative flex p-4 items-center gap-6 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center"
                        >
                            <Controller
                                name={`thresholds.${index}.threshold` as const}
                                control={control}
                                render={({ field, formState }) => (
                                    <>
                                        <div className="p-4 flex w-full flex-col gap-4 bg-contrast bg-opacity-0">
                                            <label className="font-light">{item.walletConfig.name} Governance Threshold</label>
                                            <div className="flex w-full gap-4">
                                                <label className="text-contrast bg-contrast bg-opacity-10 p-1 px-2 rounded-md">
                                                    {field.value}/{councilCount}
                                                </label>
                                                <input
                                                    type="range"
                                                    step="1"
                                                    min="0"
                                                    max={`${councilCount}`}
                                                    {...field}
                                                    className="w-full bg-accent text-accent cursor-pointer"
                                                    defaultValue={`${field.value}`}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                        </li>
                    ))}
                </ul>
                <button
                    className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                    type="submit"
                >
                    <ForwardIcon className={`w-4`} />
                    Next Step
                </button>
            </form>
        </>
    );
};

export default ProposalWizardGovernanceSelectionStep;
