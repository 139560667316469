import { BN, utils, web3 } from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import {
  ALIGN_PROGRAM_ID,
  BEACON_PROGRAM_ID,
  CLOCKWORK_PROGRAM_ID,
  IDENTIFIERS_PROGRAM_ID,
  MULTIGRAPH_PROGRAM_ID,
  PROFILES_PROGRAM_ID,
  WARP_PROGRAM_ID,
} from "./constants";
import { ConnectionType, EdgeRelation } from "./types";
import { PROGRAM_ADDRESS } from "@metaplex-foundation/mpl-token-metadata";

export namespace Derivation {
  /**
   * +++++++ WARP ++++
   *
   */

  export const deriveWarpAddress = (
    fungibleMint: PublicKey,
    collectionMint: PublicKey,
  ) => {
    const [warp] = web3.PublicKey.findProgramAddressSync(
      [Buffer.from("warp"), fungibleMint.toBuffer(), collectionMint.toBuffer()],
      WARP_PROGRAM_ID,
    );
    return warp;
  };

  export const deriveFungibleVaultAddress = (
    warpAddress: PublicKey,
    nftMint: PublicKey,
  ) => {
    const [fungibleVault] = web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from("fungible-vault"),
        warpAddress.toBuffer(),
        nftMint.toBuffer(),
      ],
      WARP_PROGRAM_ID,
    );
    return fungibleVault;
  };

  export const deriveIdentityAddress = (identifierAddress: PublicKey) => {
    const [identity] = PublicKey.findProgramAddressSync(
      [Buffer.from("identity"), identifierAddress.toBuffer()],
      IDENTIFIERS_PROGRAM_ID,
    );
    return identity;
  };

  export const deriveCollectionNftRecordAddress = (
    identifierAddress: PublicKey,
    organisation: PublicKey,
    collection: PublicKey,
  ) => {
    const [orgAddress] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("collection-record"),
        organisation.toBuffer(),
        collection.toBuffer(),
        identifierAddress.toBuffer(),
      ],
      ALIGN_PROGRAM_ID,
    );
    return orgAddress;
  };

  export const deriveOrganisationAddress = (identifierAddress: PublicKey) => {
    const [orgAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("organisation"), identifierAddress.toBuffer()],
      ALIGN_PROGRAM_ID,
    );
    return orgAddress;
  };

  export const deriveCouncilManagerAddress = (
    organisationAddress: PublicKey,
  ) => {
    const [managerAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("council-manager"), organisationAddress.toBuffer()],
      ALIGN_PROGRAM_ID,
    );
    return managerAddress;
  };

  export const deriveWalletConfigAddress = (
    organisationAddress: PublicKey,
    seeds: Buffer,
  ) => {
    const [walletAuthorityConfigAddress] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("authority-config"),
        organisationAddress.toBuffer(),
        Uint8Array.from(seeds),
      ],
      ALIGN_PROGRAM_ID,
    );
    return walletAuthorityConfigAddress;
  };

  export const deriveWalletAddress = (
    walletAuthorityConfigAddress: PublicKey,
  ) => {
    const [walletAuthorityAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("authority"), walletAuthorityConfigAddress.toBuffer()],
      ALIGN_PROGRAM_ID,
    );
    return walletAuthorityAddress;
  };

  export const deriveElectionManagerAddress = (
    organisationAddress: PublicKey,
  ) => {
    const [electionManagerAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("election-manager"), organisationAddress.toBuffer()],
      ALIGN_PROGRAM_ID,
    );
    return electionManagerAddress;
  };

  export const deriveOwnerRecordAddress = (ownerAddress: PublicKey) => {
    const [ownerRecordAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("owner-record"), ownerAddress.toBuffer()],
      IDENTIFIERS_PROGRAM_ID,
    );
    return ownerRecordAddress;
  };

  export const deriveNodeAddress = (accountAddress: PublicKey) => {
    const [ownerRecordAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("node"), accountAddress.toBuffer()],
      MULTIGRAPH_PROGRAM_ID,
    );
    return ownerRecordAddress;
  };

  export const deriveEdgeAddress = (
    fromNodeAddress: PublicKey,
    toNodeAddress: PublicKey,
    connectionType: ConnectionType,
    edgeRelation: EdgeRelation,
  ) => {
    const [edgeAddress] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("edge"),
        fromNodeAddress.toBuffer(),
        toNodeAddress.toBuffer(),
        Uint8Array.from([connectionType]),
        Uint8Array.from([edgeRelation]),
      ],
      MULTIGRAPH_PROGRAM_ID,
    );
    return edgeAddress;
  };

  export const deriveReputationManagerAddress = (
    organisationAddresss: PublicKey,
    userIdentityAddress: PublicKey,
  ) => {
    const [reputationManagerAddress] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("reputation-manager"),
        organisationAddresss.toBuffer(),
        userIdentityAddress.toBuffer(),
      ],
      ALIGN_PROGRAM_ID,
    );
    return reputationManagerAddress;
  };

  export const deriveNativeTreasuryAddress = (organisation: PublicKey) => {
    const [nativeTreasuryAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("native-treasury"), organisation.toBuffer()],
      ALIGN_PROGRAM_ID,
    );
    return nativeTreasuryAddress;
  };

  export const deriveProposalAddress = (
    walletConfigAddress: PublicKey,
    index: BN,
  ) => {
    const [proposalAddress] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("proposal"),
        walletConfigAddress.toBuffer(),
        index.toArrayLike(Buffer, "le", 8),
      ],
      ALIGN_PROGRAM_ID,
    );
    return proposalAddress;
  };

  export const deriveNftVault = (
    userIdentifier: PublicKey,
    mintAddress: PublicKey,
  ) => {
    const [nftVault] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("nft-vault"),
        userIdentifier.toBuffer(),
        mintAddress.toBuffer(),
      ],
      ALIGN_PROGRAM_ID,
    );

    return nftVault;
  };

  export const deriveContributionRecord = (
    userIdentifier: PublicKey,
    proposalAddress: PublicKey,
  ) => {
    const [contributionRecord] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("contribution-record"),
        proposalAddress.toBuffer(),
        userIdentifier.toBuffer(),
      ],
      ALIGN_PROGRAM_ID,
    );

    return contributionRecord;
  };

  export const deriveUserProfileAddress = (userIdentifier: PublicKey) => {
    const [userProfile] = PublicKey.findProgramAddressSync(
      [Buffer.from("user"), userIdentifier.toBuffer()],
      PROFILES_PROGRAM_ID,
    );

    return userProfile;
  };

  export const deriveUsernameRecordAddress = (username: string) => {
    const [usernameRecord] = PublicKey.findProgramAddressSync(
      [Buffer.from("username"), utils.bytes.utf8.encode(username)],
      PROFILES_PROGRAM_ID,
    );

    return usernameRecord;
  };

  export const getMetadataAddress = async (mint: web3.PublicKey) => {
    const [address, bump] = await web3.PublicKey.findProgramAddress(
      [
        utils.bytes.utf8.encode("metadata"),
        new web3.PublicKey(PROGRAM_ADDRESS).toBuffer(),
        mint.toBuffer(),
      ],
      new web3.PublicKey(PROGRAM_ADDRESS),
    );

    return address;
  };

  export const getMasterEditionAddress = async (mint: web3.PublicKey) => {
    const [address, bump] = await web3.PublicKey.findProgramAddress(
      [
        utils.bytes.utf8.encode("metadata"),
        new web3.PublicKey(PROGRAM_ADDRESS).toBuffer(),
        mint.toBuffer(),
        utils.bytes.utf8.encode("edition"),
      ],
      new web3.PublicKey(PROGRAM_ADDRESS),
    );

    return address;
  };

  export const deriveCouncilVoteRecord = (
    councilIdentifier: web3.PublicKey,
    proposalAddress: web3.PublicKey,
  ) => {
    const [councilVoteRecord] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("council-vote-record"),
        proposalAddress.toBuffer(),
        councilIdentifier.toBuffer(),
      ],
      ALIGN_PROGRAM_ID,
    );

    return councilVoteRecord;
  };

  export const deriveTransaction = (
    proposalAddress: web3.PublicKey,
    transaction_index: number,
  ) => {
    const indexBuff = Buffer.alloc(4);
    indexBuff.writeInt32LE(transaction_index);

    const [transactionAccount] = PublicKey.findProgramAddressSync(
      [Buffer.from("transaction"), proposalAddress.toBuffer(), indexBuff],
      ALIGN_PROGRAM_ID,
    );

    return transactionAccount;
  };

  export const deriveProposalInstructionAddress = (
    transactionAddress: web3.PublicKey,
    instructionIndex: number,
  ) => {
    const [transactionAccount] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("instruction"),
        transactionAddress.toBuffer(),
        Uint8Array.from([instructionIndex]),
      ],
      ALIGN_PROGRAM_ID,
    );

    return transactionAccount;
  };

  /** Beacon */

  export const deriveTrackedTokenRecord = (
    walletTrackerAddress: web3.PublicKey,
    tokenAccountAddress: PublicKey,
  ) => {
    const [trackedTokenRecord] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("tracked-token-record"),
        walletTrackerAddress.toBuffer(),
        tokenAccountAddress.toBuffer(),
      ],
      BEACON_PROGRAM_ID,
    );

    return trackedTokenRecord;
  };

  export const deriveInstructionCallbackAddress = (
    trackedTokenRecordAddress: PublicKey,
  ) => {
    const [cbInstructionAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("instruction"), trackedTokenRecordAddress.toBuffer()],
      BEACON_PROGRAM_ID,
    );

    return cbInstructionAddress;
  };

  export const deriveThreadAddress = (
    walletTrackerAddress: web3.PublicKey,
    index: BN,
  ) => {
    const [threadAddress] = web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from("thread"),
        walletTrackerAddress.toBuffer(),
        Buffer.from(index.toString()),
      ],
      CLOCKWORK_PROGRAM_ID,
    );

    return threadAddress;
  };

  export const deriveBeaconAddress = (
    authorityAccount: web3.PublicKey,
    programId: PublicKey,
  ) => {
    const [beaconAddress] = web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from("beacon"),
        programId.toBuffer(),
        authorityAccount.toBuffer(),
      ],
      BEACON_PROGRAM_ID,
    );
    return beaconAddress;
  };

  export const deriveWalletTrackerAddress = (
    beaconAddress: web3.PublicKey,
    wallet: web3.PublicKey,
  ) => {
    const [walletTrackerAddress] = web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from("wallet-tracker"),
        beaconAddress.toBuffer(),
        wallet.toBuffer(),
      ],
      BEACON_PROGRAM_ID,
    );
    return walletTrackerAddress;
  };

  export const deriveAllCollectionNFTRecordAddress = (
    identifier: PublicKey,
    organisation: PublicKey,
    collections: PublicKey[],
  ) => {
    return collections.map((coll) =>
      deriveCollectionNftRecordAddress(identifier, organisation, coll),
    );
  };
}
