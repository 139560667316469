import { SolanaSignInOutput } from "@solana/wallet-standard-features";
import {
  createSignInMessage,
  SolanaSignInInputWithRequiredFields,
} from "@solana/wallet-standard-util";
import { PublicKey } from "@solana/web3.js";
import { decodeJwt } from "jose";

export const JWT_KEY = "align-token";
export const WALLET_KEY = "align-wallet";

export const REFRESH_TOKEN_KEY = "align-refreshToken";

export const getJwtToken = () => {
  return sessionStorage.getItem(JWT_KEY);
};

export const setJwtToken = (token: string) => {
  sessionStorage.setItem(JWT_KEY, token);
};

export const getWalletAddress = () => {
  return sessionStorage.getItem(WALLET_KEY);
};

export const isCurrentLoggedInWallet = (wallet: string) => {
  const currentWallet = getWalletAddress();
  if (currentWallet === wallet) {
    return true;
  }
  return false;
};

export const setWalletAddress = (walletAddress: string) => {
  sessionStorage.setItem(WALLET_KEY, walletAddress);
};

export const clearJwtToken = () => {
  sessionStorage.removeItem(JWT_KEY);
};

export const getRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setRefreshToken = (token: string) => {
  sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const getHeaders = () => {
  const headers = {} as Record<string, string>;
  const token = getJwtToken();
  if (token) headers.Authorization = `Bearer ${token}`;
  return headers;
};

export const fetchSiwsMessage = async (
  publicKey: PublicKey,
  domain: string,
): Promise<SolanaSignInInputWithRequiredFields> => {
  const res = await fetch(
    `${domain}/api/users/auth/swis/message?address=${publicKey?.toBase58()}`,
  );
  return res.json();
};

export const isJwtExpired = () => {
  const token = getJwtToken();

  if (token === null) {
    return true;
  }
  const { exp } = decodeJwt(token);
  console.log(exp);

  if (!exp) {
    console.warn("No expiry was set on jwt.");
    return false;
  }

  if (Date.now() >= exp * 1000) {
    return true;
  }

  return false;
};

export const verifySiwsResponse = async (
  input: SolanaSignInInputWithRequiredFields,
  output: SolanaSignInOutput,
  domain: string,
): Promise<{ access_token: string } | undefined> => {
  let strPayload = JSON.stringify({ input, output });

  const verifyResponse = await fetch(`${domain}/api/users/auth/swis/signin`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: strPayload,
  });

  const success = await verifyResponse.json();
  return success;
};

export const login = async (
  publicKey: PublicKey,
  signMessage: (message: Uint8Array) => Promise<Uint8Array>,
  domain: string,
) => {
  const input = await fetchSiwsMessage(publicKey, domain);
  //@ts-ignore
  input.domain = new URL(input.domain).host;

  const signedMessage = createSignInMessage(input);
  const signature = await signMessage(signedMessage);

  const output: SolanaSignInOutput = {
    signedMessage: Buffer.from(signedMessage),
    signature: Buffer.isBuffer(signature) ? signature : Buffer.from(signature),
    account: {
      publicKey: publicKey?.toBuffer(),
      address: publicKey.toBase58(),
      chains: [],
      features: [],
    },
  };
  console.log("signedMesasge", output);
  const success = await verifySiwsResponse(input, output, domain);
  if (!success) throw new Error("Sign In verification failed!");
  setJwtToken(success.access_token);
};

/**
 * This currently does not work due to our api for constructing a message ties it to the pubkey which
 * is not available at the time of calling this method due to the adapter not being connected untill
 * signin call has completed. Unsure if this is a design flaw or we have implemented something wrong, future fix.
 * @param publicKey
 * @param signIn
 * @param domain
 * @returns
 */
export const loginWithSiws = async (
  publicKey: PublicKey | null,
  signIn: (
    input: SolanaSignInInputWithRequiredFields,
  ) => Promise<SolanaSignInOutput> | undefined,
  domain: string,
) => {
  if (signIn === undefined || publicKey === null) return true;
  const input = await fetchSiwsMessage(publicKey, domain);
  //@ts-ignore
  input.domain = new URL(input.domain).host;
  const output = await signIn(input);
  if (!output) return true;
  const success = await verifySiwsResponse(input, output, domain);
  if (!success) throw new Error("Sign In verification failed!");
  setJwtToken(success.access_token);
  return false;
};
