import RadialGradient from "./components/RadialGradient";
import { FC, ReactNode, Suspense, useEffect, useMemo, useCallback, useState } from "react";
import {
    ConnectionProvider,
    WalletProvider,
    useConnection,
    useWallet,
} from "@solana/wallet-adapter-react";
import {
    BackpackWalletAdapter,
    BraveWalletAdapter,
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {DialectDappsIdentityResolver} from "@dialectlabs/identity-dialect-dapps"
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { Navbar } from "./components/Navbar";
import { Adapter, WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { Router } from "./Router";
import ThemeChanger from "./components/ThemeChanger";
import { Footer } from "./components/Footer";
import NotificationPopup from "./components/NotificationPopup";
import { Notifier } from "./components/Notifier";
import ErrorBoundary from "./components/ErrorBoundary";
import CustomStyler from "./components/CustomStyler";
import { SolanaSignInOutput, type SolanaSignInInput } from "@solana/wallet-standard-features";
import { SolanaSignInInputWithRequiredFields, createSignInMessage } from "@solana/wallet-standard-util";
import { PublicKey } from "@metaplex-foundation/js";
import { loginWithSiws, setJwtToken } from "./lib/auth";
/* App.tsx */

// Baseline styles for Dialect UIs
import '@dialectlabs/react-ui/index.css';

import { 
    DialectUiManagementProvider, 
    DialectThemeProvider, 
    DialectNoBlockchainSdk,
    ConfigProps,
    BottomChat,
    Chat,
    ChatButton,
    DialectCloudEnvironment
  } from '@dialectlabs/react-ui';
  import {
    DialectSolanaSdk,
    DialectSolanaWalletAdapter,
    SolanaConfigProps,
  } from '@dialectlabs/react-sdk-blockchain-solana';
import { solanaWalletToDialectWallet } from "./dialect/wallet";
import { AlignProfileIdentityResolver } from "./dialect/phaseIdentity";
import Maintenance from "./pages/Maintenance";

// Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");




const SdkProvider = (props : {children: ReactNode}) => {
    const solanaWallet = useWallet();
    const {connection} = useConnection()
    const [dialectSolanaWalletAdapter, setDialectSolanaWalletAdapter] =
      useState<DialectSolanaWalletAdapter | null>(null);
    
    // Basic Dialect-related configuration
    const dialectConfig: ConfigProps = useMemo(() => ({
      // general environment to target
      environment: process.env.REACT_APP_DIALECT_ENV! as DialectCloudEnvironment  || 'development',
      identity: {
        resolvers: [
            new AlignProfileIdentityResolver(connection),
            new DialectDappsIdentityResolver()
        ],
        strategy: "first-found"
      },

      dialectCloud: {
        // how to store/cache authorization token to make API calls
        tokenStore: 'session-storage',
      }
    }), []);
    
    // Solana-specific configuration
    const solanaConfig: SolanaConfigProps = useMemo(() => ({
      wallet: dialectSolanaWalletAdapter,
    }), [dialectSolanaWalletAdapter]);
      
    useEffect(() => {
      // `solanaWalletToDialectWallet` is a function that needs to be implemented by you.
      // See "Converting your wallet for Dialect" section below.
      setDialectSolanaWalletAdapter(solanaWalletToDialectWallet(solanaWallet));
    }, [solanaWallet]);
    
    // If our wallet has been initialized, then switch to Solana SDK provider
    if (dialectSolanaWalletAdapter) {
      return (
        <DialectSolanaSdk config={dialectConfig} solanaConfig={solanaConfig}>
          {props.children}
        </DialectSolanaSdk>
      );
    }
  
    return <DialectNoBlockchainSdk>{props.children}</DialectNoBlockchainSdk>;
  }
  
  const DialectProviders = ({ children }: {children : ReactNode}) => {
    return (
      <SdkProvider>
        {/* 'dark' | 'light' */}
        <DialectThemeProvider theme="dark" variables={{
          dark: {
            colors: {
              bg: "bg-secondary",
              textPrimary: "text-contrast",
              accent: "text-primary",
              notificationBadgeColor: "text-contrast bg-accent ",
              
            },
            textArea: "bg-secondary dt-text-sm dt-text-contrast dt-border dt-rounded-2xl dt-px-2 dt-py-1 dt-border-neutral-300 dt-placeholder-neutral-400 dt-pr-10 dt-outline-none disabled:dt-text-neutral-800/50",
            messageBubble: "dt-px-4 dt-py-2 dt-rounded-2xl text-contrast",
            avatar: "dt-rounded-full dt-items-center dt-justify-center bg-contrast/10 text-contrast"
          }
        }} >
          <DialectUiManagementProvider>
            {children}
          </DialectUiManagementProvider>
        </DialectThemeProvider>
      </SdkProvider>
    );
  }

export const App: FC = () => {

    const autoSignIn = useCallback(async (adapter: Adapter) => {
        if (!("signIn" in adapter)) return true;
        console.log(adapter)
        adapter.connect()

        return await loginWithSiws(adapter.publicKey, adapter?.signIn, process.env.REACT_APP_ALIGN_API_URL!!)
    }, []);
    
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(() => process.env.REACT_APP_SOL_RPC!, []);
    const isInMaintenance = process.env.REACT_APP_IS_IN_MAINTENANCE!
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new BraveWalletAdapter(),
            new GlowWalletAdapter(),
            new TorusWalletAdapter(),
            new BackpackWalletAdapter(),
        ],
        [network]
    );

    return (
        <div className="app-container bg-secondary">
            <ErrorBoundary>
             
                <RadialGradient className="fixed z-0 top-0 left-0 -translate-x-1/2 -translate-y-1/3 h-auto aspect-square object-cover"/>
                {/* <div className="bg-circle rounded-full bg-gradient-to-r from-accent/50 !fixed z-0 top-0 left-0 w-screen -translate-x-1/3 translate-y-16 aspect-square object-cover blur-[400px]"></div> */}
                <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect={autoSignIn}>
                        {/* <NotificationPopup/> */}
                        <div className="relative flex flex-col w-screen min-h-screen">
                            <WalletModalProvider>
                                <DialectProviders>
                                {isInMaintenance ? <Maintenance/> :
                                  <>
                                    <Navbar />
                                    <Suspense>
                                        <Notifier/>
                                    </Suspense>
                                    <div className="w-full">
                                      <Router /> 
                                        
                                    </div>
                                    <Footer />
                                  </>
                                }
                                </DialectProviders>
                            </WalletModalProvider>
                        </div>
                    </WalletProvider>
                </ConnectionProvider>
            </ErrorBoundary>
        </div>
    );
};
