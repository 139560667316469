import {
    User as UserIcon,
} from "react-feather";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CastVote } from "./CastVote";
import { useParsedTransactions, usePayoutAmount, useProposal, useProposalMetadata, useReputationValue, useUser } from "../state/hooks/useAlignGovernance";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import BN from "bn.js";
import { Account, canPushRankingState, getMetadataAddress, Proposal as AlignProposal, ProposalState, USDC_MINT_ADDRESS, BERN_MINT_ADDRESS } from "align-sdk";
import { metadataBeet } from "@metaplex-foundation/mpl-token-metadata";
import { AlignLink } from "./AlignLink";
import Avatar from "./Avatar";
import { timeLeft } from "../utils/time";
import { useCoinPrice } from "../state/hooks/useCoins";
import { prettyRoundedStringBalance, prettyStringBalance } from "../utils/coins";
import { ArrowPathRoundedSquareIcon, LockClosedIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ContributionRecordDto, ParseTransactionTypes } from "../types";
import { BuildingLibraryIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { ArrowsRightLeftIcon, BriefcaseIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { PublicKey } from "@metaplex-foundation/js";
import { Maybe, TransactionState, UserDto, useGetReputationQuery, useGetUserQuery } from "../generated/graphql";
import { ProposalTransaction, parseServicerPayoutAmount, parseTransactions, proposalPrettyState } from "../utils/alignHelpers";
import MintProfilePicture from "./MintProfilePicture";
import { Tooltip } from "antd";
export type Proposer = {
    __typename?: "UserDto" | undefined;
    userIdentityAddress?: string | null | undefined;
    username: string;
    pfp?: string | null | undefined,
    displayName: string;
    identity?: {
        __typename?: "IdentityDto" | undefined;
        identifier: string;
    } | null | undefined;
}
export function Proposal({
    address,
    rankingTotal,
    proposer,
    servicer,
    shadow,
    upvotes,
    downvotes,
    rankingPeriod,
    rankingAt,
    state,
    organisationAddress,
    hasRanked,
    contributionRecord,
    children,
    transactions
}: {
    address: string,
    state: string,
    proposer: Proposer | undefined | null,
    servicer: Proposer | undefined | null,
    rankingTotal: number,
    upvotes: number,
    downvotes: number,
    rankingPeriod: number,
    rankingAt: string | null | undefined,
    shadow: string,
    transactions: ProposalTransaction[]
    hasRanked: boolean,
    contributionRecord: ContributionRecordDto | null| undefined,
    organisationAddress: string,
    children?: ReactNode
}) {
    // const proposal = useProposal(address)
    const proposalMetadata = useProposalMetadata(address)
    const navigate = useNavigate();
    const { connection } = useConnection();
    // const proposalAuthor = useUser(proposal?.account.proposer.toBase58())
    // const authorReputation = useReputationValue(proposalAuthor?.identity?.identifier)
    // const proposerServicer = useUser(proposal?.account.servicer?.toBase58())
    const { org } = useParams()
    const { publicKey } = useWallet()

    const parsedTransactions = parseTransactions(transactions)

    const proposerUserReputatoinResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: proposer?.userIdentityAddress || "",
            organisationAddress
        },
        skip: proposer?.userIdentityAddress === undefined 

    })

    const containsUnknownTransactions = useMemo(() => parsedTransactions.filter(tx => tx.type === ParseTransactionTypes.Unknown).length > 0, [address, parsedTransactions])
    const containsCouncilManagerTransactions = useMemo(() => parsedTransactions.filter(tx => tx.type === ParseTransactionTypes.SetWalletThreshold || tx.type === ParseTransactionTypes.AddCouncilMember || tx.type === ParseTransactionTypes.RemoveCouncilMember).length > 0, [address, parsedTransactions])
    const containsCreateProfileInstruction = useMemo(() => parsedTransactions.filter((tx) => tx.type === ParseTransactionTypes.CreateProfile).length > 0, [address, parsedTransactions])
    const containsSetPfpInstruction = useMemo(() => parsedTransactions.filter((tx) => tx.type === ParseTransactionTypes.SetProfile).length > 0, [address, parsedTransactions])
    const containsTransfer = useMemo(() => parsedTransactions.filter((tx) => tx.type === ParseTransactionTypes.TokenTransfer || tx.type === ParseTransactionTypes.NativeTransfer).length > 0, [address, parsedTransactions])
    const containsJupiter = useMemo(() => parsedTransactions.filter(tx => tx.type === ParseTransactionTypes.JupiterSwap).length > 0, [address, parsedTransactions])
    const containsStake = useMemo(() => parsedTransactions.filter(tx => tx.type === ParseTransactionTypes.BonkRewardsDeposit).length > 0, [address, parsedTransactions])

    const payoutInfo = parseServicerPayoutAmount(transactions)
    
    const price = useCoinPrice(payoutInfo?.mint?.toBase58() ? payoutInfo?.mint.toBase58() : payoutInfo?.isNative ? "So11111111111111111111111111111111111111112" : undefined)

    const uiAmount = useMemo(() => {
        if (!payoutInfo) {
            return "0"
        }
        else if (payoutInfo.decimals === 0) {
            return payoutInfo.amount.toString()
        }
        else {
            return payoutInfo?.amount.div(new BN(Math.pow(10, payoutInfo?.decimals))).toString()
        }
    }, [payoutInfo])

    const usdValue = useMemo(() => price ? Math.round(Number(uiAmount) * 100 * price) / 100 : undefined, [price, uiAmount])

    const [coinMeta, setCoinMeta] = useState<{
        symbol: string,
        name: string,
        image: string,
    } | null>(null)

    // const finalizeProposal = async () => {
    // 	if( !alignPrograms || !proposal || !proposalAuthor || !proposalAuthor.identity) return;
    // 	// const finalizeProposalTx = async() => {
    // 		await toast.promise(
    // 			finalizeDraftProposal(
    // 				proposal.address,
    // 				proposal?.account.proposer,
    // 				alignPrograms
    // 			), {
    // 			loading: "Finalizing your proposal...",
    // 			success: "Proposal finalized successfully!",
    // 			error: "Failed to finalize proposal.",
    // 		});

    // 		navigate(`/u/${proposalAuthor.identity.address.toBase58()}`);
    // 	// }
    // }

    useEffect(() => {
        const fetchMeta = async () => {

            if (!payoutInfo) {
                return
            }
            try {
                if (payoutInfo?.isNative) {
                    setCoinMeta({
                        symbol: "SOL",
                        name: "Solana",
                        image: "/sol-logo.webp",
                    })
                    return
                }
                else if (payoutInfo?.mint) {
                    if (payoutInfo?.mint.toBase58() === USDC_MINT_ADDRESS.toBase58()) {
                        setCoinMeta({
                            symbol: "USDC",
                            name: "USDC",
                            image: "/usdc-logo.webp",
                        })
                        return
                    } else if (payoutInfo?.mint.toBase58() === BERN_MINT_ADDRESS.toBase58()) {
                        setCoinMeta({
                            symbol: "BERN",
                            name: "BERN",
                            image: "/bern.jpg",
                        })
                        return
                    }
                    const coinMetadataAddress = getMetadataAddress(payoutInfo.mint)

                    const accountInfo = await connection.getAccountInfo(
                        coinMetadataAddress
                    );

                    if (accountInfo) {
                        const [metadata] = metadataBeet.deserialize(accountInfo.data);
                        const response = await fetch(metadata.data.uri)
                        if (response.ok) {
                            const json = await response.json()
                            setCoinMeta(json)
                        }
                        return
                    }
                    return
                }

            }
            catch (e) {
                console.warn(e)
            }
        }
        fetchMeta()
    }, [payoutInfo?.amount.toString()])


    if (!proposalMetadata) return <></>;



    return (
        <>
            <div className="proposal-list-item my-3 flex w-full justify-between gap-2 sm:gap-4">
                <CastVote 
                   address={address}
                   rankingTotal={rankingTotal}
                   proposer={proposer}
                   state={state}
                   organisationAddress={organisationAddress}
                   contributionRecord={contributionRecord}
                />

                <div className="proposal-list-item_main box-container relative rounded-box border-boxWidth border-contrast border-opacity-30 flex-1 pl-4 py-3 pr-4 group hover:border-opacity-100 transition-all">

                    <div className="h-full items-stretch gap-6 md:flex">
                        <div className="flex-1">
                            <AlignLink path={`/proposal/${address}`} orgAddress={org} >
                                <div className="flex-1 space-y-2">
                                    <div className="flex justify-between items-start">

                                        <h2 className="text-base md:text-lg">
                                            {containsCouncilManagerTransactions ?
                                                "Governance Settings Proposal" : proposalMetadata?.name}

                                        </h2>
                                        <div className="flex gap-3">
                                            {containsCouncilManagerTransactions && <>
                                                <BuildingLibraryIcon className="w-6 " />
                                            </>}
                                            {containsJupiter && <>
                                                <Tooltip title="Jupiter treasury swap proposal">
                                                    <ArrowPathRoundedSquareIcon className="w-6"/>
                                                </Tooltip>
                                            </>}

                                            {(containsCreateProfileInstruction || containsSetPfpInstruction) && <>
                                                <UserCircleIcon className="w-6 " />
                                            </>}
                                            {servicer !== null && <>
                                                <BriefcaseIcon className="w-6 " />
                                            </>}
                                            {containsTransfer && <>
                                                <Tooltip title="Contains a token transfer">
                                                    <ArrowsRightLeftIcon className="w-6 " />
                                                </Tooltip>
                                            </>}

                                            {containsStake && <>
                                                <Tooltip title="Contains a locking of tokens for rewards">
                                                    <LockClosedIcon className="w-6 " />
                                                </Tooltip>
                                            </>}
                                            {containsUnknownTransactions && <>
                                                <ExclamationCircleIcon className="w-6 text-red-400 " />
                                            </>}
                                            <span className="bg-accent px-2 py-1 text-[10px] md:text-xs rounded-md">
                                                {proposalPrettyState(rankingPeriod, rankingAt, state)}
                                            </span>
                                        </div>
                                    </div>
                                    <p className="text-xs md:text-sm font-light opacity-75">
                                        {containsCouncilManagerTransactions ?
                                            "This is a proposal containing changes to the council and/or governance thresholds of DAO wallets"
                                            : proposalMetadata?.description.slice(0, 100)
                                        }
                                    </p>
                                    {
                                        state === "Ranking" &&
                                        rankingAt &&
                                        !canPushRankingState(rankingPeriod, rankingAt, state) &&
                                        <p className="text-sm">
                                            <span className="opacity-70">Ranking Period Ends:</span> <strong>{timeLeft(rankingAt, rankingPeriod)}</strong>
                                        </p>
                                    }

                                    {/* <p>Time Left: { dayjs(proposal?.account.rankingAt?.toString()).to(proposal?.account.rankingPeroid.toString()) }</p> */}
                                </div>
                            </AlignLink>
                        </div>


                        <div className="flex md:flex-col sm:flex-row justify-around sm:h-fit md:h-full md:w-36 sm:w-full md:items-start sm:items-center border-l-white border-opacity-30 md:border-l md:px-3">
                            <div className="flex justify-start gap-2 items-center">
                                {
                                    containsCouncilManagerTransactions ? <BuildingLibraryIcon /> : <>
                                        <img className="w-6 rounded-full" src={coinMeta?.image} alt="coin" />
                                        <p className="text-sm">{prettyRoundedStringBalance(uiAmount)}
                                            {/* {" "}{payoutInfo?.decimals === 0 ? coinMeta?.name : ""} */}
                                            {" "}{payoutInfo?.decimals === 0 ? "NFT" : ""}
                                            {usdValue !== undefined && <label className="text-sm font-extralight text-contrast text-opacity-60"> ~${usdValue}</label>}
                                        </p>
                                    </>
                                }

                            </div>
                            <div className="flex flex-col">
                                <p className="text-xs pt-1">Proposed By:</p>
                                <p className="text-xs text-primary">
                                    {proposer?.identity && <Link to={`/u/${proposer.username}`}>
                                        {proposer !== undefined && proposer?.username}
                                    </Link>}
                                </p>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="proposal-list-item_service md:h-full hidden md:block box-container rounded-box border-boxWidth border-contrast border-opacity-30 text-contrast min-w-[180px] w-40 px-4 py-3">
                    <Link to={`/u/${proposer?.username}`}>
                    <h4 className="mb-2 flex items-center gap-2 border-b border-b-white border-opacity-30 pb-2 text-sm">

                        {proposer?.identity && <MintProfilePicture mint={proposer?.pfp} width={8} />}
                        <div>{proposer !== undefined && proposer?.username.slice(1)}</div>
                    </h4>
                    </Link>
                    <p className="text-xs opacity-75">{proposerUserReputatoinResponse.data?.reputation.totalReputation} REP</p>
                    {/* <p className="text-xs opacity-75">12 PopHeadz</p> */}
                </div>

            </div>
            {children}
        </>


    );
}
