import React, { useMemo } from "react"
import { ReactNode, useState } from "react"

type WizardProps = {
    children: (props : WizardStepProps<any>) => JSX.Element
}

export interface WizardStepProps<T> {
    currentStep?: number,
    totalSteps?: number,
    onComplete: (data : T) => void,
    goBack: () => void
}

export function Wizard(
    { children }: WizardProps
){

    const [currentStep, setCurrentStep] = useState(0)

    

    const totalSteps = useMemo(() => {
        const tempProps: WizardStepProps<any> = {
            currentStep,
            onComplete: () => {},
            goBack: () => {}
        };

        const childrenElements = children(tempProps);
        
        return React.Children.count(childrenElements.props.children);
    }, [children, currentStep]);

    const nextStep = () => {
  
        setCurrentStep((prev) => prev + 1)
        
    }

    const previousStep = () => {
        if(currentStep === 0){
            return
        }

        setCurrentStep((prev) => prev - 1)
    }

    return(
        <div className="mx-auto max-w-screen-2xl h-full w-full px-1 md:px-8 pb-8 md:py-8 text-contrast">
            <div className="flex-wrap flex h-full justify-center gap-6 md:w-2/3 mx-auto">            
            {children({
                currentStep,
                totalSteps,
                onComplete : nextStep,
                goBack: previousStep
            })}
        </div>
        </div>
    )
}