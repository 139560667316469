import { useAuth } from "../state/hooks/useAlignGovernance";

const SignInButton = () => {
    const {signIn, isSignedIn} = useAuth()
    return ( 
        <>
            {
                !isSignedIn &&
                <>
                    <button 
                        className="flex bg-accent px-4 py-1 rounded-md text-sm hover:bg-opacity-90"
                        onClick={signIn}
                        >
                        Sign In to continue
                    </button>
                </>
                
            }
        </>
       
     );
}
 
export default SignInButton;