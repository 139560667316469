import { metadataBeet } from "@metaplex-foundation/mpl-token-metadata";
import { useConnection } from "@solana/wallet-adapter-react";
import { AccountMeta, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { canPushRankingState, Derivation, getMetadataAccount, getMetadataAddress, USDC_MINT_ADDRESS } from "align-sdk";
import { Tooltip } from "antd";
import BN from "bn.js";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import { ChevronDownIcon, ChevronUpIcon, ClipboardDocumentIcon, ArrowLeftIcon, FingerPrintIcon, HandThumbDownIcon, HandThumbUpIcon, DocumentTextIcon, QuestionMarkCircleIcon, ArrowPathRoundedSquareIcon, ChatBubbleLeftRightIcon, ShareIcon } from "@heroicons/react/24/outline";
import { delay, String } from "lodash";
import { isCouncilMember } from "../state/users";
import { useNavigate, useParams } from "react-router-dom";
import * as AlignConfig from "../align.config.js";
import { ActionPanel } from "../components/ActionPanel";
import { AlignLink } from "../components/AlignLink";
import CouncilProposalDetails from "../components/CouncilProposalDetails";
import { CouncilReviewProgress } from "../components/CouncilReviewProgress";
import { CouncilVoteProgress } from "../components/CouncilVoteProgress";
import { Iframe } from "../components/Iframe";
import { ProgressBar } from "../components/ProgressBar";
import { themeOpts } from "../components/ThemeChanger";
import { useAuth, useCurrentOrganisation, useCurrentUsersCouncilVoteRecord, useParsedTransactions, usePayoutAmount, usePfp, useProposal, useProposalMetadata, useReputationValue, useUser } from "../state/hooks/useAlignGovernance";
import { useCoinPrice } from "../state/hooks/useCoins";
import { CouncilVote, ParseTransactionTypes, ParsedProposalInstruction } from "../types";
import { prettyRoundedStringBalance, prettyStringBalance } from "../utils/coins";
import { truncateKey } from "../utils/truncateKey";
import { useGetOrganisationQuery, useGetProposalQuery, useGetProposalsQuery, useGetReputationQuery } from "../generated/graphql";
import MintProfilePicture from "../components/MintProfilePicture";
import { parseTransactions } from "../utils/alignHelpers";
import { useApolloClient } from "@apollo/client";
import LoadingSpinner from "../components/LoadingSpinner";
import { DecodedTransferCheckedInstruction, createAssociatedTokenAccountIdempotent, getAccount, getAssociatedTokenAddressSync, getMint, getOrCreateAssociatedTokenAccount } from "@solana/spl-token";
import { render } from "@testing-library/react";
import { Discussion } from "../components/Comment";
import Drawer from "../components/Drawer";
import { ProposalTransferSummary } from "../components/CreateProposalWizard/steps/sendAssets/ProposalWizardSendSummary";
import { formatDuration } from "../utils/time";

export const ProposalPageTransferSummary = ({
	instructions
}: { instructions: ParsedProposalInstruction[] }) => {
	const { connection } = useConnection()
	const transfersInstructions = useMemo(() => instructions
		.filter(x => x.type === ParseTransactionTypes.TokenTransfer || x.type === ParseTransactionTypes.NativeTransfer || 
			//@ts-ignore
			x.type === 163
		)
		, [instructions])
	const ataInstructions = useMemo(() => instructions
		.filter(x => x.type === ParseTransactionTypes.CreateAtaIdempotent), [instructions])
	const [transfers, settransfers] = useState<Record<string, {
		amount: string;
		decimals: number;
		recipient: string | number;
	}[]>>({});

	const [recipients, setRecipients] = useState<{
		id: number;
		walletAddress: string;
		name: string;
	}[]>([])

	useEffect(() => {
		const fetchRecipients = async () => {
			const recipientWallets = ataInstructions.map((ix: any) => ix?.keys.walletAddress.pubkey.toBase58());

			let recipients: {
				id: number;
				walletAddress: string;
				name: string;
			}[] = [];

			// Populate recipients from tokenAccounts
			recipientWallets.forEach(t => {
				const walletAddress = t;

				if (walletAddress && !recipients.some(r => r.walletAddress === walletAddress)) {
					recipients.push({
						id: Math.floor(Math.random() * 9999999),
						walletAddress: walletAddress,
						name: ""
					});
				}
			});

			// Populate recipients from transfersInstructions
			transfersInstructions.forEach(ix => {
				if (ix.type === ParseTransactionTypes.NativeTransfer) {
					const toWalletAddress = (ix.keys as any).toPubkey.pubkey.toBase58();

					if (!recipients.some(r => r.walletAddress === toWalletAddress)) {
						recipients.push({
							id: Math.floor(Math.random() * 9999999),
							walletAddress: toWalletAddress,
							name: "" // Or provide a name if available
						});
					}
				}
			});



			const transfersArray = transfersInstructions.map((ix: any) => {

				let recipientId = recipients.find(x => ix.type === ParseTransactionTypes.NativeTransfer ? (ix.keys.toPubkey.pubkey.toBase58() === x.walletAddress) : (ix.type === ParseTransactionTypes.TokenTransfer ? (getAssociatedTokenAddressSync(ix.keys.mint.pubkey, new PublicKey(x.walletAddress), true).toBase58() === ix.keys.destination.pubkey.toBase58()?.id) : (ix.keys.destination.pubkey.toBase58() === x.walletAddress)))
				if (!recipientId) {
					recipientId = {
						id: Math.floor(Math.random() * 9999999),
						walletAddress: ix.keys.destination.pubkey.toBase58(),
						name: ""
					};
					recipients.push(recipientId);
				}

				return {
					amount: ParseTransactionTypes.NativeTransfer === ix.type ? Math.floor(ix.data.amount.div(new BN(10).pow(new BN(9 - 2))).toNumber()) / 100 : ix.type === ParseTransactionTypes.TokenTransfer ? Math.floor(ix.data.amount.div(new BN(10).pow(new BN(ix.data.decimals - 2))).toNumber()) / 100 : 1,
					decimals: ix.data.decimals ?? 0,
					recipient: recipientId,
					mint: ix.keys?.mint?.pubkey?.toBase58() || "11111111111111111111111111111111"
				}

			})
			setRecipients(recipients)
			console.log(recipients)
			transfersArray.filter(t => t.recipient === undefined)
			const transfersRecord: Record<string, {
				amount: string;
				decimals: number;
				recipient: string | number;
			}[]> = {};

			transfersArray.forEach(transfer => {
				if (!transfersRecord[transfer.mint]) {
					transfersRecord[transfer.mint] = [];
				}
				console.log(transfer)
				transfersRecord[transfer.mint].push({
					amount: transfer.amount as unknown as string,
					decimals: transfer.decimals,
					recipient: transfer.recipient.id as number
				});
			});
			console.log(transfersRecord, recipients, transfersInstructions)
			settransfers(transfersRecord)
		}

		fetchRecipients()

	}, [transfersInstructions]);

	return <ProposalTransferSummary recipients={recipients} tokenAmounts={transfers} />
}

export const JupiterSwapInfo = ({ txInfo }: { txInfo: any }) => {
	const [swapMeta, setSwapMeta] = useState<any>(null)
	useEffect(() => {
		const getTokenSwapMeta = async () => {
			if (txInfo) {
				// console.log(txInfo)
				try {
					if (txInfo.keys[7] && txInfo.keys[8]) {
						const tokenData = await fetch('https://token.jup.ag/strict').then(res => res.json());
						const outTokenData = tokenData.find((token: any) => token.address === txInfo.keys[7].pubkey.toString());
						const inTokenData = tokenData.find((token: any) => token.address === txInfo.keys[8].pubkey.toString());

						setSwapMeta({
							treasuryAddress: txInfo.keys[2].pubkey.toString(),
							srcTokenAddress: txInfo.keys[7].pubkey.toString(),
							srcTokenData: outTokenData,
							destTokenAddress: txInfo.keys[8].pubkey.toString(),
							destTokenData: inTokenData,
							srcAmount: txInfo.data.inAmount.toString(),
							destAmount: txInfo.data.quotedOutAmount.toString(),
							slippage: txInfo.data.slippageBps,
							fees: txInfo.data.platformFeeBps
						})
					}
					return;
				}
				catch (e) {
					console.log(e)
				}
			}
		}
		getTokenSwapMeta()
	}, [txInfo])

	return (
		<div className="flex flex-col gap-2">
			<label className="text-sm font-light">Jupiter Swap</label>

			{swapMeta && swapMeta.srcTokenData && (
				<>
					<div className="flex flex-row gap-2 mt-4 mb-2 w-full">
						<div className="flex-1">
							<p className="text-xs mb-3"><strong>From:</strong></p>
							<div className="flex flex-col gap-2 bg-white bg-opacity-10 p-4 flex-1 rounded-md">
								{(swapMeta.srcAmount / (Math.pow(10, swapMeta.srcTokenData.decimals))).toFixed(2)}
								<div className="flex gap-2">
									<img className="w-8 rounded-full" src={swapMeta.srcTokenData?.logoURI} alt="" />
									<div className="flex flex-col">
										<label className="text-xs">{swapMeta.srcTokenData?.symbol}</label>
										<label className="text-xs font-light">{swapMeta.srcTokenData?.name}</label>
									</div>
								</div>
							</div>
						</div>
						<div className="flex-1">
							<p className="text-xs mb-3"><strong>To: <em className="font-light opacity-50">(quoted amount)</em></strong></p>
							<div className="flex flex-col gap-2 bg-white bg-opacity-10 p-4 flex-1 rounded-md">
								{(swapMeta.destAmount / (Math.pow(10, swapMeta.destTokenData.decimals))).toFixed(2)}
								<div className="flex gap-2">
									<img className="w-8 rounded-full" src={swapMeta.destTokenData?.logoURI} alt="" />
									<div className="flex flex-col">
										<label className="text-xs">{swapMeta.destTokenData?.symbol}</label>
										<label className="text-xs font-light">{swapMeta.destTokenData?.name}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-row gap-5 mt-0 mb-1 w-full">
						<p className="my-0"><small><span className="opacity-50">Slippage:</span> {swapMeta.slippage / 100}%</small></p>
						<p className="my-0"><small><span className="opacity-50">Fee:</span> {swapMeta.fees / 100}%</small></p>
					</div>
					<p className="my-0 mb-4">
						<small className="flex gap-2 items-center">
							<span className="opacity-50">Treasury Address:</span> {truncateKey(swapMeta.treasuryAddress)}
							<ClipboardDocumentIcon className="w-4 right-2 top-2 opacity-80 hover:opacity-100" onClick={() => {
								navigator.clipboard.writeText(swapMeta.treasuryAddress)
								toast.success("Copied to clipboard.")
							}
							} />
						</small>
					</p>
				</>
			)}
		</div>
	)
}

export function ProposalPage() {
	const [theme,] = useAtom(themeOpts);
	const { proposalAddress } = useParams();
	const { connection } = useConnection();
	const [currentOrgAddress, _] = useCurrentOrganisation()

	const user = useAuth()

	const organisation = useGetOrganisationQuery({
		variables: {
			address: currentOrgAddress || "",
			take: 0
		},
		skip: currentOrgAddress === undefined
	})

	const { data, loading, error, refetch } = useGetProposalQuery({
		variables: {
			address: proposalAddress || "",
			userIdentifier: user?.identity?.identifier || ""
		},
		skip: proposalAddress === undefined
	})

	const proposerUserReputatoinResponse = useGetReputationQuery({
		variables: {
			userIdentityAddress: data?.proposal.proposer?.userIdentityAddress || "",
			organisationAddress: currentOrgAddress || ""
		},
		skip: data?.proposal.proposer?.userIdentityAddress === undefined || currentOrgAddress === undefined
	})

	// const proposal = useProposal(proposalAddress)
	useEffect(() => {
		if (!data?.proposal) {
			return
		}
		const fetchMeta = async () => {
			const res = await fetch(
				`https://shdw-drive.genesysgo.net/${data.proposal.shadowDrive}/${data.proposal.address}.json`
			);
			if (res.status === 200) {
				const metadata: { name: string, description: string } = await res.json();
				setDescription(metadata?.description)
				setName(metadata?.name)
			}
			else {
				console.error(`Failed to fetch metadata for proposal ${proposalAddress}`)
			}
		}

		fetchMeta()

	}, [data?.proposal?.address])
	// const data.proposal.proposer = useUser(proposal?.account.proposer.toBase58())
	const payoutInfo = usePayoutAmount(proposalAddress)
	const [showDiscussion, setShowDiscussion] = useState(false);
	const price = useCoinPrice(payoutInfo?.mint?.toBase58() ? payoutInfo?.mint.toBase58() : payoutInfo?.isNative ? "So11111111111111111111111111111111111111112" : undefined)
	const parsedTransactions = parseTransactions(data?.proposal.proposalTransactions || [])
	const containsJupiter = useMemo(() => parsedTransactions.filter(tx => tx.type === ParseTransactionTypes.JupiterSwap).length > 0, [parsedTransactions])

	const jupTxInfo = useMemo(() => parsedTransactions.find(tx => tx.type === ParseTransactionTypes.JupiterSwap), [parsedTransactions])
	// const {councilVoteRecord, refreshVoteRecord} = useCurrentUsersCouncilVoteRecord(proposalAddress)

	// const authorReputation = useReputationValue(data.proposal.proposer?.identity?.identifier)
	const [previewMarkdown, setPreviewMarkdown] = useState(false)

	const [description, setDescription] = useState<string | undefined>(undefined);
	const [name, setName] = useState<string | undefined>(undefined);

	const hasPassedVote = data?.proposal?.executedAt !== null;

	const isAuthor = useMemo(() => data?.proposal && user?.identity && data.proposal.proposer?.identity?.identifier === (user?.identity?.identifier), [user?.publicKey, user?.identity, proposalAddress])

	const isInCouncilReview = useMemo(() => data && data.proposal.state === "Reviewing", [data?.proposal, user?.identity, proposalAddress])
	const isInCouncilVote = useMemo(() => data?.proposal &&
		(data?.proposal.state === "Ranking" &&
			data?.proposal.rankingAt &&
			canPushRankingState(data.proposal.rankingPeriod, data.proposal.rankingAt, data.proposal.state)) || data?.proposal.state === "Voting"
		, [data?.proposal, user?.identity, proposalAddress])

	const createProfileInstruction = useMemo(() => parsedTransactions.filter((tx) => tx.type === ParseTransactionTypes.CreateProfile), [proposalAddress, parsedTransactions])
	const setPfpInstruction = useMemo(() => parsedTransactions.filter((tx) => tx.type === ParseTransactionTypes.SetProfile), [proposalAddress, parsedTransactions])
	const councilMembers = useMemo(() => organisation.data?.organisation.councilManager?.councilMembers.map(x => x.user.userIdentityAddress) || [], [organisation?.data?.organisation])
	const containsUnknownTransactions = useMemo(() => parsedTransactions.filter(tx => tx.type === ParseTransactionTypes.Unknown), [proposalAddress, parsedTransactions])
	const containsCouncilManagerTransactions = useMemo(() => parsedTransactions.filter(tx => tx.type === ParseTransactionTypes.SetWalletThreshold || tx.type === ParseTransactionTypes.AddCouncilMember || tx.type === ParseTransactionTypes.RemoveCouncilMember).length > 0, [proposalAddress, parsedTransactions])
	const usersCounilVote = useMemo(() => data?.proposal.councilVotes.find(mem => mem.user.username === user?.username), [user?.username, data?.proposal.councilVotes])
	const stakeDepositInstructions = useMemo(() => parsedTransactions.filter((tx) => tx.type === ParseTransactionTypes.BonkRewardsDeposit), [proposalAddress, parsedTransactions])
	
	const uiAmount = useMemo(() => {
		if (!payoutInfo) {
			return "0"
		}
		else if (payoutInfo.decimals === 0) {
			return payoutInfo.amount.toString()
		}
		else {
			return payoutInfo?.amount.div(new BN(Math.pow(10, payoutInfo?.decimals))).toString()
		}
	}, [payoutInfo])

	const usdValue = useMemo(() => price ? Math.round(Number(uiAmount) * 100 * price) / 100 : undefined, [price, uiAmount])

	const [coinMeta, setCoinMeta] = useState<{
		symbol: string,
		name: string,
		image: string,
	} | null>(null)

	const navigate = useNavigate();


	useEffect(() => {
		const fetchMeta = async () => {

			if (!payoutInfo) {
				if (setPfpInstruction.length > 0) {
					const mint = setPfpInstruction[0].keys["6"] as AccountMeta

					const coinMetadataAddress = getMetadataAddress(mint.pubkey)

					const accountInfo = await connection.getAccountInfo(
						coinMetadataAddress
					);

					if (accountInfo) {
						const [metadata] = metadataBeet.deserialize(accountInfo.data);
						const response = await fetch(metadata.data.uri)
						if (response.ok) {
							const json = await response.json()
							setCoinMeta(json)
						}
						return
					}
					return
				}
				return
			}
			try {
				if (payoutInfo?.isNative) {
					setCoinMeta({
						symbol: "SOL",
						name: "Solana",
						image: "/sol-logo.webp",
					})
					return
				}
				else if (payoutInfo?.mint) {
					if (payoutInfo?.mint.toBase58() === USDC_MINT_ADDRESS.toBase58()) {
						setCoinMeta({
							symbol: "USDC",
							name: "USDC",
							image: "/usdc-logo.webp",
						})
						return
					}
					const coinMetadataAddress = getMetadataAddress(payoutInfo.mint, payoutInfo.tokenProgram)

					const accountInfo = await connection.getAccountInfo(
						coinMetadataAddress
					);

					if (accountInfo) {
						const [metadata] = metadataBeet.deserialize(accountInfo.data);
						const response = await fetch(metadata.data.uri)
						if (response.ok) {
							const json = await response.json()
							setCoinMeta(json)
						}
						return
					}
					return
				}

			}
			catch (e) {
				console.warn(e)
			}
		}
		fetchMeta()
	}, [payoutInfo?.amount.toString()])

	if (!data && loading) return <LoadingSpinner />;
	if (!data) return (
		<div className="mx-auto max-w-screen-2xl px-8 mb-6 text-contrast min-h-[calc(100vh-132px)]">
			Error loading proposal
		</div>
	)
	return (
		<div className="mx-auto max-w-screen-2xl md:px-8 px-1 mb-2 text-contrast min-h-[calc(100vh-132px)]">
			{/* <div className="flex-wrap justify-between gap-6 lg:grid lg:grid-cols-1"> */}
			{/* <main className={`proposals-container box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col w-full lg:col-span-2 ${theme.gridLayout && 'p-3 sm:p-6 bg-secondary bg-opacity-30 border-boxWidth'}`}> */}
			{/* <div className="flex items-start gap-8"> */}
			{/* <AlignLink path="/" orgAddress={currentOrgAddress}> */}
			<button onClick={() => navigate(-1)} className="mb-4 flex items-center gap-2">
				<ArrowLeftIcon className="w-4" /> Back to Proposals
			</button>
			{/* </AlignLink> */}
			<div className="proposal-container flex-wrap justify-between gap-6 lg:grid lg:grid-cols-3 grid-rows-1 mb-24">

				<main className={`proposal-main relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col w-full lg:col-span-2 ${theme.gridLayout && 'p-3 sm:p-6 py-3 bg-secondary bg-opacity-30 border-boxWidth'}`}>

					<div className="space-y-3 flex flex-col w-full  justify-center ">
						<div className="flex flex-wrap justify-between items-center">
							<h1 className="font-heading text-lg sm:text-xl md:text-2xl lg:text-4xl max-w-[22ch] flex">
								{containsCouncilManagerTransactions ? `Governance Configuration Proposal` : name}

							</h1>



						</div>

						<div>
							<hr className="opacity-30" />
							<div className="flex justify-start items-center gap-1 py-1">
								<div className="flex my-2 gap-2 items-stretch ">

									<div className="hover:cursor-pointer" onClick={() => data.proposal.proposer?.identity ? navigate(`/u/${data.proposal.proposer?.identity.identifier}`) : () => { }}>
										<div className="relative flex flex-col items-start w-full justify-center gap-1 rounded bg-transparent">
											{/* <p className="text-xs font-light">Proposed by:</p> */}

											<div className="flex gap-3 items-center justify-center px-3  rounded-full hover:opacity-90">
												{/* {authorPfp && authorPfp.pfp ? 
									<img alt="avatar" className="w-8 h-8 rounded-full" src={authorPfp.pfp}/> : 
									<span className="flex h-8 w-8 rounded-full items-center justify-center border relative border-white bg-white border-opacity-30">
										<svg className="w-12 h-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
									</span>
								} */}

												{data.proposal.proposer && (<MintProfilePicture mint={data.proposal.proposer?.pfp} width={8} />)}

												<div>
													<p className="text-white text-opacity-90 text-xs md:text-xs">
														{data.proposal.proposer?.displayName}
													</p>
													<p className="text-white text-opacity-70 text-[10px] md:text-xs">
														<a>{data.proposal.proposer?.username}</a>
													</p>
												</div>
												<p className="text-xs">
													{proposerUserReputatoinResponse.data?.reputation.totalReputation} {" REP"}
												</p>

											</div>

										</div>


									</div>
									{data.proposal.rankingAt && <div className="flex justify-center items-center text-sm  gap-2 w-fit px-2">
										{/* <p className="font-light">Proposed on{" "} </p>   */}
										<p className="bg-contrast text-xs font-light bg-opacity-10 px-2">{new Date(data.proposal.rankingAt).toDateString()}</p>
									</div>}

								</div>

								<div className="flex-1" />

								{payoutInfo?.amount && (
									<div className={`relative  flex items-start w-fit justify-center gap-3 pr-4 py-2 ml-2 px-3 rounded-full `}>
										<><div className="flex gap-2 justify-start items-center h-full">
											{coinMeta?.image ? <img
												className={` w-6 rounded-full`} src={coinMeta?.image} alt={coinMeta?.name}
											/> : <QuestionMarkCircleIcon className="w-9 text-sm rounded-full mr-1" />}
											<label className="text-sm">{prettyStringBalance(uiAmount)}{" "}</label>
											{usdValue && <label className="text-sm font-extralight text-contrast text-opacity-60"> ~${usdValue}</label>}

										</div>



										</>

									</div>
								)}
								<div className="h-full flex-wrap flex md:flex-row flex-col">
								<button onClick={() => setShowDiscussion(true)} className="relative hover:bg-opacity-10 hover:bg-contrast flex justify-center items-center w-10 h-10 p-2 rounded-full">
									<ChatBubbleLeftRightIcon className="w-8" />
									{data.proposal.commentCount > 0 ? <label className="absolute text-[9px] bg-accent px-1 rounded-full bottom-0 left-7 ">
										{data.proposal.commentCount < 100 ? data.proposal.commentCount : "99+"}
									</label> : <></>}
								</button>
								<button onClick={() => { }} className=" hover:bg-opacity-10 hover:bg-contrast flex justify-center items-center w-10 h-10 p-2 rounded-full">
									<ShareIcon className="w-8" />
								</button>
								</div>
							</div>

							<hr className="opacity-30" />
						</div>

						{
							containsCouncilManagerTransactions ? (
								<CouncilProposalDetails transactions={parsedTransactions} hasPassedVote={hasPassedVote} />
							) :

								<div className="flex-grow h-full leading-6 relative">
									{name && description !== undefined && isAuthor && data?.proposal.state === "Draft" && (
										<>
											<div className={`${previewMarkdown ? "opacity-90" : ""} hover:opacity-90 hover:cursor-pointer opacity-70 px-1 py-1 rounded-lg bg-accent absolute right-3 top-2`}>
												<Tooltip title="Preview Markdown">
													<DocumentTextIcon className={`w-5`} onClick={() => setPreviewMarkdown(!previewMarkdown)} />
												</Tooltip>
											</div>
											{previewMarkdown ?
												<Iframe style={{ color: "white", width: "100%", height: "100%", minHeight: '500px' }}>
													<ReactMarkdown className="markdown leading-6" children={description} />
												</Iframe>
												:
												<textarea
													value={description}
													placeholder={"# Proposal Description"}
													rows={4}
													onChange={(e) => setDescription(e.target.value)}
													className="flex-1 bg-transparent p-3 w-full min-h-full"
												/>
											}
										</>
									)}
									{
										name && description && (data?.proposal.state !== "Draft" || !isAuthor) && (
											<>
												<ReactMarkdown className="markdown prose max-w-none" children={description} />
											</>

										)
									}

								</div>

						}
						{/* 						
							{data.proposal.proposer && data.proposal.proposer.reputation && !!proposal?.account.state.draft && (
								<p>
									<button
									onClick={() => finalizeProposal()}
										className="w-auto mt-6 bg-accent rounded-button border-primary  transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
									>
									Finalize Proposal
									</button>
								</p>
							)} */}
					</div>





				</main>



				<aside className="proposal-aside w-full h-full lg:col-span-1 mt-4 lg:mt-0 flex flex-col gap-4">
					<div className="relative box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 p-5 text-contrast">
						<h3 className="text-xl font-semibold mb-4 flex gap-2">
							Information {containsJupiter && (
								<Tooltip title="Jupiter treasury swap proposal">
									<ArrowPathRoundedSquareIcon className="w-6" />
								</Tooltip>
							)}
						</h3>
						{(stakeDepositInstructions.length > 0 && (
							<div className="relative flex items-start justify-center gap-3 pl-4 pb-4 pt-3 bg-contrast bg-opacity-10 px-5 w-full rounded-md mb-2 ">
									<div className="flex flex-col gap-3">
										<label className="text-xs w-full text-center text-contrast text-opacity-80 font-extralight">Deposit Bonk Rewards</label>
										<div className="flex gap-6 justify-center">
											<img className="w-12 rounded-full" src="/bonk.webp" />
											<div className="flex flex-col justify-center">
												{/* {Object.entries(stakeDepositInstructions[0].data).reverse().map(([key, val], i) => ( */}
												<label className={"text-xs text-contrast text-opacity-60 font-light"}>Amount</label>
												<label className={"text-sm text-contrast font-light"}>{(prettyRoundedStringBalance((stakeDepositInstructions[0].data as any).amount.div(new BN(100_000))?.toString()))}</label>
												{/* ))} */}
											</div>

												<div className="flex flex-col justify-center">
												{/* {Object.entries(stakeDepositInstructions[0].data).reverse().map(([key, val], i) => ( */}
												<label className={"text-xs text-contrast text-opacity-60 font-light"}>Duration</label>
												<label className={"text-sm text-contrast font-light"}>{formatDuration((stakeDepositInstructions[0].data as any).lockupDuration.toNumber())}</label>
												{/* ))} */}
											</div>

										</div>
									</div>

								</div>
						))}
						{(createProfileInstruction.length > 0 && setPfpInstruction.length > 0) && (
								<div className="relative flex items-start justify-center gap-3 pl-4 pb-4 pt-3 bg-contrast bg-opacity-10 px-5 w-full rounded-md mb-2 ">
									<div className="flex flex-col gap-3">
										<label className="text-xs w-full text-center text-contrast text-opacity-80 font-extralight">Proposed Organisation Profile</label>
										<div className="flex gap-3 justify-center">
											<img className="w-12 rounded-full" src={coinMeta?.image} />
											<div className="flex flex-col justify-center">
												{Object.entries(createProfileInstruction[0].data).reverse().map(([key, val], i) => (
													<label className={`${i > 0 ? "text-xs text-contrast text-opacity-60 font-light" : "text-sm"}`}>{val?.toString()}</label>
												))}
											</div>

										</div>
									</div>

								</div>
							)}


						{jupTxInfo && <JupiterSwapInfo txInfo={jupTxInfo} />}
						<ProposalPageTransferSummary instructions={parsedTransactions} />


						{/* <label>Payout</label> */}
						<div className="flex gap-3 flex-wrap">
							{/* 						
						{ payoutInfo?.amount && !containsJupiter && (
							<div className={`relative flex items-center w-48 justify-start gap-3 pl-4 py-2 rounded border  border-gray-700 `}>
								<>
									<div className="flex justify-start items-center h-full w-1/5">
										{coinMeta?.image ? <img className={` w-9 rounded-full`} src={coinMeta?.image} alt={coinMeta?.name}/> : <QuestionMarkCircleIcon className="w-9 text-sm rounded-full"/>}
									</div>
									
									<div className="flex flex-1 gap-0 flex-col">
										<label className="opacity-75 text-xs mt-0 font-light w-full text-left">{truncateKey(Derivation.deriveWalletAddress(new PublicKey(data.proposal?.authorityConfigAddress)).toBase58())}</label>
										<label className="text-sm">{uiAmount}</label>
									</div>
									<div className="self-start w-5">
										<ClipboardDocumentIcon className="w-4 absolute right-2 top-2 opacity-80 hover:opacity-100" onClick={() => {
											navigator.clipboard.writeText(Derivation.deriveWalletAddress(new PublicKey(data.proposal?.authorityConfigAddress)).toBase58())
											toast.success("Copied to clipboard.")
											}
											}/>
									</div>
								</>
							</div>
						)} */}


							<div className="flex flex-wrap w-2/5 gap-4">
								<label className="mt-3 font-bold flex flex-row gap-3 flex-wrap">Proof of completion</label>
								{
									data.proposal.serviceProofs.map((proof: string) => (
										<a className="px-2 py-1 rounded-full flex items-center justify-center gap-1 bg-contrast hover:bg-accent bg-opacity-20 h-fit hover:cursor-pointer"
											target="_blank"
											href={
												proof.slice(0, 7) !==
													"http://" &&
													proof.slice(0, 8) !==
													"https://"
													? "https://" + proof
													: proof
											}>

											<FingerPrintIcon className="w-7" />
											<label>Item 1</label>
										</a>
									))
								}

							</div>
							<div className={`absolute top-4 right-4 flex items-center w-fit justify-start gap-3 px-4 py-1 text-sm rounded-full font-bold bg-accent`}>
								{/* <label className=""></label> */}
								<label>{data && data.proposal.state === "Ranking" && data.proposal.rankingAt && canPushRankingState(data.proposal.rankingPeriod, data.proposal.rankingAt, data.proposal.state) ?
									(AlignConfig.strings.council ? `${AlignConfig.strings.council} Voting` : 'Council Voting') : data.proposal.state}</label>
							</div>

						</div>
					</div>

					{isInCouncilReview && data && (
						<CouncilReviewProgress
							walletConfig={data.proposal.authorityConfigAddress}
							userVoteDirection={undefined}
							councilVoteRecord={data.proposal.councilVotes}
						/>
					)}

					<div className={`box-container ${isInCouncilVote ? "" : "opacity-40"} rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 p-5 text-contrast`}>
						<div className="flex flex-col items-start justify-center">
							<label className="text-lg block font-semibold mb-4">{AlignConfig.strings.council ? AlignConfig.strings.council : 'Council'} Voting</label>

							{data && <CouncilVoteProgress
								proposalState={data.proposal.state}
								councilYesVotes={data.proposal.totalCouncilYesVotes}
								councilNoVotes={data.proposal.totalCouncilNoVotes}
								councilAbstainVotes={data.proposal.totalCouncilAbstainVotes}
								walletConfig={data.proposal.authorityConfigAddress}
								// userVoteDirection={councilVoteRecord?.account.vote?.yes ? CouncilVote.Yes : councilVoteRecord?.account.vote?.no ? CouncilVote.No : undefined }
								userVoteDirection={usersCounilVote?.vote as CouncilVote}
								councilVoteRecord={data.proposal.councilVotes}
							/>}
						</div>
					</div>

					<div className={`community-ranking-container box-container ${data.proposal.state === "Ranking" ? "" : "opacity-40"} rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 p-5 text-contrast`}>
						<div className={`flex w-full  flex-col items-between justify-start`}>

							<label className="text-lg block mt-1 font-semibold">Community Ranking</label>
							<div className="w-full flex flex-col justify-center">
								<div className="flex justify-between mt-3 mb-3 ">
									<div className="flex flex-col">
										<label className="text-sm opacity-70 uppercase font-light mb-1">Upvotes</label>
										<label className="flex items-center gap-1 text-lg">{data.proposal.upvotes}
											{data.proposal.upvotes > 0 && (
												<p className="text-sm">{`(${Math.floor((data.proposal.upvotes / (data.proposal.downvotes + data.proposal.upvotes)) * 10000) / 100} %)`}</p>
											)}
										</label>
										<label></label>
									</div>
									<div className="flex justify-center items-center gap-2 w-fit h-auto mt-3 text-lg ">
										{data.proposal.upvotes - data.proposal.downvotes >= 0 ?
											<ChevronUpIcon className="w-5  text-green-400 rounded-md border border-green-400 " />
											: <ChevronDownIcon className="w-5 text-red-400 rounded-md border border-red-400 " />
										}
										<p className={data.proposal.rankingTotal >= 0 ? "text-green-400 font-normal text-xl" : "text-red-400 font-normal text-xl"}>{data.proposal.rankingTotal}</p>

									</div>
									<div className="flex flex-col">
										<label className="text-sm opacity-70 uppercase font-light mb-1">Downvotes</label>
										<label className="flex items-center justify-end gap-1 text-lg">{data.proposal.downvotes.toString()}
											{data.proposal.downvotes > 0 && (
												<p className="text-sm font-light">{`(${Math.floor((data.proposal.downvotes / (data.proposal.downvotes + data.proposal.upvotes)) * 10000) / 100} %)`}</p>
											)}
										</label>
									</div>
								</div>
								{data && <ProgressBar yesVotes={data.proposal.upvotes} noVotes={data.proposal.downvotes} />}

							</div>

						</div>

					</div>
					<Drawer title="Discussion" onClose={() => setShowDiscussion(false)} open={showDiscussion} position="right">
						<Discussion proposalAddress={proposalAddress} organisationAddress={data.proposal.organisationAddress} councilMembers={councilMembers} />
					</Drawer>


					{description !== undefined && name !== undefined &&
						<ActionPanel
							proposalAddress={proposalAddress}
							metadata={{ name, description }}
							state={data.proposal.state}
							proposer={data.proposal.proposer}
							servicer={data.proposal.servicer}
							rankingTotal={data.proposal.rankingTotal}
							upvotes={data.proposal.upvotes}
							downvotes={data.proposal.downvotes}
							rankingPeriod={data.proposal.rankingPeriod}
							rankingAt={data.proposal.rankingAt}
							shadow={data.proposal.shadowDrive}
							transactions={data.proposal.proposalTransactions}
							hasRanked={data.proposal.hasRankVotes.hasRanked}
							councilVotes={data.proposal.councilVotes}
							contributionRecord={data.proposal.hasRankVotes.contributionRecord}
							organisationAddress={data.proposal.organisationAddress}
							serviceProofs={data.proposal.serviceProofs}
							refetchProposal={refetch}
							hasGovernanceTransaction={containsCouncilManagerTransactions}
						/>}


				</aside>



			</div>

			{/* </div> */}
		</div>

	);
}

