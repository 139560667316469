import { useWallet } from "@solana/wallet-adapter-react";
import { Connection } from "@solana/web3.js";
import {
  AlignPrograms,
  AuthorityConfigType,
  createAlignPrograms,
} from "align-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  useGetOrganisationQuery,
  useGetUserQuery,
} from "../../generated/graphql";
import {
  getJwtToken,
  isCurrentLoggedInWallet,
  isJwtExpired,
  login,
  setWalletAddress,
} from "../../lib/auth";
import { isPubkey } from "../../utils/truncateKey";
import {
  currentOrganisation,
  currentProposal,
  organisationKeys,
  proposalAccount,
  proposalMetadata,
  proposalRankingKeys,
  queryContributionRecord,
  queryCouncilVoteRecord,
  queryOrganisationWarps,
  queryParsedTransaction,
  queryServicerPayout,
  refreshContributionRecord,
  refreshCouncil,
  refreshCouncilVoteRecord,
  refreshProposal,
  refreshProposalKeys,
  walletConfigs,
} from "../alignGovernance";
import { isCustomDomainStore } from "../domains";
import {
  isCouncilMember,
  isSignedInStore,
  queryUserGlobalReputationScore,
  refreshNfts,
  refreshProfile,
  refreshReputation,
  routesCurrentId,
  userPfp,
  userReputation,
  userReputationValue,
} from "../users";
import { connectionUrl } from "../web3";

export const useCurrentOrganisation = (): [
  string | undefined,
  string | undefined,
] => {
  const { proposalAddress, org } = useParams();
  const isCustomDomain = useRecoilValue(isCustomDomainStore);

  const [currentOrg, setCurrentOrg] = useRecoilState<string | undefined>(
    currentOrganisation,
  );

  const [currentProp, setCurrentProp] = useRecoilState<string | undefined>(
    currentProposal,
  );

  useEffect(() => {
    if (!proposalAddress) {
      return;
    }
    setCurrentProp(proposalAddress);
  }, [proposalAddress]);

  useEffect(() => {
    if (!org) {
      if (!isCustomDomain) {
        const orgAdress = org ? org : undefined;
        setCurrentOrg(orgAdress);
        return;
      }
      return;
    }
    setCurrentOrg(org);
  }, [org, isCustomDomain]);

  return [currentOrg, currentProp];
};

export const useOrganisation = (orgAddress: string | undefined) => {
  const { data, loading, refetch, error } = useGetOrganisationQuery({
    variables: {
      address: orgAddress || "",
      take: 0,
    },
    skip: orgAddress === undefined,
  });

  return {
    organisation: data?.organisation,
    refetchOrg: refetch,
    loading,
    error,
  };
};

export const useOrganisations = () => {
  const keys = useRecoilValue(organisationKeys);
  return [...keys].sort((a, b) => (a > b ? -1 : 1));
};

export const useAuth = () => {
  const { publicKey, signMessage } = useWallet();
  const [isSignedIn, setisSignedIn] = useRecoilState<boolean>(isSignedInStore);
  const { loading, error, data, refetch } = useGetUserQuery({
    variables: {
      address: publicKey?.toBase58() ? publicKey?.toBase58() : "",
      identifier: "userIdentifier",
    },
  });

  const refreshUser = refetch;

  const isValidToken = useMemo(() => {
    if (publicKey === null || signMessage === undefined) {
      return false;
    } else if (!isCurrentLoggedInWallet(publicKey.toBase58())) {
      return false;
    } else if (isJwtExpired()) {
      return false;
    }
    setisSignedIn(true);
    return true;
  }, [publicKey]);

  const { user } = data || {};

  const signIn = useCallback(async () => {
    if (publicKey === null || signMessage === undefined) {
      setisSignedIn(false);
      return;
    } else if (!isCurrentLoggedInWallet(publicKey.toBase58())) {
      await login(
        publicKey,
        signMessage,
        process.env.REACT_APP_ALIGN_API_URL!!,
      );
      setWalletAddress(publicKey.toBase58());
    } else if (isJwtExpired()) {
      console.log("token is expired signing new message.");
      await login(
        publicKey,
        signMessage,
        process.env.REACT_APP_ALIGN_API_URL!!,
      );
    }
    setisSignedIn(true);
  }, [publicKey]);

  const signOut = useCallback(() => {
    // clearJwtToken()
    setisSignedIn(false);
  }, [publicKey]);

  if (!data && !loading) {
    return {
      signIn: () => {},
      isSignedIn: false,
      identity: undefined,
      username: undefined,
      publicKey: undefined,
      userIdentityAddress: undefined,
      id: undefined,
      profileIsLoading: false,
      displayName: undefined,
      hasProfile: undefined,
      pfp: undefined,
      signOut: signOut,
      refreshLoggedInUser: () => {},
    };
  }
  return {
    ...user,
    publicKey,
    hasProfile: user !== undefined && user !== null,
    profileIsLoading: loading,
    refreshLoggedInUser: () => refreshUser(),
    isSignedIn,
    signIn,
    getJwtToken,
    signOut,
  };
};
export const useIsCouncilMember = (identifier: string | undefined) => {
  const user = useRecoilValue(isCouncilMember(identifier as string));
  return user;
};

export const useUser = (identifier: string | undefined) => {
  const isIdentifier = identifier ? isPubkey(identifier) : false;
  const { loading, error, data, refetch } = useGetUserQuery({
    variables: {
      identifier: isIdentifier ? identifier : undefined,
      username: !isIdentifier ? identifier : undefined,
    },
  });
  return data?.user;
};

export const usePayoutAmount = (proposalAddress: string | undefined) => {
  const payout = useRecoilValue(queryServicerPayout(proposalAddress));
  return payout;
};

export const useCurrentUsersContributionRecord = (
  proposalAddress: string | undefined,
) => {
  const contributionRecord = useRecoilValue(
    queryContributionRecord(proposalAddress),
  );
  const refresh = useSetRecoilState(refreshContributionRecord(proposalAddress));
  return {
    contributionRecord,
    refreshContribution: () => refresh((val) => val + 1),
  };
};

export const useCurrentUsersCouncilVoteRecord = (
  proposalAddress: string | undefined,
) => {
  const councilVoteRecord = useRecoilValue(
    queryCouncilVoteRecord(proposalAddress),
  );
  const refresh = useSetRecoilState(refreshCouncilVoteRecord(proposalAddress));
  return {
    councilVoteRecord,
    refreshVoteRecord: () => refresh((val) => val + 1),
  };
};

export const useProposalAddresses = (orgAddress?: string) => {
  const [currentorg] = useCurrentOrganisation();
  const [proposalAddresses, addProposalKey] = useRecoilState(
    proposalRankingKeys(orgAddress ? orgAddress : currentorg),
  );

  const addProposalAddress = useCallback((address: string) => {
    addProposalKey([address]);
  }, []);

  return { proposalAddresses, addProposalAddress };
};

export const useRefreshProposals = (
  organisationAddress: string | undefined,
) => {
  const refresh = useSetRecoilState(refreshProposalKeys(organisationAddress));

  return () => refresh((curr) => curr + 1);
};

export const useRefreshNfts = (walletAddress: string | undefined) => {
  const refresh = useSetRecoilState(refreshNfts(walletAddress));

  return () => refresh((curr) => curr + 1);
};

export const useRefreshContributionRecord = (
  proposalAddress: string | undefined,
) => {
  const refresh = useSetRecoilState(refreshContributionRecord(proposalAddress));

  return () => refresh((curr) => curr + 1);
};

export const useRefreshProposal = (proposalAddress: string | undefined) => {
  const refresh = useSetRecoilState(refreshProposal(proposalAddress));

  return () => refresh((curr) => curr + 1);
};

export const useRefreshProfile = (identifier: string | undefined) => {
  const refresh = useSetRecoilState(refreshProfile(identifier));

  return () => refresh((curr) => curr + 1);
};

export const useRefreshReputation = (identifier: string | undefined) => {
  const refresh = useSetRecoilState(refreshReputation(identifier));
  return () => refresh((curr) => curr + 1);
};

export const useRefreshCouncilManager = (organisation: string | undefined) => {
  const refresh = useSetRecoilState(refreshCouncil(organisation));
  return () => refresh((curr) => curr + 1);
};

export const useProposal = (proposalAddress: string | undefined) => {
  const proposal = useRecoilValue(proposalAccount(proposalAddress));
  return proposal;
};

export const useGlobalReputationScore = (identifier: string | undefined) => {
  const score = useRecoilValue(queryUserGlobalReputationScore(identifier));
  return score;
};

export const useWarp = (organisationAddress: string | undefined) => {
  return useRecoilValue(queryOrganisationWarps(organisationAddress));
};

export const useProposalMetadata = (proposalAddress: string | undefined) => {
  const metadata = useRecoilValue(proposalMetadata(proposalAddress));
  return metadata;
};

export const useAlignPrograms = () => {
  const wallet = useWallet();
  const connUrl = useRecoilValue(connectionUrl);
  const [alignPrograms, setAlignPrograms] = useState<AlignPrograms | null>(
    null,
  );

  useEffect(() => {
    if (!wallet?.publicKey) {
      return;
    }
    const createPrograms = async () => {
      const connection = new Connection(connUrl, "confirmed");
      const shadowConnectoin = new Connection(
        process.env.REACT_APP_SHADOW_RPC!,
        "max",
      );
      const programs = await createAlignPrograms(
        connection,
        wallet as any,
        shadowConnectoin,
      );
      setAlignPrograms(programs);
    };

    createPrograms();
  }, [wallet?.publicKey]);
  return alignPrograms;
};

export const useReputation = (identifier: string | undefined) => {
  const reputation = useRecoilValue(userReputation(identifier as string));
  return reputation;
};

export const useReputationValue = (identifier: string | undefined) => {
  const reputation = useRecoilValue(userReputationValue(identifier as string));
  return reputation;
};

export const useRoutesCurrentIdentifier = () => {
  const { identifier } = useParams();
  const [currentId, setCurrentId] = useRecoilState<string | undefined>(
    routesCurrentId,
  );

  useEffect(() => {
    if (!identifier) {
      return;
    }
    setCurrentId(identifier);
  }, [identifier]);

  return currentId;
};

export const useWalletConfigs = (filter?: AuthorityConfigType) => {
  const configs = useRecoilValue(walletConfigs);

  switch (filter) {
    case AuthorityConfigType.Cold: {
      return configs.filter(
        (acc) => acc.account.authorityType?.cold !== undefined,
      );
    }
    case AuthorityConfigType.Hot: {
      return configs.filter(
        (acc) => acc.account.authorityType?.hot !== undefined,
      );
    }
    case undefined:
      return configs;
  }
};

export const usePfp = (userIdentifier: string | undefined) => {
  const usersPfp = useRecoilValue(userPfp(userIdentifier));
  return usersPfp;
};

export const useParsedTransactions = (proposalAddress: string | undefined) => {
  const parsedTransactions = useRecoilValue(
    queryParsedTransaction(proposalAddress),
  );
  return parsedTransactions;
};

// export const useWalletsOutgoingSol = (walletConfigAddress : string | undefined) => {
//     const totalOutgoingSOL = useRecoilValue(queryTotalOutgoingSol(walletConfigAddress))
//     return totalOutgoingSOL
// }

// export const useWalletsOutgoingUsdc = (walletConfigAddress : string | undefined) => {
//     const totalOutgoingUsdc = useRecoilValue(queryTotalOutgoingUSDC(walletConfigAddress))
//     return totalOutgoingUsdc
// }
