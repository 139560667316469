import type {
  AccountAddress,
  Identity,
  IdentityResolver,
} from "@dialectlabs/sdk";
import type { Connection } from "@solana/web3.js";
import { PublicKey } from "@solana/web3.js";
import { AlignPrograms, Api, createAlignPrograms } from "align-sdk";
import { fetchNfts } from "../api/nfts";
import { READ_MOCK_WALLET } from "../constants";

export class AlignProfileIdentityResolver implements IdentityResolver {
  private alignPrograms: AlignPrograms | null = null;

  constructor(private readonly connection: Connection) {}

  async init() {
    this.alignPrograms = await createAlignPrograms(
      this.connection,
      READ_MOCK_WALLET as any,
      this.connection,
    );
  }

  get type(): string {
    return "ALIGN";
  }

  processUsername(username: string): string {
    // Trim any whitespace
    username = username.trim();

    // Remove the 'align:' prefix if present using regex
    username = username.replace(/^align:/, "");

    // Add '@' to the start if it's not already present
    if (!username.startsWith("@")) {
      username = "@" + username;
    }

    return username;
  }

  async fetchPfpLink(mint: string | undefined) {
    try {
      console.log("mint", mint);
      if (mint === undefined) return null;
      const res = await fetchNfts([mint]);
      console.log(res);
      if (res.length === 0) {
        return null;
      }
      const pfpLink = res[0]?.content?.links.image;
      if (!pfpLink) {
        return null;
      }
      return pfpLink;
    } catch (e) {
      throw new Error("Could not resolve pfp.");
    }
  }

  async resolve(address: AccountAddress): Promise<Identity | null> {
    if (!this.alignPrograms) {
      await this.init();
    }
    try {
      const res = await Api.fetchUserProfileByOwnerPubkey(
        new PublicKey(address),
        this.alignPrograms as AlignPrograms,
      );
      const avatarUrl = await this.fetchPfpLink(
        res.account.profile.pfp?.toBase58(),
      );

      return {
        name: res.account.username.toString(),
        address,
        type: this.type,
        additionals: {
          displayName: res.account.profile.displayName,
          avatarUrl: avatarUrl || undefined,
          link: `https://align.phaselabs.io/u/${res.account.username}`,
        },
      };
    } catch (e: any) {
      console.log(e);
    }
    return null;
  }

  async resolveReverse(username: string): Promise<Identity | null> {
    if (!this.alignPrograms) {
      await this.init();
    }

    const user = this.processUsername(username);
    const profile = await Api.fetchUserProfileByUsername(
      user,
      this.alignPrograms as AlignPrograms,
    );
    const ownerRecords = await Api.fetchOwnerRecordsByIdentifier(
      profile.account.identifier,
      this.alignPrograms as AlignPrograms,
    );
    const ownerRecord = ownerRecords?.find((x) => x.account.isVerified);

    if (!ownerRecords || !ownerRecord || ownerRecords.length === 0) {
      return null;
    }
    const avatarUrl = await this.fetchPfpLink(
      profile.account.profile.pfp?.toBase58(),
    );
    console.log("ownerrecord", ownerRecord);
    return {
      type: this.type,
      name: username,
      address: ownerRecord?.account.account?.toBase58(),
      additionals: {
        displayName: profile.account.profile.displayName,
        avatarUrl: avatarUrl || undefined,
        link: `https://align.phaselabs.io/u/${profile.account.username}`,
      },
    };
  }
}
