export const postData = async (
  url: string,
  data: any,
  abortSignal?: AbortSignal,
) => {
  try {
    const stringifiedData = JSON.stringify(data);
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: stringifiedData, // body data type must match "Content-Type" header,
      signal: abortSignal,
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (e) {
    console.warn(e);
    return null;
  }
};
