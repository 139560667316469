export type Beacon = {
  version: "0.1.0";
  name: "beacon";
  docs: [
    "Default plugin that allows staked nfts to be recorded in align\n    Does not emit any project tokens",
  ];
  instructions: [
    {
      name: "createBeacon";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "beacon";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "callbackInstructionData";
          type: "bytes";
        },
      ];
    },
    {
      name: "createWalletTracker";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "wallet";
          isMut: false;
          isSigner: true;
        },
        {
          name: "beacon";
          isMut: false;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "trackTokenAccount";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "wallet";
          isMut: false;
          isSigner: true;
        },
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "beacon";
          isMut: false;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "trackedTokenRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "instruction";
          isMut: true;
          isSigner: false;
        },
        {
          name: "collectionMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "collectionMetadata";
          isMut: false;
          isSigner: false;
        },
        {
          name: "collectionMasterEdition";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "userNftMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userNftMetadata";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userNftMasteredition";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "userTokenAccount";
          isMut: false;
          isSigner: false;
        },
        {
          name: "thread";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clockwork";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "accountMetas";
          type: {
            vec: {
              defined: "BeaconAccountMetaData";
            };
          };
        },
      ];
    },
    {
      name: "pauseTrackedTokenAccount";
      accounts: [
        {
          name: "wallet";
          isMut: false;
          isSigner: false;
        },
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "beacon";
          isMut: false;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenAccount";
          isMut: false;
          isSigner: false;
          docs: [
            "CHECK : Check is conditional as token account is usually closed when transfered",
          ];
        },
        {
          name: "trackedTokenRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "thread";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clockwork";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "resumeTrackedTokenAccount";
      accounts: [
        {
          name: "wallet";
          isMut: false;
          isSigner: true;
        },
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "beacon";
          isMut: false;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenAccount";
          isMut: false;
          isSigner: false;
          docs: [
            "CHECK : Check is conditional as token account is usually closed when transfered",
          ];
        },
        {
          name: "trackedTokenRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "thread";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clockwork";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "onTokenChange";
      accounts: [
        {
          name: "walletTracker";
          isMut: false;
          isSigner: false;
        },
        {
          name: "trackedTokenRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userTokenAccount";
          isMut: false;
          isSigner: false;
          docs: [
            "CHECK : Check is conditional as token account is usually closed when transfered",
          ];
        },
        {
          name: "thread";
          isMut: false;
          isSigner: true;
        },
        {
          name: "instruction";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
      returns: {
        defined: "clockwork_sdk::state::ThreadResponse";
      };
    },
  ];
  accounts: [
    {
      name: "beacon";
      type: {
        kind: "struct";
        fields: [
          {
            name: "beacon";
            type: "publicKey";
          },
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "callBackProgramId";
            type: "publicKey";
          },
          {
            name: "callBackInstructionData";
            type: "bytes";
          },
          {
            name: "bump";
            type: "u8";
          },
        ];
      };
    },
    {
      name: "walletTracker";
      type: {
        kind: "struct";
        fields: [
          {
            name: "beacon";
            type: "publicKey";
          },
          {
            name: "clockStake";
            type: "publicKey";
          },
          {
            name: "wallet";
            type: "publicKey";
          },
          {
            name: "totalTrackers";
            type: "u32";
          },
          {
            name: "bump";
            type: "u8";
          },
        ];
      };
    },
    {
      name: "trackedTokenRecord";
      type: {
        kind: "struct";
        fields: [
          {
            name: "walletTracker";
            type: "publicKey";
          },
          {
            name: "tokenAccount";
            type: "publicKey";
          },
          {
            name: "mint";
            type: "publicKey";
          },
          {
            name: "id";
            type: "u32";
          },
          {
            name: "currentAmount";
            type: "u64";
          },
          {
            name: "lastMovement";
            type: "u32";
          },
          {
            name: "paused";
            type: "bool";
          },
          {
            name: "bump";
            type: "u8";
          },
        ];
      };
    },
    {
      name: "tokenAccountInstructionCallback";
      type: {
        kind: "struct";
        fields: [
          {
            name: "programId";
            type: "publicKey";
          },
          {
            name: "accounts";
            type: {
              vec: {
                defined: "BeaconAccountMetaData";
              };
            };
          },
          {
            name: "data";
            type: "bytes";
          },
          {
            name: "bump";
            type: "u8";
          },
        ];
      };
    },
    {
      name: "allowItem";
      type: {
        kind: "struct";
        fields: [
          {
            name: "beacon";
            type: "publicKey";
          },
          {
            name: "mint";
            type: "publicKey";
          },
          {
            name: "bump";
            type: "u8";
          },
        ];
      };
    },
  ];
  types: [
    {
      name: "BeaconAccountMetaData";
      type: {
        kind: "struct";
        fields: [
          {
            name: "pubkey";
            type: "publicKey";
          },
          {
            name: "isSigner";
            type: "bool";
          },
          {
            name: "isWritable";
            type: "bool";
          },
        ];
      };
    },
  ];
  errors: [
    {
      code: 6000;
      name: "NumericalOverflow";
      msg: "Numerical Overflow!";
    },
    {
      code: 6001;
      name: "TokenAccountInvalid";
      msg: "Token Account is not valid";
    },
  ];
};

export const IDL: Beacon = {
  version: "0.1.0",
  name: "beacon",
  docs: [
    "Default plugin that allows staked nfts to be recorded in align\n    Does not emit any project tokens",
  ],
  instructions: [
    {
      name: "createBeacon",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "beacon",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "callbackInstructionData",
          type: "bytes",
        },
      ],
    },
    {
      name: "createWalletTracker",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "wallet",
          isMut: false,
          isSigner: true,
        },
        {
          name: "beacon",
          isMut: false,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "trackTokenAccount",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "wallet",
          isMut: false,
          isSigner: true,
        },
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "beacon",
          isMut: false,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "trackedTokenRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "instruction",
          isMut: true,
          isSigner: false,
        },
        {
          name: "collectionMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "collectionMetadata",
          isMut: false,
          isSigner: false,
        },
        {
          name: "collectionMasterEdition",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "userNftMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userNftMetadata",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userNftMasteredition",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "userTokenAccount",
          isMut: false,
          isSigner: false,
        },
        {
          name: "thread",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clockwork",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "accountMetas",
          type: {
            vec: {
              defined: "BeaconAccountMetaData",
            },
          },
        },
      ],
    },
    {
      name: "pauseTrackedTokenAccount",
      accounts: [
        {
          name: "wallet",
          isMut: false,
          isSigner: false,
        },
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "beacon",
          isMut: false,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userTokenAccount",
          isMut: false,
          isSigner: false,
          docs: [
            "CHECK : Check is conditional as token account is usually closed when transfered",
          ],
        },
        {
          name: "trackedTokenRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "thread",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clockwork",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "resumeTrackedTokenAccount",
      accounts: [
        {
          name: "wallet",
          isMut: false,
          isSigner: true,
        },
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "beacon",
          isMut: false,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userTokenAccount",
          isMut: false,
          isSigner: false,
          docs: [
            "CHECK : Check is conditional as token account is usually closed when transfered",
          ],
        },
        {
          name: "trackedTokenRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "thread",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clockwork",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "onTokenChange",
      accounts: [
        {
          name: "walletTracker",
          isMut: false,
          isSigner: false,
        },
        {
          name: "trackedTokenRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userTokenAccount",
          isMut: false,
          isSigner: false,
          docs: [
            "CHECK : Check is conditional as token account is usually closed when transfered",
          ],
        },
        {
          name: "thread",
          isMut: false,
          isSigner: true,
        },
        {
          name: "instruction",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
      returns: {
        defined: "clockwork_sdk::state::ThreadResponse",
      },
    },
  ],
  accounts: [
    {
      name: "beacon",
      type: {
        kind: "struct",
        fields: [
          {
            name: "beacon",
            type: "publicKey",
          },
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "callBackProgramId",
            type: "publicKey",
          },
          {
            name: "callBackInstructionData",
            type: "bytes",
          },
          {
            name: "bump",
            type: "u8",
          },
        ],
      },
    },
    {
      name: "walletTracker",
      type: {
        kind: "struct",
        fields: [
          {
            name: "beacon",
            type: "publicKey",
          },
          {
            name: "clockStake",
            type: "publicKey",
          },
          {
            name: "wallet",
            type: "publicKey",
          },
          {
            name: "totalTrackers",
            type: "u32",
          },
          {
            name: "bump",
            type: "u8",
          },
        ],
      },
    },
    {
      name: "trackedTokenRecord",
      type: {
        kind: "struct",
        fields: [
          {
            name: "walletTracker",
            type: "publicKey",
          },
          {
            name: "tokenAccount",
            type: "publicKey",
          },
          {
            name: "mint",
            type: "publicKey",
          },
          {
            name: "id",
            type: "u32",
          },
          {
            name: "currentAmount",
            type: "u64",
          },
          {
            name: "lastMovement",
            type: "u32",
          },
          {
            name: "paused",
            type: "bool",
          },
          {
            name: "bump",
            type: "u8",
          },
        ],
      },
    },
    {
      name: "tokenAccountInstructionCallback",
      type: {
        kind: "struct",
        fields: [
          {
            name: "programId",
            type: "publicKey",
          },
          {
            name: "accounts",
            type: {
              vec: {
                defined: "BeaconAccountMetaData",
              },
            },
          },
          {
            name: "data",
            type: "bytes",
          },
          {
            name: "bump",
            type: "u8",
          },
        ],
      },
    },
    {
      name: "allowItem",
      type: {
        kind: "struct",
        fields: [
          {
            name: "beacon",
            type: "publicKey",
          },
          {
            name: "mint",
            type: "publicKey",
          },
          {
            name: "bump",
            type: "u8",
          },
        ],
      },
    },
  ],
  types: [
    {
      name: "BeaconAccountMetaData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "pubkey",
            type: "publicKey",
          },
          {
            name: "isSigner",
            type: "bool",
          },
          {
            name: "isWritable",
            type: "bool",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "NumericalOverflow",
      msg: "Numerical Overflow!",
    },
    {
      code: 6001,
      name: "TokenAccountInvalid",
      msg: "Token Account is not valid",
    },
  ],
};
