export type Warp = {
  version: "0.1.0";
  name: "warp";
  instructions: [
    {
      name: "createWarp";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "warp";
          isMut: true;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "warp";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Mint";
                path: "fungible_mint";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Mint";
                path: "collection_mint";
              },
            ];
          };
        },
        {
          name: "fungibleMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "collectionMint";
          isMut: true;
          isSigner: true;
        },
        {
          name: "collectionMetadata";
          isMut: true;
          isSigner: false;
          docs: ["CHECK inside instruction"];
        },
        {
          name: "collectionMasterEdition";
          isMut: true;
          isSigner: false;
          docs: ["CHECK inside instruction"];
        },
        {
          name: "collectionTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenMetadata";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "splPerNft";
          type: "u64";
        },
        {
          name: "collectionMetadata";
          type: {
            defined: "MetadataV2";
          };
        },
        {
          name: "nftUri";
          type: {
            option: "string";
          };
        },
      ];
    },
    {
      name: "warpCoin";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "warp";
          isMut: true;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "warp";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Mint";
                path: "fungible_mint";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Mint";
                path: "collection_mint";
              },
            ];
          };
          relations: ["fungible_mint", "collection_mint"];
        },
        {
          name: "fungibleMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userFungibleTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "collectionMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "collectionMetadata";
          isMut: true;
          isSigner: false;
        },
        {
          name: "collectionMasterEdition";
          isMut: true;
          isSigner: false;
        },
        {
          name: "nftMint";
          isMut: true;
          isSigner: true;
        },
        {
          name: "nftMetadata";
          isMut: true;
          isSigner: false;
          docs: ["CHECK inside instruction"];
        },
        {
          name: "nftMasterEdition";
          isMut: true;
          isSigner: false;
          docs: ["CHECK inside instruction"];
        },
        {
          name: "nftTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "fungibleVault";
          isMut: true;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "fungible-vault";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Warp";
                path: "warp";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Mint";
                path: "nft_mint";
              },
            ];
          };
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenMetadata";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "warpNft";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "warp";
          isMut: false;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "warp";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Mint";
                path: "fungible_mint";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Mint";
                path: "collection_mint";
              },
            ];
          };
          relations: ["fungible_mint", "collection_mint"];
        },
        {
          name: "fungibleMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userFungibleTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "collectionMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "collectionMetadata";
          isMut: true;
          isSigner: false;
        },
        {
          name: "collectionMasterEdition";
          isMut: false;
          isSigner: false;
        },
        {
          name: "nftMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "nftMetadata";
          isMut: true;
          isSigner: false;
          docs: ["CHECK inside instruction"];
        },
        {
          name: "nftMasterEdition";
          isMut: true;
          isSigner: false;
          docs: ["CHECK inside instruction"];
        },
        {
          name: "nftTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "fungibleVault";
          isMut: true;
          isSigner: false;
          pda: {
            seeds: [
              {
                kind: "const";
                type: "string";
                value: "fungible-vault";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Warp";
                path: "warp";
              },
              {
                kind: "account";
                type: "publicKey";
                account: "Mint";
                path: "nft_mint";
              },
            ];
          };
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenMetadata";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
  ];
  accounts: [
    {
      name: "warp";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "fungibleMint";
            type: "publicKey";
          },
          {
            name: "fungiblePerNft";
            type: "u64";
          },
          {
            name: "collectionMint";
            type: "publicKey";
          },
          {
            name: "nftUri";
            type: {
              option: "string";
            };
          },
          {
            name: "bump";
            type: "u8";
          },
        ];
      };
    },
  ];
  types: [
    {
      name: "MetadataCreator";
      type: {
        kind: "struct";
        fields: [
          {
            name: "address";
            type: "publicKey";
          },
          {
            name: "verified";
            type: "bool";
          },
          {
            name: "share";
            type: "u8";
          },
        ];
      };
    },
    {
      name: "MetadataV2";
      type: {
        kind: "struct";
        fields: [
          {
            name: "name";
            docs: ["The name of the asset"];
            type: "string";
          },
          {
            name: "symbol";
            docs: ["The symbol for the asset"];
            type: "string";
          },
          {
            name: "uri";
            docs: ["URI pointing to JSON representing the asset"];
            type: "string";
          },
        ];
      };
    },
  ];
  errors: [
    {
      code: 6000;
      name: "NumericalOverflow";
      msg: "Numerical Overflow!";
    },
    {
      code: 6001;
      name: "IncorrectCollectionMint";
      msg: "Incorrect collection mint for warp!";
    },
    {
      code: 6002;
      name: "IncorrectFungibleMint";
      msg: "Incorrect fungible mint for warp!";
    },
    {
      code: 6003;
      name: "FungibleBalanceToLow";
      msg: "Not enough funible token to redeem a warped nft!";
    },
  ];
};

export const IDL: Warp = {
  version: "0.1.0",
  name: "warp",
  instructions: [
    {
      name: "createWarp",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "warp",
          isMut: true,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "warp",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Mint",
                path: "fungible_mint",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Mint",
                path: "collection_mint",
              },
            ],
          },
        },
        {
          name: "fungibleMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "collectionMint",
          isMut: true,
          isSigner: true,
        },
        {
          name: "collectionMetadata",
          isMut: true,
          isSigner: false,
          docs: ["CHECK inside instruction"],
        },
        {
          name: "collectionMasterEdition",
          isMut: true,
          isSigner: false,
          docs: ["CHECK inside instruction"],
        },
        {
          name: "collectionTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenMetadata",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "splPerNft",
          type: "u64",
        },
        {
          name: "collectionMetadata",
          type: {
            defined: "MetadataV2",
          },
        },
        {
          name: "nftUri",
          type: {
            option: "string",
          },
        },
      ],
    },
    {
      name: "warpCoin",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "warp",
          isMut: true,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "warp",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Mint",
                path: "fungible_mint",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Mint",
                path: "collection_mint",
              },
            ],
          },
          relations: ["fungible_mint", "collection_mint"],
        },
        {
          name: "fungibleMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userFungibleTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "collectionMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "collectionMetadata",
          isMut: true,
          isSigner: false,
        },
        {
          name: "collectionMasterEdition",
          isMut: true,
          isSigner: false,
        },
        {
          name: "nftMint",
          isMut: true,
          isSigner: true,
        },
        {
          name: "nftMetadata",
          isMut: true,
          isSigner: false,
          docs: ["CHECK inside instruction"],
        },
        {
          name: "nftMasterEdition",
          isMut: true,
          isSigner: false,
          docs: ["CHECK inside instruction"],
        },
        {
          name: "nftTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "fungibleVault",
          isMut: true,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "fungible-vault",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Warp",
                path: "warp",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Mint",
                path: "nft_mint",
              },
            ],
          },
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenMetadata",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "warpNft",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "warp",
          isMut: false,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "warp",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Mint",
                path: "fungible_mint",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Mint",
                path: "collection_mint",
              },
            ],
          },
          relations: ["fungible_mint", "collection_mint"],
        },
        {
          name: "fungibleMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userFungibleTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "collectionMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "collectionMetadata",
          isMut: true,
          isSigner: false,
        },
        {
          name: "collectionMasterEdition",
          isMut: false,
          isSigner: false,
        },
        {
          name: "nftMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "nftMetadata",
          isMut: true,
          isSigner: false,
          docs: ["CHECK inside instruction"],
        },
        {
          name: "nftMasterEdition",
          isMut: true,
          isSigner: false,
          docs: ["CHECK inside instruction"],
        },
        {
          name: "nftTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "fungibleVault",
          isMut: true,
          isSigner: false,
          pda: {
            seeds: [
              {
                kind: "const",
                type: "string",
                value: "fungible-vault",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Warp",
                path: "warp",
              },
              {
                kind: "account",
                type: "publicKey",
                account: "Mint",
                path: "nft_mint",
              },
            ],
          },
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenMetadata",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: "warp",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "fungibleMint",
            type: "publicKey",
          },
          {
            name: "fungiblePerNft",
            type: "u64",
          },
          {
            name: "collectionMint",
            type: "publicKey",
          },
          {
            name: "nftUri",
            type: {
              option: "string",
            },
          },
          {
            name: "bump",
            type: "u8",
          },
        ],
      },
    },
  ],
  types: [
    {
      name: "MetadataCreator",
      type: {
        kind: "struct",
        fields: [
          {
            name: "address",
            type: "publicKey",
          },
          {
            name: "verified",
            type: "bool",
          },
          {
            name: "share",
            type: "u8",
          },
        ],
      },
    },
    {
      name: "MetadataV2",
      type: {
        kind: "struct",
        fields: [
          {
            name: "name",
            docs: ["The name of the asset"],
            type: "string",
          },
          {
            name: "symbol",
            docs: ["The symbol for the asset"],
            type: "string",
          },
          {
            name: "uri",
            docs: ["URI pointing to JSON representing the asset"],
            type: "string",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "NumericalOverflow",
      msg: "Numerical Overflow!",
    },
    {
      code: 6001,
      name: "IncorrectCollectionMint",
      msg: "Incorrect collection mint for warp!",
    },
    {
      code: 6002,
      name: "IncorrectFungibleMint",
      msg: "Incorrect fungible mint for warp!",
    },
    {
      code: 6003,
      name: "FungibleBalanceToLow",
      msg: "Not enough funible token to redeem a warped nft!",
    },
  ],
};
