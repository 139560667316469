import { useEffect, useMemo, useState } from "react"
import { useCurrentOrganisation, useOrganisation } from "../state/hooks/useAlignGovernance"
import { CouncilVote, CouncilVoteRecordDto } from "../types"
import MintProfilePicture from "./MintProfilePicture"
import { Link } from "react-router-dom"

export const CouncilVoteProgress = ({
    proposalState,
    councilYesVotes,
    councilNoVotes,
    councilAbstainVotes,
    walletConfig,
    userVoteDirection,
    councilVoteRecord
}: {
    proposalState : string,
    councilYesVotes : number,
    councilNoVotes : number,
    councilAbstainVotes : number,
    walletConfig : string,
    userVoteDirection : CouncilVote | undefined,
    councilVoteRecord : CouncilVoteRecordDto[]
}) => {

    const [currentOrg] = useCurrentOrganisation()
    const {organisation} = useOrganisation(currentOrg)

    const yesVotes = useMemo(() => {
        return councilVoteRecord.filter(x => x.vote === "Yes")
    }, [councilVoteRecord])

    const noVotes = useMemo(() => {
        return councilVoteRecord.filter(x => x.vote === "No")
    }, [councilVoteRecord])

    const abstainVotes = useMemo(() => {
        return councilVoteRecord.filter(x => x.vote === "Abstain")
    }, [councilVoteRecord])

    const threshold = useMemo(() => {
        if(!organisation?.councilManager?.council_count) return 
        const threshold = organisation?.wallets.find(x => x.address === walletConfig)?.threshold
        if(threshold){
            const minWallets = Math.ceil((threshold / 10000) * organisation?.councilManager?.council_count)
            return minWallets
        }
        return 0
    }, [organisation?.wallets.length, walletConfig, organisation?.councilManager?.council_count])

    return (
        
        <div className="flex w-full justify-between h-full gap-5 items-center flex-wrap pl-5">
            <div className="flex w-fit xxl:w-full flex-col justify-center items-center pr-5">
                <label className="font-bold text-md">{threshold} / {organisation?.councilManager?.council_count}</label>
                <label className="font-light text-xs">Threshold</label>

                {threshold && (
                    <>
                        <label className="text-left font-light text-xs mt-4">Approve with {threshold - yesVotes.length} Yes</label>
                        <label className="text-left font-light text-xs mt-1">Decline with {threshold - noVotes.length} No</label>
                    </>
                )}
            </div>
            <div className={`flex flex-col flex-1 gap-2 justify-center`}>
                <div className={`relative flex flex-row w-full justify-center items-center gap-1 bg-white rounded-md bg-opacity-10 px-3 py-2  ${userVoteDirection !== undefined && userVoteDirection === "Yes" ? "border-primary text-primary" : "border-contrast border-opacity-30"}`}>
                    <label className="text-sm">Yes -</label>
                    <label className="font-bold text-md">{councilYesVotes}</label>
                    <div className="flex -space-x-2 ml-auto">
                        {yesVotes.map(vote => (
                                <Link to={`/u/${vote.user.username}`}>
                                    <MintProfilePicture mint={vote.user.pfp} width={8} alt={vote.user.displayName}/>
                            </Link>
                        ))}
                    </div>

                    {threshold && (
                        <div className="absolute -bottom-2 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700 overflow-hidden mt-6 mb-2">
                            <div className="bg-accent h-1 rounded-full" style={{width: `${yesVotes.length/threshold*100}%`}}></div>
                        </div>
                    )}
                </div>
                <div className={`relative flex flex-row w-full justify-center items-center gap-1 bg-white rounded-md bg-opacity-10 px-3 py-2  ${userVoteDirection !== undefined && userVoteDirection === "Yes" ? "border-primary text-primary" : "border-contrast border-opacity-30"}`}>
                    <label className="text-sm">No -</label>
                    <label className="font-bold text-md">{councilNoVotes}</label>
                    <div className="flex -space-x-2 ml-auto">
                        {noVotes.map(vote => (
                            <Link to={`/u/${vote.user.username}`}>
                                <MintProfilePicture mint={vote.user.pfp} width={8} alt={vote.user.displayName}/>
                            </Link>
                        ))}
                    </div>

                    {threshold && (
                        <div className="absolute -bottom-2 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700 overflow-hidden mt-6 mb-2">
                            <div className="bg-red-400 h-1 rounded-full" style={{width: `${noVotes.length/threshold*100}%`}}></div>
                        </div>
                    )}
                </div>
                {/* <div className={`relative flex flex-row w-full justify-center items-center gap-1 bg-white rounded-md bg-opacity-10 px-3 py-2  ${userVoteDirection !== undefined && userVoteDirection === "Yes" ? "border-primary text-primary" : "border-contrast border-opacity-30"}`}>
                    <label className="text-sm">Abstain -</label>
                    <label className="font-bold text-md">{councilAbstainVotes}</label>
                    <div className="flex -space-x-2 ml-auto">
                        {abstainVotes.map(vote => (
                            <a href={`/u/${vote.user.userIdentityAddress}`}>
                                <MintProfilePicture mint={vote.user.pfp} width={8} alt={vote.user.displayName}/>
                            </a>
                        ))}
                    </div>
                </div> */}
            </div>
        </div>
    )

}