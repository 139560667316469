import { useNavigate } from "react-router-dom";
import MintProfilePicture from "./MintProfilePicture";
import { useGetUserQuery } from "../generated/graphql";
import { ProfileDto } from "../types";


const UserList = ({
    councilMembers
}: {
    councilMembers : {
        __typename?: "CouncilMemberDto" | undefined;
        councilManagerAddress: string;
        user: {
            __typename?: "ProfileDto" | undefined;
            username: string;
            displayName: string;
            pfp?: string | null | undefined;
            userIdentityAddress: string;
        }
    }[] | undefined | null
}

) => {
    const navigate = useNavigate()

    if(!councilMembers){
        return <></>
    }

    return ( 
        <>
        {councilMembers?.map(({user}) => (
                                <div key={user.username} className="hover:cursor-pointer" onClick={() => navigate(`/u/${user.username}`)}>
                                    <MintProfilePicture mint={user.pfp} width={8}/>  
                                </div>
                            ))}
        </>
     );
}
 
export default UserList;