import { web3 } from "@project-serum/anchor";
import { profile } from "console";
import { useEffect, useState } from "react";
import { useAuth, usePfp, useUser } from "../state/hooks/useAlignGovernance";

export default function Avatar({userIdentifier, className, size, noBorder, showHandle}: {userIdentifier?: web3.PublicKey, className? : string, size : number, noBorder? : boolean, showHandle? : boolean }){
    const loggedInUser = useAuth()
    const user = useUser(userIdentifier?.toBase58())
    const pfp = usePfp(userIdentifier ? userIdentifier.toBase58() : loggedInUser?.identity?.identifier)
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div className="flex flex-col justify-center items-center text-[9px] overflow-hidden ">        
        {pfp ? (
                <img
                className={`  ${isLoading ? `bg-contrast h-${size} animate-pulse` : ""} object-cover h-${size} w-${size} min-w-${size} rounded-full border border-contrast border-opacity-30 ${className}`}
                src={pfp.pfp}
                alt=""
                onLoad={() => setIsLoading(!isLoading)}
                />
        ) : (
            <span className={`${className ? className : ""} h-${size} w-${size} rounded-full flex items-center justify-center relative ${noBorder === undefined ? "border border-contrast border-opacity-30" : "" }`}>
                <svg className={`w-${size/2} h-${size/2} text-gray-400`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
            </span>
        )}
        {showHandle && (<label className="font-light text-xs">{userIdentifier ? user?.username : loggedInUser?.username}</label>)}

    </div>
    )
}