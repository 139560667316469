import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export const WizardEntryPoint: FC<{
  title: string;
  description: string;
  route: string;
  icon?: ReactNode;
  disabled?: boolean; // Add the optional prop
}> = ({ title, description, route, icon, disabled }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!disabled) {
      navigate(route);
    }
  };

  return (
    <button
      className={`proposals-container w-full md:w-lg box-container rounded-box border-boxWidth border-contrast border-opacity-30 ${disabled ? "" : "hover:border-opacity-60" } max-w-lg w-lg  bg-opacity-30 py-2 px-2 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      onClick={handleClick}
      disabled={disabled}
    >
      <div className="flex p-3 gap-3 justify-start h-full items-center text-contrast relative w-full md:w-lg">
        {icon}
        <div className="flex h-full w-full flex-col justify-center gap-2">
          <h1 className="text-lg w-full md:text-2xl 'text-opacity-70">
            {title}
          </h1>
          <span className="text-sm text-contrast text-opacity-70">{description}</span>
        </div>
      </div>
    </button>
  );
};

const ProposalWizardSelector = ({ children }: { children: ReactNode[] | ReactNode }) => {
  return (
    <div className="grid w-full md:w-fit grid-cols-1 md:grid-cols-2 gap-4">
      {children}
    </div>
  );
};

export default ProposalWizardSelector;
