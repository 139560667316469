import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/24/solid"
import { Fragment, useState } from "react"
import { Recipient } from "../../ProposalWizardSendTokens"
import { useRecoilState, useRecoilValue } from "recoil";
import { proposalWizardSendAssetsNFTs } from "../../../../../../state/forms";
import { Compression, Ownership } from "../../../../../../types";

export type NFT = {
  metadata: {
    name: string;
    description: string;
    image: string;
  };
  image: string,
  mint: string;
  compression: Compression
  ownership: Ownership,
  token_standard: string;
};

export type NFTItemsProps = {
  nft: NFT;
  recipients: Recipient[];
  onSelectedRecipient: (selectedRecipient?: Recipient) => void; 
};

export const NFTItem = ({ nft, recipients, onSelectedRecipient }: NFTItemsProps) => {
  const nftsWithRecipients = useRecoilValue(proposalWizardSendAssetsNFTs);
  const currentNFT = nftsWithRecipients ? nftsWithRecipients[nft.mint] : null;

  const [selectedRecipient, setSelectedRecipient] = useState(currentNFT?.recipient);

  const handleSelectedRecipient = (value: number) => {
    if (selectedRecipient && value === selectedRecipient.id) {
      setSelectedRecipient(undefined);
      onSelectedRecipient(undefined);
      return;
    } 

      const newSelectedRecipient = recipients.find((recipient) => recipient.id === value);
      setSelectedRecipient(newSelectedRecipient);
      onSelectedRecipient(newSelectedRecipient);
  }

  return (
    <div className="w-full bg-contrast bg-opacity-10 p-4 h-fit rounded flex justify-between items-center gap-2">
      <div className="flex items-center gap-3">
        {nft.image && <img className="rounded-sm w-16 h-16" src={nft.image} alt={`${nft.metadata.name} NFT`} />}
        <div>
          <div>{nft.metadata?.name}</div>
          <div className="text-contrast font-light text-xs">
            {nft.metadata?.description}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Listbox value={selectedRecipient?.id} onChange={handleSelectedRecipient}>
          <div className="relative mt-1">
            <div className="flex gap-2 px-3 md:px-0 ">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-accent px-4 py-2 shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                {selectedRecipient ? <div className="flex justify-between w-full gap-4"><div>{selectedRecipient.name}</div>
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </div> : 'Select recipient'}
              </Listbox.Button>
            </div>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-contrast py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                {recipients?.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-accent' : ' text-black'
                      }`
                    }
                    value={person.id}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))
                }
              </Listbox.Options>

            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  )
}
