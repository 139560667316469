import { useCallback, useEffect } from "react";
import { Wizard, WizardStepProps } from "./Wizard";
import ProposalWizardRecipient from "./steps/sendAssets/ProposalWizardRecipient";
import ProposalWizardSendDescription from "./steps/sendAssets/ProposalWizardSendDescription";
import ProposalWizardSendSummary from "./steps/sendAssets/ProposalWizardSendSummary";
import ProposalWizardSendTokens from "./steps/sendAssets/ProposalWizardSendTokens";
import { useSetRecoilState } from "recoil";
import { proposalWizardSendAssetsNFTs } from "../../state/forms";


const ProposalWizardSendAssets = () => {
    const setSendAssetsNFTs = useSetRecoilState(proposalWizardSendAssetsNFTs)

    const resetAllFormsRecoilStates = useCallback(() => {
        setSendAssetsNFTs(null)
    }, [setSendAssetsNFTs])

    useEffect(function resetAll() {
        resetAllFormsRecoilStates()
    }, [resetAllFormsRecoilStates])


    return (
        <>
            <Wizard>
                {
                    (props: WizardStepProps<{}>) => (
                        <>
                            <ProposalWizardSendDescription {...props} />
                            <ProposalWizardRecipient {...props} />
                            <ProposalWizardSendTokens {...props} />
                            <ProposalWizardSendSummary {...props} />
                        </>
                    )
                }

            </Wizard>
        </>
    );
}

export default ProposalWizardSendAssets;