import { useRecoilValue } from "recoil";
import { isCustomDomainStore } from "../state/domains";

export function Footer() {
    const isCustomDomain = useRecoilValue(isCustomDomainStore)

	if( !isCustomDomain ) return <></>
    return (
        <div className="mt-auto">
            <nav>
                <div className="mx-auto max-w-screen-2xl px-8 py-8 text-center text-contrast">
					Powered by Align
                </div>
            </nav>
        </div>
    );
}
