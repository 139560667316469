import {
  Metadata,
  transferInstructionDiscriminator,
  TransferStruct,
} from "@metaplex-foundation/mpl-token-metadata";
import {
  AnchorProvider,
  BorshInstructionCoder,
  Program,
  web3,
} from "@project-serum/anchor";
import { seq, u8 } from "@solana/buffer-layout";
import {
  Account as TokenAccount,
  decodeTransferCheckedInstruction,
  getAccount,
  TokenInstruction,
  TOKEN_2022_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  Connection,
  PublicKey,
  SystemInstruction,
  SystemProgram,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  Account,
  ALIGN_PROGRAM_ID,
  Api,
  CollectionItem,
  CollectionNftRecord,
  ContributionRecord,
  CouncilManager,
  CouncilVoteRecord,
  createAlignPrograms,
  Derivation,
  filterFactory,
  Organisation,
  PROFILES_PROGRAM_ID,
  Proposal,
  ProposalInstruction,
  ProposalTransaction,
  WalletConfig,
  Warp,
  WarpAccount,
  WARP_PROGRAM_ID,
} from "align-sdk";
import { IDL as AlignIDL } from "align-sdk/dist/idls/align_governance";
import { IDL } from "align-sdk/dist/idls/profiles";
import BN from "bn.js";
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  waitForNone,
} from "recoil";
import {
  ADD_COUNCIL_MEMBER_IX_DISCRIMATOR,
  CREATE_PROFILE_IX_DISCRIMATOR,
  CREATE_WALLET_TRACKER_IX_DISCRIMATOR,
  METADATA_PREFIX,
  METADATA_PROGRAM_ID,
  PROPOSAL_PROPOSER_OFFSET,
  PROPOSAL_STATE_OFFSET,
  READ_MOCK_WALLET,
  REMOVE_COUNCIL_MEMBER_IX_DISCRIMATOR,
  SET_PFP_IX_DISCRIMATOR,
  SET_THRESHOLD_IX_DISCRIMATOR,
  TRACK_PFP_IX_DISCRIMATOR,
} from "../constants";
import {
  ParsedProposalInstruction,
  ParseTransactionTypes,
  UserInfo,
} from "../types";
import { arrayIsEqual } from "../utils/equality";
import { createWarpProvider } from "../warp";
import { queryCustomDomainOrganisation } from "./domains";
import {
  currentUserIdentifier,
  isLoggedIn,
  loggedInUserInfo,
  refreshReputation,
} from "./users";
import { connectionUrl } from "./web3";

export const proposalAccount = selectorFamily<
  Account<Proposal> | undefined,
  string | undefined
>({
  key: "proposal/account",
  get:
    (proposalAddress: string | undefined) =>
    async ({ get }) => {
      if (!proposalAddress) {
        return;
      }
      const connUrl = get(connectionUrl);
      get(refreshContributionRecord(proposalAddress));
      get(refreshProposal(proposalAddress));
      get(refreshCouncilVoteRecord(proposalAddress));
      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        const proposals = await Api.fetchProposal(
          new PublicKey(proposalAddress),
          programs,
        );
        return proposals;
      } catch (e) {
        console.warn(e);
      }
    },
});

export const proposalMetadata = selectorFamily<
  { name: string; description: string } | undefined,
  string | undefined
>({
  key: "proposal/metadata",
  get:
    (proposalAddress: string | undefined) =>
    async ({ get }) => {
      if (!proposalAddress) {
        return;
      }
      const proposal = get(proposalAccount(proposalAddress));

      if (!proposal) {
        return;
      }

      const shadowDrive: PublicKey = proposal.account.shadowDrive;

      const res = await fetch(
        `https://shdw-drive.genesysgo.net/${shadowDrive}/${proposal.address}.json`,
      );
      if (res.status === 200) {
        const metadata: { name: string; description: string } =
          await res.json();
        return metadata;
      } else {
        console.error(
          `Failed to fetch metadata for proposal ${proposalAddress}`,
        );
      }
    },
});

// export const proposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/accountData",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const proposalAddresses = get(queryRankingProposalKeys(organisationAddress));
//             const index = get(proposalPageIndex)

//             const proposalLoadables = get(waitForNone(
//                 proposalAddresses.map((address: string) => proposalAccount(address))
//               ));
//               return proposalLoadables
//                 .filter(({state}: any) => state === 'hasValue')
//                 .map(({contents}: any) => contents);
//         }
// })

// export const rankingProposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/ranking",
//     get: (organisationAddress : string | undefined) =>
//     async ({ get }: any) => {
//         if(!organisationAddress) return []
//         const proposalAddresses = get(queryRankingProposalKeys(organisationAddress));
//         const index = get(proposalPageIndex)
//         const itemsPerPage = get(proposalItemsPerPage)
//          // Pagination logic
//          const start = index * itemsPerPage;
//          const end = start + itemsPerPage;

//          // Get addresses for the current page
//          const pagedAddresses = proposalAddresses.slice(start, end);
//         const proposalLoadables = get(waitForNone(
//             pagedAddresses.map((address: string) => proposalAccount(address))
//           ));
//           return proposalLoadables
//             .filter(({state}: any) => state === 'hasValue')
//             .map(({contents}: any) => contents);
//     }
// })

// export const completedProposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/completed",
//     get: (organisationAddress : string | undefined) =>
//     async ({ get }: any) => {
//         if(!organisationAddress) return []
//         const proposalAddresses = get(queryCompleteProposalKeys(organisationAddress));
//         const index = get(proposalPageIndex)
//         const itemsPerPage = get(proposalItemsPerPage)
//          // Pagination logic
//          const start = index * itemsPerPage;
//          const end = start + itemsPerPage;

//          // Get addresses for the current page
//          const pagedAddresses = proposalAddresses.slice(start, end);
//         const proposalLoadables = get(waitForNone(
//             pagedAddresses.map((address: string) => proposalAccount(address))
//           ));
//           return proposalLoadables
//             .filter(({state}: any) => state === 'hasValue')
//             .map(({contents}: any) => contents);
//     }
// })

// export const deniedProposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/denied",
//     get: (organisationAddress : string | undefined) =>
//     async ({ get }: any) => {
//         if(!organisationAddress) return []
//         const proposalAddresses = get(queryDeniedProposalKeys(organisationAddress));
//         const index = get(proposalPageIndex)
//         const itemsPerPage = get(proposalItemsPerPage)
//          // Pagination logic
//          const start = index * itemsPerPage;
//          const end = start + itemsPerPage;

//          // Get addresses for the current page
//          const pagedAddresses = proposalAddresses.slice(start, end);
//         const proposalLoadables = get(waitForNone(
//             pagedAddresses.map((address: string) => proposalAccount(address))
//           ));
//           return proposalLoadables
//             .filter(({state}: any) => state === 'hasValue')
//             .map(({contents}: any) => contents);
//     }
// })

// export const draftProposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/draft",
//     get: (organisationAddress : string | undefined) =>
//     async ({ get }: any) => {
//         if(!organisationAddress) return []
//         const proposalAddresses = get(queryDraftProposalKeys(organisationAddress));
//         const index = get(proposalPageIndex)
//         const itemsPerPage = get(proposalItemsPerPage)
//          // Pagination logic
//          const start = index * itemsPerPage;
//          const end = start + itemsPerPage;

//          // Get addresses for the current page
//          const pagedAddresses = proposalAddresses.slice(start, end);
//         const proposalLoadables = get(waitForNone(
//             pagedAddresses.map((address: string) => proposalAccount(address))
//           ));
//           return proposalLoadables
//             .filter(({state}: any) => state === 'hasValue')
//             .map(({contents}: any) => contents);
//     }
// })

// export const reviewProposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/review",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const readyExecProposalAddresses = get(queryReadyToExecuteProposalKeys(organisationAddress));
//             const execProposalAddresses = get(queryExecutingProposalKeys(organisationAddress));
//             const reviewedProposalAddresses = get(queryReveiwedProposalKeys(organisationAddress));
//             const reviewingProposalAddresses = get(queryReveiwingProposalKeys(organisationAddress));

//             const proposalAddresses = [
//                 ...reviewingProposalAddresses,
//                 ...readyExecProposalAddresses,
//                 ...execProposalAddresses,
//                 ...reviewedProposalAddresses,
//             ]
//             console.log(proposalAddresses)

//             const index = get(proposalPageIndex)
//             const itemsPerPage = get(proposalItemsPerPage)
//              // Pagination logic
//              const start = index * itemsPerPage;
//              const end = start + itemsPerPage;

//              // Get addresses for the current page
//              const pagedAddresses = proposalAddresses.slice(start, end);
//             const proposalLoadables = get(waitForNone(
//                 pagedAddresses.map((address: string) => proposalAccount(address))
//               ));
//               return proposalLoadables
//                 .filter(({state}: any) => state === 'hasValue')
//                 .map(({contents}: any) => contents);
//         }
// })

// /**
//  * Have to fully pull all these accounts sadly because some proposals with ranking are
//  * ready to be pushed to council vote but stull have the state of ranking
//  */
// export const votingProposalAccounts = selectorFamily<Account<Proposal>[] | null, {
//     organisationAddress: string | undefined,
//     filterType?: string,
//     sortType?: string
//   }>({
//     key: "proposals/councilVoting",
//     get: ({ organisationAddress, filterType = 'ALL', sortType = 'NONE' }) =>
//       async ({ get }: any) => {
//         if (!organisationAddress) return [];

//         const proposalAddresses = get(queryVotingProposalKeys(organisationAddress));
//         const rankingProposalAddresses = get(queryRankingProposalKeys(organisationAddress))
//         const combinedAddresses = [...proposalAddresses, ...rankingProposalAddresses];

//         const proposalLoadables = get(waitForNone(
//           combinedAddresses.map((address: string) => proposalAccount(address))
//         ));

//         let proposals = proposalLoadables
//           .filter(({ state }: any) => state === 'hasValue')
//           .map(({ contents }: any) => contents);

//         // Apply filters
//         if (filterType === 'UNVOTED') {
//           proposals = proposals.filter((proposal : Account<Proposal>) => {
//             const councilVoteRecord = get(queryCouncilVoteRecord(proposal.address?.toBase58()));
//             return !councilVoteRecord;
//           });
//         }
//         else if (filterType === 'VOTED') {
//             proposals = proposals.filter((proposal : Account<Proposal>) => {
//               const councilVoteRecord = get(queryCouncilVoteRecord(proposal.address?.toBase58()));
//               return councilVoteRecord;
//             });
//         }

//         // Apply sorting
//         if (sortType === 'RANK_DESCENDING') {
//           proposals.sort((a: Account<Proposal>, b: Account<Proposal>) =>
//             b.account.upvotes.sub(b.account.downvotes).toNumber() - a.account.upvotes.sub(a.account.downvotes).toNumber()
//           );
//         }
//        // Adjust your sort type handling code:
//        // Replace this part of your code
//        else if (sortType === "REPUTATION_DESCENDING") {
//         // Pre-fetch Loadables of reputation values for all proposals
//         const reputationLoadables = get(waitForNone(
//           proposals.map((proposal: Account<Proposal>) => userReputationValue(proposal.account.proposer.toBase58()))
//         ));

//         // Extract values if they exist or provide a default, map to object with userId for future lookup
//         const reputations = reputationLoadables.map((loadable : any, index : any) => {
//           let rep = 0;
//           if (loadable.state === 'hasValue') {
//             rep = loadable.contents.toNumber();
//           } // You can add error handling for 'loading' and 'hasError' states if needed
//           return { userId: proposals[index].account.proposer.toBase58(), rep };
//         });

//         // Convert array of reputation values into an object for easy lookup
//         const reputationLookup = reputations.reduce((lookup: any, { userId, rep } : any) => {
//           lookup[userId] = rep;
//           return lookup;
//         }, {});

//         // Now, use these reputation values in your sort comparison
//         proposals.sort((a: Account<Proposal>, b: Account<Proposal>) => {
//           // Get the reputation values we just fetched
//           const aReputation = reputationLookup[a.account.proposer.toBase58()] || 0;
//           const bReputation = reputationLookup[b.account.proposer.toBase58()] || 0;

//           // Now compare the reputation values.
//           return bReputation - aReputation;
//         });
//       }

//         return proposals;
//       }
//   });

// export const unvotedProposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/unvotedCouncil",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             const proposals = get(votingProposalAccounts({organisationAddress}));
//             if(!proposals) return null;

//             const unvotedProposals = [];

//             // Loop through each proposal and add to unvotedProposals only if the user hasn't voted
//             for(const proposal of proposals){
//                 const councilVoteRecord = get(queryCouncilVoteRecord(proposal.address));
//                 if(!councilVoteRecord){
//                     unvotedProposals.push(proposal);
//                 }
//             }

//             return unvotedProposals;
//         }
// });

// export const pagedVotingProposalAccounts = selectorFamily<Account<Proposal>[] | null, {
//     organisationAddress: string | undefined,
//     filterType?: string,
//     sortType?: string
//   }>({
//     key: "proposals/councilVoting/paged",
//     get: ({ organisationAddress, filterType = 'ALL', sortType = 'NONE' }) =>
//         async ({ get }: any) => {
//                 if(!organisationAddress) return []
//                 const proposals = get(votingProposalAccounts({organisationAddress, filterType, sortType}));

//                 const index = get(proposalPageIndex)
//                 const itemsPerPage = get(proposalItemsPerPage)
//                 const start = index * itemsPerPage;
//                 const end = start + itemsPerPage;
//                 const p = proposals.slice(start, end);
//                 return p;
//             }
// })
// export const allProposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/all",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//                 if(!organisationAddress) return []
//                 const proposalAddresses = get(queryVotingProposalKeys(organisationAddress));
//                 const rankingProposalAddresses = get(queryRankingProposalKeys(organisationAddress))
//                 const complete = get(queryCompleteProposalKeys(organisationAddress))
//                 const denied = get(queryDeniedProposalKeys(organisationAddress))
//                 const review = get(queryReveiwedProposalKeys(organisationAddress))
//                 const readyExecProposalAddresses = get(queryReadyToExecuteProposalKeys(organisationAddress));
//                 const execProposalAddresses = get(queryExecutingProposalKeys(organisationAddress));
//                 const reviewingProposalAddresses = get(queryReveiwingProposalKeys(organisationAddress));

//                 const combinedAddresses = [
//                         ...complete,
//                         ...denied,
//                         ...review,
//                         ...readyExecProposalAddresses,
//                         ...execProposalAddresses,
//                         ...reviewingProposalAddresses,
//                         ...proposalAddresses,
//                         ...rankingProposalAddresses
//                     ]

//                 const index = get(proposalPageIndex)
//                 const itemsPerPage = get(proposalItemsPerPage)
//                 const start = index * itemsPerPage;
//                 const end = start + itemsPerPage;

//                 const pagedAddresses = combinedAddresses.slice(start, end);
//             const proposalLoadables = get(waitForNone(
//                 pagedAddresses.map((address: string) => proposalAccount(address))
//               ));
//               return proposalLoadables
//                 .filter(({state}: any) => state === 'hasValue')
//                 .map(({contents}: any) => contents);
//             }
// })
// /**
//  * Doesnt include drafts because thats one extra gpa call and they dont really count anyway
//  */
// export const allProposalCount = selectorFamily<number , string | undefined>({
//     key: "proposals/councilVoting/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return 0
//             const proposalAddresses = get(queryVotingProposalKeys(organisationAddress));
//             const rankingProposalAddresses = get(queryRankingProposalKeys(organisationAddress))
//             const complete = get(queryCompleteProposalKeys(organisationAddress))
//             const denied = get(queryDeniedProposalKeys(organisationAddress))
//             const review = get(queryReveiwedProposalKeys(organisationAddress))
//             const readyExecProposalAddresses = get(queryReadyToExecuteProposalKeys(organisationAddress));
//             const execProposalAddresses = get(queryExecutingProposalKeys(organisationAddress));
//             const reviewingProposalAddresses = get(queryReveiwingProposalKeys(organisationAddress));
//             // const draftProposalAddresses = get(queryDraftProposalKeys(organisationAddress));

//             const combinedAddresses = [
//                 ...complete,
//                 ...denied,
//                 ...review,
//                 ...readyExecProposalAddresses,
//                 ...execProposalAddresses,
//                 ...reviewingProposalAddresses,
//                 ...proposalAddresses,
//                 ...rankingProposalAddresses,
//                 // ...draftProposalAddresses
//             ]

//             return combinedAddresses?.length;
//         }
// })

// export const votingProposalCount = selectorFamily<number , string | undefined>({
//     key: "proposals/councilVoting/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const proposals = get(votingProposalAccounts({organisationAddress}))
//             return proposals?.length;
//         }
// })

// export const completedProposalCount = selectorFamily<number , string | undefined>({
//     key: "proposals/complete/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const proposals = get(queryCompleteProposalKeys(organisationAddress))
//             return proposals?.length;
//         }
// })

// export const draftProposalCount = selectorFamily<number , string | undefined>({
//     key: "proposals/draft/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const proposals = get(queryCompleteProposalKeys(organisationAddress))
//             return proposals?.length;
//         }
// })

// export const deniedProposalCount = selectorFamily<number , string | undefined>({
//     key: "proposals/denied/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const proposals = get(queryDeniedProposalKeys(organisationAddress))
//             return proposals?.length;
//         }
// })

// export const reviewProposalCount = selectorFamily<number , string | undefined>({
//     key: "proposals/councilVoting/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return 0
//             const readyExecProposalAddresses = get(queryReadyToExecuteProposalKeys(organisationAddress));
//             const execProposalAddresses = get(queryExecutingProposalKeys(organisationAddress));
//             const reviewedProposalAddresses = get(queryReveiwedProposalKeys(organisationAddress));
//             const reviewingProposalAddresses = get(queryReveiwingProposalKeys(organisationAddress));
//             const proposals = [
//                 ...reviewingProposalAddresses,
//                 ...readyExecProposalAddresses,
//                 ...execProposalAddresses,
//                 ...reviewedProposalAddresses,
//             ]
//             return proposals?.length;
//         }
// })

// export const rankingProposalCount = selectorFamily<number, string | undefined>({
//     key: "proposals/ranking/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const proposals = get(queryRankingProposalKeys(organisationAddress))
//             return proposals?.length;
//         }
// })

// export const servicingProposalCount = selectorFamily<number, string | undefined>({
//     key: "proposals/servicing/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const proposals = get(queryServicingProposalKeys(organisationAddress))
//             return proposals?.length;
//         }
// })

// export const userProposalCount = selectorFamily<number, string | undefined>({
//     key: "proposals/user/count",
//     get: (organisationAddress : string | undefined) =>
//         async ({ get }: any) => {
//             if(!organisationAddress) return []
//             const proposals = get(queryUsersProposalKeys(organisationAddress))
//             return proposals?.length;
//         }
// })

// export const servicingProposalAccounts = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/servicing",
//     get: (organisationAddress : string | undefined) =>
//     async ({ get }: any) => {
//         if(!organisationAddress) return []
//         const proposalAddresses = get(queryServicingProposalKeys(organisationAddress));
//         const index = get(proposalPageIndex)
//         const itemsPerPage = get(proposalItemsPerPage)
//          // Pagination logic
//          const start = index * itemsPerPage;
//          const end = start + itemsPerPage;

//          // Get addresses for the current page
//          const pagedAddresses = proposalAddresses.slice(start, end);
//         const proposalLoadables = get(waitForNone(
//             pagedAddresses.map((address: string) => proposalAccount(address))
//           ));
//           return proposalLoadables
//             .filter(({state}: any) => state === 'hasValue')
//             .map(({contents}: any) => contents);
//     }
// })

// export const queryTotalOutgoingSol = selectorFamily<BN | null, string | undefined>({
//     key: "proposals/totalOutgoingSOL",
//     get: (walletConfigAddress : string | undefined) =>
//         async ({ get }: any) => {
//             const organisationAddress = get(currentOrganisation)
//             if(!organisationAddress) return null

//             const servicingProposals : Account<Proposal>[] = get(servicingProposalAccounts(organisationAddress));
//             const review : Account<Proposal>[] = get(reviewProposalAccounts(organisationAddress));

//             const payoutLoadables = get(waitForNone(
//                 [...servicingProposals, ...review].filter(prop => prop.account.authorityConfigAddress.toBase58() === walletConfigAddress).map((proposal) => queryServicerPayout(proposal.address.toBase58()))
//               ));

//             return payoutLoadables
//             .filter(({state}: any) => state === 'hasValue')
//             .filter(({contents} : {contents : {
//                 amount: BN;
//                 mint: web3.PublicKey | null;
//                 isNative: boolean;
//                 decimals: number;
//             }}) => contents !== null)
//             .filter(({contents} : {contents : {
//                 amount: BN;
//                 mint: web3.PublicKey | null;
//                 isNative: boolean;
//                 decimals: number;
//             }}) => contents.isNative === true)
//             .map(({contents} : {contents : {
//                 amount: BN;
//                 mint: web3.PublicKey | null;
//                 isNative: boolean;
//                 decimals: number;
//             }}) => contents.amount)
//             .reduce((total :BN, curr: BN) => curr.add(total), new BN(0));

//         }
// })

// export const queryTotalOutgoingUSDC = selectorFamily<BN | null, string | undefined>({
//     key: "proposals/totalOutgoingUSDC",
//     get: (walletConfigAddress : string | undefined) =>
//         async ({ get }: any) => {
//             const organisationAddress = get(currentOrganisation)
//             if(!organisationAddress) return null

//             const servicingProposals : Account<Proposal>[] = get(servicingProposalAccounts(organisationAddress));
//             const review : Account<Proposal>[] = get(reviewProposalAccounts(organisationAddress));

//             const payoutLoadables = get(waitForNone(
//                 [...servicingProposals, ...review].filter(prop => prop.account.authorityConfigAddress.toBase58() === walletConfigAddress).map((proposal) => queryServicerPayout(proposal.address.toBase58()))
//               ));

//             return payoutLoadables
//             .filter(({state}: any) => state === 'hasValue')
//             .filter(({contents} : {contents : {
//                 amount: BN;
//                 mint: web3.PublicKey | null;
//                 isNative: boolean;
//                 decimals: number;
//             }}) => contents !== null)
//             .filter(({contents} : {contents : {
//                 amount: BN;
//                 mint: web3.PublicKey | null;
//                 isNative: boolean;
//                 decimals: number;
//             }}) => contents.mint && contents.mint.toBase58() === USDC_MINT_ADDRESS.toBase58())
//             .map(({contents} : {contents : {
//                 amount: BN;
//                 mint: web3.PublicKey | null;
//                 isNative: boolean;
//                 decimals: number;
//             }}) => contents.amount)
//             .reduce((total :BN, curr: BN) => curr.add(total), new BN(0));

//         }
// })

// export const userProposedProposals = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/userProposed",
//     get: (organisationAddress : string | undefined) =>
//     async ({ get }: any) => {
//         if(!organisationAddress) return []
//             const proposalAddresses = get(queryUsersProposalKeys(organisationAddress));
//             const index = get(proposalPageIndex)
//             const itemsPerPage = get(proposalItemsPerPage)
//             // Pagination logic
//             const start = index * itemsPerPage;
//             const end = start + itemsPerPage;

//             // Get addresses for the current page
//             const pagedAddresses = proposalAddresses.slice(start, end);
//             const proposalLoadables = get(waitForNone(
//                 pagedAddresses.map((address: string) => proposalAccount(address))
//             ));
//             return proposalLoadables
//                 .filter(({state}: any) => state === 'hasValue')
//                 .map(({contents}: any) => contents);
//     }
// })

export const queryProposalTransactions = selectorFamily<
  Account<ProposalTransaction>[] | null,
  string | undefined
>({
  key: "proposal/transactions",
  get:
    (proposalAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!proposalAddress) {
        return null;
      }

      const proposal = get(proposalAccount(proposalAddress));

      if (!proposal) {
        return null;
      }

      const transactionCount = proposal.account.transactionCount;
      const transactionAddresses = [...Array(transactionCount).keys()].map(
        (index) =>
          Derivation.deriveTransaction(new PublicKey(proposalAddress), index),
      );
      const transactionLoadables = get(
        waitForNone(
          transactionAddresses.map((address: PublicKey) =>
            queryProposalTransaction(address.toBase58()),
          ),
        ),
      );
      return transactionLoadables
        .filter(({ state }: any) => state === "hasValue")
        .map(({ contents }: any) => contents);
    },
});

export const queryProposalTransaction = selectorFamily<
  Account<ProposalTransaction> | null,
  string | undefined
>({
  key: "transaction/account",
  get:
    (transactionAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!transactionAddress) {
        return null;
      }

      const connUrl = get(connectionUrl);

      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );

      try {
        const transaction = await Api.fetchProposalTransaction(
          new PublicKey(transactionAddress),
          programs,
        );
        return transaction;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
});

export const queryTransactionInstructions = selectorFamily<
  Account<ProposalInstruction>[],
  string | undefined
>({
  key: "transaction/instructions",
  get:
    (transactionAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!transactionAddress) {
        return [];
      }

      const transaction = get(queryProposalTransaction(transactionAddress));
      if (!transaction) {
        return [];
      }
      const connUrl = get(connectionUrl);

      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      const instructionCount = transaction.account.instructionCount;
      const instructionAddresses = [...Array(instructionCount).keys()].map(
        (index) =>
          Derivation.deriveProposalInstructionAddress(
            transaction.address,
            index,
          ),
      );
      const instructionLoadables = get(
        waitForNone(
          instructionAddresses.map((address: PublicKey) =>
            queryInstruction(address.toBase58()),
          ),
        ),
      );
      return instructionLoadables
        .filter(({ state }: any) => state === "hasValue")
        .map(({ contents }: any) => contents);
    },
});

export const queryInstruction = selectorFamily<
  Account<ProposalInstruction> | null,
  string | undefined
>({
  key: "instruction/account",
  get:
    (instructionAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!instructionAddress) {
        return null;
      }

      const connUrl = get(connectionUrl);

      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );

      try {
        const instructions = await Api.fetchProposalInstruction(
          new PublicKey(instructionAddress),
          programs,
        );
        return instructions;
      } catch (e) {
        return null;
      }
    },
});

export const queryWarpByCollection = selectorFamily<
  Account<WarpAccount> | undefined,
  string | undefined
>({
  key: "collection/warp",
  get:
    (collection: string | undefined) =>
    async ({ get }: any) => {
      if (!collection) {
        return;
      }
      const connUrl = get(connectionUrl);

      const provider = new AnchorProvider(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        {},
      );
      const program: Program<Warp> = createWarpProvider(provider);

      const [account] = await program.provider.connection.getProgramAccounts(
        WARP_PROGRAM_ID,
        {
          filters: [
            filterFactory(
              8 + 32 + 32 + 8,
              new PublicKey(collection).toBuffer(),
            ),
          ],
        },
      );

      const parsedAccountData: WarpAccount = program.coder.accounts.decode(
        "warp",
        account.account.data as Buffer,
      ) as WarpAccount;

      return {
        address: account.pubkey,
        account: parsedAccountData,
      };
    },
});

export const queryOrganisationWarps = selectorFamily<
  Account<WarpAccount>[],
  string | undefined
>({
  key: "organisation/warps",
  get:
    (organisation: string | undefined) =>
    async ({ get }: any) => {
      if (!organisation) {
        return [];
      }

      const organisationAccount: Account<Organisation> = get(
        queryOrganisationAccount(organisation),
      );
      if (!organisationAccount) {
        return [];
      }

      const collectionLoadables = get(
        waitForNone(
          organisationAccount.account.config.collectionItems.map(
            (address: CollectionItem) =>
              queryWarpByCollection(address?.mint?.toBase58()),
          ),
        ),
      );

      const loadables = collectionLoadables
        .filter(({ state }: any) => state === "hasValue")
        .map(({ contents }: any) => contents)
        .filter((address: string) => address !== undefined);
      return loadables;
    },
});

export const queryCollectionNfts = selectorFamily<
  Metadata[],
  string | undefined
>({
  key: "organisation/collectionNfts",
  get:
    (organisation: string | undefined) =>
    async ({ get }: any) => {
      if (!organisation) {
        return [];
      }

      const connUrl = get(connectionUrl);
      const connection = new Connection(connUrl, "confirmed");

      const organisationAccount: Account<Organisation> = get(
        queryOrganisationAccount(organisation),
      );
      if (!organisationAccount) {
        return [];
      }
      const promises = organisationAccount.account.config.collectionItems.map(
        async (item) => {
          const [programAccount] = PublicKey.findProgramAddressSync(
            [
              METADATA_PREFIX,
              METADATA_PROGRAM_ID.toBuffer(),
              item.mint.toBuffer(),
            ],
            METADATA_PROGRAM_ID,
          );
          const accountInfo = await connection.getAccountInfo(programAccount);
          if (accountInfo) {
            try {
              const [metadata] = Metadata.fromAccountInfo(accountInfo);
              return metadata;
            } catch (e) {
              console.warn(
                `Error deserializing metadata account: ${programAccount}`,
              );
              return undefined;
            }
          }
        },
      );
      const nfts = await Promise.all(promises);
      const filtered: Metadata[] = nfts.filter(
        (n) => n !== undefined && n !== null,
      ) as Metadata[];
      return filtered;
    },
});

export const queryCollectionRecords = selectorFamily<
  Array<Account<CollectionNftRecord>> | null,
  string | undefined
>({
  key: "records/userNftCollectionRecord",
  get:
    (identifier: string | undefined) =>
    async ({ get }: any) => {
      if (!identifier) {
        return null;
      }

      const connUrl = get(connectionUrl);
      get(refreshReputation(identifier));
      const org = get(currentOrganisation);
      if (org === undefined) {
        return null;
      }

      const organisationAccount: Account<Organisation> = get(
        queryOrganisationAccount(org),
      );

      if (!organisationAccount) {
        return null;
      }

      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );

      try {
        const addresses = Derivation.deriveAllCollectionNFTRecordAddress(
          new PublicKey(identifier),
          new PublicKey(org),
          organisationAccount.account.config.collectionItems.map((x) => x.mint),
        );
        const records = await Api.fetchMulitpleCollectionNftRecord(
          addresses,
          programs,
        );
        return records.filter((x) => x.account !== null) as Array<
          Account<CollectionNftRecord>
        >;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
});
// Very hacky needs to be fixed once we have custom transactions
export const queryServicerPayout = selectorFamily<
  {
    amount: BN;
    mint: web3.PublicKey | null;
    isNative: boolean;
    decimals: number;
    tokenProgram: PublicKey | undefined;
  } | null,
  string | undefined
>({
  key: "proposal/payout",
  get:
    (proposalAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!proposalAddress) {
        return null;
      }

      const transactions = get(queryProposalTransactions(proposalAddress));

      if (!transactions || transactions.length === 0) {
        return null;
      }

      const instructions: Account<ProposalInstruction>[] = get(
        queryTransactionInstructions(transactions[0].address),
      );
      if (!instructions || instructions.length === 0) {
        return null;
      }

      const transctionInstructions = instructions.map(
        (inst) =>
          new TransactionInstruction({
            keys: inst.account.accounts,
            programId: inst.account.programId,
            data: Buffer.from(new Uint8Array(inst.account.data)),
          }),
      );

      for (let i = 0; i < transctionInstructions.length; i++) {
        const transactionInstruction = transctionInstructions[i];
        if (transactionInstruction.programId.equals(TOKEN_PROGRAM_ID)) {
          const decodedData = u8().decode(transactionInstruction.data);

          switch (decodedData) {
            case TokenInstruction.TransferChecked: {
              const decoded = decodeTransferCheckedInstruction(
                transactionInstruction,
              );
              return {
                amount: new BN(decoded.data.amount.toString()),
                decimals: decoded.data.decimals,
                mint: decoded.keys.mint.pubkey,
                isNative: false,
                tokenProgram: TOKEN_PROGRAM_ID,
              };
            }
          }
        } else if (transactionInstruction.programId.equals(TOKEN_PROGRAM_ID)) {
          const decodedData = u8().decode(transactionInstruction.data);

          switch (decodedData) {
            case 0: {
              if (transctionInstructions.length < i + 1) {
                const transferDecodedData = u8().decode(
                  transactionInstruction.data,
                );

                switch (transferDecodedData) {
                  case TokenInstruction.TransferChecked: {
                    const decoded = decodeTransferCheckedInstruction(
                      transactionInstruction,
                    );
                    return {
                      amount: new BN(decoded.data.amount.toString()),
                      decimals: decoded.data.decimals,
                      mint: decoded.keys.mint.pubkey,
                      isNative: false,
                      tokenProgram: TOKEN_PROGRAM_ID,
                    };
                  }
                }
              }
              return null;
            }
          }
        } else if (
        /**
         * TOKEN 2022
         */
          transactionInstruction.programId.equals(TOKEN_2022_PROGRAM_ID)
        ) {
          const decodedData = u8().decode(transactionInstruction.data);
          switch (decodedData) {
            case TokenInstruction.TransferChecked: {
              const decoded = decodeTransferCheckedInstruction(
                transactionInstruction,
                TOKEN_2022_PROGRAM_ID,
              );
              return {
                amount: new BN(decoded.data.amount.toString()),
                decimals: decoded.data.decimals,
                mint: decoded.keys.mint.pubkey,
                isNative: false,
                tokenProgram: TOKEN_2022_PROGRAM_ID,
              };
            }
            default:
              return null;
          }
        } else if (

        /**
         * P-NFT
         */
          transactionInstruction.programId.equals(METADATA_PROGRAM_ID)
        ) {
          const transferDecodedData = u8().decode(transactionInstruction.data);

          switch (transferDecodedData) {
            case transferInstructionDiscriminator: {
              const [decoded] = TransferStruct.deserialize(
                transactionInstruction.data,
              );

              return {
                amount: new BN(decoded.transferArgs.amount.toString()),
                decimals: 0,
                mint: transactionInstruction.keys[4].pubkey,
                isNative: false,
                tokenProgram: TOKEN_PROGRAM_ID,
              };
            }
            default:
              return null;
          }
        } else if (
          transactionInstruction.programId.equals(SystemProgram.programId)
        ) {
          const instructiontype = SystemInstruction.decodeInstructionType(
            transactionInstruction,
          );
          switch (instructiontype) {
            case "Transfer": {
              const decoded = SystemInstruction.decodeTransfer(
                transactionInstruction,
              );
              return {
                amount: new BN(decoded.lamports.toString()),
                decimals: 9,
                mint: null,
                isNative: true,
                tokenProgram: undefined,
              };
            }
            default:
              return null;
          }
        }
      }

      return null;
    },
});

export const queryParsedTransaction = selectorFamily<
  ParsedProposalInstruction[],
  string | undefined
>({
  key: "proposal/parsedTransactions",
  get:
    (proposalAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!proposalAddress) {
        return [];
      }

      const transactions = get(queryProposalTransactions(proposalAddress));

      if (!transactions || transactions.length === 0) {
        return [];
      }

      const instructions: Account<ProposalInstruction>[][] = transactions.map(
        (tx: Account<ProposalTransaction>) =>
          get(queryTransactionInstructions(tx.address.toBase58())),
      );
      if (!instructions || instructions.length === 0) {
        return [];
      }

      const transctionInstructions = instructions.flatMap((insts) =>
        insts.map(
          (inst) =>
            new TransactionInstruction({
              keys: inst.account.accounts,
              programId: inst.account.programId,
              data: Buffer.from(new Uint8Array(inst.account.data)),
            }),
        ),
      );
      let parsedTransctions: ParsedProposalInstruction[] = [];
      for (let i = 0; i < transctionInstructions.length; i++) {
        const transactionInstruction = transctionInstructions[i];
        if (transactionInstruction.programId.equals(TOKEN_PROGRAM_ID)) {
          const decodedData = u8().decode(transactionInstruction.data);

          switch (decodedData) {
            case TokenInstruction.TransferChecked: {
              const decoded = decodeTransferCheckedInstruction(
                transactionInstruction,
              );
              parsedTransctions.push({
                ...decoded,
                type: ParseTransactionTypes.TokenTransfer,
                keys: decoded.keys,
                data: {
                  ...decoded.data,
                  amount: new BN(decoded.data.amount.toString()),
                },
              });
            }
          }
        } else if (transactionInstruction.programId.equals(TOKEN_PROGRAM_ID)) {
          const decodedData = u8().decode(transactionInstruction.data);

          switch (decodedData) {
            case 0: {
              if (transctionInstructions.length < i + 1) {
                const transferDecodedData = u8().decode(
                  transactionInstruction.data,
                );

                switch (transferDecodedData) {
                  case TokenInstruction.TransferChecked: {
                    const decoded = decodeTransferCheckedInstruction(
                      transactionInstruction,
                    );
                    parsedTransctions.push({
                      ...decoded,
                      type: ParseTransactionTypes.TokenTransfer,
                      keys: decoded.keys,
                      data: {
                        ...decoded.data,
                        amount: new BN(decoded.data.amount.toString()),
                      },
                    });
                    break;
                  }
                  default: {
                    parsedTransctions.push({
                      programId: transactionInstruction.programId,
                      type: ParseTransactionTypes.Unknown,
                      data: transactionInstruction.data,
                      keys: transactionInstruction.keys,
                    });
                  }
                }
              }
            }
          }
        } else if (
          transactionInstruction.programId.equals(SystemProgram.programId)
        ) {
          const instructiontype = SystemInstruction.decodeInstructionType(
            transactionInstruction,
          );
          switch (instructiontype) {
            case "Transfer": {
              const decoded = SystemInstruction.decodeTransfer(
                transactionInstruction,
              );
              parsedTransctions.push({
                type: ParseTransactionTypes.TokenTransfer,
                keys: {
                  fromPubkey: {
                    isSigner: true,
                    isWritable: true,
                    pubkey: decoded.fromPubkey,
                  },
                  toPubkey: {
                    isSigner: true,
                    isWritable: true,
                    pubkey: decoded.toPubkey,
                  },
                },
                data: {
                  amount: new BN(decoded.lamports.toString()),
                },
                programId: transactionInstruction.programId,
              });
              break;
            }
            default: {
              parsedTransctions.push({
                programId: transactionInstruction.programId,
                type: ParseTransactionTypes.Unknown,
                data: transactionInstruction.data,
                keys: transactionInstruction.keys,
              });
            }
          }
        } else if (
          transactionInstruction.programId.equals(PROFILES_PROGRAM_ID)
        ) {
          const instructiontype = seq(u8(), 8).decode(
            transactionInstruction.data,
          );
          const coder = new BorshInstructionCoder(IDL);
          const decoded = coder.decode(transactionInstruction.data, "base58");
          if (arrayIsEqual(instructiontype, CREATE_PROFILE_IX_DISCRIMATOR)) {
            parsedTransctions.push({
              ...decoded,
              type: ParseTransactionTypes.CreateProfile,
              //@ts-ignore
              data: {
                //@ts-ignore
                ...decoded.data,
              },
              keys: {
                ...transactionInstruction.keys,
              },
            });
          } else if (arrayIsEqual(instructiontype, SET_PFP_IX_DISCRIMATOR)) {
            parsedTransctions.push({
              ...decoded,
              type: ParseTransactionTypes.SetProfile,
              //@ts-ignore
              data: {
                //@ts-ignore
                ...decoded.data,
              },
              keys: {
                ...transactionInstruction.keys,
              },
            });
          } else if (
            arrayIsEqual(instructiontype, CREATE_WALLET_TRACKER_IX_DISCRIMATOR)
          ) {
            parsedTransctions.push({
              ...decoded,
              type: ParseTransactionTypes.CreateWalletTracker,
              //@ts-ignore
              data: {
                //@ts-ignore
                ...decoded.data,
              },
              keys: {
                ...transactionInstruction.keys,
              },
            });
          } else if (arrayIsEqual(instructiontype, TRACK_PFP_IX_DISCRIMATOR)) {
            parsedTransctions.push({
              ...decoded,
              type: ParseTransactionTypes.TrackPfp,
              //@ts-ignore
              data: {
                //@ts-ignore
                ...decoded.data,
              },
              keys: {
                ...transactionInstruction.keys,
              },
            });
          } else {
            parsedTransctions.push({
              programId: transactionInstruction.programId,
              type: ParseTransactionTypes.Unknown,
              data: transactionInstruction.data,
              keys: transactionInstruction.keys,
            });
          }
        } else if (transactionInstruction.programId.equals(ALIGN_PROGRAM_ID)) {
          const instructiontype = seq(u8(), 8).decode(
            transactionInstruction.data,
          );
          const coder = new BorshInstructionCoder(AlignIDL);
          const decoded = coder.decode(transactionInstruction.data, "base58");
          if (arrayIsEqual(instructiontype, SET_THRESHOLD_IX_DISCRIMATOR)) {
            parsedTransctions.push({
              ...decoded,
              type: ParseTransactionTypes.SetWalletThreshold,
              //@ts-ignore
              data: {
                //@ts-ignore
                ...decoded.data,
              },
              keys: {
                ...transactionInstruction.keys,
              },
            });
          } else if (
            arrayIsEqual(instructiontype, ADD_COUNCIL_MEMBER_IX_DISCRIMATOR)
          ) {
            parsedTransctions.push({
              ...decoded,
              type: ParseTransactionTypes.AddCouncilMember,
              //@ts-ignore
              data: {
                //@ts-ignore
                ...decoded.data,
              },
              keys: {
                ...transactionInstruction.keys,
              },
            });
          } else if (
            arrayIsEqual(instructiontype, REMOVE_COUNCIL_MEMBER_IX_DISCRIMATOR)
          ) {
            parsedTransctions.push({
              ...decoded,
              type: ParseTransactionTypes.RemoveCouncilMember,
              //@ts-ignore
              data: {
                //@ts-ignore
                ...decoded.data,
              },
              keys: {
                ...transactionInstruction.keys,
              },
            });
          }

          // else if (arrayIsEqual(instructiontype, CREATE_PROFILE_IX_DISCRIMATOR)){
          //     parsedTransctions.push({
          //       ...decoded,
          //       type : ParseTransactionTypes.CreateWalletTracker,
          //       //@ts-ignore
          //       data : {
          //           //@ts-ignore
          //          ...decoded.data
          //       },
          //       keys : {
          //         ...transactionInstruction.keys
          //       }

          //  })
          // }

          // else if (arrayIsEqual(instructiontype, TRACK_PFP_IX_DISCRIMATOR)){
          //     parsedTransctions.push({
          //       ...decoded,
          //       type : ParseTransactionTypes.TrackPfp,
          //       //@ts-ignore
          //       data : {
          //           //@ts-ignore
          //          ...decoded.data
          //       },
          //       keys : {
          //         ...transactionInstruction.keys
          //       }

          //  })
          // }
          else {
            parsedTransctions.push({
              programId: transactionInstruction.programId,
              type: ParseTransactionTypes.Unknown,
              data: transactionInstruction.data,
              keys: transactionInstruction.keys,
            });
          }
        }
      }

      return parsedTransctions;
    },
});

// export const userServicingProposalsData = selectorFamily<Account<Proposal>[] | null, string | undefined>({
//     key: "proposals/userServicing",
//     get: (orgAddress : string | undefined) =>
//         async ({ get }: any) => {
//             const proposals : Account<Proposal>[] = get(proposalAccounts(orgAddress));
//             const isConnected: boolean = get(isLoggedIn)
//             if (isConnected) {
//                 const currentUserId : string = get(currentUserIdentifier) as string
//                 return proposals.filter(prop => prop.account.servicer !== null && prop.account.servicer.toBase58() === currentUserId)

//             }
//             return []

//         }
// })

// export const queryProposalKeys = selectorFamily<string[], string | undefined>({
//     key: "proposals/querykeys",
//     get: (organisationAddress : string | undefined) =>
//         async ({get}: any) => {
//             if(!organisationAddress){
//                 return []
//             }
//             get(currentOrganisation);
//             get(refreshProposalKeys(organisationAddress))
//             if (organisationAddress !== undefined){
//                 const connUrl = get(connectionUrl)

//                 const programs = await createAlignPrograms(
//                     new Connection(connUrl, "confirmed"),
//                     READ_MOCK_WALLET as any,
//                     new Connection(process.env.REACT_APP_SHADOW_RPC!, "max")
//                 );
//                 try{
//                     const proposals = await Api.fetchOrganisationProposalKeys(new PublicKey(organisationAddress), programs)
//                     return proposals.map(key => key.toBase58())

//                 }
//                 catch(e){
//                     console.warn(e)
//                     return []
//                 }

//             }

//             return []
//         }
// })

export const queryRankingProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/ranking",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(8, [4])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryCompleteProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/complete",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(8, [9])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryDeniedProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/denied",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(8, [10])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});
// prop.account.state?.reviewing !== undefined ||
// prop.account.state?.reviewed !== undefined ||
// prop.account.state?.executing !== undefined ||
// prop.account.state?.readyToExecute !== undefined

export const queryServicingProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/servicing",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(PROPOSAL_STATE_OFFSET, [6])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryDraftProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/draft",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(PROPOSAL_STATE_OFFSET, [0])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryUsersProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/user",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      const isLogged = get(isLoggedIn);
      get(refreshProposalKeys(organisationAddress));
      const userId = get(currentUserIdentifier);
      if (
        organisationAddress !== undefined &&
        userId !== undefined &&
        isLogged
      ) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [
              filterFactory(
                PROPOSAL_PROPOSER_OFFSET,
                new PublicKey(userId).toBuffer(),
              ),
            ],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryReadyToExecuteProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/ready-exec",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(PROPOSAL_STATE_OFFSET, [2])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryExecutingProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/executing",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(PROPOSAL_STATE_OFFSET, [3])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryReveiwedProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/reveiwed",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(PROPOSAL_STATE_OFFSET, [8])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryReveiwingProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/reveiwing",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(PROPOSAL_STATE_OFFSET, [7])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const queryVotingProposalKeys = selectorFamily<
  string[],
  string | undefined
>({
  key: "proposals/querykeys/voting",
  get:
    (organisationAddress: string | undefined) =>
    async ({ get }: any) => {
      if (!organisationAddress) {
        return [];
      }
      get(currentOrganisation);
      get(refreshProposalKeys(organisationAddress));
      if (organisationAddress !== undefined) {
        const connUrl = get(connectionUrl);

        const programs = await createAlignPrograms(
          new Connection(connUrl, "confirmed"),
          READ_MOCK_WALLET as any,
          new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
        );
        try {
          const proposals = await Api.fetchOrganisationProposalKeys(
            new PublicKey(organisationAddress),
            [filterFactory(PROPOSAL_STATE_OFFSET, [5])],
            programs,
          );
          return proposals.map((key) => key.toBase58());
        } catch (e) {
          console.warn(e);
          return [];
        }
      }

      return [];
    },
});

export const walletConfigs = selector<Account<WalletConfig>[]>({
  key: "governance/walletConfigs",
  get: async ({ get }: any) => {
    const organisation = get(currentOrganisation);

    if (!organisation) {
      return [];
    }
    const connUrl = get(connectionUrl);

    const programs = await createAlignPrograms(
      new Connection(connUrl, "confirmed"),
      READ_MOCK_WALLET as any,
      new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
    );
    try {
      const configs = await Api.fetchWalletConfigs(
        new PublicKey(organisation),
        programs,
      );
      return configs;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
});

export const queryGovernedTokenAccountInfo = selectorFamily<
  TokenAccount | undefined,
  string
>({
  key: "governance/tokenAccounts",
  get:
    (tokenAccountAddress) =>
    async ({ get }: any) => {
      const connUrl = get(connectionUrl);
      try {
        const tokenInfo = await getAccount(
          new Connection(connUrl, "confirmed"),
          new PublicKey(tokenAccountAddress),
        );
        return tokenInfo;
      } catch (e) {
        console.warn(e);
      }
    },
});

export const queryOrganisationAccount = selectorFamily<
  Account<Organisation> | null,
  string | undefined
>({
  key: "organisation/account",
  get:
    (organisation: string | undefined) =>
    async ({ get }: any) => {
      get(currentOrganisation);

      if (!organisation) {
        return null;
      }

      const connUrl = get(connectionUrl);

      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        const organisationAccount = await Api.fetchOrganisation(
          new PublicKey(organisation),
          programs,
        );
        return organisationAccount;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
});

export const queryCouncilManager = selectorFamily<
  Account<CouncilManager> | null,
  string | undefined
>({
  key: "organisation/councilmanager",
  get:
    (organisation: string | undefined) =>
    async ({ get }: any) => {
      get(currentOrganisation);
      if (!organisation) {
        return null;
      }
      get(refreshCouncil(organisation));

      const connUrl = get(connectionUrl);

      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        if (organisation === "B1urDqj8tGWBTFYB8x61sqfY9XN26nbyWqagHefpZaAr") {
          return null;
        }
        const councilManager = await Api.fetchCouncilManager(
          new PublicKey(organisation),
          programs,
        );
        return councilManager;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
});

export const queryCouncilVoteRecord = selectorFamily<
  Account<CouncilVoteRecord> | null,
  string | undefined
>({
  key: "proposal/councilCountribution",
  get:
    (proposalAddress: string | undefined) =>
    async ({ get }: any) => {
      const connUrl = get(connectionUrl);
      get(refreshCouncilVoteRecord(proposalAddress));
      const user: UserInfo = get(loggedInUserInfo);
      if (!user || !proposalAddress) {
        return null;
      }
      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        const contributionAddress = Derivation.deriveCouncilVoteRecord(
          user?.identity?.account.identifier,
          new PublicKey(proposalAddress),
        );
        const contributionRecord = await Api.fetchCouncilVoteRecord(
          contributionAddress,
          programs,
        );
        return contributionRecord;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
});

// export const queryUnclaimedReputation = selectorFamily<UnclaimedReputationContext[], string | undefined>({
//     key: "proposal/unclaimedReputation",
//     get: (userIdentifier : string | undefined) => ({get}: any) => {
//         const currentOrg = get(currentOrganisation)
//         if(!currentOrg){
//             return []
//         }
//         const completedproposals : Account<Proposal>[] = get(completedProposalAccounts(currentOrg))
//         const deniedProposals : Account<Proposal>[] = get(deniedProposalAccounts(currentOrg))
//         const proposals = [...deniedProposals, ...completedproposals]

//         if(!completedproposals || !deniedProposals || !userIdentifier || !currentOrg){
//             return []
//         }
//         const org : Account<Organisation> = get(queryOrganisationAccount(currentOrg))
//         if(!org){
//             return []
//         }
//         const identifier = new PublicKey(userIdentifier)
//         try {
//             const contribtionRecords = get(waitForNone(
//                 proposals.map((prop: Account<Proposal>) => queryContributionRecord(prop.address.toBase58()))
//             ));

//             return contribtionRecords
//               .filter(({state}: any) => state === 'hasValue')
//               .map(({contents}: any) => contents)
//               .filter((contributionRecord : Account<ContributionRecord>) => contributionRecord !== null)
//               .map((contributionRecord : Account<ContributionRecord>) => {
//                 const prop = proposals.find((proposal) => proposal.address.equals(contributionRecord.account.proposal))
//                 if(!prop){
//                     return null
//                 }
//                 const councilVoteDirection = prop.account.state?.denied ? CouncilVote.No : CouncilVote.Yes
//                 const userRankDirection = contributionRecord.account.upVoteCount > contributionRecord.account.downVoteCount ? RankVoteType.Upvote : RankVoteType.Downvote
//                 const userDidntRank = contributionRecord.account.downVoteCount.eq(new BN(0)) && contributionRecord.account.upVoteCount.eq(new BN(0))
//                 return JSON.parse(JSON.stringify({
//                     contributionRecord,
//                     isServicer : prop.account.servicer !== null && prop.account.servicer?.equals(identifier),
//                     isProposer : prop.account.proposer.equals(identifier),
//                     councilVoteDirection,
//                     userRankDirection,
//                     proposalTimestamp : prop.account.executedAt?.toNumber() || prop.account.deniedAt?.toNumber(),
//                     unclaimedReputation : calculateContributionClaimableReputation(
//                         org.account.config,
//                         contributionRecord.account,
//                         councilVoteDirection,
//                         userDidntRank ? null : userRankDirection,
//                         prop.account.proposer.equals(identifier),
//                         prop.account.servicer !== null && prop.account.servicer?.equals(identifier)
//                         )
//                 }))
//               })
//               .filter((res : any) => res !== null)

//         }
//         catch(e){
//             console.warn(e)
//             return []
//         }

//     }
// })

export const queryContributionRecord = selectorFamily<
  Account<ContributionRecord> | null,
  string | undefined
>({
  key: "proposal/countribution",
  get:
    (proposalAddress: string | undefined) =>
    async ({ get }: any) => {
      const connUrl = get(connectionUrl);
      get(refreshContributionRecord(proposalAddress));
      const user: UserInfo = get(loggedInUserInfo);
      if (!user || !proposalAddress) {
        return null;
      }
      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        const contributionAddress = Derivation.deriveContributionRecord(
          user?.identity?.account.identifier,
          new PublicKey(proposalAddress),
        );
        const contributionRecord = await Api.fetchContributionRecord(
          contributionAddress,
          programs,
        );
        return contributionRecord;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
});

export const queryOrganisationKeys = selector<string[]>({
  key: "organisations/queryKeys",
  get: async ({ get }: any) => {
    // const connUrl = get(connectionUrl)
    // // get(refreshContributionRecord(proposalAddress))
    // const programs = await createAlignPrograms(
    //     new Connection(connUrl, "confirmed"),
    //     READ_MOCK_WALLET as any,
    //     new Connection(process.env.REACT_APP_SHADOW_RPC!, "max")
    // );
    // try {
    //     const organisations = await Api.fetchOrganisations(programs)
    //     return organisations.map(x => x.toBase58())
    // }
    // catch(e){
    //     console.warn(e)
    //     return []
    // }

    return [];
  },
});

export const queryOrganisationAccounts = selector<Account<Organisation>[]>({
  key: "organisations/accounts",
  get: async ({ get }: any) => {
    const connUrl = get(connectionUrl);
    const organisationKeys = get(queryOrganisationKeys);

    const accountsLoadables = get(
      waitForNone(
        organisationKeys.map((address: string) =>
          queryOrganisationAccount(address),
        ),
      ),
    );

    return accountsLoadables
      .filter(({ state }: any) => state === "hasValue")
      .map(({ contents }: any) => contents);
  },
});

export const proposalRankingKeys = atomFamily<string[], string | undefined>({
  key: "proposals/ranking/keys",
  default: (param) => queryRankingProposalKeys(param),
});

export const currentOrganisation = atom<string | undefined>({
  key: "organisation/current",
  default: queryCustomDomainOrganisation,
});

export const organisationKeys = atom<string[]>({
  key: "organisations/keys",
  default: queryOrganisationKeys,
});

export const currentProposal = atom<string | undefined>({
  key: "proposals/current",
  default: undefined,
});

export const refreshProposalKeys = atomFamily<number, string | undefined>({
  key: "refresh/proposals",
  default: 0,
});

export const refreshContributionRecord = atomFamily<number, string | undefined>(
  {
    key: "refresh/contribution",
    default: 0,
  },
);

export const refreshCouncilVoteRecord = atomFamily<number, string | undefined>({
  key: "refresh/councilVote",
  default: 0,
});

export const refreshCouncil = atomFamily<number, string | undefined>({
  key: "refresh/council",
  default: 0,
});

export const refreshProposal = atomFamily<number, string | undefined>({
  key: "refresh/proposal",
  default: 0,
});

export const proposalCursorState = atom<string[]>({
  key: "proposal/cursor-state",
  default: [],
});

export const proposalItemsPerPage = atom<number>({
  key: "proposal/q-per-page",
  default: 8,
});
