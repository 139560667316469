import { useState } from "react";
import { Recipient } from "../ProposalWizardSendTokens";
import { NFT } from "../ProposalWizardSendTokens/components/NFTItem";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ArrowRight } from "react-feather";
import { truncateKey } from "../../../../../utils/truncateKey";

type NFTSummaryProps = {
  nftsToSend: Record<string, { nft: NFT; recipient: Recipient }>
}

export const NFTSummary = ({ nftsToSend }: NFTSummaryProps) => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <div className="w-full bg-contrast bg-opacity-10 p-4 min-h-28 rounded flex flex-col gap-4">
      <div className={`flex w-full ${isOpen ? "justify-end py-2" : "justify-between"}  items-center`}>
        <div className={`flex gap-2 items-end ${isOpen ? "hidden" : ""}`}>
          {Object.entries(nftsToSend).map(([key, value], index) => (
            <div>
              <img className={`w-7 ${index === 0 && "w-9"} rounded-md ${index > 4 && "hidden"}`} src={value?.nft?.metadata?.image} alt={value?.nft?.metadata?.name} />
            </div>
          ))}
          {Object.entries(nftsToSend).length > 5 && <div className="contrast text-contrast text-opacity-50 font-thin text-sm">+{Object.entries(nftsToSend).length - 5} more</div>}
        </div>
        <div className="flex gap-1 text-xs justify-center items-center font-light">
          <label className="flex gap-2 justify-start items-center w-[150px] bg-contrast bg-opacity-10 px-2 text-sm h-6">
            To {new Set(Object.entries(nftsToSend).map(([key, value]) => value?.recipient?.walletAddress)).size} Recipient(s)
          </label>
          <button className={`rounded-full bg-contrast bg-opacity-10 p-1 w-5 transform transition-all duration-300 ${isOpen ? "rotate-180" : ""}`} >
            <ChevronDownIcon onClick={() => setisOpen((prev) => !prev)} />
          </button>
        </div>
      </div>
      {
        isOpen && Object.entries(nftsToSend).map(([key, value]) => (
          <div className="flex justify-center items-center gap-4">
            <img className="w-9 rounded-md" src={value?.nft?.metadata?.image} alt={value?.nft?.metadata?.name} />
            <div className="flex-1 flex items-center">
              <hr className="w-full border-accent border" />
              <ArrowRight className="text-accent" />
            </div>
            <label className="flex gap-2 bg-contrast bg-opacity-10 px-2 h-7 text-sm items-center">
              {value?.recipient?.name}
              <p className="text-xs text-opacity-25">{truncateKey(value?.recipient.walletAddress)}</p>
            </label>
          </div>
        ))
      }
    </div>
  )
}
