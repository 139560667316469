import { useEffect } from "react";
import { useCurrentOrganisation } from "../state/hooks/useAlignGovernance";
import { useStyle } from "../state/hooks/useStyle";

const CustomStyler = ({children}: {children : React.ReactNode}) => {

    const [organisation] = useCurrentOrganisation()
    const customStyle = useStyle(organisation)

    useEffect(() => {
        let style = document.getElementById('customStyle');
        if(style === null){
            style = document.createElement('style')
            style.id = "customStyle"
            document.head.append(style);
        }
        style.textContent = customStyle.css;

    }, [customStyle, organisation])

    return ( 
        <>
        {children}
        </>
     );
}
 
export default CustomStyler;