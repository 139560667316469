import { useApolloClient } from "@apollo/client";
import { PublicKey } from "@solana/web3.js";
import {
    castRankVote,
    RankVoteType,
	SEND_TX_STATUS
} from "align-sdk";
import { delay } from "lodash";
import { useMemo } from "react";
import {
    ChevronDown,
    ChevronUp,
} from "react-feather";
import toast from "react-hot-toast";
import { useGetReputationQuery } from "../generated/graphql";
import { useAlignPrograms, useAuth } from "../state/hooks/useAlignGovernance";
import { ContributionRecordDto } from "../types";
import { Proposer } from "./Proposal";
import { REFRESH_DELAY } from "../constants";

export function CastVote({
    address,
    rankingTotal,
    proposer,
    state,
    organisationAddress,
    contributionRecord,
}: {
    address: string,
    state: string,
    proposer: Proposer | undefined | null,
    rankingTotal: number,
    contributionRecord: ContributionRecordDto | null | undefined,
    organisationAddress : string,
    }) {
	const alignPrograms = useAlignPrograms()
	const totalVotes = useMemo(() => contributionRecord?.downVoteCount + contributionRecord?.upVoteCount, [contributionRecord?.downVoteCount, contributionRecord?.upVoteCount])
	const user = useAuth()
	const client = useApolloClient()
	const {data, loading, error, refetch} = useGetReputationQuery({
		variables: {
			userIdentityAddress: user?.identity?.identityAddress || "",
			organisationAddress: organisationAddress
		},
		skip: user?.identity?.identityAddress === undefined
	})

    return (
		<div className={`cast-vote-container box-container self-start rounded-box border-boxWidth border-contrast border-opacity-30 p-2 text-center`}>
			<button
				onClick={async () => {
					if (
						!user?.identity ||
						!data?.reputation ||
						!alignPrograms 
					) {
						return;
					}
					try {
						toast.loading("Voting for proposal.", {id: "castRank"})

						const res = await castRankVote(
							new PublicKey(user.identity.identifier),
							new PublicKey(address),
							RankVoteType.Upvote,
							contributionRecord? data.reputation.totalReputation - contributionRecord.upVoteCount : data.reputation.totalReputation,
							alignPrograms,
							 {
								onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
									<div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
										<b>{`${res.count}/${res.total}: Sending Transactions`}</b>
										<div>Failed transactions will try again..</div>
										<div>Please do not refresh or exit..</div>
									</div>, {
										id: "castRank"
								}),
								onTransactionFailure: (res: any) => toast.error(
									<div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
										<div>Error:</div>
										<div>{`${res}`}</div>
									</div>, {
										id: "castRank"
								})
                			}
						)
						// , {
						// 	loading: "Voting for proposal.",
						// 	success: "Vote created successfully!",
						// 	error: (e) => e.toString(),
						// })
	  				 if(res.status === SEND_TX_STATUS.SUCESSFUL){
						toast.success("Vote created successfully!", {id: "castRank"})

						delay(async () => {
							await client.resetStore();
							await refetch()
						}, REFRESH_DELAY)
					}
					else{
						toast.error("Error voting for proposal. Please try again.", {id: "castRank"})
					}
					} catch (err) {
						toast.error("Error voting for proposal. Please try again.", {id: "castRank"})
						console.error(err);
						return;
					}
				}}
				className={`flex h-5 w-5 items-center justify-center rounded-md border border-white  opacity-50 hover:opacity-100 ${contributionRecord?.upVoteCount > 0 ? "border-accent opacity-100 border-1" : "" }`}
				disabled={state !== "Ranking" || contributionRecord?.downVoteCount > 0 || (contributionRecord?.upVoteCount === totalVotes && contributionRecord?.upVoteCount !== 0) || proposer?.identity?.identifier === user?.identity?.identifier}
			>
				<ChevronUp className={`${contributionRecord?.upVoteCount > 0 ? "text-accent font-semibold" : "" }`} size={"16px"} />
			</button>
			<span className="my-2 block font-mono">
				{ rankingTotal}
			</span>

			<button
				onClick={async () => {
					if (
						!user?.identity ||
						!data?.reputation ||
						!alignPrograms 
					) {
						return;
					}
					try {
						const sig = await toast.promise(castRankVote(
							new PublicKey(user.identity.identifier),
							new PublicKey(address),
							RankVoteType.Downvote,
							contributionRecord? data.reputation.totalReputation - contributionRecord.downVoteCount : data.reputation.totalReputation,
							alignPrograms
						), {
							loading: "Voting for proposal.",
							success: "Vote created successfully!",
							error: (e) => e.toString(),
						})
						// Need to then update in the store. refresh proposal??	
						delay(async () => {
						await client.resetStore();
						await refetch()
					}, REFRESH_DELAY)
					
					
					} catch (err) {

						console.error(err);
						return;
					}
				}}
				className={`flex h-5 w-5 items-center justify-center rounded-md border border-white text-contrast opacity-50 hover:opacity-100 ${contributionRecord?.downVoteCount > 0 ? "border-accent opacity-100 border-1" : "" }`}
				disabled={state !== "Ranking" || contributionRecord?.upVoteCount > 0 || (contributionRecord?.downVoteCount === totalVotes && contributionRecord?.downVoteCount !== 0 || proposer?.identity?.identifier === user?.identity?.identifier)}
			>
				<ChevronDown className={`${contributionRecord?.downVoteCount > 0 ? "text-accent" : "" }`} size={"16px"} />
			</button>
		</div>
	)}