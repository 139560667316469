import { ArrowsUpDownIcon, BriefcaseIcon, BuildingLibraryIcon, CurrencyDollarIcon, PhotoIcon } from "@heroicons/react/24/outline"
import { Suspense, useState } from "react"
import { AlignLink } from "../components/AlignLink"
import ProposalWizardSelector, { WizardEntryPoint } from "../components/CreateProposalWizard/ProposalWizardSelector"
import { useCurrentOrganisation } from "../state/hooks/useAlignGovernance"

export function CreateProposalWizard(){

    const [currentStep, setCurrentStep] = useState(0)
    const [currentOrg] = useCurrentOrganisation()

    const nextStep = () => {
        if (currentStep === 5){
            return
        }

        setCurrentStep(currentStep + 1)
        
    }

    const previousStep = () => {
        if(currentStep ===1){
            return
        }

        setCurrentStep(currentStep -1)
    }

    return(
        <div className="mx-auto px-1 md:px-16 max-w-screen-2xl pb-8 md:py-8 text-contrast">
            <div className="flex-wrap flex justify-center gap-6">            

            <Suspense>
                <ProposalWizardSelector>
                    <WizardEntryPoint
                        title="Service Proposal"
                        description="A proposal for services completed by you or another DAO member."
                        route={"service"}
                        icon={<BriefcaseIcon className="w-16 text-accent"/>}
                    />
                    <WizardEntryPoint
                        title="Adjust Council"
                        description="Create a proposal to adjust the council members of the DAO"
                        route={"council"}
                        icon={<BuildingLibraryIcon className="w-16 text-accent"/>}
                    />
                    <WizardEntryPoint
                        title="Send Assets"
                        description="Create a proposal to send treasury assets"
                        route={"send"}
                        icon={<ArrowsUpDownIcon className="w-16 text-accent"/>}
                        disabled={false}
                    />
                   
                    <AlignLink
                        orgAddress={currentOrg}
                        path={"/proposal/create"}
                    >
                        <WizardEntryPoint
                            title="Manual"
                            description="Create a proposal manually by crafting your own transactions."
                            route={"create"}
                            icon={<PhotoIcon className="w-16 text-accent"/>}
                        />
                    </AlignLink>

                    <WizardEntryPoint
                        title="Swap Tokens"
                        description="Create a proposal to swap treasury tokens"
                        route={"swap"}
                        icon={<CurrencyDollarIcon className="w-16 text-accent"/>}
                        disabled={true}
                    />
             
                    <WizardEntryPoint
                        title="Purchase NFT"
                        description="Creates a proposal to purchase nfts from a marketplace."
                        route={"nft"}
                        icon={<PhotoIcon className="w-16 text-accent"/>}
                        disabled={true}
                    />
                </ProposalWizardSelector>
               
            </Suspense>

        </div>
        </div>
    )
}