import { ForwardIcon, PencilSquareIcon } from "@heroicons/react/24/solid"
import { useConnection } from "@solana/wallet-adapter-react"
import { PublicKey } from "@solana/web3.js"
import { Api, SEND_TX_STATUS, createOrganisation, createOrganisationProfileTransaction, getMetadataAccount } from "align-sdk"
import BN from "bn.js"
import { sha256 } from 'js-sha256'
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { councilMembersFormInput, organisationConfigFormInput, organisationProfileFormInput, previousCreatedOrganisations, thresholdsFormInput } from "../../../state/forms"
import { useAlignPrograms, useAuth } from "../../../state/hooks/useAlignGovernance"
import { DescriptionPanel } from "../../DescriptionPanel"
import { WizardStepProps } from "../../CreateProposalWizard/Wizard"
import { titleToStepMap } from "../CreateOrganisationWizard"

type SummaryStepProps = WizardStepProps<{
  rankingPeriod : number,
  proposalMinReputationGate : number,
}> & {
  setCurrentStep : (step : number) => void
}

export function SummaryStep({goBack, setCurrentStep}: SummaryStepProps){
  const alignPrograms = useAlignPrograms()
  const configInputs = useRecoilValue(organisationConfigFormInput)
const setConfigInputs = useSetRecoilState(organisationConfigFormInput)

  const councilInputs = useRecoilValue(councilMembersFormInput)
    const configHash = useMemo(() => sha256(`${JSON.stringify(configInputs)}${JSON.stringify(councilInputs)}`), [councilInputs, configInputs])
    const [prevCreatedOrgs, setPrevCreatedOrgs] = useRecoilState(previousCreatedOrganisations(configHash))
  const thresholdInputs = useRecoilValue(thresholdsFormInput)
  const profileInputs = useRecoilValue(organisationProfileFormInput)
  const navigate = useNavigate()
  const [organisationIsCreated, setOrganisationIsCreated] = useState(false)
  const [organisationDetails, setOrganisationDetails] = useState<{
      organizationIdentifier: string;
      organizationAddress: string;
      sig: string[];
      treasuryWallet: string;
      treasuryConfigAddress: string;
      organisationAuthorityConfigAddress : string
  }| null>(null)

  const {connection} = useConnection()
  const user = useAuth()
  const [name, setName] = useState<string | null>(null);
  const [nftUri, setNftUri] = useState<string | null>(null);
  const [notFound, setNotFound] = useState(false)
  const isPfpLoading = useMemo(() => (name === null || nftUri === null) && notFound === false, [nftUri, name, notFound])

  const resetConfigInputs = useResetRecoilState(organisationConfigFormInput);
  const resetCouncilInputs = useResetRecoilState(councilMembersFormInput);
  const resetThresholdInputs = useResetRecoilState(thresholdsFormInput);
  const resetProfileInputs = useResetRecoilState(organisationProfileFormInput);

  const resetAllInputs = () => {
    resetConfigInputs();
    resetCouncilInputs();
    resetThresholdInputs();
    resetProfileInputs();
  };

  const handleCreateProfile = async () => {

    //   if(prevCreatedOrgs.profileProposal !== null && prevCreatedOrgs.oragnisation !== null){
    //     navigate(`/org/${prevCreatedOrgs.oragnisation.organizationAddress}/proposal/${prevCreatedOrgs.profileProposal}`)
    //   }
    const orgDetails = organisationDetails || prevCreatedOrgs.oragnisation

      if(!alignPrograms 
          || !configInputs?.rankingPeriod 
          || !configInputs?.collectionItems 
          || !thresholdInputs?.treasuryThreshold
          || !thresholdInputs?.configThreshold
          || !profileInputs
          || !profileInputs?.pfpMint
          || !user?.identity
          || !orgDetails
          ){
          toast.error("There was a problem preparing the transaction. If the problem persists please contact us.")
          return
      }

      toast.loading("Creating the proposal for your organisation profile", {
        id: "createProposal"
      })

      if(!organisationDetails) {
        toast.error("There was a problem preparing the transaction, organisation details not found.")
        return
      }

      const {sigs, proposalAddress, status} = await createOrganisationProfileTransaction(
          '@' + profileInputs?.handle,
          profileInputs?.displayName,
          new PublicKey(profileInputs?.pfpMint),
          new PublicKey(user.identity.identifier),
          new PublicKey(organisationDetails.organizationAddress),
          new PublicKey(organisationDetails.organisationAuthorityConfigAddress),
          alignPrograms,
          {
             onCreatingDrive: () => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Creating a shadow drive to upload your proposal metadata to.</div>
                            <div>This may take some time..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onCreateDrive: (res) => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Drive created successfully..</div>
                            <div>Uploading metadata..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onUpload: (res) => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Upload complete!</div>
                            <div>Creating your proposal..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onUploadRetry: () => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Uploading metadata failed!</div>
                            <div>Trying again..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
            onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
                    <b>{`${res.count}/${res.total}: Sending Transactions`}</b>
                    <div>Failed transactions will try again..</div>
                    <div>Please do not refresh or exit..</div>
                </div>, {
                    id: "createProposal"
                }),
                onTransactionFailure: (res: any) => toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>Error:</div>
                    <div>{`${res}`}</div>
                </div>, {
                    id: "createProposal"
                })}
      )

      toast.success("Successfully created your organisation profile proposal.", {id: "createProposal"})
    //   {
    //       loading : "Creating the proposal for your organisation profile",
    //       success: "Successfully created your organisation profile proposal.",
          
    //       error:(e) => {
    //           console.error(e)
    //           return e.toString() 
    //       }
    //   })

      if(status === SEND_TX_STATUS.SUCESSFUL){
        setPrevCreatedOrgs(val => ({...val, profileProposal: proposalAddress.toBase58()}))
        navigate(`/org/${organisationDetails.organizationAddress}/proposal/${proposalAddress}`)
        resetAllInputs()
      }
  }

  const handleCreateOrganisation = async () => {
      if(!alignPrograms 
          || !configInputs?.rankingPeriod 
          || !configInputs?.collectionItems 
          || !thresholdInputs?.treasuryThreshold
          || !thresholdInputs?.configThreshold
          || !profileInputs
          || !profileInputs?.pfpMint
          || !user?.identity
          ){
          toast.error("There was a problem preparing the transaction. If the problem persists please contact us.")
          return
      }

      const profilesPromise = councilInputs.map(handle => Api.fetchUserProfileByUsername(handle, alignPrograms))

      const councilProfiles = await Promise.all(profilesPromise)
      const councilIdentifiers = councilProfiles.filter(x => x !== null ).map(p => p.account.identifier)
      
      if(councilIdentifiers.length !== councilInputs.length){
          toast.error("There was an error with fetching council user identifiers for the handles provided. If the problem persists please contact us.")
      }

      const configThresholdPrecentage = Math.floor(thresholdInputs.configThreshold / councilInputs.length * 10000)
      const treasuryThresholdPrecentage = Math.floor(thresholdInputs.treasuryThreshold / councilInputs.length * 10000)
        toast.loading("Creating your organisation.", {
        id: "createProposal"
      })
      const res = await createOrganisation(
          {
              votingRepReward: 1,
              votingInAlignmentReward: 1,
              proposalServicedReward: 25,
              proposalCreatedReward: 5,
              nftRequireForActions: true,
              rankingPeriod: new BN(configInputs.rankingPeriod * 24 * 60 * 60),
              proposalMinReputationGate: new BN(1),
              collectionItems: configInputs.collectionItems.map(item => ({...item, mint: new PublicKey(item.mint)}))
          },
          treasuryThresholdPrecentage,
          configThresholdPrecentage,
          configThresholdPrecentage,
          councilIdentifiers,
          alignPrograms,
          {
             onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
                        <b>{`${res.count}/${res.total}: Sending Transactions`}</b>
                        <div>Failed transactions will try again..</div>
                        <div>Please do not refresh or exit..</div>
                    </div>, {
                        id: "createProposal"
                    }),
                    onTransactionFailure: (res: any) => toast.error(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Error:</div>
                        <div>{`${res}`}</div>
                    </div>, {
                        id: "createProposal"
                    })
          }
      )

    //   ),
    //       {
    //           loading : "Creating your organisation",
    //           success: "Successfully created your organisation.",
    //           error:(e) => {
    //               console.error(e)
    //               return e.toString() 
    //           }
    //       }
    //   )
      if(res.status === SEND_TX_STATUS.SUCESSFUL) {
        toast.success("Successfully created your organisation.", {id: "createProposal"})
          setOrganisationIsCreated(true)
          const org =  {
                organisationAuthorityConfigAddress: res.organisationAuthorityConfigAddress.toBase58(),
                organizationAddress: res.organizationAddress.toBase58(),
                organizationIdentifier: res.organizationIdentifier.toBase58(),
                sig: res.sig,
                treasuryConfigAddress: res.treasuryConfigAddress.toBase58(),
                treasuryWallet: res.treasuryWallet.toBase58()
            }
          setOrganisationDetails(org)
          setPrevCreatedOrgs({
            oragnisation: org,
            configHash: configHash,
            profileProposal: null,
            date: Date.now()
          })
      }
      else {
        toast.error("There was an error creating your organisation", {id: "createProposal"})
      }
      console.log("organisation address:", res.organizationAddress.toBase58())

  }

  useEffect(() => {
      const fetchProfile = async () => {
          if(!profileInputs?.pfpMint){
              return
          }
          setNotFound(false)
          try{
              const metadataAccount = await getMetadataAccount(new PublicKey(profileInputs?.pfpMint), connection)
              const uri = metadataAccount.data.uri
              const response = await fetch(uri)
              if(!response.ok){
                  setNotFound(true)
                  return
              }
              const offchainMetadata = await response.json()
              setName(metadataAccount.data.name)
              setNftUri(offchainMetadata.image)
          }
          catch(e){
              console.log(e)
              setNotFound(true)
          }
          
      }
      fetchProfile()
  }, [profileInputs?.pfpMint]);

 return(
 <>
      <DescriptionPanel message="Time to review your organisation before we process the transactions."/>

      <div className=" flex flex-col gap-4 rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 w-full p-6 lg:col-span-2">

          <div className="mt-2 flex justify-between items-start px-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5">
            <div className="flex justify-center flex-grow">
              <div className="" >
                {nftUri ? <img className="w-24 rounded-full" src={nftUri}/> : (
                    <span className={`h-${24} w-${24} rounded-full flex items-center justify-center border relative border-white border-opacity-30`}>
                    <svg className={`w-${24/2} h-${24/2} text-gray-400`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
                    </span>
                ) }
              </div>
              <p className="flex justify-center flex-col pl-3">
                <span className="block text-lg font-light">
                    {profileInputs?.displayName}
                </span>
                <span className="block text-sm font-light text-contrast text-opacity-40">
                    {"@" + profileInputs?.handle}
                </span>
              </p>
            </div>
            <button className="text-contrast text-opacity-60 hover:bg-contrast hover:bg-opacity-10 rounded-md p-1 self-start" onClick={() => setCurrentStep(titleToStepMap["Organisation Profile"])}>
              <PencilSquareIcon className="w-5"/>
            </button>
          </div>
          
          <div className=" w-full text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3">
            <div className="flex justify-between items-center">
              <label className="font-light text-contrast text-opacity-60">Council Members</label>
              <button className="text-contrast text-opacity-60 hover:bg-contrast hover:bg-opacity-10 rounded-md p-1" onClick={() => setCurrentStep(titleToStepMap["Council Members"])}>
                <PencilSquareIcon className="w-5"/>
              </button>
            </div>
              <div className="py-3 flex w-full items-center flex-wrap gap-4">
                  {councilInputs.map(member => (
                      <div className="border p-1 rounded-md" key={member}>
                          {member}
                      </div>
                  ))}
              </div>
         
          </div>

          <div className=" w-full text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3">
              {/* <label className="font-light text-contrast text-opacity-60">Governance Thresholds</label> */}
              <div className="py-1 flex-col w-full items-start flex flex-wrap gap-4">
                  <div className="flex flex-col gap-2 w-full">
                      <div className="flex justify-between items-center">
                        <label className="font-light text-contrast text-opacity-60">Configuration Governance</label>
                        <button className="text-contrast text-opacity-60 hover:bg-contrast hover:bg-opacity-10 rounded-md p-1" onClick={() => setCurrentStep(titleToStepMap["Thresholds"])}>
                          <PencilSquareIcon className="w-5"/>
                        </button>
                      </div>
                      <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">{thresholdInputs?.configThreshold} / {councilInputs.length}</div>
                      </div>
                  <div className="flex flex-col gap-2">
                      <label className="font-light text-contrast text-opacity-60">Treasury Governance</label>
                      <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">{thresholdInputs?.treasuryThreshold} / {councilInputs.length}</div>
                  </div>
              </div>
         
          </div>


          <div className=" w-full text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3">
              {/* <label className="font-light text-contrast text-opacity-60">Governance Thresholds</label> */}
              <div className="py-1 flex-col w-full items-start flex flex-wrap gap-4">
                  <div className="flex flex-col gap-2 w-full">
                      <div className="flex justify-between items-center">
                        <label className="font-light text-contrast text-opacity-60">Ranking Period</label>
                        <button className="text-contrast text-opacity-60 hover:bg-contrast hover:bg-opacity-10 rounded-md p-1" onClick={() => setCurrentStep(titleToStepMap["Organisation Config"])}>
                          <PencilSquareIcon className="w-5"/>
                        </button>
                      </div>
                      <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">{configInputs?.rankingPeriod} Days</div>
                  </div>
              </div>
         
          </div>

          <div className=" w-full text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3">
              {/* <label className="font-light text-contrast text-opacity-60">Governance Thresholds</label> */}
              <div className="py-1 flex-col w-full items-start flex flex-wrap gap-4">
                  <div className="flex flex-col gap-6 w-full">
                      <div className="flex justify-between items-center">
                        <label className="font-light text-contrast text-opacity-60">Collection Mints</label>
                        <button className="text-contrast text-opacity-60 hover:bg-contrast hover:bg-opacity-10 rounded-md p-1" onClick={() => setCurrentStep(titleToStepMap["Organisation Config"])}>
                          <PencilSquareIcon className="w-5"/>
                        </button>
                      </div>
                      {configInputs?.collectionItems.map(item => (
                          <div className="flex flex-col gap-2">
                              <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">{item.mint}</div>
                              <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">{item.repMultiplier}x REP Multiplier</div>
                          </div>
                      ))}

                      </div>
    
              </div>
         
          </div>
                      <button
                      className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-[var(--button-radius)] 
                       
                        
                    
                       
                      transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                      onClick={handleCreateOrganisation}
                      disabled={(prevCreatedOrgs.oragnisation !== null && prevCreatedOrgs.profileProposal === null)}
                      >
                      <ForwardIcon className={`w-4 `}/>
                      Create Organisation
              </button>

              <button
                          className="w-full flex justify-center gap-2 items-center mt-1 self-end bg-accent rounded-[var(--button-radius)] 
                           
                            
                        
                           
                          transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                          onClick={handleCreateProfile}
                          disabled={(prevCreatedOrgs.oragnisation === null || prevCreatedOrgs.profileProposal !== null)}
                          >
                          <ForwardIcon className={`w-4 `}/>
                          Create Organisations Profile
                  </button>
                  <button
                        className="w-full flex justify-center gap-2 items-center mt-4 self-end border rounded-button border-primary 
                        transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                        onClick={() => goBack()}
                    >
                        <ForwardIcon className={`w-4 transform rotate-180`}/>
                        Go back
                    </button>
      

                  

      </div>
  </>     
 )

}