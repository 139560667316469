export function DiscoverSkeleton() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <DiscoverSkeletonItem/>
      <DiscoverSkeletonItem/>
      <DiscoverSkeletonItem/>
      <DiscoverSkeletonItem/>
      <DiscoverSkeletonItem/>
    </div>
  );
}

export const DiscoverSkeletonItem = () => 
  <div className="animate-pulse box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 py-6 px-6">
      <div className="flex p-3 gap-3 justify-start items-center text-contrast">
          <span className="block rounded-full bg w-24 aspect-square bg-gray-700" ></span>
          <div className="flex h-full flex-col justify-center">
              <div className="flex gap-3 justify-center items-center">
                  <p className="w-full h-4 bg-gray-700 rounded-full mb-1"></p>
              </div>
              <p className="w-36 h-2 bg-gray-700 rounded-full mb-1"></p>
              <p className="w-12 h-2 bg-gray-700 rounded-full mb-1"></p>
          </div>
      </div>
  </div>
