import { web3 } from "@project-serum/anchor";
import { useWallet } from "@solana/wallet-adapter-react";
import { Api, SEND_TX_STATUS, followOrganization, trackNfts } from "align-sdk";
import { Suspense, useEffect, useState } from "react";
import toast from "react-hot-toast";
import CreateProfilelModal from "./CreateProfileModal";

import { useApolloClient } from "@apollo/client";
import { PublicKey } from "@solana/web3.js";
import { delay } from "lodash";
import { useGetOrganisationQuery, useGetReputationQuery } from "../generated/graphql";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useRefreshReputation } from "../state/hooks/useAlignGovernance";
import MintProfilePicture from "./MintProfilePicture";
import { REFRESH_DELAY } from "../constants";

export function OrganisationHeader() {
    const [currentOrgAddress] = useCurrentOrganisation()

    const organisation = useGetOrganisationQuery({
        variables: {
          address: currentOrgAddress ||  "",
          take: 0
        } 
      })    
    
    const user = useAuth();
    const wallet = useWallet()
    const client = useApolloClient()

    const reputationResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: user?.userIdentityAddress || "",
            organisationAddress : currentOrgAddress || "" 
        },
        skip: user?.userIdentityAddress === undefined || currentOrgAddress === undefined

    })
    const alignPrograms = useAlignPrograms()
    const refreshRep = useRefreshReputation(user?.identity?.identifier)
    const [untrackedNfts, setUntrackedNfts] = useState<PublicKey[]>([]);

    const [modalOpen, setModalOpen] = useState(false);

    const joinOrganization = () => {
        if( !user?.hasProfile ) {
            setModalOpen(true);
            return;
        }
		if( !alignPrograms || !currentOrgAddress ) return;
		const joinOrgTx = async() => {
			if( !wallet.publicKey ) return;
			await toast.promise(
				followOrganization(
					new web3.PublicKey(currentOrgAddress),
                    // nfts.map(x => new web3.PublicKey(x.account)),
                    [],
                    wallet.publicKey,
					alignPrograms
				), {
				loading: "Joining organization...",
				success: "Successfully joined this org!",
				error: (e) => e.toString(),
			  });
            // await trackNFTsTx()

            delay(async () => {
                await reputationResponse.refetch()
                await user.refreshLoggedInUser()
            }, REFRESH_DELAY)
		}

        joinOrgTx()

	}

   useEffect(() => {

        const fetchUntrackedNfts = async () => {
            if(user?.identity?.identifier === undefined || !currentOrgAddress || !alignPrograms){
                return
            }
            const nfts = await Api.fetchUntrackedTokensForUser(new PublicKey(user?.identity?.identifier), new PublicKey(currentOrgAddress), alignPrograms)
            setUntrackedNfts(nfts)
        } 

    fetchUntrackedNfts() 
   }, [user?.identity?.identifier, wallet?.publicKey])

    const trackNFTsTx = async() => {
        if( !organisation?.data?.organisation.address || !wallet.publicKey || !user?.identity  || !alignPrograms || untrackedNfts.length === 0 ) return;

            toast.loading("Initiating NFT tracking for REP.", {id:"trackNfts"})

            const res = await trackNfts(
                new PublicKey(user?.identity?.identifier),
                new PublicKey(organisation.data?.organisation.address),
                untrackedNfts,
                wallet.publicKey,
                alignPrograms,
                {
                     onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
                            <b>{`${res.count}/${res.total}: Sending Transactions`}</b>
                            <div>Failed transactions will try again..</div>
                            <div>Please do not refresh or exit..</div>
                        </div>, {
                            id: "trackNfts"
                    }),
                    onTransactionFailure: (res: any) => toast.error(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Error:</div>
                            <div>{`${res}`}</div>
                        </div>, {
                            id: "trackNfts"
                    })
                }
            )

            
        //     {
        //     loading: "Initiating NFT tracking for REP...",
        //     success: "Successfully tracking for REP!",
        //     error: (e) => e.toString(),
        // });
        if(res.status === SEND_TX_STATUS.SUCESSFUL){
            toast.success("Successfully tracking for REP!", {id: "trackNfts"})
            delay(async () => {
                await client.resetStore()
                await reputationResponse.refetch()
                await user.refreshLoggedInUser()
            }, REFRESH_DELAY)
        }
        else{
            toast.error("There was an error tracking your nfts. Please try again.", {id:"trackNfts"})
        }

    }

    return (
        <div className="flex p-3 gap-3 justify-start items-center">
            {organisation.data?.organisation.profile?.pfp && <MintProfilePicture width={24} mint={organisation.data?.organisation.profile?.pfp}/>}
            <div className="flex h-full flex-col justify-center">
                <div className="flex gap-3 justify-center items-center">
                    {organisation.data?.organisation.profile && <h1 className="font-heading text-lg sm:text-xl md:text-2xl lg:text-4xl">{organisation.data?.organisation.profile.displayName}</h1>}
                    
                    { reputationResponse.data?.reputation && untrackedNfts.length > 0 && (
                        <button className="w-16 h-6 text-xs border rounded-full hover:bg-accent hover:text-black hover:border-none" onClick={trackNFTsTx}>Sync NFT's</button>
                    )}

                    { reputationResponse.data?.reputation ? (
                        <button className=" w-16 h-6 text-xs disabled:bg-transparent border rounded-full " disabled={true}>Joined</button>
                    ) : (
                        <button className="w-16 h-6 text-xs border rounded-full hover:bg-accent hover:text-black hover:border-none" onClick={joinOrganization}>Join</button>
                    )}
                </div>
                <label className="text-sm font-thin opacity-75">{organisation?.data?.organisation?.profile?.username}</label>
            </div>

            <Suspense>
                <CreateProfilelModal onClose={() => setModalOpen(false)} show={modalOpen}/>
            </Suspense>
        </div>
    )
}
