import { getMint } from "@solana/spl-token";
import { useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { getMetadataAccount } from "align-sdk";
import { useState, useMemo, useEffect } from "react";
import { ControllerRenderProps } from "react-hook-form";
import LoadingSpinner from "../../../LoadingSpinner";

export function CollectionNftInfo({mint, mintField} : {mint : string, mintField : ControllerRenderProps<any, `collectionItems.${number}.mint`>}){
  const [name, setName] = useState<string | null>(null);
  const [nftUri, setNftUri] = useState<string | null>(null);
  const [notFound, setNotFound] = useState(false)
  const [isFungible, setIsFungible] = useState(false)

  const isLoading = useMemo(() => (name === null || nftUri === null) && notFound === false, [nftUri, name, notFound])
  const {connection} = useConnection()

  useEffect(() => {
      const fetchMetadata = async () => {

          setNotFound(false)
          try{
              const address = new PublicKey(mint)
              const mintAccount = await getMint(connection, address )

              if(mintAccount.decimals !== 0){
                  setIsFungible(true)
              }

              const metadataAccount = await getMetadataAccount(address, connection)
              
              const uri = metadataAccount.data.uri
              const response = await fetch(uri)
              if(!response.ok){
                  setNotFound(true)
                  return
              }
              
              const offchainMetadata = await response.json()
              setName(metadataAccount.data.name)
              setNftUri(offchainMetadata.image)
          }
          catch(e){
              console.log(e)
              setNotFound(true)
          }
          
      }
      fetchMetadata()
  }, [mint, connection]);


  // if(notFound) return <div>User not found</div>

  return(
      !isLoading ? (
       <>
          {nftUri ? <img className="w-16 rounded-full" src={nftUri}/> : (
              <span className={`h-${16} w-${16} rounded-full flex items-center justify-center relative border-white border-opacity-30`}>
              <svg className={`w-${16/2} h-${16/2} text-gray-400`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
              </span>
          ) }
          <div className="flex flex-col gap-1 justify-center">
              <label className="text-sm">{name}</label>
              {/* <label className="text-sm font-light text-contrast text-opacity-70">{truncateKey(userIdentifier?.toBase58())}</label> */}
          </div>
          <div className="mb-1 flex gap-2 justify-center flex-1 bg-transparent border border-contrast border-opacity-10 rounded-full text-sm w-full ">
              {/* <MagnifyingGlassIcon className="flex w-4"/> */}
              <label className="bg-contrast bg-opacity-20 p-2 px-3 rounded-l-full w-32">Mint Address</label>
              <input className="bg-transparent flex-1 outline-none focus:outline-none" {...mintField} value={mint} />
          </div>
          {notFound && !isFungible && <label className="absolute text-xs text-red-500 right-2 bottom-1">Collection not found</label>}
          {isFungible && <label className="absolute text-xs text-red-500 right-2 bottom-1">This is a fungible mint. Please warp.</label>}


          {/* <input className="bg-transparent flex-1 outline-none focus:outline-none" {...weightField} value={weight} /> */}


      </>
      ) : <LoadingSpinner/>
  )
}
