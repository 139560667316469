import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { Clipboard } from "react-feather";

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { ArrowsPointingOutIcon, ArrowsUpDownIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { PublicKey } from "@metaplex-foundation/js";
import { web3 } from "@project-serum/anchor";
import { TOKEN_2022_PROGRAM_ID, getAssociatedTokenAddressSync } from "@solana/spl-token";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { BERN_MINT_ADDRESS, USDC_MINT_ADDRESS } from "align-sdk";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { fetchNfts } from "../api/nfts";
import { SCAM_MINTS } from "../constants";
import { useGetProposalCountQuery } from "../generated/graphql";
import { getTokenAccountsByAddress } from "../pages/Profile";
import { isCustomDomainStore } from "../state/domains";
import { useCurrentOrganisation, useOrganisation } from "../state/hooks/useAlignGovernance";
import { HeliusGetAssetResponse, WalletConfigDto } from "../types";
import { alignLink } from "../utils/alignRoute";
import { truncateKey } from "../utils/truncateKey";
import UserList from "./UserList";
import WithdrawalModal from "./WithdrawalModal";


const TreasuryPanel = ({container}:{container?: boolean}) => {
    const { connection } = useConnection();
    const wallet = useAnchorWallet();
    const [organisationAddress] = useCurrentOrganisation()
    const {organisation, loading, error, refetchOrg} = useOrganisation(organisationAddress)
    // const collections = useRecoilValue(queryCollectionNfts(organisationAddress))
    const [pfps, setPfps] = useState<any[]>([]);


    const navigate= useNavigate()

    const fetchNFTs = async () => {
        if(!organisation?.collectionItems){
            return
        }
		const nftDatas = await fetchNfts(organisation?.collectionItems.map(x => x.mint, true))
        const pfps  = nftDatas.map(x =>  ({ metadata: x.content?.metadata, mint: x.id} ))
		return pfps.filter(p => p !== undefined);
	};


	const proposalCount = useGetProposalCountQuery({
		variables: {
			orgAddress: organisationAddress || "",
			filter: ["Ranking", "Servicing", "Voting", "ReadyToExecute", "Executing", "Complete", "Denied", "Reviewed"],
		},
        skip: organisationAddress === undefined
	})

    useEffect(() => {
		if(!organisation?.collectionItems ) return;
		const getNfts = async() => {
			const res = await fetchNFTs()
            if(!res){
                return
            }
            setPfps(res)
		}
		getNfts();
	},[wallet?.publicKey, organisation?.collectionItems?.length, organisationAddress])


    return (
        <div className={`treasury-panel-container mb-4 py-4 px-6 ${container && 'box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30'}`}>
            <h3 className="text-lg font-heading">DAO Overview:</h3>
            <div className="text-lg py-2">
                <hr className="border-none border-contrast border-opacity-30"/>
                <div className="overflow-hidden bg-transparent text-contrast shadow sm:rounded-lg">
                    <div className="py-2">
                        <h3 className="text-base font-semibold leading-6 text-contrast">Treasury Wallets</h3>
                    </div>
                    <div className="space-y-2 mb-4">
                    {organisation?.wallets ? [...organisation.wallets].sort((a,b) => a.name === "Treasury" ? -1 : 1).map((config, i) => <WalletInfo key={config.address} walletConfig={config} isOpen={config.name === "Treasury"}/>) : undefined}
                    
                    </div>
                </div>
                <hr className="border-none border-contrast border-opacity-30"/>
                <div className="overflow-hidden bg-transparent text-contrast shadow sm:rounded-lg">

                    <div className="py-2">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-contrast-500 opacity-50">Total Proposals</dt>
                            <dd className="mt-1 text-contrast flex gap-2 text-lg">{proposalCount.data?.proposals.pageInfo.count} </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-contrast-500 opacity-50">Organisation Address</dt>
                            <div className="flex gap-2 items-center">
                                <dd className="mt-1 text-contrast text-md flex gap-2">{organisationAddress ? truncateKey(organisationAddress, 6) : ""}</dd>
                                <Clipboard
                                            className="inline-block h-4 cursor-pointer"
                                            onClick={organisationAddress ? () => {
                                                navigator.clipboard.writeText(
                                                    organisationAddress
                                                );
                                                toast.success("Copied to clipboard.")
                                            } : () => {}}/>
                            </div>
                  
                        </div>
                        </dl>
                    </div>
                    <div className="py-2">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-contrast-500 opacity-50">Governing Collections</dt>
                            <dd className="mt-1 text-contrast flex flex-wrap gap-2 text-lg">

                                {pfps.map((pfp) => (
                                    <a href={`https://solana.fm/address/${pfp.mint}`} target="_blank" key={pfp.mint} className="hover:cursor-pointer" onClick={() => navigate(``)}>
                                        <img className={`h-8 w-8 rounded-full border border-accent border-opacity-30`} src={pfp?.metadata?.image} alt={pfp?.metadata?.name}/>  
                                    </a>
                                ))}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-contrast-500 opacity-50">Council Members</dt>
                            <dd className="mt-1 text-contrast flex gap-2 flex-wrap text-lg">
                                    <UserList councilMembers={organisation?.councilManager?.councilMembers}/>
                            </dd>
                        </div>

                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TreasuryPanel;


const WalletInfo = ({walletConfig, isOpen} : {walletConfig : WalletConfigDto, isOpen : boolean}) => {
    const [usdcBalance, setusdcBalance] = useState<number>(0);
    const [solBalance, setSolBalance] = useState(0);
    const [currentOrgAddress] = useCurrentOrganisation()
    const [showWithdrawal, setshowWithdrawal] = useState(false);
    const isCustomDomain = useRecoilValue(isCustomDomainStore)
    const navigate = useNavigate()
    // const outgoingSol = useWalletsOutgoingSol(walletConfig.address)
    // const isOutgoinSolNearingTotaltreasury = useMemo(() => outgoingSol !== null && (((outgoingSol?.toNumber() / LAMPORTS_PER_SOL) / solBalance) >= 0.8), [outgoingSol, walletConfig.address, solBalance])
    
    // const outgoingUsdc = useWalletsOutgoingUsdc(walletConfig.address)
    // const isOutgoinUsdcNearingTotaltreasury = useMemo(() => outgoingUsdc !== null && ((outgoingUsdc?.toNumber() / 1000000) / usdcBalance) >= 0.8, [outgoingUsdc, walletConfig.address, usdcBalance])
    

    const {connection} = useConnection()
    useEffect(() => {
        if( !walletConfig ) return
        const getTreasuryBalance = async () => {
            
            const balance = await connection.getBalance(new PublicKey(walletConfig.walletAddress))
            setSolBalance(Math.floor(balance / LAMPORTS_PER_SOL));
            try{
                const ata = getAssociatedTokenAddressSync(USDC_MINT_ADDRESS, new PublicKey(walletConfig.walletAddress), true)
                const tokenBal = await connection.getTokenAccountBalance(ata)
                if (!tokenBal.value.uiAmount){
                    return
                }
                setusdcBalance(tokenBal.value.uiAmount)
            }
            catch(e){
                console.warn(e)
            }

        };
        getTreasuryBalance();
    }, [walletConfig.address]);

    return (
        <>
        <WithdrawalModal open={showWithdrawal} onClose={() => setshowWithdrawal(false)}/>
        <Disclosure defaultOpen={isOpen}>
            {({ open }) => (
                <div className="treasury-wallet-drawer rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 p-3">
                <Disclosure.Button className="treasury-wallet-drawer-button flex w-full justify-between">
                    <div className="flex items-center gap-2">
                        <p className=" text-sm  text-contrast">{walletConfig.name}</p>
                        <p className="max-w-2xl text-opacity-60 text-xs text-contrast font-light ">{truncateKey(walletConfig.walletAddress, 10)}</p>
                        <ClipboardDocumentIcon className="w-4 opacity-80 hover:opacity-100" onClick={(e) => {
                            e.preventDefault()
                            navigator.clipboard.writeText(walletConfig.walletAddress)
                            toast.success("Copied to clipboard.")
                        }}/>
                        <ArrowsUpDownIcon
                          onClick={(e) => {
                            e.preventDefault()
                            navigate(alignLink("proposal/create/wizard/send", isCustomDomain))
                          }}
                          className="w-4 opacity-80 hover:opacity-100"/>
                    </div>
                    
                    <div className="flex items-center gap-2">
                        {/* {isOutgoinSolNearingTotaltreasury || isOutgoinUsdcNearingTotaltreasury && <ExclamationCircleIcon className="h-5 w-5 text-red-500"/>} */}
                        <ChevronUpIcon
                        className={`${
                            open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-contrast`}
                        />
                    </div>
                </Disclosure.Button>
                <Disclosure.Panel className="treasury-wallet-drawer-panel py-2">
                    <div className="">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-contrast-500 opacity-50">Sol Amount</dt>
                                <dd className="mt-1 text-contrast flex gap-2 text-lg">{solBalance} <img alt="swap" className="w-6 self-center rounded-full" src="/sol-logo.webp" /></dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-contrast-500 opacity-50">USDC Amount</dt>
                                <dd className="mt-1 text-contrast flex gap-2 text-lg">{usdcBalance} <img alt="swap" className="w-6 self-center rounded-full" src="/usdc-logo.webp" /></dd>
                            </div>
                            <div className="sm:col-span-2">

                                <dt className="text-sm font-medium text-contrast-500 opacity-50">SPL Tokens</dt>

                                <TreasurySPLList walletAddress={walletConfig.walletAddress}/>
                            </div>
                            {/* <div className="sm:col-span-2">
                                <div className="flex justify-between items-center">
                                    <dt className="text-sm font-medium text-contrast-500 opacity-50">SOL in Active Proposals </dt>
                                    <dd className="text-contrast">{ outgoingSol && outgoingSol?.toNumber() / LAMPORTS_PER_SOL}</dd>
                                </div>
                                {isOutgoinSolNearingTotaltreasury && <p className="text-xs text-red-500 uppercase">Amount nearing treasury total</p>}
                            </div>
                            <div className="sm:col-span-2">
                                <div className="flex justify-between items-center">
                                    <dt className="text-sm font-medium text-contrast-500 opacity-50">USDC in Active Proposals </dt>
                                    <dd className="text-contrast">{outgoingUsdc && outgoingUsdc?.toNumber() / 1000000}</dd>
                                </div>
                                {isOutgoinUsdcNearingTotaltreasury && <p className="text-xs text-red-500 uppercase">Amount nearing treasury total</p>}
                            </div> */}
                        </dl>
                    </div>
                </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
        </>

    )
}

export const TreasurySPLList = ({walletAddress} : {walletAddress : string}) => {
    
    const [tokenAccounts, settokenAccounts] = useState<{
        pubkey: web3.PublicKey;
        account: web3.AccountInfo<web3.ParsedAccountData>;
    }[]>([]);

    const isModalOpen = useState(false)


    const sortedTokens = useMemo(() => tokenAccounts.filter(acc => acc.account.data.parsed.info.mint !== USDC_MINT_ADDRESS.toBase58()).sort((a, b) => a.account.data.parsed.info.tokenAmount.uiAmount > b.account.data.parsed.info.tokenAmount.uiAmount ? -1 : 1),  [tokenAccounts])
    const displayCount = useMemo(() => sortedTokens.length < 7 ? sortedTokens.length : 7, [sortedTokens.length])

    const {connection} = useConnection()

    useEffect(() => {
        const fetchTokens = async () => {
            try {
                const tokenAccounts = await getTokenAccountsByAddress(walletAddress, connection)
                const token22Accounts = await getTokenAccountsByAddress(walletAddress, connection, TOKEN_2022_PROGRAM_ID)
                const nonScamNfts = [...token22Accounts.value, ...tokenAccounts.value].filter(acc => !SCAM_MINTS.includes(acc.account.data.parsed.info.mint))
                settokenAccounts(nonScamNfts)
            }
            catch(e){
                console.warn(e)
            }
        }
        fetchTokens()
    }, []);

    return ( 
        <div className="py-2 flex flex-wrap justify-start items-center gap-2 w-full relative">
            {sortedTokens.slice(0, displayCount).map((acc, index) => (
                <TreasurySPLItem key={acc.pubkey.toBase58()} tokenAccountAddress={acc.pubkey.toBase58()} account={acc.account} />
            ))}
            {sortedTokens.length > displayCount && (
                <>
                    <div className="rounded-full border-opacity-60 font-light flex items-center text-contrast p-1">

                        <div className="text-xs">&#43;{sortedTokens.length - displayCount}</div>
                        </div>

                <button disabled className=" bg-accent rounded-full border-opacity-60 font-light flex items-center cursor-pointer text-contrast p-1 hover:bg-opacity-80">
                    {/* <div className="text-xs whitespace-nowrap">View All</div> */}
                    <ArrowsPointingOutIcon className="w-4 h-4"/>

                </button>
            </>
            )}
      </div>
        
    );
}

export const TreasurySPLItem = ({tokenAccountAddress, account} : {tokenAccountAddress : string, account : web3.AccountInfo<web3.ParsedAccountData>}) => {
    
    const [meta, setmeta] = useState<HeliusGetAssetResponse>();

    useEffect(()=> {
        const fetchCoinMeta = async () =>{
            try{
               
                 if (account.data.parsed.info.mint === BERN_MINT_ADDRESS.toBase58()){
                    setmeta(
                        //@ts-ignore
                        {
                            id:  account.data.parsed.info.mint ,
                            content: {
                                //@ts-ignore
                                metadata: {
                                    symbol : "$BERN",
                                    name : "BERN",

                                },
                            links : {external_url:"/bern.jpg", image: "/bern.jpg"},

                            }
                    })
        
                }
                else{
                    const response = await fetchNfts([account.data.parsed.info.mint])

                    if(response.length !== 1 
                        || !response[0]?.content?.metadata
                        ){
                        return
                    }
    
                    setmeta(response[0])
                    
                }
     

            }
            catch(e){
                console.warn(e)
            }
        }
        fetchCoinMeta()
    }, [tokenAccountAddress])

    return ( 
        <div className="w-8 h-8">
        {meta?.content?.links.image ? (
            <img
            className={`h-8 w-8 rounded-full border border-accent border-opacity-30 text-[9px] overflow-hidden flex items-center justify-center`}
            src={meta?.content?.links.image}
            alt={meta?.content?.metadata.symbol}
            // style={{ width: '100%', height: '100%' }}

            />
        ) : (
            <span className={`h-8 w-8 rounded-full flex items-center justify-center relative`}>
                <svg className={`w-6 h-6 text-gray-400`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
            </span>
        )}
    </div>
    );
}




 
