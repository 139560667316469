import { Component, ReactNode } from "react";
import RadialGradient from "./RadialGradient";
 
class ErrorBoundary extends Component<{children : ReactNode}, {hasError : boolean, error? : any, info? : any}> {
  constructor(props: any){
    super(props);
    this.state = { hasError: false };
  }
 
  static getDerivedStateFromError(error: any, info : any) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error
    };
  }
 
//   componentDidCatch(error, errorInfo) {
//     // You can also log the error to an error reporting service like AppSignal
//     // logErrorToMyService(error, errorInfo);
//   }
 
  render() {
    const { hasError, error, info } = this.state;
 
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-container-wrapper relative flex flex-col gap-6 justify-center items-center w-full h-screen text-contrast">
            <div className="error-container z-10 flex flex-col gap-6 justify-center items-center w-full h-screen text-contrast">
                <h1>Oops.</h1>
                <p className="w-1/3 text-center text-light ">Something went wrong. If the problem persists, please raise a ticket in the Phase Labs discord</p>
        
                {error.message && <span className="p-2 border border-contrast border-opacity-40 rounded-md font-light">Error: {error.message}</span>}
                    <button className="`flex gap-2 items-center justify-center mt-4 rounded-button border-primary bg-accent border-buttonWidth border-contrast hover:opacity-80
                                                transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center cursor-pointer">
                    <a href="https://discord.gg/gGkVGYaqTk" target="_blank">

                        Open a Ticket
                    </a>

                </button>

            </div>
           
            <RadialGradient className="fixed z-0 top-0 left-0 -translate-x-1/2 -translate-y-1/3 h-auto aspect-square object-cover"/>

        </div>
      );
    }
 
    return this.props.children;
  }
}
 
export default ErrorBoundary;