import { useEffect, useState } from "react";
import { useCurrentOrganisation, useUser } from "../state/hooks/useAlignGovernance";
import { JoinOrg } from "./JoinOrg";

export default function JoinOrgModal({onClose, show} : {onClose: () => void, show : boolean}) {
    const [currentOrgAddress] = useCurrentOrganisation();

    // if bonk do this intead
    if( currentOrgAddress === '2Rctxa6KSTG2ft8bQQvnjTv42VdhcbSGfFnEWH7pKzYp' ) {
      return (
        <div id="defaultModalBonk" tabIndex={-1} aria-hidden="true" className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-secondary bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${show ? "" : "hidden"}`}>
          <div className="relative w-full h-full max-w-2xl md:h-auto text-contrast">
            <div className="relative bg-black border border-white border-opacity-30 rounded-lg shadow text-contrast p-10">
              <div className="flex flex-col items-start justify-between">
                <h3 className="text-xl font-semibold mb-6">
                  You need to join this organization!
                </h3>
                <p className="text-base font-base mb-6">
                  Before you can create a proposal or interact with organizations you need to start earning REP with this organization.
                </p>
                <JoinOrg />
                <button
                  onClick={() => onClose()}
                  type="button"
                  className="bg-transparent hover:opacity-90 absolute right-2 top-2 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }

    // else default modal
    return (
      <div id="defaultModal" tabIndex={-1} aria-hidden="true" className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-secondary bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${show ? "" : "hidden"}`}>
        <div className="relative w-full h-full max-w-2xl md:h-auto text-contrast">
          <div className="relative bg-black border border-white border-opacity-30 rounded-lg shadow text-contrast p-10">
            <div className="flex flex-col items-start justify-between">
              <h3 className="text-xl font-semibold mb-6">
                You need to join this organization!
              </h3>
              <p className="text-base font-base mb-6">
                Before you can create a proposal or interact with organizations you need to start earning REP with this organization.
              </p>
              <JoinOrg />
              <button
                onClick={() => onClose()}
                type="button"
                className="bg-transparent hover:opacity-90 absolute right-2 top-2 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}
