import { TOKEN_PROGRAM_ID } from "@project-serum/anchor/dist/cjs/utils/token";
import { Connection, PublicKey } from "@solana/web3.js";
import {
  Account,
  Api,
  CollectionNftRecord,
  CouncilManager,
  createAlignPrograms,
  Identity,
  Organisation,
  ReputationManager,
  User,
} from "align-sdk";
import BN from "bn.js";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { fetchNfts, HeliusNft } from "../api/nfts";
import { READ_MOCK_WALLET } from "../constants";
import { HeliusGetAssetResponse, UserInfo } from "../types";
import {
  currentOrganisation,
  organisationKeys,
  queryCollectionRecords,
  queryCouncilManager,
  queryOrganisationAccount,
} from "./alignGovernance";
import { connectionUrl } from "./web3";

export const userProfile = selectorFamily<
  Account<User> | undefined | null,
  string
>({
  key: "user/profile",
  get:
    (identifier: string) =>
    async ({ get }: any) => {
      if (!identifier) {
        return;
      }
      const connUrl = get(connectionUrl);
      get(refreshProfile(identifier));
      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        const profile = await Api.fetchUserProfileByIdentifier(
          new PublicKey(identifier),
          programs,
        );
        return profile;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
});

export const userPfp = selectorFamily<
  { mint: PublicKey; pfp: string } | null,
  string | undefined
>({
  key: "user/pfp",
  get:
    (identifier: string | undefined) =>
    async ({ get }: any) => {
      if (!identifier) {
        return null;
      }
      const profile: Account<User> = get(userProfile(identifier));
      if (!profile?.account?.profile?.pfp) {
        return null;
      }

      const response = await fetchNfts([
        profile.account.profile.pfp.toBase58(),
      ]);
      if (response.length !== 1) {
        return null;
      }
      if (!response[0]?.content?.links.image) {
        return null;
      }
      return {
        mint: profile.account.profile.pfp,
        pfp: response[0].content.links.image,
      };
    },
});

export const ownerProfile = selectorFamily<Account<User> | undefined, string>({
  key: "user/ownerProfile",
  get:
    (ownerAddress: string) =>
    async ({ get }: any) => {
      if (!ownerAddress) {
        return;
      }
      const connUrl = get(connectionUrl);
      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        const ownerRecord = await Api.fetchOwnerRecord(
          new PublicKey(ownerAddress),
          programs,
        );
        const profile = get(
          userProfile(ownerRecord.account.account.toBase58()),
        );
        return profile;
      } catch (e) {
        console.warn(e);
      }
    },
});

export const userIdentity = selectorFamily<
  Account<Identity> | undefined | null,
  string
>({
  key: "user/identity",
  get:
    (identifier: string) =>
    async ({ get }: any) => {
      if (!identifier) {
        return;
      }
      const connUrl = get(connectionUrl);
      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        const identity = await Api.fetchIdentityInfo(
          new PublicKey(identifier),
          programs,
        );
        return identity;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
});

export const userReputation = selectorFamily<
  Account<ReputationManager> | undefined,
  string
>({
  key: "user/reputation",
  get:
    (identifier: string | undefined) =>
    async ({ get }: any) => {
      if (!identifier) {
        return;
      }
      get(refreshReputation(identifier));
      const org = get(currentOrganisation);

      if (org === undefined) {
        return;
      }

      const connUrl = get(connectionUrl);
      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      try {
        const reputation = await Api.fetchIdentifiersReputationManager(
          new PublicKey(identifier),
          new PublicKey(org),
          programs,
        );
        return reputation;
      } catch (e) {
        return;
      }
    },
});

export const userReputationValue = selectorFamily<BN, string | undefined>({
  key: "user/reputationValue",
  get:
    (identifier: string | undefined) =>
    async ({ get }: any) => {
      if (!identifier) {
        return new BN(0);
      }

      const rep: Account<ReputationManager> | null = get(
        userReputation(identifier),
      );

      if (!rep) {
        return new BN(0);
      }

      const org = get(currentOrganisation);

      if (org === undefined) {
        return new BN(0);
      }

      const organisationAccount: Account<Organisation> = get(
        queryOrganisationAccount(org),
      );

      if (!organisationAccount) {
        return new BN(0);
      }

      const collectionRecords: Array<Account<CollectionNftRecord>> = get(
        queryCollectionRecords(identifier),
      );
      if (!collectionRecords) {
        return new BN(0);
      }
      const capitalRep = collectionRecords
        .map((record) => {
          const collectionItem =
            organisationAccount.account.config.collectionItems.find(
              (coll) =>
                coll.mint.toBase58() ===
                record.account.collectionMint.toBase58(),
            );
          if (!collectionItem) {
            return new BN(0);
          }
          return new BN(collectionItem?.repMultiplier).mul(
            new BN(record.account.nftCount),
          );
        })
        .reduce((prev: BN, curr: BN) => prev.add(curr), new BN(0));

      return capitalRep.add(rep.account.reputation);
    },
});

export const queryUserGlobalReputation = selectorFamily<
  Account<ReputationManager>[] | undefined,
  string | undefined
>({
  key: "user/queryGlobalReputation",
  get:
    (identifier: string | undefined) =>
    async ({ get }: any) => {
      if (!identifier) {
        return;
      }
      const orgKeys: string[] = get(organisationKeys);
      const connUrl = get(connectionUrl);
      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );
      const repPromise = orgKeys.map(
        async (org) =>
          await Api.fetchIdentifiersReputationManager(
            new PublicKey(identifier),
            new PublicKey(org),
            programs,
          ),
      );
      const rep = await Promise.all(repPromise);
      return rep.filter((rep) => rep !== null);
    },
});

export const queryUserGlobalReputationScore = selectorFamily<
  string,
  string | undefined
>({
  key: "user/queryGlobalReputationScore",
  get:
    (identifier: string | undefined) =>
    async ({ get }: any) => {
      if (!identifier) {
        return "0";
      }
      const reps: Account<ReputationManager>[] = get(
        queryUserGlobalReputation(identifier),
      );
      const score = reps
        .reduce(
          (total: BN, curr: Account<ReputationManager>) =>
            total.add(curr.account.reputation),
          new BN(0),
        )
        .toString();
      return score;
    },
});

export const isCouncilMember = selectorFamily<boolean, string | undefined>({
  key: "user/isCouncilMember",
  get:
    (identifier: string | undefined) =>
    async ({ get }: any) => {
      if (!identifier) {
        return false;
      }

      const org = get(currentOrganisation);

      if (!org) {
        return false;
      }

      const councilManager: Account<CouncilManager> = get(
        queryCouncilManager(org),
      );
      if (!councilManager) {
        return false;
      }
      const councilMembers: PublicKey[] =
        councilManager.account.councilIdentifiers;
      return (
        councilMembers.find((member) => member.toBase58() === identifier) !==
        undefined
      );
    },
});

export const userInfo = selectorFamily<UserInfo | null, string>({
  key: "user/info",
  get:
    (identifier: string | undefined) =>
    ({ get }: any) => {
      if (!identifier) {
        return null;
      }
      get(refreshUserInfo(identifier));
      const profile: Account<User> = get(userProfile(identifier));
      const identity: Account<Identity> = get(userIdentity(identifier));
      const reputation: Account<ReputationManager> = get(
        userReputation(identifier),
      );
      const linkedWallets: string[] = get(linkedKeys(identifier));
      const isCouncil: boolean = get(isCouncilMember(identifier));
      return {
        profile,
        identity,
        reputation,
        linkedWallets,
        isCouncil,
      };
    },
});

export const userInfos = selector<UserInfo[]>({
  key: "users/info",
  get: ({ get }: any) => {
    let currentIdentifiers = get(userIdentifiers);
    let userInfos = currentIdentifiers.map((id: string) => get(userInfo(id)));
    return userInfos;
  },
});

export const loggedInUserInfo = selector<UserInfo | undefined | null>({
  key: "auth/user",
  get: ({ get }: any) => {
    get(refreshLoggedInUser);
    const infos: UserInfo[] = get(userInfos);
    const user = get(currentUserIdentifier);
    const info = infos.find(
      (i) => i.identity.account.identifier.toBase58() === user,
    );
    if (!info) {
      const userinfo: UserInfo | null = get(userInfo(user));
      return userinfo;
    }
    return info;
  },
});

export const nftsForWallet = selectorFamily<
  HeliusGetAssetResponse[],
  string | undefined
>({
  key: "user/nfts",
  get:
    (walletPubkey: string | undefined) =>
    async ({ get }: any) => {
      if (!walletPubkey) {
        return [];
      }
      const connUrl = get(connectionUrl);

      get(refreshNfts(walletPubkey));

      const connection = new Connection(connUrl, "confirmed");

      const rpcResonse = await connection.getParsedTokenAccountsByOwner(
        new PublicKey(walletPubkey),
        {
          programId: new PublicKey(TOKEN_PROGRAM_ID),
        },
      );
      const mints: string[] = rpcResonse.value
        .filter(
          (acc) =>
            acc.account.data.parsed.info.tokenAmount.uiAmount === 1 &&
            acc.account.data.parsed.info.tokenAmount.decimals === 0,
        )
        .map((acc) => acc.account.data.parsed.info.mint);

      const chuckedAccounts: string[][] = mints.reduce(
        (all: any, one: any, i) => {
          const ch = Math.floor(i / 999);
          all[ch] = [].concat(all[ch] || [], one);
          return all;
        },
        [],
      );

      const responsePromises = chuckedAccounts.map((keys) => fetchNfts(keys));

      const nfts = await Promise.all(responsePromises);
      // const filtered = nfts.flat().filter(nft => nft.offChainMetadata.error === "" && nft.onChainAccountInfo.error === "" && nft.offChainMetadata.error === "" && nft?.content?.id !== null  )
      return nfts.flat();
    },
});

export const organisationNftsForWallet = selectorFamily<
  HeliusNft[],
  string | undefined
>({
  key: "user/organisationNfts",
  get:
    (organisation: string | undefined) =>
    async ({ get }: any) => {
      if (!organisation) {
        return [];
      }

      const currentUserWallet = get(currentUserPubkey);
      const nfts: HeliusNft[] = get(nftsForWallet(currentUserWallet));
      const organisationAccount: Account<Organisation> | null = get(
        queryOrganisationAccount(organisation),
      );
      console.log(nfts, organisationAccount, currentUserWallet);
      if (!organisationAccount) {
        return [];
      }
      const collections =
        organisationAccount.account.config.collectionItems.map((item) =>
          item.mint.toBase58(),
        );
      const filteredNfts = nfts.filter(
        (nft) =>
          collections.findIndex(
            (coll) =>
              coll ===
              (nft?.onChainMetadata?.metadata?.collection
                ?.key as unknown as string),
          ) !== -1,
      );
      return filteredNfts;
    },
});

export const linkedKeys = selectorFamily<string[], string>({
  key: "user/linkedKeys",
  get:
    (identifier: string) =>
    async ({ get }: any) => {
      if (!identifier) {
        return [];
      }
      const connUrl = get(connectionUrl);

      const programs = await createAlignPrograms(
        new Connection(connUrl, "confirmed"),
        READ_MOCK_WALLET as any,
        new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
      );

      try {
        const ownerRecords = await Api.fetchOwnerRecordsByIdentifier(
          new PublicKey(identifier),
          programs,
        );
        return ownerRecords
          .filter((record) => record.account.isVerified)
          .map((record) => record.account.account.toBase58());
      } catch (e) {
        console.warn(e);
        return [];
      }
    },
});

export const currentUserIdentifier = selector<string | undefined>({
  key: "auth/currentUser",
  get: async ({ get }: any) => {
    const user = get(currentUserPubkey);
    get(refreshLoggedInUser);
    if (!user) {
      return;
    }
    const connUrl = get(connectionUrl);

    const programs = await createAlignPrograms(
      new Connection(connUrl, "confirmed"),
      READ_MOCK_WALLET as any,
      new Connection(process.env.REACT_APP_SHADOW_RPC!, "max"),
    );
    try {
      const ownerRecord = await Api.fetchOwnerRecord(
        new PublicKey(user),
        programs,
      );
      return ownerRecord.account.identifier.toBase58();
    } catch (e) {
      console.warn(e);
    }
  },
});

export const isLoggedIn = selector<boolean>({
  key: "auth/isLoggedIn",
  get: ({ get }: any) => {
    const user = get(currentUserIdentifier);
    if (!user) {
      return false;
    }
    return true;
  },
});

export const routesCurrentId = atom<string | undefined>({
  key: "route/identifiers",
  default: undefined,
});
export const currentUserPubkey = atom<string | null>({
  key: "auth/pubkey",
  default: null,
});

export const userIdentifiers = atom<string[]>({
  key: "users/identifiers",
  default: [],
});

export const refreshProfile = atomFamily<number, string | undefined>({
  key: "refresh/profile",
  default: 0,
});

export const refreshUserInfo = atomFamily<number, string | undefined>({
  key: "refresh/userInfo",
  default: 0,
});

export const refreshLoggedInUser = atom<number>({
  key: "refresh/loggedInUser",
  default: 0,
});

export const isSignedInStore = atom<boolean>({
  key: "user/isSignedin",
  default: false,
});

export const refreshReputation = atomFamily<number, string | undefined>({
  key: "refresh/rep",
  default: 0,
});

export const refreshNfts = atomFamily<number, string | undefined>({
  key: "refresh/nfts",
  default: 0,
});
