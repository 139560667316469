import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { PublicKey } from "@solana/web3.js";
import { Api } from "align-sdk";
import Avatar from "./Avatar";
import { useState, useMemo, useEffect } from "react";
import { ControllerRenderProps } from "react-hook-form";
import { useAlignPrograms } from "../state/hooks/useAlignGovernance";
import { truncateKey } from "../utils/truncateKey";

export function UserInfo({username, field} : {username : string | null | undefined, field : ControllerRenderProps<any, any>}){
  const alignPrograms = useAlignPrograms()
  const [userIdentifier, setuserIdentifier] = useState<PublicKey | null>(null);
  const [displayname, setdisplayname] = useState<string | null>(null);
  const [notFound, setNotFound] = useState(false);

  const isLoading = useMemo(() => displayname === null || userIdentifier === null && notFound === false, [displayname, userIdentifier])


  useEffect(() => {
      const fetchProfile = async () => {
          if(!alignPrograms || !username){
              return
          }
          setNotFound(false)
          const profile = await Api.fetchUserProfileByUsername(username, alignPrograms)
          if(!profile){
              setNotFound(true)
              return
          }
          setuserIdentifier(prev => profile.account.identifier)
          setdisplayname( prev => profile.account.profile.displayName)
          setNotFound(false)
      }
      fetchProfile()
  }, [username, alignPrograms]);


  // if(notFound) return <div>User not found</div>

  return(
      // (!isLoading && userIdentifier !== null) || username === null ? (
       <>
          <Avatar userIdentifier={userIdentifier === null? undefined : userIdentifier} size={16} noBorder={true}/>
          <div className="flex flex-col gap-1 justify-center">
              <label className="text-sm">{displayname}</label>
              <label className="text-sm font-light text-contrast text-opacity-70">{userIdentifier !== null? truncateKey(userIdentifier.toBase58()) : ""}</label>
          </div>
          <div className="mb-1 flex gap-2 justify-center flex-1 bg-transparent border border-contrast border-opacity-10 rounded-full text-sm p-2 px-2 ">
              <MagnifyingGlassIcon className="flex w-4"/>
              <input className="bg-transparent flex-1 outline-none focus:outline-none" {...field} value={username ? username : ""} />
          </div>
          {notFound && <label className="absolute text-xs text-red-500 right-2 bottom-1">User not found</label>}


      </>
      // ) : <LoadingSpinner/>
  )
}
