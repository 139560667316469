import { useEffect, useState } from "react";
import { fetchNfts } from "../api/nfts";

const MintProfilePicture = (
    {
        mint,
        width,
        height = width,
        alt
    } : {
        mint : string | null | undefined,
        width : number
        height? : number 
        alt?: string

    }

) => {
    const [imageUrl, setimageUrl] = useState<string| null>(null);
    const [loading, setloading] = useState(true);
    const [error, setError] = useState(false)

    useEffect(() => {
        const getImage = async () => {
            try {
                if(mint === null || mint === undefined){
                    setloading(false)
                    return
                }
                const response  = await fetchNfts([mint])
                if(response.length ===  0){
                    setloading(false)
                    setError(true)
                    return
                }
                const image = response[0].content?.links.image
                if(!image){
                    setloading(false)
                    return
                }
                setimageUrl(image)
                setloading(false)
            }
            catch(e){
                setloading(false)
                setError(true)
                return
            }
        }
        getImage()
    }, [mint]);
    if(loading){
        return <div className={`rounded-full w-${width} h-${height} bg-slate-600`}/>
    }

    if(!loading && !imageUrl){
       return ( <span className={`h-${width} w-${height} rounded-full flex items-center justify-center relative border border-contrast border-opacity-30`} title={alt || "dp"}>
                <svg className={`w-${width} h-${height/2} text-gray-400`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
            </span>
       )    
}
    
    return ( <>
        <img className={`rounded-full w-${width} h-${height}`} src={imageUrl ? imageUrl : undefined} alt={alt || "dp"} title={alt || "dp"} />
    </>);
}
 
export default MintProfilePicture;