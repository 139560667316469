import { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
import { ProfileOrg } from "./pages/ProfileOrg";
import { ProposalPage } from "./pages/Proposal";
import { Api, createAlignPrograms } from "align-sdk";
import { Toaster } from "react-hot-toast";
import {
    useAnchorWallet,
    useConnection,
    useWallet,
} from "@solana/wallet-adapter-react";
import { ProfileEdit } from "./pages/ProfileEdit";
import { ProfileCreate } from "./pages/ProfileCreate";
import { Review } from "./pages/Review";
import { CreateProposal } from "./pages/CreateProposal";
import { Discover } from "./pages/Discover";
import { CreateOrganisation } from "./pages/CreateOrganisation";
import { OrgOverview } from "./pages/OrgOverview";
import { useRecoilValue } from "recoil";
import { isCustomDomainStore } from "./state/domains";
import { CreateProposalWizard } from "./pages/CreateProposalWizard";
import ProposalWizardServiceBased from "./components/CreateProposalWizard/ProposalWizardServiceBased";
import ProposalWizardGovernance from "./components/CreateProposalWizard/ProposalWizardGovernance";
import LoadingSpinner from "./components/LoadingSpinner";
import CustomStyler from "./components/CustomStyler";
import ProposalWizardSendAssets from "./components/CreateProposalWizard/ProposalWizardSendAssets";
import ProposalWizardBonkRewards from "./components/CreateProposalWizard/ProposalWizardBonkRewards";

export function Router() {
    const isCustomDomain = useRecoilValue(isCustomDomainStore)
    return <>
        {isCustomDomain ? (
                <Routes>
                    <Route path="/" element={<Suspense fallback={<LoadingSpinner/>}><CustomStyler><Home /></CustomStyler></Suspense>} /> 
                    <Route path="/review" element={<CustomStyler><Review /></CustomStyler>} />
                    <Route path="/u/:identifier" element={<Suspense><CustomStyler><ProfileOrg /></CustomStyler></Suspense>}/>
                    <Route path="/u/:identifier/edit" element={<Suspense fallback={<LoadingSpinner/>}><CustomStyler><ProfileEdit /></CustomStyler></Suspense>} />
                    <Route path="/u/:identifier/create" element={<Suspense><ProfileCreate/></Suspense>}/>
                    <Route path="/proposal/:proposalAddress" element={<Suspense fallback={<LoadingSpinner/>}><CustomStyler><ProposalPage/></CustomStyler></Suspense>} />
                    <Route path="/proposal/create" element={<Suspense><CustomStyler><CreateProposal/></CustomStyler></Suspense>}/>
                    <Route path="/proposal/create/wizard" element={<Suspense><CustomStyler><CreateProposalWizard/></CustomStyler></Suspense>}/>
                    <Route path="/proposal/create/wizard/service" element={<Suspense><CustomStyler><ProposalWizardServiceBased/></CustomStyler></Suspense>}/>
                    <Route path="/proposal/create/wizard/council" element={<Suspense><CustomStyler><ProposalWizardGovernance/></CustomStyler></Suspense>}/>
                    <Route path="/proposal/create/wizard/send" element={<Suspense><CustomStyler><ProposalWizardSendAssets/></CustomStyler></Suspense>}/>
                    <Route path="/proposal/create/wizard/bonkrewards" element={<Suspense><CustomStyler><ProposalWizardBonkRewards/></CustomStyler></Suspense>}/>

                    <Route path="/overview" element={<CustomStyler><OrgOverview /></CustomStyler>} />
                    <Route path="/org/create" element={<Suspense><CustomStyler><CreateOrganisation/></CustomStyler></Suspense>}/>
                </Routes>
            ) : (
                <Routes>
                    <Route path="/" element={<Suspense><CustomStyler><Discover/></CustomStyler></Suspense>} />
                    <Route path="/org/:org/" element={<Suspense fallback={<LoadingSpinner/>}><CustomStyler><Home /></CustomStyler></Suspense>} /> 
                    <Route path="/org/:org/review" element={<CustomStyler><Review /></CustomStyler>} />
                    <Route path="/org/:org/overview" element={<CustomStyler><OrgOverview /></CustomStyler>} />
                    <Route path="/org/:org/proposal/:proposalAddress" element={<Suspense fallback={<LoadingSpinner/>}><CustomStyler><ProposalPage/></CustomStyler></Suspense>} />
                    <Route path="/org/:org/proposal/create" element={<Suspense><CustomStyler><CreateProposal/></CustomStyler></Suspense>}/>
                    <Route path="/org/:org/proposal/create/wizard" element={<Suspense><CustomStyler><CreateProposalWizard/></CustomStyler></Suspense>}/>
                    <Route path="/org/:org/proposal/create/wizard/service" element={<Suspense><CustomStyler><ProposalWizardServiceBased/></CustomStyler></Suspense>}/>
                    <Route path="/org/:org/proposal/create/wizard/council" element={<Suspense><CustomStyler><ProposalWizardGovernance/></CustomStyler></Suspense>}/>
                    <Route path="/org/:org/proposal/create/wizard/send" element={<Suspense><CustomStyler><ProposalWizardSendAssets/></CustomStyler></Suspense>}/>
                    <Route path="/org/:org/proposal/create/wizard/bonkrewards" element={<Suspense><CustomStyler><ProposalWizardBonkRewards/></CustomStyler></Suspense>}/>
                    <Route path="/org/create" element={<Suspense><CustomStyler><CreateOrganisation/></CustomStyler></Suspense>}/>
                    <Route path="/u/:identifier" element={<Suspense fallback={<LoadingSpinner/>} ><CustomStyler><Profile /></CustomStyler></Suspense>}/>
                    <Route path="org/:org/u/:identifier" element={<Suspense fallback={<LoadingSpinner/>}><CustomStyler><ProfileOrg /></CustomStyler></Suspense>}/>
                    <Route path="/u/:identifier/edit" element={<Suspense fallback={<LoadingSpinner/>}><CustomStyler><ProfileEdit /></CustomStyler></Suspense>} />
                    <Route path="/u/:identifier/create" element={<Suspense fallback={<LoadingSpinner/>}><CustomStyler><ProfileCreate/></CustomStyler></Suspense>}/>
                </Routes>
            )}

        <Toaster
            position="bottom-left"
            reverseOrder={false}
            toastOptions={{
                style: {
                    wordBreak: 'break-all'
                },
            }}
        />
    </>
}
