import { XMarkIcon, ForwardIcon } from "@heroicons/react/24/solid"
import { Api } from "align-sdk"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import toast from "react-hot-toast"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { councilMembersFormInput } from "../../../state/forms"
import { useAuth, useAlignPrograms } from "../../../state/hooks/useAlignGovernance"
import { DescriptionPanel } from "../../DescriptionPanel"
import { UserInfo } from "../../UserInfo"
import { WizardStepProps } from "../../CreateProposalWizard/Wizard"

export function CouncilStep({ onComplete, goBack }: WizardStepProps<{username : string}[]>){
  const user = useAuth()
  const setCouncilInputs = useSetRecoilState(councilMembersFormInput)
  const alignPrograms = useAlignPrograms()
  const councilMembersUsernamesArray = useRecoilValue(councilMembersFormInput); // Retrieves the actual array of strings
  const defaultCouncilMembers = councilMembersUsernamesArray.map(username => ({ username }));

  const { register, handleSubmit, formState: { errors }, control, getValues } = useForm<{councilMembers : {username : string}[]}>({
      defaultValues: {
          councilMembers: defaultCouncilMembers || []
      }
  });

  const {fields, append, remove} = useFieldArray<{councilMembers : {username : string}[]}>({
      control,
      name: "councilMembers",
      rules: {
          validate : async (values : {username : string}[]) => {

              const hasDuplicate = values.filter((user, index) => values.findIndex(u => u.username === user.username) !== index)
              if(hasDuplicate.length > 0){
                  toast.error("You have duplicate council users. Please remove duplicates before continuing.")
                  return false
              }
              if(!alignPrograms){
                  return false
              }
              
              const profilePromises = values.map( members => Api.fetchUserProfileByUsername(members.username, alignPrograms))
              const profiles = await Promise.all(profilePromises)
              const profileIndex = profiles.findIndex((x) => x === null)
              if(profileIndex !== -1) {
                  return `User ${values[profileIndex].username} does not exist.`
              }
              return true
          }
      }
  })

  if(user?.identity === undefined){
    return <></>
  }

 const onSubmit = ({councilMembers}: {councilMembers: {username: string}[]}) => {
      if(!councilMembers || councilMembers.length === 0){
          toast.error("Please choose at least one council member.")
          return
      }
      console.log("Storing Council members inputs", councilMembers)
      setCouncilInputs(councilMembers.map(user => user.username))
      onComplete(councilMembers)

 }

 return (
  <>
      <form onSubmit={handleSubmit(onSubmit)} 
              className="rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 w-full p-6 lg:col-span-2">
          <DescriptionPanel message="Please add your council members.
                  The council will have authority over the treasury and config accounts within the DAO, 
                  they also will particpate in reviewing proposals proposed by the community."/>

          <ul className="flex flex-col gap-4 mt-4">
              {fields.map((item, index) => (
                  <li key={item.id} className="relative flex p-4 items-center gap-6 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center">
                      <Controller 
                          name={`councilMembers.${index}.username` as const}
                          control={control}
                          render={({ field}) => <UserInfo field={field} username={field.value}
                          />  
                      }
                          />
                      <XMarkIcon className="w-6 p-1 bg-contrast text-opacity-70 bg-opacity-10 rounded-full absolute top-1 right-1" type="button" onClick={() => remove(index)}/>

                  </li>


              ))}

              <button
                  className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                  
                  transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                  type="button"
                  onClick={!user?.username ? () => append({username: user?.username as string}) : () => {}}
                  >
                  Add Council Member
              </button>
          </ul>
          
          
          <button
              className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
              transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
              type="submit"
          >
                  <ForwardIcon className={`w-4 `}/>
                  Next Step
          </button>

          <button
              className="w-full flex justify-center gap-2 items-center mt-4 self-end border rounded-button border-primary 
              transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
              onClick={() => goBack()}
          >
              <ForwardIcon className={`w-4 transform rotate-180`}/>
              Go back
          </button>
      </form>
  </>
  )
  

}