export type MetaplexBubblegum = {
  "version": "0.12.0",
  "name": "bubblegum",
  "instructions": [
    {
      "name": "burn",
      "docs": [
        "Burns a leaf node from the tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        }
      ]
    },
    {
      "name": "cancelRedeem",
      "docs": [
        "Cancels a redeem."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "voucher",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        }
      ]
    },
    {
      "name": "compress",
      "docs": [
        "Compresses a metadata account."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "createTree",
      "docs": [
        "Creates a new tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treeCreator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "maxDepth",
          "type": "u32"
        },
        {
          "name": "maxBufferSize",
          "type": "u32"
        },
        {
          "name": "public",
          "type": {
            "option": "bool"
          }
        }
      ]
    },
    {
      "name": "decompressV1",
      "docs": [
        "Decompresses a leaf node from the tree."
      ],
      "accounts": [
        {
          "name": "voucher",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "sysvarRent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "metadata",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "delegate",
      "docs": [
        "Sets a delegate for a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "previousLeafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "newLeafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        }
      ]
    },
    {
      "name": "mintToCollectionV1",
      "docs": [
        "Mints a new asset and adds it to a collection."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "If there is no collecton authority record PDA then",
            "this must be the Bubblegum program address."
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "editionAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bubblegumSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "metadataArgs",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ],
      "returns": {
        "defined": "LeafSchema"
      }
    },
    {
      "name": "mintV1",
      "docs": [
        "Mints a new asset."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ],
      "returns": {
        "defined": "LeafSchema"
      }
    },
    {
      "name": "redeem",
      "docs": [
        "Redeems a vouches.",
        "",
        "Once a vouch is redeemed, the corresponding leaf node is removed from the tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "voucher",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        }
      ]
    },
    {
      "name": "setAndVerifyCollection",
      "docs": [
        "Sets and verifies a collection to a leaf node"
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "This account is checked to be a signer in",
            "the case of `set_and_verify_collection` where",
            "we are actually changing the NFT metadata."
          ]
        },
        {
          "name": "collectionAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "If there is no collecton authority record PDA then",
            "this must be the Bubblegum program address."
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "editionAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bubblegumSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        },
        {
          "name": "collection",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "setDecompressableState",
      "docs": [
        "Sets the `decompressible_state` of a tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treeCreator",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "decompressableState",
          "type": {
            "defined": "DecompressibleState"
          }
        }
      ]
    },
    {
      "name": "setDecompressibleState",
      "docs": [
        "Sets the `decompressible_state` of a tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treeCreator",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "decompressableState",
          "type": {
            "defined": "DecompressibleState"
          }
        }
      ]
    },
    {
      "name": "setTreeDelegate",
      "docs": [
        "Sets a delegate for a tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treeCreator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "newTreeDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "transfer",
      "docs": [
        "Transfers a leaf node from one account to another."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "newLeafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        }
      ]
    },
    {
      "name": "unverifyCollection",
      "docs": [
        "Unverifies a collection from a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "This account is checked to be a signer in",
            "the case of `set_and_verify_collection` where",
            "we are actually changing the NFT metadata."
          ]
        },
        {
          "name": "collectionAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "If there is no collecton authority record PDA then",
            "this must be the Bubblegum program address."
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "editionAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bubblegumSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "unverifyCreator",
      "docs": [
        "Unverifies a creator from a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "verifyCollection",
      "docs": [
        "Verifies a collection for a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "This account is checked to be a signer in",
            "the case of `set_and_verify_collection` where",
            "we are actually changing the NFT metadata."
          ]
        },
        {
          "name": "collectionAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "If there is no collecton authority record PDA then",
            "this must be the Bubblegum program address."
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "editionAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bubblegumSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "verifyCreator",
      "docs": [
        "Verifies a creator for a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "updateMetadata",
      "docs": [
        "Updates metadata for a leaf node that is not part of a verified collection."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true,
          "docs": [
            "Either collection authority or tree owner/delegate, depending",
            "on whether the item is in a verified collection"
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false,
          "isOptional": true,
          "docs": [
            "Used when item is in a verified collection"
          ]
        },
        {
          "name": "collectionMetadata",
          "isMut": false,
          "isSigner": false,
          "isOptional": true,
          "docs": [
            "Used when item is in a verified collection"
          ]
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "isOptional": true
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "currentMetadata",
          "type": {
            "defined": "MetadataArgs"
          }
        },
        {
          "name": "updateArgs",
          "type": {
            "defined": "UpdateArgs"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "TreeConfig",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "treeCreator",
            "type": "publicKey"
          },
          {
            "name": "treeDelegate",
            "type": "publicKey"
          },
          {
            "name": "totalMintCapacity",
            "type": "u64"
          },
          {
            "name": "numMinted",
            "type": "u64"
          },
          {
            "name": "isPublic",
            "type": "bool"
          },
          {
            "name": "isDecompressible",
            "type": {
              "defined": "DecompressibleState"
            }
          }
        ]
      }
    },
    {
      "name": "Voucher",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "leafSchema",
            "type": {
              "defined": "LeafSchema"
            }
          },
          {
            "name": "index",
            "type": "u32"
          },
          {
            "name": "merkleTree",
            "type": "publicKey"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "Creator",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "verified",
            "type": "bool"
          },
          {
            "name": "share",
            "docs": [
              "The percentage share.",
              "",
              "The value is a percentage, not basis points."
            ],
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Uses",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "useMethod",
            "type": {
              "defined": "UseMethod"
            }
          },
          {
            "name": "remaining",
            "type": "u64"
          },
          {
            "name": "total",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "Collection",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "verified",
            "type": "bool"
          },
          {
            "name": "key",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "MetadataArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "name",
            "docs": [
              "The name of the asset"
            ],
            "type": "string"
          },
          {
            "name": "symbol",
            "docs": [
              "The symbol for the asset"
            ],
            "type": "string"
          },
          {
            "name": "uri",
            "docs": [
              "URI pointing to JSON representing the asset"
            ],
            "type": "string"
          },
          {
            "name": "sellerFeeBasisPoints",
            "docs": [
              "Royalty basis points that goes to creators in secondary sales (0-10000)"
            ],
            "type": "u16"
          },
          {
            "name": "primarySaleHappened",
            "type": "bool"
          },
          {
            "name": "isMutable",
            "type": "bool"
          },
          {
            "name": "editionNonce",
            "docs": [
              "nonce for easy calculation of editions, if present"
            ],
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "tokenStandard",
            "docs": [
              "Since we cannot easily change Metadata, we add the new DataV2 fields here at the end."
            ],
            "type": {
              "option": {
                "defined": "TokenStandard"
              }
            }
          },
          {
            "name": "collection",
            "docs": [
              "Collection"
            ],
            "type": {
              "option": {
                "defined": "Collection"
              }
            }
          },
          {
            "name": "uses",
            "docs": [
              "Uses"
            ],
            "type": {
              "option": {
                "defined": "Uses"
              }
            }
          },
          {
            "name": "tokenProgramVersion",
            "type": {
              "defined": "TokenProgramVersion"
            }
          },
          {
            "name": "creators",
            "type": {
              "vec": {
                "defined": "Creator"
              }
            }
          }
        ]
      }
    },
    {
      "name": "UpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "name",
            "type": {
              "option": "string"
            }
          },
          {
            "name": "symbol",
            "type": {
              "option": "string"
            }
          },
          {
            "name": "uri",
            "type": {
              "option": "string"
            }
          },
          {
            "name": "creators",
            "type": {
              "option": {
                "vec": {
                  "defined": "Creator"
                }
              }
            }
          },
          {
            "name": "sellerFeeBasisPoints",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "primarySaleHappened",
            "type": {
              "option": "bool"
            }
          },
          {
            "name": "isMutable",
            "type": {
              "option": "bool"
            }
          }
        ]
      }
    },
    {
      "name": "Version",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "V1"
          }
        ]
      }
    },
    {
      "name": "LeafSchema",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "V1",
            "fields": [
              {
                "name": "id",
                "type": "publicKey"
              },
              {
                "name": "owner",
                "type": "publicKey"
              },
              {
                "name": "delegate",
                "type": "publicKey"
              },
              {
                "name": "nonce",
                "type": "u64"
              },
              {
                "name": "data_hash",
                "type": {
                  "array": [
                    "u8",
                    32
                  ]
                }
              },
              {
                "name": "creator_hash",
                "type": {
                  "array": [
                    "u8",
                    32
                  ]
                }
              }
            ]
          }
        ]
      }
    },
    {
      "name": "TokenProgramVersion",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Original"
          },
          {
            "name": "Token2022"
          }
        ]
      }
    },
    {
      "name": "TokenStandard",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "NonFungible"
          },
          {
            "name": "FungibleAsset"
          },
          {
            "name": "Fungible"
          },
          {
            "name": "NonFungibleEdition"
          }
        ]
      }
    },
    {
      "name": "UseMethod",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Burn"
          },
          {
            "name": "Multiple"
          },
          {
            "name": "Single"
          }
        ]
      }
    },
    {
      "name": "BubblegumEventType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "LeafSchemaEvent"
          }
        ]
      }
    },
    {
      "name": "DecompressibleState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Enabled"
          },
          {
            "name": "Disabled"
          }
        ]
      }
    },
    {
      "name": "InstructionName",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Unknown"
          },
          {
            "name": "MintV1"
          },
          {
            "name": "Redeem"
          },
          {
            "name": "CancelRedeem"
          },
          {
            "name": "Transfer"
          },
          {
            "name": "Delegate"
          },
          {
            "name": "DecompressV1"
          },
          {
            "name": "Compress"
          },
          {
            "name": "Burn"
          },
          {
            "name": "CreateTree"
          },
          {
            "name": "VerifyCreator"
          },
          {
            "name": "UnverifyCreator"
          },
          {
            "name": "VerifyCollection"
          },
          {
            "name": "UnverifyCollection"
          },
          {
            "name": "SetAndVerifyCollection"
          },
          {
            "name": "MintToCollectionV1"
          },
          {
            "name": "SetDecompressibleState"
          },
          {
            "name": "UpdateMetadata"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "AssetOwnerMismatch",
      "msg": "Asset Owner Does not match"
    },
    {
      "code": 6001,
      "name": "PublicKeyMismatch",
      "msg": "PublicKeyMismatch"
    },
    {
      "code": 6002,
      "name": "HashingMismatch",
      "msg": "Hashing Mismatch Within Leaf Schema"
    },
    {
      "code": 6003,
      "name": "UnsupportedSchemaVersion",
      "msg": "Unsupported Schema Version"
    },
    {
      "code": 6004,
      "name": "CreatorShareTotalMustBe100",
      "msg": "Creator shares must sum to 100"
    },
    {
      "code": 6005,
      "name": "DuplicateCreatorAddress",
      "msg": "No duplicate creator addresses in metadata"
    },
    {
      "code": 6006,
      "name": "CreatorDidNotVerify",
      "msg": "Creator did not verify the metadata"
    },
    {
      "code": 6007,
      "name": "CreatorNotFound",
      "msg": "Creator not found in creator Vec"
    },
    {
      "code": 6008,
      "name": "NoCreatorsPresent",
      "msg": "No creators in creator Vec"
    },
    {
      "code": 6009,
      "name": "CreatorHashMismatch",
      "msg": "User-provided creator Vec must result in same user-provided creator hash"
    },
    {
      "code": 6010,
      "name": "DataHashMismatch",
      "msg": "User-provided metadata must result in same user-provided data hash"
    },
    {
      "code": 6011,
      "name": "CreatorsTooLong",
      "msg": "Creators list too long"
    },
    {
      "code": 6012,
      "name": "MetadataNameTooLong",
      "msg": "Name in metadata is too long"
    },
    {
      "code": 6013,
      "name": "MetadataSymbolTooLong",
      "msg": "Symbol in metadata is too long"
    },
    {
      "code": 6014,
      "name": "MetadataUriTooLong",
      "msg": "Uri in metadata is too long"
    },
    {
      "code": 6015,
      "name": "MetadataBasisPointsTooHigh",
      "msg": "Basis points in metadata cannot exceed 10000"
    },
    {
      "code": 6016,
      "name": "TreeAuthorityIncorrect",
      "msg": "Tree creator or tree delegate must sign."
    },
    {
      "code": 6017,
      "name": "InsufficientMintCapacity",
      "msg": "Not enough unapproved mints left"
    },
    {
      "code": 6018,
      "name": "NumericalOverflowError",
      "msg": "NumericalOverflowError"
    },
    {
      "code": 6019,
      "name": "IncorrectOwner",
      "msg": "Incorrect account owner"
    },
    {
      "code": 6020,
      "name": "CollectionCannotBeVerifiedInThisInstruction",
      "msg": "Cannot Verify Collection in this Instruction"
    },
    {
      "code": 6021,
      "name": "CollectionNotFound",
      "msg": "Collection Not Found on Metadata"
    },
    {
      "code": 6022,
      "name": "AlreadyVerified",
      "msg": "Collection item is already verified."
    },
    {
      "code": 6023,
      "name": "AlreadyUnverified",
      "msg": "Collection item is already unverified."
    },
    {
      "code": 6024,
      "name": "UpdateAuthorityIncorrect",
      "msg": "Incorrect leaf metadata update authority."
    },
    {
      "code": 6025,
      "name": "LeafAuthorityMustSign",
      "msg": "This transaction must be signed by either the leaf owner or leaf delegate"
    },
    {
      "code": 6026,
      "name": "CollectionMustBeSized",
      "msg": "Collection Not Compatable with Compression, Must be Sized"
    },
    {
      "code": 6027,
      "name": "MetadataMintMismatch",
      "msg": "Metadata mint does not match collection mint"
    },
    {
      "code": 6028,
      "name": "InvalidCollectionAuthority",
      "msg": "Invalid collection authority"
    },
    {
      "code": 6029,
      "name": "InvalidDelegateRecord",
      "msg": "Invalid delegate record pda derivation"
    },
    {
      "code": 6030,
      "name": "CollectionMasterEditionAccountInvalid",
      "msg": "Edition account doesnt match collection"
    },
    {
      "code": 6031,
      "name": "CollectionMustBeAUniqueMasterEdition",
      "msg": "Collection Must Be a Unique Master Edition v2"
    },
    {
      "code": 6032,
      "name": "UnknownExternalError",
      "msg": "Could not convert external error to BubblegumError"
    },
    {
      "code": 6033,
      "name": "DecompressionDisabled",
      "msg": "Decompression is disabled for this tree."
    },
    {
      "code": 6034,
      "name": "MissingCollectionMintAccount",
      "msg": "Missing collection mint account"
    },
    {
      "code": 6035,
      "name": "MissingCollectionMetadataAccount",
      "msg": "Missing collection metadata account"
    },
    {
      "code": 6036,
      "name": "CollectionMismatch",
      "msg": "Collection mismatch"
    },
    {
      "code": 6037,
      "name": "MetadataImmutable",
      "msg": "Metadata not mutable"
    },
    {
      "code": 6038,
      "name": "PrimarySaleCanOnlyBeFlippedToTrue",
      "msg": "Can only update primary sale to true"
    },
    {
      "code": 6039,
      "name": "CreatorDidNotUnverify",
      "msg": "Creator did not unverify the metadata"
    },
    {
      "code": 6040,
      "name": "InvalidTokenStandard",
      "msg": "Only NonFungible standard is supported"
    },
    {
      "code": 6041,
      "name": "InvalidCanopySize",
      "msg": "Canopy size should be set bigger for this tree"
    }
  ],
  "metadata": {
    "address": "BGUMAp9Gq7iTEuizy4pqaxsTyUCBK68MDfK752saRPUY",
    "origin": "anchor",
    "binaryVersion": "0.27.0",
    "libVersion": "0.28.0"
  }
}

export const IDL: MetaplexBubblegum = {
  "version": "0.12.0",
  "name": "bubblegum",
  "instructions": [
    {
      "name": "burn",
      "docs": [
        "Burns a leaf node from the tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        }
      ]
    },
    {
      "name": "cancelRedeem",
      "docs": [
        "Cancels a redeem."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "voucher",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        }
      ]
    },
    {
      "name": "compress",
      "docs": [
        "Compresses a metadata account."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "createTree",
      "docs": [
        "Creates a new tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "treeCreator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "maxDepth",
          "type": "u32"
        },
        {
          "name": "maxBufferSize",
          "type": "u32"
        },
        {
          "name": "public",
          "type": {
            "option": "bool"
          }
        }
      ]
    },
    {
      "name": "decompressV1",
      "docs": [
        "Decompresses a leaf node from the tree."
      ],
      "accounts": [
        {
          "name": "voucher",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "mintAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "metadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterEdition",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "sysvarRent",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "associatedTokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "metadata",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "delegate",
      "docs": [
        "Sets a delegate for a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "previousLeafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "newLeafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        }
      ]
    },
    {
      "name": "mintToCollectionV1",
      "docs": [
        "Mints a new asset and adds it to a collection."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "If there is no collecton authority record PDA then",
            "this must be the Bubblegum program address."
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "editionAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bubblegumSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "metadataArgs",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ],
      "returns": {
        "defined": "LeafSchema"
      }
    },
    {
      "name": "mintV1",
      "docs": [
        "Mints a new asset."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ],
      "returns": {
        "defined": "LeafSchema"
      }
    },
    {
      "name": "redeem",
      "docs": [
        "Redeems a vouches.",
        "",
        "Once a vouch is redeemed, the corresponding leaf node is removed from the tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "voucher",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        }
      ]
    },
    {
      "name": "setAndVerifyCollection",
      "docs": [
        "Sets and verifies a collection to a leaf node"
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "This account is checked to be a signer in",
            "the case of `set_and_verify_collection` where",
            "we are actually changing the NFT metadata."
          ]
        },
        {
          "name": "collectionAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "If there is no collecton authority record PDA then",
            "this must be the Bubblegum program address."
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "editionAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bubblegumSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        },
        {
          "name": "collection",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "setDecompressableState",
      "docs": [
        "Sets the `decompressible_state` of a tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treeCreator",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "decompressableState",
          "type": {
            "defined": "DecompressibleState"
          }
        }
      ]
    },
    {
      "name": "setDecompressibleState",
      "docs": [
        "Sets the `decompressible_state` of a tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treeCreator",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "decompressableState",
          "type": {
            "defined": "DecompressibleState"
          }
        }
      ]
    },
    {
      "name": "setTreeDelegate",
      "docs": [
        "Sets a delegate for a tree."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "treeCreator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "newTreeDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "transfer",
      "docs": [
        "Transfers a leaf node from one account to another."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "newLeafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        }
      ]
    },
    {
      "name": "unverifyCollection",
      "docs": [
        "Unverifies a collection from a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "This account is checked to be a signer in",
            "the case of `set_and_verify_collection` where",
            "we are actually changing the NFT metadata."
          ]
        },
        {
          "name": "collectionAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "If there is no collecton authority record PDA then",
            "this must be the Bubblegum program address."
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "editionAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bubblegumSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "unverifyCreator",
      "docs": [
        "Unverifies a creator from a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "verifyCollection",
      "docs": [
        "Verifies a collection for a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "treeDelegate",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "This account is checked to be a signer in",
            "the case of `set_and_verify_collection` where",
            "we are actually changing the NFT metadata."
          ]
        },
        {
          "name": "collectionAuthority",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "docs": [
            "If there is no collecton authority record PDA then",
            "this must be the Bubblegum program address."
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "collectionMetadata",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "editionAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bubblegumSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "verifyCreator",
      "docs": [
        "Verifies a creator for a leaf node."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "creator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "dataHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "creatorHash",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "message",
          "type": {
            "defined": "MetadataArgs"
          }
        }
      ]
    },
    {
      "name": "updateMetadata",
      "docs": [
        "Updates metadata for a leaf node that is not part of a verified collection."
      ],
      "accounts": [
        {
          "name": "treeAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "authority",
          "isMut": false,
          "isSigner": true,
          "docs": [
            "Either collection authority or tree owner/delegate, depending",
            "on whether the item is in a verified collection"
          ]
        },
        {
          "name": "collectionMint",
          "isMut": false,
          "isSigner": false,
          "isOptional": true,
          "docs": [
            "Used when item is in a verified collection"
          ]
        },
        {
          "name": "collectionMetadata",
          "isMut": false,
          "isSigner": false,
          "isOptional": true,
          "docs": [
            "Used when item is in a verified collection"
          ]
        },
        {
          "name": "collectionAuthorityRecordPda",
          "isMut": false,
          "isSigner": false,
          "isOptional": true
        },
        {
          "name": "leafOwner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "leafDelegate",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "payer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "merkleTree",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "logWrapper",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "compressionProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenMetadataProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "root",
          "type": {
            "array": [
              "u8",
              32
            ]
          }
        },
        {
          "name": "nonce",
          "type": "u64"
        },
        {
          "name": "index",
          "type": "u32"
        },
        {
          "name": "currentMetadata",
          "type": {
            "defined": "MetadataArgs"
          }
        },
        {
          "name": "updateArgs",
          "type": {
            "defined": "UpdateArgs"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "TreeConfig",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "treeCreator",
            "type": "publicKey"
          },
          {
            "name": "treeDelegate",
            "type": "publicKey"
          },
          {
            "name": "totalMintCapacity",
            "type": "u64"
          },
          {
            "name": "numMinted",
            "type": "u64"
          },
          {
            "name": "isPublic",
            "type": "bool"
          },
          {
            "name": "isDecompressible",
            "type": {
              "defined": "DecompressibleState"
            }
          }
        ]
      }
    },
    {
      "name": "Voucher",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "leafSchema",
            "type": {
              "defined": "LeafSchema"
            }
          },
          {
            "name": "index",
            "type": "u32"
          },
          {
            "name": "merkleTree",
            "type": "publicKey"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "Creator",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "address",
            "type": "publicKey"
          },
          {
            "name": "verified",
            "type": "bool"
          },
          {
            "name": "share",
            "docs": [
              "The percentage share.",
              "",
              "The value is a percentage, not basis points."
            ],
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Uses",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "useMethod",
            "type": {
              "defined": "UseMethod"
            }
          },
          {
            "name": "remaining",
            "type": "u64"
          },
          {
            "name": "total",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "Collection",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "verified",
            "type": "bool"
          },
          {
            "name": "key",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "MetadataArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "name",
            "docs": [
              "The name of the asset"
            ],
            "type": "string"
          },
          {
            "name": "symbol",
            "docs": [
              "The symbol for the asset"
            ],
            "type": "string"
          },
          {
            "name": "uri",
            "docs": [
              "URI pointing to JSON representing the asset"
            ],
            "type": "string"
          },
          {
            "name": "sellerFeeBasisPoints",
            "docs": [
              "Royalty basis points that goes to creators in secondary sales (0-10000)"
            ],
            "type": "u16"
          },
          {
            "name": "primarySaleHappened",
            "type": "bool"
          },
          {
            "name": "isMutable",
            "type": "bool"
          },
          {
            "name": "editionNonce",
            "docs": [
              "nonce for easy calculation of editions, if present"
            ],
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "tokenStandard",
            "docs": [
              "Since we cannot easily change Metadata, we add the new DataV2 fields here at the end."
            ],
            "type": {
              "option": {
                "defined": "TokenStandard"
              }
            }
          },
          {
            "name": "collection",
            "docs": [
              "Collection"
            ],
            "type": {
              "option": {
                "defined": "Collection"
              }
            }
          },
          {
            "name": "uses",
            "docs": [
              "Uses"
            ],
            "type": {
              "option": {
                "defined": "Uses"
              }
            }
          },
          {
            "name": "tokenProgramVersion",
            "type": {
              "defined": "TokenProgramVersion"
            }
          },
          {
            "name": "creators",
            "type": {
              "vec": {
                "defined": "Creator"
              }
            }
          }
        ]
      }
    },
    {
      "name": "UpdateArgs",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "name",
            "type": {
              "option": "string"
            }
          },
          {
            "name": "symbol",
            "type": {
              "option": "string"
            }
          },
          {
            "name": "uri",
            "type": {
              "option": "string"
            }
          },
          {
            "name": "creators",
            "type": {
              "option": {
                "vec": {
                  "defined": "Creator"
                }
              }
            }
          },
          {
            "name": "sellerFeeBasisPoints",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "primarySaleHappened",
            "type": {
              "option": "bool"
            }
          },
          {
            "name": "isMutable",
            "type": {
              "option": "bool"
            }
          }
        ]
      }
    },
    {
      "name": "Version",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "V1"
          }
        ]
      }
    },
    {
      "name": "LeafSchema",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "V1",
            "fields": [
              {
                "name": "id",
                "type": "publicKey"
              },
              {
                "name": "owner",
                "type": "publicKey"
              },
              {
                "name": "delegate",
                "type": "publicKey"
              },
              {
                "name": "nonce",
                "type": "u64"
              },
              {
                "name": "data_hash",
                "type": {
                  "array": [
                    "u8",
                    32
                  ]
                }
              },
              {
                "name": "creator_hash",
                "type": {
                  "array": [
                    "u8",
                    32
                  ]
                }
              }
            ]
          }
        ]
      }
    },
    {
      "name": "TokenProgramVersion",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Original"
          },
          {
            "name": "Token2022"
          }
        ]
      }
    },
    {
      "name": "TokenStandard",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "NonFungible"
          },
          {
            "name": "FungibleAsset"
          },
          {
            "name": "Fungible"
          },
          {
            "name": "NonFungibleEdition"
          }
        ]
      }
    },
    {
      "name": "UseMethod",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Burn"
          },
          {
            "name": "Multiple"
          },
          {
            "name": "Single"
          }
        ]
      }
    },
    {
      "name": "BubblegumEventType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "LeafSchemaEvent"
          }
        ]
      }
    },
    {
      "name": "DecompressibleState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Enabled"
          },
          {
            "name": "Disabled"
          }
        ]
      }
    },
    {
      "name": "InstructionName",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Unknown"
          },
          {
            "name": "MintV1"
          },
          {
            "name": "Redeem"
          },
          {
            "name": "CancelRedeem"
          },
          {
            "name": "Transfer"
          },
          {
            "name": "Delegate"
          },
          {
            "name": "DecompressV1"
          },
          {
            "name": "Compress"
          },
          {
            "name": "Burn"
          },
          {
            "name": "CreateTree"
          },
          {
            "name": "VerifyCreator"
          },
          {
            "name": "UnverifyCreator"
          },
          {
            "name": "VerifyCollection"
          },
          {
            "name": "UnverifyCollection"
          },
          {
            "name": "SetAndVerifyCollection"
          },
          {
            "name": "MintToCollectionV1"
          },
          {
            "name": "SetDecompressibleState"
          },
          {
            "name": "UpdateMetadata"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "AssetOwnerMismatch",
      "msg": "Asset Owner Does not match"
    },
    {
      "code": 6001,
      "name": "PublicKeyMismatch",
      "msg": "PublicKeyMismatch"
    },
    {
      "code": 6002,
      "name": "HashingMismatch",
      "msg": "Hashing Mismatch Within Leaf Schema"
    },
    {
      "code": 6003,
      "name": "UnsupportedSchemaVersion",
      "msg": "Unsupported Schema Version"
    },
    {
      "code": 6004,
      "name": "CreatorShareTotalMustBe100",
      "msg": "Creator shares must sum to 100"
    },
    {
      "code": 6005,
      "name": "DuplicateCreatorAddress",
      "msg": "No duplicate creator addresses in metadata"
    },
    {
      "code": 6006,
      "name": "CreatorDidNotVerify",
      "msg": "Creator did not verify the metadata"
    },
    {
      "code": 6007,
      "name": "CreatorNotFound",
      "msg": "Creator not found in creator Vec"
    },
    {
      "code": 6008,
      "name": "NoCreatorsPresent",
      "msg": "No creators in creator Vec"
    },
    {
      "code": 6009,
      "name": "CreatorHashMismatch",
      "msg": "User-provided creator Vec must result in same user-provided creator hash"
    },
    {
      "code": 6010,
      "name": "DataHashMismatch",
      "msg": "User-provided metadata must result in same user-provided data hash"
    },
    {
      "code": 6011,
      "name": "CreatorsTooLong",
      "msg": "Creators list too long"
    },
    {
      "code": 6012,
      "name": "MetadataNameTooLong",
      "msg": "Name in metadata is too long"
    },
    {
      "code": 6013,
      "name": "MetadataSymbolTooLong",
      "msg": "Symbol in metadata is too long"
    },
    {
      "code": 6014,
      "name": "MetadataUriTooLong",
      "msg": "Uri in metadata is too long"
    },
    {
      "code": 6015,
      "name": "MetadataBasisPointsTooHigh",
      "msg": "Basis points in metadata cannot exceed 10000"
    },
    {
      "code": 6016,
      "name": "TreeAuthorityIncorrect",
      "msg": "Tree creator or tree delegate must sign."
    },
    {
      "code": 6017,
      "name": "InsufficientMintCapacity",
      "msg": "Not enough unapproved mints left"
    },
    {
      "code": 6018,
      "name": "NumericalOverflowError",
      "msg": "NumericalOverflowError"
    },
    {
      "code": 6019,
      "name": "IncorrectOwner",
      "msg": "Incorrect account owner"
    },
    {
      "code": 6020,
      "name": "CollectionCannotBeVerifiedInThisInstruction",
      "msg": "Cannot Verify Collection in this Instruction"
    },
    {
      "code": 6021,
      "name": "CollectionNotFound",
      "msg": "Collection Not Found on Metadata"
    },
    {
      "code": 6022,
      "name": "AlreadyVerified",
      "msg": "Collection item is already verified."
    },
    {
      "code": 6023,
      "name": "AlreadyUnverified",
      "msg": "Collection item is already unverified."
    },
    {
      "code": 6024,
      "name": "UpdateAuthorityIncorrect",
      "msg": "Incorrect leaf metadata update authority."
    },
    {
      "code": 6025,
      "name": "LeafAuthorityMustSign",
      "msg": "This transaction must be signed by either the leaf owner or leaf delegate"
    },
    {
      "code": 6026,
      "name": "CollectionMustBeSized",
      "msg": "Collection Not Compatable with Compression, Must be Sized"
    },
    {
      "code": 6027,
      "name": "MetadataMintMismatch",
      "msg": "Metadata mint does not match collection mint"
    },
    {
      "code": 6028,
      "name": "InvalidCollectionAuthority",
      "msg": "Invalid collection authority"
    },
    {
      "code": 6029,
      "name": "InvalidDelegateRecord",
      "msg": "Invalid delegate record pda derivation"
    },
    {
      "code": 6030,
      "name": "CollectionMasterEditionAccountInvalid",
      "msg": "Edition account doesnt match collection"
    },
    {
      "code": 6031,
      "name": "CollectionMustBeAUniqueMasterEdition",
      "msg": "Collection Must Be a Unique Master Edition v2"
    },
    {
      "code": 6032,
      "name": "UnknownExternalError",
      "msg": "Could not convert external error to BubblegumError"
    },
    {
      "code": 6033,
      "name": "DecompressionDisabled",
      "msg": "Decompression is disabled for this tree."
    },
    {
      "code": 6034,
      "name": "MissingCollectionMintAccount",
      "msg": "Missing collection mint account"
    },
    {
      "code": 6035,
      "name": "MissingCollectionMetadataAccount",
      "msg": "Missing collection metadata account"
    },
    {
      "code": 6036,
      "name": "CollectionMismatch",
      "msg": "Collection mismatch"
    },
    {
      "code": 6037,
      "name": "MetadataImmutable",
      "msg": "Metadata not mutable"
    },
    {
      "code": 6038,
      "name": "PrimarySaleCanOnlyBeFlippedToTrue",
      "msg": "Can only update primary sale to true"
    },
    {
      "code": 6039,
      "name": "CreatorDidNotUnverify",
      "msg": "Creator did not unverify the metadata"
    },
    {
      "code": 6040,
      "name": "InvalidTokenStandard",
      "msg": "Only NonFungible standard is supported"
    },
    {
      "code": 6041,
      "name": "InvalidCanopySize",
      "msg": "Canopy size should be set bigger for this tree"
    }
  ],
  "metadata": {
    "address": "BGUMAp9Gq7iTEuizy4pqaxsTyUCBK68MDfK752saRPUY",
    "origin": "anchor",
    "binaryVersion": "0.27.0",
    "libVersion": "0.28.0"
  }
}