export type Profiles = {
  version: "0.1.0";
  name: "profiles";
  instructions: [
    {
      name: "createProfile";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "identity";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ownerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userProfile";
          isMut: true;
          isSigner: false;
        },
        {
          name: "usernameRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "beacon";
          isMut: true;
          isSigner: false;
        },
        {
          name: "beaconProgram";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "username";
          type: "string";
        },
        {
          name: "displayName";
          type: "string";
        },
      ];
    },
    {
      name: "createPfpWalletTracker";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "identity";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userProfile";
          isMut: true;
          isSigner: false;
        },
        {
          name: "beacon";
          isMut: true;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ownerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beaconProgram";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "editDisplayName";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "identity";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ownerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userProfile";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [
        {
          name: "displayName";
          type: "string";
        },
      ];
    },
    {
      name: "trackPfp";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "identity";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ownerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userProfile";
          isMut: true;
          isSigner: false;
        },
        {
          name: "collectionMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "collectionMetadata";
          isMut: false;
          isSigner: false;
        },
        {
          name: "collectionMasterEdition";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "pfpMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "pfpMetadata";
          isMut: false;
          isSigner: false;
        },
        {
          name: "pfpMasterEdition";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "pfpTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "nftHolderOwnerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beacon";
          isMut: false;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "trackedTokenRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "instruction";
          isMut: true;
          isSigner: false;
        },
        {
          name: "thread";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clockwork";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beaconProgram";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "setPfp";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "identity";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ownerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userProfile";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pfpTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pfpMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "nftHolderOwnerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beacon";
          isMut: false;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "trackedTokenRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "instruction";
          isMut: true;
          isSigner: false;
        },
        {
          name: "thread";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clockwork";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beaconProgram";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "removePfp";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "identity";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ownerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "userProfile";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pfpTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pfpMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "nftHolderOwnerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beacon";
          isMut: false;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "trackedTokenRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "thread";
          isMut: true;
          isSigner: false;
        },
        {
          name: "clockwork";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beaconProgram";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
      ];
      args: [];
    },
    {
      name: "onTokenAccountChange";
      accounts: [
        {
          name: "owner";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
        {
          name: "identity";
          isMut: false;
          isSigner: false;
        },
        {
          name: "nftHolderOwnerRecord";
          isMut: false;
          isSigner: false;
        },
        {
          name: "nftMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "nftTokenAccount";
          isMut: true;
          isSigner: false;
        },
        {
          name: "user";
          isMut: true;
          isSigner: false;
        },
        {
          name: "beacon";
          isMut: false;
          isSigner: false;
        },
        {
          name: "walletTracker";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenRecord";
          isMut: true;
          isSigner: false;
        },
        {
          name: "thread";
          isMut: true;
          isSigner: true;
        },
        {
          name: "clockwork";
          isMut: false;
          isSigner: false;
        },
        {
          name: "beaconProgram";
          isMut: false;
          isSigner: false;
          docs: ["CHECK"];
        },
      ];
      args: [];
    },
  ];
  accounts: [
    {
      name: "user";
      type: {
        kind: "struct";
        fields: [
          {
            name: "identifier";
            type: "publicKey";
          },
          {
            name: "profile";
            type: {
              defined: "Profile";
            };
          },
          {
            name: "username";
            type: "string";
          },
          {
            name: "bump";
            type: "u8";
          },
        ];
      };
    },
    {
      name: "username";
      type: {
        kind: "struct";
        fields: [
          {
            name: "user";
            type: "publicKey";
          },
          {
            name: "name";
            type: "string";
          },
          {
            name: "bump";
            type: "u8";
          },
        ];
      };
    },
  ];
  types: [
    {
      name: "Profile";
      type: {
        kind: "struct";
        fields: [
          {
            name: "displayName";
            type: "string";
          },
          {
            name: "pfp";
            type: {
              option: "publicKey";
            };
          },
        ];
      };
    },
  ];
  errors: [
    {
      code: 6000;
      name: "UnverifiedNFT";
      msg: "Unverified NFT for collection";
    },
    {
      code: 6001;
      name: "CollectionMintMismatch";
      msg: "Incorrect NFT for collection that governs organisation";
    },
  ];
};

export const IDL: Profiles = {
  version: "0.1.0",
  name: "profiles",
  instructions: [
    {
      name: "createProfile",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "identity",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ownerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userProfile",
          isMut: true,
          isSigner: false,
        },
        {
          name: "usernameRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "beacon",
          isMut: true,
          isSigner: false,
        },
        {
          name: "beaconProgram",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "username",
          type: "string",
        },
        {
          name: "displayName",
          type: "string",
        },
      ],
    },
    {
      name: "createPfpWalletTracker",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "identity",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userProfile",
          isMut: true,
          isSigner: false,
        },
        {
          name: "beacon",
          isMut: true,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ownerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beaconProgram",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "editDisplayName",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "identity",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ownerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userProfile",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "displayName",
          type: "string",
        },
      ],
    },
    {
      name: "trackPfp",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "identity",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ownerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userProfile",
          isMut: true,
          isSigner: false,
        },
        {
          name: "collectionMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "collectionMetadata",
          isMut: false,
          isSigner: false,
        },
        {
          name: "collectionMasterEdition",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "pfpMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "pfpMetadata",
          isMut: false,
          isSigner: false,
        },
        {
          name: "pfpMasterEdition",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "pfpTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "nftHolderOwnerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beacon",
          isMut: false,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "trackedTokenRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "instruction",
          isMut: true,
          isSigner: false,
        },
        {
          name: "thread",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clockwork",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beaconProgram",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setPfp",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "identity",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ownerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userProfile",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pfpTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pfpMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "nftHolderOwnerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beacon",
          isMut: false,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "trackedTokenRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "instruction",
          isMut: true,
          isSigner: false,
        },
        {
          name: "thread",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clockwork",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beaconProgram",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "removePfp",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "identity",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ownerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "userProfile",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pfpTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pfpMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "nftHolderOwnerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beacon",
          isMut: false,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "trackedTokenRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "thread",
          isMut: true,
          isSigner: false,
        },
        {
          name: "clockwork",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beaconProgram",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "onTokenAccountChange",
      accounts: [
        {
          name: "owner",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
        {
          name: "identity",
          isMut: false,
          isSigner: false,
        },
        {
          name: "nftHolderOwnerRecord",
          isMut: false,
          isSigner: false,
        },
        {
          name: "nftMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "nftTokenAccount",
          isMut: true,
          isSigner: false,
        },
        {
          name: "user",
          isMut: true,
          isSigner: false,
        },
        {
          name: "beacon",
          isMut: false,
          isSigner: false,
        },
        {
          name: "walletTracker",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenRecord",
          isMut: true,
          isSigner: false,
        },
        {
          name: "thread",
          isMut: true,
          isSigner: true,
        },
        {
          name: "clockwork",
          isMut: false,
          isSigner: false,
        },
        {
          name: "beaconProgram",
          isMut: false,
          isSigner: false,
          docs: ["CHECK"],
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: "user",
      type: {
        kind: "struct",
        fields: [
          {
            name: "identifier",
            type: "publicKey",
          },
          {
            name: "profile",
            type: {
              defined: "Profile",
            },
          },
          {
            name: "username",
            type: "string",
          },
          {
            name: "bump",
            type: "u8",
          },
        ],
      },
    },
    {
      name: "username",
      type: {
        kind: "struct",
        fields: [
          {
            name: "user",
            type: "publicKey",
          },
          {
            name: "name",
            type: "string",
          },
          {
            name: "bump",
            type: "u8",
          },
        ],
      },
    },
  ],
  types: [
    {
      name: "Profile",
      type: {
        kind: "struct",
        fields: [
          {
            name: "displayName",
            type: "string",
          },
          {
            name: "pfp",
            type: {
              option: "publicKey",
            },
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "UnverifiedNFT",
      msg: "Unverified NFT for collection",
    },
    {
      code: 6001,
      name: "CollectionMintMismatch",
      msg: "Incorrect NFT for collection that governs organisation",
    },
  ],
};
