import * as React from "react"

const RadialGradient = (props:any) => (
  <svg
    width={2374}
    height={2374}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <circle
        cx={1186.61}
        cy={1186.61}
        r={872.32}
        transform="rotate(-30 1186.61 1186.61)"
        fill="url(#b)"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={314.291}
        y1={314.292}
        x2={2058.93}
        y2={2058.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop className="gradient-stop-1" stopColor="#8FFEAA" />
        <stop className="gradient-stop-1" offset={0} stopColor="#8FFEAA" stopOpacity={0.932} />
        <stop className="gradient-stop-2" offset={1} stopColor="#007AFF" stopOpacity={0} />
      </linearGradient>
      <filter
        id="a"
        x={0.146}
        y={0.146}
        width={2372.93}
        height={2372.93}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation={157}
          result="effect1_foregroundBlur_2646_206"
        />
      </filter>
    </defs>
  </svg>
)

export default RadialGradient
