import { TicketIcon } from "@heroicons/react/24/solid";
import BN from "bn.js";
import { useEffect } from "react";
import { useAuth, useReputation } from "../state/hooks/useAlignGovernance";
import { useNotifications } from "../state/hooks/useNotifications";
import { NotifType } from "../state/notifications";

export function Notifier(){
    // const user = useAuth()
    // const unclaimedReputation = useUnclaimedReputation(user?.identity?.identifier)
    // const {createNotification, notifications} = useNotifications()

    // useEffect(() => {
    //     unclaimedReputation
    //     .filter(x => {
    //         if(parseInt(x.unclaimedReputation as unknown as string) === 0 && x.contributionRecord.account.isClaimed === false) {
    //             return false
    //         }
    //         return true
    //     })
    //     .map( rep => {
    //         createNotification({
    //             id: rep.contributionRecord.address,
    //             timeStamp: rep.proposalTimestamp,
    //             title: `Reputation to redeem`,
    //             message: `${user?.displayName}, 
    //                         a proposal you interacted with has ended. 
    //                         You have ${parseInt(rep.unclaimedReputation as unknown as string, 16)} reputation to claim from it.`,
    //             type: NotifType.RedeemReputation,
    //             image: (<TicketIcon className="w-7 h-7"/>),
    //             seen: false,
    //             timeout: 0,
    //             context: rep
    //         })
    //     })
        

    // }, [unclaimedReputation, user?.identity?.identifier])
    return <></>    
}