import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

const Drawer = ({
    onClose,
    open,
    position,
    title,
    children
}: {
    onClose : () => void,
    open : boolean,
    title : string,
    position : "right" | "left" | "bottom",
    children : ReactNode
}) => {
    return ( <>
        
            
            <div className={`fixed px-5 pt-3 flex flex-col top-0 right-0 overflow-y-auto scrollbar z-20 w-full md:w-1/3 h-full transition-all duration-500 transform border-l-2 border-opacity-20 bg-black ${open ? "translate-x-0" : "translate-x-full"}`}>
                <div className=" text-xl font-semibold flex h-14 items-center justify-between " >
                    {title}
                    <button onClick={onClose} className=" hover:bg-contrast hover:bg-opacity-20 rounded-full">
                        <XMarkIcon className="w-8 text-contrast"/>
                    </button>
                </div>
                {/* <hr className=" opacity-20"/> */}

                {children}
            </div>
     
     </>
    )
}
 
export default Drawer;