import { PublicKey } from "@metaplex-foundation/js";

export const truncateKey = (s: string, chars = 5) => {
  return s.slice(0, chars) + "…" + s.slice(-1 * chars);
};

export const isPubkey = (key: PublicKey | string | null) => {
  if ((key as PublicKey)?.toBase58) {
    return true;
  }
  try {
    new PublicKey(key as PublicKey);
    return true;
  } catch (e) {
    return false;
  }
};
