import {
  Program,
  AnchorProvider,
  BN,
  web3,
  Wallet,
} from "@project-serum/anchor";
import { ShdwDrive } from "@shadow-drive/sdk";
import { PublicKey } from "@solana/web3.js";
import { AlignGovernance } from "./idls/align_governance";
import { Beacon as BeaconIDL } from "./idls/beacon";
import { Identifiers } from "./idls/identifiers";
import { Leaf } from "./idls/leaf";
import { Multigraph } from "./idls/multigraph";
import { Profiles } from "./idls/profiles";

export enum EdgeRelation {
  Asymmetric,
  Symmetric,
}

export enum ConnectionType {
  SocialRelation,
  Interaction,
}

export interface AlignPrograms {
  alignGovernanceProgram: Program<AlignGovernance>;
  identifiersProgram: Program<Identifiers>;
  multigraphProgram: Program<Multigraph>;
  profilesProgram: Program<Profiles>;
  leafProgram: Program<Leaf>;
  beaconProgram: Program<BeaconIDL>;
  provider?: AnchorProvider;
  shadowDriveInstance: ShdwDrive;
  mainnetProvider: AnchorProvider;
  wallet: Wallet;
}
export interface NativeTreasuryAccount {
  organisation: PublicKey;
  votingProposalCount: number;
  totalProposals: BN;
  councilThreshold: number;
  bump: number;
}

export interface CollectionItem {
  mint: PublicKey;
  repMultiplier: number;
}

export interface OrganisationConfig {
  votingRepReward: number;
  votingInAlignmentReward: number;
  proposalServicedReward: number;
  proposalCreatedReward: number;

  /*
        Whether you require an nft to do any actions in the DAO
        if false and the user has some min_reputation earnt within the DAO
        they can still interact in the DAO
    */
  nftRequireForActions: boolean;
  rankingPeriod: BN;

  /*
        Minimum reputation needed to create proposals
    */
  proposalMinReputationGate: BN | null;

  /*
        Collection items and there multipliers for rep
    */
  collectionItems: Array<CollectionItem>;
}

export interface WarpNftMetadata {
  name: string;
  symbol: string;
  uri: string;
}

export interface WarpNftCreator {
  address: PublicKey;
  verified: boolean;
  share: number;
}

export interface WarpAccount {
  authority: PublicKey;
  fungibleMint: PublicKey;
  fungiblePerNft: BN;
  collectionMint: PublicKey;
  nftUri: string | null;
  bump: number;
}

export interface Organisation {
  identifier: PublicKey;
  config: OrganisationConfig;
  authority: PublicKey;
  currentVotingProposals: number;
  parentOrganisation: PublicKey;
  bump: number;
}

export interface Profile {
  displayName: string;
  pfp: PublicKey | null;
}

export interface User {
  identifier: PublicKey;
  profile: Profile;
  username: String;
  bump: number;
}

export interface UserRecord {
  user: PublicKey;
  name: String;
  bump: number;
}

export interface OwnerRecord {
  identifier: PublicKey;
  isDelegate: boolean;
  // Have we signed using the account getting delegated to avoid spoofing
  isVerified: boolean;
  account: PublicKey;
  // Program that owns the account, system for wallet address or programId of pda owner
  keyAccountOwner: PublicKey;
  reserved: number[];
  bump: number;
}

export interface ContributionRecord {
  idenitfier: PublicKey;
  organisation: PublicKey; // Sub org or Organisation
  proposal: PublicKey; // Account governance that determines council
  isClaimed: boolean;
  upVoteCount: BN;
  downVoteCount: BN;
  bump: number;
}

export interface CollectionNftRecord {
  idenitfier: PublicKey;
  organisation: PublicKey; // Sub org or Organisation
  nftCount: number; // Account governance that determines council
  collectionMint: PublicKey;
  bump: number;
}

export interface Identity {
  identifier: PublicKey;
  owner: PublicKey;
  isInRecovery: boolean;
  recoveryKey: PublicKey | null;
  recoveryCount: number;
  reserved: number[];
  // Future proofing would be nice to have DID cabpabilities
  // but need to consult brian for his expertise
  // http://g.identity.com/sol-did/
  did: string | null;
  bump: number;
}

export interface ContributionReputation {
  proposalVotes: BN;
  proposalVotesInAlignment: BN;
  servicedProposalsFailed: BN;
  servicedProposalsSuccess: BN;
  proposalsCreatedFailed: BN;
  proposalsCreatedSuccess: BN;
}

export interface CapitalReputation {
  amount: number;
  weight: number;
}

export interface ReputationManager {
  identifier: PublicKey;
  organisation: PublicKey;
  contributionReputation: ContributionReputation;
  reputation: BN;
  bump: number;
}

export enum CouncilVote {
  Yes,
  No,
  Abstain,
}

export interface AnchorCouncilVote {
  yes?: {};
  no?: {};
  abstain?: {};
}
export enum SubOrganisationType {
  ProposalModeration,
  Product,
  Engineering,
  Operations,
  CustomerSupport,
  Marketing,
  Growth,
  Finance,
  Security,
  Recruiting,
  Tokenomics,
  Other,
}
export interface AnchorSubOrganisationType {
  proposalModeration?: {};
  product?: {};
  engineering?: {};
  operations?: {};
  customerSupport?: {};
  marketing?: {};
  growth?: {};
  finance?: {};
  security?: {};
  recruiting?: {};
  tokenomics?: {};
  other?: {};
}

export enum ProposalState {
  Draft,
  RequestingServicer,
  ReadyToExecute,
  Executing,
  Ranking,
  Voting,
  Servicing,
  Reviewing,
  Reviewed,
  Complete,
  Denied,
  Canceled,
  ReviewFailed,
}

export interface AnchorProposalState {
  draft?: {};
  ranking?: {};
  reviewFailed?: {};
  requestingServicer?: {};
  voting?: {};
  servicing?: {};
  reviewing?: {};
  reviewed?: {};
  complete?: {};
  denied?: {};
  readyToExecute?: {};
  executing?: {};
  canceled?: {};
}

export interface Proposal {
  state: AnchorProposalState;
  organisation: PublicKey;
  subOrgType: AnchorSubOrganisationType | null;
  proposer: PublicKey; // idenitifier of person who created proposal
  authorityConfigAddress: PublicKey;
  rankingAt: BN | null;
  votingAt: BN | null;
  executedAt: BN | null;
  deniedAt: BN | null;
  approvedAt: BN | null;
  servicerAcceptedRequestAt: BN | null;
  servicerDeclinedRequestAt: BN | null;
  transactionCount: number;
  executingTransactionIndex: number | null;
  draftAt: BN;
  servicer: PublicKey | null; // idenitfier of person to service the proposal
  id: BN;
  shadowDrive: PublicKey;
  councilReviewRating: number | null;
  councilReviewCount: number;
  totalCouncilYesVotes: number;
  totalCouncilNoVotes: number;
  totalCouncilAbstainVotes: number;
  upvotes: BN;
  rankingPeroid: BN;
  downvotes: BN;
  bump: number;
  serviceProofs: string[];
}

export enum RankVoteType {
  Upvote,
  Downvote,
}

export interface AnchorRankVoteType {
  upvote?: {};
  downvote?: {};
}

export interface Account<T> {
  address: PublicKey;
  account: T;
}

export interface AnchorCouncilManagerState {
  electing?: {};
  elected?: {};
}

export interface CouncilManager {
  state: AnchorCouncilManagerState;
  organisation: PublicKey; // Sub org or Organisation
  authority: PublicKey; // Account governance that determines council
  councilIdentifiers: PublicKey[];
  councilCount: number;
  isInElection: boolean;
  electionManager: PublicKey | null;
  electedAt: BN | null;
  bump: number;
}

export interface CouncilVoteRecord {
  organisation: PublicKey; // Sub org or Organisation
  proposal: PublicKey;
  vote: AnchorCouncilVote;
  reviewScore: number | null;
  bump: number;
}

export interface ProposalData {
  name: string;
  description: string;
  // image: string;
}

export interface TransactionState {
  waiting?: {};
  readyToExecute?: {};
  executing?: {};
  success?: {};
  failed?: {};
}

export interface ProposalTransaction {
  state: TransactionState;
  proposal: web3.PublicKey;
  executedAt: BN | null;
  transactionIndex: number;
  instructionCount: number;
  executedBy: web3.PublicKey | null;
  bump: number;
}

export interface AlignAccountMeta {
  pubkey: web3.PublicKey;
  isSigner: boolean;
  isWritable: boolean;
}

export interface ProposalInstruction {
  transaction: web3.PublicKey;
  programId: web3.PublicKey;
  isExecuted: boolean;
  instructionIndex: number;
  accounts: AlignAccountMeta[];
  data: number[];
  bump: number;
}

export interface AuthorityConfigTypeAnchor {
  hot?: {};
  cold?: {};
}

export enum AuthorityConfigType {
  Cold,
  Hot,
}

export interface WalletConfig {
  authorityType: AuthorityConfigTypeAnchor;
  organisation: PublicKey;
  seed: number[];
  votingProposalCount: number;
  totalProposals: BN;
  threshold: number;
  authorityBump: number;
  authorityAddress: PublicKey;
  name: string;
  bump: number;
}

export interface WalletTracker {
  beacon: PublicKey;
  clockStake: PublicKey;
  wallet: PublicKey;
  totalTrackers: number;
  bump: number;
}

export interface TrackedTokenRecord {
  walletTracker: PublicKey;
  tokenAccount: PublicKey;
  mint: PublicKey;
  id: number;
  currentAmount: BN;
  lastMovement: number;
  paused: boolean;
  bump: number;
}

export interface TokenAccountInstructionCallback {
  programId: PublicKey;
  accounts: BeaconAccountMetaData[];
  data: number[];
  bump: number;
}

export interface BeaconAccountMetaData {
  pubkey: PublicKey;
  isSigner: boolean;
  isWritable: boolean;
}

export interface Beacon {
  beacon: PublicKey;
  authority: PublicKey;
  callBackProgramId: PublicKey;
  callBackInstructionData: number[];
  bump: number;
}
