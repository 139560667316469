import { Clipboard, Edit } from "react-feather";
import { Proposal } from "../components/Proposal";
import { Connection, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@project-serum/anchor/dist/cjs/utils/token";
import LoadingSpinner from "../components/LoadingSpinner";
import { Suspense, useEffect, useMemo, useState } from "react";
import {useRecoilValue} from "recoil"
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth, useCurrentOrganisation, useOrganisation, useReputation, useReputationValue, useRoutesCurrentIdentifier, useUser } from "../state/hooks/useAlignGovernance";
import { ProposalSkeleton } from "../components/ProposalSkeleton";
import { nftsForWallet } from "../state/users";
import { Metaplex } from "@metaplex-foundation/js";
import { useAtom } from "jotai";
import { themeOpts } from "../components/ThemeChanger";
import { OrganisationHeader } from "../components/OrganisationHeader";
import { truncateKey } from "../utils/truncateKey";
import toast from "react-hot-toast";
import Avatar from "../components/Avatar";
import { AlignLink } from "../components/AlignLink";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

export const getTokenAccountsByAddress = async (
    addr: string,
    connection: Connection
) => {
    return connection.getParsedTokenAccountsByOwner(new PublicKey(addr), {
        programId: new PublicKey(TOKEN_PROGRAM_ID),
    });
};
export const METADATA_PREFIX = Buffer.from("metadata");
export const METADATA_PROGRAM_ID = new PublicKey(
    "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

export function ProfileOrg() {
    const navigate = useNavigate();
    const identifier = useRoutesCurrentIdentifier()
    const userProfile = useUser(identifier)
    const authProfile = useAuth()
	const [ currentOrgAddress, _ ] = useCurrentOrganisation()

    const [theme, setTheme] = useAtom(themeOpts)
    const {org} = useParams()
    const reputation = useReputationValue(userProfile?.identity?.identifier)


    if (!userProfile ) {
        return (
            <div className="max-w-6xl mx-auto flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="mx-auto max-w-screen-2xl text-contrast">
            <div className="my-proposals-list-container flex-wrap justify-between gap-6 lg:grid lg:grid-cols-3 px-8">
                <div className="w-full lg:col-span-2">

                    <AlignLink path="/" orgAddress={currentOrgAddress}>
                        <button className="mb-4 flex items-center gap-2 breadcrumb-link">
                            <ArrowLeftIcon className="w-4"/> Back to Proposals
                        </button>
                    </AlignLink>

                    <main className="my-proposals-container box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 p-6">

                        {identifier === authProfile?.identity?.identifier ? (
                            <h1 className="font-heading text-4xl">My Proposals</h1>
                        ) : (
                            <h1 className="font-heading text-4xl">{userProfile.displayName}'s Proposals</h1>
                        )}

                        <hr className="mt-6 opacity-30" />
                        {/* <Suspense fallback={<ProposalSkeleton/>}>
                            {identifier === authProfile?.identity?.identifier ? <UserProposalList/> : (identifier && <IdentityProposalList identifier={identifier}/>)}
                        </Suspense> */}

                        {identifier === authProfile?.identity?.identifier && (
                            <div className="mt-auto">
                                <button
                                onClick={() => navigate(`/proposal/create`)}
                                className={`w-full bg-accent rounded-button border-primary text-contrast border-buttonWidth transition font-poppins font-medium p-3 ${theme.mono ? 'border-contrast hover:bg-contrast hover:text-secondary' : 'border-primary hover:bg-opacity-80'}`}
                                >
                                Create New Proposal
                                </button>
                            </div>
                        )}
                    </main>
                </div>
                <aside className="user-info-container w-full lg:col-span-1">
                    {/* <div className="box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-[color:var(--box-background)] mb-6 w-full"> */}
                    <div className="mb-6 w-full">
                        <div className="ml-5 mt-5 flex">
                        {userProfile?.identity?.identifier && <Avatar userIdentifier={new PublicKey(userProfile?.identity?.identifier)} size={24}/>}
                            <p className="ml-3 mt-6 text-xl">
                                <span className="block">
                                    {userProfile.displayName}
                                </span>
                                <span className="block text-xs font-thin opacity-50">
                                    {userProfile.username}
                                </span>
                            </p>
                            <p className="flex grow"></p>
                            <p className="align-right mr-10 mt-6 items-end justify-end text-right text-xl">
                                {!!reputation && (
                                    <span className="block">{reputation.toString()} REP</span>
                                )}
                                <span className="block text-xs font-thin opacity-50">
                                    {/* Store should derive this */}
                                    {/* {proposals?.filter(proposal => proposal.account.proposer.toBase58() === user.account.identifier.toBase58()).length || 0} Proposal(s) */}
                                </span>
                            </p>
                        </div>
                        <div className="my-5 ml-6 mr-10">
                            {/* <p className="text-sm opacity-75">
                                1/1 artist, musician, Webflow design/dev,
                                arm-chair DAOist. Built websites for PopHeadz,
                                BAPE Services, Bears Reloaded, Sanctuary,
                                H3LIUM, Chicken Tribe, and more.{" "}
                            </p> */}
                            <p className="mt-5 text-xs opacity-75">
                                {userProfile && (
                                    <>
                                    <strong>Profile Identifier: </strong>
                                    {userProfile?.identity && truncateKey(userProfile?.identity?.identifier, 12)}
                                    <Clipboard
                                        className="inline-block h-4 cursor-pointer"
                                        onClick={() => {
                                            if(!userProfile?.identity?.identifier){
                                                return
                                            }
                                            navigator.clipboard.writeText(
                                                userProfile?.identity?.identifier
                                            );
                                            toast.success("Copied to clipboard.")
                                        }}/>
                                    </>
                                )}
                            </p>
                            <p className="mt-5 text-xs opacity-75">
                                {userProfile && (
                                    <div className="flex gap-1">
                                    <strong>Linked Wallets: </strong>
                                    {userProfile && userProfile.ownerRecords.map(wallet => <>
                                        <p>{truncateKey(wallet.account, 12)}</p>
                                        <Clipboard
                                        className="inline-block h-4 cursor-pointer"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                wallet.account
                                            );
                                            toast.success("Copied to clipboard.")
                                        }}/>
                               
                                    </>)}
                                    </div>
                                )}
                            </p>

                            {identifier === authProfile?.identity?.identifier && (
                                <div className="mt-5">
                                    <button className="mr-4 rounded-full border border-white border-opacity-30 bg-transparent py-2 px-4 text-xs uppercase">
                                        Profile Recovery Key{" "}
                                        <Clipboard className="inline-block h-4" />
                                    </button>
                                    <button className="rounded-full border border-white border-opacity-30 bg-transparent py-2 px-4 text-xs uppercase" onClick={() => {
                                        if(userProfile?.identity){
                                            navigate(`/u/${userProfile.identity.identifier}/edit`);
                                        }
                                    }}>
                                        Edit Profile{" "}
                                        <Edit className="inline-block h-4" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    );
}

// export function UserProposalList() {
//     const [organisation] = useCurrentOrganisation()
//     const proposals = useRecoilValue(userProposedProposals(organisation));

//     return <>
//         {proposals?.length ? 
//             proposals?.map((proposal) => <Proposal proposal={proposal} key={proposal.address.toBase58()} />)
//             :
//             <p className="h-24 flex justify-center items-center my-4">You don't have any current draft proposals</p>
//         }
//     </>
// }


// export function IdentityProposalList({identifier}: {identifier: string}) {
//     const [organisation] = useCurrentOrganisation()

//     const proposals = useRecoilValue(allProposalAccounts(organisation));
//     const proposalsByIDentifier = proposals?.filter(proposal => proposal.account.proposer.toBase58() === identifier);

//     return <>
//         {proposalsByIDentifier?.length ? 
//             proposalsByIDentifier?.map((proposal) => <Proposal proposal={proposal} key={proposal.address.toBase58()} />)
//             :
//             <p className="h-24 flex justify-center items-center my-4">This member hasn't created any proposals</p>
//         }
//     </>
// }

