import { ForwardIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetUserQuery } from "../../../../generated/graphql";
import { DebouncedFunc, add, debounce } from "lodash";
import { useWallet } from "@solana/wallet-adapter-react";
import { useAuth } from "../../../../state/hooks/useAlignGovernance";
import { useSetRecoilState,  } from "recoil";
import { proposalWizardSendAssetsRecipients } from "../../../../state/forms";
import { isPubkey } from "../../../../utils/truncateKey";
import { toast } from "react-hot-toast";
import { WizardStepProps } from "../../Wizard";

type RecipientProps = {
    count: number,
    defaultWalletAddress?: string,
    defaultName?: string,
    onEntry: (walletAddress: string, name : string) => void
    onRemove: () => void
}

const Recipient = ({
    count,
    defaultWalletAddress,
    defaultName,
    onEntry,
    onRemove
}: RecipientProps) => {

    const [inputWalletAddress, setInputWalletAddress] = useState(defaultWalletAddress || "");
    const [inputName, setInputName] = useState(defaultName || "");

    // States to hold fetched data
    const [fetchedWalletAddress, setFetchedWalletAddress] = useState("");
    const [fetchedName, setFetchedName] = useState("");

    // Fetch user based on either walletAddress or name
    const { data } = useGetUserQuery({
        variables: {
            address: inputWalletAddress
        },
        skip: inputWalletAddress === "" && inputName === ""
    });

    useEffect(() => {
        if (data?.user) {
            setFetchedName(data.user.displayName || "");
        }
    }, [data, inputName, inputWalletAddress]);

    useEffect(() => {
        if (fetchedName) {
            onEntry(inputWalletAddress, fetchedName);
        }
    }, [fetchedName, inputWalletAddress]);

    // const debounceFn = useCallback<DebouncedFunc<(value: { wallet: string, name: string }) => void>>(
    //     debounce((value: { wallet: string, name: string }) => onEntry(value.wallet, value.name), 100),
    //     []
    // );
    return (
        <li className="w-full bg-contrast bg-opacity-10 p-4 h-28 rounded flex flex-col gap-2">
            <div className="flex justify-between">
                <h2 className="text-sm">Recipient {count}:</h2>
                <div className="flex gap-1 text-xs justify-center items-center font-light">
                    Remove
                    <button className=" flex justify-center items-center w-5 h-5 rounded-full bg-contrast bg-opacity-20">
                        <XMarkIcon className="w-4" onClick={() => onRemove()}/> 
                    </button>
                </div>
            </div>
            <div className="flex-1 flex gap-3 items-center">
            <input  
            className=" w-32 md:w-52 bg-transparent hover:border-accent active:border-accent active:outline-none border-contrast border-opacity-20 border text-sm pl-2 h-8" 
                value={inputWalletAddress } onChange={e => {
                setInputWalletAddress(e.target.value);
                onEntry(e.target.value, inputName || fetchedName ); }} 
                placeholder="Address"
                />

        <input
        className=" w-52 bg-transparent hover:border-accent disabled:text-opacity-60 text-contrast disabled:border-contrast disabled:border-opacity-20 active:border-accent active:outline-none border-contrast border-opacity-20 border text-sm pl-2 h-8" 
            value={inputName || fetchedName} onChange={e => {
                console.log(e.target.value)
            setInputName(e.target.value);
            onEntry( inputWalletAddress, e.target.value);
        }} disabled={fetchedName !== ""} placeholder="Recipient Name" />
            </div>
        </li>
    )
}

type RecipientType = {
    id : number,
    walletAddress: string,
    name: string
}

const ProposalWizardRecipient = ({
    currentStep,
    totalSteps,
    goBack,
    onComplete
} : WizardStepProps<{}>) => {

    const user = useAuth()
    const setInputs = useSetRecoilState(proposalWizardSendAssetsRecipients)

    const [recipients, setRecipients] = useState<RecipientType[]>([{
        id : Math.floor(Math.random() * 9999999),
        walletAddress: "",
        name: ""
    }]);

    const handleSubmit = (recipients : {
        id : number
        walletAddress: string,
        name : string,
    }[] ) => {
        const isValid = recipients?.length > 0 && recipients?.every((rec) => isPubkey(rec?.walletAddress))
        
        if(!isValid){
            toast.error("You have submitted an invalid pubkey. Please remove or edit to continue.")
            return
        }
        
        setInputs(recipients)
        onComplete({})
    }

    if(currentStep !== 1 ){
        return <></>
    }



    return ( 
        <div className="proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full md:w-full p-6 lg:col-span-2 sm:p-6 bg-secondary border-boxWidth flex flex-col">
            <div className="flex justify-between">
              <div className="font-heading text-lg md:text-3xl mb-2">Create Send Proposal </div>
              <p className="text-lg font-semibold text-primary">STEP {currentStep + 1}/{totalSteps}</p>
            </div>
            <h3 className="text-sm font-normal mb-6">Input or select recipients to send to</h3>

            <div className="w-full mb-3 h-96 p-4 proposals-container box-container bg-opacity-30 rounded-lg border-2 border-contrast border-opacity-30 flex-col justify-start items-start gap-2.5 inline-flex">
                <h2 className="text-sm">Send To:</h2>
                <ol className="flex h-full flex-col gap-3 w-full overflow-auto scroll">
                    {recipients?.map((rec, i) => (
                        <Recipient 
                            key={rec.id}
                            count={i + 1}
                            defaultName={rec.name}
                            defaultWalletAddress={rec.walletAddress}
                            onRemove={() => recipients.length > 0 ? setRecipients(value => value.filter( r => r.id !== rec.id )) : null} 
                            onEntry={(walletAddress: string, name : string) => {
                                setRecipients(currentRecipients => {
                                    return currentRecipients.map((rec, idx) => {
                                        if (idx === i) { 
                                            return { ...rec, walletAddress, name };
                                        }
                                        return rec; 
                                    });
                                });
                            }}
                        />
                    ))}
                </ol>
            <div className="text-sm flex gap-2 mt-3 self-end h-1/5">
                <button
                    //@ts-ignore
                    onClick={() => setRecipients(val => [...val, {walletAddress: user?.publicKey?.toBase58(), name: user?.displayName, id : Math.floor(Math.random() * 9999999)}])}
                    disabled={user?.id === undefined}
                    className="border border-accent rounded-full px-4 py-2 h-10 text-accent text-opacity-90 border-opacity-90" >
                    Add Me
                </button>
                <button 
                    onClick={() => setRecipients(val => [...val, {walletAddress: "", name: "", id : Math.floor(Math.random() * 9999999)}])}
                    className="border border-accent hover:border-opacity-90 hover:bg-opacity-90 bg-accent rounded-full px-4 py-2 h-10" >
                    Add Recipient
                </button>
        
            </div>
            </div>
            <button
                className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary  
                transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                
                onClick={() => handleSubmit(recipients)}
                >
                 <ForwardIcon className={`w-4 `}/>
                Next Step
            </button>
            <button
              className="w-full flex justify-center gap-2 items-center mt-4 self-end border rounded-button border-primary 
              transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
              onClick={() => goBack()}
          >
              <ForwardIcon className={`w-4 transform rotate-180`}/>
              Go back
          </button>
    </div>           
        
     );
}
 
export default ProposalWizardRecipient;

