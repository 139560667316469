export function TreasuryPanelSkeleton() {
  return (
    <div className="box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-[color:var(--box-background)] p-4 space-y-4">
      <div className="animate-pulse">
        <p className="w-24 h-2 bg-gray-700 rounded-full mb-4"></p>
        <div className="box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-[color:var(--box-background)] flex-1 px-4 py-3">
          <div className="h-full items-stretch gap-6 md:flex">
            
            <div className="flex-1 w-full animate-pulse">
              <p className="w-1/2 h-4 bg-gray-700 rounded-full mb-3"></p>
              <p className="w-full h-2 bg-gray-700 rounded-full mb-1"></p>
              <p className="w-3/4 h-2 bg-gray-700 rounded-full mb-1"></p>
              <p className="w-full h-2 bg-gray-700 rounded-full mb-1"></p>
              <p className="w-full h-2 bg-gray-700 rounded-full mb-1"></p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 py-4 animate-pulse"> 
          <div className="flex flex-col gap-2">
            <p className="w-12 h-2 bg-gray-700 rounded-full mb-1"></p>
            <p className="w-6 h-4 bg-gray-700 rounded-full mb-1"></p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="w-12 h-2 bg-gray-700 rounded-full mb-1"></p>
            <p className="w-6 h-4 bg-gray-700 rounded-full mb-1"></p>
          </div>
        </div>
        <div className="grid grid-cols-2 py-4 animate-pulse"> 
          <div className="flex flex-col gap-2">
            <p className="w-12 h-2 bg-gray-700 rounded-full mb-1"></p>
            <p className="w-6 h-4 bg-gray-700 rounded-full mb-1"></p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="w-12 h-2 bg-gray-700 rounded-full mb-1"></p>
            <p className="w-6 h-4 bg-gray-700 rounded-full mb-1"></p>
          </div>
        </div>
      </div>
      <div className="my-4 w-full justify-between gap-2 sm:gap-4">
        <div className="box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-[color:var(--box-background)] flex-1 px-4 py-3">
          <div className="h-full items-stretch gap-6 md:flex">
            <div className="flex-1 animate-pulse">
              <p className="w-3/4 h-4 bg-gray-700 rounded-full mb-3"></p>
              <p className="w-full h-2 bg-gray-700 rounded-full mb-1"></p>
              <p className="w-full h-2 bg-gray-700 rounded-full mb-1"></p>
              <p className="w-full h-2 bg-gray-700 rounded-full mb-1"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
