import { ForwardIcon, InformationCircleIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { useWallet } from "@solana/wallet-adapter-react";
import { Api } from "align-sdk";
import { Tooltip } from "antd";
import { Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { PfpModal } from "../../../pages/ProfileCreate";
import { organisationProfileFormInput } from "../../../state/forms";
import { useAlignPrograms } from "../../../state/hooks/useAlignGovernance";
import { nftsForWallet } from "../../../state/users";
import { HeliusGetAssetResponse } from "../../../types";
import { WizardStepProps } from "../../CreateProposalWizard/Wizard";
import { DescriptionPanel } from "../../DescriptionPanel";

export function OrganisationProfileStep({ onComplete }: Omit<WizardStepProps<{
  handle : string,
  displayName : string,
  pfpMint: string
}>, 'goBack'>){
  const setConfigInputs = useSetRecoilState(organisationProfileFormInput)
  const profileFormData = useRecoilValue(organisationProfileFormInput); 
  const alignPrograms = useAlignPrograms()
  const [modalPfpOpen, setModalPfpOpen] = useState(false);
  const wallet = useWallet()
  const [pfps, setPfps] = useState<any[]>([]);

 // Initialize state with fetched data
   const [pfpImage, setPfpImage] = useState(profileFormData?.pfpMint || '');
   const nfts = useRecoilValue(nftsForWallet(wallet.publicKey?.toBase58() as string))

   // Initialize useForm with default values from Recoil store
   const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm<{
       handle : string,
       displayName : string,
       pfpMint: string
   }>({
       defaultValues: {
           handle: profileFormData?.handle || '',
           displayName: profileFormData?.displayName || '',
           pfpMint: profileFormData?.pfpMint || ''
       }
   });
  const displayName = watch("displayName")
  const handle = watch("handle")
  const pfpMint = watch("pfpMint")


useEffect(() => {
  if( !pfpMint || !nfts) return;
  const currentPfp = nfts.find((p: HeliusGetAssetResponse) => p.id as any === pfpMint);
  if( currentPfp && currentPfp?.content?.metadata ) {
    setPfpImage(currentPfp.content?.links.image as string)
  }
},[pfpMint, nfts])



 const onSubmit = (profile : {
      handle : string,
      displayName : string,
      pfpMint: string
}) => {
      if(!pfpMint){
          toast.error("Please choose a nft to use as your DAO's profile picture.")
          return
      }

      console.log("Storing organisation profile inputs", profile)
      setConfigInputs(profile)
      onComplete(profile)
 }

 return (
  <>
       <DescriptionPanel message="In this step we are creating a user profile on chain for your organisation. Choose an NFT to use as the profile picture for your organisation, 
       this NFT will be transfered to an DAO goverened wallet upon creating the profile."/>
      <form onSubmit={handleSubmit(onSubmit)} 
          className=" flex flex-col gap-4 rounded-box border-boxWidth border-black border-opacity-30 bg-secondary bg-opacity-30 w-full p-6 lg:col-span-2">

          <div className="mt-2 flex justify-center rounded-box py-5">
              <button className="hover:opacity-80 relative" onClick={(e) => {
                  e.preventDefault()
                  setModalPfpOpen(true)
              }}>
                  {pfpImage ? (
                      <img
                      className="h-24 w-24 rounded-full border border-black border-opacity-30"
                      src={pfpImage}
                      alt="avatar"
                      />
                  ) : (
                      
                      <span className="h-24 w-24 rounded-full flex items-center justify-center border relative border-white border-opacity-30">
                          <svg className="w-12 h-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
                      </span>
                  )}
                  <PencilSquareIcon className="w-7 h-7 p-1 bg-accent bg-opacity-80 rounded-full absolute right-1/2 top-1/2 translate-x-1/2 -translate-y-1/2"/>

                  
              </button>
              <p className="ml-3 mt-6">
                  <span className="block text-lg font-light">
                      {displayName ? displayName : "Phase Labs"}
                  </span>
                  <span className="block text-sm font-light text-contrast text-opacity-40">
                      {handle ? "@" + handle : "@phaseLabs"}
                  </span>
              </p>
          </div>


              {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4"> */}
              <div className="">
                  <div className="flex gap-3 items-center">
                      <label className="font-light">Handle</label> 
                      <Tooltip title="The unique identifier for your profile">
                          <InformationCircleIcon className="w-4 text-opacity-10"/>
                      </Tooltip>
                      {errors.handle?.type === 'pattern' && <span className="text-xs text-red-500 ">Handle not valid</span>}
                      {errors.handle?.type === 'isAvailable' && <span className="text-xs text-red-500 ">Username is not available</span>}


                  </div>

                  <div className="flex w-full gap-4 input">
                      <label className="pr-2">@</label>
                      <input {...register("handle", { 
                          required: true,
                          pattern: /^[a-z0-9_]{1,15}$/,
                          validate: {
                              isAvailable: async (val) => {
                                  if(!alignPrograms){
                                      return false
                                  }
                                  const userProfile = await Api.fetchUserProfileByUsername("@" + val, alignPrograms)
                                  return userProfile === null
                              } 
                          }
                          })} className="bg-transparent outline-none" placeholder="phaselabs"/>
                  </div>   
              </div>
              <div className="">
                  <div className="flex gap-3 items-center">
                      <label className="font-light">Display Name</label> 
                          <Tooltip title="The display name for your profile.">
                              <InformationCircleIcon className="w-4 text-opacity-10"/>
                          </Tooltip>
                          {errors.displayName?.type === 'required' && <span className="text-xs text-red-500 ">Display name is required.</span>}

                  </div>

                  <div className="flex w-full gap-4">
                      <input {...register("displayName", 
                      { 
                          required: true,
                          pattern: /^(?=.*[A-Za-z0-9])[A-Za-z0-9_\-.,!?()\[\]{}<>"' ]{3,22}$/
                      })} className="input " placeholder="Phase Labs"/>
                  </div>   
              </div>



          <div>

                  <button
                          className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary                              
                          transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                          type="submit"
                          
                          >
                          <ForwardIcon className={`w-4 `}/>
                          Next Step
                  </button>
              </div>
      </form>

      <Suspense>
            <PfpModal 
        onClose={() => setModalPfpOpen(false)} 
        pfps={nfts}
        setPfp={(pfp: string) => setValue("pfpMint", pfp)} 
        show={modalPfpOpen}/>
          </Suspense>
  </>
  )
}