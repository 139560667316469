import { PublicKey } from "@solana/web3.js";
import { useMemo, useState } from "react";
import { useAlignPrograms, useAuth, useOrganisation, useProposalAddresses, useRoutesCurrentIdentifier } from "../state/hooks/useAlignGovernance";
import { Account, Api, CollectionNftRecord, Derivation } from 'align-sdk'
import { Link } from "react-router-dom";
import BN from "bn.js";
import MintProfilePicture from "./MintProfilePicture";
import { useGetReputationQuery } from "../generated/graphql";

export function UserOrganisationItem({organisation, username, displayname, pfp, identifier} : {organisation : string, username : string | undefined, displayname : string | undefined, pfp: string | null | undefined, identifier: string}){
    const {proposalAddresses} = useProposalAddresses(organisation)

    const reputationResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: Derivation.deriveIdentityAddress(new PublicKey(identifier || "")).toBase58(),
            organisationAddress : organisation || "" 
        },
        skip: identifier === undefined
    })


    return (<>
        {!reputationResponse.loading  && (
            <Link 
                to={`/org/${organisation}`}>
                <div className="box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 py-2 px-2">
                    <div className="flex p-3 gap-3 justify-start items-center text-contrast relative">
                        <span className="absolute top-3 right-3 text-xs border-x border-contrast border-opacity-10 bg-opacity-70 rounded-full bg-accent text-contrast px-2 py-1">{reputationResponse?.data?.reputation.totalReputation} REP</span>
                        {/* <img className="rounded-full w-16" src={organisationAccount.organisation?.pfp?.pfp} alt={organisationAccount?.profile?.username.toString()}/> */}
                        <MintProfilePicture mint={pfp} width={16}/>
                        <div className="flex h-full flex-col justify-center">
                            <div className="flex gap-3 justify-center items-center">
                                <h1 className="text-lg md:text-2xl">{displayname}</h1>
                            </div>
                            <label className="text-sm font-thin opacity-75">{username}</label>
                            <small className="opacity-80 uppercase mt-2">{proposalAddresses && `${proposalAddresses.length} Proposals`}</small>
                        </div>
                    </div>
                </div>
            </Link>
        )

        }
        </>
    )
}