import { Listbox, Tab, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, ForwardIcon } from "@heroicons/react/24/outline";
import { TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { BERN_MINT_ADDRESS, USDC_MINT_ADDRESS } from "align-sdk";
import { Fragment, useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchNfts } from "../../../../api/nfts";
import { fetchMultiCoinPrice } from "../../../../api/token";
import { SCAM_MINTS } from "../../../../constants";
import { getTokenAccountsByAddress } from "../../../../pages/Profile";
import { proposalWizardSendAssetsNFTs, proposalWizardSendAssetsRecipients, proposalWizardSendAssetsTokens } from "../../../../state/forms";
import { useAuth, useCurrentOrganisation, useOrganisation } from "../../../../state/hooks/useAlignGovernance";
import { truncateKey } from "../../../../utils/truncateKey";
import { WizardStepProps } from "../../Wizard";
import { NFT, NFTItem } from "./ProposalWizardSendTokens/components/NFTItem";
import { HeliusGetAssetResponse } from "../../../../types";

export type Recipient = {
  id: number;
  walletAddress: string;
  name: string;
}

type CoinComponentProps = {
  amount: number,
  mint: string,
  recipients: Recipient[],
  price: number | null | undefined,
  selectedRecipients: string[],
  tokenAmounts: Record<string, string>,
  onRecipientChange: (selectedRecipients: string[], tokenAmounts: Record<string, string>) => void
}


const CoinComponent = ({
  amount,
  mint,
  recipients,
  price,
  selectedRecipients,
  tokenAmounts,
  onRecipientChange
}: CoinComponentProps) => {
  const [name, setName] = useState<string | null>(null);
  const [image, setImage] = useState<string | null | undefined>(null)

  const handleSelectedRecipientsChange = (newSelectedRecipients: string[]) => {
    onRecipientChange(newSelectedRecipients, tokenAmounts);
  };

  const handleTokenAmountsChange = useCallback((newTokenAmounts: Record<string, string>) => {
    onRecipientChange(selectedRecipients, newTokenAmounts);
  }, [selectedRecipients, onRecipientChange]);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        if (mint === "11111111111111111111111111111111") {
          setName("SOL")
          setImage("/sol-logo.webp")
          return
        }

        if (mint === USDC_MINT_ADDRESS.toBase58()) {
          setName("USDC")
          setImage("/usdc-logo.webp")
          return
        }

                if(mint === BERN_MINT_ADDRESS.toBase58()){
                    setName("BERN")
                    setImage("/bern.jpg")
                    return
                }
                
                const account = await fetchNfts([mint])
                if(account.length === 0){
                    return
                }
                setName(account[0]?.content?.metadata.name)
                setImage(account[0]?.content?.links.image)
            }
            catch(e){
               return 
            }
        
        }

    fetchMetadata()
  }, [mint]);

  const handleMaxAmount = useCallback(() => {
    selectedRecipients.forEach(recipient => {
      handleTokenAmountsChange({
        ...tokenAmounts,
        [recipient]: amount.toString()
      });
    });
  }, [amount, selectedRecipients, tokenAmounts, handleTokenAmountsChange]);

  return (
    <li className="w-full bg-contrast bg-opacity-10 p-4 h-fit rounded flex flex-col gap-2">
      <div className="flex flex-col mb-2 md:mb-0 md:flex-row justify-between">

        <div className="flex gap-3 mb-2 md:mb-0 justify-center items-center">
          <img className="rounded-full w-8 h-8" src={image || ""} alt="" />
          <div className="flex flex-col ">
            <div className="flex gap-3 justify-start items-center ">
              <div>{amount}</div>
              <div className="text-xs font-light text-contrast text-opacity-70">${Math.floor((price || 0 * amount) * 100) / 100}</div>
            </div>
            <a target="_blank" href={`https://solana.fm/address/${mint}`} className="hover:cursor-pointer hover:text-opacity-60 text-xs text-accent text-opacity-20">{mint !== "11111111111111111111111111111111" ? truncateKey(mint) : "~"}</a>
          </div>

        </div>

        <div className="flex gap-1 text-xs justify-center items-center font-light">
          <Listbox value={selectedRecipients} onChange={handleSelectedRecipientsChange} multiple>
            <div className="relative mt-1">
              <div className="flex gap-2 px-3 md:px-0 ">
                <Listbox.Button className="relative md:w-full cursor-default rounded-lg bg-accent py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate">Select Recipients</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <div className="flex gap-1 justify-between items-center px-2 bg-accent w-[150px]">
                  <label>Amount</label>
                  <img className="rounded-full w-6 h-6" src={image || ""} alt="" />
                </div>

              </div>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-contrast py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                  {recipients.map((person, personIdx) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-accent' : ' text-black'
                        }`
                      }
                      value={person.id}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                              }`}
                          >
                            {person.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>


        </div>
      </div>

      {
        selectedRecipients.map(sel => (
          <div className="flex gap-2 justify-between md:justify-start items-center">
            <div className="hidden md:flex md:flex-1" />
            <label className="bg-contrast bg-opacity-20 px-2 py-1 text-sm text-left">{recipients.find(x => x?.id as any === sel)?.name}</label>
            <div
              className="relative z-0 flex justify-end items-center w-[100px] bg-transparent hover:border-accent active:border-accent active:outline-none border-contrast border-opacity-20 border text-sm h-8"

            >
              <input
                className="absolute z-0 bg-transparent max-w-fit h-full w-full pl-2"
                onChange={(e) => {
                  let value = e.target.value
                  if (e.target.value[0] === ".") {
                    value = `0${e.target.value}`
                  }
                  var regex = /^(\d+\.?\d*|\.)?$/;
                  if (regex.test(value)) {
                    handleTokenAmountsChange({
                      ...tokenAmounts,
                      [sel]: value
                    });
                  }
                }}
                value={tokenAmounts[sel]}
              />
              <img className="rounded-full w-6 h-6 mr-2" src={image || ""} />
            </div>
            <button
              className="flex gap-1 justify-between items-center px-2 text-sm h-8 bg-accent"
              onClick={handleMaxAmount}>Add max</button>
          </div>
        ))
      }
    </li>
  )
}

export type Token = {
  mint: string,
  quantity: number,
  decimals: number,
  programId: string,
  token_standard?: string;
}

type CoinState = Record<string, { selectedRecipients: string[], tokenAmounts: Record<string, string> }>

const ProposalWizardSendTokens = ({
  currentStep,
  totalSteps,
  onComplete,
  goBack
}: WizardStepProps<{}>) => {

  const user = useAuth()
  const setFungibleTokensInput = useSetRecoilState(proposalWizardSendAssetsTokens)
  const setNonFungibleTokensInput = useSetRecoilState(proposalWizardSendAssetsNFTs)
  const recipientInputs = useRecoilValue(proposalWizardSendAssetsRecipients)
  const [currentOrg] = useCurrentOrganisation()
  const { organisation } = useOrganisation(currentOrg)
  const { connection } = useConnection()

  const [tokens, setTokens] = useState<Token[]>([]);
  const [nfts, setNfts] = useState<any[]>();

  const [prices, setprices] = useState<Record<string, { value: number }>>({});

  const [coinComponentStates, setCoinComponentStates] = useState<CoinState>({});

  const handleCoinComponentChange = (mint: string, selectedRecipients: string[], tokenAmounts: Record<string, string>) => {
    setCoinComponentStates(prevStates => ({
      ...prevStates,
      [mint]: { selectedRecipients, tokenAmounts },
    }));
  };

  useEffect(function removeNonExistentRecipients() {
    setCoinComponentStates(prevStates => {
      const newStates = { ...prevStates };
      for (const mint in newStates) {
        const selectedRecipients = newStates[mint].selectedRecipients.filter(recipientId => recipientInputs?.find(r => r.id.toString() === recipientId.toString()));
        newStates[mint].selectedRecipients = selectedRecipients;

        const tokenAmounts: Record<string, string> = {};
        for (const recipientId of selectedRecipients) {
          tokenAmounts[recipientId] = newStates[mint].tokenAmounts[recipientId];
        }
        newStates[mint].tokenAmounts = tokenAmounts;
      }
      return newStates;
    });
  }, [recipientInputs]);

  const areAmountsValid = (): boolean => {
    for (const mint in coinComponentStates) {
      const totalAmountForMint = Object.values(coinComponentStates[mint].tokenAmounts).reduce((sum, tokenAmount) => sum + parseFloat(tokenAmount), 0);

      const tokenBalance = tokens.find(token => token.mint === mint)?.quantity || 0;
      console.log(totalAmountForMint, tokenBalance)
      if (totalAmountForMint > tokenBalance) {
        return false
      }
    }
    return true;
  };

  const handleSubmit = (recipients: CoinState) => {
    //Validations neeeds to be done heere
    if (!organisation?.wallets[0]?.walletAddress) {
      return
    }
    if (!areAmountsValid()) {
      toast.error("Balance exceeds what is available in the DAO treasury. Try reduce the amount of tokens.")
      return
    }

    const coinStateToTokenAmounts = (coinState: CoinState): Record<string, { amount: string; decimals: number; recipient: string | number; programId: string; token_standard?: string; }[]> => {
      const tokenAmounts: Record<string, { amount: string; decimals: number; recipient: string | number; programId: string; token_standard?: string; }[]> = {};

      for (const mint in coinState) {
        coinState[mint].selectedRecipients.forEach(recipientId => {
          const amount = coinState[mint].tokenAmounts[recipientId];
          const token = tokens.find(token => token.mint === mint);
          const token_standard = token?.token_standard;
          const programId = token?.programId || TOKEN_PROGRAM_ID.toBase58();
          const decimals = token?.decimals || 0;
          const recipient = recipientInputs?.find(r => r.id.toString() === recipientId)?.walletAddress || recipientId;

          if (!tokenAmounts[mint]) {
            tokenAmounts[mint] = [];
          }
          tokenAmounts[mint].push({ amount, decimals, recipient, programId, token_standard });
        });
      }

      return tokenAmounts;
    }

    const tokenAmounts = coinStateToTokenAmounts(recipients);

    setFungibleTokensInput(tokenAmounts)
    onComplete({})
  }

  const getNFTsFromTokens = async (tokens: Token[], walletAddress: string) => {
    const nftMints = tokens.filter((token) => token.decimals === 0)
    const nftDatas = await fetchNfts(nftMints.map(x => x.mint))
    const response = await fetch(process.env.REACT_APP_SOL_RPC ?? '', {
                                  method: 'POST',
                                  headers: {
                                    "Content-Type": "application/json"
                                  },
                                  body: JSON.stringify({
                                    "jsonrpc": "2.0",
                                    "id": "text",
                                    "method": "getAssetsByOwner",
                                    "params": {
                                      "ownerAddress": walletAddress,
                                      "page": 1,
                                      "limit": 1000,
                                    }
                                  }),
                              });
    const assetsByOwner = await response.json();
    console.log('nfts', assetsByOwner.result.items);
    console.log('nfts Raw', nftDatas);
    const compressedAssets = assetsByOwner.result.items
      .filter((asset: HeliusGetAssetResponse) => asset.compression?.compressed)
      .map((asset: HeliusGetAssetResponse) => ({
        metadata: asset.content?.metadata,
        mint: asset.id,
        image: asset.content?.links?.image,
        compression: asset.compression,
        ownership: asset.ownership,
        token_standard: asset.content.metadata.token_standard
      }));
    const pfps = nftDatas.map(x => ({ metadata: x.content?.metadata, mint: x.id, image: x.content.links.image, compression: x.compression, ownership: x.ownership, token_standard: x.content.metadata.token_standard }))

    return [...pfps.filter(p => p !== undefined), ...compressedAssets]
  }

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const walletAddress = organisation?.wallets.find(x => x.name === "Treasury")?.walletAddress
        if (!walletAddress) {
          return
        }
        const tokenAccounts = await getTokenAccountsByAddress(walletAddress, connection)
        const token22Accounts = await getTokenAccountsByAddress(walletAddress, connection, TOKEN_2022_PROGRAM_ID)
        const nonScamNfts = [...token22Accounts.value, ...tokenAccounts.value].filter(acc => !SCAM_MINTS.includes(acc.account.data.parsed.info.mint))
        console.log(nonScamNfts)
        const tokens: {
          mint: string,
          quantity: number,
          decimals: number,
          programId: string
        }[] = nonScamNfts.map(token => ({
          mint: token.account.data.parsed.info.mint,
          quantity: token.account.data.parsed.info.tokenAmount.uiAmount,
          decimals: token.account.data.parsed.info.tokenAmount.decimals,
          programId: token.account.data.program === "spl-token-2022" ? TOKEN_2022_PROGRAM_ID.toBase58() : TOKEN_PROGRAM_ID.toBase58()
        })).filter(token => token.quantity > 0);
        console.log("tokens", tokens);
        const solBalance = await connection.getBalance(new PublicKey(walletAddress))
        tokens.sort((a, b) => b.mint === USDC_MINT_ADDRESS.toBase58() || b.mint === "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263" ? 1 : -1)
        tokens.unshift({
          mint: "11111111111111111111111111111111",
          quantity: Math.floor(solBalance / 10000000) / 100,
          decimals: 9,
          programId: TOKEN_PROGRAM_ID.toBase58()
        })

        const nfts = await getNFTsFromTokens(tokens, walletAddress)
        setNfts(nfts)

        console.log(tokens)
        setTokens(tokens)

        const prices = await fetchMultiCoinPrice([...tokens.map(x => x.mint), "So11111111111111111111111111111111111111112"])
        setprices(prices || {})
        console.log(prices)


      }
      catch (e) {
        console.warn(e)
      }
    }
    fetchTokens()
  }, [organisation?.wallets, currentStep, connection])

  const handleDeleteNFT = useCallback((mint: string) => {
    setNonFungibleTokensInput(prevState => {
      const newState = { ...prevState };
      delete newState[mint];
      return newState;
    });
  }, [setNonFungibleTokensInput]);

  const handleAddNFT = useCallback((nft: NFT, selectedRecipient: Recipient) => {
    setNonFungibleTokensInput(prevState => ({
      ...prevState,
      [nft.mint]: { nft, recipient: selectedRecipient }
    }));
  }, [setNonFungibleTokensInput]);

  const handleNFTToSelectedRecipient = useCallback((nft: NFT, selectedRecipient?: Recipient) => {
    if (!selectedRecipient) {
      return handleDeleteNFT(nft.mint);
    }

    return handleAddNFT(nft, selectedRecipient);
  }, [setNonFungibleTokensInput, handleDeleteNFT, handleAddNFT]);

  if (currentStep !== 2) {
    return <></>
  }

  return (
    <div className="proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full md:w-full p-6 lg:col-span-2 sm:p-6 bg-secondary border-boxWidth flex flex-col">
      <div className="flex justify-between">
        <div className="font-heading text-lg md:text-3xl mb-2">Create Send Proposal </div>
        <p className="text-lg font-semibold text-primary">STEP {currentStep + 1}/{totalSteps}</p>
      </div>
      <h3 className="text-sm font-normal mb-6">Input or select recipients to send to</h3>
      <Tab.Group>
        <div className="flex flex-col justify-center gap-2">
          <Tab.List className="rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full gap-2 p-2 bg-secondary border-boxWidth flex justify-evenly ">
            <Tab className='w-full rounded-md ui-selected:bg-primary ui-selected:text-black p-2'>Fungible tokens</Tab>
            <Tab className='w-full rounded-md ui-selected:bg-primary ui-selected:text-black p-2'>NFTs</Tab>
          </Tab.List>

          <Tab.Panels className="w-full mb-3 h-96 p-4 proposals-container box-container bg-opacity-30 rounded-lg border-2 border-contrast border-opacity-30 flex-col justify-start  items-start gap-2.5 inline-flex">
            <Tab.Panel className="w-full h-96">
              <h2 className="text-sm">SELECT FUNDIBLE TOKENS TO SEND</h2>
              <ol className="flex h-[21rem] flex-col gap-3 w-full overflow-auto scroll">
                {tokens?.filter(x => x.decimals !== 0).map((tok, i) => (
                  <CoinComponent
                    key={tok.mint}
                    amount={tok.quantity}
                    recipients={recipientInputs ?? []}
                    mint={tok.mint}
                    price={tok.mint !== "11111111111111111111111111111111" ? prices[tok.mint]?.value : prices["So11111111111111111111111111111111111111112"]?.value}
                    selectedRecipients={coinComponentStates[tok.mint]?.selectedRecipients || []}
                    tokenAmounts={coinComponentStates[tok.mint]?.tokenAmounts || {}}
                    onRecipientChange={(selectedRecipients, tokenAmounts) => handleCoinComponentChange(tok.mint, selectedRecipients, tokenAmounts)}
                  />
                ))}
              </ol>
            </Tab.Panel>
            <Tab.Panel className="w-full h-96">
              <h2 className="text-sm">SELECT NFTs TO SEND</h2>
              <ol className="flex h-[21rem] flex-col gap-3 w-full overflow-auto scroll">
                {nfts?.map((nft, i) => (
                  <NFTItem
                    key={nft?.id}
                    nft={nft}
                    recipients={recipientInputs ?? []}
                    onSelectedRecipient={(selectedRecipient) => handleNFTToSelectedRecipient(nft, selectedRecipient)}
                  />
                ))}
              </ol>
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>

      <button
        className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary  
                transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"

        onClick={() => handleSubmit(coinComponentStates)}
      >
        <ForwardIcon className={`w-4 `} />
        Next Step
      </button>
      <button
        className="w-full flex justify-center gap-2 items-center mt-4 self-end border rounded-button border-primary 
              transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
        onClick={() => goBack()}
      >
        <ForwardIcon className={`w-4 transform rotate-180`} />
        Go back
      </button>
    </div>

  );
}

export default ProposalWizardSendTokens;