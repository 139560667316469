import { AccountMeta, PublicKey } from "@solana/web3.js";
import { Account, Identity, ReputationManager, User } from "align-sdk";
import BN from "bn.js";

export interface UserInfo {
  profile: Account<User>;
  identity: Account<Identity>;
  reputation: Account<ReputationManager>;
  linkedWallets: string[];
}

export type RawInstructionData = Buffer;
export type RawInstructionKeys = AccountMeta[];
export type InstructionArg = Record<string, number | Uint8Array | BN | string>;
export type InstructionKeys = Record<
  string | number,
  AccountMeta | AccountMeta[]
>;

export enum ParseTransactionTypes {
  TokenTransfer,
  JupiterSwap,
  NativeTransfer,
  CreateProfile,
  SetProfile,
  CreateWalletTracker,
  TrackPfp,
  AddCouncilMember,
  RemoveCouncilMember,
  SetWalletThreshold,
  CreateAtaIdempotent,
  BonkRewardsDeposit,
  BonkRewardsClaim,
  Unknown,
}

export interface ParsedProposalInstruction {
  [x: string]: any;
  type: ParseTransactionTypes;
  data: InstructionArg | RawInstructionData;
  keys: InstructionKeys | RawInstructionKeys;
  programId: PublicKey;
}

export type ContributionRecordDto = {
  __typename?: "ContributionRecordDto";
  address: string;
  isClaimed: boolean;
  upVoteCount: any;
  downVoteCount: any;
};
export type CouncilVoteRecordDto = {
  __typename?: "CouncilVoteRecordDto" | undefined;
  vote: string;
  address: string;
  review_score?: number | null | undefined;
  id: string;
  user: {
    __typename?: "ProfileDto" | undefined;
    username: string;
    displayName: string;
    userIdentityAddress: string;
    pfp?: string | null | undefined;
  };
};

export type CouncilVote = "Yes" | "No" | "Abstain" | "None";

export type ProfileDto = {
  __typename?: "ProfileDto";
  pfp?: string | null;
  userIdentityAddress: string;
  username: string;
  displayName: string;
};

export type WalletConfigDto = {
  __typename?: "WalletDto" | undefined;
  walletAddress: string;
  address: string;
  votingProposalCount: number;
  name: string;
  threshold: number;
  totalProposals: any;
};

export type Comment = {
  __typename?: "CommentDto";
  id: string;
  comment: string;
  upvotes: number;
  votesTotal: number;
  downvotes: number;
  created_at: string;
  proposalAddress: string;
  user: {
    __typename?: "UserDto";
    userIdentityAddress?: string | null;
    pfp?: string | null;
    username: string;
    displayName: string;
  };
  parent?: {
    __typename?: "CommentDto";
    id: string;
  } | null;
  children: {
    __typename?: "CommentDto";
    id: string;
    comment: string;
    upvotes: number;
    votesTotal: number;
    downvotes: number;
    created_at: string;
    proposalAddress: string;
    user: {
      __typename?: "UserDto";
      userIdentityAddress?: string | null;
      pfp?: string | null;
      username: string;
      displayName: string;
    };
    parent?: {
      __typename?: "CommentDto";
      id: string;
    } | null;
    childrenCount: number;
    children: {
      __typename?: "CommentDto";
      id: string;
      comment: string;
      upvotes: number;
      votesTotal: number;
      downvotes: number;
      created_at: string;
      proposalAddress: string;
      user: {
        __typename?: "UserDto";
        userIdentityAddress?: string | null;
        pfp?: string | null;
        username: string;
        displayName: string;
      };
      parent?: {
        __typename?: "CommentDto";
        id: string;
      } | null;
      childrenCount: number;
    }[];
  }[];
};

export type GroupKey = "collection";

export interface HeliusGetAssetResponse {
  interface: string;
  id: string;
  content: Content;
  authorities?: Authorities;
  compression?: Compression;
  grouping: Grouping[];
  royalty?: Royalty;
  creators?: Creators;
  ownership?: Ownership;
}

export interface Content {
  $schema: string;
  json_uri: string;
  files: any[];
  metadata: Metadata;
  links: Links;
}

export interface Metadata {
  attributes: any[];
  description: string;
  name: string;
  symbol: string;
  token_standard: string;
}

export interface Links {
  external_url: string;
  image: string;
}

export interface Authorities {
  address: string;
  scopes: string[];
}

export interface Compression {
  eligible: boolean;
  compressed: boolean;
  data_hash: string;
  creator_hash: string;
  asset_hash: string;
  tree: string;
  leaf_id?: number;
}

export interface Grouping {
  group_key: GroupKey;
  group_value: string;
  collection_metadata?: Metadata;
}

export interface Royalty {
  royalty_model: string;
  target: string;
  percent: number;
  primary_sale_happened: boolean;
  locked: boolean;
}

export interface Creators {
  address: string;
  verified: boolean;
}

export interface Ownership {
  frozen: boolean;
  delegated: boolean;
  delegate: string;
  ownership_model: string;
  owner: string;
  supply: string;
  mutable: boolean;
  burnt: boolean;
}
