import { AnchorProvider, Program, Wallet, web3 } from "@project-serum/anchor";
import {
  AccountMeta,
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  SYSVAR_INSTRUCTIONS_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from "@solana/web3.js";
import { Api } from "./api";
import {
  ALIGN_PROGRAM_ID,
  BEACON_PROGRAM_ID,
  CLOCKWORK_PROGRAM_ID,
  IDENTIFIERS_PROGRAM_ID,
  LEAF_PROGRAM_ID,
  MULTIGRAPH_PROGRAM_ID,
  PROFILES_PROGRAM_ID,
  TOKEN_AUTH_RULES_ID,
} from "./constants";
import { IDL as AlignIDL } from "./idls/align_governance";
import { IDL as BeaconIDL } from "./idls/beacon";
import { IDL as IdentifiersIDL } from "./idls/identifiers";
import { IDL as LeafIDL } from "./idls/leaf";
import { IDL as MultigraphIDL } from "./idls/multigraph";
import { IDL as ProfilesIDL } from "./idls/profiles";
import { Derivation } from "./pda";
import {
  AlignPrograms,
  AnchorCouncilVote,
  AnchorRankVoteType,
  AuthorityConfigType,
  ConnectionType,
  ContributionRecord,
  CouncilVote,
  EdgeRelation,
  OrganisationConfig,
  ProposalData,
  RankVoteType,
} from "./types";

import { SequenceType } from "@blockworks-foundation/mangolana/lib/globalTypes";
import {
  createTransferInstruction as createTransferPnftInstruction,
  TokenStandard,
} from "@metaplex-foundation/mpl-token-metadata";
import {
  CreateStorageResponse,
  ShadowUploadResponse,
  ShdwDrive,
} from "@shadow-drive/sdk";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  createTransferCheckedInstruction,
  getAccount,
  getMint,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import BN from "bn.js";
import {
  createIdentifierIx,
  createPfpWalletTracker,
  createUserProfileIx,
  editDisplayName,
  removePfp,
  setPfpIx,
  trackPfp,
} from "./identifiers";
import {
  sendSignAndConfirmTransactionsProps,
  TransactionInstructionWithSigners,
  TransactionInstructionWithType,
} from "./mangolana";
import {
  createPriorityFeeIx,
  createShadowAccount,
  findTokenRecordId,
  getAssociatedTokenAddressSync,
  getMasterEditionAddress,
  getMetadataAccount,
  getMetadataAddress,
  SEND_TX_STATUS,
  signSendAndConfirmTransactionsWithFees,
  swapSolToShadowIfBelowMin,
  unique,
  updateProposalMeta,
  uploadProposalMetadata,
} from "./utils";

export { Api } from "./api";
export * from "./constants";
export * from "./filters";
export * from "./identifiers";
export { Derivation } from "./pda";
export * from "./types";
export * from "./utils";

export { SequenceType } from "@blockworks-foundation/mangolana/lib/globalTypes";
export { AlignGovernance } from "./idls/align_governance";
export { Identifiers } from "./idls/identifiers";
export { Leaf } from "./idls/leaf";
export { Multigraph } from "./idls/multigraph";
export { Profiles } from "./idls/profiles";
export { Warp, IDL as WarpIDL } from "./idls/warp";
export { TransactionInstructionWithSigners } from "./mangolana";

export const createAlignPrograms = async (
  connection: Connection,
  wallet: Wallet,
  shadowConnection: Connection,
): Promise<AlignPrograms> => {
  const provider = new AnchorProvider(connection, wallet, {
    commitment: "confirmed",
  });
  const mainnetProvider = new AnchorProvider(shadowConnection, wallet, {
    commitment: "confirmed",
  });
  const alignGovernanceProgram = new Program(
    AlignIDL,
    ALIGN_PROGRAM_ID,
    provider,
  );
  const identifiersProgram = new Program(
    IdentifiersIDL,
    IDENTIFIERS_PROGRAM_ID,
    provider,
  );
  const multigraphProgram = new Program(
    MultigraphIDL,
    MULTIGRAPH_PROGRAM_ID,
    provider,
  );
  const profilesProgram = new Program(
    ProfilesIDL,
    PROFILES_PROGRAM_ID,
    provider,
  );

  const beaconProgram = new Program(BeaconIDL, BEACON_PROGRAM_ID, provider);
  const leafProgram = new Program(LeafIDL, LEAF_PROGRAM_ID, provider);

  return {
    alignGovernanceProgram,
    identifiersProgram,
    multigraphProgram,
    profilesProgram,
    leafProgram,
    beaconProgram,
    provider,
    shadowDriveInstance: await new ShdwDrive(shadowConnection, wallet).init(),
    mainnetProvider,
    wallet,
  };
};

export const createCollectionNftRecordInstruction = async (
  collectionMint: PublicKey,
  organisation: PublicKey,
  userIdentifier: PublicKey,
  programs: AlignPrograms,
) => {
  return await programs.alignGovernanceProgram.methods
    .createCollectionNftRecord(collectionMint)
    .accountsStrict({
      payer: programs.profilesProgram.provider.publicKey,
      organisation: organisation,
      identity: Derivation.deriveIdentityAddress(userIdentifier),
      ownerRecord: Derivation.deriveOwnerRecordAddress(
        programs.profilesProgram.provider.publicKey,
      ),
      systemProgram: SystemProgram.programId,
      owner: programs.profilesProgram.provider.publicKey,
      collectionRecord: Derivation.deriveCollectionNftRecordAddress(
        userIdentifier,
        organisation,
        collectionMint,
      ),
    })
    .instruction();
};

export const castRankVote = async (
  userIdentifier: PublicKey,
  proposalAddress: PublicKey,
  voteType: RankVoteType,
  amountOfPoints: number,
  programs: AlignPrograms,
  callbacks: {
    onTransactionConfirmation?: (res: any) => void;
    onTransactionFailure?: (res: any) => void;
    onError?: (
      e: any,
      notProcessedTransactions: TransactionInstructionWithType[],
      originalProps: sendSignAndConfirmTransactionsProps,
    ) => void;
  } = {
    onTransactionConfirmation: (res) => {},
    onTransactionFailure: (res) => {},
    onError: (
      e: any,
      notProcessedTransactions: TransactionInstructionWithType[],
      originalProps: sendSignAndConfirmTransactionsProps,
    ) => {},
  },
) => {
  const contributionRecord = Derivation.deriveContributionRecord(
    userIdentifier,
    proposalAddress,
  );
  const contributionRecordAccount = await Api.fetchContributionRecord(
    contributionRecord,
    programs,
  );
  const proposal = await Api.fetchProposal(proposalAddress, programs);
  const organisation = await Api.fetchOrganisation(
    proposal.account.organisation,
    programs,
  );
  const collectionNftRecordAddresses =
    Derivation.deriveAllCollectionNFTRecordAddress(
      userIdentifier,
      organisation.address,
      organisation.account.config.collectionItems.map((x) => x.mint),
    );
  // const collectionAccounts = await Api.fetchMulitpleCollectionNftRecord(collectionNftRecordAddresses, programs)

  // collectionAccounts.filter(acc => acc.account === null)

  if (proposal.account.state?.ranking === undefined) {
    throw "Proposal is not in ranking state cannot vote at this time.";
  }
  const ownerRecordAddress = Derivation.deriveOwnerRecordAddress(
    programs.alignGovernanceProgram.provider.publicKey,
  );
  const identityAddress = Derivation.deriveIdentityAddress(userIdentifier);
  const reputationManagerAddress = Derivation.deriveReputationManagerAddress(
    proposal.account.organisation,
    identityAddress,
  );

  const anchorRankVoteType: AnchorRankVoteType =
    voteType === RankVoteType.Upvote ? { upvote: {} } : { downvote: {} };
  const roundedPoints = Math.floor(amountOfPoints);
  if (roundedPoints <= 0) throw "Points must be above zero";

  const tx = await programs.alignGovernanceProgram.methods
    .castRank(anchorRankVoteType, new BN(amountOfPoints))
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      organisation: proposal.account.organisation,
      identity: identityAddress,
      ownerRecord: ownerRecordAddress,
      systemProgram: SystemProgram.programId,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      reputationManager: reputationManagerAddress,
      proposal: proposal.address,
      contributionRecord: contributionRecord,
      walletAuthorityConfig: proposal.account.authorityConfigAddress,
    })
    .remainingAccounts(
      collectionNftRecordAddresses.map((address) => ({
        pubkey: address,
        isSigner: false,
        isWritable: false,
      })),
    )
    .preInstructions(
      contributionRecordAccount === null
        ? [
            await createContributionRecordIx(
              userIdentifier,
              proposal.account.organisation,
              proposal.address,
              programs,
            ),
          ]
        : [],
    )
    .transaction();
  console.log(tx.instructions);
  let castRankVoteInstructions: any[] = [
    {
      instructionsSet: tx.instructions
        .filter((ix) => ix !== undefined)
        .map((ix) => new TransactionInstructionWithSigners(ix)),
      sequenceType: SequenceType.Sequential,
    },
  ];

  let res = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: castRankVoteInstructions,
    confirmLevel: "confirmed",
    extra: callbacks,
  });

  return res;
};

export const createContributionRecordIx = async (
  identifier: PublicKey,
  organisation: PublicKey,
  proposal: PublicKey,
  programs: AlignPrograms,
) => {
  const contributionRecord = Derivation.deriveContributionRecord(
    identifier,
    proposal,
  );
  return await programs.alignGovernanceProgram.methods
    .createContributionRecord()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      identity: Derivation.deriveIdentityAddress(identifier),
      ownerRecord: Derivation.deriveOwnerRecordAddress(
        programs.alignGovernanceProgram.provider.publicKey,
      ),
      systemProgram: SystemProgram.programId,
      organisation,
      proposal,
      contributionRecord: contributionRecord,
    })
    .instruction();
};

export const castCouncilVote = async (
  userIdentifier: PublicKey,
  proposalAddress: PublicKey,
  councilVoteType: CouncilVote,
  programs: AlignPrograms,
) => {
  let voteType: AnchorCouncilVote = { yes: {} };

  if (councilVoteType === CouncilVote.Abstain) {
    voteType = { abstain: {} };
  } else if (councilVoteType === CouncilVote.No) {
    voteType = { no: {} };
  }
  const proposal = await Api.fetchProposal(proposalAddress, programs);
  const rankingDuration = proposal.account.rankingPeroid.toNumber() * 1000;

  const timeInRanking =
    new Date().getTime() -
    new Date(proposal.account.rankingAt.toNumber() * 1000).getTime();

  if (!proposal.account.state?.voting && timeInRanking < rankingDuration) {
    throw "Proposal is still currently in the ranking state. You cannot vote on this proposal untill it has finished ranking.";
  }

  let mustPushState = false;

  if (
    proposal.account.state?.ranking !== undefined &&
    timeInRanking >= rankingDuration
  ) {
    mustPushState = true;
  }

  const ownerRecordAddress = Derivation.deriveOwnerRecordAddress(
    programs.alignGovernanceProgram.provider.publicKey,
  );
  const identityAddress = Derivation.deriveIdentityAddress(userIdentifier);
  const councilManagerAddress = Derivation.deriveCouncilManagerAddress(
    proposal.account.organisation,
  );
  const councilVoteRecordAddress = Derivation.deriveCouncilVoteRecord(
    userIdentifier,
    proposalAddress,
  );

  const councilVoteRecord = await Api.fetchCouncilVoteRecord(
    councilVoteRecordAddress,
    programs,
  );

  const preInstructions = mustPushState
    ? [
        await programs.alignGovernanceProgram.methods
          .pushProposalState()
          .accountsStrict({
            payer: programs.profilesProgram.provider.publicKey,
            organisation: proposal.account.organisation,
            proposal: proposalAddress,
            systemProgram: SystemProgram.programId,
            walletAuthorityConfig: proposal.account.authorityConfigAddress,
          })
          .instruction(),

        await programs.alignGovernanceProgram.methods
          .createCouncilVoteRecord()
          .accountsStrict({
            payer: programs.alignGovernanceProgram.provider.publicKey,
            owner: programs.alignGovernanceProgram.provider.publicKey,
            organisation: proposal.account.organisation,
            identity: identityAddress,
            ownerRecord: ownerRecordAddress,
            systemProgram: SystemProgram.programId,
            councilManager: councilManagerAddress,
            councilVoteRecord: councilVoteRecordAddress,
            proposal: proposalAddress,
            walletAuthorityConfig: proposal.account.authorityConfigAddress,
            walletAuthority: Derivation.deriveWalletAddress(
              proposal.account.authorityConfigAddress,
            ),
          })
          .instruction(),
      ]
    : [
        councilVoteRecord === null
          ? await programs.alignGovernanceProgram.methods
              .createCouncilVoteRecord()
              .accountsStrict({
                payer: programs.alignGovernanceProgram.provider.publicKey,
                owner: programs.alignGovernanceProgram.provider.publicKey,
                organisation: proposal.account.organisation,
                identity: identityAddress,
                ownerRecord: ownerRecordAddress,
                systemProgram: SystemProgram.programId,
                councilManager: councilManagerAddress,
                councilVoteRecord: councilVoteRecordAddress,
                proposal: proposalAddress,
                walletAuthorityConfig: proposal.account.authorityConfigAddress,
                walletAuthority: Derivation.deriveWalletAddress(
                  proposal.account.authorityConfigAddress,
                ),
              })
              .instruction()
          : null,
      ];

  const tx = await programs.alignGovernanceProgram.methods
    .castCouncilVote(voteType)
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: proposal.account.organisation,
      identity: identityAddress,
      ownerRecord: ownerRecordAddress,
      systemProgram: SystemProgram.programId,
      councilManager: councilManagerAddress,
      councilVoteRecord: councilVoteRecordAddress,
      proposal: proposalAddress,
      walletAuthorityConfig: proposal.account.authorityConfigAddress,
      walletAuthority: Derivation.deriveWalletAddress(
        proposal.account.authorityConfigAddress,
      ),
    })
    .preInstructions(preInstructions.filter((ix) => ix !== null))
    .transaction();
  let castCouncilVoteInstructions: TransactionInstructionWithType[] = [
    {
      instructionsSet: tx.instructions.map(
        (ix) => new TransactionInstructionWithSigners(ix),
      ),
      sequenceType: SequenceType.Sequential,
    },
  ];

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: castCouncilVoteInstructions,
    confirmLevel: "confirmed",
    extra: [],
  });

  return sigs[0];
};

// export const canPushRankingState = (
//     rankingPeriod : BN,
//     rankingAt : BN | null,
//     state : AnchorProposalState
// ) : boolean =>  {
//     if (rankingAt === null) return false
//     const rankingDuration = rankingPeriod.toNumber() * 1000
//     const timeInRanking = new Date().getTime() - new Date(rankingAt.toNumber() * 1000).getTime()
//     return (timeInRanking >= rankingDuration) && state?.ranking !== undefined
// }

export const canPushRankingState = (
  rankingPeriod: number,
  rankingAt: string | null,
  state: string,
): boolean => {
  if (rankingAt === null) return false;
  const rankingDuration = rankingPeriod * 1000;
  const timeInRanking = new Date().getTime() - new Date(rankingAt).getTime();
  return timeInRanking >= rankingDuration && state === "Ranking";
};

export const reviewProposal = async (
  identifier: web3.PublicKey,
  proposalAddress: web3.PublicKey,
  score: number,
  programs: AlignPrograms,
) => {
  const proposal = await Api.fetchProposal(proposalAddress, programs);
  const councilVoteRecord = Derivation.deriveCouncilVoteRecord(
    identifier,
    proposalAddress,
  );
  const councilVoteRecordAccount = await Api.fetchCouncilVoteRecord(
    councilVoteRecord,
    programs,
  );

  const tx = await programs.alignGovernanceProgram.methods
    .reviewProposal(score)
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: proposal.account.organisation,
      walletAuthorityConfig: proposal.account.authorityConfigAddress,
      councilManager: Derivation.deriveCouncilManagerAddress(
        proposal.account.organisation,
      ),
      councilVoteRecord: Derivation.deriveCouncilVoteRecord(
        identifier,
        proposalAddress,
      ),
      proposal: proposalAddress,
      identity: Derivation.deriveIdentityAddress(identifier),
      ownerRecord: Derivation.deriveOwnerRecordAddress(
        programs.alignGovernanceProgram.provider.publicKey,
      ),
      systemProgram: SystemProgram.programId,
    })
    .preInstructions(
      councilVoteRecordAccount === null
        ? [
            await programs.alignGovernanceProgram.methods
              .createCouncilVoteRecord()
              .accountsStrict({
                payer: programs.alignGovernanceProgram.provider.publicKey,
                owner: programs.alignGovernanceProgram.provider.publicKey,
                organisation: proposal.account.organisation,
                identity: Derivation.deriveIdentityAddress(identifier),
                ownerRecord: Derivation.deriveOwnerRecordAddress(
                  programs.alignGovernanceProgram.provider.publicKey,
                ),
                systemProgram: SystemProgram.programId,
                councilManager: Derivation.deriveCouncilManagerAddress(
                  proposal.account.organisation,
                ),
                councilVoteRecord,
                proposal: proposalAddress,
                walletAuthorityConfig: proposal.account.authorityConfigAddress,
                walletAuthority: Derivation.deriveWalletAddress(
                  proposal.account.authorityConfigAddress,
                ),
              })
              .instruction(),
          ]
        : [],
    )
    .transaction();

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: [
      {
        instructionsSet: tx.instructions.map(
          (ix) => new TransactionInstructionWithSigners(ix),
        ),
        sequenceType: SequenceType.Sequential,
      },
    ],
    confirmLevel: "confirmed",
    extra: [],
  });

  if (status === SEND_TX_STATUS.SUCESSFUL) {
    return sigs;
  } else {
    return null;
  }
};

export const attachProof = async (
  identifier: web3.PublicKey,
  proposalAddress: web3.PublicKey,
  proof: string,
  programs: AlignPrograms,
) => {
  const proposal = await Api.fetchProposal(proposalAddress, programs);

  const addProofIx = await programs.alignGovernanceProgram.methods
    .addProof(proof)
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: proposal.account.organisation,
      proposal: proposalAddress,
      ownerRecord: Derivation.deriveOwnerRecordAddress(
        programs.alignGovernanceProgram.provider.publicKey,
      ),
      systemProgram: SystemProgram.programId,
      servicerIdentity: Derivation.deriveIdentityAddress(identifier),
    })
    .transaction();

  let formattedAddProofIx: any[] = addProofIx.instructions.map(
    (instruction) => {
      return {
        instructionsSet: [new TransactionInstructionWithSigners(instruction)],
        sequenceType: SequenceType.Sequential,
      };
    },
  );

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: formattedAddProofIx,
    confirmLevel: "confirmed",
    extra: [],
  });
  return sigs[0];
};

export const finishServicingProposal = async (
  identifier: web3.PublicKey,
  proposalAddress: web3.PublicKey,
  programs: AlignPrograms,
) => {
  const proposal = await Api.fetchProposal(proposalAddress, programs);

  let finishServicingProposalIx = await programs.alignGovernanceProgram.methods
    .finishServicingProposal()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: proposal.account.organisation,
      proposal: proposalAddress,
      ownerRecord: Derivation.deriveOwnerRecordAddress(
        programs.alignGovernanceProgram.provider.publicKey,
      ),
      systemProgram: SystemProgram.programId,
      servicerIdentity: Derivation.deriveIdentityAddress(identifier),
    })
    .transaction();

  let formattedFinishServicingProposalIx: any[] =
    finishServicingProposalIx.instructions.map((instruction) => {
      return {
        instructionsSet: [new TransactionInstructionWithSigners(instruction)],
        sequenceType: SequenceType.Sequential,
      };
    });

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: formattedFinishServicingProposalIx,
    confirmLevel: "confirmed",
    extra: [],
  });
  return sigs[0];
};

export const executeTransactionTx = async (
  proposalAddress: web3.PublicKey,
  proposalTransaction: web3.PublicKey,
  programs: AlignPrograms,
): Promise<web3.Transaction> => {
  const instructions = await Api.fetchProposalTransactionInstructions(
    proposalTransaction,
    programs,
  );
  const proposal = await Api.fetchProposal(proposalAddress, programs);

  if (
    proposal.account.state?.readyToExecute === undefined &&
    !proposal.account.state?.executing === undefined
  ) {
    throw "The proposal is not in the right state to execute its attached transactions.";
  }

  const remainingAccountsMetas: AccountMeta[] = instructions.flatMap((ix) =>
    ix.account.accounts.map((acc) => ({
      ...acc,
      isSigner: false,
    })),
  );

  const remainingAccountsProgramIds: AccountMeta[] = instructions.flatMap(
    (ix) => ({
      isSigner: false,
      isWritable: false,
      pubkey: ix.account.programId,
    }),
  );

  const reamingAccountsInstructions: AccountMeta[] = instructions.flatMap(
    (ix) => ({
      isSigner: false,
      isWritable: true,
      pubkey: ix.address,
    }),
  );

  const remainingAcountsUnique = unique(
    [
      ...reamingAccountsInstructions,
      ...remainingAccountsMetas,
      ...remainingAccountsProgramIds,
    ],
    (val: AccountMeta) => val.pubkey.toBase58(),
  );

  const executeTransaction = await programs.alignGovernanceProgram.methods
    .executeTransaction()
    .accountsStrict({
      walletAuthorityConfig: proposal.account.authorityConfigAddress,
      walletAuthority: Derivation.deriveWalletAddress(
        proposal.account.authorityConfigAddress,
      ),
      proposal: proposalAddress,
      transaction: proposalTransaction,
    })
    // .preInstructions(ataCreationInstructions)
    .remainingAccounts([...remainingAcountsUnique])
    .transaction();
  return executeTransaction;
};

/**
 *
 * @param proposalAddress
 * @param proposalTransaction
 * @param programs
 * @returns signature of transaction or null if transaction has already been processed
 */
export const executeTransaction = async (
  proposalAddress: web3.PublicKey,
  proposalTransaction: web3.PublicKey,
  programs: AlignPrograms,
): Promise<string> => {
  const transactionAccount = await Api.fetchProposalTransaction(
    proposalTransaction,
    programs,
  );
  if (transactionAccount.account.state?.success !== undefined) {
    return null;
  }
  const executeTransaction = await executeTransactionTx(
    proposalAddress,
    proposalTransaction,
    programs,
  );

  const executeTransactionInstructions: any[] =
    executeTransaction.instructions.map((instruction) => {
      return {
        instructionsSet: [new TransactionInstructionWithSigners(instruction)],
        sequenceType: SequenceType.Sequential,
      };
    });

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: executeTransactionInstructions,
    confirmLevel: "confirmed",
    extra: [],
  });

  return sigs[0];
};

export const executeAllTransactions = async (
  proposalAddress: web3.PublicKey,
  onTransactionComplete: (sig: string, id: number) => void,
  programs: AlignPrograms,
): Promise<string[]> => {
  const proposal = await Api.fetchProposal(proposalAddress, programs);

  const transactionAddresses = [
    ...Array(proposal.account.transactionCount),
  ].map((_, i) => Derivation.deriveTransaction(proposalAddress, i));
  const _executetransactions = async (): Promise<string[]> => {
    const transactionSignatures: string[] = [];
  
    for (let i = 0; i < transactionAddresses.length; i++) {
      const transactionAddress = transactionAddresses[i];
      const sig = await executeTransaction(
        proposalAddress,
        transactionAddress,
        programs,
      );
      onTransactionComplete(sig, i);
      console.log(
        "one transaction signature in executeAllTransactions: ",
        sig,
      );
      transactionSignatures.push(sig);
    }

    return transactionSignatures;
  };

  const sigs = await _executetransactions();

  return sigs;
};

export const finalizeDraftProposal = async (
  proposalAddress: PublicKey,
  userIdentifier: PublicKey,
  programs: AlignPrograms,
) => {
  const proposal = await Api.fetchProposal(proposalAddress, programs);
  const userIdentity = Derivation.deriveIdentityAddress(userIdentifier);
  const reputationManagerAddress = Derivation.deriveReputationManagerAddress(
    proposal.account.organisation,
    userIdentity,
  );
  const ownerRecordAddress = Derivation.deriveOwnerRecordAddress(
    programs.alignGovernanceProgram.provider.publicKey,
  );
  const proposerContributionRecord = Derivation.deriveContributionRecord(
    proposal.account.proposer,
    proposal.address,
  );
  const organisation = await Api.fetchOrganisation(
    proposal.account.organisation,
    programs,
  );

  const collectionNftRecordAddresses =
    Derivation.deriveAllCollectionNFTRecordAddress(
      userIdentifier,
      organisation.address,
      organisation.account.config.collectionItems.map((x) => x.mint),
    );

  let finalizeDraftProposalIx = await programs.alignGovernanceProgram.methods
    .finalizeDraftProposal()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: proposal.account.organisation,
      walletAuthorityConfig: proposal.account.authorityConfigAddress,
      reputationManager: reputationManagerAddress,
      proposal: proposalAddress,
      identity: userIdentity,
      ownerRecord: ownerRecordAddress,
      systemProgram: SystemProgram.programId,
      proposerContributionRecord,
      councilManager: Derivation.deriveCouncilManagerAddress(
        proposal.account.organisation,
      ),
    })
    .remainingAccounts(
      collectionNftRecordAddresses.map((address) => ({
        pubkey: address,
        isSigner: false,
        isWritable: false,
      })),
    )
    .transaction();
  let formattedFinalizeDraftProposalIx: any[] =
    finalizeDraftProposalIx.instructions.map((instruction) => {
      return {
        instructionsSet: [new TransactionInstructionWithSigners(instruction)],
        sequenceType: SequenceType.Sequential,
      };
    });

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: formattedFinalizeDraftProposalIx,
    confirmLevel: "confirmed",
    extra: [],
  });
  return sigs[0];
};

export const cancelDraftProposal = async (
  proposalAddress: PublicKey,
  userIdentifier: PublicKey,
  programs: AlignPrograms,
) => {
  const proposal = await Api.fetchProposal(proposalAddress, programs);
  const userIdentity = Derivation.deriveIdentityAddress(userIdentifier);
  const ownerRecordAddress = Derivation.deriveOwnerRecordAddress(
    programs.alignGovernanceProgram.provider.publicKey,
  );
  const proposerContributionRecord = Derivation.deriveContributionRecord(
    proposal.account.proposer,
    proposal.address,
  );
  let cancelDraftIx = await programs.alignGovernanceProgram.methods
    .cancelDraft()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: proposal.account.organisation,
      walletAuthorityConfig: proposal.account.authorityConfigAddress,
      proposal: proposalAddress,
      identity: userIdentity,
      ownerRecord: ownerRecordAddress,
      systemProgram: SystemProgram.programId,
      proposerContributionRecord,
    })
    .transaction();
  console.log("in cancel draft IX: ", cancelDraftIx.instructions);
  let formattedCancelDraftProposalIx: any[] = cancelDraftIx.instructions.map(
    (instruction) => {
      return {
        instructionsSet: [new TransactionInstructionWithSigners(instruction)],
        sequenceType: SequenceType.Sequential,
      };
    },
  );
  console.log(
    "formattedCancelDraftProposalIx: ",
    formattedCancelDraftProposalIx,
  );
  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: formattedCancelDraftProposalIx,
    confirmLevel: "confirmed",
    extra: [],
  });
  return sigs[0];
};
export const addTransactionIx = async (
  ownerAddress: web3.PublicKey,
  ownerIdentifier: web3.PublicKey,
  proposalAddress: web3.PublicKey,
  ownerRecord: web3.PublicKey,
  programs: AlignPrograms,
  transactionIndex: number = 0,
): Promise<{ address: PublicKey; instruction: TransactionInstruction }> => {
  const transactionAddress = Derivation.deriveTransaction(
    proposalAddress,
    transactionIndex,
  );
  const identity = Derivation.deriveIdentityAddress(ownerIdentifier);
  const ix = await programs.alignGovernanceProgram.methods
    .addTransaction()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: ownerAddress,
      proposal: proposalAddress,
      identity,
      ownerRecord,
      systemProgram: SystemProgram.programId,
      transaction: transactionAddress,
    })
    .instruction();

  return {
    address: transactionAddress,
    instruction: ix,
  };
};

export const addInstructionIx = async (
  instruction: TransactionInstruction,
  ownerAddress: web3.PublicKey,
  ownerIdentifier: web3.PublicKey,
  proposalAddress: web3.PublicKey,
  transactionAddress: web3.PublicKey,
  transactionIndex: number,
  instructionIndex: number,
  ownerRecord: web3.PublicKey,
  programs: AlignPrograms,
): Promise<TransactionInstruction> => {
  const instructionAddress = Derivation.deriveProposalInstructionAddress(
    transactionAddress,
    instructionIndex,
  );
  const identity = Derivation.deriveIdentityAddress(ownerIdentifier);
  console.log('instruction.keys', instruction.keys);
  const itx = await programs.alignGovernanceProgram.methods
    .addInstruction(
      instruction.programId,
      instruction.data,
      instruction.keys,
      transactionIndex,
    )
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: ownerAddress,
      proposal: proposalAddress,
      identity: identity,
      ownerRecord: ownerRecord,
      systemProgram: SystemProgram.programId,
      transaction: transactionAddress,
      instruction: instructionAddress,
    })
    .instruction();

  return itx
};
export const createProposalV2 = async (
  organisationAddress: PublicKey,
  userIdentifier: PublicKey,
  transactions: web3.Transaction[],
  walletConfigAddress: PublicKey,
  rankingPeriod: BN,
  proposalData: ProposalData,
  shadowCallbacks: {
    onCreatingDrive?: () => void;
    onCreateDrive?: (res: CreateStorageResponse) => void;
    onUpload?: (res: ShadowUploadResponse) => void;
    onUploadRetry?: () => void;
    onTransactionConfirmation?: (res: any) => void;
    onTransactionFailure?: (res: any) => void;
  } = {
    onCreatingDrive: () => {},
    onCreateDrive: (res: CreateStorageResponse) => {},
    onUpload: (res: ShadowUploadResponse) => {},
    onUploadRetry: () => {},
    onTransactionConfirmation: (res: any) => {},
    onTransactionFailure: (res: any) => {},
  },
  programs: AlignPrograms,
  servicerIdentifier?: PublicKey,
): Promise<{ sigs: string[]; proposalAddress: PublicKey }> => {
  const {
    createProposal,
    createTransactions,
    addInstructionsIx,
    contributionIx,
    proposalAddress,
  } = await createProposalTransactionWithCreateShadow(
    organisationAddress,
    userIdentifier,
    transactions,
    walletConfigAddress,
    rankingPeriod,
    proposalData,
    shadowCallbacks,
    programs,
    servicerIdentifier,
  );

  const formattedCreateTransactionInstructions: any[] = createTransactions.map(
    (instruction) => {
      return {
        instructionsSet: [{ transactionInstruction: instruction }],
        sequenceType: SequenceType.Sequential,
      };
    },
  );

  const formattedCreateInstructionInstructions: any[] = addInstructionsIx.map(
    (instruction) => {
      return {
        instructionsSet: [{ transactionInstruction: instruction }],
        sequenceType: SequenceType.Sequential,
      };
    },
  );

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: [
      {
        instructionsSet: [{ transactionInstruction: createProposal }],
        sequenceType: SequenceType.Sequential,
      },
      ...formattedCreateTransactionInstructions,
      ...formattedCreateInstructionInstructions,
      {
        instructionsSet: [{ transactionInstruction: contributionIx }],
        sequenceType: SequenceType.Sequential,
      },
    ],
    confirmLevel: "confirmed",
    extra: shadowCallbacks,
  });
  if (status === SEND_TX_STATUS.SUCESSFUL) {
    console.log("response has been returned: ", status);
    return {
      sigs: sigs,
      proposalAddress: proposalAddress,
    };
  } else {
    return {
      sigs: [],
      proposalAddress: null,
    };
  }
};
export const createProposalTransactionWithCreateShadow = async (
  organisationAddress: PublicKey,
  userIdentifier: PublicKey,
  transactions: web3.Transaction[],
  walletConfigAddress: PublicKey,
  rankingPeriod: BN,
  proposalData: ProposalData,
  shadowCallbacks: {
    onCreatingDrive?: () => void;
    onCreateDrive?: (res: CreateStorageResponse) => void;
    onUpload?: (res: ShadowUploadResponse) => void;
    onUploadRetry?: () => void;
  } = {
    onCreatingDrive: () => {},
    onCreateDrive: (res: CreateStorageResponse) => {},
    onUpload: (res: ShadowUploadResponse) => {},
    onUploadRetry: () => {},
  },
  programs: AlignPrograms,
  servicerIdentifier?: PublicKey,
): Promise<{
  createProposal: web3.TransactionInstruction;
  createTransactions: web3.TransactionInstruction[];
  addInstructionsIx: web3.TransactionInstruction[];
  contributionIx: web3.TransactionInstruction;
  proposalAddress: PublicKey;
}> => {
  const walletConfigAccount = await Api.fetchWalletConfig(
    walletConfigAddress,
    programs,
  );

  const proposalIndex = walletConfigAccount.account.totalProposals;
  const proposalAddress = Derivation.deriveProposalAddress(
    walletConfigAccount.address,
    proposalIndex,
  );
  const ownerRecordAddress = Derivation.deriveOwnerRecordAddress(
    programs.alignGovernanceProgram.provider.publicKey,
  );
  const identityAddress = Derivation.deriveIdentityAddress(userIdentifier);
  const councilManagerAddress =
    Derivation.deriveCouncilManagerAddress(organisationAddress);
  const reputationManagerAddress = Derivation.deriveReputationManagerAddress(
    organisationAddress,
    identityAddress,
  );
  const organisation = await Api.fetchOrganisation(
    organisationAddress,
    programs,
  );

  const collectionNftRecordAddresses =
    Derivation.deriveAllCollectionNFTRecordAddress(
      userIdentifier,
      organisationAddress,
      organisation.account.config.collectionItems.map((x) => x.mint),
    );

  const addTransactionInstructionsPromise = transactions.map(
    async (tx, i) =>
      await addTransactionIx(
        programs.alignGovernanceProgram.provider.publicKey,
        userIdentifier,
        proposalAddress,
        ownerRecordAddress,
        programs,
        i,
      ),
  );

  // can add retry somewhere amongst these
  const addTransactionInstructions = await Promise.all(
    addTransactionInstructionsPromise,
  );

  const addInstructionToTransactionIxPromise = transactions
    .map((tx, i) =>
      tx.instructions.map(
        async (ix, instructionIndex) =>
          await addInstructionIx(
            ix,
            programs.alignGovernanceProgram.provider.publicKey,
            userIdentifier,
            proposalAddress,
            addTransactionInstructions[i].address,
            i,
            instructionIndex,
            ownerRecordAddress,
            programs,
          ),
      ),
    )
    .flat();

  const addInstructionToTransactionIx = await Promise.all(
    addInstructionToTransactionIxPromise,
  );

  await swapSolToShadowIfBelowMin(
    programs.wallet,
    programs.mainnetProvider,
    0.0001,
  );
  shadowCallbacks.onCreatingDrive();
  const accountRes = await createShadowAccount(
    "ALIGN_PROPOSAL",
    proposalData,
    programs.shadowDriveInstance,
  );
  shadowCallbacks.onCreateDrive(accountRes);
  const shadowDrive = new web3.PublicKey(accountRes.shdw_bucket);
  // await sleep(600)
  const shadowRes: ShadowUploadResponse = await uploadProposalMetadata(
    proposalAddress.toBase58(),
    proposalData,
    shadowDrive,
    programs.shadowDriveInstance,
    shadowCallbacks.onUploadRetry,
  );
  shadowCallbacks.onUpload(shadowRes);

  const ix = await programs.alignGovernanceProgram.methods
    .createProposal(rankingPeriod, servicerIdentifier ? true : false)
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: organisationAddress,
      identity: identityAddress,
      ownerRecord: ownerRecordAddress,
      systemProgram: SystemProgram.programId,
      councilManager: councilManagerAddress,
      proposal: proposalAddress,
      reputationManager: reputationManagerAddress,
      shadowDrive,
      walletAuthorityConfig: walletConfigAddress,
    })
    .remainingAccounts(
      servicerIdentifier
        ? [
            {
              pubkey: Derivation.deriveIdentityAddress(servicerIdentifier),
              isSigner: false,
              isWritable: false,
            },
            ...collectionNftRecordAddresses.map((address) => ({
              pubkey: address,
              isSigner: false,
              isWritable: false,
            })),
          ]
        : [
            ...collectionNftRecordAddresses.map((address) => ({
              pubkey: address,
              isSigner: false,
              isWritable: false,
            })),
          ],
    )
    .instruction();

  return {
    createTransactions: addTransactionInstructions.map((tx) => tx.instruction),
    createProposal: ix,
    addInstructionsIx: addInstructionToTransactionIx,
    contributionIx: await createContributionRecordIx(
      userIdentifier,
      organisationAddress,
      proposalAddress,
      programs,
    ),
    proposalAddress,
  };
};

// TODO add some callbacks for feedback
export const editDraftProposal = async (
  proposalAddress: PublicKey,
  newProposalData: ProposalData,
  programs: AlignPrograms,
) => {
  const proposal = await Api.fetchProposal(proposalAddress, programs);
  return updateProposalMeta(
    proposal.account.shadowDrive,
    proposalAddress,
    newProposalData,
    programs.shadowDriveInstance,
  );
};

export const createProposal = async (
  userIdentifier: PublicKey,
  organisationAddress: PublicKey,
  servicerIdentifier: PublicKey,
  proposalData: ProposalData,
  ranking_period: BN,
  walletConfigAddress: PublicKey,
  payoutAmount: BN,
  programs: AlignPrograms,
  shadowCallbacks: {
    onCreatingDrive?: () => void;
    onCreateDrive?: (res: CreateStorageResponse) => void;
    onUpload?: (res: ShadowUploadResponse) => void;
    onUploadRetry?: () => void;
  } = {
    onCreatingDrive: () => {},
    onCreateDrive: (res: CreateStorageResponse) => {},
    onUpload: (res: ShadowUploadResponse) => {},
    onUploadRetry: () => {},
  },
  mint?: PublicKey,
  tokenProgramId: PublicKey = TOKEN_PROGRAM_ID,
): Promise<{ sigs: string[]; proposalAddress: PublicKey }> => {
  const walletConfigAccount = await Api.fetchWalletConfig(
    walletConfigAddress,
    programs,
  );
  let transferInstruction;
  let createTokenAccountInstruction: web3.TransactionInstruction | undefined;

  let walletBalance: BN;
  if (mint) {
    const metadata = await getMetadataAccount(
      mint,
      programs.provider.connection,
      tokenProgramId,
    );
    const daoWallet = Derivation.deriveWalletAddress(
      walletConfigAccount.address,
    );
    const daoWalletAta = getAssociatedTokenAddressSync(
      mint,
      daoWallet,
      tokenProgramId,
    );
    const userAta = getAssociatedTokenAddressSync(
      mint,
      programs.alignGovernanceProgram.provider.publicKey,
      tokenProgramId,
    );
    const accountInfo = await getAccount(
      programs.alignGovernanceProgram.provider.connection,
      daoWalletAta,
      "confirmed",
      tokenProgramId,
    );
    const mintInfo = await getMint(
      programs.alignGovernanceProgram.provider.connection,
      accountInfo.mint,
      "confirmed",
      tokenProgramId,
    );

    walletBalance = new BN(accountInfo.amount.toString());
    if (
      metadata &&
      metadata.tokenStandard === TokenStandard.ProgrammableNonFungible
    ) {
      transferInstruction = createTransferPnftInstruction(
        {
          token: daoWalletAta,
          tokenOwner: daoWallet,
          destination: userAta,
          destinationOwner: programs.alignGovernanceProgram.provider.publicKey,
          mint,
          metadata: getMetadataAddress(mint),
          edition: getMasterEditionAddress(mint),
          ownerTokenRecord: findTokenRecordId(mint, daoWalletAta),
          destinationTokenRecord: findTokenRecordId(mint, userAta),
          authority: daoWallet,
          payer: daoWallet,
          systemProgram: SystemProgram.programId,
          sysvarInstructions: SYSVAR_INSTRUCTIONS_PUBKEY,
          splTokenProgram: TOKEN_PROGRAM_ID,
          splAtaProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          authorizationRules: metadata.programmableConfig.ruleSet,
          authorizationRulesProgram: TOKEN_AUTH_RULES_ID,
        },
        {
          transferArgs: {
            __kind: "V1",
            amount: 1,
            authorizationData: null,
          },
        },
      );
    } else {
      try {
        await getAccount(
          programs.alignGovernanceProgram.provider.connection,
          userAta,
          "confirmed",
          tokenProgramId,
        );
      } catch (e) {
        createTokenAccountInstruction = createAssociatedTokenAccountInstruction(
          walletConfigAccount.account.authorityAddress,
          userAta,
          programs.provider.publicKey,
          mint,
          tokenProgramId,
        );
      }
      transferInstruction = createTransferCheckedInstruction(
        daoWalletAta,
        mint,
        userAta,
        walletConfigAccount.account.authorityAddress,
        BigInt(payoutAmount.toString()),
        mintInfo.decimals,
        [],
        tokenProgramId,
      );
    }
  } else {
    const accountInfo =
      await programs.alignGovernanceProgram.provider.connection.getAccountInfo(
        walletConfigAccount.account.authorityAddress,
      );
    walletBalance = new BN(accountInfo.lamports.toString());
    transferInstruction = SystemProgram.transfer({
      toPubkey: programs.alignGovernanceProgram.provider.publicKey,
      fromPubkey: Derivation.deriveWalletAddress(walletConfigAccount.address),
      lamports: BigInt(payoutAmount.toString()),
    });
  }

  if (walletBalance.lt(payoutAmount)) {
    throw `Create Proposal failed. The DAO's wallet balance does not have enough funds to cover this proposal. Please reduce payout amount. Requested: ${payoutAmount.toString()} DAO Balance : ${walletBalance.toString()}`;
  }

  const proposalIndex = walletConfigAccount.account.totalProposals;
  const proposalAddress = Derivation.deriveProposalAddress(
    walletConfigAccount.address,
    proposalIndex,
  );
  const ownerRecordAddress = Derivation.deriveOwnerRecordAddress(
    programs.alignGovernanceProgram.provider.publicKey,
  );
  const identityAddress = Derivation.deriveIdentityAddress(userIdentifier);
  const councilManagerAddress =
    Derivation.deriveCouncilManagerAddress(organisationAddress);
  const reputationManagerAddress = Derivation.deriveReputationManagerAddress(
    organisationAddress,
    identityAddress,
  );

  const addTransactionInstruction = await addTransactionIx(
    programs.alignGovernanceProgram.provider.publicKey,
    userIdentifier,
    proposalAddress,
    ownerRecordAddress,
    programs,
  );
  let addCreateAtaToTransctionIx;

  if (createTokenAccountInstruction) {
    addCreateAtaToTransctionIx = await addInstructionIx(
      createTokenAccountInstruction,
      programs.alignGovernanceProgram.provider.publicKey,
      userIdentifier,
      proposalAddress,
      addTransactionInstruction.address,
      0,
      0,
      ownerRecordAddress,
      programs,
    );
  }

  const addInstructionToTransactionIx = await addInstructionIx(
    transferInstruction,
    programs.alignGovernanceProgram.provider.publicKey,
    userIdentifier,
    proposalAddress,
    addTransactionInstruction.address,
    0,
    createTokenAccountInstruction ? 1 : 0,
    // 0,
    ownerRecordAddress,
    programs,
  );
  await swapSolToShadowIfBelowMin(
    programs.wallet,
    programs.mainnetProvider,
    0.0001,
  );
  shadowCallbacks.onCreatingDrive();
  const accountRes = await createShadowAccount(
    "ALIGN_PROPOSAL",
    proposalData,
    programs.shadowDriveInstance,
  );
  shadowCallbacks.onCreateDrive(accountRes);
  const shadowDrive = new web3.PublicKey(accountRes.shdw_bucket);
  // await sleep(600)
  const shadowRes: ShadowUploadResponse = await uploadProposalMetadata(
    proposalAddress.toBase58(),
    proposalData,
    shadowDrive,
    programs.shadowDriveInstance,
    shadowCallbacks.onUploadRetry,
  );
  shadowCallbacks.onUpload(shadowRes);
  const organisation = await Api.fetchOrganisation(
    organisationAddress,
    programs,
  );

  const collectionNftRecordAddresses =
    Derivation.deriveAllCollectionNFTRecordAddress(
      userIdentifier,
      organisationAddress,
      organisation.account.config.collectionItems.map((x) => x.mint),
    );

  const postInstructions = createTokenAccountInstruction
    ? [
        addTransactionInstruction.instruction,
        addCreateAtaToTransctionIx,
        addInstructionToTransactionIx,
      ]
    : [addTransactionInstruction.instruction, addInstructionToTransactionIx];

  const tx = await programs.alignGovernanceProgram.methods
    .createProposal(ranking_period, servicerIdentifier ? true : false)
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: organisationAddress,
      identity: identityAddress,
      ownerRecord: ownerRecordAddress,
      systemProgram: SystemProgram.programId,
      councilManager: councilManagerAddress,
      proposal: proposalAddress,
      reputationManager: reputationManagerAddress,
      shadowDrive,
      walletAuthorityConfig: walletConfigAddress,
    })
    .remainingAccounts([
      {
        pubkey: Derivation.deriveIdentityAddress(servicerIdentifier),
        isSigner: false,
        isWritable: false,
      },
      ...collectionNftRecordAddresses.map((address) => ({
        pubkey: address,
        isSigner: false,
        isWritable: false,
      })),
    ])
    // .preInstructions([
    //     await createPriorityFeeIx([ALIGN_PROGRAM_ID.toBase58()]),
    // ])
    .postInstructions([
      await createContributionRecordIx(
        userIdentifier,
        organisationAddress,
        proposalAddress,
        programs,
      ),
    ])
    .transaction();

  // const sigs = await programs.alignGovernanceProgram.provider.sendAll([
  //     {
  //         tx,
  //         signers: [],
  //     },
  //     {
  //         tx: new web3.Transaction().add(...postInstructions),
  //     },
  // ]);

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: [
      {
        instructionsSet: tx.instructions.map(
          (ix) => new TransactionInstructionWithSigners(ix),
        ),
        sequenceType: SequenceType.Sequential,
      },

      {
        instructionsSet: postInstructions.map(
          (ix) => new TransactionInstructionWithSigners(ix),
        ),
        sequenceType: SequenceType.Sequential,
      },
    ],
    confirmLevel: "confirmed",
    extra: shadowCallbacks,
  });
  if (status === SEND_TX_STATUS.SUCESSFUL) {
    console.log("response has been returned: ", status);
    return {
      sigs: sigs,
      proposalAddress: proposalAddress,
    };
  } else {
    return {
      sigs: [],
      proposalAddress: null,
    };
  }
};

export const createUserProfile = async (
  handle: string,
  displayName: string,
  pfpMint: PublicKey | undefined,
  pfpOwnerAddress: PublicKey,
  // Not yet implemented
  linkedAddress: PublicKey[],
  recoveryKey: PublicKey,
  programs: AlignPrograms,
): Promise<{
  sigs: string[];
  identifierAddress: PublicKey;
}> => {
  const identifier = new Keypair();
  let transaction;

  if (pfpMint) {
    const metadata = await getMetadataAccount(
      pfpMint,
      programs.provider.connection,
    );

    const collection = metadata.collection.key;

    /**
     * TODO add regex for username and handle
     */

    const userProfile = Derivation.deriveUserProfileAddress(
      identifier.publicKey,
    );
    const beaconAddress = Derivation.deriveBeaconAddress(
      userProfile,
      PROFILES_PROGRAM_ID,
    );
    const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
      beaconAddress,
      pfpOwnerAddress,
    );

    const walletTrackerAccount = await Api.fetchWalletTracker(
      walletTrackerAddress,
      programs,
    );
    let threadIndex;

    if (walletTrackerAccount === null) {
      threadIndex = new BN(0);
    } else {
      threadIndex = new BN(walletTrackerAccount.account.totalTrackers);
    }

    const createWalletTrackerIx = await createPfpWalletTracker(
      programs.provider.publicKey,
      identifier.publicKey,
      programs,
    );

    const trackPfpInstruction = await trackPfp(
      pfpMint,
      collection,
      pfpOwnerAddress,
      threadIndex,
      programs.provider.publicKey,
      identifier.publicKey,
      programs,
    );

    const setPfpInstruction = await setPfpIx(
      pfpMint,
      pfpOwnerAddress,
      threadIndex,
      programs.provider.publicKey,
      identifier.publicKey,
      programs,
    );

    transaction = new web3.Transaction().add(
      await createPriorityFeeIx([
        ALIGN_PROGRAM_ID.toBase58(),
        PROFILES_PROGRAM_ID.toBase58(),
      ]),
      createWalletTrackerIx,
      trackPfpInstruction,
      SystemProgram.transfer({
        toPubkey: Derivation.deriveThreadAddress(
          walletTrackerAddress,
          threadIndex,
        ),
        fromPubkey: programs.alignGovernanceProgram.provider.publicKey,
        lamports: 0.01 * LAMPORTS_PER_SOL,
      }),
      setPfpInstruction,
    );
  }

  const identiferInstruction = await createIdentifierIx(
    programs.provider.publicKey,
    recoveryKey,
    identifier.publicKey,
    programs,
  );

  const userProfileInstruction = await createUserProfileIx(
    handle,
    displayName,
    programs.alignGovernanceProgram.provider.publicKey,
    identifier.publicKey,
    programs,
  );

  const createTransactionTx = new web3.Transaction().add(
    await createPriorityFeeIx([
      IDENTIFIERS_PROGRAM_ID.toBase58(),
      PROFILES_PROGRAM_ID.toBase58(),
    ]),
    web3.ComputeBudgetProgram.setComputeUnitLimit({ units: 430_000 }),
    identiferInstruction,
    userProfileInstruction,
  );

  const sigs = await programs.alignGovernanceProgram.provider.sendAll(
    pfpMint
      ? [
          {
            tx: createTransactionTx,
            signers: [identifier],
          },
          {
            tx: transaction,
            signers: [],
          },
        ]
      : [
          {
            tx: createTransactionTx,
            signers: [identifier],
          },
        ],
    { skipPreflight: true },
  );
  return {
    sigs,
    identifierAddress: identifier.publicKey,
  };
};

export const replacePfpTransaction = async (
  identifier: PublicKey,
  pfpMint: PublicKey,
  pfpOwnerAddress: PublicKey,
  beaconAddress: PublicKey,
  collectionMint: PublicKey,
  programs: AlignPrograms,
): Promise<web3.Transaction> => {
  const userProfile = await Api.fetchUserProfileByIdentifier(
    identifier,
    programs,
  );
  const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
    beaconAddress,
    pfpOwnerAddress,
  );
  const walletTrackerAccount = await Api.fetchWalletTracker(
    walletTrackerAddress,
    programs,
  );

  const transaction = new web3.Transaction();

  if (userProfile.account.profile.pfp) {
    const currentPfpAta = getAssociatedTokenAddressSync(
      userProfile.account.profile.pfp,
      pfpOwnerAddress,
    );
    const trackedTokenRecordAddress = Derivation.deriveTrackedTokenRecord(
      walletTrackerAddress,
      currentPfpAta,
    );
    const trackedTokenRecord = await Api.fetchTrackedTokenRecord(
      trackedTokenRecordAddress,
      programs,
    );
    transaction.add(
      await removePfp(
        userProfile.account.profile.pfp,
        pfpOwnerAddress,
        new BN(trackedTokenRecord.account.id),
        programs.alignGovernanceProgram.provider.publicKey,
        identifier,
        programs,
      ),
    );
  }
  const toPfpAta = getAssociatedTokenAddressSync(pfpMint, pfpOwnerAddress);

  const toTrackedTokenRecordAddress = Derivation.deriveTrackedTokenRecord(
    walletTrackerAddress,
    toPfpAta,
  );
  const toTrackedTokenRecordAccount = await Api.fetchTrackedTokenRecord(
    toTrackedTokenRecordAddress,
    programs,
  );
  if (!walletTrackerAccount) {
    transaction.add(
      await createPfpWalletTracker(pfpOwnerAddress, identifier, programs),
    );
  }
  const profileFeeIx = await createPriorityFeeIx([
    PROFILES_PROGRAM_ID.toBase58(),
    SystemProgram.programId.toBase58(),
  ]);
  if (!toTrackedTokenRecordAccount) {
    transaction.add(
      profileFeeIx,
      await trackPfp(
        pfpMint,
        collectionMint,
        pfpOwnerAddress,
        walletTrackerAccount
          ? new BN(walletTrackerAccount.account.totalTrackers)
          : new BN(0),
        programs.alignGovernanceProgram.provider.publicKey,
        identifier,
        programs,
      ),
      SystemProgram.transfer({
        toPubkey: Derivation.deriveThreadAddress(
          walletTrackerAddress,
          walletTrackerAccount
            ? new BN(walletTrackerAccount.account.totalTrackers)
            : new BN(0),
        ),
        fromPubkey: programs.alignGovernanceProgram.provider.publicKey,
        lamports: 0.01 * LAMPORTS_PER_SOL,
      }),
      await setPfpIx(
        pfpMint,
        pfpOwnerAddress,
        walletTrackerAccount
          ? new BN(walletTrackerAccount.account.totalTrackers)
          : new BN(0),
        programs.alignGovernanceProgram.provider.publicKey,
        identifier,
        programs,
      ),
    );
  } else if (toTrackedTokenRecordAccount) {
    transaction.add(
      profileFeeIx,
      await setPfpIx(
        pfpMint,
        pfpOwnerAddress,
        new BN(toTrackedTokenRecordAccount.account.id),
        programs.alignGovernanceProgram.provider.publicKey,
        identifier,
        programs,
      ),
    );
  }

  return transaction;
};

export const editUserProfile = async (
  identifier: PublicKey,
  programs: AlignPrograms,
  profileChanges: {
    pfpMint?: PublicKey;
    pfpOwnerAddress?: PublicKey;
    displayName?: string;
  },
): Promise<string> => {
  if (
    !profileChanges.pfpMint &&
    !profileChanges.pfpOwnerAddress &&
    !profileChanges.displayName
  ) {
    throw "No changes to profile were provided to editUserProfile.";
  }

  const { pfpMint, pfpOwnerAddress, displayName } = profileChanges;

  const metadata = await getMetadataAccount(
    pfpMint,
    programs.provider.connection,
  );
  const collection = metadata.collection.key;

  const userProfile = Derivation.deriveUserProfileAddress(identifier);
  const beaconAddress = Derivation.deriveBeaconAddress(
    userProfile,
    PROFILES_PROGRAM_ID,
  );

  let transaction: web3.Transaction = new web3.Transaction();

  if (pfpMint) {
    transaction.add(
      await createPriorityFeeIx([PROFILES_PROGRAM_ID.toBase58()]),
      await replacePfpTransaction(
        identifier,
        pfpMint,
        pfpOwnerAddress,
        beaconAddress,
        collection,
        programs,
      ),
    );
  }

  if (displayName) {
    transaction.add(
      await createPriorityFeeIx([PROFILES_PROGRAM_ID.toBase58()]),
      await editDisplayName(
        displayName,
        programs.alignGovernanceProgram.provider.publicKey,
        identifier,
        programs,
      ),
    );
  }

  const sig = await programs.alignGovernanceProgram.provider.sendAndConfirm(
    transaction,
    [],
  );
  return sig;
};

export const followOrganization = async (
  organisationAddress: PublicKey,
  nftMints: web3.PublicKey[],
  nftOwnerAddress: PublicKey,
  programs: AlignPrograms,
): Promise<string[]> => {
  const ownerRecord = await Api.fetchOwnerRecord(
    programs.alignGovernanceProgram.provider.publicKey,
    programs,
  );
  const identity = Derivation.deriveIdentityAddress(
    ownerRecord.account.identifier,
  );
  const organisationAccount = await Api.fetchOrganisation(
    organisationAddress,
    programs,
  );

  const oragnisationIdentifier = organisationAccount.account.identifier;
  const organisationIdentity = Derivation.deriveIdentityAddress(
    oragnisationIdentifier,
  );

  const toNode = Derivation.deriveNodeAddress(organisationIdentity);
  const fromNode = Derivation.deriveNodeAddress(identity);
  const edge = Derivation.deriveEdgeAddress(
    fromNode,
    toNode,
    ConnectionType.SocialRelation,
    EdgeRelation.Symmetric,
  );

  const trackNftTransactionsPromise = nftMints.map((mint) =>
    trackNftTransaction(
      ownerRecord.account.identifier,
      organisationAddress,
      mint,
      nftOwnerAddress,
      programs,
    ),
  );

  const trackNftTransactions = await Promise.all(trackNftTransactionsPromise);

  // Create Collection Nft records
  const createCollectionNftRecordsIxPrimses =
    organisationAccount.account.config.collectionItems.map((item) =>
      createCollectionNftRecordInstruction(
        item.mint,
        organisationAddress,
        ownerRecord.account.identifier,
        programs,
      ),
    );
  const createCollectionNftRecordsIx = await Promise.all(
    createCollectionNftRecordsIxPrimses,
  );
  const joinOrgTransaction = await programs.alignGovernanceProgram.methods
    .joinOrganisation()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: organisationAddress,
      reputationManager: Derivation.deriveReputationManagerAddress(
        organisationAddress,
        identity,
      ),
      identity: identity,
      ownerRecord: ownerRecord.address,
      systemProgram: SystemProgram.programId,
      toNode,
      fromNode,
      edge,
      multigraph: programs.multigraphProgram.programId,
      identifierProgram: programs.identifiersProgram.programId,
    })
    .preInstructions([
      await createPriorityFeeIx([
        ALIGN_PROGRAM_ID.toBase58(),
        MULTIGRAPH_PROGRAM_ID.toBase58(),
      ]),
      ...createCollectionNftRecordsIx,
    ])
    .transaction();

  return programs.alignGovernanceProgram.provider.sendAll(
    [
      {
        tx: joinOrgTransaction,
      },
      ...trackNftTransactions.map((tx) => ({ tx })),
    ],
    { skipPreflight: true },
  );
};

export const createWalletIx = async (
  walletName: string,
  walletType: AuthorityConfigType,
  organizationAddress: PublicKey,
  /**
   * Council vote threshold of governance account in basis points.
   * must reach this threshold to tip voting results
   * eg. 2/3 signers vote yes but threshold is 70% all three voters must
   * vote yes before it tips to approved
   */
  threshold: number,
  programs: AlignPrograms,
): Promise<{
  walletAddress: PublicKey;
  walletConfigAddress: PublicKey;
  instruction: web3.TransactionInstruction;
}> => {
  const ownerRecord = await Api.fetchOwnerRecord(
    programs.alignGovernanceProgram.provider.publicKey,
    programs,
  );
  const identity = Derivation.deriveIdentityAddress(
    ownerRecord.account.identifier,
  );

  let authorityConfigSeed = new web3.Keypair().publicKey.toBuffer();
  const authorityConfigAddress = Derivation.deriveWalletConfigAddress(
    organizationAddress,
    authorityConfigSeed,
  );
  const walletAddress = Derivation.deriveWalletAddress(authorityConfigAddress);

  const instruction = await programs.alignGovernanceProgram.methods
    .createWallet(
      authorityConfigSeed,
      threshold,
      walletType === AuthorityConfigType.Cold ? { cold: {} } : { hot: {} },
      walletName,
    )
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: organizationAddress,
      identity,
      ownerRecord: ownerRecord.address,
      systemProgram: SystemProgram.programId,
      councilManager:
        Derivation.deriveCouncilManagerAddress(organizationAddress),
      tokenProgram: TOKEN_PROGRAM_ID,
      rent: SYSVAR_RENT_PUBKEY,
      walletAuthorityConfig: authorityConfigAddress,
      wallet: walletAddress,
    })
    .instruction();
  return {
    instruction,
    walletAddress,
    walletConfigAddress: authorityConfigAddress,
  };
};

export const createOrganisationProfileTransaction = async (
  handle: string,
  displayName: string,
  pfpMint: PublicKey,
  userIdentifier: PublicKey,
  organisationAddress: PublicKey,
  organisationWalletConfigAddress: PublicKey,
  programs: AlignPrograms,
  shadowCallbacks: {
    onCreatingDrive?: () => void;
    onCreateDrive?: (res: CreateStorageResponse) => void;
    onUpload?: (res: ShadowUploadResponse) => void;
    onUploadRetry?: () => void;
    onTransactionConfirmation?: (res: any) => void;
    onTransactionFailure?: (res: any) => void;
  } = {
    onCreatingDrive: () => {},
    onCreateDrive: (res: CreateStorageResponse) => {},
    onUpload: (res: ShadowUploadResponse) => {},
    onUploadRetry: () => {},
    onTransactionConfirmation: (res: any) => {},
    onTransactionFailure: (res: any) => {},
  },
): Promise<{
  sigs: string[];
  status: SEND_TX_STATUS;
  proposalAddress: PublicKey;
}> => {
  const metadata = await getMetadataAccount(
    pfpMint,
    programs.provider.connection,
  );
  const organisationAccount = await Api.fetchOrganisation(
    organisationAddress,
    programs,
  );
  const collection = metadata.collection.key;
  const organisationWallet = Derivation.deriveWalletAddress(
    organisationWalletConfigAddress,
  );
  const transaction = new web3.Transaction();
  const createTransactionTx = new web3.Transaction();

  const userProfile = Derivation.deriveUserProfileAddress(
    organisationAccount.account.identifier,
  );
  const beaconAddress = Derivation.deriveBeaconAddress(
    userProfile,
    PROFILES_PROGRAM_ID,
  );
  const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
    beaconAddress,
    organisationWallet,
  );

  const userProfileInstruction = await createUserProfileIx(
    handle,
    displayName,
    organisationWallet,
    organisationAccount.account.identifier,
    programs,
    organisationWallet,
  );

  const createWalletTrackerIx = await createPfpWalletTracker(
    organisationWallet,
    organisationAccount.account.identifier,
    programs,
    organisationWallet,
  );

  const trackPfpInstruction = await trackPfp(
    pfpMint,
    collection,
    organisationWallet,
    new BN(0),
    organisationWallet,
    organisationAccount.account.identifier,
    programs,
    organisationWallet,
  );

  const setPfpInstruction = await setPfpIx(
    pfpMint,
    organisationWallet,
    new BN(0),
    organisationWallet,
    organisationAccount.account.identifier,
    programs,
    organisationWallet,
  );
  const orgAta = getAssociatedTokenAddressSync(pfpMint, organisationWallet);

  let orgBalance;
  try {
    orgBalance =
      await programs.alignGovernanceProgram.provider.connection.getTokenAccountBalance(
        orgAta,
      );
  } catch (e) {
    console.log(e);
    console.log(
      "Org doesnt already have nft. Creating a tranfer instruction from user",
    );
  }

  const userAta = getAssociatedTokenAddressSync(
    pfpMint,
    programs.alignGovernanceProgram.provider.publicKey,
  );

  const transferPfpTransaction = new web3.Transaction().add(
    await createPriorityFeeIx([SystemProgram.programId.toBase58()]),
    web3.ComputeBudgetProgram.setComputeUnitLimit({ units: 500_000 }),
    SystemProgram.transfer({
      toPubkey: organisationWallet,
      fromPubkey: programs.alignGovernanceProgram.provider.publicKey,
      lamports: 0.1 * LAMPORTS_PER_SOL,
    }),
  );

  if (
    metadata &&
    metadata.tokenStandard === TokenStandard.ProgrammableNonFungible
  ) {
    transferPfpTransaction.add(
      createTransferPnftInstruction(
        {
          token: userAta,
          tokenOwner: programs.alignGovernanceProgram.provider.publicKey,
          destination: orgAta,
          destinationOwner: organisationWallet,
          mint: pfpMint,
          metadata: getMetadataAddress(pfpMint),
          edition: getMasterEditionAddress(pfpMint),
          ownerTokenRecord: findTokenRecordId(
            pfpMint,
            programs.alignGovernanceProgram.provider.publicKey,
          ),
          destinationTokenRecord: findTokenRecordId(pfpMint, orgAta),
          authority: programs.alignGovernanceProgram.provider.publicKey,
          payer: programs.alignGovernanceProgram.provider.publicKey,
          systemProgram: SystemProgram.programId,
          sysvarInstructions: SYSVAR_INSTRUCTIONS_PUBKEY,
          splTokenProgram: TOKEN_PROGRAM_ID,
          splAtaProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          authorizationRules: metadata.programmableConfig.ruleSet,
          authorizationRulesProgram: TOKEN_AUTH_RULES_ID,
        },
        {
          transferArgs: {
            __kind: "V1",
            amount: 1,
            authorizationData: null,
          },
        },
      ),
    );
  } else {
    transferPfpTransaction.add(
      createAssociatedTokenAccountInstruction(
        programs.profilesProgram.provider.publicKey,
        orgAta,
        organisationWallet,
        pfpMint,
      ),
      createTransferCheckedInstruction(
        userAta,
        pfpMint,
        orgAta,
        programs.alignGovernanceProgram.provider.publicKey,
        1,
        0,
      ),
    );
  }

  createTransactionTx.add(userProfileInstruction);

  transaction.add(
    createWalletTrackerIx,
    trackPfpInstruction,
    SystemProgram.transfer({
      toPubkey: Derivation.deriveThreadAddress(walletTrackerAddress, new BN(0)),
      fromPubkey: organisationWallet, // <-- This needs to be a DAO wallet
      lamports: 0.01 * LAMPORTS_PER_SOL,
    }),
    setPfpInstruction,
  );

  const ownerRecord = await Api.fetchOwnerRecord(
    programs.alignGovernanceProgram.provider.publicKey,
    programs,
  );
  const identity = Derivation.deriveIdentityAddress(
    ownerRecord.account.identifier,
  );

  const oragnisationIdentifier = organisationAccount.account.identifier;
  const organisationIdentity = Derivation.deriveIdentityAddress(
    oragnisationIdentifier,
  );

  const toNode = Derivation.deriveNodeAddress(organisationIdentity);
  const fromNode = Derivation.deriveNodeAddress(identity);
  const edge = Derivation.deriveEdgeAddress(
    fromNode,
    toNode,
    ConnectionType.SocialRelation,
    EdgeRelation.Symmetric,
  );

  const createCollectionNftRecordsIxPromises =
    organisationAccount.account.config.collectionItems.map((item) =>
      createCollectionNftRecordInstruction(
        item.mint,
        organisationAddress,
        ownerRecord.account.identifier,
        programs,
      ),
    );

  const createCollectionNftsRecords = await Promise.all(
    createCollectionNftRecordsIxPromises,
  );

  const joinInstruction = await programs.alignGovernanceProgram.methods
    .joinOrganisation()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: organisationAddress,
      reputationManager: Derivation.deriveReputationManagerAddress(
        organisationAddress,
        identity,
      ),
      identity: identity,
      ownerRecord: ownerRecord.address,
      systemProgram: SystemProgram.programId,
      toNode,
      fromNode,
      edge,
      multigraph: programs.multigraphProgram.programId,
      identifierProgram: programs.identifiersProgram.programId,
    })
    .instruction();

  const createProposalTransaction =
    await createProposalTransactionWithCreateShadow(
      organisationAddress,
      userIdentifier,
      [createTransactionTx, transaction],
      organisationWalletConfigAddress,
      new BN(0),
      {
        name: `Create Profile | ${displayName}`,
        description: `# Create Profile

            ## Handle
            ${handle}
            
            ## Display Name
            ${displayName}
            
            ## Profile Picture Mint
            ${pfpMint.toBase58()}
            `,
      },
      {
        onCreateDrive: () => {},
        onCreatingDrive: () => {},
        onUpload: () => {},
        onUploadRetry: () => {},
      },
      programs,
      undefined,
    );

  const formattedCreateTransactionInstructions: any[] =
    createProposalTransaction.createTransactions.map((instruction) => {
      return {
        instructionsSet: [{ transactionInstruction: instruction }],
        sequenceType: SequenceType.Sequential,
      };
    });

  const formattedCreateInstructionInstructions: any[] =
    createProposalTransaction.addInstructionsIx.map((instruction) => {
      return {
        instructionsSet: [{ transactionInstruction: instruction }],
        sequenceType: SequenceType.Sequential,
      };
    });

  const finalizeDraftInstruction = await programs.alignGovernanceProgram.methods
    .finalizeDraftProposal()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: programs.alignGovernanceProgram.provider.publicKey,
      organisation: organisationAddress,
      reputationManager: Derivation.deriveReputationManagerAddress(
        organisationAddress,
        Derivation.deriveIdentityAddress(userIdentifier),
      ),
      identity: Derivation.deriveIdentityAddress(userIdentifier),
      ownerRecord: Derivation.deriveOwnerRecordAddress(
        programs.alignGovernanceProgram.provider.publicKey,
      ),
      systemProgram: SystemProgram.programId,
      walletAuthorityConfig: organisationWalletConfigAddress,
      proposal: Derivation.deriveProposalAddress(
        organisationWalletConfigAddress,
        new BN(0),
      ),
      proposerContributionRecord: Derivation.deriveContributionRecord(
        userIdentifier,
        Derivation.deriveProposalAddress(
          organisationWalletConfigAddress,
          new BN(0),
        ),
      ),
      councilManager:
        Derivation.deriveCouncilManagerAddress(organisationAddress),
    })
    .instruction();

  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: !orgBalance?.value?.uiAmount
      ? [
          {
            instructionsSet: transferPfpTransaction.instructions.map((ix) => ({
              transactionInstruction: ix,
            })),
            sequenceType: SequenceType.Sequential,
          },
          {
            instructionsSet: [
              {
                transactionInstruction: joinInstruction,
              },
              ...createCollectionNftsRecords.map((x) => ({
                transactionInstruction: x,
              })),
              {
                transactionInstruction:
                  createProposalTransaction.createProposal,
              },
            ],
            sequenceType: SequenceType.Sequential,
          },
          ...formattedCreateTransactionInstructions,
          ...formattedCreateInstructionInstructions,
          {
            instructionsSet: [
              {
                transactionInstruction:
                  createProposalTransaction.contributionIx,
              },
              { transactionInstruction: finalizeDraftInstruction },
            ],
            sequenceType: SequenceType.Sequential,
          },
        ]
      : [
          {
            instructionsSet: [
              {
                transactionInstruction: joinInstruction,
              },
              ...createCollectionNftsRecords.map((x) => ({
                transactionInstruction: x,
              })),
              {
                transactionInstruction:
                  createProposalTransaction.createProposal,
              },
            ],
            sequenceType: SequenceType.Sequential,
          },
          ...formattedCreateTransactionInstructions,
          ...formattedCreateInstructionInstructions,
          {
            instructionsSet: [
              {
                transactionInstruction:
                  createProposalTransaction.contributionIx,
              },
              { transactionInstruction: finalizeDraftInstruction },
            ],
            sequenceType: SequenceType.Sequential,
          },
        ],
    confirmLevel: "confirmed",
    extra: shadowCallbacks,
  });

  return {
    sigs: sigs,
    status,
    proposalAddress: createProposalTransaction.proposalAddress,
  };
};

export const createOrganisation = async (
  config: OrganisationConfig,
  treasuryThreshold: number,
  councilConfigThreshold: number,
  organisationConfigThreshold: number,
  councilIdentifiers: PublicKey[],
  programs: AlignPrograms,
  shadowCallbacks: {
    onCreatingDrive?: () => void;
    onCreateDrive?: (res: CreateStorageResponse) => void;
    onUpload?: (res: ShadowUploadResponse) => void;
    onUploadRetry?: () => void;
    onTransactionConfirmation?: (res: any) => void;
    onTransactionFailure?: (res: any) => void;
  } = {
    onCreatingDrive: () => {},
    onCreateDrive: (res: CreateStorageResponse) => {},
    onUpload: (res: ShadowUploadResponse) => {},
    onUploadRetry: () => {},
    onTransactionConfirmation: (res: any) => {},
    onTransactionFailure: (res: any) => {},
  },
): Promise<{
  organizationIdentifier: PublicKey;
  organizationAddress: PublicKey;
  sig: string[];
  treasuryWallet: PublicKey;
  treasuryConfigAddress: PublicKey;
  status: SEND_TX_STATUS;
  organisationAuthorityConfigAddress: PublicKey;
}> => {
  const identifier = new Keypair();
  let organisationAuthorityConfigSeed = new web3.Keypair().publicKey.toBuffer();
  let councilAuthorityConfigSeed = new web3.Keypair().publicKey.toBuffer();

  const organizationAddress = Derivation.deriveOrganisationAddress(
    identifier.publicKey,
  );
  const councilManager =
    Derivation.deriveCouncilManagerAddress(organizationAddress);

  const organisationAuthorityConfigAddress =
    Derivation.deriveWalletConfigAddress(
      organizationAddress,
      organisationAuthorityConfigSeed,
    );
  const councilAuthorityConfigAddress = Derivation.deriveWalletConfigAddress(
    organizationAddress,
    councilAuthorityConfigSeed,
  );

  const organisationAuthorityWallet = Derivation.deriveWalletAddress(
    organisationAuthorityConfigAddress,
  );
  const councilAuthorityWallet = Derivation.deriveWalletAddress(
    councilAuthorityConfigAddress,
  );

  const beacon = Derivation.deriveBeaconAddress(
    organizationAddress,
    programs.alignGovernanceProgram.programId,
  );

  const createWalletInstruction = await createWalletIx(
    "Treasury",
    AuthorityConfigType.Hot,
    organizationAddress,
    treasuryThreshold,
    programs,
  );

  const createOrgTransaction = await programs.alignGovernanceProgram.methods
    .createOrganisation(
      config,
      councilAuthorityConfigSeed,
      organisationAuthorityConfigSeed,
      councilConfigThreshold,
      organisationConfigThreshold,
    )
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      organisation: organizationAddress,
      identity: Derivation.deriveIdentityAddress(identifier.publicKey),
      ownerRecord: Derivation.deriveOwnerRecordAddress(
        organisationAuthorityWallet,
      ),
      multigraph: programs.multigraphProgram.programId,
      identifierProgram: programs.identifiersProgram.programId,
      systemProgram: SystemProgram.programId,
      councilManager,
      tokenProgram: TOKEN_PROGRAM_ID,
      identifierSigner: identifier.publicKey,
      identifier: identifier.publicKey,
      node: Derivation.deriveNodeAddress(
        Derivation.deriveIdentityAddress(identifier.publicKey),
      ),
      councilManagerAuthorityConfig: councilAuthorityConfigAddress,
      councilManagerAuthority: councilAuthorityWallet,
      organisationAuthorityConfig: organisationAuthorityConfigAddress,
      organisationAuthority: organisationAuthorityWallet,
      beacon,
      beaconProgram: programs.beaconProgram.programId,
    })
    .remainingAccounts(
      councilIdentifiers.map((id) => ({
        isSigner: false,
        isWritable: false,
        pubkey: id,
      })),
    )
    // .preInstructions([
    //     await createPriorityFeeIx([ALIGN_PROGRAM_ID.toBase58()]),
    // ])
    .signers([identifier])
    .transaction();

  const createUSDCAtaTransaction = new web3.Transaction().add(
    await createPriorityFeeIx([ALIGN_PROGRAM_ID.toBase58()]),
    createWalletInstruction.instruction,
    // createAssociatedTokenAccountInstruction(
    //     programs.provider.publicKey,
    //     getAssociatedTokenAddressSync(USDC_MINT_ADDRESS, createWalletInstruction.walletAddress, true),
    //     createWalletInstruction.walletAddress,
    //     USDC_MINT_ADDRESS
    // )
  );

  let createOrgInstructions: any[] = createOrgTransaction.instructions.map(
    (instruction) => {
      return {
        instructionsSet: [
          new TransactionInstructionWithSigners(instruction, [identifier]),
        ],
        sequenceType: SequenceType.Sequential,
      };
    },
  );
  let { sigs, status } = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: [
      ...createOrgInstructions,
      {
        instructionsSet: [
          new TransactionInstructionWithSigners(
            createWalletInstruction.instruction,
          ),
        ],
        sequenceType: SequenceType.Sequential,
      },
    ],
    confirmLevel: "confirmed",
    extra: shadowCallbacks,
  });

  return {
    sig: sigs,
    status,
    organizationAddress,
    organizationIdentifier: identifier.publicKey,
    treasuryWallet: createWalletInstruction.walletAddress,
    treasuryConfigAddress: createWalletInstruction.walletConfigAddress,
    organisationAuthorityConfigAddress,
  };
};

export const trackNft = async (
  identifier: PublicKey,
  organisation: PublicKey,
  nftMint: PublicKey,
  nftOwnerAddress: PublicKey,
  programs: AlignPrograms,
  signers?: Keypair[],
): Promise<string> => {
  const metadata = await getMetadataAccount(
    nftMint,
    programs.provider.connection,
  );
  const collection = metadata.collection.key;

  /**
   * TODO add regex for username and handle
   */
  const transaction = new web3.Transaction();

  const beaconAddress = Derivation.deriveBeaconAddress(
    organisation,
    ALIGN_PROGRAM_ID,
  );
  const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
    beaconAddress,
    nftOwnerAddress,
  );
  const identity = Derivation.deriveIdentityAddress(identifier);
  const walletTrackerAccount = await Api.fetchWalletTracker(
    walletTrackerAddress,
    programs,
  );
  const nftAta = getAssociatedTokenAddressSync(nftMint, nftOwnerAddress);
  let threadIndex;

  if (walletTrackerAccount === null) {
    threadIndex = new BN(0);
    const createWalletTrackerInstruction =
      await programs.alignGovernanceProgram.methods
        .createWalletTracker()
        .accountsStrict({
          payer: programs.alignGovernanceProgram.provider.publicKey,
          owner: nftOwnerAddress,
          identity,
          organisation,
          reputationManager: Derivation.deriveReputationManagerAddress(
            organisation,
            identity,
          ),
          beacon: beaconAddress,
          walletTracker: walletTrackerAddress,
          ownerRecord: Derivation.deriveOwnerRecordAddress(nftOwnerAddress),
          beaconProgram: BEACON_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
        })
        .transaction();
    transaction.add(createWalletTrackerInstruction);
  } else {
    threadIndex = new BN(walletTrackerAccount.account.totalTrackers);
  }

  const trackNftInstruction = await programs.alignGovernanceProgram.methods
    .trackNft()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      systemProgram: SystemProgram.programId,
      walletTracker: walletTrackerAddress,
      owner: nftOwnerAddress,
      collectionMint: collection,
      trackedTokenRecord: Derivation.deriveTrackedTokenRecord(
        walletTrackerAddress,
        nftAta,
      ),
      collectionMetadata: getMetadataAddress(collection),
      collectionMasterEdition: getMasterEditionAddress(collection),
      userNftMint: nftMint,
      userNftMetadata: getMetadataAddress(nftMint),
      userNftMasteredition: getMasterEditionAddress(nftMint),
      userTokenAccount: nftAta,
      thread: Derivation.deriveThreadAddress(walletTrackerAddress, threadIndex),
      clockwork: CLOCKWORK_PROGRAM_ID,
      instruction: Derivation.deriveInstructionCallbackAddress(
        Derivation.deriveTrackedTokenRecord(walletTrackerAddress, nftAta),
      ),
      identity,
      ownerRecord: Derivation.deriveOwnerRecordAddress(nftOwnerAddress),
      organisation,
      beacon: beaconAddress,
      beaconProgram: BEACON_PROGRAM_ID,
      reputationManager: Derivation.deriveReputationManagerAddress(
        organisation,
        identity,
      ),
      collectionRecord: Derivation.deriveCollectionNftRecordAddress(
        identifier,
        organisation,
        collection,
      ),
    })

    .preInstructions([
      await createPriorityFeeIx([
        ALIGN_PROGRAM_ID.toBase58(),
        BEACON_PROGRAM_ID.toBase58(),
      ]),
    ])
    .transaction();

  transaction.add(
    SystemProgram.transfer({
      toPubkey: Derivation.deriveThreadAddress(
        walletTrackerAddress,
        threadIndex,
      ),
      fromPubkey: programs.alignGovernanceProgram.provider.publicKey,
      lamports: 0.01 * LAMPORTS_PER_SOL,
    }),
    trackNftInstruction,
  );

  const sig = await programs.alignGovernanceProgram.provider.sendAndConfirm(
    transaction,
    signers,
    { skipPreflight: true },
  );
  return sig;
};

export const trackNfts = async (
  identifier: PublicKey,
  organisation: PublicKey,
  nftMints: PublicKey[],
  nftOwnerAddress: PublicKey,
  programs: AlignPrograms,
  callbacks: {
    onTransactionConfirmation?: (res: any) => void;
    onTransactionFailure?: (res: any) => void;
    onError?: (
      e: any,
      notProcessedTransactions: TransactionInstructionWithType[],
      originalProps: sendSignAndConfirmTransactionsProps,
    ) => void;
  } = {
    onTransactionConfirmation: (res) => {},
    onTransactionFailure: (res) => {},
    onError: (
      e: any,
      notProcessedTransactions: TransactionInstructionWithType[],
      originalProps: sendSignAndConfirmTransactionsProps,
    ) => {},
  },
): Promise<{ sigs: string[]; status: SEND_TX_STATUS }> => {
  const beaconAddress = Derivation.deriveBeaconAddress(
    organisation,
    ALIGN_PROGRAM_ID,
  );
  const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
    beaconAddress,
    nftOwnerAddress,
  );
  const walletTrackerAccount = await Api.fetchWalletTracker(
    walletTrackerAddress,
    programs,
  );

  const trackNftTransactionsPromise = nftMints.map((mint, i) =>
    trackNftTransaction(
      identifier,
      organisation,
      mint,
      nftOwnerAddress,
      programs,
      i === 0 && walletTrackerAccount === null ? true : false,
      walletTrackerAccount
        ? new BN(walletTrackerAccount.account.totalTrackers + i)
        : new BN(i),
    ),
  );

  const trackNftTransactions = await Promise.all(trackNftTransactionsPromise);

  const ixSets = trackNftTransactions.map((tx) => ({
    instructionsSet: tx.instructions.flatMap((ix) => ({
      transactionInstruction: ix,
    })),
    sequenceType: SequenceType.Sequential,
  }));
  let res = await signSendAndConfirmTransactionsWithFees({
    connection: programs.provider.connection,
    wallet: programs.provider.wallet,
    transactionInstructions: ixSets,
    confirmLevel: "confirmed",
    extra: callbacks,
  });

  return res;
};

export const trackNftTransaction = async (
  identifier: PublicKey,
  organisation: PublicKey,
  nftMint: PublicKey,
  nftOwnerAddress: PublicKey,
  programs: AlignPrograms,
  includeCreateWalletTrackerTx: boolean = true,
  thread_index?: BN,
  signers?: Keypair[],
): Promise<web3.Transaction> => {
  const metadata = await getMetadataAccount(
    nftMint,
    programs.provider.connection,
  );
  const collection = metadata.collection.key;

  /**
   * TODO add regex for username and handle
   */
  const transaction = new web3.Transaction();

  const beaconAddress = Derivation.deriveBeaconAddress(
    organisation,
    ALIGN_PROGRAM_ID,
  );
  const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
    beaconAddress,
    nftOwnerAddress,
  );
  const identity = Derivation.deriveIdentityAddress(identifier);
  const walletTrackerAccount = await Api.fetchWalletTracker(
    walletTrackerAddress,
    programs,
  );
  const nftAta = getAssociatedTokenAddressSync(nftMint, nftOwnerAddress);
  let threadIndex;

  if (walletTrackerAccount === null && includeCreateWalletTrackerTx) {
    threadIndex = new BN(0);
    const createWalletTrackerInstruction =
      await programs.alignGovernanceProgram.methods
        .createWalletTracker()
        .accountsStrict({
          payer: programs.alignGovernanceProgram.provider.publicKey,
          owner: nftOwnerAddress,
          identity,
          organisation,
          reputationManager: Derivation.deriveReputationManagerAddress(
            organisation,
            identity,
          ),
          beacon: beaconAddress,
          walletTracker: walletTrackerAddress,
          ownerRecord: Derivation.deriveOwnerRecordAddress(nftOwnerAddress),
          beaconProgram: BEACON_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
        })
        .transaction();
    transaction.add(createWalletTrackerInstruction);
  } else {
    threadIndex = thread_index
      ? thread_index
      : new BN(walletTrackerAccount.account.totalTrackers);
  }

  const trackNftInstruction = await programs.alignGovernanceProgram.methods
    .trackNft()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      systemProgram: SystemProgram.programId,
      walletTracker: walletTrackerAddress,
      owner: nftOwnerAddress,
      collectionMint: collection,
      trackedTokenRecord: Derivation.deriveTrackedTokenRecord(
        walletTrackerAddress,
        nftAta,
      ),
      collectionMetadata: getMetadataAddress(collection),
      collectionMasterEdition: getMasterEditionAddress(collection),
      userNftMint: nftMint,
      userNftMetadata: getMetadataAddress(nftMint),
      userNftMasteredition: getMasterEditionAddress(nftMint),
      userTokenAccount: nftAta,
      thread: Derivation.deriveThreadAddress(walletTrackerAddress, threadIndex),
      clockwork: CLOCKWORK_PROGRAM_ID,
      instruction: Derivation.deriveInstructionCallbackAddress(
        Derivation.deriveTrackedTokenRecord(walletTrackerAddress, nftAta),
      ),
      identity,
      ownerRecord: Derivation.deriveOwnerRecordAddress(nftOwnerAddress),
      organisation,
      beacon: beaconAddress,
      beaconProgram: BEACON_PROGRAM_ID,
      reputationManager: Derivation.deriveReputationManagerAddress(
        organisation,
        identity,
      ),
      collectionRecord: Derivation.deriveCollectionNftRecordAddress(
        identifier,
        organisation,
        collection,
      ),
    })
    .preInstructions([
      await createPriorityFeeIx([
        ALIGN_PROGRAM_ID.toBase58(),
        BEACON_PROGRAM_ID.toBase58(),
      ]),
    ])
    .transaction();

  transaction.add(
    SystemProgram.transfer({
      toPubkey: Derivation.deriveThreadAddress(
        walletTrackerAddress,
        threadIndex,
      ),
      fromPubkey: programs.alignGovernanceProgram.provider.publicKey,
      lamports: 0.0085 * LAMPORTS_PER_SOL,
    }),
    trackNftInstruction,
  );

  return transaction;
};

export const redeemReputation = async (
  identifier: PublicKey,
  propsalAddress: PublicKey,
  programs: AlignPrograms,
): Promise<string> => {
  const identity = Derivation.deriveIdentityAddress(identifier);
  const proposal = await Api.fetchProposal(propsalAddress, programs);
  const tx = await programs.alignGovernanceProgram.methods
    .claimReputation()
    .accountsStrict({
      organisation: proposal.account.organisation,
      proposal: propsalAddress,
      payer: programs.alignGovernanceProgram.provider.publicKey,
      systemProgram: web3.SystemProgram.programId,
      identity,
      contributionRecord: Derivation.deriveContributionRecord(
        identifier,
        propsalAddress,
      ),
      reputationManager: Derivation.deriveReputationManagerAddress(
        proposal.account.organisation,
        identity,
      ),
    })
    .preInstructions([await createPriorityFeeIx([ALIGN_PROGRAM_ID.toBase58()])])
    .transaction();

  const sig = await programs.alignGovernanceProgram.provider.sendAndConfirm(
    tx,
    [],
    { skipPreflight: true },
  );
  return sig;
};

export const calculateContributionClaimableReputation = (
  organisationConfig: OrganisationConfig,
  contributionRecord: ContributionRecord,
  councilVoteDirection: CouncilVote,
  userRankDirection: RankVoteType | null,
  isProposer: boolean,
  isServicer: boolean,
): BN => {
  let votedWithCouncil = false;
  if (userRankDirection === RankVoteType.Upvote) {
    if (councilVoteDirection === CouncilVote.Yes) {
      votedWithCouncil = true;
    }
  } else if (userRankDirection === RankVoteType.Downvote) {
    if (councilVoteDirection === CouncilVote.No) {
      votedWithCouncil = true;
    }
  }

  let voteRep = votedWithCouncil
    ? organisationConfig.votingInAlignmentReward +
      organisationConfig.votingRepReward
    : organisationConfig.votingRepReward;
  voteRep = userRankDirection === null ? 0 : voteRep;
  const servicerRep =
    councilVoteDirection === CouncilVote.Yes && isServicer
      ? new BN(organisationConfig.proposalServicedReward)
      : new BN(0);
  const proposerRep =
    councilVoteDirection === CouncilVote.Yes && isProposer
      ? new BN(organisationConfig.proposalCreatedReward)
      : new BN(0);

  const repToClaim = contributionRecord.isClaimed
    ? new BN(0)
    : new BN(voteRep).add(servicerRep).add(proposerRep);
  return repToClaim;
};

export * from "./governance";
