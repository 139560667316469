import { PublicKey } from "@solana/web3.js";
import { CouncilVote, RankVoteType } from "align-sdk";
import { Renderable } from "react-hot-toast";
import { atomFamily } from "recoil";

export enum NotifType {
  RedeemReputation,
  ServicerRequest,
  CouncilReview,
  CouncilVote,
  ProposalComplete,
}

export interface ContributionRecordStringfied {
  idenitfier: string;
  organisation: string; // Sub org or Organisation
  proposal: string; // Account governance that determines council
  isClaimed: boolean;
  upVoteCount: string;
  downVoteCount: string;
  bump: number;
}

export interface UnclaimedReputationContext {
  contributionRecord: {
    address: string;
    account: ContributionRecordStringfied;
  };
  isServicer: boolean;
  isProposer: boolean;
  proposalTimestamp: number;
  councilVoteDirection: CouncilVote;
  userRankDirection: null | RankVoteType;
  unclaimedReputation: number;
}

export type NotificationContext = UnclaimedReputationContext | PublicKey;

export const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export interface Notification<NotificationContext> {
  id: string | number;
  timeStamp: number;
  title: string;
  message: string;
  type: NotifType;
  image: Renderable;
  seen: boolean;
  timeout: number;
  context: NotificationContext;
}

export const notificationsState = atomFamily<
  Notification<NotificationContext>[],
  string | undefined
>({
  key: "notif",
  default: [],
  effects: (key) => [localStorageEffect(`notifications_history_${key}`)],
});
