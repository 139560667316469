import { DocumentTextIcon, ForwardIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "antd";
import { useAtom } from "jotai";
import { useState } from "react";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import { useSetRecoilState } from "recoil";
import remarkGfm from "remark-gfm";
import { proposalWizardSendAssetsDescription } from "../../../../state/forms";
import { useAuth } from "../../../../state/hooks/useAlignGovernance";
import { themeOpts } from "../../../ThemeChanger";
import { WizardStepProps } from "../../Wizard";

const ProposalWizardSendDescription = ({
    currentStep,
    totalSteps,
    onComplete
} : WizardStepProps<{}>) => {

    const user = useAuth()
    const [name, setName] = useState("");
  const [previewMarkdown, setPreviewMarkdown] = useState(false);
  const [description, setDescription] = useState("");
  const [theme, setTheme] = useAtom(themeOpts);
    const setInputs = useSetRecoilState(proposalWizardSendAssetsDescription)
  const [hasRanking, setHasRanking] = useState(true)
   


    const handleClick = () => {
        if (name.trim().length === 0 || description.trim().length === 0) {
          toast.error("Fields cannot be left empty");
        } else {
          setInputs({
            title: name,
            description,
            hasRanking
          });
          onComplete({});
        }
      };
    if(currentStep !== 0 ){
        return <></>
    }



    return ( 
        <div className="proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full md:w-full p-6 lg:col-span-2 sm:p-6 bg-secondary border-boxWidth flex flex-col">
            <div className="flex justify-between">
              <div className="font-heading text-lg md:text-3xl mb-2">Create Send Proposal </div>
              <p className="text-lg font-semibold text-primary">STEP {currentStep + 1}/{totalSteps}</p>
            </div>
            <h3 className="text-sm font-normal mb-6">Enter a title and description for your send proposal.</h3>

    
          <input
            value={name}
            placeholder={"Proposal Title"}
            onChange={(e) => setName(e.target.value)}
            className={`input block mt-1 p-3 w-full h-24`}
          />
          <div className="input relative rounded border flex-1 gap-3">
            <Tooltip title="Preview Markdown">
              <div
                className={`${
                  previewMarkdown ? "opacity-90" : ""
                } hover:opacity-90 hover:cursor-pointer opacity-70 px-1 py-1 rounded-lg bg-accent absolute right-3 top-2`}
                onClick={() => setPreviewMarkdown(!previewMarkdown)}  
              >
                  <DocumentTextIcon className={`w-5`}  />
              </div>
            </Tooltip>
            {previewMarkdown ? (
              <div className="markdown-container">
                <ReactMarkdown
                  className="max-w-none markdown prose prose-invert text-contrast"
                  children={description}
                  remarkPlugins={[remarkGfm]}
                />
              </div>
            ) : (
              <textarea
                value={description}
                placeholder={"# Proposal Description"}
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
                className={`flex-1 bg-transparent p-3 w-full min-h-full h-80`}
              />
            )}
            
        </div>
          <div className="flex p-2 border border-contrast border-opacity-30 rounded-md text-opacity-20 gap-2 text-sm ">

                  <input 
                      className="" 
                      type="checkbox"
                      checked={hasRanking}
                      onClick={() => setHasRanking(!hasRanking)}
                      />
                  <span className=" text-gray-200 ">Proposal Ranking Period</span>
                  <label className="ml-4 text-contrast text-opacity-50 text-[10px] ">This is the period of time that the community has to vote up or down the proposal. If it is time sensitive you can remove ther ranking period.</label>
              </div>
           
            <button
                className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary  
                transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                disabled={description.length === 0 || name.length === 0 }
                onClick={() => handleClick()}
                >
                 <ForwardIcon className={`w-4 `}/>
                Next Step
            </button>
    </div>           
        
     );
}
 
export default ProposalWizardSendDescription;