import { XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { toast } from "react-hot-toast";

export function ProofModal({show, onSubmit, onClose} : {show : boolean, onSubmit: (proof : string) => void, onClose : () => void}){
    const [proof, setproof] = useState('');
    if (!show) return <></>
    return(
        <>
            <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-20 z-40"/>
            <div className="flex justify-center items-center fixed w-screen h-screen left-0 top-0 z-50">
                <div className="relative flex flex-col gap-7 border-2 border-gray-600 bg-black w-1/2 h-fit px-5 pb-10 pt-4 rounded-lg">
                    <label className="text-contrast text-lg font-semibold border-b border-b-contrast border-opacity-10 pb-2">Attach Service Proof</label>
                    <label>Please provide a link to some proof of your service completion. This can be a link to a demo or end product for the council to review.</label>
                    <label>You can use cloud providers such as <a className="text-accent" href="https://drive.google.com" target={"_blank"}>Google Drive</a>, <a className="text-accent" href="https://www.dropbox.com/" target={"_blank"}>Drop Box</a> or <a className="text-accent" href="https://onedrive.live.com/" target={"_blank"}>OneDrive</a></label>

                    <input 
                        value={proof}
                        onChange={(e) => {
                            try {
                                new URL(e.currentTarget.value.trim())
                            }
                            catch(e){
                                toast.error("Invalid URL provided.")
                            }
                            setproof(e.currentTarget.value.trim())}
                        }
                        className="input" 
                        placeholder="https://example.com/myproof"
                        />
                    <button 
                        onClick={proof !== '' ? () => {
                            onSubmit(proof)
                            onClose()
                         } : () => {}}
                        className="w-full flex justify-center gap-2 items-center self-end bg-accent rounded-button border-primary 
									 
									  
									 border-[color:var(--button-border-color)] 
									 
									transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ">Submit Proof</button>
                    <XMarkIcon onClick={() => onClose()} className="absolute hover:cursor-pointer  w-7 top0 right-4"/>
                </div>
    
            </div>
        </>

    )
}