import { BN, web3 } from "@project-serum/anchor";
import {
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { Keypair, PublicKey, SystemProgram } from "@solana/web3.js";
import {
  BEACON_PROGRAM_ID,
  CLOCKWORK_PROGRAM_ID,
  PROFILES_PROGRAM_ID,
} from "./constants";
import { Derivation } from "./pda";
import { AlignPrograms } from "./types";
import { getMasterEditionAddress, getMetadataAddress } from "./utils";

export const createUserProfileIx = async (
  handle: string,
  displayName: string,
  ownerAddress: PublicKey,
  identifier: PublicKey,
  programs: AlignPrograms,
  payer: PublicKey = programs.alignGovernanceProgram.provider.publicKey,
): Promise<web3.TransactionInstruction> => {
  const identity = Derivation.deriveIdentityAddress(identifier);

  return await programs.profilesProgram.methods
    .createProfile(handle, displayName)
    .accountsStrict({
      payer,
      owner: ownerAddress,
      identity,
      ownerRecord: Derivation.deriveOwnerRecordAddress(ownerAddress),
      userProfile: Derivation.deriveUserProfileAddress(identifier),
      usernameRecord: Derivation.deriveUsernameRecordAddress(handle),
      systemProgram: SystemProgram.programId,
      beacon: Derivation.deriveBeaconAddress(
        Derivation.deriveUserProfileAddress(identifier),
        PROFILES_PROGRAM_ID,
      ),
      beaconProgram: BEACON_PROGRAM_ID,
    })
    .instruction();
};

export const editDisplayName = async (
  displayName: string,
  ownerAddress: PublicKey,
  identifier: PublicKey,
  programs: AlignPrograms,
): Promise<web3.TransactionInstruction> => {
  const identity = Derivation.deriveIdentityAddress(identifier);

  return await programs.profilesProgram.methods
    .editDisplayName(displayName)
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      owner: ownerAddress,
      identity,
      ownerRecord: Derivation.deriveOwnerRecordAddress(ownerAddress),
      userProfile: Derivation.deriveUserProfileAddress(identifier),
      systemProgram: SystemProgram.programId,
    })
    .instruction();
};

export const createPfpWalletTracker = async (
  ownerAddress: PublicKey,
  identifier: PublicKey,
  programs: AlignPrograms,
  payer: PublicKey = programs.alignGovernanceProgram.provider.publicKey,
): Promise<web3.TransactionInstruction> => {
  const identity = Derivation.deriveIdentityAddress(identifier);
  const userProfile = Derivation.deriveUserProfileAddress(identifier);
  const beaconAddress = Derivation.deriveBeaconAddress(
    userProfile,
    PROFILES_PROGRAM_ID,
  );

  return await programs.profilesProgram.methods
    .createPfpWalletTracker()
    .accountsStrict({
      payer,
      owner: ownerAddress,
      identity,
      ownerRecord: Derivation.deriveOwnerRecordAddress(ownerAddress),
      userProfile: Derivation.deriveUserProfileAddress(identifier),
      systemProgram: SystemProgram.programId,
      beacon: beaconAddress,
      beaconProgram: BEACON_PROGRAM_ID,
      walletTracker: Derivation.deriveWalletTrackerAddress(
        beaconAddress,
        ownerAddress,
      ),
    })
    .instruction();
};

export const trackPfp = async (
  pfpMint: PublicKey,
  collectionMint: PublicKey,
  pfpOwner: PublicKey,
  threadIndex: BN,
  ownerAddress: PublicKey,
  identifier: PublicKey,
  programs: AlignPrograms,
  payer: PublicKey = programs.alignGovernanceProgram.provider.publicKey,
): Promise<web3.TransactionInstruction> => {
  const identity = Derivation.deriveIdentityAddress(identifier);
  const userProfile = Derivation.deriveUserProfileAddress(identifier);
  const beaconAddress = Derivation.deriveBeaconAddress(
    userProfile,
    PROFILES_PROGRAM_ID,
  );
  const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
    beaconAddress,
    pfpOwner,
  );
  const pfpAta = getAssociatedTokenAddressSync(pfpMint, pfpOwner, true);
  const trackedTokenRecord = Derivation.deriveTrackedTokenRecord(
    walletTrackerAddress,
    pfpAta,
  );
  const instructionAddress =
    Derivation.deriveInstructionCallbackAddress(trackedTokenRecord);

  return await programs.profilesProgram.methods
    .trackPfp()
    .accountsStrict({
      payer,
      systemProgram: SystemProgram.programId,
      walletTracker: walletTrackerAddress,
      collectionMint,
      trackedTokenRecord: trackedTokenRecord,
      collectionMetadata: getMetadataAddress(collectionMint),
      collectionMasterEdition: getMasterEditionAddress(collectionMint),
      pfpMint,
      pfpMetadata: getMetadataAddress(pfpMint),
      pfpMasterEdition: getMasterEditionAddress(pfpMint),
      pfpTokenAccount: pfpAta,
      thread: Derivation.deriveThreadAddress(walletTrackerAddress, threadIndex),
      clockwork: CLOCKWORK_PROGRAM_ID,
      instruction: instructionAddress,
      owner: ownerAddress,
      identity,
      userProfile,
      beacon: beaconAddress,
      ownerRecord: Derivation.deriveOwnerRecordAddress(ownerAddress),
      beaconProgram: BEACON_PROGRAM_ID,
      nftHolderOwnerRecord: Derivation.deriveOwnerRecordAddress(pfpOwner),
    })
    .instruction();
};

export const removePfp = async (
  pfpMint: PublicKey,
  pfpOwner: PublicKey,
  threadIndex: BN,
  ownerAddress: PublicKey,
  identifier: PublicKey,
  programs: AlignPrograms,
): Promise<web3.TransactionInstruction> => {
  const identity = Derivation.deriveIdentityAddress(identifier);
  const userProfile = Derivation.deriveUserProfileAddress(identifier);
  const beaconAddress = Derivation.deriveBeaconAddress(
    userProfile,
    PROFILES_PROGRAM_ID,
  );
  const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
    beaconAddress,
    pfpOwner,
  );
  const pfpAta = getAssociatedTokenAddressSync(pfpMint, pfpOwner);
  const trackedTokenRecord = Derivation.deriveTrackedTokenRecord(
    walletTrackerAddress,
    pfpAta,
  );

  return await programs.profilesProgram.methods
    .removePfp()
    .accountsStrict({
      payer: programs.alignGovernanceProgram.provider.publicKey,
      systemProgram: SystemProgram.programId,
      walletTracker: walletTrackerAddress,
      trackedTokenRecord: trackedTokenRecord,
      pfpMint,
      pfpTokenAccount: pfpAta,
      thread: Derivation.deriveThreadAddress(walletTrackerAddress, threadIndex),
      clockwork: CLOCKWORK_PROGRAM_ID,
      owner: ownerAddress,
      identity,
      userProfile,
      beacon: beaconAddress,
      ownerRecord: Derivation.deriveOwnerRecordAddress(ownerAddress),
      beaconProgram: BEACON_PROGRAM_ID,
      nftHolderOwnerRecord: Derivation.deriveOwnerRecordAddress(pfpOwner),
    })
    .instruction();
};

export const setPfpIx = async (
  pfpMint: PublicKey,
  pfpOwner: PublicKey,
  threadIndex: BN,
  ownerAddress: PublicKey,
  identifier: PublicKey,
  programs: AlignPrograms,
  payer: PublicKey = programs.alignGovernanceProgram.provider.publicKey,
): Promise<web3.TransactionInstruction> => {
  const identity = Derivation.deriveIdentityAddress(identifier);
  const userProfile = Derivation.deriveUserProfileAddress(identifier);
  const beaconAddress = Derivation.deriveBeaconAddress(
    userProfile,
    PROFILES_PROGRAM_ID,
  );
  const walletTrackerAddress = Derivation.deriveWalletTrackerAddress(
    beaconAddress,
    pfpOwner,
  );
  const pfpAta = getAssociatedTokenAddressSync(pfpMint, pfpOwner, true);
  const trackedTokenRecord = Derivation.deriveTrackedTokenRecord(
    walletTrackerAddress,
    pfpAta,
  );

  return await programs.profilesProgram.methods
    .setPfp()
    .accountsStrict({
      payer,
      owner: ownerAddress,
      identity,
      ownerRecord: Derivation.deriveOwnerRecordAddress(ownerAddress),
      userProfile,
      pfpMint,
      pfpTokenAccount: pfpAta,
      nftHolderOwnerRecord: Derivation.deriveOwnerRecordAddress(pfpOwner),
      systemProgram: SystemProgram.programId,
      beacon: beaconAddress,
      beaconProgram: BEACON_PROGRAM_ID,
      walletTracker: walletTrackerAddress,
      trackedTokenRecord,
      instruction:
        Derivation.deriveInstructionCallbackAddress(trackedTokenRecord),
      thread: Derivation.deriveThreadAddress(walletTrackerAddress, threadIndex),
      clockwork: CLOCKWORK_PROGRAM_ID,
    })
    .instruction();
};

export const createIdentifierIx = async (
  ownerAddress: PublicKey,
  recoveryKey: PublicKey,
  identifier: PublicKey,
  programs: AlignPrograms,
): Promise<web3.TransactionInstruction> => {
  const identity = Derivation.deriveIdentityAddress(identifier);
  const ownerRecord = Derivation.deriveOwnerRecordAddress(ownerAddress);
  const node = Derivation.deriveNodeAddress(identity);
  return await programs.identifiersProgram.methods
    .initializeIdentifier(null)
    .accountsStrict({
      payer: programs.identifiersProgram.provider.publicKey,
      owner: ownerAddress,
      identity,
      ownerRecord,
      systemProgram: SystemProgram.programId,
      identifierMasterMint: identifier,
      node,
      recoveryKey,
      multigraph: programs.multigraphProgram.programId,
      tokenProgram: TOKEN_PROGRAM_ID,
    })
    .instruction();
};
