import { ExclamationCircleIcon, ForwardIcon } from "@heroicons/react/24/outline";
import { PublicKey, Transaction } from "@solana/web3.js";
import { addCouncilMemberInstruction, createProposalV2, removeCouncilMemberInstruction, setWalletThresholdInstruction } from "align-sdk";
import BN from "bn.js";
import { delay } from "lodash";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isCustomDomainStore } from "../../../state/domains";
import { proposalWizardGovernanceThresholdStepInputs, proposalWizardcouncilMembersStepInputs } from "../../../state/forms";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useIsCouncilMember, useOrganisation, useReputationValue } from "../../../state/hooks/useAlignGovernance";
import { alignLink } from "../../../utils/alignRoute";
import Avatar from "../../Avatar";
import { REFRESH_DELAY } from "../../../constants";
import { DescriptionPanel } from "../../DescriptionPanel";
import { WizardStepProps } from "../Wizard";

const ProposalWizardGovernanceReviewStep = ({
    currentStep,
    onComplete
}: WizardStepProps<{}>) => {
    const user = useAuth()
    const [organisationAddress] = useCurrentOrganisation()
    const { organisation } = useOrganisation(organisationAddress)
    const reputation = useReputationValue(user?.identity?.identifier)
    const isCouncil = useIsCouncilMember(user?.identity?.identifier)

    const thresholdInputs = useRecoilValue(proposalWizardGovernanceThresholdStepInputs)
    const councilInputs = useRecoilValue(proposalWizardcouncilMembersStepInputs)
    const navigate = useNavigate()
    const alignPrograms = useAlignPrograms()
    const isCustomDomain = useRecoilValue(isCustomDomainStore)

    const [councilToAdd, setcouncilToAdd] = useState();
    const [councilToRemove, setcouncilToRemove] = useState();

    const councilCount = useMemo(() => organisation?.councilManager && councilInputs ? organisation?.councilManager.council_count + councilInputs?.toAdd.length - councilInputs?.toRemove.length : 0, [organisation?.councilManager?.council_count,  councilInputs]);


    const handleCreateProposal = async (
    ) => {

        if((organisation 
            && organisation?.config?.proposalMinReputationGate !== null 
            && reputation < organisation?.config?.proposalMinReputationGate
            ) && !isCouncil){
                toast.error("You do not have enough reputation to create a proposal.");
                return;
            }
        if (!user?.identity) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>There is no user for the proposal being submited.</div>
                    <div>Please contact us if problems persist.</div>
                </div>);
            return;
        }

        if (!councilInputs) {
            toast.error("No Council found.");
            return;
        }

        if (!organisation) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>No organisation was found that matched the proposal being submited.</div>
                    <div>Please contact us if problems persist.</div>
                </div>
            );
            return;
        }

        if (!alignPrograms) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>Provider information was not found for instruction.</div>
                    <div>Please contact us if problems persist.</div>
                </div>
            );
            return;
        }

        // if(!gover?.walletConfig){
        //     toast.error("Please select a governance account to take the payout from if the proposal gets approved by the council.");
        //     return;
        // }
        toast.loading(
            <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                <div>Creating your proposal this may take some time.</div>
                <div>Please be patient.</div>
            </div>, {
            id: 'createProposal'
        })

        const addCouncilInstructionsPromise = councilInputs.toAdd.map(item => addCouncilMemberInstruction(
            new PublicKey(item.identifier),
            new PublicKey(organisation.address),
            alignPrograms
        ))

        const removeCouncilInstructionsPromise = councilInputs.toRemove.map(item => removeCouncilMemberInstruction(
            new PublicKey(item.identifier),
            new PublicKey(organisation.address),
            alignPrograms
        ))

        const addCouncilInstructions = await Promise.all(addCouncilInstructionsPromise)
        const removeCouncilInstructions = await Promise.all(removeCouncilInstructionsPromise)
        const walletConfig = organisation.wallets.find(config => config.walletAddress === organisation.councilManager?.authority)

        const setThresholdInstructionsPromises = thresholdInputs ? thresholdInputs.thresholdChanges
            .map(threshold => setWalletThresholdInstruction(
                threshold.newThreshold,
                new PublicKey(threshold.walletConfigAddress),
                new PublicKey(organisation.address),
                alignPrograms
            )) : []
        
        const setThresholdInstructions = await Promise.all(setThresholdInstructionsPromises)

        if (!walletConfig) {
            toast.error("Could not find wallet config");
            return;
        }

        const { sigs, proposalAddress } = await createProposalV2(
            new PublicKey(organisation.address),
            new PublicKey(user.identity.identifier),
            [new Transaction().add(...addCouncilInstructions, ...removeCouncilInstructions, ...setThresholdInstructions)],
            new PublicKey(walletConfig.address),
            new BN(0),
            {
                name: "",
                description: "",
            },
            {
                onCreatingDrive: () => toast.loading(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Creating a shadow drive to upload your proposal metadata to.</div>
                        <div>This may take some time..</div>
                    </div>, {
                    id: 'createProposal'
                }),
                onCreateDrive: (res) => toast.loading(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Drive created successfully..</div>
                        <div>Uploading metadata..</div>
                    </div>, {
                    id: 'createProposal'
                }),
                onUpload: (res) => toast.loading(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Upload complete!</div>
                        <div>Creating your proposal..</div>
                    </div>, {
                    id: 'createProposal'
                }),
                onUploadRetry: () => toast.loading(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Uploading metadata failed!</div>
                        <div>Trying again..</div>
                    </div>, {
                    id: 'createProposal'
                }),
                onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
                        <div>{`${res.count}/${res.total}: Sending Transactions..`}</div>
                        <div>Failed transactions will try again..</div>
                        <div>Please do not refresh or exit..</div>
                    </div>, {
                    id: "createProposal"
                }),
                onTransactionFailure: (res: any) => toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>Error:</div>
                    <div>{`${res}`}</div>
                </div>, {
                    id: "createProposal"
                })
            },
            alignPrograms,
            undefined
        )

        if (proposalAddress) {
            toast.success("Sucessfully created your proposal.", { id: 'createProposal' })
            delay(() => {
                // refreshProposals();
                navigate(alignLink(`/proposal/${proposalAddress.toBase58()}`, isCustomDomain, organisation?.address))
            }, REFRESH_DELAY);
        }
    }

    if (currentStep !== 2) {
        return <></>
    }
    return (
        <>
            <div className="proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full p-6 lg:col-span-2 sm:p-6 bg-secondary border-boxWidth flex flex-col gap-2">
                <h1 className="font-heading text-lg md:text-3xl mb-2">
              Review Council
            </h1>
            <DescriptionPanel message="Time to review your proposal before we process the transactions." />

                {/* <div className="flex gap-3">         */}
                <div className=" w-full flex text-sm mt-2 flex-col gap-5 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 rounded-md">
                    <label className="font-light text-contrast text-opacity-60">Add Council Members</label>
                    <div className="flex gap-4 flex-wrap">

                        {
                            councilInputs?.toAdd.length === 0 ? (
                                <p className="block text-xs font-light text-contrast text-opacity-40">
                            No Change
                            </p>
                            ) : (

                                councilInputs?.toAdd.map(item => (
                                    <div className="flex flex-col w-24 justify-center items-center gap-2">
                                        <div className="flex h-full items-center" >
                                            <Avatar userIdentifier={new PublicKey(item?.identifier)} size={12} />
                                        </div>
                                        <p className="flex flex-col h-full justify-center items-center">
                                            <span className="block text-xs font-light text-contrast text-opacity-40">
                                                {item?.username}
                                            </span>
                                        </p>
                                    </div>
                                ))
                            )}
                    </div>
                </div>

                <div className=" w-full flex text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 rounded-md">
                    <label className="font-light text-contrast text-opacity-60">Remove Council Members</label>
                    <div className="flex gap-4">

                        {
                        councilInputs?.toRemove.length === 0 ? (
                            <p className="block text-xs font-light text-contrast text-opacity-40">
                            No Change
                            </p>
                        ) : (
                            councilInputs?.toRemove.map(item => (
                            <div className="flex flex-col justify-center items-center gap-2">
                                <div className="flex h-full items-center">
                                <Avatar userIdentifier={new PublicKey(item?.identifier)} size={12} />
                                </div>
                                <p className="flex flex-col h-full justify-center items-center">
                                <span className="block text-xs font-light text-contrast text-opacity-40">
                                    {item?.username}
                                </span>
                                </p>
                            </div>
                            ))
                        )}
                    </div>

                </div>



                <div className=" w-full text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 rounded-md ">
                    {/* <label className="font-light t?ext-contrast text-opacity-60"></label> */}
                    <div className="py-1 flex-col w-full items-start flex flex-wrap gap-4">
                        {
                            thresholdInputs?.thresholdChanges.map(item => (
                                <div className="flex flex-col gap-2">
                                    <label className="font-light text-contrast text-opacity-60">{item.name} Governance</label>
                                    <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">{Math.floor(councilCount * item.newThreshold / 10000)} / {councilCount}</div>
                                </div>
                            ))
                        }
                    </div>

                </div>

                <div className="flex items-center gap-2 mt-3">
                    <ExclamationCircleIcon className="h-5 w-10 text-red-500"/>
                    <div className=" text-sm ">
                        To execute a proposal that contains a governance change there must be no proposals currently in council voting/review within the organisation.
                    </div>
                </div>

                <button
                    disabled={thresholdInputs?.thresholdChanges.length === 0 && !councilInputs?.toAdd.length && !councilInputs?.toRemove.length}
                    className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                        transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                    onClick={() => handleCreateProposal()}>
                    <ForwardIcon className={`w-4 `} />
                    Create Proposal
                </button>

            </div>




        </>
    );
}

export default ProposalWizardGovernanceReviewStep;