import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { CheckBadgeIcon, ChevronDownIcon, ChevronUpIcon, CogIcon, DocumentPlusIcon, ExclamationCircleIcon, HandThumbDownIcon, HandThumbUpIcon, PaperAirplaneIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PublicKey } from "@solana/web3.js";
import { CouncilVote, ProposalData, RankVoteType, attachProof, canPushRankingState, cancelDraftProposal, castCouncilVote, castRankVote, editDraftProposal, executeAllTransactions, finalizeDraftProposal, finishServicingProposal, reviewProposal } from "align-sdk";
import { delay } from "lodash";
import { useMemo, useState } from "react";
import { ThumbsDown, ThumbsUp } from "react-feather";
import toast from "react-hot-toast";
import { GetProposalQuery, useGetOrganisationQuery, useGetProposalCountQuery, useGetReputationQuery } from "../generated/graphql";
import { useAlignPrograms, useAuth } from "../state/hooks/useAlignGovernance";
import { ContributionRecordDto, CouncilVoteRecordDto } from "../types";
import { ProposalTransaction } from "../utils/alignHelpers";
import { ProofModal } from "./ProofModal";
import { Proposer } from "./Proposal";
import { REFRESH_DELAY } from "../constants";

export function ActionPanel(
    {
		proposalAddress,
		rankingTotal,
		proposer,
		servicer,
		shadow,
		upvotes,
		downvotes,
		rankingPeriod,
		rankingAt,
		state,
		organisationAddress,
		hasRanked,
		contributionRecord,
		councilVotes,
		transactions,
		metadata,
		serviceProofs,
		refetchProposal,
		hasGovernanceTransaction 
	} : {
		proposalAddress: string | undefined,
		state: string,
		proposer: Proposer | undefined | null,
		servicer: Proposer | undefined | null,
		rankingTotal: number,
		upvotes: number,
		downvotes: number,
		rankingPeriod: number,
		rankingAt: string | null | undefined,
		shadow: string,
		transactions: ProposalTransaction[]
		hasRanked: boolean,
		councilVotes : CouncilVoteRecordDto[],
		contributionRecord: ContributionRecordDto | null| undefined,
		organisationAddress: string,
		metadata : ProposalData,
		serviceProofs: string[],
		refetchProposal: () => Promise<ApolloQueryResult<GetProposalQuery>>,
		hasGovernanceTransaction : boolean
}
) {
	const user = useAuth()

	const organisation = useGetOrganisationQuery({
        variables: {
            address: organisationAddress,
			take: 0
        }
    })

	const proposalCount = useGetProposalCountQuery({
		variables: {
			orgAddress: organisationAddress,
			filter: ["Voting", "Reviewing", "Servicing", "Reviewed"],
		}
	})

	const client = useApolloClient()

	const proposerUserReputatoinResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: proposer?.userIdentityAddress || "",
            organisationAddress
        },
		skip: proposer?.userIdentityAddress === undefined
    })

	const reputationResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: user?.userIdentityAddress || "",
            organisationAddress
        },
		skip: proposer?.userIdentityAddress === undefined
    })

	const authorReputation = proposerUserReputatoinResponse.data?.reputation.totalReputation

    const alignPrograms = useAlignPrograms();
	const [showProofMoal, setshowProofMoal] = useState(false);

	const reputation = reputationResponse.data?.reputation.totalReputation
	
    const isCouncil = useMemo(() => organisation.data?.organisation.councilManager?.councilMembers.find(member => member.user.username === user?.username) !== undefined, [user?.username, organisation.data?.organisation.councilManager?.councilMembers, proposalAddress])

    const hasUpVoted = useMemo(() => hasRanked && contributionRecord?.upVoteCount > 0, [contributionRecord?.upVoteCount, user?.id])
	const hasDownVoted = useMemo(() => hasRanked && contributionRecord?.downVoteCount > 0, [contributionRecord?.downVoteCount, user?.id])
	const isProposer = useMemo(() => user?.identity?.identifier === proposer?.identity?.identifier, [user?.identity?.identifier, proposalAddress, proposer?.identity?.identifier])

    const canUpVote =  useMemo(() => 
										user && 
										(!contributionRecord ||
										(contributionRecord?.upVoteCount < reputationResponse.data?.reputation.totalReputation && !hasDownVoted)
										&& !isProposer
										), [contributionRecord?.upVoteCount, user?.id, hasDownVoted, hasUpVoted, isProposer])
	const canDownVote =  useMemo(() =>  
										user && 
										(contributionRecord === null ||
										(contributionRecord?.downVoteCount < reputationResponse.data?.reputation.totalReputation && !hasUpVoted)
										&& !isProposer
										), [contributionRecord?.downVoteCount, user?.id, hasDownVoted, hasUpVoted, isProposer])
	
	const isInCouncilVote = useMemo(() => 
										(state === "Ranking" &&
										rankingAt &&
										canPushRankingState(rankingPeriod, rankingAt, state)) || state === "Voting"
										,[proposalAddress, user?.identity?.identifier, state])

	
	const isInCouncilReview = useMemo(() => state === "Reviewing",[state])

	const canUserCouncilVote = useMemo(() => isInCouncilVote && isCouncil
										, [isInCouncilVote,  isCouncil])

	const usersCouncilVote = useMemo(() => councilVotes.find(vote => vote.user.username === user?.username), [councilVotes, proposalAddress, user?.id])

	const councilHasYesVoted = useMemo(() =>  usersCouncilVote?.vote === "Yes" , [user, usersCouncilVote, proposalAddress])
	const councilHasNoVoted = useMemo(() => usersCouncilVote?.vote === "No" , [user, usersCouncilVote, proposalAddress])
	const councilHasAbstainVoted = useMemo(() => usersCouncilVote?.vote === "Abstain" , [user, usersCouncilVote, proposalAddress])
    const councilHasReviewPositive = useMemo(() => usersCouncilVote && usersCouncilVote.review_score !== null && usersCouncilVote.review_score !== undefined &&  usersCouncilVote?.review_score > 0, [usersCouncilVote])
    const councilHasReviewNegative = useMemo(() => usersCouncilVote && usersCouncilVote.review_score !== null && usersCouncilVote.review_score !== undefined &&   usersCouncilVote?.review_score < 0, [usersCouncilVote ])
	const totalVotes = useMemo(() => contributionRecord?.downVoteCount + contributionRecord?.upVoteCount, [contributionRecord?.downVoteCount, contributionRecord?.upVoteCount])

    const isServicer = useMemo(() => user?.identity?.identifier === servicer?.identity?.identifier, [user?.identity?.identifier, proposalAddress])
											
    const finalizeProposal = async () => {
		if( !alignPrograms || !proposalAddress || !proposer?.identity?.identifier ) return;
			
			await toast.promise(
				finalizeDraftProposal(
					new PublicKey(proposalAddress),
					new PublicKey(proposer.identity.identifier),
					alignPrograms
				), {
				loading: "Finalizing your proposal...",
				success: "Proposal finalized successfully!",
				error: (e) => e.toString(),
			});
			delay(async () => {
				await client.resetStore()
				refetchProposal()
			}, REFRESH_DELAY)	}

	const cancelDraft = async () => {
		if( !alignPrograms || !proposalAddress || !proposer?.identity?.identifier ) return;
			
			await toast.promise(
				cancelDraftProposal(
					new PublicKey(proposalAddress),
					new PublicKey(proposer.identity.identifier),
					alignPrograms
				), {
				loading: "Canceling your proposal...",
				success: "Proposal canceled successfully!",
				error: (e) => e.toString(),
			});
			delay(async () => {
				await client.resetStore()
				refetchProposal()
			}, REFRESH_DELAY)
	}

	const editDraft = async () => {
		if( !alignPrograms || !proposalAddress || !proposer?.identity?.identifier ) return;
			
			await toast.promise(
				editDraftProposal(
					new PublicKey(proposalAddress),
					metadata,
					alignPrograms
				), {
				loading: "Saving your proposal...",
				success: "Proposal saved successfully!",
				error: (e) => e.toString(),
			});
			delay(
				async () => {
					await client.resetStore()
					await refetchProposal()
			}, REFRESH_DELAY)	}

    return (
        <div className="action-panel-container">
        {proposer  && proposer?.identity?.identifier === user?.identity?.identifier && state === "Draft" && (
			<p className="mt-4">
				<button
				onClick={() => editDraft()}
				className="w-full flex justify-center gap-2 items-center bg-accent hover:bg-opacity-80 rounded-button border-primary   transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
				>
				<PencilSquareIcon className="w-4"/>Save Draft
				</button>
			<div className="w-full flex gap-3">

				<button
				onClick={() => cancelDraft()}
				className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-red-500 hover:bg-opacity-80 rounded-button border-primary   transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
				>
				<TrashIcon className="w-4"/> Cancel Proposal
				</button>
				<button
				onClick={() => finalizeProposal()}
				className="w-full flex justify-center gap-2 items-center  self-end bg-accent hover:bg-opacity-80 rounded-button border-primary   transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
				>
				<CheckBadgeIcon className="w-4"/>Finalize Draft
				</button>

			</div>

			</p>
		)} 
		{ state === "Ranking" && rankingAt && !canPushRankingState(rankingPeriod, rankingAt, state) &&(
				<div className="flex gap-4 w-full justify-around">
					<button
						type="submit"
						onClick={async () => {
							if (
								!user?.identity ||
								!alignPrograms ||
								!proposalAddress
							) {
								return;
							}
							try {
								const sig = await toast.promise(castRankVote(
									new PublicKey(user.identity.identifier),
									new PublicKey(proposalAddress),
									RankVoteType.Upvote,
									contributionRecord? reputation - contributionRecord.upVoteCount : reputation,
									alignPrograms
								), {
									loading: "Voting for proposal.",
									success: "Vote created successfully!",
									error: (e) => e.toString(),
								})
								delay(
									async () => {
										await client.resetStore()
										await refetchProposal() 
									}, REFRESH_DELAY)	
				
							} catch (err) {
								console.error(err);
								return;
							}
						}}
						disabled={!canUpVote}

						className={`w-full flex justify-center gap-2 items-center mt-4 self-end rounded-button border-primary bg-accent border-buttonWidth border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${hasUpVoted ? "hover:border-green-400 border-green-400 hover:text-green-400 text-green-400 " : ""}`}
						>
							<ChevronUpIcon className={`w-4 rounded-md border ${hasUpVoted ? "border-green-400":"border-white"} `}/>
							Upvote
					</button>
					<button
						type="submit"
						onClick={async () => {
							if (
								!user?.identity ||
								!alignPrograms ||
								!proposalAddress
							) {
								return;
							}
							try {
								const sig = await toast.promise(castRankVote(
									new PublicKey(user.identity.identifier),
									new PublicKey(proposalAddress),
									RankVoteType.Downvote,
									contributionRecord? reputation - contributionRecord.downVoteCount : reputation,
									alignPrograms
								), {
									loading: "Voting for proposal.",
									success: "Vote created successfully!",
									error: (e) => e.toString(),
								})
								delay(
									async () => {
										await client.resetStore()
										await refetchProposal() 
									}, REFRESH_DELAY)	

								// Need to then update in the store. refresh proposal??						
							} catch (err) {
								console.error(err);
								return;
							}
						}}
						disabled={!canDownVote}
						className={`w-full flex justify-center gap-2 items-center mt-4 self-end rounded-button border-primary bg-accent border-buttonWidth border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${hasDownVoted ? "hover:border-red-400 border-red-400 hover:text-red-400 text-red-400" : ""}`}
						>
							<ChevronDownIcon className={`w-4 rounded-md border ${hasDownVoted ? "border-red-400":"border-white"} `}/>

							Downvote
					</button>
				
				</div>
		)}

{  proposer?.identity?.identifier === user?.identity?.identifier
	&& state === "Ranking" && rankingAt && !canPushRankingState(rankingPeriod, rankingAt, state) && 
	<button
			onClick={() => cancelDraft()}
			className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-red-500 hover:bg-opacity-80 rounded-button border-primary   transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
			>
			<TrashIcon className="w-4"/> Cancel Proposal
		</button>
}

{ canUserCouncilVote &&(
				<div className="flex gap-4 w-full justify-around">
					<button
						type="submit"
						onClick={async () => {

							if (
								!user?.identity ||
								!alignPrograms ||
								!proposalAddress
							) {
								return;
							}
							try {
								const sig = await toast.promise(castCouncilVote(
									new PublicKey(user.identity.identifier),
									new PublicKey(proposalAddress),
									CouncilVote.Yes,
									alignPrograms
								), {
									loading: "Voting for proposal.",
									success: "Vote created successfully!",
									error: (e) => e.toString(),
								})
								delay(
									async () => {
										await client.resetStore()
										await refetchProposal() 
									}, REFRESH_DELAY)	
								// console.log(sig)
								// Need to then update in the store. refresh proposal??						
							} catch (err) {
								console.error(err);
								return;
							}
						}}
						disabled={councilHasNoVoted || councilHasYesVoted }
						className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent  rounded-button border-primary hover:opacity-90 disabled:opacity-20
									transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${councilHasYesVoted ? "border-green-400" : ""}`}
						>
							<HandThumbUpIcon className={`w-4 `}/>
							Yes
					</button>
					<button
						type="submit"
						onClick={async () => {
							if (
								!user?.identity ||
								!alignPrograms ||
								!proposalAddress
							) {
								return;
							}
							try {
								const sig = await toast.promise(castCouncilVote(
									new PublicKey(user.identity.identifier),
									new PublicKey(proposalAddress),
									CouncilVote.No,
									alignPrograms
								), {
									loading: "Voting for proposal.",
									success: "Vote created successfully!",
									error: (e) => e.toString(),
								})
								delay(
									async () => {
										await client.resetStore()
										await refetchProposal() 
									}, REFRESH_DELAY)	

								// Need to then update in the store. refresh proposal??						
							} catch (err) {
								console.error(err);
								return;
							}
						}}
						disabled={councilHasNoVoted || councilHasYesVoted}
						className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-red-400 text-contrast rounded-button border-primary hover:opacity-90 disabled:opacity-20									
									transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${councilHasNoVoted ? "hover:border-red-400 border-red-400" : ""}`}
						>
							<HandThumbDownIcon className={`w-4 `}/>
							No
					</button>
				</div>
		)}
        {state === "Servicing" && (
				<div className="flex gap-4 w-full justify-around">
					<button
					type="submit"
					onClick={() => setshowProofMoal(true)}
					disabled={!isServicer}
					className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary hover:opacity-90 disabled:opacity-20
								transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${councilHasNoVoted ? "hover:border-red-400 border-red-400 hover:text-red-400 text-red-400 " : ""}`}
					>
						<DocumentPlusIcon className={`w-4 `}/>
						Attach Proof
				</button>
				<button
				type="submit"
				onClick={async () => {
					if (
						!user?.identity ||
						!alignPrograms ||
						!proposalAddress
					) {
						return;
					}
					try {
						const sig = await toast.promise(finishServicingProposal(
							new PublicKey(user.identity.identifier),
							new PublicKey(proposalAddress),
							alignPrograms
						), {
							loading: "Submiting serviced proposal",
							success: "Submitted serviced proposal successfully!",
							error: (e) => e.toString(),
						})
						// console.log(sig)
						delay(
							async () => {
								await client.resetStore()
								await refetchProposal() 
							}, REFRESH_DELAY)	
						// Need to then update in the store. refresh proposal??						
					} catch (err) {
						console.error(err);
						return;
					}
				}}
				disabled={!isServicer || serviceProofs.length === 0}
				className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary hover:opacity-90 disabled:opacity-20
							transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${councilHasNoVoted ? "hover:border-red-400 border-red-400 hover:text-red-400 text-red-400 " : ""}`}
				>
					<PaperAirplaneIcon className={`w-4 `}/>
					Submit Service
			</button>
			<ProofModal onClose={() => setshowProofMoal(false)} show={showProofMoal} onSubmit={async (proof) => {
					if (
						!user?.identity ||
						!alignPrograms ||
						!proposalAddress
					) {
						return;
					}
					try {
						const sig = await toast.promise(attachProof(
							new PublicKey(user.identity.identifier),
							new PublicKey(proposalAddress),
							proof,
							alignPrograms
						), {
							loading: "Attaching service proof to proposal",
							success: "Attaching service proof to proposal successfully!",
							error: (e) => e.toString(),
						})
						delay(
							async () => {
								await client.resetStore()
								await refetchProposal() 
							}, REFRESH_DELAY)	
					} catch (err) {
						console.error(err);
						return;
					}
			}}/>
		</div>

        )}
        {state === "Reviewing" && (
            <div className="flex gap-4 w-full justify-around">

                <button
                type="submit"
                onClick={async () => {
                    if (
						!user?.identity ||
						!alignPrograms ||
						!proposalAddress
                    ) {
                        return;
                    }
                    try {
                        const sig = await toast.promise(reviewProposal(
							new PublicKey(user.identity.identifier),
                            new PublicKey(proposalAddress),
                            1,
                            alignPrograms
                        ), {
                            loading: "Submiting review for serviced proposal",
                            success: "Submitted review successfully!",
							error: (e) => e.toString(),
                        })
                        // console.log(sig)
						 if(sig){
								delay(
								async () => {
									await client.resetStore()
									await refetchProposal() 
								}, REFRESH_DELAY)
							}
							else{
								toast.error("Error submitting serviced proposal.")
							}
                    } catch (err) {
                        console.error(err);
                        return;
                    }
                }}
                disabled={councilHasReviewPositive || councilHasReviewNegative || councilHasReviewPositive === null || councilHasReviewNegative === null || !isCouncil}
                className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary hover:opacity-90 disabled:opacity-20
                            transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${councilHasReviewPositive ? "hover:border-green-400 border-green-400 hover:text-green-400 text-green-400 " : ""}`}
                >
                    <ThumbsUp className={`w-4 `}/>
                    Review Service
            </button>
                    <button
                    type="submit"
                    onClick={async () => {
                        if (
							!user?.identity ||
							!alignPrograms ||
							!proposalAddress
                        ) {
                            return;
                        }
                        try {
                            const sig = await toast.promise(reviewProposal(
								new PublicKey(user.identity.identifier),
                                new PublicKey(proposalAddress),
                                -1,
                                alignPrograms
                            ), {
                                loading: "Submiting serviced proposal",
                                success: "Submitted serviced proposal successfully!",
								error: (e) => e.toString(),
                            })
                            if(sig){
								delay(
								async () => {
									await client.resetStore()
									await refetchProposal() 
								}, REFRESH_DELAY)
							}
							else{
								toast.error("Error submitting serviced proposal.")
							}
						
                        } catch (err) {
                            console.error(err);
                            return;
                        }
                    }}
                    disabled={councilHasReviewPositive || councilHasReviewNegative || councilHasReviewPositive === null || councilHasReviewNegative === null || !isCouncil}
                    className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary hover:opacity-90 disabled:opacity-20
                                transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${councilHasReviewNegative ? "hover:border-red-400 border-red-400 hover:text-red-400 text-red-400 " : ""}`}
                    >
                        <ThumbsDown className={`w-4 `}/>
                        Review Service 
                </button>
                
            </div>
        )}

    {(state === "Executing" || state === "ReadyToExecute") && (
		<>
		    { hasGovernanceTransaction && <div className="flex items-center gap-2">
				<ExclamationCircleIcon className="h-5 w-10 text-red-500"/>
				<div className=" text-sm ">
					To execute a proposal that contains a governance change there must be no proposals currently in council voting/review within the organisation.
				</div>
		 	</div>}
            <button
            type="submit"
            onClick={async () => {
                if (
					!user?.identity ||
					!alignPrograms ||
					!proposalAddress
                ) {
                    return;
                }
                // try {
                    const sig = await toast.promise(executeAllTransactions(
                        new PublicKey(proposalAddress),
						(sig : string, id : number) => {
							sig !== null ? console.log("Completed Executing proposal transaction", sig, id) : console.log("Skipping transaction as already executed", id)
						},
                        alignPrograms
                    ), {
                        loading: "Executing proposals transactions.",
                        success: "Executed proposals transactions successfully!",
						error: (e) => e.toString(),
                    })
					delay(
						async () => {
							await client.resetStore()
							await reputationResponse.refetch()
							await refetchProposal() 
						}, REFRESH_DELAY)	
                    // Need to then update in the store. refresh proposal??						
                // } catch (err) {
                //     console.error(err);
                //     return;
                // }
            }}
            disabled={(state !== "Executing" && state !== "ReadyToExecute") || ( hasGovernanceTransaction && (proposalCount.data?.proposals && proposalCount.data?.proposals.pageInfo.count > 0))}
            className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary hover:opacity-90 disabled:opacity-20
                        transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center`}
            >
                <CogIcon className={`w-4 `}/>
                Execute Proposals Transactions
        </button>
		</>
        )}

        
        </div>
    )

}