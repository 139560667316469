// import { useWallet } from "@solana/wallet-adapter-react";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
// import CouncilVotingPanel from "../components/CouncilVotingPanel";
// import PastProposalsPanel from "../components/PastProposalsPanel";
import { Proposal } from "../components/Proposal";
// import { ProposalSkeleton } from "../components/ProposalSkeleton";
import TreasuryPanel from "../components/TreasuryPanel";
import * as AlignConfig from "../align.config.js";
// import { gridLayout } from "../align.config.js";
import { themeOpts } from "../components/ThemeChanger";
import { useAtom } from "jotai";
import { ProposalSkeleton } from "../components/ProposalSkeleton";
import { useAuth, useCurrentOrganisation, useOrganisation } from "../state/hooks/useAlignGovernance";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import JoinOrgModal from "../components/joinOrgModal";
import { AlignLink } from "../components/AlignLink";
import { Account, Proposal as ProposalAccount } from "align-sdk";
import { Tab } from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { TreasuryPanelSkeleton } from "../components/TreasuryPanelSkeleton";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { proposalCursorState, proposalItemsPerPage} from "../state/alignGovernance";
import { useInfiniteScroll } from "../hooks/useInfiniteScroll";
import { ArrowLeftIcon, ArrowRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { useGetOrganisationQuery, useGetProposalsQuery, useGetReputationQuery } from "../generated/graphql";
import { isError } from "lodash";
import { Tooltip } from "antd";

export enum FilterType {
  User = "User",
  Ranking = "Ranking",
  CouncilReview = "Review",
  CouncilVoting = "CouncilVoting",
  Servicing = "Servicing",
  All = "All"
}

const tabIndex : Record<string, number> = {
  "Ranking" : 0,
  "CouncilVoting": 1,
  "Servicing" : 2,
  "Review": 3,
  "User": 4,
  "All": 5
}

export function Home() {
  const [modalJoinOpen, setModalJoinOpen] = useState(false)
  const [theme,] = useAtom(themeOpts)
  const { org,  } = useParams()

  const [searchParams, setSearchParams] = useSearchParams();
  const [organisationAddress] = useCurrentOrganisation()

  const user = useAuth()
  
  const reputation = useGetReputationQuery({
    variables: {
        userIdentityAddress: user?.userIdentityAddress || "",
        organisationAddress : organisationAddress || ""
    },
      skip: user?.userIdentityAddress === undefined || organisationAddress === undefined
    })

  const {data, error, loading,} = useGetOrganisationQuery({
    variables: {
      address: organisationAddress || "",
      take: 0
    },
    skip: organisationAddress === undefined
  })

  const isCouncil = useMemo(() => data?.organisation.councilManager?.councilMembers.find(member => member.user.username === user?.username) !== undefined, [user?.username, data?.organisation.councilManager?.councilMembers])

  const setCursor = useSetRecoilState(proposalCursorState)
  const [filter, _setfilter] = useState(searchParams.get("category") || FilterType.Ranking);

  const setfilter = useCallback((filterType : FilterType) => {
    _setfilter(filterType);
    setCursor([])
    setSearchParams({ category: filterType})
  }, [])


  useEffect(() => {
    const cat = searchParams.get("category")
    if(!cat){
      setSearchParams({ category: filter})
      return
    }
    _setfilter(cat)
  }, []);
  

  // if (!wallet?.publicKey) {
  //   return (
  //     <div className="max-w-6xl mx-auto px-4 py-8 text-contrast">
  //       <div className="flex justify-between items-start">
  //         Please connect your wallet to continue.
  //       </div>
  //     </div>
  //   );
  // }

  return (
    
    <div className="proposals-list-wrapper mx-auto max-w-screen-2xl  md:px-8 px-1 py-1 text-contrast">
      <div className="flex-wrap justify-between gap-6 lg:grid lg:grid-cols-3 mb-12">
        <main className={`proposals-container sm:w-full relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col w-full lg:col-span-2 ${theme.gridLayout && 'p-3 sm:p-6 bg-secondary bg-opacity-30 border-boxWidth'}`}>
          <div className="flex flex-wrap md:justify-between items-center md:flex-row flex-col gap-2 md:gap-0">
            <h1 className="font-heading text-lg md:text-3xl mb-1">
              Community Proposals
            </h1>
            
            <AlignLink path={`/proposal/create/wizard`} orgAddress={org} disabled={(reputation.data?.reputation?.totalReputation === 0 || reputation.data?.reputation?.totalReputation === undefined) && !isCouncil}>
              <Tooltip title="To create a proposal you must have some reputation in the organisation." >

                <button
                  disabled={(reputation.data?.reputation?.totalReputation === 0 || reputation.data?.reputation?.totalReputation === undefined) && !isCouncil} 
                  className={`w-full  md:w-fit bg-accent rounded-button border-primary text-contrast border-buttonWidth transition font-poppins font-base py-1 px-4 mb-1 text-center disabled:bg-white ${theme.mono ? 'border-contrast hover:bg-contrast hover:text-secondary' : 'border-primary hover:bg-opacity-80'}`}>
                  Create New Proposal
                </button>
              </Tooltip>

            </AlignLink>
          </div>

          {/* <div className="w-full flex justify-end">
            <button 
                onClick={() => setfilter(filter  === FilterType.Ranking ? FilterType.User : FilterType.Ranking)}
                className="top-5 right-6 flex justify-center gap-2 items-center rounded-button border-primary bg-accent border-buttonWidth border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs">{filter === FilterType.User ? "Community Proposals" : "Your Proposals"}</button>
          </div> */}
          <hr className="mt-6 opacity-30" />
          <Tab.Group selectedIndex={tabIndex[filter]}>
            <Tab.List className="w-full mt-5 flex justify-center">
    
              <div className="flex flex-wrap border border-contrast border-opacity-30 rounded-md p-2 md:gap-4 gap-1 w-full justify-between">
               <Tab defaultChecked={filter === FilterType.Ranking} onClick={() => setfilter(FilterType.Ranking)}
                className="whitespace-nowrap top-5 ui-not-selected:bg-transparent ui-not-selected:text-white right-6 flex justify-center gap-2 items-center rounded-md bg-accent border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 md:px-3 py-1.5 text-center text-xs">Current Proposals</Tab>
                <Tab defaultChecked={filter === FilterType.CouncilVoting} onClick={() => setfilter(FilterType.CouncilVoting)}
                className="whitespace-nowrap top-5 right-6 ui-not-selected:bg-transparent ui-not-selected:text-white flex justify-center gap-2 items-center rounded-md bg-accent border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs">{AlignConfig.strings.council ? AlignConfig.strings.council : 'Council'} Review</Tab>
                <Tab defaultChecked={filter === FilterType.Servicing} onClick={() => setfilter(FilterType.Servicing)}
                  className="whitespace-nowrap top-5 right-6 ui-not-selected:bg-transparent ui-not-selected:text-white flex justify-center gap-2 items-center rounded-md bg-accent border-contrast hover:opacity-80
                    transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs">Being Actioned</Tab>
          
                <Tab defaultChecked={filter === FilterType.CouncilReview} onClick={() => setfilter(FilterType.CouncilReview)}
                  className="whitespace-nowrap top-5 right-6 ui-not-selected:bg-transparent ui-not-selected:text-white flex justify-center gap-2 items-center rounded-md bg-accent border-contrast hover:opacity-80
                    transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs">Completion Review</Tab>
                <Tab defaultChecked={filter === FilterType.User} onClick={() => setfilter(FilterType.User)}
                  className="whitespace-nowrap top-5 right-6 ui-not-selected:bg-transparent ui-not-selected:text-white flex justify-center gap-2 items-center rounded-md bg-accent border-contrast hover:opacity-80
                    transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs">My Proposals</Tab>
                <Tab defaultChecked={filter === FilterType.All} onClick={() => setfilter(FilterType.All)}
                  className="whitespace-nowrap top-5 right-6 ui-not-selected:bg-transparent ui-not-selected:text-white flex justify-center gap-2 items-center rounded-md bg-accent border-contrast hover:opacity-80
                    transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs">All</Tab>
                  </div>
            </Tab.List>
          </Tab.Group>
          <div className="mt-4 flex justify-between text-md md:text-lg">
            <p className="ml-14 hidden md:block text-sm md:text-base">Proposals</p>
            <p className="w-[180px] hidden md:block text-sm md:text-base">Service Provider</p>
          </div>
          

          <Suspense fallback={ <ProposalSkeleton />}>
          {data && (
            <ProposalList org={data?.organisation.address} filterType={filter as FilterType} />
          )}
          </Suspense>
        </main>
        <aside className="w-full lg:col-span-1 mt-4 lg:mt-0">

          <Suspense fallback={<TreasuryPanelSkeleton />}>
            <>
              <TreasuryPanel container={true} />
              {/* <CouncilVotingPanel /> */}
              {/* <PastProposalsPanel /> */}
            </>
          </Suspense>
        </aside>
      </div>
      <JoinOrgModal onClose={() => setModalJoinOpen(false)} show={modalJoinOpen} />
    </div>
  );
}
const PaginationButtons = ({ proposalCount, lastAddress }: { proposalCount: number | undefined, lastAddress : string | undefined }) => {
  const [itemsPerPage, setItemsPerPage] = useRecoilState(proposalItemsPerPage);
  const [cursor, setCursor] = useRecoilState(proposalCursorState); // Assuming you created a Recoil state for the cursor.

  return (
      <div className="w-full flex justify-center gap-3 pt-4 items-center ">
          {/* Previous button: We don't set a cursor for going to the previous page. 
              For simplicity, we're assuming you can only paginate forward. 
              If you want bi-directional pagination, you'll need to manage a stack of cursors. */}
          <button
              disabled={!cursor} 
              onClick={() => {
                setCursor(stack => {
                const newStack = [...stack];
                newStack.pop();  // Remove the last cursor when going back
                return newStack;
              })}
            }
              className="hover:bg-contrast hover:bg-opacity-10 rounded-md p-1"
          >
              <ChevronDoubleLeftIcon className="w-6" />
          </button>

          <label>{/* Displaying current page index is trickier now since we're using cursors. 
                   For simplicity, this label is removed or you can manage page indexes explicitly. */}</label>

          {/* Next button */}
          <button
          disabled={ proposalCount ? Math.ceil(proposalCount / itemsPerPage) === cursor.length + 1 || proposalCount === 0 : false} 
          onClick={() =>  lastAddress ? setCursor(stack => [...stack, lastAddress]) : null} // Add new cursor for the next page
              className="hover:bg-contrast hover:bg-opacity-10 rounded-md p-1"
          >
              <ChevronDoubleRightIcon className="w-6" />
          </button>
      </div>
  );
}
 

export const ProposalListController = ({filterType, org}:{filterType : FilterType, org: string}) => {
  const [sortType, setSortType] = useState("RANK_DESCENDING");
  const [filter, setFilterType] = useState("All");

  const user = useAuth()

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortType(e.target.value);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterType(e.target.value);
  };


  return ( 
    <>
      <div className="w-full flex md:justify-end justify-center items-center gap-4 mt-3 mb-2">
        <div className="flex gap-1">
          <label className="text-sm">Sort by </label>
          <select  className="bg-contrast bg-opacity-10 text-sm" 
            value={sortType} onChange={handleSortChange}>
            <option value="RANK_DESCENDING">Rank</option>
            <option disabled value="REPUTATION_DESCENDING">Reputation</option>
          </select>
        </div>
        <div>
          <label className="text-sm">Filter by </label>
          <select className="bg-contrast bg-opacity-10 text-sm" 
            value={filter} 
            disabled={filterType !== FilterType.CouncilVoting && filterType !== FilterType.CouncilReview }
            onChange={handleFilterChange}>
            <option value="Unvoted">Unvoted</option>
            <option value="Voted">Voted</option>
            <option value="All">All</option>
          </select>
        </div>
      </div>
      <ProposalListAll organisation={org} filterType={filterType} voteFilter={{userAddress: user?.userIdentityAddress, type: filter}}/>

    </>
  );
}





export const ProposalListAll = ({filterType, organisation, voteFilter}:{filterType : FilterType, organisation: string, voteFilter? : any}) => {
  const user = useAuth()
  const filter = useMemo(() => {
    let f : string[] = []
    switch (filterType){
      case FilterType.Ranking : f = ["Ranking"]; break;
      case FilterType.CouncilReview : f = ["Executing", "ReadyToExecute", "Reviewing", "Reviewed"]; break;
      case FilterType.CouncilVoting : f = ["Voting"]; break;
      case FilterType.Servicing : f = ["Servicing"]; break;
      case FilterType.All : f = [];
    }
    if(voteFilter){
      f.push(voteFilter.type)
    }
    return f
  }, [filterType, voteFilter])

 
  const cursor = useRecoilValue(proposalCursorState); // Assuming you created a Recoil state for the cursor.

  const {data, loading, error} = useGetProposalsQuery({
    variables: {
      orgAddress: organisation,
      proposerAddress: filterType === FilterType.User ? user?.identity?.identifier : undefined,
      take: 10,
      userIdentifier: user?.identity?.identifier || "",
      filter,
      councilVoter : voteFilter ? voteFilter.userAddress : undefined,
      cursor: cursor.length > 0 ? cursor[cursor.length - 1] : undefined 
    }
  })

  // Determine the address of the last proposal in the current list for use as the cursor for pagination.
  const lastProposalAddress = data?.proposals.proposals[data.proposals.proposals.length - 1]?.address;

  if( loading){
    return <ProposalSkeleton/>
  }
  return ( <>

    {data && data.proposals.proposals.length > 0 ? data?.proposals.proposals.map((prop) => (
      <Suspense key={prop.address} fallback={<ProposalSkeleton/>}>
            <Proposal 
              key={prop.address} 
              address={prop.address} 
              state={prop.state} 
              proposer={prop?.proposer} 
              servicer={prop?.servicer} 
              rankingTotal={prop?.rankingTotal} 
              upvotes={prop.upvotes} 
              downvotes={prop.downvotes} 
              rankingPeriod={prop.rankingPeriod} 
              rankingAt={prop?.rankingAt} 
              shadow={prop?.shadowDrive} 
              organisationAddress={prop?.organisationAddress}
              transactions={prop?.proposalTransactions}
              contributionRecord={prop?.hasRankVotes?.contributionRecord}   
              hasRanked={prop?.hasRankVotes?.hasRanked} 
              />
      </Suspense>

    ))
      
     : (
      <p className="box-container flex flex-col justify-center text-center rounded-box bg-contrast bg-opacity-5 p-5 text-contrast mt-5">No proposals currently open for vote</p>
    )}
    <PaginationButtons lastAddress={lastProposalAddress} proposalCount={data?.proposals.pageInfo.count}/>

  </> );
}

// export const ProposalListReview = ({filterType}:{filterType : FilterType}) => {
//   const [organisation] = useCurrentOrganisation()
//   const proposals = useRecoilValue(reviewProposalAccounts(organisation))
//   const proposalCount = useRecoilValue(votingProposalCount(organisation))
  
//   if(filterType !== FilterType.CouncilReview){
//     return <></>
//   }
//   return ( <>
//     {proposals && proposals.length ? [...proposals]?.sort((a, b) => a.account.upvotes.sub(a.account.downvotes).gt(b.account.upvotes.sub(b.account.downvotes)) ? 1 : 0).map((proposal) => (
//       <Proposal proposal={proposal} key={proposal.address.toBase58()} />
      
//     )) : (
//       <p className="box-container flex flex-col justify-center text-center rounded-box bg-contrast bg-opacity-5 p-5 text-contrast mt-5">No proposals currently open for vote</p>
//     )}
//     <PaginationButtons proposalCount={proposalCount}/>

//   </> );
// }

// export const ProposalListRanking = ({filterType}:{filterType : FilterType}) => {
//   const [organisation] = useCurrentOrganisation()
//   const proposals = useRecoilValue(rankingProposalAccounts(organisation))
//   const proposalCount = useRecoilValue(rankingProposalCount(organisation))

//   if(filterType !== FilterType.Ranking){
//     return <></>
//   }
  
//   return ( <>
//     { proposals && proposals.length ? [...proposals]?.sort((a, b) => a.account.upvotes.sub(a.account.downvotes).gt(b.account.upvotes.sub(b.account.downvotes)) ? 1 : 0).map((proposal) => (
//         <Proposal proposal={proposal} key={proposal.address.toBase58()} />     
//     )) : (
//       <p className="box-container flex flex-col justify-center text-center rounded-box bg-contrast bg-opacity-5 p-5 text-contrast mt-5">No proposals currently open for vote</p>
//     )}
//      <PaginationButtons proposalCount={proposalCount}/>



//   </> );
// }

// export const ProposalListServicing = ({filterType}:{filterType : FilterType}) => {
//   const [organisation] = useCurrentOrganisation()
//   const proposals = useRecoilValue(servicingProposalAccounts(organisation))
//   const proposalCount = useRecoilValue(servicingProposalCount(organisation))

//   if(filterType !== FilterType.Servicing){
//     return <></>
//   }
  
//   return ( <>
//     { proposals && proposals.length ? [...proposals]?.sort((a, b) => a.account.upvotes.sub(a.account.downvotes).gt(b.account.upvotes.sub(b.account.downvotes)) ? 1 : 0).map((proposal) => (
//         <Proposal proposal={proposal} key={proposal.address.toBase58()} />     
//     )) : (
//       <p className="box-container flex flex-col justify-center text-center rounded-box bg-contrast bg-opacity-5 p-5 text-contrast mt-5">No proposals currently open for vote</p>
//     )}
//      <PaginationButtons proposalCount={proposalCount}/>



//   </> );
// }
// export const ProposalListUserProposed = ({filterType}:{filterType : FilterType}) => {
//   const [organisation] = useCurrentOrganisation()
//   const proposals = useRecoilValue(userProposedProposals(organisation))
//   const proposalCount = useRecoilValue(servicingProposalCount(organisation))

//   if(filterType !== FilterType.User){
//     return <></>
//   }
  
//   return ( <>
//     { proposals && proposals.length ? [...proposals]?.sort((a, b) => a.account.upvotes.sub(a.account.downvotes).gt(b.account.upvotes.sub(b.account.downvotes)) ? 1 : 0).map((proposal) => (
//         <Proposal proposal={proposal} key={proposal.address.toBase58()} />     
//     )) : (
//       <p className="box-container flex flex-col justify-center text-center rounded-box bg-contrast bg-opacity-5 p-5 text-contrast mt-5">No proposals currently open for vote</p>
//     )}
//      <PaginationButtons proposalCount={proposalCount}/>



//   </> );
// }


export function ProposalList({org, filterType, voteFilter}:{org: string, filterType : FilterType, voteFilter? : {type: "Voted" | "Unvoted", userAddress: string}} ) {

  return <>
  
    <ProposalListController org={org} filterType={filterType}/>


    
  </>
}