import { useNavigate } from "react-router-dom";
import { useCurrentOrganisation } from "../state/hooks/useAlignGovernance";
import { useStyle } from "../state/hooks/useStyle";
import alignByPhaseLabs from "../assets/alignbyphaselabs.svg";
import { useMemo } from "react";
import { isCustomDomainStore } from "../state/domains";
import { useRecoilValue } from "recoil";


const AlignLogo = () => {

    const [org] = useCurrentOrganisation()
    const style = useStyle(org)
    const isCustomDomain = useRecoilValue(isCustomDomainStore)
    
    return (
        <>
            <img
                alt="align by phase labs"
                className="w-[50px] object-cover object-left h-[40px] overflow-hidden sm:object-contain sm:h-16 max-w-[30vw] md:max-w-[240px] my-auto sm:w-auto"
                src={style.config?.logo && isCustomDomain ? style.config.logo : alignByPhaseLabs}
            ></img>

            {!style.config.logo || !isCustomDomain && (
                <span className="absolute top-0 right-3 translate-x-full bg-accent text-contrast text-[10px] font-bold ml-3 px-2 py-1 rounded-full">ALPHA</span>
            )}
        </>
    )
}

export default AlignLogo;