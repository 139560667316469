import { atomFamily, selectorFamily } from "recoil";

export const queryCoinPrice = selectorFamily<
  number | undefined,
  string | undefined
>({
  key: "coins/priceQuery",
  get:
    (mint: string | undefined) =>
    async ({ get }: any) => {
      try {
        const priceResponse = await fetch(
          `https://public-api.birdeye.so/public/price?address=${mint}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        );
        if (priceResponse.ok) {
          const priceJson: { data: { value: number } } =
            await priceResponse.json();
          return priceJson.data.value;
        } else {
          return 0;
        }
      } catch (e) {
        // const priceResponse = await fetch(
        //     "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd",
        //     {
        //         headers: {
        //             Accept: "application/json",
        //             "Content-Type": "application/json",
        //         },
        //     }
        // );
        // if (priceResponse.ok) {
        //     const priceJson: { solana: { usd: number } } =
        //         await priceResponse.json();
        //     return priceJson.solana.usd;
        // } else {
        //     return 0;
        // }
        console.log("Issue fetching price for token", mint);
      }
    },
});

export const coinPriceState = atomFamily<
  number | undefined,
  string | undefined
>({
  key: "coins/price",
  default: (mint: string | undefined) => queryCoinPrice(mint),
});
