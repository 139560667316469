import DescriptionStep from "./steps/DescriptionStep";
import ProposalWizardReviewStep from "./steps/ProposalWizardReviewStep";
import ProposalWizardServicerStep from "./steps/ProposalWizardServicerSetp";
import { Wizard, WizardStepProps } from "./Wizard";

const ProposalWizardServiceBased = (
) => {
    return( 
        <>
           <Wizard>
                {
                    (props : WizardStepProps<{}>) => (
                        <>
                            <DescriptionStep {...props} />
                            <ProposalWizardServicerStep {...props}/>
                            <ProposalWizardReviewStep {...props}/>
                        </>
                    )
                }
       
            </Wizard>
        </> 
    );
}
 
export default ProposalWizardServiceBased;