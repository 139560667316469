import { PublicKey } from "@solana/web3.js"
import { useEffect, useMemo } from "react"
import { useCurrentOrganisation, useOrganisation, useWalletConfigs, useCurrentUsersCouncilVoteRecord, useProposal, useAlignPrograms } from "../state/hooks/useAlignGovernance"
import { ProgressBar } from "./ProgressBar"
import { Link, useParams } from "react-router-dom"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { Tooltip } from "antd"
import { CouncilVote, CouncilVoteRecordDto } from "../types"
import MintProfilePicture from "./MintProfilePicture"


export const CouncilReviewProgress = ({
    walletConfig,
    userVoteDirection,
    councilVoteRecord
}: {
    walletConfig : string,
    userVoteDirection : CouncilVote | undefined,
    councilVoteRecord : CouncilVoteRecordDto[]
}) => {

    const [currentOrg] = useCurrentOrganisation()
    const { proposalAddress } = useParams()
    const proposal = useProposal(proposalAddress)
    const {organisation, loading, error, refetchOrg} = useOrganisation(currentOrg)


    const yesVotes = useMemo(() => {
        return councilVoteRecord.filter(x => x.review_score === 1)
    }, [councilVoteRecord])

    const noVotes = useMemo(() => {
        return councilVoteRecord.filter(x => x.review_score === -1)
    }, [councilVoteRecord])

    const abstainVotes = useMemo(() => {
        return councilVoteRecord.filter(x => x.vote === null)
    }, [councilVoteRecord])

    const threshold = useMemo(() => {
        if(!organisation?.councilManager?.council_count) return 
        const threshold = organisation?.wallets.find(x => x.address === walletConfig)?.threshold
        if(threshold){
            const minWallets = Math.ceil((threshold / 10000) * organisation?.councilManager?.council_count)
            return minWallets
        }
        return 0
    }, [organisation?.wallets.length, walletConfig, organisation?.councilManager?.council_count])
    return (
        <div className={`box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 p-5 text-contrast`}>
		    <div className="flex flex-col items-start justify-center">
                <div className="flex items-center justify-between w-full mb-4">
				    <label className="text-lg block font-semibold">Council Review</label>
                    {threshold && proposal?.account.councilReviewCount && (threshold - proposal?.account.councilReviewCount > 0) && (
                        <Tooltip title="Additional Reviews Required" placement="left">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500 cursor-pointer"/>
                        </Tooltip>
                    )}
                </div>
                {/* <div className="flex w-full justify-between h-full gap-5 items-center flex-wrap px-5">
                    <div className="flex w-fit xxl:w-full flex-col justify-center items-center">
                        <label className="font-bold text-md">{proposal?.account.councilReviewCount}</label>
                        <label className="font-light text-xs">Reviews Made</label>
                    </div>
                    {proposal?.account.councilReviewRating && (
                    <div className="flex w-fit xxl:w-full flex-col justify-center items-center">
                        <label className="font-bold text-md">{proposal?.account.councilReviewRating > 0 ? <span>PASSING</span> : <span>FAILING</span>}</label>
                        <label className="font-light text-xs">Review Rating</label>
                    </div>
                    )}
                    <div className="flex w-fit xxl:w-full flex-col justify-center items-center">
                        <label className="font-bold text-md">{threshold} / {organisation?.councilManager?.council_count}</label>
                        <label className="font-light text-xs">Quorum</label>
                    </div>
                </div> */}
                <div className="flex w-full justify-between h-full gap-5 items-center flex-wrap pl-5">
                    <div className="flex w-fit xxl:w-full flex-col justify-center items-center pr-5">
                        <label className="font-bold text-md">{threshold} / {organisation?.councilManager?.council_count}</label>
                        <label className="font-light text-xs">Threshold</label>

                        {threshold && (
                            <>
                                <label className="text-left font-light text-xs mt-4">Approve with {threshold - yesVotes.length} Yes</label>
                                <label className="text-left font-light text-xs mt-1">Decline with {threshold - noVotes.length} No</label>
                            </>
                        )}
                    </div>
                    <div className={`flex flex-col flex-1 gap-2 justify-center`}>
                        <div className={`relative flex flex-row w-full justify-center items-center gap-1 bg-white rounded-md bg-opacity-10 px-3 py-2  ${userVoteDirection !== undefined && userVoteDirection === "Yes" ? "border-primary text-primary" : "border-contrast border-opacity-30"}`}>
                            <label className="text-sm">Yes -</label>
                            <label className="font-bold text-md">{yesVotes.length}</label>
                            <div className="flex -space-x-2 ml-auto">
                                {yesVotes.map(vote => (
                                    <Link to={`/u/${vote.user.username}`}>
                                        <MintProfilePicture mint={vote.user.pfp} width={8} alt={vote.user.displayName}/>
                                    </Link>
                                ))}
                            </div>

                            {threshold && (
                                <div className="absolute -bottom-2 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700 overflow-hidden mt-6 mb-2">
                                    <div className="bg-accent h-1 rounded-full" style={{width: `${yesVotes.length/threshold*100}%`}}></div>
                                </div>
                            )}
                        </div>
                        <div className={`relative flex flex-row w-full justify-center items-center gap-1 bg-white rounded-md bg-opacity-10 px-3 py-2  ${userVoteDirection !== undefined && userVoteDirection === "Yes" ? "border-primary text-primary" : "border-contrast border-opacity-30"}`}>
                            <label className="text-sm">No -</label>
                            <label className="font-bold text-md">{noVotes.length}</label>
                            <div className="flex -space-x-2 ml-auto">
                                {noVotes.map(vote => (
                                    <Link to={`/u/${vote.user.username}`}>
                                        <MintProfilePicture mint={vote.user.pfp} width={8} alt={vote.user.displayName}/>
                                    </Link>
                                ))}
                            </div>

                            {threshold && (
                                <div className="absolute -bottom-2 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700 overflow-hidden mt-6 mb-2">
                                    <div className="bg-red-400 h-1 rounded-full" style={{width: `${noVotes.length/threshold*100}%`}}></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}