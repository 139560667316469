import { PowerIcon, WalletIcon } from "@heroicons/react/24/outline";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletModalButton } from "@solana/wallet-adapter-react-ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useCurrentOrganisation, useReputationValue } from "../state/hooks/useAlignGovernance";
import { truncateKey } from "../utils/truncateKey";
import Avatar from "./Avatar";
import { useRecoilValue } from "recoil";
import { isCustomDomainStore } from "../state/domains";
import { useGetReputationQuery } from "../generated/graphql";
import MintProfilePicture from "./MintProfilePicture";
import { clearJwtToken } from "../lib/auth";

export const AlignUserProfileButton = () => {

    const { publicKey, wallet, disconnect } = useWallet();
    const userInfo = useAuth()
    const navigate = useNavigate()
    const isCustomDomain = useRecoilValue(isCustomDomainStore)
    const [organisationAddress] = useCurrentOrganisation()

    const reputationResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: userInfo?.userIdentityAddress || "",
            organisationAddress : organisationAddress || "" 
        },
        skip: userInfo?.userIdentityAddress === undefined || organisationAddress === undefined
    })


    useEffect(() => {
        if (publicKey !== null && (!userInfo?.profileIsLoading && !userInfo?.displayName)) {
            navigate(`/u/${publicKey.toString()}/create`)
        }

        userInfo.signOut()

    }, [userInfo?.hasProfile, publicKey?.toString()])


    if (!wallet) return (
        <div className="wallet-connect cursor-pointer flex items-center gap-3 bg-accent rounded-lg h-12 w-fit px-5">
            <WalletIcon className="w-5 h-5"/>
            <WalletModalButton style={{ height: "3rem", padding: "0rem", fontFamily: "Poppins, sans-serif", fontWeight: "normal", background: "transparent", color: "black" }}/>

        </div>
    )
    if (!publicKey) return <AlignWalletConnectButton />


    return (
        <>
            <div
                onClick={() => {
                    if (!userInfo?.identity) return
                    navigate(
                        "/u/" +
                        userInfo?.identity?.identifier
                    );
                }}
                className="wallet-connect cursor-pointer flex bg-accent rounded-lg items-center gap-2 h-12 w-fit px-2 sm:px-4 py-1"
            >
                {/* <User className="inline-block h-5" />{" "} */}
                <div className="hidden sm:block">
                    <MintProfilePicture mint={userInfo?.pfp} width={8} />
                </div>
                <div className="text-sm sm:text-base">
                    {userInfo?.displayName ? userInfo?.displayName : truncateKey(publicKey.toBase58())}
                </div>
                {userInfo && reputationResponse.data?.reputation && isCustomDomain && (

                <button 
                    className="text-xs border-x border-accent border-opacity-10 bg-opacity-20 rounded-full bg-black text-contrast px-2 py-1">
                            <span className="block ">{reputationResponse?.data?.reputation.totalReputation} REP</span>
                    </button>
                )}

                <PowerIcon onClick={(e) => {
                    e.stopPropagation()
                    disconnect()
                }} className="w-6 p-1 bg-black bg-opacity-20 hover:bg-gray-900 hover:bg-opacity-40 rounded-full" />

            </div>
        </>
    )
}

export const AlignWalletConnectButton = () => {

    return (
        <button
            className="wallet-connect h-12 min-w-24 w-fit flex bg-accent rounded-lg items-center gap-3 px-5 text-contrast"
        >
            <div
                className="inline-block h-4 w-4 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-white align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
                role="status">
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Connecting... </span>
                
            </div>
        </button>
    )
}
