import { useCallback, useState } from "react"
import { SummaryStep } from "./steps/SummaryStep"
import { OrganisationConfigStep } from "./steps/OrganisationsConfigStep"
import { ThresholdsStep } from "./steps/ThresholdsStep"
import { CouncilStep } from "./steps/CouncilStep"
import { OrganisationProfileStep } from "./steps/OrganisationProfileStep"

type CreateOrganizationWizardStep = "Organisation Profile" | "Council Members" | "Thresholds" | "Organisation Config" | "Summary"

export const titleToStepMap: Record<CreateOrganizationWizardStep, number> = {
    "Organisation Profile" : 0,
    "Council Members" : 1,
    "Thresholds" : 2,
    "Organisation Config" : 3,
    "Summary" : 4
}



export function CreateOrganisationWizard(){
    const [currentStep, setCurrentStep] = useState(0)

    const nextStep = useCallback(() => {
        if (currentStep === 4){
            return
        }

        setCurrentStep(currentStep + 1)
        
    }, [currentStep])

    const previousStep = useCallback(() => {
        if(currentStep === 0){
            return
        }

        setCurrentStep(currentStep -1)
    }, [currentStep])

    return(
        <div className="mx-auto max-w-screen-2xl px-8 py-8 text-contrast">
            <div className="flex-wrap flex justify-between gap-6 max-w-xl mx-auto">            

                {currentStep === titleToStepMap["Organisation Profile"] && <OrganisationProfileStep onComplete={nextStep} />}

                {currentStep === titleToStepMap["Council Members"] && <CouncilStep onComplete={nextStep} goBack={previousStep} />}
                
                {currentStep === titleToStepMap["Thresholds"] && <ThresholdsStep onComplete={nextStep} goBack={previousStep} />}

                {currentStep === titleToStepMap["Organisation Config"] && <OrganisationConfigStep onComplete={nextStep} goBack={previousStep} />}

                {currentStep === titleToStepMap["Summary"] && <SummaryStep onComplete={nextStep} goBack={previousStep} setCurrentStep={setCurrentStep} />}
            </div>
        </div>
    )
}