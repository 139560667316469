module.exports = {
    logo: '',
    gridLayout: true,
    mono: false,
    primary: '',
    secondary: '',
    contrast: '',
    accent: '',
    strings: {
        council: '',
    }
};