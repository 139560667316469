import { ForwardIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid"
import { web3 } from "@project-serum/anchor"
import { TOKEN_2022_PROGRAM_ID, getMint } from "@solana/spl-token"
import { useConnection } from "@solana/wallet-adapter-react"
import { PublicKey, SystemProgram } from "@solana/web3.js"
import { Account, Api, AuthorityConfigType, USDC_MINT_ADDRESS, WalletConfig } from "align-sdk"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useSetRecoilState } from "recoil"
import { SCAM_MINTS } from "../../../constants"
import { TreasuryOption } from "../../../pages/CreateProposal"
import { getTokenAccountsByAddress } from "../../../pages/Profile"
import { proposalWizardServicerStepInputs } from "../../../state/forms"
import { useAlignPrograms, useAuth, useCurrentOrganisation, useOrganisation } from "../../../state/hooks/useAlignGovernance"
import { truncateKey } from "../../../utils/truncateKey"
import Avatar from "../../Avatar"
import { Tooltip } from "antd"
import { WalletConfigDto } from "../../../types"
import { useAtom } from "jotai"
import { themeOpts } from "../../ThemeChanger"
import { WizardStepProps } from "../Wizard"


const ProposalWizardServicerStep = ({
    currentStep,
    onComplete
} : WizardStepProps<{}>) => { 
const {connection} = useConnection()
const alignPrograms = useAlignPrograms()
const [organisationAddress] = useCurrentOrganisation()
const [theme, setTheme] = useAtom(themeOpts);


const {organisation, refetchOrg} = useOrganisation(organisationAddress)


const [userIdentifier, setuserIdentifier] = useState<PublicKey | null>(null);
const [displayname, setdisplayname] = useState<string | null>(null);
const [notFound, setNotFound] = useState(false);
const [selectedWalletConfig, setSelectedWalletConfig  ] = useState<WalletConfigDto | null>(null)
const [selectedAddress, setselectedAddress] = useState<string>();
const [mint, setMint] = useState<PublicKey | null>(null)
const [mintDecimals, setMintDecimals] = useState<number | null>(null)

const [tokenAccounts, settokenAccounts] = useState<{
    pubkey: web3.PublicKey;
    account: web3.AccountInfo<web3.ParsedAccountData>;
}[]>([]);
const [tokenAccountListLength, settokenAccountListLength] = useState(9);
const [uiAmount, setUiAmount] = useState(0);

const isLoading = useMemo(() => displayname === null || userIdentifier === null && notFound === false, [displayname, userIdentifier])
const setInputs = useSetRecoilState(proposalWizardServicerStepInputs)

const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm<{
    handle : string    
}>()

const auth = useAuth()
const walletConfig = useMemo(() => organisation?.wallets?.find(x => x.name === "Treasury"), [organisation?.wallets])

const handle = watch("handle", '@phaselabs')
useEffect(()=> {
    if(!walletConfig){
        return
    }
    setSelectedWalletConfig(walletConfig)
    setselectedAddress(walletConfig?.walletAddress)
    
}, [walletConfig])

useEffect(() => {
    const fetchTokens = async () => {
        if(!organisation){
            return
        }
        try {
            const tokenPromises = organisation?.wallets.map(async treasuryConfig => await getTokenAccountsByAddress(treasuryConfig.walletAddress, connection))
            // const token22Promises = organisation?.wallets.map(async treasuryConfig => await getTokenAccountsByAddress(treasuryConfig.walletAddress, connection, TOKEN_2022_PROGRAM_ID))
            
            const tokenAccounts = await Promise.all(tokenPromises)
            // const token22Accounts = await Promise.all(token22Promises)
            const flattened = [...tokenAccounts].flatMap(acc => acc.value).filter(acc => !SCAM_MINTS.includes(acc.account.data.parsed.info.mint))
            settokenAccounts(flattened)
        }
        catch(e){
            console.warn(e)
        }
    }
    fetchTokens()

}, [organisation?.wallets])

useEffect(() => {
  const fetchProfile = async () => {
    if (!handle || !alignPrograms) {
      return;
    }

    const profile = await Api.fetchUserProfileByUsername(handle, alignPrograms);

    if (profile === null) {
      setNotFound(true);
      return;
    }

    setuserIdentifier(profile.account.identifier);
    setdisplayname(profile.account.profile.displayName);
    setNotFound(false);
  };

  fetchProfile();
}, [handle, alignPrograms]);

if(currentStep !== 1){
    return <></>
}


const onSubmit = async ({handle}: {
    handle : string
}) => {

    if (uiAmount === 0) {
    toast.error("Payout Amount cannot be empty");
    return;
    }
    
    if(!selectedWalletConfig){
        toast.error("Please select a governance account to take the payout from if the proposal gets approved by the council.");
        return;
    }

    let decimals = mintDecimals

    if(decimals === null){
        // Native sol address make decimals 9 points
        if(mint === null && selectedAddress === selectedWalletConfig.walletAddress){
           decimals = 9 
        }
        // If we have a mint but decimals are missing fetch them
        else if (mint !== null && selectedAddress){
            const mintinfo = await getMint(connection, mint, "confirmed")
            decimals = mintinfo.decimals
        }
        // Nothing is selected we have other problems
        else{
            toast.error("Could not retrieve mint decimals. Please contact us if problems persist.");
            return;
        }

    }
    const servicer = userIdentifier && displayname && handle ? {
        handle,
        displayname,
        identifier : userIdentifier?.toBase58()
    } : null
    
    console.log("Storing Council members inputs", handle, decimals, selectedWalletConfig, mint)
    setInputs({
        servicer,
        mint: mint ? mint.toBase58() : null,
        mintDecimals : decimals,
        uiAmount,
        walletConfig : selectedWalletConfig.address
    })
    onComplete({})

}

//     const handleClick = (e, handleSubmit) => {
//   e.preventDefault(); // Prevent the default form submission
 
//   } else {
//     handleSubmit(); // Manually trigger the form submission
//   }
// };

return (
<>
        <form onSubmit={handleSubmit(onSubmit)} onError={(e) => toast.error(e.toString()) }
            className={`proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col w-full lg:col-span-2 ${
                theme.gridLayout && "p-3 sm:p-6 bg-secondary bg-opacity-30 border-boxWidth"
              }`}>
            <h1 className="font-heading text-lg md:text-3xl mb-6">
              Payout Information
            </h1>
                <div className="mt-4">
                    <label>Servicer</label>
                </div>
                <div className="flex p-4 items-center gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center my-2"> 
                { userIdentifier ? 
                    <Avatar userIdentifier={userIdentifier} size={16} noBorder={true}/>
                    : 
                    <span className="h-24 w-24 rounded-full flex items-center justify-center border relative border-white border-opacity-30">
                        <svg className="w-12 h-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
                    </span>
                }
                    <div className="flex flex-col gap-1 justify-center">
                        <label className="text-sm">{displayname}</label>
                        <label className="text-sm font-light text-contrast text-opacity-70">{ userIdentifier ?  truncateKey(userIdentifier?.toBase58()) : null}</label>
                    </div>
                    <Tooltip title="Selecting a seperate user to complete the proposal service coming soon.." placement="top">
                        <div className=" mb-1 flex gap-2 justify-center items-start flex-1 bg-transparent border border-contrast border-opacity-10 rounded-full text-sm p-2 px-2 ">
                            <MagnifyingGlassIcon className="w-4 text-contrast text-opacity-20"/>
                            <span className=" bg-transparent flex-1 outline-none  text-left focus:outline-none text-contrast text-opacity-20">{auth?.username}</span>

                            {/* <input className="bg-transparent flex-1 outline-none focus:outline-none" {...register("handle", {
                                required: true,
                                pattern: /^@[a-zA-Z0-9_]{1,15}$/,

                                validate: {
                                        isAvailable : async (value : string) => {
            
                                            if(!alignPrograms){
                                                return false
                                            }
                                            
                                            const profile = await Api.fetchUserProfileByUsername(value, alignPrograms)
                                            return profile !== null ? true : "User does not exist."
                                        }
                                }
                            })} /> */}
                        </div>
                    </Tooltip>
                    {errors.handle?.type === 'isAvailable' && <span className="text-xs text-red-500 ">Username is not available</span>}
                    {errors.handle?.type === 'pattern' && <span className="text-xs text-red-500 ">Handle not valid</span>}

                </div>


                <label>Service Payout</label>
                <div className="flex flex-col p-4 items-center gap-6 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center mt-2"> 
                <label className="block text-sm mt-2 opacity-75 w-full flex-wrap">Treasury Wallet</label>
                <div className="flex flex-wrap justify-center md:justify-start items-center gap-3 mt-1 w-full">
                {  walletConfig &&     
                            <TreasuryOption 
                                key={walletConfig?.address}
                                name={walletConfig?.name}
                                isSelected={walletConfig.walletAddress === selectedAddress} 
                                treasuryWallet={walletConfig.walletAddress} 
                                onClick={(treasuryWallet, decimals, coinMeta) => {
                                    if(!treasuryWallet || decimals === null){
                                        return
                                    }
                                    
                                    setMintDecimals(decimals)
                                    setMint(null)
                                    setSelectedWalletConfig(walletConfig)
                                    setselectedAddress(walletConfig.walletAddress)
                                    //TODO
                                    // setTokenProgramId(undefined)
                                }}
                                tokenProgramId={SystemProgram.programId}

                            />
                    }
                   {tokenAccounts.sort((a,b) => a.account.data.parsed.info.mint === USDC_MINT_ADDRESS.toBase58() ? -1 : 1).filter(x => x.account.data.parsed.info.tokenAmount.uiAmount !== 0).slice(0, tokenAccountListLength).map((acc) => (
                        <TreasuryOption 
                            key={acc.pubkey.toBase58()}
                            name={""}
                            isSelected={acc.pubkey.toBase58() === selectedAddress} 
                            treasuryWallet={acc.account.data.parsed.info.owner} 
                            mint={new PublicKey(acc.account.data.parsed.info.mint)}
                            onClick={(treasuryWallet, decimals, coinMeta) => {

                                    if(!treasuryWallet || decimals === null){
                                        return
                                    }
                                    const account = organisation?.wallets.find(x => x.walletAddress === treasuryWallet)
                                    if(!account){
                                        return
                                    }
                                    setMintDecimals(decimals)
                                    setMint(coinMeta.mint)
                                    setSelectedWalletConfig(account)
                                    setselectedAddress(acc.pubkey.toBase58())
                                }} 
                            tokenProgramId={acc.account.owner}
                        />
                    ))}
                      
           
              </div>
                   <button className={`flex hover:border-accent hover:text-white items-center justify-center gap-1 flex-col rounded border
                          border-gray-700 "} p-1 text-gray-700 w-full mt-4`}
                        //create token accounts tx
                        onClick={(e) => {
                            e.preventDefault()
                            settokenAccountListLength(x => x + 9 >= tokenAccounts.length ? tokenAccounts.length : x + 9 )
                            // toast.error("Creating additional wallets for your DAO is a not avaliable at this time.") 
                            }
                        }
                        disabled={tokenAccounts.length <= tokenAccountListLength}
                        >
                            Load More 
                    {/* <Plus/> */}
                </button>
                <div className="flex w-full">
                    <label className="text-sm block mt-4 opacity-75 w-40">Payout Amount</label>
                    <input
                        value={uiAmount}
                        onChange={(e) => {
                            const number = Number(e.target.value)
                            if (!isNaN(number)){
                                setUiAmount(number)
                            }
            
                        }}
                        className="input block mt-1 p-3 w-full h-24"
                    />
                </div>
              </div>

              <button
                    className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${
                    uiAmount <= 0 ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    // onClick={handleClick}
                    type="submit"
                    
                    >
                    <ForwardIcon className={`w-4 `}/>
                    Next Step
            </button> 
        </form>
</>
)
}
 
export default ProposalWizardServicerStep;

