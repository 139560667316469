import { ChatBubbleLeftIcon, ChatBubbleLeftRightIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCreateCommentMutation, useGetCommentsQuery, useGetReputationQuery, useGetUserQuery, useVoteOnCommentMutation } from "../generated/graphql";
import { useAuth, useReputationValue } from "../state/hooks/useAlignGovernance";
import type { Comment as CommentType} from "../types";
import MintProfilePicture from "./MintProfilePicture";
import { useApolloClient } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { ScaleIcon } from "@heroicons/react/24/solid";
import LoadingSpinner from "./LoadingSpinner";
import { toast } from "react-hot-toast";
import { clearJwtToken, getJwtToken, login, setJwtToken } from "../lib/auth";
import { useWallet } from "@solana/wallet-adapter-react";
import SignInButton from "./SignIn";



export const Discussion = ({proposalAddress, organisationAddress, councilMembers} : {proposalAddress: string | undefined, organisationAddress : string, councilMembers : string[]}) => {
    
    const [commentParentId, setcommentParentId] = useState<string| null>(null);
    
    const {data, loading, error} = useGetCommentsQuery({
        variables: {
            proposalAddress: proposalAddress || "",
            commentParentId: commentParentId
        },
        skip: proposalAddress === undefined
    })


    const isReplyBranch = useMemo(() => commentParentId !== null, [commentParentId, proposalAddress])
    
    const user = useAuth()
    return (
        <>
        {proposalAddress && (
            
            <div className="flex flex-col gap-4 relative bg-opacity-30 p-5 text-contrast w-full">
                {loading && <LoadingSpinner/>}
                
                {/* <h1 className="zzz">Discussion</h1> */}
                {user?.userIdentityAddress && user?.displayName && !isReplyBranch && (
                    <CommentInput username={user.displayName} pfp={user?.pfp} proposal={proposalAddress} identityAddress={user?.userIdentityAddress}/>
                )}
               
                {data?.comments.map((x) => (
                    <div className="w-full">
                        {isReplyBranch && 
                            <div className="w-full flex justify-end items-end">
                                <button onClick={() => setcommentParentId(null)} className="p-1 mb-2 ml-1 flex gap-1  text-xs hover:bg-contrast hover:bg-opacity-10">
                                    <p className="text-xs w-full justify-center items-center"> close comment branch</p>
                                    <XMarkIcon className="w-4 text-contrast"/>

                                </button>
                            </div>
                        }
                        <Branch 
                            comment={x} 
                            organisationAddress={organisationAddress} 
                            proposal={proposalAddress} 
                            councilMembers={councilMembers}
                            setParentId={setcommentParentId}
                            />

                    </div>   
                ))}
            </div>
            )}
        </>
    )
}

export const Branch = ({
    comment, 
    organisationAddress, 
    proposal, 
    councilMembers,
    setParentId
    } : {
    comment: CommentType, 
    organisationAddress : string, 
    proposal: string, 
    councilMembers: string[],
    setParentId: (id: string) => void
    }) => {
    return (
        <>
            <Comment 
                id={comment.id}
                comment={comment.comment}
                pfp={comment.user.pfp}
                created_at={comment.created_at}
                totalVotes={comment.votesTotal}
                username={comment.user.username}
                displayName={comment.user.displayName}
                userIdentityAddress={comment.user.userIdentityAddress}
                organisationAddress={organisationAddress}
                proposal={proposal}
                councilMembers={councilMembers}
                hiddenReplyCount={0}
                setParentId={() => {}}

            />
            {
                comment.children.map(comment => (
                    <>
                    <div className="flex mt-2">

                        <div className="min-w-[24px]"/>
                        <Comment 
                        id={comment.id}
                        comment={comment.comment}
                        pfp={comment.user.pfp}
                        created_at={comment.created_at}
                        totalVotes={comment.votesTotal}
                        username={comment.user.username}
                        displayName={comment.user.displayName}
                        userIdentityAddress={comment.user.userIdentityAddress}
                        organisationAddress={organisationAddress}
                        proposal={proposal}
                        councilMembers={councilMembers}
                        hiddenReplyCount={0}
                        setParentId={setParentId}
                        />
                        
                    </div>
                    {
                        comment.children.map(childComment => (
                            <div className="flex">

                                <div className="min-w-[48px]"/>
                                <Comment 
                                id={childComment.id}
                                comment={childComment.comment}
                                pfp={childComment.user.pfp}
                                created_at={childComment.created_at}
                                totalVotes={childComment.votesTotal}
                                username={childComment.user.username}
                                displayName={childComment.user.displayName}
                                userIdentityAddress={childComment.user.userIdentityAddress}
                                organisationAddress={organisationAddress}
                                proposal={proposal}
                                councilMembers={councilMembers}
                                hiddenReplyCount={childComment.childrenCount}
                                setParentId={setParentId}
                                />
                            </div>
                    
                ))
            }
                    </>
                ))
            }
        </>
    )
}

export const CommentInput = ({
    proposal,
    pfp,
    username,
    parentCommentId,
    identityAddress,
    onClose = () => {}
}: {
    proposal : string,
    pfp : string | undefined | null,
    username: string,
    parentCommentId? : string,
    identityAddress: string,
    onClose?: () => void
}) => {
    const [comment, setComment] = useState("");
    const apollo = useApolloClient()
    const {publicKey, signMessage} = useWallet()
    const {isSignedIn} = useAuth()
    const [createCommentMutation, {data, loading, error}] = useCreateCommentMutation({
        variables: {
            proposalAddress : proposal,
            comment,
            parentCommentId
        }
    })
    
    return (
        <div className="flex  flex-col gap-2">
          
           <div className=" flex-col px-3 relative rounded-md border border-contrast border-opacity-20 p-2 bg-opacity-5 bg-contrast border-1">
           {
                !isSignedIn && <div 
                                className="flex justify-center items-center absolute left-0 top-0 bg-contrast bg-opacity-25 w-full h-full rounded-md"
                                >
                                    <SignInButton/> 
                                </div>
            }
                <div className="flex items-center gap-3 border-b pt-1 pb-3 border-opacity-20 border-contrast">
                    <MintProfilePicture mint={pfp} width={8}/>
                    <h2 className="text-contrast text-sm font-light text-opacity-70">{username}</h2>
                </div>

                <textarea
                        value={comment}
                        placeholder={"Discuss the proposal..."}
                        rows={4}
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                        className=" w-full font-thin bg-transparent bg-contrast pt-2 border-transparent !outline-none"
                        >
                </textarea>
            </div> 
            
            <button 
                disabled={loading || !isSignedIn}
                className=" flex self-end bg-accent px-4 py-1 rounded-md text-sm hover:bg-opacity-90"
                onClick={async () => {
                    try{
                        const res = await createCommentMutation()
                        console.log(res?.errors)
                        if(res?.errors?.length === undefined || res?.errors?.length === 0) {
                            setComment("")
                        }
                        else{
                            //@ts-ignore
                            toast.error(res?.errors[0])

                        }
                        onClose()
                        await apollo.resetStore()
                    }
                    catch(e : any){
                        if(e?.toString() === "ApolloError: Unauthorized" && publicKey && signMessage){
                            if(getJwtToken()){
                                clearJwtToken()     
                            }
                            login(publicKey, signMessage, process.env.REACT_APP_ALIGN_API_URL!!)
                        }
                        console.error(e)
                        toast.error(e?.toString())
                    }
                   
                }}
                >
                { !loading ? "Reply" : <div className="flex justify-center items-center w-full"><LoadingSpinner size="small"/></div>}
            </button>
        </div>
       
    )
}

export const CommentVoteButtons = ({
    totalVotes,
    id,
    hiddenRepliesCount,
    proposal,
    openBranch
}: {
    totalVotes: number,
    id: string,
    hiddenRepliesCount: number,
    proposal: string,
    openBranch : () => void 
}) => {
    
    const user = useAuth()
    const client = useApolloClient()
    const [showInput, setShowInput] = useState(false);
    const [voteUpMutation] = useVoteOnCommentMutation({
        variables: {
            id,
            voteType: "upvote"
        },
        onCompleted: () => client.resetStore()
    })

    const [voteDownMutation] = useVoteOnCommentMutation({
        variables: {
            id,
            voteType: "downvote"
        },
        onCompleted: () => client.resetStore()
    })


    return (
        <div className="flex flex-col gap-2">

        
            <div className="flex gap-2 items-center">
                <button 
                    className="p-1 border border-1 border-solid rounded-full disabled:opacity-20"
                    onClick={() => voteUpMutation()}
                    disabled={user?.displayName === undefined}

                    >
                    <ChevronUpIcon className="w-2 h-2"/>
                </button>
                <p className="text-sm">{totalVotes}</p>
                <button 
                    className="p-1 border border-1 border-solid rounded-full disabled:opacity-20"
                    onClick={() => voteDownMutation()}
                    disabled={user?.displayName === undefined}
                    >
                    <ChevronDownIcon className="w-2 h-2 "/>
                </button>
                <button 
                    className="p-1 flex ml-1 gap-1 items-center text-xs hover:bg-contrast hover:bg-opacity-10 "
                    disabled={user?.displayName === undefined}
                    onClick={() => setShowInput(!showInput)}
                    >
                    <ChatBubbleLeftIcon className="w-5 h-5 "/>
                    <p>Reply</p>
                </button>
            </div>
            {hiddenRepliesCount > 0 ? (
                <button className="flex ml-1 gap-1 py-1 items-center justify-center text-xs hover:bg-contrast bg-contrast bg-opacity-20 hover:bg-opacity-10" onClick={openBranch}>
                    <p className="text-xs">Open {hiddenRepliesCount} more replies</p>
                </button>
            ) : <></>}
            {showInput && user?.userIdentityAddress && user?.displayName && <CommentInput username={user.displayName} pfp={user?.pfp} onClose={() => setShowInput(false)} parentCommentId={id} identityAddress={user.userIdentityAddress} proposal={proposal}/>}
        </div>
    )
}
export const Comment = ({
    id,
    comment, 
    pfp, 
    created_at, 
    totalVotes,
    username,
    userIdentityAddress,
    displayName,
    organisationAddress,
    proposal,
    councilMembers,
    hiddenReplyCount,
    setParentId
}: {
    id: string,
    comment: string, 
    pfp: string | null | undefined
    created_at: string,
    totalVotes: number,
    username : string,
    displayName : string
    userIdentityAddress: string | null | undefined,
    organisationAddress: string,
    proposal: string,
    councilMembers : string[],
    hiddenReplyCount: number,
    setParentId?: (id: string) => void
}) => {

    const {data, loading, error} = useGetReputationQuery({
        variables: {
            userIdentityAddress: userIdentityAddress || "",
            organisationAddress
        },
        skip: !userIdentityAddress
    })

    const isCouncil = useMemo(() => councilMembers.includes(userIdentityAddress || ""), [councilMembers, userIdentityAddress])

    return ( 
        <div className="flex gap-2 w-full mb-2">
            <MintProfilePicture mint={pfp} width={8}/>
            <div className="flex flex-col gap-2 w-full border-l-contrast border-l-2 border-solid border-spacing-2 border-opacity-20 pl-2">
                <div className="flex gap-2 h-8 items-center">
                    <p className="text-xs">{username}</p>
                    <div className="flex items-center px-2 text-xs rounded-full bg-contrast bg-opacity-20">{data?.reputation.totalReputation || 0} rep</div>
                    {isCouncil && 
                        <div className="px-2 bg-accent rounded-full flex gap-1 text-xs">
                            <ScaleIcon className="w-4"/>
                            <p>Council</p>
                        </div>
                    }
                </div>
                <p className="text-sm">{comment}</p>
                <CommentVoteButtons openBranch={setParentId ? () => setParentId(id) : () => {}} hiddenRepliesCount={hiddenReplyCount} id={id} totalVotes={totalVotes} proposal={proposal}/>

            </div>
        </div> 
    );
}
