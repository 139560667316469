import { ClipboardDocumentIcon, DocumentTextIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { BN, web3 } from "@project-serum/anchor";
import { TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID, TokenInstruction, createTransferCheckedInstruction, decodeTransferCheckedInstruction, getMint } from "@solana/spl-token";
import { useConnection } from "@solana/wallet-adapter-react";
import { AccountMeta, LAMPORTS_PER_SOL, PublicKey, SystemInstruction, SystemProgram, Transaction } from "@solana/web3.js";
import { Api, BERN_MINT_ADDRESS, USDC_MINT_ADDRESS, createProposal, getAssociatedTokenAddressSync, getMetadataAddress } from "align-sdk";
import { delay } from "lodash";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useIsCouncilMember, useOrganisation } from "../state/hooks/useAlignGovernance";
// import { tokenTickers } from "../constants";
import { TokenStandard, metadataBeet } from "@metaplex-foundation/mpl-token-metadata";
import { u8 } from "@solana/buffer-layout";
import { Tooltip } from "antd";
import { useAtom } from "jotai";
import ReactMarkdown from 'react-markdown';
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import remarkGfm from 'remark-gfm';
import { fetchNfts } from "../api/nfts";
import Avatar from "../components/Avatar";
import CreateProfileModal from "../components/CreateProfileModal";
import { themeOpts } from "../components/ThemeChanger";
import JoinOrgModal from "../components/joinOrgModal";
import { REFRESH_DELAY, SCAM_MINTS } from "../constants";
import { useGetReputationQuery } from "../generated/graphql";
import { isCustomDomainStore } from "../state/domains";
import { HeliusGetAssetResponse, WalletConfigDto } from "../types";
import { alignLink } from "../utils/alignRoute";
import { truncateKey } from "../utils/truncateKey";
import { getTokenAccountsByAddress } from "./Profile";


export function CreateProposal() {
    const user = useAuth()
    const [organisationAddress] = useCurrentOrganisation()
    const {organisation, refetchOrg} = useOrganisation(organisationAddress)
    const [name, setName] = useState("");
    const [uiAmount, setUiAmount] = useState("0");
    const [mintDecimals, setMintDecimals] = useState<number | null>(null)
    const [mint, setMint] = useState<PublicKey | null>(null)
    const [theme, setTheme] = useAtom(themeOpts)
    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [selectedAddress, setselectedAddress] = useState<string>();
    const [showProfileModal, setShowProfileModal] = useState<boolean>(false)
    const [modalJoinOpen, setModalJoinOpen] = useState(false);
    const [dismissedModal, setDismissedModal] = useState(false);
    const [isUrgent, setIsUrgent] = useState(false);
    const [previewMarkdown, setPreviewMarkdown] = useState(false)
    const [mdPreview, setMdPreview] = useState({markdown: ''})
    const isCouncil = useIsCouncilMember(user?.identity?.identifier)
    const [tokenProgramId, setTokenProgramId] = useState<PublicKey| undefined>();
    const [description, setDescription] = useState("");
    const [selectedWalletConfig, setSelectedWalletConfig  ] = useState<WalletConfigDto| null>(null)
    const isCustomDomain = useRecoilValue(isCustomDomainStore)

    const reputationResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: user?.userIdentityAddress || "",
            organisationAddress : organisationAddress || "" 
        },
        skip: user?.userIdentityAddress === undefined || organisation === undefined

    })
    const reputation = reputationResponse?.data?.reputation.totalReputation

    const alignPrograms = useAlignPrograms()
    const navigate = useNavigate()
    const {connection} = useConnection()
    const [tokenAccountListLength, settokenAccountListLength] = useState(9);

    const [tokenAccounts, settokenAccounts] = useState<{
        pubkey: web3.PublicKey;
        account: web3.AccountInfo<web3.ParsedAccountData>;
    }[]>([]);
  
    const walletConfig = useMemo(() => organisation?.wallets?.find(x => x.name === "Treasury"), [organisation?.wallets])

    useEffect(() => {
        if(user?.hasProfile){
            setShowProfileModal(false)
        }
        setShowProfileModal(true)
    }, [user?.hasProfile])

    useEffect(() => {
        if( !dismissedModal ) {
            if(user?.hasProfile && reputation){
                setModalJoinOpen(true)
            } else {
                setModalJoinOpen(false)
            }
        }
    }, [user, dismissedModal])

    useEffect(()=> {
        if(!walletConfig){
            return
        }
        setSelectedWalletConfig(walletConfig)
        setselectedAddress(walletConfig?.walletAddress)
        
    }, [walletConfig])

    useEffect(() => {
        const fetchTokens = async () => {
            if(!organisation){
                return
            }
            try {
                const tokenPromises = organisation?.wallets.map(async treasuryConfig => await getTokenAccountsByAddress(treasuryConfig.walletAddress, connection))
                const token22Promises = organisation?.wallets.map(async treasuryConfig => await getTokenAccountsByAddress(treasuryConfig.walletAddress, connection, TOKEN_2022_PROGRAM_ID))
                
                const tokenAccounts = await Promise.all(tokenPromises)
                const token22Accounts = await Promise.all(token22Promises)
                const flattened = [...tokenAccounts, ...token22Accounts].flatMap(acc => acc.value).filter(acc => !SCAM_MINTS.includes(acc.account.data.parsed.info.mint))
                settokenAccounts(flattened)
            }
            catch(e){
                console.warn(e)
            }
        }
        fetchTokens()

    }, [organisation?.wallets])

    useEffect(() => {
        if (
            !user?.publicKey
            || !selectedWalletConfig){
                return
            }
            
            if (mint !== null && mintDecimals !== null){
                const txs = [new Transaction().add(createTransferCheckedInstruction(
                    new PublicKey(selectedWalletConfig.walletAddress),
                    mint,
                    getAssociatedTokenAddressSync(mint,user?.publicKey, tokenProgramId),
                    new PublicKey(selectedWalletConfig.address),
                    mintDecimals === 0 ? BigInt(uiAmount) : BigInt(new BN(uiAmount).mul(new BN(Math.pow(10, mintDecimals))).toString()),
                    mintDecimals
                ))]
                setTransactions(txs)
                return
            }
            else if(selectedAddress === selectedWalletConfig.walletAddress){
                const txs = [new Transaction().add(SystemProgram.transfer({
                    toPubkey: user?.publicKey,
                    fromPubkey: new PublicKey(selectedWalletConfig.walletAddress),
                    lamports: BigInt(new BN(uiAmount).mul(new BN(LAMPORTS_PER_SOL)).toString())
                }
  
                ))]
                setTransactions(txs)
                return
            }

    }, [mint, user?.publicKey, selectedAddress, mintDecimals, uiAmount, selectedWalletConfig]);


    if(!user?.identity?.identifier){
        return <CreateProfileModal show={showProfileModal} onClose={() => {
            setShowProfileModal(false); 
            navigate("/")
        }} />
    }
  
    return <>
    {/* <div
    //   id="defaultModal"
    //   tabIndex={-1}
      aria-hidden="true"
      className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-black bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full 
        }`}
    > */}
       
        <div className="proposal-form-wrapper mx-auto max-w-screen-2xl md:px-8 sm:px-0 py-8 text-contrast">

        <form
            onSubmit={async (e) => { 
                e.preventDefault();
                if((organisation 
                    && organisation?.config?.proposalMinReputationGate !== null 
                    && reputation < organisation?.config?.proposalMinReputationGate
                    ) && !isCouncil){
                        toast.error("You do not have enough reputation to create a proposal.");
                        return;
                    }
                if (!name || !description) {
                  toast.error("All fields of the proposal form are required.");
                  return;
                }
  
                if (!user?.identity) {
                  toast.error(                
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>There is no user for the proposal being submited.</div>
                        <div>Please contact us if problems persist.</div>
                    </div>);
                  return;
                }
  
                if (!organisation) {
                  toast.error(                
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>No organisation was found that matched the proposal being submited.</div>
                        <div>Please contact us if problems persist.</div>
                    </div>);
                  return;
                }
  
                if (!alignPrograms) {
                  toast.error(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Provider information was not found for instruction.</div>
                        <div>Please contact us if problems persist.</div>
                    </div>
                    );
                  return;
                }

                if(!selectedWalletConfig){
                    toast.error(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Please select a governance account to take the payout from</div>
                        <div>if the proposal gets approved by the council.</div>
                    </div>);
                    return;
                }

                let decimals = mintDecimals

                if(decimals === null){
                    // Native sol address make decimals 9 points
                    if(mint === null && selectedAddress === selectedWalletConfig.walletAddress){
                       decimals = 9 
                    }
                    // If we have a mint but decimals are missing fetch them
                    else if (mint !== null && selectedAddress){
                        const mintinfo = await getMint(connection, mint, "confirmed")
                        decimals = mintinfo.decimals
                    }
                    // Nothing is selected we have other problems
                    else{
                        toast.error("Could not retrieve mint decimals. Please contact us if problems persist.");
                        return;
                    }
   
                }

                if(uiAmount === "0" ){
                    toast.error("Please enter a payout amount above zero.")
                    return
                }

                try {
                    toast.loading("Creating your proposal this may take some time. Please be patient.", {
                        id: 'createProposal' 
                    })
                    const {sigs, proposalAddress} = await createProposal(
                        new PublicKey(user?.identity?.identifier),
                        new web3.PublicKey(organisation.address),
                        new PublicKey(user?.identity?.identifier),
                        {
                          name,
                          description,
                        },
                        isUrgent ? new BN(0) : new BN(60 * 60 * 24 *3),
                        new PublicKey(selectedWalletConfig?.address),
                        new BN(uiAmount).mul(new BN(10).pow(new BN(decimals))),
                        alignPrograms,
                        {
                            onCreatingDrive: () => toast.loading(
                                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                    <div>Creating a shadow drive to upload your proposal metadata to.</div>
                                    <div>This may take some time..</div>
                                </div>, {
                                id: 'createProposal'
                            }),
                            onCreateDrive: (res) => toast.loading(
                                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                    <div>Drive created successfully..</div>
                                    <div>Uploading metadata..</div>
                                </div>, {
                                id: 'createProposal'
                            }),
                            onUpload: (res) => toast.loading(
                                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                    <div>Upload complete!</div>
                                    <div>Creating your proposal..</div>
                                </div>, {
                                id: 'createProposal'
                            }),
                            onUploadRetry: () => toast.loading(
                                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                    <div>Uploading metadata failed!</div>
                                    <div>Trying again..</div>
                                </div>, {
                                id: 'createProposal'
                            })
                        },
                        mint ? mint : undefined,
                        mint ? tokenProgramId : undefined
                    )
                      
                        if( proposalAddress ) {
                            toast.success("Sucessfully created your proposal.", {id: 'createProposal'})
                            delay(() => {
                                navigate(alignLink(`/proposal/${proposalAddress.toBase58()}`, isCustomDomain, organisation.address))
                            }, REFRESH_DELAY);
                        }
                }
                catch(e : any) {
                    toast.error(e.toString(), {id: "createProposal"})
                }
                
                    
            }
        }
            >
        <div className="flex-wrap justify-between gap-6 lg:grid lg:grid-cols-3 h-full">

        <main className={`proposal-form-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col w-full lg:col-span-2 lg:row-span-2 h-full ${theme.gridLayout && 'p-3 sm:p-6 bg-secondary bg-opacity-30 border-boxWidth'}`}>

          {/* <div className="flex items-start justify-between"> */}
            <h3 className="text-xl font-semibold mb-6">
             Information
            </h3>
          {/* </div> */}
          <div className="space-y-6 h-full flex flex-col">

                { isCouncil ? (<Tooltip title="This will create a proposal with no ranking peroid. Usefull for non serviced based proposals, where the aim is to move funds around the DAO's treasury wallets.">
                    <div className="flex p-2 border border-contrast border-opacity-30 rounded-md text-opacity-20 gap-2 text-sm absolute top-4 right-5">
                        <input 
                            className="" 
                            type="checkbox"
                            checked={isUrgent}
                            onClick={() => setIsUrgent(!isUrgent)}
                            />
                        <span className=" text-gray-200 ">Urgent proposal</span>
                    </div>

                </Tooltip>)
                : <div></div>}
              {/* <label className="block opacity-75">Title</label> */}
              <input
                value={name}
                placeholder={"Proposal Title"}
                onChange={(e) => setName(e.target.value)}
                className="input block mt-1 p-3 w-full h-12"
              />
              {/* <label className="block mt-6 opacity-75">Description</label> */}
              <div className="input relative rounded border flex-1">
                <div className={`${previewMarkdown ? "opacity-90" : ""} hover:opacity-90 hover:cursor-pointer opacity-70 px-1 py-1 rounded-lg bg-accent absolute right-3 top-2`}>
                    <Tooltip title="Preview Markdown">
                        <DocumentTextIcon className={`w-5`} onClick={() => setPreviewMarkdown(!previewMarkdown)}/>
                    </Tooltip>
                </div>
                {previewMarkdown ?
                    <ReactMarkdown className="max-w-none markdown prose prose-invert text-contrast" 
                        children={description}
                        remarkPlugins={[remarkGfm]} />
                    :
                    <textarea
                        value={description}
                        placeholder={"# Proposal Description"}
                        rows={4}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                        className="flex-1 bg-transparent p-3 w-full min-h-full"
                    />
                }
              </div>
          </div>
          </main>
          <aside className="w-full h-full lg:col-span-1 mt-4 lg:mt-0 flex flex-col justify-evenly gap-4">
          <div className="payout-container box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 p-5 text-contrast">
            <h3 className="text-xl font-semibold">
             Payout
            </h3>
          <label className="block text-sm mt-2 opacity-75 w-full flex-wrap">Treasury Wallet</label>
                <div className="flex flex-wrap items-center justify-center gap-3 mt-1 w-full">
                    
                     {  walletConfig &&     
                            <TreasuryOption 
                                key={walletConfig?.address}
                                name={walletConfig?.name}
                                isSelected={walletConfig.walletAddress === selectedAddress} 
                                treasuryWallet={walletConfig.walletAddress} 
                                onClick={(treasuryWallet, decimals, coinMeta) => {
                                    if(!treasuryWallet || decimals === null){
                                        return
                                    }
                                    
                                    setMintDecimals(decimals)
                                    setMint(null)
                                    setSelectedWalletConfig(walletConfig)
                                    setselectedAddress(walletConfig.walletAddress)
                                    setTokenProgramId(undefined)
                                }}
                                tokenProgramId={SystemProgram.programId}

                            />
                    }
                    
                    
                    {tokenAccounts.sort((a,b) => a.account.data.parsed.info.mint === USDC_MINT_ADDRESS.toBase58() ? -1 : 1).filter(x => x.account.data.parsed.info.tokenAmount.uiAmount !== 0).slice(0, tokenAccountListLength).map((acc) => (
                        <TreasuryOption 
                            key={acc.pubkey.toBase58()}
                            name={""}
                            isSelected={acc.pubkey.toBase58() === selectedAddress} 
                            treasuryWallet={acc.account.data.parsed.info.owner} 
                            mint={new PublicKey(acc.account.data.parsed.info.mint)}
                            onClick={(treasuryWallet, decimals, coinMeta) => {

                                    if(!treasuryWallet || decimals === null){
                                        return
                                    }
                                    const account = organisation?.wallets.find(x => x.walletAddress === treasuryWallet)
                                    if(!account){
                                        return
                                    }
                                    setMintDecimals(decimals)
                                    setMint(coinMeta.mint)
                                    setSelectedWalletConfig(account)
                                    setselectedAddress(acc.pubkey.toBase58())
                                    setTokenProgramId(acc.account.owner)
                                }} 
                            tokenProgramId={acc.account.owner}
                        />
                    ))}
                      
           
              </div>
                   <button className={`flex hover:border-accent hover:text-white items-center justify-center gap-1 flex-col rounded border
                          border-gray-700 "} p-1 text-gray-700 w-full mt-4`}
                        //create token accounts tx
                        onClick={(e) => {
                            e.preventDefault()
                            settokenAccountListLength(x => x + 9 >= tokenAccounts.length ? tokenAccounts.length : x + 9 )
                            // toast.error("Creating additional wallets for your DAO is a not avaliable at this time.") 
                            }
                        }
                        disabled={tokenAccounts.length <= tokenAccountListLength}
                        >
                            Load More 
                    {/* <Plus/> */}
                </button>
              <label className="text-sm block mt-4 opacity-75">Payout Amount</label>
              <input
                value={uiAmount}
                onChange={(e) => {
                    var regex = /^\d+\.?\d*$/
                    if (regex.test(e.target.value)){
                        setUiAmount(e.target.value)
                    }
       
                }}
                className="block bg-transparent rounded border mt-2 p-3 py-2 w-full"
              />
              </div>

              <CreateProposalTransactionViewer 
                transactions={transactions}
                servicer={user?.identity?.identifier}
                />

            <button
                type="submit"
                disabled={reputation === undefined || reputation === null}
                className="w-full mt-4 self-end bg-accent rounded-button border-primary text-contrast hover:opacity-80 border transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
              >
                Create
              </button>
                
            </aside>
          </div>
          </form>

          <JoinOrgModal onClose={() => {
                setModalJoinOpen(false);
                setDismissedModal(true);
            }} show={modalJoinOpen}/>
  </div>
  </>
  }

export function TreasuryOption({
    isSelected, 
    treasuryWallet, 
    onClick,
    name,
    mint,
    tokenProgramId = TOKEN_PROGRAM_ID
    } : 
    {
        isSelected : boolean, 
        treasuryWallet : string, 
        onClick : (
            treasuryWallet : string | undefined, 
            decimals : number,
            coinMeta : {
                symbol : string,
                name : string,
                image : string,
                mint : PublicKey,
                tokenStandard : TokenStandard,
            }
            ) => void,
        name : string,
        mint? : PublicKey,
        tokenProgramId : PublicKey
    }) {
    
    const {connection} = useConnection()
    const [balance, setBalance ] = useState<number>()
    const [isLoading, setIsLoading] = useState(true)
    const [coinMeta, setCoinMeta] = useState<{
        symbol : string,
        name : string,
        image : string,
        mint : PublicKey,
        programId: PublicKey,
        tokenStandard : TokenStandard,
    } | null>(null)
    const [mintDecimals, setMintDecimals] = useState<number| null>()
    const [address, setAddress] = useState<string>(treasuryWallet)

 
    useEffect(()=> {
        const fetchBalance = async () =>{
            try{
                if (!mint){
                    const balance =  await connection.getBalance(new PublicKey(treasuryWallet))
                    setBalance(Math.floor(balance /LAMPORTS_PER_SOL))
                    setCoinMeta({
                        symbol : "SOL",
                        name : "Solana",
                        image : "/sol-logo.webp",
                        mint: SystemProgram.programId,
                        programId: SystemProgram.programId,
                        tokenStandard : TokenStandard.Fungible
                    })
                    setMintDecimals(9)
                    return
                }

                const ata = getAssociatedTokenAddressSync(mint, new PublicKey(treasuryWallet), tokenProgramId)
                const balance = await connection.getTokenAccountBalance(ata)
                setAddress(ata.toBase58())
                if(balance.value.uiAmount){
                    setBalance(balance.value.uiAmount)
                }

                if (mint.toBase58() === USDC_MINT_ADDRESS.toBase58()){
                    setCoinMeta({
                        symbol : "USDC",
                        name : "USDC",
                        image : "/usdc-logo.webp",
                        mint,
                        programId: tokenProgramId,
                        tokenStandard : TokenStandard.Fungible
                    })
                    setMintDecimals(6)
                    return
                }
  
                const mintInfo = await getMint(connection, mint, "confirmed", tokenProgramId)
                setMintDecimals(mintInfo.decimals)
                const coinMetadataAddress = getMetadataAddress(mint, tokenProgramId)
                
                const accountInfo = await connection.getAccountInfo(
                    coinMetadataAddress
                );
                if (accountInfo) {
                    const [metadata] = metadataBeet.deserialize(accountInfo.data);
                    const response = await fetch(metadata.data.uri)
                    if(response.ok){
                        const json = await response.json()
                        setCoinMeta({...json, tokenStandard : metadata.tokenStandard, mint: metadata.mint})
                    }
                    else {
                        setCoinMeta({symbol : "", name: "", mint: mint, programId : tokenProgramId, tokenStandard: mintInfo.decimals === 0 ? TokenStandard.NonFungible : TokenStandard.Fungible, image: ""})
                    }
                    return 
                }
                else{
                    setCoinMeta({symbol : "", name: "", mint: mint, programId : tokenProgramId, tokenStandard: mintInfo.decimals === 0 ? TokenStandard.NonFungible : TokenStandard.Fungible, image: ""})
                }
                return 
 
            }
            catch(e){
                console.warn(e)
            }
        }
        fetchBalance()
    }, [treasuryWallet])


        
  return <div 
  onClick={() => (mintDecimals !== undefined && mintDecimals !== null) && coinMeta ? onClick(treasuryWallet, mintDecimals, coinMeta ) : () => {}} 
  className={`flex items-center w-40 md:w-48 justify-start gap-3 pl-4 rounded border
                ${isSelected ?"bg-accent border-primary" :"  border-gray-700 hover:border-white"} mt-1 p-2 cursor-pointer relative`}>
            
            <div className="flex justify-start items-center h-full w-1/5">
            {coinMeta?.image ? <img 
                className={` w-9 rounded-full  ${isLoading ? "bg-contrast h-8 animate-pulse" : ""}`} src={coinMeta?.image} alt={coinMeta?.name}
                onLoad={() => setIsLoading(false)}
                /> : <QuestionMarkCircleIcon className="w-9"/>
            }
            </div>
            <div className="flex flex-1 gap-0 flex-col">
                <label className="opacity-75 text-xs mt-0 font-light w-full text-left">{truncateKey(address)}</label>
                <label className="text-sm">{balance}</label>
            </div>
            <div className="self-start w-5">
                <ClipboardDocumentIcon className="w-4 absolute right-2 top-2 opacity-80 hover:opacity-100" onClick={() => {
                    navigator.clipboard.writeText(address)
                    toast.success("Copied to clipboard.")
                }
                }/>
            </div>
   
  </div>
}

export function CreateProposalTransactionViewer({transactions, servicer}: {transactions : Transaction[], servicer: string}) {


    return (
        <div className="txs-container box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 p-5 text-contrast">
            <h3 className="text-xl font-semibold">
            Transactions
            </h3>
            {transactions.map((tx, i) => <TransactionItem key={i} transaction={tx} servicer={servicer}/>)}
            
            <Tooltip title="Transaction is auto populated for payouts, more complex transaction builder coming in V2">
                <button
                    className="w-full mt-6 self-end bg-accent border opacity-40 rounded-button border-primary transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center cursor-not-allowed"
                    onClick={(e) => e.preventDefault()}>
                            Add Transaction
                        
                </button>
            </Tooltip>
        </div>
    )

}

export function TransactionItem({transaction, servicer}: {transaction : Transaction, servicer : string}) {

    const parseTransaction = (transaction : Transaction) => {
        const parsedInstructions = transaction.instructions.map((ix) => {
            if(ix.programId.toBase58() === TOKEN_PROGRAM_ID.toBase58()){
                const decodedData = u8().decode(ix.data)
                switch (decodedData){
                    case TokenInstruction.TransferChecked : {
                       const decoded = decodeTransferCheckedInstruction(ix)
                       return {
                            instructionName: "transfer",
                            accounts: decoded.keys as unknown as Record<string, AccountMeta>,
                            data : { amount: new BN(decoded.data.amount.toString()), decimals: decoded.data.decimals }
                       }
              
                    }
                    default: {
                        return {
                            instructionName: "unknown",
                            accounts: {},
                            data : {unparsed: ix.data}
                           }
                    }
                            
                    
                }
           }
           else if(ix.programId.equals(SystemProgram.programId)){
            const instructiontype = SystemInstruction.decodeInstructionType(ix)
            switch (instructiontype){
                case "Transfer": {
                   const decoded = SystemInstruction.decodeTransfer(ix)
                   return {
                    instructionName: "Native Transfer",
                    accounts: {
                        fromPubkey : {
                            isSigner : true,
                            isWritable : true,
                            pubkey: decoded.fromPubkey
                        }, 
                        toPubkey : {
                            isSigner : false,
                            isWritable: true,
                            pubkey: decoded.toPubkey
                        }
                    } as unknown as Record<string, AccountMeta>,
                    data : { amount: new BN(decoded.lamports.toString())}
                   }
                }
                default: 
                     return {
                        instructionName: "unknown",
                        accounts: {},
                        data : {unparsed: ix.data}
                }
            }

        }
           return {
            instructionName: "unknown",
            accounts: {},
            data : {unparsed: ix.data}
           }
        })

        return parsedInstructions
    }

    const [parsedInstructions, setParsedInstructions] = useState<{instructionName: string, data : Record<string, any>, accounts: Record<string, AccountMeta>}[]>([])

    useEffect(() => {

       const instructions = parseTransaction(transaction)
       setParsedInstructions(instructions)

    }, [transaction])

    return (
        <div className="flex flex-col text-sm w-full">
            {
                parsedInstructions.map((ix, i) => (
                    <InstructionItem instructionName={ix.instructionName} data={ix.data} accounts={ix.accounts} servicer={servicer} index={i}/>
                ))
            }
        </div>
    )

}

const InstructionItem = ({instructionName, data, accounts, servicer, index }: {instructionName : string,  data : Record<string, any>, accounts: Record<string, AccountMeta>, servicer : string, index : number}) => {
    if(instructionName === "transfer"){
        return <TransferInstructionItem instructionName={instructionName} data={data} accounts={accounts} servicer={servicer} index={index}/>
    }
    else if(instructionName === "Native Transfer"){
        return <NativeTransferInstructionItem instructionName={instructionName} data={data} accounts={accounts} servicer={servicer} index={index}/>
    }
    return ( <>Unkown</> );
}

const NativeTransferInstructionItem = ({instructionName, data, accounts, servicer, index }: {instructionName : string,  data : Record<string, any>, accounts: Record<string, AccountMeta>, servicer : string, index : number}) => {
    const programs = useAlignPrograms()
    const [currentOrganisationAddress] = useCurrentOrganisation()
    const organisation = useOrganisation(currentOrganisationAddress)


    useEffect(() => {
        if(!programs){
            return
        }
        // Assert desitination === servicer token account
        const servicerIdentifierOwnsAta = async () => {
            const ownerRecords = await Api.fetchOwnerRecordsByIdentifier(new PublicKey(servicer), programs)

            const servicerIsReciever = ownerRecords.find(record => {
                const owner = record.account.account
                const expectedTokenAccount = accounts.destination
                const calculatedAta = getAssociatedTokenAddressSync(accounts.mint.pubkey, owner)
                return calculatedAta.toBase58() === expectedTokenAccount.pubkey.toBase58()
            }) !== undefined

            return servicerIsReciever
        }
    
    }, [servicer, accounts])

    return (<> 
        {/* <div className="flex justify-center mt-2 items-center gap-2"> */}

        <div className="flex flex-1 flex-col px-3 justify-center items-center mt-2 p-1 bg-contrast bg-opacity-10 rounded-lg">
            
            <label className="text-xs font-light self-start pl-2 pt-1 text-center ">#{index+ " " +instructionName[0].toUpperCase() + instructionName.slice(1)}</label>

                <div className="w-full h-full justify-around items-center flex">
                    <div className="w-1/3">
                        {organisation?.organisation?.profile && 
                            <Avatar size={10} showHandle={true} className="rounded-full" userIdentifier={new PublicKey(organisation.organisation.identifier)}/>}

                    </div>

                    <div className="flex w-1/3 flex-col justify-center items-center">
                        <label className="font-light text-xs">{data.amount.div(new BN(LAMPORTS_PER_SOL)).toString()}</label>

                        <img
                            className="w-8 h-8 rounded-full"
                            src="/sol-logo.webp"
                            />
                        <label className="font-light text-lg">&#x2192;</label>
                    </div>
                    <div className="w-1/3">

                        <Avatar showHandle={true} size={10} className="rounded-full" userIdentifier={new PublicKey(servicer)}/>
                    </div>
  
               
                </div>
        </div>
    </>)
}
 


const TransferInstructionItem = ({instructionName, data, accounts, servicer, index }: {instructionName : string,  data : Record<string, any>, accounts: Record<string, AccountMeta>, servicer : string, index : number}) => {
    const programs = useAlignPrograms()
    const [currentOrganisationAddress] = useCurrentOrganisation()
    const organisation = useOrganisation(currentOrganisationAddress)

    const [mintMeta, setMintMeta] = useState<HeliusGetAssetResponse | null>(null);

    useEffect(() => {
        if (accounts.mint.pubkey.toBase58() === USDC_MINT_ADDRESS.toBase58()){
            setMintMeta(
                //@ts-ignore

                  {
                            id:  accounts.mint.pubkey.toBase58() ,
                            content: {
                                //@ts-ignore
                                metadata: {
                                        symbol : "USDC",
                                    name : "USDC",
                                },
                                                                    links : {external_url:"/usdc-logo.webp", image: "/usdc-logo.webp"},

                            }
                    }
        )
            return
        }
        else if (accounts.mint.pubkey.toBase58() === BERN_MINT_ADDRESS.toBase58()){
            
            setMintMeta(
                //@ts-ignore
                 {
                            id:  accounts.mint.pubkey.toBase58() ,
                            content: {
                                //@ts-ignore
                                metadata: {
                                    symbol : "$BERN",
                                    name : "BERN",
                                },
                                                                    links : {external_url:"/bern.jpg", image: "/bern.jpg" },

                            }
                    }
            )

        }
        else{
            const fetch = async () => {
                const meta = await fetchNfts([accounts.mint.pubkey.toBase58()])
                if(meta.length === 0){
                    return
                }
                setMintMeta(meta[0])
            }
            fetch()
        }

    }, [accounts.mint.pubkey.toBase58()]);

    useEffect(() => {
        if(!programs){
            return
        }
        // Assert desitination === servicer token account
        const servicerIdentifierOwnsAta = async () => {
            const ownerRecords = await Api.fetchOwnerRecordsByIdentifier(new PublicKey(servicer), programs)

            const servicerIsReciever = ownerRecords.find(record => {
                const owner = record.account.account
                const expectedTokenAccount = accounts.destination
                const calculatedAta = getAssociatedTokenAddressSync(accounts.mint.pubkey, owner)
                return calculatedAta.toBase58() === expectedTokenAccount.pubkey.toBase58()
            }) !== undefined

            return servicerIsReciever
        }
    
    }, [servicer, accounts])

    return (<> 
        {/* <div className="flex justify-center mt-2 items-center gap-2"> */}

        <div className="flex flex-1 flex-col px-3 justify-center items-center mt-2 p-1 bg-contrast bg-opacity-10 rounded-lg">
            
            <label className="text-xs font-light self-start pl-2 pt-1 text-center ">#{index+ " " +instructionName[0].toUpperCase() + instructionName.slice(1)}</label>

                <div className="w-full h-full justify-around items-center flex">
                    <div className="w-1/3">
    
                        {organisation?.organisation?.profile && <Avatar size={10} showHandle={true} className="rounded-full" userIdentifier={new PublicKey(organisation.organisation.identifier)}/>}

                    </div>

                    <div className="flex w-1/3 flex-col justify-center items-center">
                        <label className="font-light text-xs">{data.decimals !== 0 ? new BN(data.amount.toString()).div(new BN(10).pow(new BN(data.decimals))).toString() : data.amount.toString()}</label>

                        <img
                            className="w-8 h-8 rounded-full"
                            src={mintMeta?.content?.links.image}
                            />
                        <label className="font-light text-lg">&#x2192;</label>
                    </div>
                    <div className="w-1/3">

                        <Avatar showHandle={true} size={10} className="rounded-full" userIdentifier={new PublicKey(servicer)}/>
                    </div>
  
               
                </div>
        </div>
    </>)
}

