import { ForwardIcon } from "@heroicons/react/24/solid"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { thresholdsFormInput, councilMembersFormInput } from "../../../state/forms"
import { useAuth } from "../../../state/hooks/useAlignGovernance"
import { DescriptionPanel } from "../../DescriptionPanel"
import { WizardStepProps } from "../../CreateProposalWizard/Wizard"

export function ThresholdsStep({onComplete, goBack}: WizardStepProps<{
  treasuryThreshold : number,
  configThreshold : number,
}>){
  const user = useAuth()
  const thresholdsFormInputValues = useRecoilValue(thresholdsFormInput)
  const setThresholdsState = useSetRecoilState(thresholdsFormInput)
  const councilMembersFormInputValues= useRecoilValue(councilMembersFormInput)
  const { register, handleSubmit, formState: { errors }, control, watch } = useForm<any>({
    defaultValues: {
      treasuryThreshold : thresholdsFormInputValues?.treasuryThreshold || `${councilMembersFormInputValues.length -1}`,
      configThreshold : thresholdsFormInputValues?.configThreshold || `${councilMembersFormInputValues.length -1}`,
    }
  })

  const watchConfigThreshold = watch("configThreshold", thresholdsFormInputValues?.configThreshold)
  const watchTreausryThreshold = watch("treasuryThreshold", thresholdsFormInputValues?.treasuryThreshold)

 if(user?.identity === undefined){
  return <></>
 }

 const onSubmit = (thresholds : {
      treasuryThreshold : number,
      configThreshold : number,
 }) => {
      if(thresholds.treasuryThreshold === 0 || thresholds.configThreshold === 0){
          toast.error("Thresholds must be above zero.")
          return
      }
      console.log("Storing threshold inputs", thresholds)
      setThresholdsState(thresholds)
      onComplete(thresholds)

 }

 return (
  <>
      <DescriptionPanel message="Please stipulate your governance thresholds.
      This is the amount of council wallets needed to vote 'yes' on a ranked proposal for it to be accepted in. Governance is seperate for the configuration of the DAO so it can have a seperate threshold than treasury."/>

      <form onSubmit={handleSubmit(onSubmit)} onError={(e) => toast.error(e.toString()) }
              className=" flex flex-col gap-4 rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 w-full p-6 lg:col-span-2">
          

          <div className="p-4 flex flex-col gap-4 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center">
              <label className="font-light">Treasury Governance Threshold</label>

              <div className="flex w-full gap-4">
                  <label className="text-contrast bg-contrast bg-opacity-10 p-1  px-2 rounded-md">{watchTreausryThreshold}/{councilMembersFormInputValues.length}</label>
                  <input type="range" step="1" min="0" max={`${councilMembersFormInputValues.length}`} {...register("treasuryThreshold", { required: true, valueAsNumber : true, })} className=" w-full bg-accent text-accent " defaultValue={`${councilMembersFormInputValues.length -1}`}/>
                  
              </div>   
          </div>
      
          <div className="p-4 flex flex-col gap-4 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center">
              <label className="font-light">Configuration Governance Threshold</label>

              <div className="flex w-full gap-4">
                  <label className="text-contrast bg-contrast bg-opacity-10 p-1 px-2 rounded-md">
                      {watchConfigThreshold}/{councilMembersFormInputValues.length}
                  </label>
                  <input type="range" step="1" min="0" max={`${councilMembersFormInputValues.length}`} 
                      {
                          ...register("configThreshold", 
                          { 
                              required: true, 
                              valueAsNumber : true,
                          }
                              
                          )} 
                      className=" w-full bg-accent text-accent " 
                      defaultValue={`${councilMembersFormInputValues.length -1}`}
                      />
                  
              </div>
          </div>
          <button
                  className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                   
                    
                
                   
                  transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                  type="submit"
                  
                  >
                  <ForwardIcon className={`w-4 `}/>
                  Next Step
          </button>

          <button
              className="w-full flex justify-center gap-2 items-center mt-4 self-end border rounded-button border-primary 
              transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
              onClick={() => goBack()}
          >
              <ForwardIcon className={`w-4 transform rotate-180`}/>
              Go back
          </button>
      </form>
  </>
  )
  

}
