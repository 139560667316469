import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { AnchorProvider, BN, Program, web3 } from "@project-serum/anchor";
import { createAssociatedTokenAccountIdempotentInstruction, createTransferCheckedInstruction, getAssociatedTokenAddress, getAssociatedTokenAddressSync } from "@solana/spl-token";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import { BERN_MINT_ADDRESS, USDC_MINT_ADDRESS, createProposalV2 } from "align-sdk";
import { delay } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { ArrowRight } from "react-feather";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { fetchNfts } from "../../../../api/nfts";
import { BONK_MINT, BONK_REWARDS_POOL_ADDRESS, REFRESH_DELAY } from "../../../../constants";
import { useGetReputationQuery } from "../../../../generated/graphql";
import { isCustomDomainStore } from "../../../../state/domains";
import { proposalWizardBonkAmount, proposalWizardSendAssetsDescription, proposalWizardSendAssetsRecipients, proposalWizardSendAssetsTokens } from "../../../../state/forms";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useOrganisation } from "../../../../state/hooks/useAlignGovernance";
import { useCoinPrice } from "../../../../state/hooks/useCoins";
import { alignLink } from "../../../../utils/alignRoute";
import { prettyRoundedStringBalance } from "../../../../utils/coins";
import { truncateKey } from "../../../../utils/truncateKey";
import { WizardStepProps } from "../../Wizard";
import {SPL_TOKEN_STAKING_ID, SPL_TOKEN_STAKING_ID_V0, SplTokenStaking, SplTokenStakingIDL, SplTokenStakingIDLV0, SplTokenStakingV0, StakePool, YEAR_IN_SECONDS, createStakeInstruction, getNextUnusedStakeReceiptNonce, getRewardPoolPublicKeys} from "@mithraic-labs/token-staking"

const CoinSendSummary = ({
    mint,
    recipients,
    amounts
}: {
    mint: string,
    recipients: {
        id: number;
        walletAddress: string;
        name: string;
    }[], 
    amounts: {
        amount: string,
        decimals : number
        recipient: string | number
    }[]
}) => {

    const [name, setName] = useState<string| null>(null);
    const [image, setImage] = useState<string| null | undefined>(null)
    const [tokenAmounts, settokenAmounts] = useState<Record<string, string>>({});
    const price = useCoinPrice(mint === "11111111111111111111111111111111" ? "So11111111111111111111111111111111111111112" : mint )
    const amount : number =useMemo(() => amounts.reduce((prev: number, current : {
        amount: string,
        decimals : number
        recipient: string | number
    }) => prev + Number(current.amount), 0 ), [amounts]) as number
    
    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                if(mint === "11111111111111111111111111111111"){
                    setName("SOL")
                    setImage("/sol-logo.webp")
                    return
                }

                if(mint === USDC_MINT_ADDRESS.toBase58()){
                    setName("USDC")
                    setImage("/usdc-logo.webp")
                    return
                }

                if(mint === BERN_MINT_ADDRESS.toBase58()){
                    setName("BERN")
                    setImage("/bern.jpg")
                    return
                }
                
                const account = await fetchNfts([mint])
                if(account.length === 0){
                    return
                }
                setName(account[0]?.content.metadata.name)
                setImage(account[0]?.content?.links.image)
            }
            catch(e){
               return 
            }
        
        }

        fetchMetadata()
    }, [mint]);


   

    return (
        <li className="w-full bg-contrast bg-opacity-10 p-4 min-h-28 rounded flex flex-col gap-2">
            <div className="flex justify-between mb-4">

                <div className="flex gap-3 justify-center items-center">
                    <img className="rounded-full w-8 h-8" src={image || ""}/>
                    <div className="flex flex-col ">
                        <div className="flex gap-3 justify-start items-center ">
                            <div>{  prettyRoundedStringBalance(amount.toString())}</div>
                            <div className="text-xs font-light text-contrast text-opacity-70">${Math.floor(((amount * (price || 0)) * 100))/100}</div>
                        </div>
                        <a target="_blank" href={`https://solana.fm/address/${mint}`} className="hover:cursor-pointer hover:text-opacity-60 text-xs text-accent text-opacity-20">{  mint !== "11111111111111111111111111111111" ? truncateKey(mint) : "~"}</a>
                    </div>
                 
                </div>
               
                <div className="flex gap-1 text-xs justify-center items-center font-light">
                <label className="flex gap-2 justify-start items-center w-[150px] bg-contrast bg-opacity-10 px-2 text-sm h-6"> 
                    To {amounts.length} Recipient(s)
                </label>
                
                </div>
            </div>
            
            {/* {
                amounts.map(am => (
                    <div className=" w-full flex gap-2 justify-start=items-center">
                        
                        <div
                            className="flex gap-2 justify-start items-center w-[100px] bg-contrast bg-opacity-10 px-2 text-sm h-7"
                
                        >
                            <label>{prettyRoundedStringBalance(am.amount.toString())}
                            </label>
                            <label className="text-xs font-light text-contrast text-opacity-70">${Math.floor(((parseFloat(am.amount) * (price || 0)) * 100))/100}</label>
                            
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <hr className="border-accent w-full border "/>
                            <ArrowRight className="text-accent"/>
                        </div>
                        <label className="flex gap-2 justify-start items-center bg-contrast bg-opacity-10 px-2 text-sm h-7">{recipients.find(x => x?.id as any  === am.recipient)?.name} 
                            <p className="text-xs text-opacity-25">{truncateKey(recipients.find(x => x?.id as any  === am.recipient)?.walletAddress || "")}</p>
                        
                        </label>


                    </div>


                ))
            } */}
        </li>
    )
}

export const ProposalTransferSummary = ({
    recipients,
    tokenAmounts
}: {
    recipients: {
        id: number;
        walletAddress: string;
        name: string;
    }[],
    tokenAmounts: Record<string, {
        amount: string,
        decimals : number
        recipient: string | number
    }[]>
}) => {
    return (
        <ol className="flex flex-col gap-3 w-full overflow-auto scroll">
             
        {
          recipients && tokenAmounts && Object.entries(tokenAmounts).map(([mint, input]: [string, 
            {
            amount: string,
            decimals : number
            recipient: string | number
        }[]]) => (
                <CoinSendSummary mint={mint} recipients={recipients} amounts={input}/>
           ))
        }
          
        </ol>
    )
   
}

const ProposalWizardBonkRewardsSummary = ({
        currentStep,
        onComplete
    } : WizardStepProps<{}> ) => {
    const user = useAuth()
    const amount = useRecoilValue(proposalWizardBonkAmount)
    const descriptionInputs = useRecoilValue(proposalWizardSendAssetsDescription)

    const [currentOrg] = useCurrentOrganisation()
    const {organisation} = useOrganisation(currentOrg)
    const {connection} = useConnection()
    const alignPrograms = useAlignPrograms()
    const navigate = useNavigate()
    const isCustomDomain = useRecoilValue(isCustomDomainStore)
  const wallet = useAnchorWallet()


    const reputation = useGetReputationQuery({
        variables: {
            userIdentityAddress: user?.userIdentityAddress || "",
            organisationAddress: currentOrg || ""
        },
        skip: user?.userIdentityAddress === undefined || currentOrg === undefined
    })
    const isCouncil = useMemo(() => organisation?.councilManager?.councilMembers.find(c => c.user?.userIdentityAddress === user?.userIdentityAddress) !== undefined, [organisation?.councilManager?.councilMembers, user?.userIdentityAddress])

    console.log(reputation)

    if(currentStep !== 2 ){
        return <></>
    }

    const handleSubmit = async () => {
        //Validations neeeds to be done heere
        if((organisation 
            && organisation?.config?.proposalMinReputationGate !== null 
            && reputation.data?.reputation.totalReputation < organisation?.config?.proposalMinReputationGate
            ) && !isCouncil){
                toast.error("You do not have enough reputation to create a proposal.");
                return;
            }

            const walletConfig = organisation?.wallets.find(x => x.name === "Treasury")
        if (!user?.identity) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>There is no user for the proposal being submited.</div>
                    <div>Please contact us if problems persist.</div>
                </div>
            );
            return;
        }

        if ( amount === undefined || !descriptionInputs) {
            toast.error("Error retrieving form inputs.");
            return;
        }

        if (!organisation || organisation.config?.rankingPeriod === undefined || organisation.config?.rankingPeriod === null) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>No organisation was found that matched the proposal being submited.</div>
                    <div>Please contact us if problems persist.</div>
                </div>
            );
            return;
        }

        if (!alignPrograms || !wallet) {
            toast.error(
                <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                    <div>Provider information was not found for instruction.</div>
                    <div>Please contact us if problems persist.</div>
                </div>
            );
            return;
        }

        // if(!gover?.walletConfig){
        //     toast.error("Please select a governance account to take the payout from if the proposal gets approved by the council.");
        //     return;
        // }
        toast.loading(
            <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                <div>Creating your proposal this may take some time.</div>
                <div>Please be patient.</div>
            </div>, {
            id: 'createProposal'
        })


        if (!walletConfig) {
            toast.error("Could not find wallet config");
            return;
        }
        try {
            const provider = new AnchorProvider(connection, wallet, {})

            const tokenStakingProgram = new Program<SplTokenStakingV0>(SplTokenStakingIDLV0, SPL_TOKEN_STAKING_ID_V0,provider )
            const nextReceiptNonce = await getNextUnusedStakeReceiptNonce(provider.connection, tokenStakingProgram.programId, new PublicKey(walletConfig.walletAddress), BONK_REWARDS_POOL_ADDRESS)
            const pool : StakePool = await tokenStakingProgram.account.stakePool.fetch(BONK_REWARDS_POOL_ADDRESS) as any;
            const rewardVaults= pool.rewardPools.filter((rp) => !rp.rewardVault.equals(web3.PublicKey.default)).map(x => x.rewardVault)
            const stakeMintAta = getAssociatedTokenAddressSync(pool.stakeMint,new PublicKey(walletConfig.walletAddress), true)
            const stakeIx = await createStakeInstruction(
                tokenStakingProgram as any,
                new PublicKey(walletConfig.walletAddress),
                new PublicKey(walletConfig.walletAddress),
               BONK_REWARDS_POOL_ADDRESS,
               getAssociatedTokenAddressSync(BONK_MINT, new PublicKey(walletConfig.walletAddress), true),
               stakeMintAta,
               amount,
               new BN(Math.floor(YEAR_IN_SECONDS / 2)),
                nextReceiptNonce,
                rewardVaults
            )
            const { sigs, proposalAddress } = await createProposalV2(
                new PublicKey(organisation.address),
                new PublicKey(user.identity.identifier),
                [
                    new Transaction().add(createAssociatedTokenAccountIdempotentInstruction(
                                new PublicKey(walletConfig.walletAddress), 
                                stakeMintAta, 
                                new PublicKey(walletConfig.walletAddress),
                                pool.stakeMint 
                        ), ).add(stakeIx)
                ],
                new PublicKey(walletConfig.address),
                !descriptionInputs.hasRanking ? new BN(0) : new BN(organisation.config.rankingPeriod),
                {
                    name: descriptionInputs.title,
                    description: descriptionInputs.description,
                },
                {
                    onCreatingDrive: () => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Creating a shadow drive to upload your proposal metadata to.</div>
                            <div>This may take some time..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onCreateDrive: (res) => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Drive created successfully..</div>
                            <div>Uploading metadata..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onUpload: (res) => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Upload complete!</div>
                            <div>Creating your proposal..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onUploadRetry: () => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Uploading metadata failed!</div>
                            <div>Trying again..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
                        <b>{`${res.count}/${res.total}: Sending Transactions`}</b>
                        <div>Failed transactions will try again..</div>
                        <div>Please do not refresh or exit..</div>
                    </div>, {
                        id: "createProposal"
                    }),
                    onTransactionFailure: (res: any) => toast.error(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Error:</div>
                        <div>{`${res}`}</div>
                    </div>, {
                        id: "createProposal"
                    })
                },
                alignPrograms,
                undefined
            )

            console.log("created proposal: ", sigs, proposalAddress.toBase58())
    
            if (proposalAddress) {
                toast.success("Sucessfully created your proposal.", { id: 'createProposal' })
                delay(() => {
                    // refreshProposals();
                    navigate(alignLink(`/proposal/${proposalAddress.toBase58()}`, isCustomDomain, organisation?.address))
                }, REFRESH_DELAY);
            }
        }
        catch(e){
            // Could inject retry logic here
            toast.error(e?.toString()|| "Unkown Error", {id: 'createProposal'})
            throw e
        }
     
    

    }

    return ( 
        <div className="proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 w-full md:w-5/6 p-3 md:p-6 lg:col-span-2 sm:p-6 bg-secondary border-boxWidth flex flex-col">
        <div className="font-heading text-lg md:text-3xl mb-2">Create Send Proposal </div>
        <h3 className="text-sm font-normal mb-6">Review your send proposal summary below.</h3>
        <div className="w-full mb-3 p-4 proposals-container box-container bg-opacity-30 rounded-lg border-2 border-contrast border-opacity-30 flex-col justify-start items-start gap-2.5 inline-flex">
                    <h2 className="text-sm">SUMMARY</h2>

        <div className="w-full bg-contrast bg-opacity-10 p-4 min-h-28 rounded flex flex-col gap-2">
            <div className="contrast">{descriptionInputs?.title}</div>
            <div className="text-sm text-contrast text-opacity-90">{descriptionInputs?.description}</div>
        </div>

        <div className="w-full bg-contrast bg-opacity-10 p-4 min-h-28 rounded flex flex-col gap-2">
            <div className="contrast text-contrast text-opacity-50 font-thin text-sm">Has Ranking Period</div>
            <div className="text-sm text-contrast text-opacity-90">{descriptionInputs?.hasRanking ? "YES" : "FALSE"}</div>
        </div>
                <div className="w-full bg-contrast bg-opacity-10 p-4 min-h-28 rounded flex flex-col gap-2">

             <div className="flex justify-between mb-4">

                <div className="flex gap-3 justify-center items-center">
                    {/* <img className="rounded-full w-8 h-8" src={ || ""}/> */}
                    <div className="flex flex-col ">
                        <div className="flex gap-3 justify-start items-center ">
                            <div>{  prettyRoundedStringBalance(amount.div(new BN(10).pow(new BN(5))).toString())} locked BONK for 6 months</div>
                            {/* <div className="text-xs font-light text-contrast text-opacity-70">${Math.floor(((amount * (price || 0)) * 100))/100}</div> */}
                        </div>
                        <a target="_blank" href={`https://solana.fm/address/DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263`} className="hover:cursor-pointer hover:text-opacity-60 text-xs text-accent text-opacity-20">{ truncateKey("DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263")}</a>
                    </div>
                 
                </div>
               
                <div className="flex gap-1 text-xs justify-center items-center font-light">
        
                
                </div>
            </div>
           </div>
        </div>
        <button
            className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary  
            transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
            
            onClick={() => handleSubmit()}
            >
             <Cog6ToothIcon className={`w-4 `}/>
            Create Proposal
        </button>
</div>           
    );
}
 
export default ProposalWizardBonkRewardsSummary;