import { Keypair, PublicKey, Transaction } from "@solana/web3.js";

export const READ_MOCK_WALLET = {
  publicKey: new Keypair().publicKey,
  signAllTransactions: async (tx: Transaction[]) => tx,
  signTransaction: async (tx: Transaction) => tx,
  payer: new Keypair().publicKey,
};
export const METADATA_PREFIX = Buffer.from("metadata");
export const METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s",
);

// From @jup-ag/instruction-parser
export const JUPITER_V6_PROGRAM_ID = new PublicKey(
  "JUP6LkbZbjS1jKKwapdHNy74zcZ3tLUZoi5QNyVTaV4",
);

export const BONK_REWARDS_POOL_ADDRESS = new PublicKey(
  "9AdEE8AAm1XgJrPEs4zkTPozr3o4U5iGbgvPwkNdLDJ3",
);
export const BONK_MINT = new PublicKey(
  "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
);
export const AMM_TYPES = {
  "9W959DqEETiGZocYWCQPaJ6sBmUzgfxXfqGeTEdp3aQP": "Orca",
  MERLuDFBMmsHnsBPZw2sDQZHvXFMwp8EdjudcU2HKky: "Mercurial",
  "9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin": "Serum",
  "675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8": "Raydium",
  SSwpkEEcbUqx4vtoEByFjSkhKdCT862DNVb52nZg1UZ: "Saber",
  PSwapMdSai8tjrEXcxFeQth87xC4rRsa4VA5mhGhXkP: "Penguin",
  AMM55ShdkoGRB5jVYPjWziwk8m5MpwyDgsMWHaMSQWH6: "Aldrin",
  CURVGoZn8zycx6FXwwevgBTB2gVvdbGTEpvMJDbgs2t4: "Aldrin v2",
  SSwpMgqNDsyV7mAgN9ady4bDVu5ySjmmXejXvy2vLt1: "Step",
  CTMAxxk34HjKWxQ3QLZK1HpaLXmBveao3ESePXbiyfzh: "Cropper",
  SCHAtsf8mbjyjiv4LkhLKutTf6JnZAbdJKFkXQNMFHZ: "Sencha",
  CLMM9tUoggJu2wagPkkqs9eFG4BWhVBZWkP1qv3Sp7tR: "Crema",
  EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S: "Lifinity",
  SSwapUtytfBdBn1b9NUGG6foMVPtcWgpRU32HToDUZr: "Saros",
  whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc: "Whirlpool",
  cysPXAjehMpVKUapzbMCCnpFxUFFryEWEaLgnb9NrR8: "Cykura",
  MarBmsSgKXdrN1egZf5sqe1TMai9K1rChYNDJgjq7aD: "Marinade",
  Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j: "Stepn",
  Eo7WjKq67rjJQSZxS6z3YkapzY3eMj6Xy8X5EQVn5UaB: "Meteora",
  HyaB3W9q6XdA5xwpU4XnSZV94htfmbmqJXZcEbRaJutt: "Invariant",
  "7WduLbRfYhTJktjLw5FDEyrqoEv61aTTCuGAetgLjzN5": "GooseFX",
  DecZY86MU5Gj7kppfUCEmd4LbXXuyZH1yHaP2NTqdiZB: "Saber Decimal Wrapper",
  D3BBjqUdCYuP18fNvvMbPAZ8DpcRi4io2EsYHQawJDag: "Balansol",
  dp2waEWSBy5yKmq65ergoU3G6qRLmqa6K7We4rZSKph: "Dradex",
  "2wT8Yq49kHgDzXuPxZSaeLaH1qbmGXtEyPy64bL7aD3c": "Lifinity v2",
  CAMMCzo5YL8w4VFF8KVHrK22GGUsp5VTaW7grrKgrWqK: "Raydium CLMM",
  srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX: "Openbook",
  "9tKE7Mbmj4mxDjWatikzGAtkoWosiiZX9y6J4Hfm2R8H": "Marco Polo",
  PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY: "Phoenix",
  "2KehYt3KsEQR53jYcxjbQp2d2kCp4AkuQW68atufRwSr": "Symmetry",
  BSwp6bEBihVLdqJRKGgzjcGLHkcTuzmSo1TQkHepzH8p: "BonkSwap",
  FLUXubRmkEi2q6K3Y9kBPg9248ggaZVsoSFhtJHSrm1X: "FluxBeam",
  treaf4wWBBty3fHdyBpo35Mz84M8k3heKXmjmi9vFt5: "Helium Network",
  stkitrT1Uoy18Dk1fTrgPw8W6MVzoCfYoAFT4MLsmhq: "unstake.it",
};

export const tokenTickers: Record<string, string> = {
  So11111111111111111111111111111111111111112: "wSOL",
  So11111111111111111111111111111111111111111: "SOL",
  EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v: "USDC",
};

export const CREATE_PROFILE_IX_DISCRIMATOR = [
  225, 205, 234, 143, 17, 186, 50, 220,
];
export const SET_PFP_IX_DISCRIMATOR = [113, 63, 184, 69, 199, 22, 122, 121];
export const CREATE_WALLET_TRACKER_IX_DISCRIMATOR = [
  2, 131, 178, 219, 245, 99, 36, 154,
];
export const TRACK_PFP_IX_DISCRIMATOR = [244, 68, 230, 30, 80, 68, 149, 235];

export const ADD_COUNCIL_MEMBER_IX_DISCRIMATOR = [
  196, 129, 242, 165, 144, 108, 247, 60,
];

export const REMOVE_COUNCIL_MEMBER_IX_DISCRIMATOR = [
  120, 48, 182, 140, 224, 106, 89, 59,
];

export const SET_THRESHOLD_IX_DISCRIMATOR = [
  155, 53, 245, 104, 116, 169, 239, 167,
];

export const SCAM_MINTS = [
  "DUpGEz3tWPEVfmReNrVYq9eLNChHzr4T9hfKfrFq3Pnz",
  "8uqZLvnJFrtmH4hmjJ74DQ2qq9Wpur9XoC4yApmUtLAD",
  "PhiLR4JDZB9z92rYT5xBXKCxmq4pGB1LYjtybii7aiS",
  "FQHKQopgzAGcyaoHSLManGfV7fR7DFaHPMtzyaebGKCq",
  "BvmPsFzVh8gLnUzz44EezDQMSYvtgJoFinPWPgEqinZQ",
];

export const PROPOSAL_STATE_OFFSET = 8;
export const PROPOSAL_PROPOSER_OFFSET = PROPOSAL_STATE_OFFSET + 32 + 1 + 1;
export const PROPOSAL_RANKING_AT_OFFSET =
  PROPOSAL_STATE_OFFSET +
  32 +
  1 +
  1 + //suborg
  32 +
  32;

export const PROPOSAL_RANKING_PERIOD_OFFSET =
  PROPOSAL_RANKING_AT_OFFSET +
  1 +
  8 + //Ranking_at
  1 +
  8 +
  1 +
  8 +
  1 +
  8 +
  1 +
  8 + // approved at
  1 +
  8 +
  1 +
  8 +
  4 + //tx count
  1 +
  32 +
  8 +
  32 +
  1 +
  1 + //council+review
  1 +
  1 +
  1 +
  1; // total abstain

export const REFRESH_DELAY = 1100;
