import BN from "bn.js";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

//a function that takes a bignumber unix timestamp and returns a string of the date
export const unixToDate = (unix: BN) => {
  const date = new Date(unix.toNumber() * 1000);
  return date;
};

export const timeLeft = (rankingAtString: string, rankingPeriod: number) => {
  dayjs.extend(relativeTime);
  const rankingAt = new Date(rankingAtString);
  const closeDate = dayjs.default(rankingAt).add(rankingPeriod, "second");
  return closeDate.fromNow();
};

export const formatDuration = (seconds: number) => {
  // Constants for time conversions
  const secondsPerDay = 86400; // 60 seconds * 60 minutes * 24 hours
  const daysPerMonth = 30.44; // Average days per month
  const daysPerYear = 365.25; // Days per year including leap year corrections

  // Convert seconds to total days
  let days = seconds / secondsPerDay;

  // Format based on the size of the period
  if (days > daysPerYear) {
    // Convert days to years if the period is more than a year
    const years = days / daysPerYear;
    return `${years.toFixed(0)} years`;
  } else if (days > daysPerMonth) {
    // Convert days to months if the period is more than a month
    const months = days / daysPerMonth;
    return `${months.toFixed(0)} months`;
  } else {
    // Use days if the period is less than a month
    return `${days.toFixed(0)} days`;
  }
};
