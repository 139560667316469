import { AnchorProvider, BN, web3 } from "@project-serum/anchor";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Account, Api, SEND_TX_STATUS, SequenceType, WarpAccount, followOrganization, signSendAndConfirmTransactionsWithFees, trackNfts } from "align-sdk";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import CreateProfilelModal from "./CreateProfileModal";

import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { Tab } from "@headlessui/react";
import { CircleStackIcon } from "@heroicons/react/24/outline";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { Mint, createAssociatedTokenAccountIdempotentInstruction, getAssociatedTokenAddressSync, getMint } from "@solana/spl-token";
import { Keypair, PublicKey, Transaction } from "@solana/web3.js";
import { Tooltip } from "antd";
import { delay } from "lodash";
import { ArrowDown, ArrowUp } from "react-feather";
import { useRecoilValue } from "recoil";
import { fetchNfts } from "../api/nfts";
import { REFRESH_DELAY } from "../constants";
import { Exact, GetReputationQuery, useGetReputationQuery } from "../generated/graphql";
import { queryCollectionNfts } from "../state/alignGovernance";
import { isCustomDomainStore } from "../state/domains";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useOrganisation, useRefreshNfts, useWarp } from "../state/hooks/useAlignGovernance";
import { nftsForWallet, organisationNftsForWallet } from "../state/users";
import { HeliusGetAssetResponse } from "../types";
import { truncateKey } from "../utils/truncateKey";
import { createWarpProvider, warpCoinIx, warpNft } from "../warp";
import { AlignLink } from "./AlignLink";
import MintProfilePicture from "./MintProfilePicture";

export function OrganisationBar() {
    const [currentOrgAddress] = useCurrentOrganisation()
    const organisation = useOrganisation(currentOrgAddress)
    const user = useAuth();
    const client = useApolloClient()

    const reputationResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: user?.identity?.identityAddress || "",
            organisationAddress : currentOrgAddress || "" 
        },
        skip: user?.identity?.identityAddress === undefined || currentOrgAddress === undefined
    })

    const reputation = reputationResponse.data?.reputation.totalReputation 

    const fetchUntrackedNfts = async () => {
        if(user?.identity?.identifier === undefined || !currentOrgAddress || !alignPrograms){
            return
        }
        const nfts = await Api.fetchUntrackedTokensForUser(new PublicKey(user?.identity?.identifier), new PublicKey(currentOrgAddress), alignPrograms)
        console.log(nfts)
        setUntrackedNfts(nfts)
    }

    const refreshRep = (variables?: Partial<Exact<{
        userIdentityAddress: string;
        organisationAddress: string;
    }>> | undefined) => {
        fetchUntrackedNfts()
        return reputationResponse.refetch(variables);
    }

    const warps = useWarp(currentOrgAddress)
    const wallet = useWallet()
    const isCustomDomain = useRecoilValue(isCustomDomainStore)

    const alignPrograms = useAlignPrograms()

    const nfts = useRecoilValue(organisationNftsForWallet(currentOrgAddress))
    const [untrackedNfts, setUntrackedNfts] = useState<PublicKey[]>([]);
    const [warpableCoins, setWarpableCoins] = useState<{ warp: Account<WarpAccount>; tokenAccount: PublicKey; mint: Mint}[]>([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [trackModalOpen, setTrackModalOpen] = useState(false);
    useEffect(() => {
        console.log("wraps", warps, warpableCoins)
    }, [warps, warpableCoins]);
    const joinOrganization = () => {
        if( !user ) {
            setModalOpen(true);
            return;
        }
		if( !alignPrograms || !currentOrgAddress ) return;

		const joinOrgTx = async() => {
			if( !wallet.publicKey ) return;
			await toast.promise(
				followOrganization(
					new web3.PublicKey(currentOrgAddress),
                    // nfts.map(x => new web3.PublicKey(x.account)),
                    [],
                    wallet.publicKey,
					alignPrograms
				), {
				loading: "Joining organization...",
				success: "Successfully joined this org!",
				error: (e) => e.toString(),
			  });
            // await trackNFTsTx()

			delay(async () => {
				await refreshRep()
			}, REFRESH_DELAY)
		}

        joinOrgTx()

	}

    const trackNFTsTx = useCallback( async(mints : PublicKey[]) => {

        if( !wallet.publicKey || !user?.identity  || !organisation?.organisation || !alignPrograms || mints.length === 0 ) return;
            
        toast.loading("Initiating NFT tracking for REP.", {id:"trackNfts"})

        const res = await trackNfts(
            new PublicKey(user?.identity?.identifier),
            new PublicKey(organisation?.organisation.address),
            mints,
            wallet.publicKey,
            alignPrograms,
            {
                    onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
                        <b>{`${res.count}/${res.total}: Sending Transactions`}</b>
                        <div>Failed transactions will try again..</div>
                        <div>Please do not refresh or exit..</div>
                    </div>, {
                        id: "trackNfts"
                }),
                onTransactionFailure: (res: any) => toast.error(
                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                        <div>Error:</div>
                        <div>{`${res}`}</div>
                    </div>, {
                        id: "trackNfts"
                })
            }
        )

        if(res.status === SEND_TX_STATUS.SUCESSFUL){
            toast.success("Successfully tracking for REP!", {id: "trackNfts"})
            delay(async () => {
                await client.resetStore()
                await refreshRep()
                await user.refreshLoggedInUser()
            }, REFRESH_DELAY)
        }
        else{
            toast.error("There was an error tracking your nfts. Please try again.", {id:"trackNfts"})
        }
        return
    }, 
    [
        alignPrograms?.provider?.publicKey.toBase58(), 
        user?.identity?.identifier, 
        organisation.organisation?.address,
        wallet?.publicKey,
        
    ]
    )


   useEffect(() => {

    fetchUntrackedNfts() 
   }, [user?.identity?.identifier, wallet?.publicKey, reputation, alignPrograms, nfts])

   useEffect(() => {

    const fetchCoins = async () => {
        if(!currentOrgAddress || !alignPrograms || !wallet?.publicKey || !alignPrograms.provider?.connection){
            return
        }

        const coinPromises = warps.map(async warp => {
            
            //@ts-ignore
            const ata = getAssociatedTokenAddressSync(warp.account.fungibleMint, wallet.publicKey)
            try{
                //@ts-ignore
                const mint = await getMint(alignPrograms.provider.connection, warp.account.fungibleMint)     
                return {
                    warp : warp,
                    tokenAccount : ata,
                    mint: mint
                }     
            }
            catch(e){
                
            }

            // if (account && new BN(account.value.amount).gte(warp.account.fungiblePerNft)){
            //     return {
            //         warp : warp,
            //         tokenAccount : account.value,
            //     }
            // }
            
           
        })
        try{
            const coins = await Promise.all(coinPromises)
            const filtered = coins.filter(coin => !!coin)
            console.log("filtered", filtered)
            //@ts-ignore
            setWarpableCoins(filtered)
        }
        catch(e){
            console.warn(e)
        }

    } 

    fetchCoins() 
    }, [user?.identity?.identifier, wallet?.publicKey, reputation, alignPrograms?.provider, currentOrgAddress, warps.length])
    
    if( !currentOrgAddress ) return <></>

    return (
        <div className="org-bar-container h-full mt-4 mb-2 text-contrast">
            <div className="!bg-opacity-10 bg-contrast  org-bar border-y-boxWidth rounded-md border-accent border-opacity-20 flex flex-wrap px-3 py-3 gap-4 justify-between md:justify-start items-center">
                <div className="flex h-full flex-col justify-center">
                   <AlignLink path={""} orgAddress={organisation.organisation?.address}> 
                        <div className="org-bar-profile flex flex-wrap gap-3 justify-center items-center md:justify-center">

                            <div className="flex items-center gap-2">
                                {organisation?.organisation?.profile?.pfp && <MintProfilePicture mint={organisation?.organisation.profile?.pfp} width={12}/>}

                                <div>
                                    {organisation?.organisation?.profile && <h1 className="text-xl flex items-center gap-2 leading-4">
                                        {organisation?.organisation?.profile?.displayName} 
                                    </h1>}
                                    
                                    <small className="text-sm font-thin opacity-75">{organisation?.organisation?.profile?.username}</small>
                                </div>
                            </div>
                            <div>
                                { reputation !== undefined && (untrackedNfts.length !== 0 || warpableCoins.length !== 0 || warps.length !== 0) && (
                                    <Tooltip title="We do not hold or escrow your NFTs, but we need to sync your NFTs so you can earn the correct REP">
                                        <button className="px-5 h-6 text-sm border bg-white rounded-full text-black hover:border-1 hover:text-black hover:bg-accent mr-2" onClick={() => setTrackModalOpen(true)}>Sync NFT's</button>
                                    </Tooltip>
                                )}

                                { reputation !== undefined ? (
                                    <button className=" px-5 h-6 text-sm disabled:bg-transparent border rounded-full " disabled={true}>Joined</button>
                                ) : (
                                    <button className="px-5 h-6 text-sm border bg-white rounded-full text-black hover:border-1 hover:text-black hover:bg-accent" onClick={joinOrganization}>Join</button>
                                )}
                            </div>
                        </div>
                    </AlignLink>
                </div>
                <div className="org-bar-nav ml-auto mr-4 space-x-6 items-center hidden md:flex">
                    <AlignLink path="overview" orgAddress={`${organisation.organisation?.address}/`}>
                        Overview
                    </AlignLink> 
                    <AlignLink path="" orgAddress={`${organisation.organisation?.address}/`}>
                        Proposals
                    </AlignLink>
                    {!isCustomDomain && (
                        <div className="text-sm text-accent font-light px-2 py-1 border border-accent rounded-full">
                            Your REP <label className=" pl-1 font-bold">{reputation || 0}</label>
                        </div>
                    )}
        
                </div>
                
                { currentOrgAddress &&
                    <Suspense>
                        <NftTrackerModal refreshReputation={refreshRep} warpCoins={warpableCoins} onTrack={(mints) => trackNFTsTx(mints)} organisationAddress={currentOrgAddress} untrackedNfts={untrackedNfts} show={trackModalOpen} onClose={() => setTrackModalOpen(false)}/>
                    </Suspense>
                }   
                <CreateProfilelModal onClose={() => setModalOpen(false)} show={modalOpen}/>
            </div>
        </div>
    )
}

const WarpCoinInfo = ({
    mint,
    organisation,
    name,
    uri,
    collectionMint,
    warp,
    decimals,
    fungiblePerNft,
    refreshReputation
}: {
    mint: string,
    organisation : string | undefined,
    name : string | null,
    uri: string | null
    collectionMint : string,
    warp : string,
    decimals : number,
    fungiblePerNft : string,
    refreshReputation : (variables?: Partial<Exact<{
        userIdentityAddress: string;
        organisationAddress: string;
    }>> | undefined) => Promise<ApolloQueryResult<GetReputationQuery>>
}) => {
    const {publicKey} = useWallet()
    const [imageUri, setimageUri] = useState<string | null>(null);
    const uiCostPreNft = useMemo(() => new BN(fungiblePerNft).div(new BN(Math.pow(10, decimals))).toNumber(), [decimals, fungiblePerNft])
    const user = useAuth()
    const [input, setinput] = useState<number>(uiCostPreNft);
    const [coinBalance, setCoinBalance] = useState<number>(0)
    const {connection} = useConnection()
    const nfts = useRecoilValue(nftsForWallet(publicKey?.toBase58() as string))
    const warpedNfts = useMemo(() => nfts.filter(nft => nft.grouping.find(x => x.group_key === "collection")?.group_value as unknown as string === collectionMint), [nfts, collectionMint])
        const client = useApolloClient()

    const wallet = useAnchorWallet()
    const refreshNfts = useRefreshNfts(wallet?.publicKey?.toBase58())

    useEffect(() => {
        const fetchImage = async () => {
            if (!uri){
                return
            }

            try {

                const res = await fetch(uri)
                const json = await res.json()
                const image = json.image

                if(image){
                    setimageUri(image)
                }
                
            }
            catch(e){
                console.warn("SPl token has no metadata")
            }
        }

        fetchImage()
    }, [uri]);

    useEffect(() => {
        const fetchBalance = async () => {

            if(!publicKey){
                return
            }

            const ata = getAssociatedTokenAddressSync(new PublicKey(mint), publicKey)
            const balance = await connection.getTokenAccountBalance(ata)

            if(!balance?.value?.uiAmount){
                return
            }

            setCoinBalance(balance.value.uiAmount)
        }

        fetchBalance()

    }, [mint, publicKey?.toBase58()]);

    return (
        <div className="flex flex-col w-full bg-black border border-white border-opacity-30 rounded-lg shadow text-contrast px-5 p-2 gap-4">

        <div className="flex justify-center items-center gap-2">
                 {
                  imageUri !== null ? <img className="w-8 rounded-full" src={imageUri}/> : <CircleStackIcon className="w-8"/>
                }
  
            <div className=" text-sm relative flex flex-col gap-3 justify-start items-center py-3">
                          
                {/* <div className=" relative flex gap-3 justify-start items-start ">
                    <label>Cost Per NFT</label>
                    <p>{Number(fungiblePerNft) / Math.pow(10, decimals)}</p>
                 </div> */}
           
                <div>{name ? name : truncateKey(mint)}</div>

            </div>


        </div>
        <div className="flex flex-wrap md:flex-row sm:flex-col justify-around items-center">
            <div className="text-xs font-extralight px-3 py-1 rounded-full bg-contrast bg-opacity-10">
                    <label className="mr-2">Balance </label>
                    <label>{coinBalance}</label>
            </div>
            

            <div className="text-xs font-extralight px-3 py-1 rounded-full bg-contrast bg-opacity-10 w-fit">
                    <label className="mr-2">Locked Coins</label>
                    <label>{new BN(fungiblePerNft).div(new BN(Math.pow(10, decimals))).mul(new BN(warpedNfts.length)).toString()}</label>
            </div>

            <div className="text-xs font-extralight px-3 py-1 rounded-full bg-contrast bg-opacity-10 w-fit">
                    <label className="mr-2">Warped NFT's</label>
                    <label>{warpedNfts.length}</label>
            </div>

            <div className="text-xs font-extralight px-3 py-1 rounded-full bg-contrast bg-opacity-10 w-fit">
                    <label className="mr-2">Cost Per NFT</label>
                    <label>{uiCostPreNft}</label>
            </div>

        </div>

        <div>
            <div className="w-full">
                    <label className="font-light text-sm">Fungible Amount</label>
                    <div className="flex flex-row gap-1 px-3 py-1 border border-contrast border-opacity-40 rounded-md justify-start items-center">
                        <label className="flex-1"> {input}</label>
                        <div className="flex flex-col">
                            <button 
                                className="p-1"
                                onClick={() => setinput(x => x + uiCostPreNft)}
                                >
                                <ArrowUp className=" w-3 h-3 text-primary"/>
                            </button>

                            <button 
                                className="p-1"
                                onClick={() => setinput(x => (x - uiCostPreNft) < 0 ? 0 :  x - uiCostPreNft ) }
                                >
                                <ArrowDown className=" w-3 h-3 text-primary"/>
                            </button>
                        </div>

                    </div>
                </div>
                <button
                    className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                    transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                    disabled={coinBalance < (Number(fungiblePerNft) / Math.pow(10, decimals)) || !warp}
                    onClick={async () => {
                        const provider = new AnchorProvider(connection, wallet as any, {})
                        const warpProgram = createWarpProvider(provider)
                        if (!warp || provider?.sendAndConfirm === undefined ||!warpProgram.provider || !wallet ) {
                            toast.error("Error occured when creating provider. Please raise a ticket in our discord.")
                            return
                        }
                
                        
                        const warpPub = new PublicKey(warp)
                        const instrcutionCount = Math.floor(input / uiCostPreNft)
                        const nftKeypairs = [...Array(instrcutionCount)].map(x => new Keypair())
                        const warpInstructionsPromise = nftKeypairs.map( async key => warpCoinIx(warpPub, key, warpProgram))
                        const warpInstructions = await Promise.all(warpInstructionsPromise)
                        const chunkedCreateInstruction : web3.TransactionInstruction[][] = warpInstructions.reduce((all : any,one : any,i) => {
                            const ch = Math.floor(i/2); 
                            all[ch] = [].concat((all[ch]||[]),one); 
                            return all
                         }, [])

                         const chunkedNftsKeypairs : Keypair[][] = nftKeypairs.reduce((all : any,one : any,i) => {
                            const ch = Math.floor(i/2); 
                            all[ch] = [].concat((all[ch]||[]),one); 
                            return all
                         }, [])
                        const transactions = chunkedCreateInstruction.map(ix => new Transaction().add( web3.ComputeBudgetProgram.setComputeUnitLimit({
                            units: 900_000,
                            //@ts-ignore
                        })).add(...ix))
                        toast.loading("Warping your coins.", {id: "warp"})

                        const ixSet = transactions.map((tx, i) =>  ({
                            instructionsSet: tx.instructions.flatMap(ix => ({
                                transactionInstruction: ix,
                                signers: chunkedNftsKeypairs[i]
                            })),
                            sequenceType: SequenceType.Sequential,
                        }))
                        //@ts-ignore
                        // const sig = await toast.promise(warpProgram.provider.sendAll(transactions.map((tx, i) => ({
                        //     tx: tx,
                        //     signers: chunkedNftsKeypairs[i],
                        // }))), {
                        //     loading: "Warping your coins to NFTs...",
                        //     success: "Successfully warped your coins to NFTs.",
                        //     error: (e) => e.toString(),
                        // })

                      let res = await signSendAndConfirmTransactionsWithFees({
                            connection: warpProgram.provider.connection,
                            wallet: wallet,
                            transactionInstructions: ixSet,
                            confirmLevel: "confirmed",
                            extra: {
                                   onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
                                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
                                        <b>{`${res.count}/${res.total}: Sending Transactions`}</b>
                                        <div>Failed transactions will try again..</div>
                                        <div>Please do not refresh or exit..</div>
                                    </div>, {
                                        id: "warp"
                                }),
                                onTransactionFailure: (res: any) => toast.error(
                                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                        <div>Error:</div>
                                        <div>{`${res}`}</div>
                                    </div>, {
                                        id: "warp"
                                })
                            },
                        });

                        if(res.status === SEND_TX_STATUS.SUCESSFUL){

                            toast.success("Successfully warped your coins.", {id: "warp"})


                            delay(async () => {
                                // await client.resetStore()
                                refreshNfts()
                                refreshReputation()
                            }, REFRESH_DELAY)
                        }

                    }}
                    >
                        Warp Coins
                </button>
                <button
                    className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                    transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                    disabled={warpedNfts.length === 0 || !warp}
                    onClick={async () => {
                        const provider = new AnchorProvider(connection, wallet as any, {})
                        const warpProgram = createWarpProvider(provider)
                        if (!warp || provider?.sendAndConfirm === undefined ||!warpProgram.provider  || !wallet) {
                            toast.error("Error occured when creating provider. Please raise a ticket in our discord.")
                            return
                        }
                
                        
                        const warpPub = new PublicKey(warp)
                        const instrcutionCount = Math.floor(input / uiCostPreNft)
                        const nfts = warpedNfts.slice(0, instrcutionCount)
                        const warpInstructionsPromise = nfts.map( async key => warpNft(warpPub, new PublicKey(key.id), warpProgram))
                        let warpInstructions = await Promise.all(warpInstructionsPromise)
                        const createAta = createAssociatedTokenAccountIdempotentInstruction(provider?.publicKey, getAssociatedTokenAddressSync(new PublicKey(mint), provider?.publicKey), provider.publicKey, new PublicKey(mint) )
                        
                        warpInstructions = [createAta, ...warpInstructions]
                        
                        //@ts-ignore
                        const chunkedCreateInstruction : web3.TransactionInstruction[][] = warpInstructions.reduce((all : any,one : any,i) => {
                            const ch = Math.floor(i/2); 
                            all[ch] = [].concat((all[ch]||[]),one); 
                            return all
                         }, [])
                         const transactions = chunkedCreateInstruction.map(ix => new Transaction().add( web3.ComputeBudgetProgram.setComputeUnitLimit({
                            units: 900_000,
                            //@ts-ignore
                        })).add(...ix))
                        const ixSet = transactions.map(tx =>  ({
                            instructionsSet: tx.instructions.flatMap(ix => ({
                                transactionInstruction: ix,
                            })),
                            sequenceType: SequenceType.Sequential,
                        }))
                        //@ts-ignore
                        // const sig = await toast.promise(warpProgram.provider.sendAll(transactions.map((tx, i) => ({
                        //     tx: tx,
                        //     signers: [],
                        // }))), {
                        //     loading: "Warping your coins to NFTs...",
                        //     success: "Successfully warped your coins to NFTs.",
                        //     error: (e) => e.toString(),
                        // })
                        toast.loading("Warping your nfts.", {id: "warp"})

                        let res = await signSendAndConfirmTransactionsWithFees({
                            connection: warpProgram.provider.connection,
                            wallet: wallet,
                            transactionInstructions: ixSet,
                            confirmLevel: "confirmed",
                            extra: {
                                   onTransactionConfirmation: (res: { count: number; total: number; }) => toast.loading(
                                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr 1fr"}}>
                                        <b>{`${res.count}/${res.total}: Sending Transactions`}</b>
                                        <div>Failed transactions will try again..</div>
                                        <div>Please do not refresh or exit..</div>
                                    </div>, {
                                        id: "warp"
                                }),
                                onTransactionFailure: (res: any) => toast.error(
                                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                        <div>Error:</div>
                                        <div>{`${res}`}</div>
                                    </div>, {
                                        id: "warp"
                                })
                            },
                        });

                        if(res.status === SEND_TX_STATUS.SUCESSFUL){

                            toast.success("Successfully warped your nfts!", {id: "warp"})


                            delay(async () => {
                                // await client.resetStore()
                                refreshNfts()
                                refreshReputation()
                            }, REFRESH_DELAY)
                        }

                    }}
                    >
                        Un-warp Coins
                </button>
            </div>
        </div>

     );
}
 

export const NftTrackerModal = ({
    warpCoins,
    untrackedNfts,
    onClose,
    show,
    organisationAddress,
    onTrack,
    refreshReputation

} : {
    warpCoins : { warp: Account<WarpAccount>; tokenAccount: PublicKey; mint: Mint }[],
    untrackedNfts : PublicKey[], 
    onClose : () => void, 
    show: boolean,
    organisationAddress: string,
    onTrack :  (mints : PublicKey[]) => Promise<void>,
    refreshReputation : (variables?: Partial<Exact<{
        userIdentityAddress: string;
        organisationAddress: string;
    }>> | undefined) => Promise<ApolloQueryResult<GetReputationQuery>>
}) => {

    const wallet = useWallet()
    const mountedRef = useRef(true)
    const nfts = useRecoilValue(nftsForWallet(wallet.publicKey?.toBase58()))
    const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 60;
	const [imageLoaded, setImageLoaded] = useState<boolean[]>([]);
    const collections = useRecoilValue(queryCollectionNfts(organisationAddress))
    const feePerNft = 0.01236
    const {connection} = useConnection()
    const [coinMeta, setCoinMeta] = useState<{ warp: Account<WarpAccount>; tokenAccount: PublicKey; metadata: Metadata, mint: Mint }[]>([])
    const [selected, setSelected] = useState<PublicKey[]>([]);
    const [isLedger, setisLedger] = useState(false);
    const organisationNfts = useMemo(() => {
        console.log("pre filter",nfts, collections)
        return nfts.filter(nft => collections.findIndex(x => x.mint.toBase58() === nft.grouping.find(x => x.group_key === "collection")?.group_value as unknown as string) !== -1)
    }
        , [nfts.length, collections.length])
    const maxPage = useMemo(()=> Math.ceil(organisationNfts.length / itemsPerPage), [itemsPerPage, organisationNfts.length] );
	const getPageItems = () => {
	  const startIndex = (currentPage - 1) * itemsPerPage;
	  const endIndex = startIndex + itemsPerPage;
      console.log("nfts", organisationNfts)
	  return organisationNfts.slice(startIndex, endIndex);
	};
  
	const nextPage = () => {
	  if (currentPage < maxPage) {
		setCurrentPage(currentPage + 1);
	  }
	};
  
	const prevPage = () => {
	  if (currentPage > 1) {
		setCurrentPage(currentPage - 1);
	  }
	};

    useEffect(() => {
        setSelected([])
    }, [untrackedNfts.length]);
  
	useEffect(() => {
		setImageLoaded([]);
	  }, [currentPage]);

      useEffect(() => {
        const controller = new AbortController()
        const fetchMetadata = async () => {
            const metadataPromises = warpCoins.map(async (coin) => {
                // const address = getMetadataAddress(coin.warp.account.fungibleMint)
                // console.log(address)
                try {
                    const account = await fetchNfts([coin.warp.account.fungibleMint.toBase58()], true, controller.signal)
                    console.log(account[0]?.content.json_uri)
                    return {
                        ...coin,
                        metadata : {data: {...account[0]?.content.metadata, uri: account[0]?.content.json_uri}}
                    }
                }
                catch(e){
                    console.log(e)
                   return coin
                }

            })
    
            const coinMeta = await Promise.all(metadataPromises)
            console.log("coins", warpCoins, organisationAddress, coinMeta)
            //@ts-ignore
            setCoinMeta(coinMeta.filter(x => x !== undefined))
        }
        fetchMetadata()
        
        return () => {mountedRef.current = false; controller.abort()}

      }, [warpCoins.length, organisationAddress, wallet?.publicKey, mountedRef])

    return ( 
    <div
        id="defaultModal"
        tabIndex={-1}
        aria-hidden="true"
        className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-secondary bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${show ? "" : "hidden"
        }`}>
        <div className="relative w-full h-full max-w-2xl md:h-auto text-contrast">
        <div className="relative bg-secondary border border-white border-opacity-30 rounded-lg shadow text-contrast p-4 md:p-10">
  
            <div className="flex flex-col items-start justify-evenly">
            <Tab.Group>
                <Tab.List className="w-full mt-1 mb-5 flex justify-center">
                    <div className="flex overflow-auto border border-contrast border-opacity-30 rounded-md p-1 gap-4 w-full justify-evenly mt-6">
                    <Tab className="whitespace-nowrap top-5 ui-not-selected:bg-transparent ui-not-selected:text-white right-6 flex justify-center gap-2 items-center rounded-md bg-accent border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs"
                                    >
                                        Sync NFT's
                                    </Tab>
                    <Tab 
                        // disabled={warpCoins.length === 0} 
                        className="whitespace-nowrap top-5 ui-not-selected:bg-transparent ui-not-selected:text-white right-6 flex justify-center gap-2 items-center rounded-md bg-accent border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs"
                                    >
                                        Warp Fungibles
                                    </Tab>
                    </div>
                </Tab.List> 


            <Tab.Panels>
                <Tab.Panel>
                    {/* <h3 className="text-xl font-semibold mb-6">
                        Sync Your NFT's
                    </h3> */}

                    <p className="text-sm font-base md:text-base mb-6">
                    Syncing your NFT allows you to participate in the organization. 
                    More synced NFTs means more reputation points, however, this is not the only way you earn reputation points. 
                    This is a non-custodial process, we do not take custody of your NFTs like traditional staking. 
                    

                    </p>
                    <p className="text-sm font-base md:text-base mb-6"><b>Syncing NFTs can get expensive quite quickly, you only need to sync one to participate in the organisation.</b></p>
                    <div className="p-3 flex gap-8 flex-col ">
                        <div className="flex border border-contrast border-opacity-60 rounded-md  p-2">
                            <div className="flex flex-col gap-1 items-start justify-center">
                                <label className="text-xs font-light">Tokens to track: <label className="">{selected.length}</label></label>
                                <label className="text-xs font-light">Estimated TX fees: <label>{Math.round((selected.length * feePerNft) * 10000)/ 10000}</label></label>
                        
                            </div>
                            <div className="flex-1"/>
                            <div className="flex items-center justify-center gap-2">
                                
                                <button
                                    onClick={() => setSelected(untrackedNfts.slice(0, untrackedNfts.length / 2))} 
                                    className="w-full flex justify-center gap-2 items-center bg-accent rounded-md hover:opacity-90 disabled:opacity-20
                                                transition font-poppins font-medium text-xs px-1.5 py-1 text-center">half</button>
                                <button
                                    onClick={() => setSelected([...untrackedNfts])} 
                                    className="w-full flex justify-center gap-2 items-center bg-accent rounded-md hover:opacity-90 disabled:opacity-20
                                                transition font-poppins font-medium text-xs px-1.5 py-1 text-center">all</button>
                            </div>
                            {/* <div className="flex items-center justify-center">
                                
                            </div> */}
                        </div>
                
                        <div className="grid grid-cols-8 md:grid-cols-12 gap-2 ">

                            {getPageItems()
                            .filter((pfp: HeliusGetAssetResponse) => pfp.content.metadata.name !== 'id')
                            .map(pfp => {console.log(pfp); return pfp})
                            .map((pfp: HeliusGetAssetResponse, index: number) => {
                            return (
                                <button
                                key={pfp.id}
                                disabled={!pfp.grouping.find(x => x.group_key === "collection")?.group_value || untrackedNfts.findIndex(untracked => untracked.toBase58() === pfp.id as unknown as string) === -1}
                                className={`${!pfp.grouping.find(x => x.group_key === "collection")?.group_value? 'opacity-50 cursor-not-allowed' : ''} 
                                ${selected.findIndex(x => x.toBase58() === pfp.id as unknown as string) !== -1 ? "outline outline-accent" : "" }`}

                                onClick={() => {
                                    const selectedIndex = selected.findIndex(x => x.toBase58() === pfp.id as unknown as string)
                                    if( selectedIndex === -1) {
                                        setSelected( arr => [...arr, new PublicKey(pfp.id) ])
                                        return
                                    }
                                    setSelected(arr => arr.filter(x => x.toBase58() !== pfp.id as unknown as string))
                                    //@ts-ignore
                                    // setPfp(pfp.onChainMetadata.metadata.mint);
                                    // onClose();
                                }}
                                >
                                {/* {!imageLoaded[index] && (
                                    <div className="w-12 h-12 flex items-center justify-center bg-contrast bg-opacity-25 animate-pulse">
                                        
                                    </div>
                                )} */}
                                                    <Tooltip 
                                    title={
                                        untrackedNfts.findIndex(untracked => untracked.toBase58() === pfp.id as unknown as string) === -1 ? 
                                        "This nft is currently being tracked"
                                        :  null
                                    }
                                >
                                    <img
                                        src={pfp?.content?.links.image ? pfp?.content?.links.image : "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"}
                                        alt={pfp.content.metadata.name}
                                        className={`w-12 h-auto ${untrackedNfts.findIndex(untracked => untracked.toBase58() === pfp.id as unknown as string) === -1 ? 'opacity-50' : ''} `}
                                        // onLoad={() => {
                                        // 	const newImageLoaded = [...imageLoaded];
                                        // 	newImageLoaded[index] = true;
                                        // 	setImageLoaded(newImageLoaded);
                                        // }}
                                        // onError={() => {
                                        // 	const newImageLoaded = [...imageLoaded];
                                        // 	newImageLoaded[index] = true;
                                        // 	setImageLoaded(newImageLoaded);
                                        // }}
                                        
                                    />
                                </Tooltip>
                                </button>
                            )})}
                            
                        </div>
                        
                        <div className="flex items-center text-sm justify-center mt-4">
                            <button onClick={prevPage} disabled={currentPage === 1} className="bg-transparent hover:opacity-90 rounded-lg p-2 mr-2">
                                <span className="sr-only">Previous Page</span>
                                &larr;
                            </button>
                            <span>Page {currentPage} of {maxPage}</span>
                            <button onClick={nextPage} disabled={currentPage === maxPage} className="bg-transparent hover:opacity-90 rounded-lg p-2 ml-2">
                                <span className="sr-only">Next Page</span>
                                &rarr;
                            </button>
                        </div>
                    </div>
                    <button
                    disabled={selected.length === 0}
                    className={`w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary hover:opacity-90 disabled:opacity-20
                    transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center`}
                    onClick={async () => {
                        await onTrack(selected)
                        onClose()
                    }}
                    >
                        Sync Tokens
                    </button>
                    
                    <button
                        onClick={() => onClose()}
                        type="button"
                        className="bg-transparent hover:opacity-90 absolute right-2 top-2 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        data-modal-toggle="defaultModal"
                    >
                        <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </Tab.Panel>

                <Tab.Panel>
                    {/* <h3 className="text-xl font-semibold mb-6">
                        Warp Your Fungibles
                    </h3> */}

                    <p className="text-sm font-base md:text-base mb-6">
                        Some organisations have fungible assets that they which to use for access & vote weight in the DAO.
                        This is made possible by "Warp", a smart contract to lock up fungible "coins" in return for an NFT belonging to a unique collection. 
                        To mint a warped NFT you must provide a certain amount of fungible coins to be locked into the contract. To redeem your coins out of the 
                        smart contract you must burn your Warp NFT via the Warp program.
                    </p>

                    {coinMeta.map(coin => (
                        <WarpCoinInfo 
                        // key={coin.tokenAccount.toBase58()}
                            refreshReputation={refreshReputation}
                            mint={coin.warp.account.fungibleMint.toBase58()} 
                            organisation={organisationAddress}
                            name={coin?.metadata?.data?.name} 
                            uri={coin?.metadata?.data.uri}
                            collectionMint={coin.warp.account.collectionMint.toBase58()} 
                            warp={coin.warp.address.toBase58()} 
                            decimals={coin.mint.decimals} 
                            fungiblePerNft={coin.warp.account.fungiblePerNft.toString()}
                            />
                    ))}

                    <button
                        onClick={() => onClose()}
                        type="button"
                        className="bg-transparent hover:opacity-90 absolute right-2 top-2 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        data-modal-toggle="defaultModal"
                    >
                        <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                </Tab.Panel>
            </Tab.Panels>
            </Tab.Group>

            </div>
        </div>
        </div>
    </div>
     );
}
