import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingSpinner from "../components/LoadingSpinner";
import { useAlignPrograms, useAuth, useCurrentOrganisation, usePfp, useReputationValue } from "../state/hooks/useAlignGovernance";

import { Metaplex } from '@metaplex-foundation/js';
import { editUserProfile } from "align-sdk";
import { Tooltip } from "antd";
import { delay } from "lodash";
import toast from "react-hot-toast";
import { useRecoilValue } from "recoil";
import { REFRESH_DELAY } from "../constants";
import { nftsForWallet } from "../state/users";
import { HeliusGetAssetResponse } from "../types";


export function ProfileEdit() {
	const [organisation] = useCurrentOrganisation()
	const [modalPfpOpen, setModalPfpOpen] = useState(false);
    const wallet = useWallet()
	const { connection } = useConnection();
	const user = useAuth()
	const [pfp, setPfp] = useState();

	const currentPfp = usePfp(user?.identity?.identifier);
	const [pfpImage, setPfpImage] = useState<string>(currentPfp?.pfp || '');
	const reputation = useReputationValue(user?.identity?.identifier)


	const alignPrograms = useAlignPrograms()

	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { 
			username: user?.username,
			display_name: user?.displayName,
			pfp: currentPfp?.pfp
		 }
	});
	
	useMemo(() => {
		const getPfp = async () => {
			if( user && user?.hasProfile && pfp ) {
				const metaplex = new Metaplex(connection);
				const mintAddress = new PublicKey(pfp);
				const nft = await metaplex.nfts().findByMint({ mintAddress });
				if( nft.json?.image ) {
					setPfpImage(nft.json.image);
				}
			} 
		}
		getPfp();
	}, [user, modalPfpOpen, pfp])

	// 
	// const pfpDisplay = exampleAvatar

	const onSubmit = (data:any, e:any) => {
		const editProfileTx = async() => {
			if( !alignPrograms || !wallet.publicKey || !user?.identity?.identifier) return;

			if( !wallet.publicKey ) return;
			if( !pfp && !currentPfp?.mint){
				return
			}
			const mint = pfp || currentPfp?.mint
			if(!mint) return

			let displayName;
			if(user?.displayName !== data.display_name){
				displayName = data.display_name
			}
			await toast.promise(
				editUserProfile(
					new PublicKey(user?.identity?.identifier),
					alignPrograms,
					{
						pfpMint: new PublicKey(mint),
						pfpOwnerAddress : wallet.publicKey,
						displayName,
						
					}
				), {
				loading: "Editing your profile...",
				success: "Profile edited successfully!",
				error: (e) => e.toString(),
			  });
			  delay(async () => await user.refreshLoggedInUser(), REFRESH_DELAY)
		}
		editProfileTx();
	}

    if (!wallet?.publicKey) {
        return <div />;
    }

    if (!user) {
        return (
            <div className="max-w-6xl mx-auto flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="mx-auto max-w-screen-2xl px-8 py-8 text-contrast">
            <div className="flex-wrap justify-between gap-6 max-w-xl mx-auto">
                <main className="edit-profile-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 w-full p-6 lg:col-span-2">
                    <h1 className="font-heading text-lg md:text-2xl mb-12">Edit Profile</h1>
            
                    <div className="w-full">
                        <div className="-mt-5 flex">
							<button className="hover:opacity-80" onClick={() => {
								setModalPfpOpen(true)
							}}>
								{pfpImage ? (
									<img
									className="h-24 w-24 rounded-full border border-white border-opacity-30"
									src={pfpImage}
									alt="avatar"
									/>
								) : (
									<span className="h-24 w-24 rounded-full flex items-center justify-center border relative border-white border-opacity-30">
										<svg className="w-12 h-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"></path></svg>
									</span>
								)}
							</button>
                            <p className="ml-3 mt-6 text-xl">
                                <span className="block">
                                    {user?.username?.slice(1)}
                                </span>
                                <span className="block text-xs font-thin opacity-50">
                                    {user?.username}
                                </span>
                            </p>
                            <p className="flex grow"></p>
                            <p className="align-right mr-4 mt-6 items-end justify-end text-right text-xl">
                                <span className="block">{reputation.toString()} REP</span>
                                <span className="block text-xs font-thin opacity-50">
                                    {/* {userPropCount} Proposal(s) */}
                                </span>
                            </p>
                        </div>

						<form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mt-4">							
							{/* include validation with required or other standard HTML validation rules */}
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
								<div>
									<label>Username</label>
									<input {...register("username", { disabled: true })} className="input" placeholder="@satoshi"/>
								</div>
								<div>
										<label>Display Name</label>
										{errors.display_name?.type === 'pattern' && <span className="pl-3 text-xs text-red-500 ">Display name not valid</span>}

										<input {...register("display_name", { 
											required: true,
											pattern: /^(?=.*[A-Za-z0-9])[A-Za-z0-9_\-.,!?()\[\]{}<>"' ]{3,22}$/
											})} 
											className="input" placeholder="Satoshi Nakamoto"/>
									</div>
								<div>
									{/* <input {...register("pfp")} className="input" type="hidden"/> */}
								</div>
							</div>

							{/* <div>
								<label>Twitter Link (optional)</label>
								<input {...register("twitter_link", { required: false })} className="input" placeholder="twitter.com/satoshi"/>
							</div>
							<div>
								<label>Discord ID (optional)</label>
								<input {...register("twitter_link", { required: false })} className="input" placeholder="satoshi#5454"/>
							</div> */}
							
							{/* errors will return when field validation fails  */}
							{(errors as any).content ? true : false && <p>This field is required</p>}
							<button type="submit" className="btn">Save Profile</button>
						</form>
                    </div>
				</main>
            </div>

			<Suspense>
            	<PfpModal onClose={() => setModalPfpOpen(false)} setPfp={(pfp:any) => setPfp(pfp)} show={modalPfpOpen}/>
          	</Suspense>
        </div>
    );
}


export function PfpModal({onClose, setPfp, show} : {onClose: () => void, setPfp: (pfp:string) => void, show : boolean}) {
	const wallet = useWallet()
    const nfts = useRecoilValue(nftsForWallet(wallet.publicKey?.toBase58() as string))
	const [imageLoaded, setImageLoaded] = useState<boolean[]>([]);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 12;
  
	const maxPage = useMemo(()=>Math.ceil(nfts.length / itemsPerPage), [itemsPerPage, nfts.length] );
  
	const getPageItems = () => {
	  const startIndex = (currentPage - 1) * itemsPerPage;
	  const endIndex = startIndex + itemsPerPage;
	  return nfts.slice(startIndex, endIndex);
	};
  
	const nextPage = () => {
	  if (currentPage < maxPage) {
		setCurrentPage(currentPage + 1);
	  }
	};
  
	const prevPage = () => {
	  if (currentPage > 1) {
		setCurrentPage(currentPage - 1);
	  }
	};
  
	useEffect(() => {
		setImageLoaded([]);
	  }, [currentPage]);

  
	return <div
	  id="defaultModal"
	  tabIndex={-1}
	  aria-hidden="true"
	  className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-secondary bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${show ? "" : "hidden"
		}`}
	>
	  <div className="relative w-full h-full max-w-2xl md:h-auto text-contrast">
		<div className="relative bg-black border border-white border-opacity-30 rounded-lg shadow text-contrast p-10">
		  <div className="flex items-start justify-between">
			<h3 className="text-xl font-semibold mb-6">
			  Pick PFP
			</h3>
			<button
			  onClick={() => onClose()}
			  type="button"
			  className="bg-transparent hover:opacity-90  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
			  data-modal-toggle="defaultModal"
			>
			  <svg
				aria-hidden="true"
				className="w-5 h-5"
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			  >
				<path
				  fillRule="evenodd"
				  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
				  clipRule="evenodd"
				></path>
			  </svg>
			  <span className="sr-only">Close modal</span>
			</button>
		  </div>
		  <div className="grid grid-cols-4 gap-6">
		  {
			getPageItems()
				.filter((pfp: HeliusGetAssetResponse) => pfp?.content.metadata.name !== 'id')
				.map((pfp: HeliusGetAssetResponse, index: number) => {
				return (
					<button
					key={pfp.id}
					disabled={!pfp.grouping.find(x => x.group_key === "collection")?.group_value }
					className={`${!pfp.grouping.find(x => x.group_key === "collection")?.group_value  ? 'opacity-50 cursor-not-allowed' : ''}`}
					onClick={() => {
						setPfp(pfp.id);
						onClose();
					}}
					>

					<Tooltip 
						title={
							!pfp.grouping.find(x => x.group_key === "collection")?.group_value  ? 
							"NFT's used in align ecosystem need to have a verified collection nft associated. Please contact the project owners to get them to add a collection nft."
							:  null
						}
					>
						<img
							src={pfp?.content?.links.image ? pfp?.content?.links.image : "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"}
							alt={pfp?.content.metadata.name}
							className={`w-32 h-auto ${!pfp.grouping.find(x => x.group_key === "collection")?.group_value  ? 'opacity-50' : ''}`}
						/>
					</Tooltip>

					</button>
				);
				})
			}
    	</div>
				<div className="flex items-center justify-center mt-4">
				<button onClick={prevPage} disabled={currentPage === 1} className="bg-transparent hover:opacity-90 p-2 mr-2">
					<span className="sr-only">Previous Page</span>
					&larr;
				</button>
				<span>Page {currentPage} of {maxPage}</span>
				<button onClick={nextPage} disabled={currentPage === maxPage} className="bg-transparent hover:opacity-90 p-2 ml-2">
					<span className="sr-only">Next Page</span>
					&rarr;
				</button>
				</div>
		</div>
	  </div>
  </div>
  }