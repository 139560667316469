import { DocumentTextIcon, ForwardIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "antd";
import { useAtom } from "jotai";
import { useState } from "react";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import { useSetRecoilState } from "recoil";
import remarkGfm from "remark-gfm";
import { proposalWizardDescriptionStepInputs } from "../../../state/forms";
import { DescriptionPanel } from "../../DescriptionPanel";
import { themeOpts } from "../../ThemeChanger";
import { WizardStepProps } from "../Wizard";

const DescriptionStep = ({ onComplete }: WizardStepProps<{}>) => {
  const [name, setName] = useState("");
  const [previewMarkdown, setPreviewMarkdown] = useState(false);
  const [description, setDescription] = useState("");
  const [theme, setTheme] = useAtom(themeOpts);
  const setInputs = useSetRecoilState(proposalWizardDescriptionStepInputs);

  const handleClick = () => {
    if (name.trim().length === 0 || description.trim().length === 0) {
      toast.error("Fields cannot be left empty");
    } else {
      setInputs({
        title: name,
        description,
      });
      onComplete({});
    }
  };

  return (
    <>
      <main
        className={`proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col w-full lg:col-span-2 ${
          theme.gridLayout && "p-3 sm:p-6 bg-secondary bg-opacity-30 border-boxWidth"
        }`}
      >
        <h1 className="font-heading text-lg md:text-3xl mb-6">Create Proposal</h1>
        <DescriptionPanel message="Add a title and description for your proposal." />
        <div className="space-y-6 h-full w-full flex flex-col mt-6">
          <input
            value={name}
            placeholder={"Proposal Title"}
            onChange={(e) => setName(e.target.value)}
            className={`input block mt-1 p-3 w-full h-24`}
          />
          <div className="input relative rounded border flex-1">
            <div
              className={`${
                previewMarkdown ? "opacity-90" : ""
              } hover:opacity-90 hover:cursor-pointer opacity-70 px-1 py-1 rounded-lg bg-accent absolute right-3 top-2`}
            >
              <Tooltip title="Preview Markdown">
                <DocumentTextIcon className={`w-5`} onClick={() => setPreviewMarkdown(!previewMarkdown)} />
              </Tooltip>
            </div>
            {previewMarkdown ? (
              <div className="markdown-container">
                <ReactMarkdown
                  className="max-w-none markdown prose prose-invert text-contrast"
                  children={description}
                  remarkPlugins={[remarkGfm]}
                />
              </div>
            ) : (
              <textarea
                value={description}
                placeholder={"# Proposal Description"}
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
                className={`flex-1 bg-transparent p-3 w-full min-h-full h-80`}
              />
            )}
          </div>
        </div>
          <button
          className={`w-full mb-2 flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center ${
            name.trim().length === 0 || description.trim().length === 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleClick}
        >
          <ForwardIcon className={`w-4`} />
          Next Step
        </button>
      </main>

    </>
  );
};

export default DescriptionStep;
