import { WalletIcon } from "@heroicons/react/24/outline";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletModalButton } from "@solana/wallet-adapter-react-ui";
import { useNavigate } from "react-router-dom";

export default function CreateProposalModal({onClose, show} : {onClose: () => void, show : boolean}) {
    const navigate = useNavigate();
    const wallet = useWallet();


    return <div
    id="defaultModal"
    tabIndex={-1}
    aria-hidden="true"
    className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-secondary bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${show ? "" : "hidden"
      }`}
  >
    <div className="relative w-full h-full max-w-2xl md:h-auto text-contrast">
      <div className="relative bg-black border border-white border-opacity-30 rounded-lg shadow text-contrast p-10">
        <div className="flex flex-col items-start justify-between">
          <h3 className="text-xl font-semibold mb-6">
            You need to create a profile!
          </h3>
          <p className="text-base font-base mb-6">
            Before you can create a proposal or interact with organizations you need to create a profile.
          </p>

          {wallet.publicKey?.toBase58() ? (
            <button
              onClick={() => {
                navigate(`/u/${wallet.publicKey?.toBase58()}/create`);
              }}
              type="button"
              className="w-full mt-6 bg-accent rounded-button border-primary  transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
              data-modal-toggle="defaultModal"
            >
            Create Profile
          </button>
          ) : (
            <div className="flex items-center gap-3 bg-accent rounded-lg h-12 w-fit px-5">
                <WalletIcon className="w-5 h-5"/>
                <WalletModalButton style={{ height: "3rem", padding: "0rem", fontFamily: "Poppins, sans-serif", fontWeight: "normal", background: "transparent", color: "black" }}/>
            </div>
          )}
          
          <button
            onClick={() => onClose()}
            type="button"
            className="bg-transparent hover:opacity-90 absolute right-2 top-2 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="defaultModal"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
      </div>
    </div>
</div>
  
}
