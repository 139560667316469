export const prettyRoundedStringBalance = (
  uiAmount: string,
  decimal: number = 2,
) => {
  const rounded = (
    Math.floor(Number(uiAmount) * Math.pow(10, decimal)) / Math.pow(10, decimal)
  ).toString();
  // const rounded = uiAmount.split(".")[0]

  if (rounded.length > 9) {
    return prettyStringBalance(rounded.slice(0, -9)) + "B";
  } else if (rounded.length > 6) {
    return prettyStringBalance(rounded.slice(0, -6)) + "M";
  }

  return prettyStringBalance(rounded);
};

export const prettyStringBalance = (uiAmount: string) => {
  var str = uiAmount.toString().split(".");
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }
  return str.join(".");

  // Cant use in safari due to lack of support for look behind regex
  // return uiAmount.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
};
