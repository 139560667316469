import { JsonMetadata } from "@metaplex-foundation/js";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { Mint } from "@solana/spl-token";
import { AccountInfo } from "@solana/web3.js";
import { HeliusGetAssetResponse } from "../types";
import { postData } from "./common";

export type Jsonized<T> = T extends object
  ? {
      [K in keyof T]: T[K] extends Function
        ? never
        : T[K] extends Date
          ? string
          : T[K] extends number
            ? number
            : T[K] extends string
              ? string
              : Jsonized<T[K]>;
    }
  : T;

export interface OnChainAccountInfo {
  accountInfo: Jsonized<AccountInfo<Mint>> & { key: string };
  error: string;
}

export interface HeliusMetadataAccountInfo<T> {
  metadata: Jsonized<T>;
  error: string;
}

export interface HeliusNft {
  account: string;
  onChainAccountInfo: OnChainAccountInfo | undefined;
  onChainMetadata: HeliusMetadataAccountInfo<Metadata>;
  offChainMetadata: HeliusMetadataAccountInfo<JsonMetadata> | undefined;
}

export const getAssetsBatch = async (
  mints: string[],
  abortSignal?: AbortSignal,
): Promise<HeliusGetAssetResponse[]> => {
  const data = {
    jsonrpc: "2.0",
    id: "align",
    method: "getAssetBatch",
    params: {
      displayOptions: {
        showUnverifiedCollections: true,
        showCollectionMetadata: true,
      },
      ids: mints,
    },
  };

  const uri = new window.URL(`https://mainnet.helius-rpc.com/`);
  uri.searchParams.append("api-key", process.env.REACT_APP_HELIUS_API_KEY!!);

  try {
    const response = await postData(uri.toString(), data, abortSignal);
    return response?.result;
  } catch (e) {
    console.warn(e);
    return [];
  }
};
export const fetchNfts = async (
  mints: string[],
  withOffChainData: boolean = true,
  abortSignal?: AbortSignal | undefined,
): Promise<HeliusGetAssetResponse[]> => {
  // if (process.env.REACT_APP_DEVELOPMENT!!){
  //     const connection = new Connection(process.env.REACT_APP_SOL_RPC!!, "confirmed")
  //     const metadataPromise = mints.map(async mint => await getMetadataAccount(new PublicKey(mint), connection ))
  //     const metadatas = await  Promise.all(metadataPromise)
  //     const offchainPromises = metadatas.filter(x => x!==null && x!==undefined).map(async metadata => {
  //         try {
  //             const res = await fetch( metadata.data.uri)
  //             return res.json()
  //         }
  //         catch(e){

  //         }

  //     })
  //     const offchain : PromiseSettledResult<JsonMetadata>[] = await  Promise.allSettled(offchainPromises)
  //     console.log(offchain)
  //     const formated : HeliusGetAssetResponse[] = metadatas.filter(x => x!==null && x!==undefined).map((meta, i) => {
  //         return {
  //             id : meta.mint.toBase58(),
  //             content: {
  //                 metadata: {
  //                     JSON.parse(JSON.stringify(meta))
  //                 }
  //             },
  //             onChainMetadata: {
  //                 metadata: JSON.parse(JSON.stringify(meta)),
  //                 error : ""
  //             },
  //             offChainMetadata: {
  //             //@ts-ignore
  //                 metadata: JSON.parse(JSON.stringify(offchain[i].status !== "rejected" && offchain[i]?.value? offchain[i].value : {})),
  //                 error : ""
  //             }

  //         }})
  //         return formated

  // }

  return getAssetsBatch(mints);
};
