import {
  Metadata,
  metadataBeet,
  PROGRAM_ADDRESS,
  PROGRAM_ID,
  TokenStandard,
  createTransferInstruction as createTransferPnftInstruction,
} from "@metaplex-foundation/mpl-token-metadata";
import { BN, Provider, utils, Wallet, web3 } from "@project-serum/anchor";
import {
  ShadowDriveResponse,
  ShadowFile,
  ShadowUploadResponse,
  ShdwDrive,
} from "@shadow-drive/sdk";
import {
  createAssociatedTokenAccountInstruction,
  createCloseAccountInstruction,
  createSyncNativeInstruction,
  createWrappedNativeAccount,
  decodeTransferCheckedInstruction,
  getAssociatedTokenAddress,
  NATIVE_MINT,
  TokenInstruction,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_2022_PROGRAM_ID,
  getAccount,
  getMint,
  createTransferCheckedInstruction,
} from "@solana/spl-token";
import {
  PublicKey,
  SystemProgram,
  SYSVAR_INSTRUCTIONS_PUBKEY,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { ProposalData } from "./types";
import { u8 } from "@solana/buffer-layout";
import {
  ALIGN_PROGRAM_ID,
  DEFAULT_PRIORITY_LAMPORT,
  SHADOW_MINT,
  TOKEN_AUTH_RULES_ID,
  WRAPPED_SOL_ADDRESS,
} from "./constants";
import { bs58 } from "@project-serum/anchor/dist/cjs/utils/bytes";
import {
  TransactionInstructionWithType,
  sendSignAndConfirmTransactions,
  sendSignAndConfirmTransactionsProps,
} from "./mangolana";
import {
  SequenceType,
  TransactionInstructionWithSigners,
} from "@blockworks-foundation/mangolana/lib/globalTypes";

export const isBrowser =
  typeof window !== "undefined" && !window.process?.hasOwnProperty("type");
export const TOKEN_2022_META_PROGAM_ID = new PublicKey(
  "META4s4fSmpkTbZoUsgC1oBnWB31vQcmnN8giPw51Zu",
);
export const SHADOW_MIN_FILE_SIZE_KB = 4;
export enum SEND_TX_STATUS {
  FAILED,
  SUCESSFUL,
}
export const hasMinShadow = async (
  wallet: web3.PublicKey,
  connection: web3.Connection,
  amount: number,
) => {
  const ata = getAssociatedTokenAddressSync(SHADOW_MINT, wallet);
  try {
    const balance = await connection.getTokenAccountBalance(ata);
    if (balance.value.uiAmount > amount) {
      return true;
    } else false;
  } catch (e) {
    return false;
  }
};
export const fetchJupiterRoutes = async (
  fromMint: web3.PublicKey,
  toMint: web3.PublicKey,
  amount: BN,
  slippage: number,
) => {
  const quoteResponse = await (
    await fetch(
      `https://quote-api.jup.ag/v6/quote?inputMint=${fromMint.toBase58()}&outputMint=${toMint.toBase58()}&amount=${amount.toString()}&slippageBps=${slippage}&asLegacyTransaction=true`,
    )
  ).json();
  return quoteResponse;
};

export const swapSolToShadow = async (
  wallet: Wallet,
  provider: Provider,
  priorityFee: number = DEFAULT_PRIORITY_LAMPORT,
) => {
  const solAmount = 10000;
  const fee = await getRecentPrioritizationFees(
    ["JUP6LkbZbjS1jKKwapdHNy74zcZ3tLUZoi5QNyVTaV4"],
    "VERYHIGH",
  );
  const quoteResponse = await fetchJupiterRoutes(
    WRAPPED_SOL_ADDRESS,
    SHADOW_MINT,
    new BN(solAmount),
    50,
  );
  const { swapTransaction } = await (
    await fetch("https://quote-api.jup.ag/v6/swap", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // route from /quote api
        quoteResponse,
        // user public key to be used for the swap
        userPublicKey: wallet.publicKey.toString(),
        // auto wrap and unwrap SOL. default is true
        wrapUnwrapSOL: true,
        // feeAccount is optional. Use if you want to charge a fee.  feeBps must have been passed in /quote API.
        // This is the ATA account for the output token where the fee will be sent to. If you are swapping from SOL->USDC then this would be the USDC ATA you want to collect the fee.
        // feeAccount: "fee_account_public_key"
        asLegacyTransaction: true,
        dynamicComputeUnitLimit: true, // allow dynamic compute limit instead of max 1,400,000
        // custom priority fee
        // prioritizationFeeLamports: "auto", // or custom lamports: 1000,
        prioritizationFeeLamports: {
          autoMultiplier: 3,
        },
      }),
    })
  ).json();

  const swapTransactionBuf = Buffer.from(swapTransaction, "base64");
  const jupTransaction = web3.Transaction.from(swapTransactionBuf);

  const blockhash = await provider.connection.getLatestBlockhash();
  const signedTransction = await wallet.signTransaction(jupTransaction);
  const rawTransaction = signedTransction.serialize();

  const txid = await provider.connection.sendRawTransaction(rawTransaction, {
    skipPreflight: true,
    preflightCommitment: "processed",
    maxRetries: 5,
  });

  return await provider.connection.confirmTransaction(
    { ...blockhash, signature: txid },
    "confirmed",
  );
};

export const swapSolToShadowIfBelowMin = async (
  wallet: Wallet,
  provider: Provider,
  minimumAmount: number,
) => {
  if (
    await hasMinShadow(wallet.publicKey, provider.connection, minimumAmount)
  ) {
    return;
  }
  return await swapSolToShadow(wallet, provider);
};
export const getProposalMetadataSize = (proposalData: ProposalData) => {
  return (
    Buffer.byteLength(JSON.stringify(proposalData, null, 4)) / 1000 +
    SHADOW_MIN_FILE_SIZE_KB
  );
};
export const createShadowAccount = async (
  name: string,
  proposalData: ProposalData,
  drive: ShdwDrive,
) => {
  const proposalSize = getProposalMetadataSize(proposalData);
  const result = await drive.createStorageAccount(
    name,
    `${proposalSize}KB`,
    "v2",
  );
  return result;
};

export const createShadowAccountWithSize = async (
  name: string,
  size: number,
  drive: ShdwDrive,
) => {
  const result = await drive.createStorageAccount(name, `${size}KB`, "v2");
  return result;
};

export const getProposalMetaShadowUrl = (
  storageAccountAddress: web3.PublicKey,
  proposalAddress: web3.PublicKey,
) => {
  return `https://shdw-drive.genesysgo.net/${storageAccountAddress.toBase58()}/${[
    proposalAddress.toBase58(),
  ]}.json`;
};

export const createPriorityFeeIx = async (
  accountKeys: string[],
  transaction?: Transaction,
) => {
  const fee = await getRecentPrioritizationFees(
    accountKeys,
    "VERYHIGH",
    transaction,
  );
  console.log("Adding priority fee");
  const feeIx = web3.ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: fee,
  });
  return feeIx;
};

export const createComputeUnitsIx = async () => {
  const computeIx = web3.ComputeBudgetProgram.setComputeUnitLimit({
    units: 1000000,
  });
  return computeIx;
};

export const convertProposalDataToFile = (
  proposalData: ProposalData | Record<string, any>,
  name: string,
) => {
  const dataBuff = Buffer.from(JSON.stringify(proposalData, null, 4));

  if (isBrowser) {
    return new File([JSON.stringify(proposalData, null, 4)], `${name}.json`, {
      type: "application/json",
    });
  } else {
    return {
      name: `${name}.json`,
      file: dataBuff,
    };
  }
};

export const updateProposalMeta = async (
  storageAccountAddress: web3.PublicKey,
  proposalAddress: web3.PublicKey,
  newProposalData: ProposalData,
  drive: ShdwDrive,
) => {
  const proposalSize = getProposalMetadataSize(newProposalData);
  const account = await drive.getStorageAccount(storageAccountAddress);
  if (account.reserved_bytes / 1000 < proposalSize) {
    await drive.addStorage(
      storageAccountAddress,
      `${proposalSize - account.reserved_bytes / 1000}KB`,
      "v2",
    );
  }
  const metadataUrl = getProposalMetaShadowUrl(
    storageAccountAddress,
    proposalAddress,
  );
  const file = convertProposalDataToFile(
    newProposalData,
    proposalAddress.toBase58(),
  );

  const result = await drive.editFile(
    storageAccountAddress,
    metadataUrl,
    file,
    "v2",
  );
  return result;
};

export const uploadProposalMetadata = async (
  name: string,
  proposalData: ProposalData,
  accountAddress: web3.PublicKey,
  drive: ShdwDrive,
  onRetry: () => void,
) => {
  const file = convertProposalDataToFile(proposalData, name);
  return await tryUploadMeta(accountAddress, file, drive, onRetry, 10, 200);
};

export const tryUploadMeta = async (
  driveAddress: web3.PublicKey,
  file: File | ShadowFile,
  drive: ShdwDrive,
  onRetry: () => void,
  maxRetries: number,
  delay: number = 100,
) => {
  const retryWithBackoff = async (
    retries: number,
  ): Promise<ShadowUploadResponse> => {
    try {
      if (retries > 0) {
        const timetoWait = 2 ** retries * delay;
        await sleep(timetoWait);
      } else {
        await sleep(delay);
      }

      const res = await drive.uploadFile(driveAddress, file);
      return res;
    } catch (e) {
      console.error(e);
      if (retries < maxRetries) {
        onRetry();
        return retryWithBackoff(retries + 1);
      } else {
        throw e;
      }
    }
  };
  return retryWithBackoff(0);
};

export const getMetadataAddress = (
  mint: web3.PublicKey,
  tokenProgramId: web3.PublicKey = TOKEN_PROGRAM_ID,
) => {
  const [address, bump] = web3.PublicKey.findProgramAddressSync(
    [
      utils.bytes.utf8.encode("metadata"),
      tokenProgramId.equals(TOKEN_PROGRAM_ID)
        ? PROGRAM_ID.toBuffer()
        : TOKEN_2022_META_PROGAM_ID.toBuffer(),
      mint.toBuffer(),
    ],
    tokenProgramId.equals(TOKEN_PROGRAM_ID)
      ? PROGRAM_ID
      : TOKEN_2022_META_PROGAM_ID,
  );

  return address;
};

export const getMasterEditionAddress = (mint: web3.PublicKey) => {
  const [address, bump] = web3.PublicKey.findProgramAddressSync(
    [
      utils.bytes.utf8.encode("metadata"),
      new web3.PublicKey(PROGRAM_ADDRESS).toBuffer(),
      mint.toBuffer(),
      utils.bytes.utf8.encode("edition"),
    ],
    new web3.PublicKey(PROGRAM_ADDRESS),
  );

  return address;
};

export const getEditionMarkAddress = (
  master_mint: web3.PublicKey,
  edition: number,
) => {
  const [address, bump] = web3.PublicKey.findProgramAddressSync(
    [
      utils.bytes.utf8.encode("metadata"),
      new web3.PublicKey(PROGRAM_ADDRESS).toBuffer(),
      master_mint.toBuffer(),
      utils.bytes.utf8.encode("edition"),
      Buffer.from(Math.floor(edition / 248).toString()),
    ],
    new web3.PublicKey(PROGRAM_ADDRESS),
  );

  return address;
};

export const getMetadataAccount = async (
  collectionAddress: web3.PublicKey,
  connection: web3.Connection,
  tokenProgramId: web3.PublicKey = TOKEN_PROGRAM_ID,
) => {
  const account = await connection.getAccountInfo(
    getMetadataAddress(collectionAddress, tokenProgramId),
  );
  console.log(account);
  try {
    const [metadata] = Metadata.fromAccountInfo(account);
    return metadata;
  } catch (e) {
    console.warn(`Error deserializing metadata account: ${account}`);
    return null;
  }
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const PromiseExecuteSequential = async (
  asyncFunc: () => Promise<String>,
  onCompelte: (id: number) => void,
) => {
  return new Promise(async (res, rej) => {
    for (let i = 0; i < asyncFunc.length; i++) {
      await asyncFunc();
      onCompelte(i);
      if (i === asyncFunc.length - 1) {
        res(null);
      }
    }
  });
};

export const unique = (
  a: any[],
  generateKey: (val: any) => string = (val: any) => val.toString(),
) => {
  return a.reduce((acc: any[], item: any) => {
    const key = generateKey(item);
    const existingItemIndex = acc.findIndex((x: any) => generateKey(x) === key);

    if (existingItemIndex === -1) {
      acc.push(item);
    } else {
      // Prioritize writable accounts
      if (item.isWritable && !acc[existingItemIndex].isWritable) {
        acc[existingItemIndex] = item;
      }
    }

    return acc;
  }, []);
};

export const isTokenTransfer = (instruction: TransactionInstruction) => {
  if (instruction.programId.equals(TOKEN_PROGRAM_ID)) {
    const decodedData = u8().decode(instruction.data);

    switch (decodedData) {
      case TokenInstruction.TransferChecked: {
        const decoded = decodeTransferCheckedInstruction(instruction);
        return {
          amount: new BN(decoded.data.amount.toString()),
          decimals: decoded.data.decimals,
          mint: decoded.keys.mint.pubkey,
          destination: decoded.keys.destination,
          owner: decoded.keys.owner,
          source: decoded.keys.source,
        };
      }
      default:
        return false;
    }
  }
};
export function getAssociatedTokenAddressSync(
  mint: web3.PublicKey,
  owner: web3.PublicKey,
  programId: web3.PublicKey = TOKEN_PROGRAM_ID,
): web3.PublicKey {
  return web3.PublicKey.findProgramAddressSync(
    [owner.toBuffer(), programId.toBuffer(), mint.toBuffer()],
    ASSOCIATED_TOKEN_PROGRAM_ID,
  )[0];
}

export function findTokenRecordId(
  mint: PublicKey,
  token: PublicKey,
): PublicKey {
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from("metadata"),
      PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from("token_record"),
      token.toBuffer(),
    ],
    PROGRAM_ID,
  )[0];
}

export const sendNftInstruction = async (
  reciever: PublicKey,
  sender: PublicKey,
  mint: PublicKey,
  amount: BN,
  connection: web3.Connection,
  tokenProgramId: PublicKey = TOKEN_PROGRAM_ID,
) => {
  const metadata = await getMetadataAccount(mint, connection, tokenProgramId);
  const senderAta = getAssociatedTokenAddressSync(mint, sender, tokenProgramId);
  const recieverAta = getAssociatedTokenAddressSync(
    mint,
    reciever,
    tokenProgramId,
  );
  const accountInfo = await getAccount(
    connection,
    senderAta,
    "confirmed",
    tokenProgramId,
  );
  const mintInfo = await getMint(
    connection,
    accountInfo.mint,
    "confirmed",
    tokenProgramId,
  );

  let transferInstructions: TransactionInstruction[] = [];
  if (
    metadata &&
    metadata.tokenStandard === TokenStandard.ProgrammableNonFungible
  ) {
    transferInstructions = [
      createTransferPnftInstruction(
        {
          token: senderAta,
          tokenOwner: sender,
          destination: recieverAta,
          destinationOwner: reciever,
          mint,
          metadata: getMetadataAddress(mint),
          edition: getMasterEditionAddress(mint),
          ownerTokenRecord: findTokenRecordId(mint, senderAta),
          destinationTokenRecord: findTokenRecordId(mint, recieverAta),
          authority: sender,
          payer: sender,
          systemProgram: SystemProgram.programId,
          sysvarInstructions: SYSVAR_INSTRUCTIONS_PUBKEY,
          splTokenProgram: TOKEN_PROGRAM_ID,
          splAtaProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          authorizationRules: metadata.programmableConfig.ruleSet,
          authorizationRulesProgram: TOKEN_AUTH_RULES_ID,
        },
        {
          transferArgs: {
            __kind: "V1",
            amount: 1,
            authorizationData: null,
          },
        },
      ),
    ];
  } else {
    try {
      await getAccount(connection, recieverAta, "confirmed", tokenProgramId);
    } catch (e) {
      transferInstructions.push(
        createAssociatedTokenAccountInstruction(
          sender,
          recieverAta,
          reciever,
          mint,
          tokenProgramId,
        ),
      );
    }
    transferInstructions.push(
      createTransferCheckedInstruction(
        senderAta,
        mint,
        recieverAta,
        sender,
        BigInt(amount.toString()),
        mintInfo.decimals,
        [],
        tokenProgramId,
      ),
    );
  }
  return transferInstructions;
};
export const getRecentPrioritizationFees = async (
  accountKeys: string[],
  priority?: "HIGH" | "MEDIUM" | "VERYHIGH",
  transaction?: Transaction,
): Promise<number> => {
  let extraFeeToAddOnTopOfEstimate =
    process.env.REACT_APP_ADDITIONAL_MICRO_LAMPORTS ?? 0;
  try {
    const response = await fetch(
      `https://mainnet.helius-rpc.com/?api-key=${process.env.REACT_APP_HELIUS_API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          id: 1,
          method: "getPriorityFeeEstimate",
          params: [
            {
              accountKeys: accountKeys?.length !== 0 ? accountKeys : undefined,
              transaction: transaction
                ? bs58.encode(
                    transaction?.serialize({
                      verifySignatures: false,
                    }),
                  )
                : undefined,
              options: {
                includeAllPriorityFeeLevels: true,
              },
            },
          ],
        }),
      },
    );
    const data = await response.json();
    console.log(data);
    let fee =
      priority === "VERYHIGH"
        ? Number(data.result.priorityFeeLevels.veryHigh.toFixed(0))
        : Number(data.result.priorityFeeLevels.high.toFixed(0));
    if (fee === 0) {
      fee = DEFAULT_PRIORITY_LAMPORT;
    }
    if (fee < 100000) {
      fee = 100000;
    }
    if (isNaN(fee)) {
      fee === DEFAULT_PRIORITY_LAMPORT;
    }
    console.log("fee: ", fee);
    console.log(
      `Fetched priority fee ${fee + Number(extraFeeToAddOnTopOfEstimate)} for `,
      accountKeys.length ? accountKeys.join(", ") : "transaction",
    );
    console.log(
      `adding priority fee ${
        Number(fee.toFixed(0)) + Number(extraFeeToAddOnTopOfEstimate)
      } for `,
      accountKeys.length ? accountKeys.join(", ") : "transaction",
    );
    return Number(fee.toFixed(0)) + Number(extraFeeToAddOnTopOfEstimate);
  } catch (e) {
    console.error("[PriorityFees] ", e);
    return DEFAULT_PRIORITY_LAMPORT + Number(extraFeeToAddOnTopOfEstimate);
  }
};

export const signSendAndConfirmTransactionsWithFees = async ({
  connection,
  wallet,
  transactionInstructions,
  confirmLevel,
  timeoutStrategy,
  callbacks,
  config,
  extra,
}: sendSignAndConfirmTransactionsProps & { extra: any }): Promise<{
  sigs: string[];
  status: SEND_TX_STATUS;
}> => {
  let totalSigned = 0;
  let confirmedCount = 0;
  let transactionSignatures: string[] = [];
  const incrementCount = () => {
    confirmedCount++;
  };
  const setTotalSigned = (value: number) => {
    totalSigned = value;
  };
  const resetCount = () => {
    confirmedCount = 0;
  };

  console.log(
    "transactionInstructions legnth: ",
    transactionInstructions,
    transactionInstructions.length,
  );
  setTotalSigned(transactionInstructions.length);
  const callbacksToSend: any = {
    afterBatchSign: (signedTxnsCount: number) => {
      if (callbacks?.afterBatchSign) {
        callbacks?.afterBatchSign(signedTxnsCount);
      }
      // some logic here to show processing txs
      if (extra?.onTransactionConfirmation) {
        extra?.onTransactionConfirmation({
          total: totalSigned,
          count: confirmedCount,
        });
      }
    },
    afterAllTxConfirmed: () => {
      if (callbacks?.afterAllTxConfirmed) {
        callbacks?.afterAllTxConfirmed();
      }
      console.log("All txns confirmed");
    },
    afterEveryTxConfirmation: (res: { txid: string }) => {
      if (callbacks?.afterEveryTxConfirmation) {
        callbacks?.afterEveryTxConfirmation();
      }
      // some logic here to show each confirmation
      incrementCount();
      transactionSignatures.push(res.txid);
      if (extra?.onTransactionConfirmation) {
        extra?.onTransactionConfirmation({
          total: totalSigned,
          count: confirmedCount,
        });
      }
    },
    onError: (
      e: any,
      notProcessedTransactions: TransactionInstructionWithType[],
      originalProps: sendSignAndConfirmTransactionsProps,
    ) => {
      // some logic here to alert the user transactions will restart
      if (extra?.onTransactionFailure) {
        extra?.onTransactionFailure(`Retrying transactions please wait..`);
      }
    },
  };
  const txConfig = {
    maxTxesInBatch: 40,
    autoRetry: true,
    maxRetries: -1,
    retried: 0,
    logFlowInfo: true,
    resendTxUntilConfirmed: true,
    resendPoolTimeMs: 2000,
    skipPreflight: true,
    ...config,
  };

  try {
    let { blockhash, lastValidBlockHeight } =
      await connection.getLatestBlockhash("confirmed");
    await sendSignAndConfirmTransactions({
      connection,
      wallet,
      transactionInstructions,
      callbacks: callbacksToSend,
      config: txConfig,
      timeoutStrategy: {
        block: {
          blockhash,
          lastValidBlockHeight,
        },
        startBlockCheckAfterSecs: 30,
        getSignatureStatusesPoolIntervalMs: 2000,
      },
      confirmLevel: confirmLevel,
    });
    console.log("all completed txs: ", transactionSignatures);
    return {
      sigs: transactionSignatures,
      status: SEND_TX_STATUS.SUCESSFUL,
    };
  } catch (e) {
    // extra.on
    console.log("hitting an error here: ", e);
    return { sigs: null, status: SEND_TX_STATUS.FAILED };
  }
};
