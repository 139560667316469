import { Suspense, useMemo } from "react"
import { AlignLink } from "../components/AlignLink"
import { DiscoverSkeleton } from "../components/DiscoverSkeleton"
import { OrganisationItem } from "../components/OrganisationItem"
import { useOrganisations } from "../state/hooks/useAlignGovernance"
import { useGetOrganisationsQuery } from "../generated/graphql"

export function Discover() {

    // const orgKeys = useOrganisations()
    
    // if (!wallet?.publicKey) {
    //   return (
    //     <div className="max-w-6xl mx-auto px-4 py-8 text-contrast">
    //       <div className="flex justify-between items-start">
    //         Please connect your wallet to continue.
    //       </div>
    //     </div>
    //   );
    // }
    const {data, loading, error} = useGetOrganisationsQuery({
        variables: {
            take : 0,
        }
    })

    return (
        <div className="mx-auto max-w-screen-2xl px-8 py-8 text-contrast">
            <div className="flex justify-between items-start mb-4">
                <form>
                    <div>
                        {/* <div className="relative flex items-center max-w-3xl mx-auto">
                            <input
                            type="text"
                            name="search"
                            placeholder="Search Organization"
                            id="search"
                            className="input"
                            />
                        </div> */}
                    </div>
                </form>
                <AlignLink path="org/create">
                    <button
                        className="w-full mt-1 mr-6 flex justify-center gap-2 items-center self-end bg-transparent rounded-button border-primary 
                        text-contrast border-buttonWidth border-accent transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                    >
                        Create Organisation
                    </button>
                </AlignLink>
            </div>
            <Suspense fallback={<DiscoverSkeleton/>}>
                {loading ? <DiscoverSkeleton/> : 
                <div className="org-list-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {data?.organisations.filter(x => x.profile?.username !== "@aligntest" && process.env.REACT_APP_SHOW_TEST_DAO!!).map(org => (
                        
                        <OrganisationItem 
                            displayname={org.profile?.displayName} 
                            key={org.address}
                            username={org.profile?.username}
                            pfp={org.profile?.pfp ? org.profile.pfp : null}
                            address={org.address}
                            loading={loading}
                            />
                    ))}
                </div>
}
            </Suspense>
        </div>
    )
}
