import { Keypair, PublicKey } from "@solana/web3.js";
import { useRef, useState } from "react";
import { useAlignPrograms, useRefreshNfts } from "../state/hooks/useAlignGovernance";
import { SequenceType, TransactionInstructionWithSigners, convertProposalDataToFile, createShadowAccountWithSize, signSendAndConfirmTransactionsWithFees, swapSolToShadowIfBelowMin } from "align-sdk";
import toast from "react-hot-toast";
import { createWarpProvider } from "../warp";
import { AnchorProvider } from "@project-serum/anchor";
import { getMint } from "@solana/spl-token";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Metaplex, keypairIdentity, walletAdapterIdentity } from "@metaplex-foundation/js";
import LoadingSpinner from "./LoadingSpinner";

export const CreateNftModal = ({
    onClose,
    onSuccess,
    show
} : {
    onClose : () => void,
    onSuccess : (collectionMint : PublicKey) => void,
    show : boolean
}) => {
    const [name, setname] = useState<string | null>(null);
    const [symbol, setSymbol] = useState<string | null>(null);
    const [imageUri, setImageUri] = useState(null);
    const [metadataUri, setMetadataUri] = useState(null)
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const [imageBlob, setImageBlob] = useState<Blob | null>(null)
    const [originalFileName, setOriginalFileName]= useState<string>("")

    const alignPrograms = useAlignPrograms()
    const {connection} = useConnection()
    const wallet = useAnchorWallet()
    const walletadpater = useWallet()
    const meta = new Metaplex(connection).use(walletAdapterIdentity(walletadpater))
    const refreshNfts = useRefreshNfts(wallet?.publicKey?.toBase58())

    const fileInputRef = useRef<HTMLInputElement>(null); // added a ref to handle file input

    // handler to deal with file upload
    const handleFileUpload = async (event: any) => {
        event.preventDefault();
        const files = event.target.files || event.dataTransfer.files;
        const file : File = files[0];
        if (file) {
            setImageBlob(file.slice(0, file.size, file.type))
            setOriginalFileName(file.name)
        }
    }

    const handleDragOver = (event : any) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const handleDragDrop = (event : any) => {
        event.preventDefault();
        event.stopPropagation();
        handleFileUpload(event);
    }

    const handleFileInputClick = () => {
        fileInputRef?.current?.click();
    }
    

    return ( 
        <div
            id="defaultModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-black bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${show ? "" : "hidden"
            }`}
        >
            <div className="relative w-full h-full max-w-2xl md:h-auto text-contrast">
                <div className="relative bg-black border border-white border-opacity-30 rounded-lg shadow text-contrast p-10">
                    <div className="flex flex-col items-start justify-between">
                        <div className="w-full">
                            <label className="font-light">NFT Name</label>
                            <input className="input w-full"  placeholder="Phase DAO" onChange={(e) => setname(e.target.value)} value={name ? name : undefined}/>
                        </div>

                        <div className="w-full">
                            <label className="font-light">NFT Symbol</label>
                            <input className="input w-full"  placeholder="PHSE" onChange={(e) => setSymbol(e.target.value)}  value={symbol ? symbol : undefined}/>
                        </div>

                        </div>

                        <div className="w-full">
                            <label className="font-light">Image</label>
                            <div className="border-dashed border-opacity-30 border h-48 w-full flex flex-col justify-center items-center" 
                                onDrop={handleDragDrop} 
                                onDragOver={handleDragOver} 
                                onClick={handleFileInputClick} 
                                
                            >
                                <input type="file" 
                                    onChange={handleFileUpload} 
                                    ref={fileInputRef} 
                                    style={{display: "none"}}
                                    className=""/>
                                
                                <label className="opacity-60"> Drag and drop your image here or click to select image</label>
                               <label className="text-primary">{originalFileName}</label>
                            </div>
                        </div>


                        <button
                            className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                            transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                            onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                try{
                                setIsUploadingImage(true)
                                
                                if(!wallet || !name || !symbol  || !imageBlob || !alignPrograms){
                                    toast.error(
                                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                            <div>Something went wrong.</div>
                                            <div>Make sure all items in the form have been set correctly.</div>
                                        </div>, {id: "createnft"})
                                    return
                                }

                                let imageFileName ;

                                if (imageBlob.type === "image/png"  ) {
                                    imageFileName = `${name}.png`
                                }
                                else if(imageBlob.type === "image/jpeg"){
                                    imageFileName = `${name}.jpg`
                                }
                                else if(imageBlob.type === "image/gif"){
                                    imageFileName = `${name}.gif`
                                }
                                else{
                                    toast.error("Image file is not in a compatiable format", {id: "createnft"})
                                    return
                                }
                                const provider = new AnchorProvider(connection, wallet, {})
                                toast.loading(
                                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                        <div>Purchasing SHDW from Jupiter</div>
                                        <div>for NFT metadata upload.</div>
                                    </div>, {id: "createnft"})

                                await swapSolToShadowIfBelowMin(alignPrograms.wallet, alignPrograms.mainnetProvider, 0.2);

                                const renamedFile = new File( [imageBlob.slice(0, imageBlob.size, imageBlob.type)], imageFileName)
                                toast.loading(
                                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                        <div>Creating the metadata for your NFT</div>
                                        <div>and uploading to Shadow Drive..</div>
                                    </div>, {id: "createnft"})

                                
                                const drive = await createShadowAccountWithSize(name, (renamedFile.size / 1024) *2, alignPrograms.shadowDriveInstance) 

                                const metadata = {
                                    name : name,
                                    symbol: symbol,
                                    description: `Profile picture for ${name}`,
                                    image: `https://shdw-drive.genesysgo.net/${drive.shdw_bucket}/${imageFileName}`,
                                    attributes: []
                                }
                                const metadataFile = convertProposalDataToFile(metadata, name || "") as File
                                const list = new DataTransfer()
                                list.items.add(renamedFile)
                                list.items.add(metadataFile)

                                const res = await alignPrograms?.shadowDriveInstance.uploadMultipleFiles(new PublicKey(drive.shdw_bucket), list.files)
                                toast.loading(
                                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                        <div>Collection Metadata Uploaded!</div>
                                        <div>Minting Collection NFT..</div>
                                    </div>, {id: "createnft"})

                                const collectionMint = new Keypair()
                                const nftMint = new Keypair()
                                const collectionBuilder =  await meta.nfts().builders().create({
                                    uri: `https://shdw-drive.genesysgo.net/${drive.shdw_bucket}/${name}.json`,
                                    name: name,
                                    sellerFeeBasisPoints: 0,
                                    useNewMint: collectionMint
                                })
                
                                const nftBuilder =  await meta.nfts().builders().create({
                                    uri: `https://shdw-drive.genesysgo.net/${drive.shdw_bucket}/${name}.json`,
                                    name: name,
                                    sellerFeeBasisPoints: 0,
                                    collection: collectionMint.publicKey,
                                    collectionAuthority: meta.identity(),
                                    useNewMint: nftMint
                                })
                                
                                const mintIxs = [{ instructionsSet: [
                                    ...collectionBuilder.getInstructionsWithSigners().map(ix => new TransactionInstructionWithSigners(ix.instruction, [collectionMint])),
                                    
                                ],
                                          sequenceType: SequenceType.Sequential,
                                }, { instructionsSet: [
                                    ...nftBuilder.getInstructionsWithSigners().map(ix => new TransactionInstructionWithSigners(ix.instruction, [nftMint])),
                                    
                                ],
                                          sequenceType: SequenceType.Sequential,
                                }];
                

                                toast.loading(
                                    <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                                        <div>Collection NFT Minted!</div>
                                        <div>Now Minting PFP..</div>
                                    </div>, {id: "createnft"})

              
                                const sigs = await signSendAndConfirmTransactionsWithFees({
                                    connection: connection,
                                    wallet: wallet,
                                    transactionInstructions: mintIxs,
                                    confirmLevel: "confirmed",
                                    extra: [],
                                    callbacks: {
                                        onError: (err) =>  console.log(err)
                                    }
                                })


                                
                                toast.success(`PFP NFT minted successfully!`, {id: "createnft"})
                                refreshNfts()
                                onSuccess(nftMint.publicKey)
                                onClose()
                            }
                            catch(e){
                                toast.error(`Error creating NFT: ${e}`, {id: "createnft"})
                            }
                            finally{
                                setIsUploadingImage(false)
                            }
                            }
                        }
                        disabled={isUploadingImage}
                        >   {
                            isUploadingImage ? <LoadingSpinner/> :
                            "Create Nft"
                        }
                            </button>

<button
                    onClick={() => onClose()}
                    type="button"
                    className="bg-transparent hover:opacity-90 absolute right-2 top-2 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
                >
                    <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                    </div>
                </div>
            </div>
   
     );
}

