import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isCustomDomainStore } from '../state/domains';

export const AlignLink = ({
  path,
  orgAddress,
  children,
  disabled = false
} : {path: string, orgAddress?: string, children: React.ReactNode, disabled?: boolean | undefined}) => {
  const isCustomDomain = useRecoilValue(isCustomDomainStore)
  
  const link = !isCustomDomain && orgAddress ? `/org/${orgAddress}${path}` : path
  
  return (
    <>
      {!disabled ? <Link className='md:w-fit w-full' to={link}>{children}</Link> : children}
    </>
  )
}