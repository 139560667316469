import {
  ALIGN_PROGRAM_ID,
  canPushRankingState,
  PROFILES_PROGRAM_ID,
} from "align-sdk";
import { IDL as AlignIDL } from "align-sdk/dist/idls/align_governance";
import { IDL as MetaplexBubblegumIDL } from "align-sdk/dist/idls/metaplex_bubblegum";
import {
  transferInstructionDiscriminator,
  TransferStruct,
} from "@metaplex-foundation/mpl-token-metadata";
import {
  SplTokenStakingIDLV0,
  SPL_TOKEN_STAKING_ID_V0,
} from "@mithraic-labs/token-staking";
import { BorshInstructionCoder } from "@project-serum/anchor";
import { seq, u8 } from "@solana/buffer-layout";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  decodeTransferCheckedInstruction,
  TokenInstruction,
  TOKEN_2022_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  PublicKey,
  SystemInstruction,
  SystemProgram,
  TransactionInstruction,
} from "@solana/web3.js";
import { IDL } from "align-sdk/dist/idls/profiles";
import BN from "bn.js";
import {
  ADD_COUNCIL_MEMBER_IX_DISCRIMATOR,
  CREATE_PROFILE_IX_DISCRIMATOR,
  CREATE_WALLET_TRACKER_IX_DISCRIMATOR,
  JUPITER_V6_PROGRAM_ID,
  REMOVE_COUNCIL_MEMBER_IX_DISCRIMATOR,
  SET_PFP_IX_DISCRIMATOR,
  SET_THRESHOLD_IX_DISCRIMATOR,
  TRACK_PFP_IX_DISCRIMATOR,
} from "../constants";
import { TransactionState } from "../generated/graphql";
import { IDL as JupiterIdl } from "../idl/jupiter";
import { METADATA_PROGRAM_ID } from "../pages/Profile";
import { ParsedProposalInstruction, ParseTransactionTypes } from "../types";
import { arrayIsEqual } from "./equality";
import { publicKey, string, u64 } from '@metaplex-foundation/umi/serializers';
import {
  Context,
  Pda
} from '@metaplex-foundation/umi';
export const proposalPrettyState = (
  rankingPeriod: number,
  rankingAt: string | null | undefined,
  state: string,
) => {
  if (state === "Ranking" && rankingAt) {
    if (canPushRankingState(rankingPeriod, rankingAt, state)) {
      return "Voting";
    }
  }

  return state;
};
export type ProposalTransaction = {
  __typename?: "ProposalTransactionDto";
  state: TransactionState;
  executed_at?: null | string | undefined;
  executedById?: string | null | undefined;
  proposalInstuctions: ProposalInstruction[];
};
export type ProposalInstruction = {
  __typename?: "ProposalInstructionDto";
  instruction_index: number;
  transactionAddress: string;
  program_id: string;
  data: any;
  accounts: Array<{
    __typename?: "AccountMetaDto";
    pubkey: string;
    isSigner: boolean;
    isWritable: boolean;
  }>;
};
export const parseServicerPayoutAmount = (
  transactions: Array<ProposalTransaction>,
) => {
  if (!transactions || transactions.length === 0) {
    return null;
  }

  const instructions: ProposalInstruction[][] = transactions.map(
    (tx: ProposalTransaction) => tx.proposalInstuctions,
  );
  if (!instructions || instructions.length === 0) {
    return null;
  }

  const transctionInstructions = instructions.flatMap((insts) =>
    insts.map(
      (inst) =>
        new TransactionInstruction({
          keys: inst.accounts.map((acc) => ({
            ...acc,
            pubkey: new PublicKey(acc.pubkey),
          })),
          programId: new PublicKey(inst.program_id),
          data: Buffer.from(new Uint8Array(inst.data.data)),
        }),
    ),
  );

  for (let i = 0; i < transctionInstructions.length; i++) {
    const transactionInstruction = transctionInstructions[i];
    if (transactionInstruction.programId.equals(TOKEN_PROGRAM_ID)) {
      const decodedData = u8().decode(transactionInstruction.data);

      switch (decodedData) {
        case TokenInstruction.TransferChecked: {
          const decoded = decodeTransferCheckedInstruction(
            transactionInstruction,
          );
          return {
            amount: new BN(decoded.data.amount.toString()),
            decimals: decoded.data.decimals,
            mint: decoded.keys.mint.pubkey,
            isNative: false,
            tokenProgram: TOKEN_PROGRAM_ID,
          };
        }
      }
    } else if (transactionInstruction.programId.equals(TOKEN_PROGRAM_ID)) {
      const decodedData = u8().decode(transactionInstruction.data);

      switch (decodedData) {
        case 0: {
          if (transctionInstructions.length < i + 1) {
            const transferDecodedData = u8().decode(
              transactionInstruction.data,
            );

            switch (transferDecodedData) {
              case TokenInstruction.TransferChecked: {
                const decoded = decodeTransferCheckedInstruction(
                  transactionInstruction,
                );
                return {
                  amount: new BN(decoded.data.amount.toString()),
                  decimals: decoded.data.decimals,
                  mint: decoded.keys.mint.pubkey,
                  isNative: false,
                  tokenProgram: TOKEN_PROGRAM_ID,
                };
              }
            }
          }
          return null;
        }
      }
    } else if (transactionInstruction.programId.equals(TOKEN_2022_PROGRAM_ID)) {
    /**
     * TOKEN 2022
     */
      const decodedData = u8().decode(transactionInstruction.data);
      switch (decodedData) {
        case TokenInstruction.TransferChecked: {
          const decoded = decodeTransferCheckedInstruction(
            transactionInstruction,
            TOKEN_2022_PROGRAM_ID,
          );
          return {
            amount: new BN(decoded.data.amount.toString()),
            decimals: decoded.data.decimals,
            mint: decoded.keys.mint.pubkey,
            isNative: false,
            tokenProgram: TOKEN_2022_PROGRAM_ID,
          };
        }
        default:
          return null;
      }
    } else if (transactionInstruction.programId.equals(METADATA_PROGRAM_ID)) {

    /**
     * P-NFT
     */
      const transferDecodedData = u8().decode(transactionInstruction.data);

      switch (transferDecodedData) {
        case transferInstructionDiscriminator: {
          const [decoded] = TransferStruct.deserialize(
            transactionInstruction.data,
          );

          return {
            amount: new BN(decoded.transferArgs.amount.toString()),
            decimals: 0,
            mint: transactionInstruction.keys[4].pubkey,
            isNative: false,
            tokenProgram: TOKEN_PROGRAM_ID,
          };
        }
        default:
          return null;
      }
    } else if (
      transactionInstruction.programId.equals(SystemProgram.programId)
    ) {
      const instructiontype = SystemInstruction.decodeInstructionType(
        transactionInstruction,
      );
      switch (instructiontype) {
        case "Transfer": {
          const decoded = SystemInstruction.decodeTransfer(
            transactionInstruction,
          );
          return {
            amount: new BN(decoded.lamports.toString()),
            decimals: 9,
            mint: null,
            isNative: true,
            tokenProgram: undefined,
          };
        }
        default:
          return null;
      }
    }
  }

  return null;
};

export const findMintAddress = (
  nonce: number,
  merkleTree: PublicKey
): [PublicKey, number] => {
  return PublicKey.findProgramAddressSync([
      Buffer.from("asset"),
      merkleTree.toBuffer(),
      new BN(nonce).toArrayLike(Buffer, 'le', 8),
  ], new PublicKey('BGUMAp9Gq7iTEuizy4pqaxsTyUCBK68MDfK752saRPUY'))
}

export const parseTransactions = (transactions: Array<ProposalTransaction>) => {
  if (!transactions || transactions.length === 0) {
    return [];
  }

  const instructions: ProposalInstruction[][] = transactions.map(
    (tx: ProposalTransaction) => tx.proposalInstuctions,
  );
  if (!instructions || instructions.length === 0) {
    return [];
  }

  const transctionInstructions = instructions.flatMap((insts) =>
    insts.map(
      (inst) =>
        new TransactionInstruction({
          keys: inst.accounts.map((acc) => ({
            ...acc,
            pubkey: new PublicKey(acc.pubkey),
          })),
          programId: new PublicKey(inst.program_id),
          data: Buffer.from(new Uint8Array(inst.data.data)),
        }),
    ),
  );
  let parsedTransctions: ParsedProposalInstruction[] = [];
  for (let i = 0; i < transctionInstructions.length; i++) {
    const transactionInstruction = transctionInstructions[i];
    if (transactionInstruction.programId.equals(new PublicKey('BGUMAp9Gq7iTEuizy4pqaxsTyUCBK68MDfK752saRPUY'))) {
      const decodedData = u8().decode(transactionInstruction.data);
      switch (decodedData) {
        case 163: {
          const coder = new BorshInstructionCoder(MetaplexBubblegumIDL);
          const decoded = coder.decode(transactionInstruction.data, "base58");
          parsedTransctions.push({
            ...decoded,
            //@ts-ignore
            type: 163,
            //@ts-ignore
            data: {
              //@ts-ignore
              ...decoded?.data,
            },
            keys: {
              payer: transactionInstruction.keys[1],
              destination: transactionInstruction.keys[3],
              mint: {
                pubkey: findMintAddress(
                  //@ts-ignore
                  decoded?.data.nonce
                  , transactionInstruction.keys[4].pubkey)[0],
                isWritable: false,
                isSigner: false,
              },
              compressionProgram: transactionInstruction.keys[6],
              systemProgram: transactionInstruction.keys[7],
            },
          });
        }
      }
    }
    if (transactionInstruction.programId.equals(TOKEN_PROGRAM_ID)) {
      const decodedData = u8().decode(transactionInstruction.data);

      switch (decodedData) {
        case TokenInstruction.TransferChecked: {
          const decoded = decodeTransferCheckedInstruction(
            transactionInstruction,
          );
          parsedTransctions.push({
            ...decoded,
            type: ParseTransactionTypes.TokenTransfer,
            keys: decoded.keys,
            data: {
              ...decoded.data,
              amount: new BN(decoded.data.amount.toString()),
            },
          });
        }
      }
    } else if (transactionInstruction.programId.equals(TOKEN_PROGRAM_ID)) {
      const decodedData = u8().decode(transactionInstruction.data);

      switch (decodedData) {
        case 0: {
          if (transctionInstructions.length < i + 1) {
            const transferDecodedData = u8().decode(
              transactionInstruction.data,
            );

            switch (transferDecodedData) {
              case TokenInstruction.TransferChecked: {
                const decoded = decodeTransferCheckedInstruction(
                  transactionInstruction,
                );
                parsedTransctions.push({
                  ...decoded,
                  type: ParseTransactionTypes.TokenTransfer,
                  keys: decoded.keys,
                  data: {
                    ...decoded.data,
                    amount: new BN(decoded.data.amount.toString()),
                  },
                });
                break;
              }

              default: {
                parsedTransctions.push({
                  programId: transactionInstruction.programId,
                  type: ParseTransactionTypes.Unknown,
                  data: transactionInstruction.data,
                  keys: transactionInstruction.keys,
                });
              }
            }
          }
        }
      }
    } else if (
      transactionInstruction.programId.equals(ASSOCIATED_TOKEN_PROGRAM_ID)
    ) {
      try {
        const decodedData = u8().decode(transactionInstruction.data);

        switch (decodedData) {
          case 1 || 0: {
            parsedTransctions.push({
              type: ParseTransactionTypes.CreateAtaIdempotent,
              keys: {
                payer: transactionInstruction.keys[0],
                ata: transactionInstruction.keys[1],
                walletAddress: transactionInstruction.keys[2],
                mint: transactionInstruction.keys[3],
                systemProgram: transactionInstruction.keys[4],
                tokenProgram: transactionInstruction.keys[5],
              },
              data: {},
              programId: transactionInstruction.programId,
            });
            break;
          }
          default: {
            parsedTransctions.push({
              programId: transactionInstruction.programId,
              type: ParseTransactionTypes.Unknown,
              data: transactionInstruction.data,
              keys: transactionInstruction.keys,
            });
          }
        }
      } catch (e) {
        console.error(
          "Problem parsing ata instruction",
          transactionInstruction,
        );
      }
    } else if (
      transactionInstruction.programId.equals(SystemProgram.programId)
    ) {
      const instructiontype = SystemInstruction.decodeInstructionType(
        transactionInstruction,
      );
      switch (instructiontype) {
        case "Transfer": {
          const decoded = SystemInstruction.decodeTransfer(
            transactionInstruction,
          );
          parsedTransctions.push({
            type: ParseTransactionTypes.NativeTransfer,
            keys: {
              fromPubkey: {
                isSigner: true,
                isWritable: true,
                pubkey: decoded.fromPubkey,
              },
              toPubkey: {
                isSigner: true,
                isWritable: true,
                pubkey: decoded.toPubkey,
              },
            },
            data: {
              amount: new BN(decoded.lamports.toString()),
            },
            programId: transactionInstruction.programId,
          });
          break;
        }
        default: {
          parsedTransctions.push({
            programId: transactionInstruction.programId,
            type: ParseTransactionTypes.Unknown,
            data: transactionInstruction.data,
            keys: transactionInstruction.keys,
          });
        }
      }
    } else if (transactionInstruction.programId.equals(PROFILES_PROGRAM_ID)) {
      const instructiontype = seq(u8(), 8).decode(transactionInstruction.data);
      const coder = new BorshInstructionCoder(IDL);
      const decoded = coder.decode(transactionInstruction.data, "base58");
      if (arrayIsEqual(instructiontype, CREATE_PROFILE_IX_DISCRIMATOR)) {
        parsedTransctions.push({
          ...decoded,
          type: ParseTransactionTypes.CreateProfile,
          //@ts-ignore
          data: {
            //@ts-ignore
            ...decoded?.data,
          },
          keys: {
            ...transactionInstruction.keys,
          },
        });
      } else if (arrayIsEqual(instructiontype, SET_PFP_IX_DISCRIMATOR)) {
        parsedTransctions.push({
          ...decoded,
          type: ParseTransactionTypes.SetProfile,
          //@ts-ignore
          data: {
            //@ts-ignore
            ...decoded?.data,
          },
          keys: {
            ...transactionInstruction.keys,
          },
        });
      } else if (
        arrayIsEqual(instructiontype, CREATE_WALLET_TRACKER_IX_DISCRIMATOR)
      ) {
        parsedTransctions.push({
          ...decoded,
          type: ParseTransactionTypes.CreateWalletTracker,
          //@ts-ignore
          data: {
            //@ts-ignore
            ...decoded?.data,
          },
          keys: {
            ...transactionInstruction.keys,
          },
        });
      } else if (arrayIsEqual(instructiontype, TRACK_PFP_IX_DISCRIMATOR)) {
        parsedTransctions.push({
          ...decoded,
          type: ParseTransactionTypes.TrackPfp,
          //@ts-ignore
          data: {
            //@ts-ignore
            ...decoded?.data,
          },
          keys: {
            ...transactionInstruction.keys,
          },
        });
      } else {
        parsedTransctions.push({
          programId: transactionInstruction.programId,
          type: ParseTransactionTypes.Unknown,
          data: transactionInstruction.data,
          keys: transactionInstruction.keys,
        });
      }
    } else if (transactionInstruction.programId.equals(JUPITER_V6_PROGRAM_ID)) {
      const coder = new BorshInstructionCoder(JupiterIdl);
      const decoded = coder.decode(transactionInstruction.data, "base58");
      parsedTransctions.push({
        ...decoded,
        type: ParseTransactionTypes.JupiterSwap,
        //@ts-ignore
        data: {
          //@ts-ignore
          ...decoded?.data,
        },
        keys: {
          ...transactionInstruction.keys,
        },
      });
    } else if (transactionInstruction.programId.equals(ALIGN_PROGRAM_ID)) {
      const instructiontype = seq(u8(), 8).decode(transactionInstruction.data);
      const coder = new BorshInstructionCoder(AlignIDL);
      const decoded = coder.decode(transactionInstruction.data, "base58");
      if (arrayIsEqual(instructiontype, SET_THRESHOLD_IX_DISCRIMATOR)) {
        parsedTransctions.push({
          ...decoded,
          type: ParseTransactionTypes.SetWalletThreshold,
          //@ts-ignore
          data: {
            //@ts-ignore
            ...decoded?.data,
          },
          keys: {
            ...transactionInstruction.keys,
          },
        });
      } else if (
        arrayIsEqual(instructiontype, ADD_COUNCIL_MEMBER_IX_DISCRIMATOR)
      ) {
        parsedTransctions.push({
          ...decoded,
          type: ParseTransactionTypes.AddCouncilMember,
          //@ts-ignore
          data: {
            //@ts-ignore
            ...decoded?.data,
          },
          keys: {
            ...transactionInstruction.keys,
          },
        });
      } else if (
        arrayIsEqual(instructiontype, REMOVE_COUNCIL_MEMBER_IX_DISCRIMATOR)
      ) {
        parsedTransctions.push({
          ...decoded,
          type: ParseTransactionTypes.RemoveCouncilMember,
          //@ts-ignore
          data: {
            //@ts-ignore
            ...decoded?.data,
          },
          keys: {
            ...transactionInstruction.keys,
          },
        });
      }

      // else if (arrayIsEqual(instructiontype, CREATE_PROFILE_IX_DISCRIMATOR)){
      //     parsedTransctions.push({
      //       ...decoded,
      //       type : ParseTransactionTypes.CreateWalletTracker,
      //       //@ts-ignore
      //       data : {
      //           //@ts-ignore
      //          ...decoded?.data
      //       },
      //       keys : {
      //         ...transactionInstruction.keys
      //       }

      //  })
      // }

      // else if (arrayIsEqual(instructiontype, TRACK_PFP_IX_DISCRIMATOR)){
      //     parsedTransctions.push({
      //       ...decoded,
      //       type : ParseTransactionTypes.TrackPfp,
      //       //@ts-ignore
      //       data : {
      //           //@ts-ignore
      //          ...decoded?.data
      //       },
      //       keys : {
      //         ...transactionInstruction.keys
      //       }

      //  })
      // }
      else {
        parsedTransctions.push({
          programId: transactionInstruction.programId,
          type: ParseTransactionTypes.Unknown,
          data: transactionInstruction.data,
          keys: transactionInstruction.keys,
        });
      }
    } else if (
      transactionInstruction.programId.equals(
        new PublicKey(SPL_TOKEN_STAKING_ID_V0),
      )
    ) {
      const coder = new BorshInstructionCoder(SplTokenStakingIDLV0);
      const decoded = coder.decode(transactionInstruction.data, "base58");
      let type = ParseTransactionTypes.BonkRewardsDeposit;
      if (decoded?.name === "claimAll") {
        type = ParseTransactionTypes.BonkRewardsClaim;
      }
      parsedTransctions.push({
        ...decoded,
        type,
        //@ts-ignore
        data: {
          //@ts-ignore
          ...decoded?.data,
        },
        keys: {
          ...transactionInstruction.keys,
        },
      });
    }
  }

  return parsedTransctions;
};
