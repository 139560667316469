import { InformationCircleIcon, XMarkIcon, ForwardIcon } from "@heroicons/react/24/solid"
import { TokenStandard } from "@metaplex-foundation/mpl-token-metadata"
import { useConnection } from "@solana/wallet-adapter-react"
import { PublicKey } from "@solana/web3.js"
import { getMetadataAccount } from "align-sdk"
import { Tooltip } from "antd"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import toast from "react-hot-toast"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { organisationConfigFormInput } from "../../../state/forms"
import { WarpButton } from "./components/WarpButton"
import { DescriptionPanel } from "../../DescriptionPanel"
import { CollectionNftInfo } from "./components/CollectionNftInfo"
import { WizardStepProps } from "../../CreateProposalWizard/Wizard"

export function OrganisationConfigStep({onComplete, goBack}: WizardStepProps<{
  rankingPeriod : number,
  proposalMinReputationGate : number,
}>){
  const configInputs = useRecoilValue(organisationConfigFormInput);
  const setConfigInputs = useSetRecoilState(organisationConfigFormInput)
  const {connection} = useConnection()

  const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm<{
    rankingPeriod : number,
    proposalMinReputationGate : number,
    collectionItems: {
        mint: string,
        repMultiplier: number,
     }[]
  }>({
    defaultValues: {
        rankingPeriod: configInputs?.rankingPeriod || 3,
        proposalMinReputationGate: configInputs?.proposalMinReputationGate || 0,
        collectionItems: configInputs?.collectionItems || []
    } 
  });


  const {fields, append, remove} = useFieldArray({
      control,
      name: "collectionItems",
      rules: {
          validate: async (vals) => {
              
              const collectionPromises = vals.map(item => getMetadataAccount(new PublicKey(item.mint), connection))
              try {
                  const res = await Promise.all(collectionPromises) 

                  if(res.filter(x => x === null).length > 0){
                     toast.error("Failed to fetch some of your collection NFT's. Please ensure you have the correct collection mint.")
                     return false

                  }

                  if(res.length !== 0 && res.filter(x => x.tokenStandard === TokenStandard.NonFungible || x.tokenStandard === TokenStandard.ProgrammableNonFungible).length === vals.length){
                      return true
                  }
                  toast.error("Please select at least one valid collection nft.")
                  return false
              }
              catch(e){
                  toast.error("Failed to fetch some of your collection NFT's. Please ensure you have the correct collection mint.")
                  return false
              }
          }
      },

      
  })

  const rankingPeriod = watch("rankingPeriod", configInputs?.rankingPeriod)

 const onSubmit = (config : {
      rankingPeriod : number,
      proposalMinReputationGate : number,
      collectionItems: {
          mint: string,
          repMultiplier: number,
       }[]
 }) => {
      console.log("Storing organisation config inputs", {...config})
      setConfigInputs({...config})
      onComplete({...config})
 }


  

 return (
  <>
       <DescriptionPanel message="Please choose the collection mints to be associated to your organisation. These collections will be used
       to gate participation in your organisation. REP Multipliers are used to give certain collections more upvotes in the ranking period"/>
      <form onSubmit={handleSubmit(onSubmit)} 
          className=" flex flex-col gap-4 rounded-box border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 w-full p-6 lg:col-span-2">
          <div className="p-4 relative flex flex-col gap-4 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center">
              <div className="flex gap-3">
              <label className="font-light">Max Community Ranking Period</label> 
                  <Tooltip title="The max time period a proposal will be available to the community for ranking. After which a council vote can commence.">
                      <InformationCircleIcon className="w-4 text-opacity-10"/>
                  </Tooltip>
              </div>

              <div className="flex w-full gap-4">
                  <label className="text-contrast bg-contrast bg-opacity-10 p-1  px-2 rounded-md">{rankingPeriod} Days</label>
                  <input type="range" step="1" min="0" max="14" {...register("rankingPeriod", { required: true, valueAsNumber : true, validate: (val) => val > 0 && val <= 14})} className=" w-full bg-accent text-accent " defaultValue="3"/>
                  {errors.rankingPeriod?.type === "validate" && <span className=" absolute bottom-1 right-2 text-xs text-red-500 ">Ranking peroid must be above 0</span>}

              </div>   
          </div>
          <ul className="flex flex-col gap-4">
              {fields.map((item, index) => (
                  <li key={item.id} className="relative flex flex-col p-4 items-center gap-4 bg-contrast bg-opacity-5 border border-accent border-opacity-20 rounded-md justify-center">
                      <Controller 
                          name={`collectionItems.${index}.mint`}
                          control={control}
                          render={({ field }) => <>
                              <CollectionNftInfo mintField={field} mint={field.value}/> 
                              <WarpButton mint={field.value} onSuccess={(collection: PublicKey) => {
                                  setValue(`collectionItems.${index}.mint`, collection.toBase58())
                              }}/>
                          </> 
                      }
                          />
                      <Controller 
                          name={`collectionItems.${index}.repMultiplier`}
                          control={control}
                          render={({ field }) => (
                              <div className="mb-1 flex gap-2 justify-center flex-1 bg-transparent border border-contrast border-opacity-10 rounded-full text-sm w-full ">
                                  <label className="bg-contrast bg-opacity-20 p-2 px-3 rounded-l-full w-32">REP Multiplier</label>
                                  <input className="bg-transparent flex-1 outline-none focus:outline-none" {...field} value={field.value}/>
                              </div>
                          )  
                      }
                          />
                         
                      
                      <XMarkIcon className="w-6 p-1 bg-contrast text-opacity-70 bg-opacity-10 rounded-full absolute top-1 right-1" type="button" onClick={() => remove(index)}/>

                  </li>


              ))}
  
          </ul> 


          <div>
              <button
                      className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                      transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                      type="button"
                      onClick={() => append({mint: "EBZXnEpDU2JQpPDdmUC8T9NJgD1RU1vxDZFCEj4MmtjL", repMultiplier: 1})}
                      >
                      Add Collection Mint
                  </button>

                  <button
                          className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                          transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                          type="submit"
                          
                          >
                          <ForwardIcon className={`w-4 `}/>
                          Review Organisation
                  </button>

                  <button
                        className="w-full flex justify-center gap-2 items-center mt-4 self-end border rounded-button border-primary 
                        transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                        onClick={() => goBack()}
                    >   
                        <ForwardIcon className={`w-4 transform rotate-180`}/>
                        Go back
                    </button>
              </div>
      </form>
  </>
  )
}