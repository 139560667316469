import { selector } from "recoil";

export const queryCustomDomainOrganisation = selector<string | undefined>({
  key: "domains/queryOrganisation",
  get: async ({ get }: any) => {
    try {
      const res = await fetch(`/api/domains`);
      const domain = await res.json();
      return domain?.orgAddress;
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  },
});

export const isCustomDomainStore = selector<boolean>({
  key: "domains/isCustom",
  get: async ({ get }: any) => {
    const org = get(queryCustomDomainOrganisation);
    if (org === undefined) {
      return false;
    }
    return true;
  },
});
