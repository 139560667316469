import { NotificationsButton } from "@dialectlabs/react-ui";
/**
 * TODO Only have a notification stamp on icon for unactioned/viewed items
 * @returns 
 */
export function NotificationDropDownButton(){

    // const [showDropdown, setShowDropdown] = useState(false)
    // const {notifications} =useNotifications()
    // const unseenNotifications = useMemo(() => (notifications).filter((notif: any) => !notif.seen && !notif.context.contributionRecord.account.isClaimed ).length, [notifications])

    return (
        <div className="relative ml-2">

        {/* <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="flex items-center gap-1 text-accent"
        >   
            
            <BellIcon className="relative w-8 sm:w-10 text-contrast hover:bg-opacity-30 rounded-full bg-contrast bg-opacity-20 p-2"/>
            
            {unseenNotifications > 0 && ( 
                <div className="hidden sm:flex justify-center items-center text-contrast text-[10px] font-normal w-4 h-4 bg-accent rounded-full absolute top-0 -right-1">
                    {unseenNotifications < 100 ? unseenNotifications : "99+"}
                </div>
            )}

        </button>
        
         {showDropdown && (<NotificationDropDown 
                                onClose={() => setShowDropdown(false)}
                                notifications={notifications}
                                />)}  */}

                                {
                                    <NotificationsButton 
                                                        dappAddress={"88FH9gTy1R8HFewr7J2aTB9W9fqrveEn85UYyeWyLQaC"} 
                                                        dialectId="dialect-notifications"                                                        
                                                        />
                                }

    </div>
             
    )
}

// export function NotificationDropDown({onClose, notifications}: {onClose: () => void, notifications : Notification<NotificationContext>[]}){
//     return <> 
//     <div onClick={onClose} className="w-screen h-screen bg-transaparent z-40 fixed left-0 top-0"></div>

//     <ul className="fixed w-full z-50 sm:absolute right-0 top-12 bg-black bg-opacity-80 backdrop-blur-sm text-contrast sm:w-96 px-5 py-5 h-[calc(100vh-140px)] overflow-scroll box-container self-start rounded-box border-boxWidth border-contrast border-opacity-30">
//         <button 
//             onClick={onClose}
//             className="flex justify-between h-10 w-full items-start border-b">
//             <label className="text-lg self-end pb-2 font-light">Notifications</label>
//             <XMarkIcon className=" w-7 h-7 self-start shadow-md border rounded-full p-1"/>

//         </button>
//         {notifications && notifications.length ?  [...notifications].sort((a, b) => b.timeStamp - a.timeStamp ).map(notification => (
//             <li key={notification.id} className="py-2">
//                 <NotifcationItem notification={notification}/>
//             </li>

//         )) : (
//             <p className="box-container flex flex-col justify-center text-center rounded-box bg-contrast bg-opacity-5 p-5 text-contrast mt-5">You don't have any notifications yet</p>
//         )}

//         <div className="h-10"></div>
//     </ul>
//     </>
// }

// export function NotifcationItem({notification}: {notification: Notification<NotificationContext>}){

//     if(notification.type === NotifType.RedeemReputation) return(<ReputationRedeemNotifcationItem notification={notification as Notification<UnclaimedReputationContext>}/>)

//     return (
//     <>
//         {notification.message}

//     </>
        
//     )
// }


// export function ReputationRedeemNotifcationItem({notification}: {notification: Notification<UnclaimedReputationContext>}){

//     const proposal = useProposal(notification.context.contributionRecord.account.proposal)
//     const proposalMeta = useProposalMetadata(proposal?.address.toBase58())
//     const userInfo = useAuth()
//     const proposerInfo = useUser(proposal?.account.proposer.toBase58())
//     const refreshRep = useRefreshReputation(userInfo?.identity?.identifier)
//     const refreshContribution = useRefreshContributionRecord(proposal?.address.toBase58())
//     const alignPrograms = useAlignPrograms()

//     const redeemRep = async () => {
//         if(!alignPrograms || !userInfo?.identity || !proposal){
//             return
//         } 
//             await toast.promise(
//                 redeemReputation(
//                     new PublicKey(userInfo.identity.identifier),
//                     proposal?.address,
//                     alignPrograms 
//                 ),
//                 {
//                     loading: "Redeeming your reputation...",
//                     success: "Reputation redeemed successfully!",
//                     error: (e) => `${e.toString()}`
//                 }
//             )
//             delay(() => {
//                 refreshRep()
//                 refreshContribution()
//             }, REFRESH_DELAY)

        
//     }

//     return (
//         <>
//             {proposal && proposalMeta && (


//             <div className={`flex flex-col gap-4 items-center bg-black bg-opacity-5 w-full h-full pr-2 py-2 rounded-md 
//                 ${!notification.seen && !notification.context.contributionRecord.account.isClaimed ? "border-2 border-accent border-opacity-75" : ""} `}>

//                 <div className="flex items-center gap-4">
//                     <div className="flex flex-col justify-center items-center gap-1 border-r border-black border-opacity-10 p-4">

//                         <Avatar size={16} userIdentifier={proposal?.account.proposer}/>
//                         <label className="text-xs font-semibold mt-1" >{proposerInfo?.displayName}</label>
//                         <label className="text-xs font-light" >{proposerInfo?.username}</label>

//                     </div>
//                     {/* <TicketIcon className="w-16 p-3"/> */}
//                     <div className="flex flex-col">
//                         <label className="text-sm border-b mb-2">{notification.title}</label>
//                         <label className="text-xs font-light">{notification.message}</label>
//                         {proposal &&(<label className="text-xs text-opacity-70 font-light">{truncateKey(proposal?.address.toBase58())}</label>)}
//                     </div>


//                 </div>
//                 {notification.context.contributionRecord.account.isClaimed === false ? 
//                     <button 
//                         onClick={redeemRep}
//                         className="text-sm bg-accent w-fit text-contrast px-4 py-1 rounded-md">
//                             Redeem
//                     </button>
//                     :
//                     <button 
//                         disabled
//                         className="text-sm bg-accent w-fit text-contrast px-4 py-1 rounded-md">
//                         Claimed
//                     </button>
//                 }
//             </div>
//                         )}
//         </>
//     )
// }