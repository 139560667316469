import { CreateOrganisationWizard } from "../components/CreateOrganisationWizard/CreateOrganisationWizard";

export function CreateOrganisation() {

    return (
        <div>
            {/* <h1>Create Organisation</h1> */}
            <CreateOrganisationWizard/>
        </div>
    )
}