import { PublicKey } from "@solana/web3.js";
import { useMemo, useState } from "react";
import { useAlignPrograms, useOrganisation, useProposalAddresses } from "../state/hooks/useAlignGovernance";
import { Api } from 'align-sdk'
import { Link } from "react-router-dom";
import { ProposalDto } from "../generated/graphql";
import { DiscoverSkeletonItem } from "./DiscoverSkeleton";
import MintProfilePicture from "./MintProfilePicture";

export function OrganisationItem({ 
    displayname, 
    pfp, 
    address, 
    username,
    loading
    // proposals 
}: 
{ 
    address: string, 
    displayname: string | undefined, 
    pfp: string | null, 
    username: string | undefined, 
    loading : boolean
    // proposals: ProposalDto[] 
}) {

    if(
        (!displayname 
        || !username) && !loading
        ){
            return <></>
        }

    if(loading){
            return <DiscoverSkeletonItem/>
        }
        

    return (<>

        <Link to={`/org/${address}`} className="h-full w-full">
            <div className="box-container rounded-box h-full flex items-center border-boxWidth border-contrast border-opacity-30 bg-secondary bg-opacity-30 py-6 px-6 hover:border-opacity-100 transition-all">
                <div className="flex gap-3 justify-start items-center text-contrast">
                    <MintProfilePicture mint={pfp} width={24}/>
                    <div className="flex h-full flex-col justify-center">
                        <div className="flex gap-3 justify-center items-center">
                            <h1 className="font-heading text-lg sm:text-xl md:text-2xl lg:text-4xl">{displayname}</h1>
                        </div>
                        <label className="text-sm font-thin opacity-75">{username}</label>
                        {/* <small className="opacity-80 uppercase mt-2">{`${proposalAddresses.length} Proposals`}</small> */}
                    </div>
                </div>
            </div>
        </Link>

    </>
    )
}