import toast from "react-hot-toast";
import { useAlignPrograms, useAuth, useCurrentOrganisation } from "../state/hooks/useAlignGovernance";
import { followOrganization, trackNft } from "align-sdk";
import { useWallet } from "@solana/wallet-adapter-react";
import { web3 } from "@project-serum/anchor";
import { Suspense, useState } from "react";
import { OrganisationHeader } from "./OrganisationHeader";

export function JoinOrg() {
    
    return (
        <div className="my-4 flex w-full justify-between items-end gap-4">
            <Suspense>
                <OrganisationHeader/>
            </Suspense>
        </div>
    );
}
