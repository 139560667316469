import { ForwardIcon } from "@heroicons/react/24/outline";
import { metadataBeet, TokenStandard } from "@metaplex-foundation/mpl-token-metadata";
import { useConnection } from "@solana/wallet-adapter-react";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import { createProposal, getMetadataAddress, USDC_MINT_ADDRESS } from "align-sdk";
import BN from "bn.js";
import { delay } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useGetReputationQuery } from "../../../generated/graphql";
import { isCustomDomainStore } from "../../../state/domains";
import { proposalWizardDescriptionStepInputs, proposalWizardServicerStepInputs } from "../../../state/forms";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useIsCouncilMember, useOrganisation, useRefreshProposals } from "../../../state/hooks/useAlignGovernance";
import { alignLink } from "../../../utils/alignRoute";
import Avatar from "../../Avatar";
import { REFRESH_DELAY } from "../../../constants";
import { DescriptionPanel } from "../../DescriptionPanel";
import { WizardStepProps } from "../Wizard";

const ProposalWizardReviewStep = ({
    currentStep,
    onComplete
} : WizardStepProps<{}>) => {
    const user = useAuth()
    const [organisation] = useCurrentOrganisation()
    const organisationAccount = useOrganisation(organisation)

    const reputationResponse = useGetReputationQuery({
        variables: {
            userIdentityAddress: user?.userIdentityAddress || "",
            organisationAddress : organisation || "" 
        },
        skip: user?.userIdentityAddress === undefined || organisation === undefined
    })
    const reputation = reputationResponse?.data?.reputation.totalReputation
    const isCouncil = useIsCouncilMember(user?.identity?.identifier)
    const isCustomDomain = useRecoilValue(isCustomDomainStore)
    const servicerInputs = useRecoilValue(proposalWizardServicerStepInputs)
    const metadataInputs = useRecoilValue(proposalWizardDescriptionStepInputs)
    const {connection} = useConnection()
    const refreshProposals = useRefreshProposals(organisation)
    const navigate = useNavigate()

    const [coinMeta, setCoinMeta] = useState<{
        symbol: String,
        name: string,
        image: string,
        mint: PublicKey,
        tokenStandard: TokenStandard
    }>();

    const alignPrograms = useAlignPrograms()


    useEffect(()=> {
        const fetchBalance = async () =>{
            if(servicerInputs == null){
                return
            }
            try{
                if (servicerInputs?.mint === null){
           
                    setCoinMeta({
                        symbol : "SOL",
                        name : "Solana",
                        image : "/sol-logo.webp",
                        mint: SystemProgram.programId,
                        tokenStandard : TokenStandard.Fungible
                    })
                    return
                }

                if (servicerInputs?.mint === USDC_MINT_ADDRESS.toBase58()){
                    setCoinMeta({
                        symbol : "USDC",
                        name : "USDC",
                        image : "/usdc-logo.webp",
                        mint: new PublicKey(servicerInputs.mint),
                        tokenStandard : TokenStandard.Fungible
                    })
                    return
                }
                const coinMetadataAddress = getMetadataAddress(new PublicKey(servicerInputs.mint))

                const accountInfo = await connection.getAccountInfo(
                    coinMetadataAddress
                );

                if (accountInfo) {
                    const [metadata] = metadataBeet.deserialize(accountInfo.data);
                    const response = await fetch(metadata.data.uri)
                    if(response.ok){
                        const json = await response.json()
                        setCoinMeta({...json, tokenStandard : metadata.tokenStandard, mint: metadata.mint})
                    }
                    return 
                }
                return 
 
            }
            catch(e){
                console.warn(e)
            }
        }
        fetchBalance()
    }, [servicerInputs])

    const handleCreateProposal = async (
    ) => {

        if((organisationAccount?.organisation 
            && organisationAccount?.organisation?.config?.proposalMinReputationGate !== null 
            && reputation < organisationAccount?.organisation?.config?.proposalMinReputationGate
            ) && !isCouncil){
                toast.error("You do not have enough reputation to create a proposal.");
                return;
            }
        if (!metadataInputs?.title || !metadataInputs?.description) {
          toast.error("All fields of the proposal form are required.");
          return;
        }

        if (!user?.identity) {
          toast.error(                
            <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                <div>There is no user for the proposal being submited.</div>
                <div>Please contact us if problems persist.</div>
            </div>);
          return;
        }

        if (!organisation) {
          toast.error(                
            <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                <div>No organisation was found that matched the proposal being submited.</div>
                <div>Please contact us if problems persist.</div>
            </div>);
          return;
        }

        if (!alignPrograms) {
          toast.error("Provider information was not found for instruction. Please contact us if problems persist.");
          return;
        }

        if(!servicerInputs?.walletConfig){
            toast.error("Please select a governance account to take the payout from if the proposal gets approved by the council.");
            return;
        }
        toast.loading("Creating your proposal this may take some time. Please be patient.", {
            id: 'createProposal' 
        })
        try{

            
            const {sigs, proposalAddress} = await createProposal(
                new PublicKey(user.identity.identifier),
                new PublicKey(organisation),
                new PublicKey(user.identity.identifier),
                {
                name: metadataInputs?.title,
                description: metadataInputs?.description,
                },
                // new BN(60 * 60 * 24 *3),
                new BN(0),
                new PublicKey(servicerInputs?.walletConfig),
                new BN(servicerInputs?.uiAmount).mul(new BN(Math.pow(10, servicerInputs?.mintDecimals))),
                alignPrograms,
                {
                    onCreatingDrive: () => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Creating a shadow drive to upload your proposal metadata to.</div>
                            <div>This may take some time..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onCreateDrive: (res) => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Drive created successfully..</div>
                            <div>Uploading metadata..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onUpload: (res) => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Upload complete!</div>
                            <div>Creating your proposal..</div>
                        </div>, {
                        id: 'createProposal'
                    }),
                    onUploadRetry: () => toast.loading(
                        <div style={{display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr 1fr"}}>
                            <div>Uploading metadata failed!</div>
                            <div>Trying again..</div>
                        </div>, {
                        id: 'createProposal'
                    })
                },
                servicerInputs?.mint ? new PublicKey(servicerInputs?.mint) : undefined
            )
            
                if( proposalAddress ) {
                    toast.success("Sucessfully created your proposal.", {id: 'createProposal'})
                    delay(() => {
                        refreshProposals();
                        navigate(alignLink(`/proposal/${proposalAddress.toBase58()}`, isCustomDomain, organisation))
                    }, REFRESH_DELAY);
                }
                else{
                    toast.error("Error creating proposal. Please try again.", {id: 'createProposal'})
                }
            }
            catch(e : any){
                console.error(e)
                toast.error(e.toString(), {id: 'createProposal'})

            }
    }

    if(currentStep !== 2){
        return <></>
    }
    return ( 
        <>
            <div className={`proposals-container relative box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col gap-2 w-full lg:col-span-2 p-3 sm:p-6 bg-secondary border-boxWidth`}>
                <h1 className="font-heading text-lg md:text-3xl mb-1">
              Review Proposal
            </h1>
            <DescriptionPanel message="Time to review your proposal before we process the transactions."/>
            <div className=" w-full text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 rounded-md">
                {/* <label className="font-light text-contrast text-opacity-60">Governance Thresholds</label> */}
                <div className="py-1 flex-col w-full items-start flex flex-wrap gap-4 ">
                    <div className="flex flex-col gap-2">
                        <label className="font-light text-contrast text-opacity-60">Title</label>
                        <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">{metadataInputs?.title}</div>
                    </div>
                </div>
           
            </div>

            <div className=" w-full text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 rounded-md">
                {/* <label className="font-light text-contrast text-opacity-60">Governance Thresholds</label> */}
                <div className="py-1 flex-col w-full items-start flex flex-wrap gap-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-light text-contrast text-opacity-60">Description</label>
                        <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">{metadataInputs?.description}</div>
                    </div>
                </div>
           
            </div>
            <div className="flex gap-3">        
        
                {
                    servicerInputs?.servicer && (
                
                        <div className=" w-full text-sm mt-2 flex-col gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 rounded-md">
                                <label className="font-light text-contrast text-opacity-60">Servicer</label>

                                {
                                    servicerInputs?.servicer ? 
                                    <div className="flex felx-col justify-start items-center gap-2">
                                    <div className="flex h-full items-center" >
                                        <Avatar userIdentifier={new PublicKey(servicerInputs?.servicer?.identifier)} size={16}/>  
                                    </div>
                                    <p className="flex flex-col h-full justify-center items-start">
                                        <span className="block text-lg font-light">
                                            {servicerInputs?.servicer.displayname}
                                        </span>
                                        <span className="block text-sm font-light text-contrast text-opacity-40">
                                            {servicerInputs?.servicer.handle}
                                        </span>
                                    </p>
                                </div>
                                : <div className="text-lg font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit">None</div>}

                                
                        </div>
                    )
        
                }
                <div className=" w-full text-sm mt-2 flex-col items-center gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 rounded-md">
                    <label className="font-light text-contrast text-opacity-60 self-start">Payout</label>
                    <div className="text-lg flex items-center gap-3 font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit mt-1">
                        <img className="rounded-full w-8" src={coinMeta?.image}/>
                        {servicerInputs?.uiAmount}
                    </div>
                </div>
        </div>


        <button
                    className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                        transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                    onClick={() => handleCreateProposal()}
                    
                    >
                    <ForwardIcon className={`w-4 `}/>
                   Create Proposal
            </button> 

        </div>



        </> 
    );
}
 
export default ProposalWizardReviewStep ;