import { ChevronDownIcon, ChevronUpIcon, CogIcon, FunnelIcon, HandThumbDownIcon, HandThumbUpIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { Account, Api, castCouncilVote, CouncilVote, createProposal, Derivation, WRAPPED_SOL_ADDRESS } from "align-sdk";
import { useWallet } from "@solana/wallet-adapter-react";
import { BN } from "bn.js";
import { Suspense, useEffect, useMemo, useState } from "react";
// import CouncilVotingPanel from "../components/CouncilVotingPanel";
// import PastProposalsPanel from "../components/PastProposalsPanel";
// import { ProposalSkeleton } from "../components/ProposalSkeleton";
import TreasuryPanel from "../components/TreasuryPanel";
import toast from "react-hot-toast";
import * as Align from "../align.config.js";
import { gridLayout } from "../align.config.js";
import { themeOpts } from "../components/ThemeChanger";
import { useAtom } from "jotai";
import { ProposalSkeleton } from "../components/ProposalSkeleton";
import { useAlignPrograms, useAuth, useCurrentOrganisation, useCurrentUsersCouncilVoteRecord, useIsCouncilMember, useProposal, useProposalAddresses, useProposalMetadata, useRefreshProposal } from "../state/hooks/useAlignGovernance";
import { web3 } from "@project-serum/anchor";
import { useNavigate } from "react-router-dom";
import { delay } from "lodash";
import { Tab } from '@headlessui/react'
import { Proposal } from "../components/Proposal";
import { CouncilVoteProgress } from "../components/CouncilVoteProgress";
import * as AlignConfig from "../align.config.js";
import { PublicKey } from "@solana/web3.js";


export function Review() {
  const wallet = useWallet();
  const [modalOpen, setModalOpen] = useState(false);
  const [theme, setTheme] = useAtom(themeOpts)

  return (
    <div className="mx-auto max-w-screen-2xl px-8 py-8 text-contrast">
      <div className="flex-wrap justify-between gap-6 lg:grid lg:grid-cols-3 mb-12">
        <main className={`proposals-container box-container rounded-box border-contrast border-opacity-30 bg-opacity-30 flex flex-col w-full lg:col-span-2 ${theme.gridLayout && 'p-3 sm:p-6 bg-secondary bg-opacity-30 border-boxWidth'}`}>
          
          <Tab.Group>
          <Tab.List className="w-full flex justify-center">
          <div className="flex border border-contrast border-opacity-30 rounded-md p-2  gap-4">
              <FunnelIcon className="w-6 p-1 bg-accent rounded-md"/>
               <Tab className="top-5 ui-not-selected:bg-transparent ui-not-selected:text-white right-6 flex justify-center gap-2 items-center rounded-button border-primary bg-accent border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs">{AlignConfig.strings.council ? AlignConfig.strings.council : 'Council'} Voting</Tab>
                <Tab className="top-5 right-6 ui-not-selected:bg-transparent ui-not-selected:text-white flex justify-center gap-2 items-center rounded-button border-primary bg-accent border-contrast hover:opacity-80
									transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs">{AlignConfig.strings.council ? AlignConfig.strings.council : 'Council'} Review</Tab>
              </div>
            </Tab.List>
            <Tab.Panels className="pt-5">
              <Tab.Panel>
                <h1 className="text-center font-heading text-lg md:text-2xl mb-4">
                  Needs Council Vote
                </h1>
                <div className="flex flex-col w-full">
                <Suspense>
                  <ProposalVotingList/>
                </Suspense>
                </div>
 

              </Tab.Panel>
              <Tab.Panel>
                <h1 className="text-center font-heading text-lg md:text-2xl mb-4">
                  Ready for Council Review
                </h1>
                <Suspense>
                  <ProposalReviewList/>
                </Suspense>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </main>
        <aside className="w-full lg:col-span-1 mt-4 lg:mt-0">
          <Suspense>
            <>
              <TreasuryPanel />
              {/* <CouncilVotingPanel />
              <PastProposalsPanel /> */}
            </>
          </Suspense>
        </aside>
      </div>
    </div>
  );
}


// export function ProposalVote({address}:{address: string}) {
//   const proposal = useProposal(address)
//   const [hide, setHide] = useState(true)
//   const user = useAuth()
//   const alignPrograms = useAlignPrograms()
// 	const {councilVoteRecord, refreshVoteRecord} = useCurrentUsersCouncilVoteRecord(address)
//   const isCouncil = useIsCouncilMember(user?.identity?.identifier)

//   const councilHasYesVoted = useMemo(() => councilVoteRecord && councilVoteRecord.account.vote.yes !== undefined, [user, councilVoteRecord])
// 	const councilHasNoVoted = useMemo(() => councilVoteRecord && councilVoteRecord.account.vote.no !== undefined, [user, councilVoteRecord])
// 	const councilHasAbstainVoted = useMemo(() => councilVoteRecord && councilVoteRecord.account.vote.abstain !== undefined, [user, councilVoteRecord])

//   if(!isCouncil) return <></>
//   return (<>
//     <div className="flex items-center justify-end">
//       <button 
//           onClick={() => setHide(!hide)}
//           className="flex justify-center gap-2 items-center rounded-button border-primary bg-accent border-buttonWidth border-contrast hover:opacity-80
// 									transition font-poppins font-medium p-3 px-3 py-1.5 text-center text-xs ">
//        {hide ?  
//         <ChevronDownIcon className=" w-4"/>
//         :
//         <ChevronUpIcon className="w-4"/>
//       }
//        Quick Vote
//       </button>

//     </div>
//     {!hide ? 
//       <div className="flex  w-full justify-center gap-8 overflow-hidden py-8 border-contrast border-opacity-30">
//         <div className="bg-contrast bg-opacity-10 w-5/7 flex gap-2 px-5 py-3 rounded-md">
//         {proposal && <CouncilVoteProgress 
//               proposalState={proposal.account.state} 
//               councilYesVotes={proposal.account.totalCouncilYesVotes} 
//               councilNoVotes={proposal.account.totalCouncilNoVotes} 
//               councilAbstainVotes={proposal.account.totalCouncilAbstainVotes}
//               walletConfig={proposal.account.authorityConfigAddress}
//               userVoteDirection={councilVoteRecord?.account.vote?.yes ? CouncilVote.Yes : councilVoteRecord?.account.vote?.no ? CouncilVote.No : undefined }
//               />}
//         <div className="flex items-center gap-3">
//           <button onClick={async () => {
// 							if (
// 								!user?.identity ||
// 								!user?.reputation ||
// 								!alignPrograms ||
// 								!proposal
// 							) {
// 								return;
// 							}
// 							try {
// 								const sig = await toast.promise(castCouncilVote(
// 									new PublicKey(user?.identity?.identifier),
// 									proposal.address,
// 									CouncilVote.Yes,
// 									alignPrograms
// 								), {
// 									loading: "Voting for proposal.",
// 									success: "Vote created successfully!",
//                   error: (e) => e.toString(),
// 								})
// 								delay(() => refreshVoteRecord(), 400)
// 								// console.log(sig)
// 								// Need to then update in the store. refresh proposal??						
// 							} catch (err) {
// 								console.error(err);
// 								return;
// 							}
// 						}}
//             disabled={councilHasNoVoted || councilHasYesVoted || councilHasYesVoted !== null || councilHasNoVoted !== null}
//             className={`border bg-transparent hover:bg-accent rounded-full    ${councilHasYesVoted ? "hover:border-green-400 border-green-400 hover:text-green-400 text-green-400 bg-transparent" : ""}`}><HandThumbUpIcon className="w-14 p-4"/></button>
//           <button
//             onClick={async () => {
//               if (
//                 !user?.identity ||
//                 !user?.reputation ||
//                 !alignPrograms ||
//                 !proposal
//               ) {
//                 return;
//               }
//               try {
//                 const sig = await toast.promise(castCouncilVote(
// 									new PublicKey(user?.identity?.identifier),
//                   proposal.address,
//                   CouncilVote.No,
//                   alignPrograms
//                 ), {
//                   loading: "Voting for proposal.",
//                   success: "Vote created successfully!",
//                   error: (e) => e.toString(),
//                 })
//                   delay(() => refreshVoteRecord(), 400)

//                 // Need to then update in the store. refresh proposal??						
//               } catch (err) {
//                 console.error(err);
//                 return;
//               }
//             }}
//             disabled={councilHasNoVoted || councilHasYesVoted || councilHasYesVoted !== null || councilHasNoVoted !== null}
//            className={`border bg-transparent hover:bg-accent rounded-full    ${councilHasNoVoted ? "hover:border-red-400 border-red-400 hover:text-red-400 text-red-400 bg-transparent" : ""}`}><HandThumbDownIcon className="w-14 p-4"/></button>
//         </div>
//         </div>

//       </div>

//         : <></>
//     }
//     </>

//   )
// }

export function ProposalReviewList() {
  // const {reviewProposals} = useProposals();
  // if( !reviewProposals || reviewProposals.length === 0) return <div className="w-full text-center text-contrast text-opacity-60">No proposals currently avaliable for review</div>
  return <>
    {/* {reviewProposals?.map((proposal) => (
      <Proposal address={proposal.address.toBase58()} key={proposal.address.toBase58()}/>
    ))} */}
  </>
}

export function ProposalVotingList() {
  // const {votingProposals} = useProposals();
  // if( !votingProposals || votingProposals?.length === 0 ) return <div className="w-full text-center text-contrast text-opacity-60">No proposals currently avaliable for {AlignConfig.strings.council ? AlignConfig.strings.council : 'Council'} voting</div>
  return <>
    {/* {votingProposals?.map((proposal) => (
      <Suspense>
          <Proposal address={proposal.address.toBase58()} key={proposal.address.toBase58()}>
            <ProposalVote address={proposal.address.toBase58()}></ProposalVote>
          </Proposal>
      </Suspense>
    ))} */}
  </>
}

