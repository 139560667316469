import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const Iframe = ({
  children,
  style,
  fontColor
} : {children: React.ReactNode, style: Record<string, number | string>, fontColor?: string}) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null)

  const mountNode =
    contentRef?.contentWindow?.document?.body

  useEffect(() => {
    if(!mountNode?.style){
      return
    }
    mountNode.style.color = fontColor? fontColor : "#dcdbdb"
    mountNode.style.fontFamily = "sans-serif"
    // mountNode.style.display = "100%"


  }, [ mountNode, contentRef, fontColor])

  return (
    <iframe style={style} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  )
}