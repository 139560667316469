import { AnchorProvider } from "@project-serum/anchor"
import { getMint } from "@solana/spl-token"
import { useConnection, useAnchorWallet } from "@solana/wallet-adapter-react"
import { PublicKey, Keypair } from "@solana/web3.js"
import { swapSolToShadowIfBelowMin, createShadowAccountWithSize, convertProposalDataToFile } from "align-sdk"
import BN from "bn.js"
import { useState, useEffect, useRef } from "react"
import toast from "react-hot-toast"
import { useAlignPrograms } from "../../../../state/hooks/useAlignGovernance"
import { createWarpProvider, createWarp } from "../../../../warp"

export const WarpButton = ({
  mint,
  onSuccess
 } : {
  mint: string,
  onSuccess : (collectionMint : PublicKey) => void
 }) => {
  const [isFungible, setIsFungible] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const {connection} = useConnection()
  const wallet = useAnchorWallet()

  useEffect(() => {
      const fetchMetadata = async () => {
          try{
              const address = new PublicKey(mint)
              const mintAccount = await getMint(connection, address )
              if(mintAccount.decimals !== 0){
                  setIsFungible(true)
              }
          }
          catch(e){
              console.log(e)
          }
      }
      fetchMetadata()
  }, [mint]);

  const WarpModal = ({
      onClose,
      onSuccess,
      show
  } : {
      onClose : () => void,
      onSuccess : (collectionMint : PublicKey) => void,
      show : boolean
  }) => {
      const [fungiblePerNft, setFungiblePerNft] = useState<string | null>(null);
      const [collectionName, setCollectionName] = useState<string | null>(null);
      const [symbol, setSymbol] = useState<string | null>(null);
      const [imageUri, setImageUri] = useState(null);
      const [metadataUri, setMetadataUri] = useState(null)
      const [isUploadingImage, setIsUploadingImage] = useState(false)
      const [imageBlob, setImageBlob] = useState<Blob | null>(null)
      const [originalFileName, setOriginalFileName]= useState<string>("")

      const alignPrograms = useAlignPrograms()

      const fileInputRef = useRef<HTMLInputElement>(null); // added a ref to handle file input
  
      // handler to deal with file upload
      const handleFileUpload = async (event: any) => {
          event.preventDefault();
          const files = event.target.files || event.dataTransfer.files;
          const file : File = files[0];
          if (file) {
              setImageBlob(file.slice(0, file.size, file.type))
              setOriginalFileName(file.name)
          }
      }
  
      const handleDragOver = (event : any) => {
          event.preventDefault();
          event.stopPropagation();
      }
  
      const handleDragDrop = (event : any) => {
          event.preventDefault();
          event.stopPropagation();
          handleFileUpload(event);
      }
  
      const handleFileInputClick = () => {
          fileInputRef?.current?.click();
      }
      

      return ( 
          <div
              id="defaultModal"
              tabIndex={-1}
              aria-hidden="true"
              className={`fixed top-0 left-0 right-0 z-50 w-screen h-screen flex justify-center items-center bg-black bg-opacity-70 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${show ? "" : "hidden"
              }`}
          >
              <div className="relative w-full h-full max-w-2xl md:h-auto text-contrast">
                  <div className="relative bg-black border border-white border-opacity-30 rounded-lg shadow text-contrast p-10">
                      <div className="flex flex-col items-start justify-between">
                          <div className="w-full">
                              <label className="font-light">Collection Name</label>
                              <input className="input w-full"  placeholder="Bonk DAO Pass" onChange={(e) => setCollectionName(e.target.value)} value={collectionName ? collectionName : undefined}/>
                          </div>

                          <div className="w-full">
                              <label className="font-light">Collection Symbol</label>
                              <input className="input w-full"  placeholder="BNK" onChange={(e) => setSymbol(e.target.value)}  value={symbol ? symbol : undefined}/>
                          </div>

                          <div className="w-full">
                              <label className="font-light">Fungible per NFT</label>
                              <input className="input w-full"  placeholder="100" onChange={(e) => {
                                  const number = Number(e.target.value)
                                  if (!isNaN(number)){
                                      setFungiblePerNft(number.toString())
                                  }
                                  
                              }}  value={fungiblePerNft ? fungiblePerNft : undefined}/>
                          </div>

                          <div className="w-full">
                              <label className="font-light">Image</label>
                              <div className="border-dashed border-opacity-30 border h-48 w-full flex flex-col justify-center items-center" 
                                  onDrop={handleDragDrop} 
                                  onDragOver={handleDragOver} 
                                  onClick={handleFileInputClick} 
                                  
                              >
                                  <input type="file" 
                                      onChange={handleFileUpload} 
                                      ref={fileInputRef} 
                                      style={{display: "none"}}
                                      className=""/>
                                  
                                  <label className="opacity-60"> Drag and drop your image here or click to select image</label>
                                 <label className="text-primary">{originalFileName}</label>
                              </div>
                          </div>


                          <button
                              className="w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary 
                              transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                              onClick={async (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  const mintPubkey = new PublicKey(mint)
                                  const collectionKeypair = new Keypair()
                                  
                                  if(!wallet || !fungiblePerNft || !collectionName || !symbol  || !imageBlob || !alignPrograms){
                                      toast.error("Something went wrong. Make sure all items in the form have been set correctly", {id: "createWarp"})
                                      return
                                  }

                                  let imageFileName ;

                                  if (imageBlob.type === "image/png"  ) {
                                      imageFileName = `${collectionName}.png`
                                  }
                                  else if(imageBlob.type === "image/jpeg"){
                                      imageFileName = `${collectionName}.jpg`
                                  }
                                  else if(imageBlob.type === "image/gif"){
                                      imageFileName = `${collectionName}.gif`
                                  }
                                  else{
                                      toast.error("Image file is not in a compatiable format", {id: "createWarp"})
                                      return
                                  }
                                  const provider = new AnchorProvider(connection, wallet, {})
                                  const warpProgram = createWarpProvider(provider)
                                  toast.loading("Purchasing some shadow from jupiter for nft metadata upload.", {id: "createWarp"})

                                  await swapSolToShadowIfBelowMin(alignPrograms.wallet, alignPrograms.mainnetProvider, 0.001 );

                                  const renamedFile = new File( [imageBlob.slice(0, imageBlob.size, imageBlob.type)], imageFileName)
                                  toast.loading("Creating the metadata for your warped collection and uploading to shadow drive.", {id: "createWarp"})

                            
                                  const drive = await createShadowAccountWithSize(collectionName, (renamedFile.size / 1024) *2, alignPrograms.shadowDriveInstance) 

                                  const metadata = {
                                      name : collectionName,
                                      symbol: symbol,
                                      description: `Warped ${collectionName} collection for fungible asset.`,
                                      image: `https://shdw-drive.genesysgo.net/${drive.shdw_bucket}/${imageFileName}`,
                                      attributes: []
                                  }
                                  const metadataFile = convertProposalDataToFile(metadata, collectionName) as File
                                  const list = new DataTransfer()
                                  list.items.add(renamedFile)
                                  list.items.add(metadataFile)

                                  const res = await alignPrograms?.shadowDriveInstance.uploadMultipleFiles(new PublicKey(drive.shdw_bucket), list.files)
                                  toast.loading("Uploaded your colllection metadata. Now creating your warp account...", {id: "createWarp"})
                                  // const res = await alignPrograms?.shadowDriveInstance.uploadFile(new PublicKey(drive.shdw_bucket))

                   

                                  const mintAccount = await getMint(provider.connection, new PublicKey(mint))

                                  const factor = new BN(10).pow(new BN(mintAccount.decimals))

                                  const sig = await createWarp(
                                          mintPubkey,
                                          new BN(fungiblePerNft).mul(factor),
                                          {
                                              name : collectionName,
                                              symbol: symbol,
                                              uri: res[1].location
                                          },
                                          collectionKeypair,
                                          res[1].location,
                                          warpProgram,
                                          wallet
                                      )
                                  
                                  toast.success(`Warp created successfully. With collection address ${collectionKeypair.publicKey.toBase58()}`, {id: "createWarp"})
                                  onSuccess(collectionKeypair.publicKey)
                                  onClose()
                              }}
                          >Create Warp</button>

<button
                      onClick={() => onClose()}
                      type="button"
                      className="bg-transparent hover:opacity-90 absolute right-2 top-2 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                      data-modal-toggle="defaultModal"
                  >
                      <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      >
                      <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                      ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                  </button>
                      </div>
                  </div>
              </div>
          </div>
       );
  }
   
  return (
       <>
          {
              isFungible && <button 
                              className="order-last w-full flex justify-center gap-2 items-center mt-4 self-end bg-accent rounded-button border-primary                              
                          transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                              onClick={(e) => {
                                  e.preventDefault()
                                  setShowModal(true)
                              }}
                              >Create Warp-able Fungible</button>
          }
          <WarpModal show={showModal} onClose={() => setShowModal(false)} onSuccess={onSuccess}/>
      </> 
  );
 }
