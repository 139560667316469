import { PublicKey } from "@solana/web3.js";

export const IDENTIFIERS_PROGRAM_ID = new PublicKey(
  "A8q3F93JH4VmLhJ3aTKH5wAp6y7MzupNbzqzPhz4J4dr",
);
export const MULTIGRAPH_PROGRAM_ID = new PublicKey(
  "Ds2xBZUz4cv6Uh9cAWZu5TtQDcDnnKH5ALRUiU9PJ8Sj",
);
export const PROFILES_PROGRAM_ID = new PublicKey(
  "CAfXv5dA3uy2Sv2C81h6q3ZJ9fUE6nMM78TpnbA8mfGc",
);
export const LEAF_PROGRAM_ID = new PublicKey(
  "6jzswRHF47Pb73WFWLY4x3eZ4ob2X8cX8rmmzGLXVtj7",
);
export const ALIGN_PROGRAM_ID = new PublicKey(
  "G4GRvHzPJazUXYywVoTx19CDgnhJRpve454frFXZ6ojV",
);
export const BEACON_PROGRAM_ID = new PublicKey(
  "G2twb9Ykb6SFbm5z1FqZAD6w6DiAUnMusMdsSNQ66NCu",
);
export const WARP_PROGRAM_ID = new PublicKey(
  "2G5cApuDirL7mdS53iJiHy7yuJiEDxGm5pXD5o1NeSR4",
);
export const CLOCKWORK_PROGRAM_ID = new PublicKey(
  "3XXuUFfweXBwFgFfYaejLvZE4cGZiHgKiGfMtdxNzYmv",
);

export const SHADOW_PROGRAM_ID = new PublicKey(
  "2e1wdyNhUvE76y6yUCvah2KaviavMJYKoRun8acMRBZZ",
);

export const POINTS_PER_SECOND = 10;
export const POINTS_DECIMAL = 1000000000;

export const WRAPPED_SOL_ADDRESS = new PublicKey(
  "So11111111111111111111111111111111111111112",
);
export const USDC_MINT_ADDRESS = new PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
);
export const BERN_MINT_ADDRESS = new PublicKey(
  "CKfatsPMUf8SkiURsDXs7eK6GWb4Jsd6UDbs7twMCWxo",
);

export const TOKEN_AUTH_RULES_ID = new PublicKey(
  "auth9SigNpDKz4sJJ1DfCTuZrZNSAgh9sFD3rboVmgg",
);

export const SHADOW_MINT = new PublicKey(
  "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y",
);

export const MAX_WALLET_THRESHOLD = 10_000;
export const MIN_WALLET_THRESHOLD = 1;
export const DEFAULT_PRIORITY_LAMPORT = 350_000;
