import { Account, Api, User } from "align-sdk";
import { useEffect, useMemo, useState } from "react";
import { ArrowRightCircle } from "react-feather";
import { useRecoilValue } from "recoil";
import { queryCouncilManager } from "../state/alignGovernance";
import { useAlignPrograms, useCurrentOrganisation, useWalletConfigs } from "../state/hooks/useAlignGovernance";
import { ParsedProposalInstruction, ParseTransactionTypes } from "../types";
import { truncateKey } from "../utils/truncateKey";
import Avatar from "./Avatar";
 

export const CouncilProposalDetailItem = ({instruction} : { instruction: ParsedProposalInstruction}) => {
    const [user, setUser] = useState<Account<User> | null>(null);
    const programs = useAlignPrograms()
    useEffect(() => {
        const fetchuser = async () => {
            if(!programs){
                return
            }
            if(instruction.type === ParseTransactionTypes.AddCouncilMember){
                //@ts-ignore
                const user = await Api.fetchUserProfileByIdentityAddress(instruction.keys[5].pubkey, programs)
                setUser(user)
            }
            else if(instruction.type === ParseTransactionTypes.RemoveCouncilMember){
                //@ts-ignore
                const user = await Api.fetchUserProfileByIdentifier(instruction.data.councilIdentifier, programs)
                setUser(user)
            }

        }
        fetchuser()
    }, [instruction, programs])
    return ( 
        <div className="flex flex-col w-24 justify-center items-center gap-2">
        <div className="flex h-full items-center" >
       
            <Avatar userIdentifier={user?.account.identifier} size={12}/>
        </div>
        <p className="flex flex-col h-full justify-center items-center">
            <span className="block text-xs font-light text-contrast text-opacity-40">
                {user?.account.username}
            </span>
        </p>
    </div>
     );
}

export const SetThresholdDetailItem = ({ instruction, newCouncilCount, oldCouncilCount, hasPassedVote }: { instruction: ParsedProposalInstruction, newCouncilCount: number, oldCouncilCount: number, hasPassedVote: boolean }) => {
  const configs = useWalletConfigs()
    //@ts-ignore
    const walletConfig = useMemo(() => configs.find(config => config.address.toBase58()=== instruction?.keys[5].pubkey.toBase58()), [instruction.keys])
    
    return ( 
        <div className="flex flex-col gap-2 bg-contrast bg-opacity-5 rounded-sm border-opacity-30 p-3 w-5/12">
            <div className="flex flex-col gap-1 justify-start items-center">
                <label className="text-contrast text-opacity-100">{walletConfig?.account?.name} Governance</label>
                <label className=" font-extralight text-xs text-contrast text-opacity-60">{walletConfig?.account ? truncateKey(walletConfig?.account.authorityAddress.toBase58()) : ""}</label>
            </div>
           
            <div className="flex gap-4 justify-center items-center">
            

                <><div className="text-md font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit text-center">{
                        //@ts-ignore
                        Math.ceil(oldCouncilCount * (walletConfig?.account.threshold/ 10000)) + " / " + oldCouncilCount
                }
                                <div className="text-xs ">Threshold</div>

                </div>

                    </>
                {!hasPassedVote && (
                    <>

                    <ArrowRightCircle className="w-12" />
                    <div className="text-md font-light bg-contrast bg-opacity-10 p-2 rounded-md w-fit text-center">{
                                //@ts-ignore
                             Math.ceil(newCouncilCount  * (instruction.data.threshold / 10000)) + " / " + newCouncilCount  
                    }
                        <div className="text-xs ">Threshold</div>
                    </div>
                    </>
                )}

            </div>
           

        </div>
     );
}


interface CouncilProposalDetailsProps {
  transactions: ParsedProposalInstruction[];
  hasPassedVote: boolean;
}

const CouncilProposalDetails = ({ transactions, hasPassedVote }: CouncilProposalDetailsProps) => {
    const containsSetThresholdInstruction = useMemo(() => transactions.filter(tx => tx.type === ParseTransactionTypes.SetWalletThreshold).length > 0 , [transactions])
    const containsAddCouncilInstruction = useMemo(() => transactions.filter(tx => tx.type === ParseTransactionTypes.AddCouncilMember).length > 0 , [transactions])
    const containsRemoveCouncilInstruction = useMemo(() => transactions.filter(tx => tx.type === ParseTransactionTypes.RemoveCouncilMember).length > 0 , [transactions])
    const [currentorg]= useCurrentOrganisation()

    const council = useRecoilValue(queryCouncilManager(currentorg))

    const councilCount = useMemo(() => {
        if (council?.account.councilCount === undefined){
            return 0
        }
        let count = council?.account.councilCount
        if(containsAddCouncilInstruction ){
            count = transactions.filter(tx => tx.type === ParseTransactionTypes.AddCouncilMember).length + count
        }

        if(containsRemoveCouncilInstruction){
            count = count - transactions.filter(tx => tx.type === ParseTransactionTypes.RemoveCouncilMember).length 
        }
        return count
    } ,[council?.account.councilCount, transactions])

    
    return ( 
        <>
        {containsAddCouncilInstruction && (
                        <div className=" w-full flex text-sm mt-2 flex-col gap-5 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 ">
                        <label className="font-light text-contrast text-opacity-60">Add Council Members</label>
                        <div className="flex gap-4 flex-wrap">
    
               {transactions.filter(ix => ix.type === ParseTransactionTypes.AddCouncilMember).map(ix => (
                 <CouncilProposalDetailItem instruction={ix}/>
               ))} 
    
            </div>
            </div>
        )}

        {containsRemoveCouncilInstruction && (


                    <div className=" w-full flex text-sm mt-2 flex-col gap-5 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3">
                                    <label className="font-light text-contrast text-opacity-60">Remove Council Members</label>
                                    <div className="flex gap-4 flex-wrap">

                        {transactions.filter(ix => ix.type === ParseTransactionTypes.RemoveCouncilMember).map(ix => (
                            <CouncilProposalDetailItem instruction={ix}/>
                        ))} 

                    </div>
                </div>
        )}

        {
            containsSetThresholdInstruction && council && (
                <div className=" w-full text-sm mt-2 flex-col items-center gap-3 bg-contrast bg-opacity-5 border border-accent border-opacity-20 py-5 px-3 rounded-md">
                <label className="font-light text-contrast text-opacity-60 mb-2">Set Wallet Thresholds</label>
                <div className="py-1 w-full items-start flex flex-wrap gap-4 mt-4">
                    
                    {transactions.filter(ix => ix.type === ParseTransactionTypes.SetWalletThreshold).map(ix => (
                        <>
                        
                            <SetThresholdDetailItem 
                                instruction={ix}
                                newCouncilCount={councilCount}
                                oldCouncilCount={council.account.councilCount} hasPassedVote={hasPassedVote}
                               />
                        
                        
                        </>
                    ))}
                </div>

            </div>
            )
        }
    </>
     );
}
 
export default CouncilProposalDetails;