import { TOKEN_PROGRAM_ID } from "@project-serum/anchor/dist/cjs/utils/token";
import { Connection, PublicKey } from "@solana/web3.js";
import { useAtom } from "jotai";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Clipboard, Edit } from "react-feather";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Avatar from "../components/Avatar";
import LoadingSpinner from "../components/LoadingSpinner";
import { Proposal } from "../components/Proposal";
import { ProposalSkeleton } from "../components/ProposalSkeleton";
import { themeOpts } from "../components/ThemeChanger";
import { UserOrganisationItem } from "../components/UserOrganisationItem";
import { useAuth, useGlobalReputationScore, useOrganisations, useRoutesCurrentIdentifier, useUser } from "../state/hooks/useAlignGovernance";
import { isPubkey, truncateKey } from "../utils/truncateKey";
import { useGetOrganisationsQuery } from "../generated/graphql";
import MintProfilePicture from "../components/MintProfilePicture";
import { Inbox, ChatNavigationHelpers, useDialectUiId, useDialectSdk, Thread } from "@dialectlabs/react-ui"
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";

export const getTokenAccountsByAddress = async (
    addr: string,
    connection: Connection,
    programId : PublicKey = TOKEN_PROGRAM_ID
) => {
    return connection.getParsedTokenAccountsByOwner(new PublicKey(addr), {
        programId,
    });
};
export const METADATA_PREFIX = Buffer.from("metadata");
export const METADATA_PROGRAM_ID = new PublicKey(
    "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

export function Profile() {
    const navigate = useNavigate();
    const identifier = useRoutesCurrentIdentifier()
    const userProfile = useUser(identifier)
    const user = useAuth()
    // const sdk = useDialectSdk()
    const [thread, setthread] = useState<null | Thread>(null);
    const [showMessager, setshowMessager] = useState(false);

    const { navigation, open, ui,  } = useDialectUiId<ChatNavigationHelpers>('messenger');
    
    // useEffect(() => {
    //     const getThreads = async() => {
    //         if(!userProfile?.ownerRecords[0]?.account){
    //             return
    //         }
            
    //         const thread = await sdk?.threads?.find({otherMembers: [userProfile?.ownerRecords[0]?.account]})
    //         console.log(thread)
    //         setthread(thread)
    //     }
    //     getThreads()
    // }, [user?.username, sdk?.threads]);
    
    useEffect(() => {
        console.log(ui?.open, navigation?.showCreateThread, showMessager)
        if(ui?.open && navigation?.showCreateThread && showMessager){
            navigation?.showCreateThread(`align:${userProfile?.username}`)
        }
        else if(!ui?.open){
            setshowMessager(false)
        }
    }, [ui?.open, navigation?.showCreateThread, showMessager]);

    const isUsername = useMemo(() => identifier?  isPubkey(identifier) : false, [])

    if (!userProfile || !userProfile?.identity) {
        return (
            <div className="max-w-6xl mx-auto flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }


    return (
        <div className="my-profile-container mx-auto max-w-screen-2xl px-8 py-8 text-contrast">
            <div className="flex-wrap justify-between gap-6 lg:grid lg:grid-cols-3">
                <main className="my-profile-main w-full py-6 lg:col-span-2">

                    <h1 className="font-heading text-lg sm:text-xl md:text-2xl lg:text-4xl">My Organizations</h1>

                    <Suspense fallback={<ProposalSkeleton/>}>
                        <UserOrgList 
                            identifier={userProfile.identity.identifier}
                        //@ts-ignore
                        edgesTo={userProfile.node.edgesTo.map(x => x.user.identity?.identifier).filter(x => x !== undefined)}/>
                    </Suspense>

                </main>
                <aside className="my-profile-aside w-full lg:col-span-1">
                    {/* <div className="box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-[color:var(--box-background)] mb-6 w-full"> */}
                    <div className="mb-6 w-full">
                        <div className="ml-5 mt-5 flex gap-4">

                            <MintProfilePicture mint={userProfile.pfp} width={24}/>
                            
                            <p className="ml-3 mt-6 text-xl">
                                <span className="block">
                                    {userProfile.displayName}
                                </span>
                                <span className="block text-xs font-thin opacity-50">
                                    {userProfile.username}
                                </span>
                            </p>
                            <button

                                onClick={() => {open(); setshowMessager(true)}}>
                                    <ChatBubbleOvalLeftEllipsisIcon className="w-8 sm:w-10 text-contrast hover:bg-opacity-30 rounded-full bg-contrast bg-opacity-20 p-2"/>
                                </button>
                            <p className="flex grow"></p>
                            <p className="align-right mr-6 mt-6 items-end justify-end text-right text-xl">
                                {/* {(
                                    <button
                                    className="w-full flex justify-center gap-2 items-center bg-accent hover:bg-opacity-80 rounded-button border-primary   transition font-poppins font-medium p-3 text-sm px-5 py-2 text-center"
                                    >Follow</button>
                                )} */}
                                <span className="block text-xs font-thin opacity-50">
                                    {/* Store should derive this */}
                                    {/* {proposals?.filter(proposal => proposal.account.proposer.toBase58() === user.account.identifier.toBase58()).length || 0} Proposal(s) */}
                                </span>
                            </p>
                        </div>
                        <div className="my-5 ml-6 mr-10">
                            {/* <p className="text-sm opacity-75">
                                1/1 artist, musician, Webflow design/dev,
                                arm-chair DAOist. Built websites for PopHeadz,
                                BAPE Services, Bears Reloaded, Sanctuary,
                                H3LIUM, Chicken Tribe, and more.{" "}
                            </p> */}
                            <div className="flex items-center gap-2"><p >{userProfile.node.followerCount}</p> <p className="font-extralight text-sm">Followers</p></div>
                            <p className="mt-5 text-xs opacity-75">
                                {userProfile && (
                                    <>
                                    <strong>Profile Identifier: </strong>
                                    {userProfile?.identity && truncateKey(userProfile?.identity.identifier, 12)}
                                    <Clipboard
                                        className="inline-block h-4 cursor-pointer"
                                        onClick={() => {
                                            if(!userProfile?.identity){
                                                return
                                            }
                                            navigator.clipboard.writeText(
                                                userProfile?.identity.identifier
                                            );
                                            toast.success("Copied to clipboard.")
                                        }}/>
                                    </>
                                )}
                            </p>
                            <div className="mt-5 text-xs opacity-75">
                                {userProfile && (
                                    <div className="flex gap-1">
                                    <strong>Linked Wallets: </strong>
                                    {userProfile && userProfile.ownerRecords.map(wallet => <>
                                        <p>{truncateKey(wallet.account, 12)}</p>
                                        <Clipboard
                                        className="inline-block h-4 cursor-pointer"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                wallet.account
                                            );
                                            toast.success("Copied to clipboard.")
                                        }}/>
                               
                                    </>)}
                                    </div>
                                )}
                            </div>
                            <div className="mt-5">
                                <button className="mr-4 rounded-full border border-white border-opacity-30 bg-transparent py-2 px-4 text-xs uppercase">
                                    Profile Recovery Key{" "}
                                    <Clipboard className="inline-block h-4" />
                                </button>
                                {user?.identity && (user?.identity?.identifier === identifier || user?.username === identifier)  && (
                                    <button className="rounded-full border border-white border-opacity-30 bg-transparent py-2 px-4 text-xs uppercase" onClick={() => {
                                            if(userProfile.identity){
                                                navigate(`/u/${userProfile?.identity.identifier}/edit`);
                                            }
                                        }}>
                                            Edit Profile{" "}
                                        <Edit className="inline-block h-4" />
                                    </button>
                                )}
                           
                                 {/* { showMessager && <Inbox
                                    dialectId="dialect-inbox"
                                    wrapperClassName="h-full overflow-hidden rounded-2xl shadow-2xl shadow-neutral-800 border border-neutral-600"
                                />} */}
         
                            </div>
                        </div>
                    </div>
                    {/* <div className="box-container rounded-box border-boxWidth border-contrast border-opacity-30 bg-[color:var(--box-background)] p-5">
                        <h1 className="text-2xl">Owned Collections</h1>
                        <hr className="my-2 opacity-30" />

                        <div className="flex flex-wrap gap-2">
                            <CollectionBadge img={slugsLogo} name="Sol Slugs" />
                            <CollectionBadge
                                img={passportsLogo}
                                name="Phase Passports"
                            />
                        </div>
                    </div> */}
                </aside>
            </div>
        </div>
    );
}

export function UserOrgList({edgesTo, identifier}: {edgesTo : string[], identifier: string}) {

    const [organisations, setOrganisations] = useState<{
        username: string | undefined,
        pfp : string | null | undefined,
        displayname : string | undefined,
        organisation : string,
    }[]>([]);

    const {data, loading, error} = useGetOrganisationsQuery({
        variables: {
            take : 0,
        }
    })
    
    useEffect(() => {
        if(!data?.organisations){
            return
        }
        const filtered = data.organisations.filter(x => edgesTo.includes(x.identifier)).map(x => ({
            username: x.profile?.username,
            pfp : x.profile?.pfp,
            displayname : x.profile?.displayName,
            organisation : x.address
        }))
        setOrganisations(filtered)

    }, [data?.organisations?.length, edgesTo.length]);
    
    if(loading) return <LoadingSpinner/>

  return <>
    {organisations ? 
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-8">
            {organisations.map(org => org && (
                <UserOrganisationItem 
                    organisation={org.organisation}
                    pfp={org.pfp}
                    displayname={org.displayname}
                    username={org.username}
                    identifier={identifier}
                />
            ))}
        </div>
        :
        <p className="h-24 flex justify-start items-center my-4">You have no joined any organizations yet.</p>
    }
  </>
}

export function UserProposalList() {
    // const {userProposedProposals} = useProposals();

  return <>
    {/* {userProposedProposals?.length ? 
        userProposedProposals?.map((proposal) => <Proposal address={proposal.address.toBase58()} key={proposal.address.toBase58()} />)
        :
        <p className="h-24 flex justify-center items-center my-4">You don't have any current draft proposals</p>
    } */}
  </>
}

