import { Wizard, WizardStepProps } from "./Wizard";
import DescriptionStep from "./steps/DescriptionStep";
import ProposalWizardGovernanceCouncilStep from "./steps/ProposalWizardGovernanceCouncilStep";
import ProposalWizardGovernanceReviewStep from "./steps/ProposalWizardGovernanceReviewStep";
import ProposalWizardGovernanceThresholdStep from "./steps/ProposalWizardGovernanceThresholdStep";


const ProposalWizardGovernance = () => {
    return( 
        <>
           <Wizard>
                {
                    (props : WizardStepProps<{}>) => (
                        <>
                            {/* <DescriptionStep {...props} /> */}
                            <ProposalWizardGovernanceCouncilStep {...props}/>
                            <ProposalWizardGovernanceThresholdStep {...props}/>
                            <ProposalWizardGovernanceReviewStep {...props}/>
                        </>
                    )
                }
       
            </Wizard>
        </> 
    );
}
 
export default ProposalWizardGovernance;