import { web3 } from "@project-serum/anchor";
import { SystemProgram } from "@solana/web3.js";
import { Api } from "./api";
import { Derivation } from "./pda";
import { AlignPrograms } from "./types";
import { MAX_WALLET_THRESHOLD, MIN_WALLET_THRESHOLD } from "./constants";

export const removeCouncilMemberInstruction = async (
  councilIdentifier: web3.PublicKey,
  organisation: web3.PublicKey,
  programs: AlignPrograms,
): Promise<web3.TransactionInstruction> => {
  const councilManagerAccount = await Api.fetchCouncilManager(
    organisation,
    programs,
  );
  const walletAuthorityConfigs = await Api.fetchWalletConfigs(
    organisation,
    programs,
  );

  const councilWalletCounfig = walletAuthorityConfigs.find((config) =>
    config.account.authorityAddress.equals(
      councilManagerAccount.account.authority,
    ),
  );

  return programs.alignGovernanceProgram.methods
    .removeCouncilMember(councilIdentifier)
    .accountsStrict({
      payer: councilWalletCounfig.account.authorityAddress,
      walletAuthority: councilWalletCounfig.account.authorityAddress,
      organisation: organisation,
      walletAuthorityConfig: councilWalletCounfig.address,
      councilManager: councilManagerAccount.address,
      systemProgram: SystemProgram.programId,
    })
    .instruction();
};

export const addCouncilMemberInstruction = async (
  councilIdentifier: web3.PublicKey,
  organisation: web3.PublicKey,
  programs: AlignPrograms,
): Promise<web3.TransactionInstruction> => {
  const councilManagerAccount = await Api.fetchCouncilManager(
    organisation,
    programs,
  );
  const walletAuthorityConfigs = await Api.fetchWalletConfigs(
    organisation,
    programs,
  );

  const councilWalletCounfig = walletAuthorityConfigs.find((config) =>
    config.account.authorityAddress.equals(
      councilManagerAccount.account.authority,
    ),
  );

  return programs.alignGovernanceProgram.methods
    .addCouncilMember()
    .accountsStrict({
      payer: councilWalletCounfig.account.authorityAddress,
      walletAuthority: councilWalletCounfig.account.authorityAddress,
      organisation: organisation,
      walletAuthorityConfig: councilWalletCounfig.address,
      councilManager: councilManagerAccount.address,
      systemProgram: SystemProgram.programId,
      councilIdentity: Derivation.deriveIdentityAddress(councilIdentifier),
    })
    .instruction();
};

export const setWalletThresholdInstruction = async (
  threshold: number,
  walletConfigAddress: web3.PublicKey,
  organisation: web3.PublicKey,
  programs: AlignPrograms,
): Promise<web3.TransactionInstruction> => {
  if (threshold > MAX_WALLET_THRESHOLD || threshold < MIN_WALLET_THRESHOLD) {
    throw "Wallet threshold out of bounds. Must be between 1 - 100%.";
  }
  const councilManagerAccount = await Api.fetchCouncilManager(
    organisation,
    programs,
  );
  const walletAuthorityConfigs = await Api.fetchWalletConfigs(
    organisation,
    programs,
  );

  const councilWalletCounfig = walletAuthorityConfigs.find((config) =>
    config.account.authorityAddress.equals(
      councilManagerAccount.account.authority,
    ),
  );
  console.log(
    "Creating setThreshold instruction for ",
    walletConfigAddress.toBase58(),
    organisation.toBase58(),
    councilWalletCounfig.address.toBase58(),
    councilWalletCounfig.account.authorityAddress.toBase58(),
  );

  return programs.alignGovernanceProgram.methods
    .setThreshold(threshold)
    .accountsStrict({
      payer: councilWalletCounfig.account.authorityAddress,
      councilWalletAuthority: councilWalletCounfig.account.authorityAddress,
      organisation: organisation,
      councilWalletAuthorityConfig: councilWalletCounfig.address,
      councilManager: councilManagerAccount.address,
      systemProgram: SystemProgram.programId,
      walletConfig: walletConfigAddress,
    })
    .instruction();
};
