import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { USDC_MINT_ADDRESS } from "align-sdk";
import { Fragment, useState } from "react";
import { isPubkey } from "../utils/truncateKey";
import { useWallet } from "@solana/wallet-adapter-react";

const coins = [
    {
        name : "SOL",
        mint: "",
        image: "/sol-logo.webp"
    },
    {
        name: "USDC",
        mint: USDC_MINT_ADDRESS.toBase58(),
        image: "/usdc-logo.webp"
    }
]

const WithdrawalModal = ({
    open,
    onClose,

}: {
    open: boolean,
    onClose: () => void
}) => {
    const [selectedCoin, setselectedCoin] = useState(coins[0]);
    const [depositInput, setdepositInput] = useState("0");
    const {publicKey} = useWallet()
    const [receiver, setreceiver] = useState<string| null>(publicKey?.toBase58() || null);
    if(!open) return <></>

    return ( 
        <>
            <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-20 z-40"/>
            <div className="flex justify-center items-center fixed w-screen h-screen left-0 top-0 z-50">
                <div className="relative flex flex-col gap-7 border-2 border-gray-600 bg-black w-[600px] h-fit px-5 pb-10 pt-4 rounded-lg">
                        <label className="text-contrast text-lg font-semibold border-b border-b-contrast border-opacity-10 pb-2">Withdrawal</label>
                        
                        <div className="flex gap-3">
                            <input 
                                value={depositInput}
                             onChange={(e) => {
                                var regex = /^\d+\.?\d*$/
                                if (regex.test(e.target.value) || e.target.value === ""){
                                    setdepositInput(e.target.value)
                                }
                            }}
                            className=" bg-transparent pl-4 border border-contrast rounded-lg" />
                            <Listbox 
                                value={selectedCoin} 
                                onChange={setselectedCoin}>
                                <Listbox.Button
                                    className="relative w-1/4 cursor-default rounded-lg bg-accent py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-accent focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                >
                                <div className="flex justify-start items-center gap-2">
                                    <img 
                                        className="w-6 self-center rounded-full"
                                        alt={selectedCoin.name} 
                                        src={selectedCoin.image}/>
                                    <span className="block truncate">{selectedCoin.name}</span>
                                </div>
                               
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                </span>
                                
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute right-0 top-28 mt-1 max-h-60 w-1/4 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {coins.map((person, personIdx) => (
                                        <Listbox.Option
                                        key={personIdx}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                            }`
                                        }
                                        value={person}
                                        >
                                        {({ selected }) => (
                                            <>
                                            <span
                                                className={`block truncate ${
                                                selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                                {person.name}
                                            </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                            </>
                                        )}
                                        </Listbox.Option>
                                    ))}
                                    </Listbox.Options>
                                </Transition>
                            </Listbox>
                            
                        </div>
                        <input 
                            value={receiver || ""}
                             onChange={(e) => {
                               if (isPubkey(e.target.value)){
                                    setreceiver(e.target.value)
                               }
                            }}
                            className="py-1 bg-transparent pl-4 border border-contrast rounded-lg" />
                        <button
                            className="w-full mt-4 self-end bg-accent rounded-button border-primary text-contrast hover:opacity-80 border transition font-poppins font-medium p-3 text-sm px-5 py-2.5 text-center"
                        >Send</button>
                        <XMarkIcon onClick={() => onClose()} className="absolute hover:cursor-pointer  w-7 top0 right-4"/>
                    </div>

            </div>
        </>
     );
}
 
export default WithdrawalModal;