import { atomFamily, selectorFamily } from "recoil";

export interface StyleConfig {
  logo: string;
  gridLayout: boolean;
  mono: boolean;
  primary: string;
  secondary: string;
  contrast: string;
  accent: string;
  strings: {
    council: string;
  };
}

export interface Style {
  css: string;
  config: StyleConfig;
}

export const defaultStyleConfig: StyleConfig = {
  logo: "",
  gridLayout: true,
  mono: false,
  primary: "",
  secondary: "",
  contrast: "",
  accent: "",
  strings: {
    council: "",
  },
};

export const defaultStyle: Style = {
  css: "body { --color-primary: 143 254 170; --color-accent: 143 254 170; --border-width: 2px; --button-background: 99 26 255; --button-background-hover: 99 26 255; --button-width: 2px; --gradient-stop-1: #8FFEAA; --gradient-stop-2: #007AFF;}",
  config: defaultStyleConfig,
};

export const queryStyle = selectorFamily<Style, string | undefined>({
  key: "styles/queryStyle",
  get:
    (oragnisation: string | undefined) =>
    async ({ get }: any) => {
      try {
        const res = await fetch(`/api/styles?org=${oragnisation}`);
        const style = await res.json();
        return style;
      } catch (e) {
        console.warn(e);
        return defaultStyle;
      }
    },
});

export const styling = atomFamily<Style, string | undefined>({
  key: "styling/styles",
  default: (param) => queryStyle(param),
});
