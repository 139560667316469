import { useCallback, useEffect, useState } from 'react';
import { PopoverPicker } from './ColorPicker';
import * as Align from '../align.config';
import { atom, useAtom } from 'jotai'
import {
    Sliders
} from "react-feather";

export const themeOpts = atom(Align);

const ThemeChanger = () => {
    const [editorOpen, setEditorOpen] = useState(false);
    const [gridLayout, setGridLayout] = useState(!!Align.gridLayout);
    const [boxBgColor, setBoxBgColor] = useState('');
    const [boxBorderColor, SetBoxBorderColor] = useState('');
    const [theme, setTheme] = useAtom(themeOpts)

    const [buttonBgColor, setButtonBgColor] = useState('');
    const [buttonTextColor, SetButtonTextColor] = useState('');

    useEffect(() => {
        const boxBgColor = getComputedStyle(document.documentElement).getPropertyValue('--box-background');
        const boxBorderColor = getComputedStyle(document.documentElement).getPropertyValue('--box-border-color');
        setBoxBgColor(boxBgColor);
        SetBoxBorderColor(boxBorderColor);
    },[])


    // useEffect(() => {
    //     document.documentElement.style.setProperty('--box-background', boxBgColor);
    // },[boxBgColor])

    // useEffect(() => {
    //     document.documentElement.style.setProperty('--box-border-color', boxBorderColor);
    // },[boxBorderColor])

    // useEffect(() => {
    //     document.documentElement.style.setProperty('--button-background', buttonBgColor);
    // },[buttonBgColor])

    // useEffect(() => {
    //     document.documentElement.style.setProperty('--button-text-color', buttonTextColor);
    // },[buttonTextColor])

    useEffect(() => {
        // setTheme({ ...theme, gridLayout: gridLayout })
        document.body.style.setProperty('--color-primary', formatColor(theme.primary));
        document.body.style.setProperty('--color-secondary', formatColor(theme.secondary));
        document.body.style.setProperty('--color-contrast', formatColor(theme.contrast));
        document.body.style.setProperty('--color-accent', formatColor(theme.accent));
    },[theme])

    const formatColor = (rgb:string) => {
        return rgb.replace(/[^0-9 ]/g, "");
    }

    return (
        <div className={`fixed w-[320px] h-screen flex flex-col flex-start left-0 top-0  border-contrast border-opacity-30 bg-white mb-4 py-4 px-6 z-10 transition-all ${editorOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <button className="absolute right-0 translate-x-full bg-gray-50 rounded-r-md p-4" onClick={() => setEditorOpen(!editorOpen)}>
                <Sliders size={"16px"} />
            </button>
            Theme Updates
            <div className="mt-0">
                <h5 className="mt-10 border-b block pb-4">Global Options</h5>
                <div className="space-y-4 pt-2">
                    <div className="flex justify-between items-center">
                        <strong>Grid Layout:</strong>
                        <input type="checkbox" name="gridLayout" className="inline-block" checked={theme.gridLayout} onChange={(e) => setTheme({ ...theme, gridLayout: e.target.checked }) }/>
                    </div>
                    <div className="flex justify-between items-center">
                        <strong>Monochrome:</strong>
                        <input type="checkbox" name="mono" className="inline-block" checked={theme.mono} onChange={(e) => setTheme({ ...theme, mono: e.target.checked }) }/>
                    </div>
                    <div className="flex justify-between items-center">
                        <strong>Primary Color:</strong>
                        <PopoverPicker color={theme.primary} onChange={(e:any) => setTheme({ ...theme, primary: e }) }/>
                    </div>
                    <div className="flex justify-between items-center">
                        <strong>Secondary Color:</strong>
                        <PopoverPicker color={theme.secondary} onChange={(e:any) => setTheme({ ...theme, secondary: e }) }/>
                    </div>
                    <div className="flex justify-between items-center">
                        <strong>Contrast Color:</strong>
                        <PopoverPicker color={theme.contrast} onChange={(e:any) => setTheme({ ...theme, contrast: e }) }/>
                    </div>
                    <div className="flex justify-between items-center">
                        <strong>Accent Color:</strong>
                        <PopoverPicker color={theme.accent} onChange={(e:any) => setTheme({ ...theme, accent: e }) }/>
                    </div>
                </div>
                {/* <h5 className="mt-10 border-b block pb-4">Box Options</h5>
                <div className="space-y-4 pt-2">
                    <div className="flex justify-between items-center">
                        <strong>Box Background Color:</strong>
                        <PopoverPicker color={boxBgColor} onChange={setBoxBgColor} />
                    </div>
                    <div className="flex justify-between items-center">
                        <strong>Box Border Color:</strong>
                        <PopoverPicker color={boxBorderColor} onChange={SetBoxBorderColor} />
                    </div>
                </div>
                <h5 className="mt-10 border-b block pb-4">Button Options</h5>
                <div className="space-y-4 pt-2">
                    <div className="flex justify-between items-center">
                        <strong>Button Background:</strong>
                        <PopoverPicker color={buttonBgColor} onChange={setButtonBgColor} />
                    </div>
                    <div className="flex justify-between items-center">
                        <strong>Button Text Color:</strong>
                        <PopoverPicker color={buttonTextColor} onChange={SetButtonTextColor} />
                    </div>
                </div> */}
            </div>
            <div className='mt-auto'>
            Theme Debug
            <pre className='overflow-scroll'>
                { JSON.stringify(theme, null, 2) }
            </pre>
            </div>
        </div>
    );
};

export default ThemeChanger;
