import { useEffect, useState } from "react";

export const ProgressBar = ({
    yesVotes,
    noVotes,
}: {
    yesVotes: number | undefined;
    noVotes: number | undefined;
}) => {
    const [upvotePercentage, setUpvotePercentage] = useState(0);
    const [downvotePercentage, setDownvotePercentage] = useState(0);


    useEffect(() => {
        if (yesVotes === undefined || noVotes == undefined) {
            return;
        }
        const totalVotes = noVotes + yesVotes;
        setUpvotePercentage((yesVotes / totalVotes) * 100)
        setDownvotePercentage((noVotes / totalVotes) * 100)

    }, [yesVotes, noVotes]);
    return (
        <div className="w-full bg-red-400 rounded-full h-2.5 mt-2 block">
            <div
                className="bg-accent h-2.5 rounded-r-none rounded-full"
                style={{ width: upvotePercentage + "%" }}
            >
            </div>
        </div>
    );
};